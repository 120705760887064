import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  // Device Tabla
  botonesTabla: {
    paddingRight: '8px'
  },
  tituloTabla: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  // Device CRU
  root: {
    width: '100%',
    '& > * + *': {},
    paddingTop: '10px',
    paddingLeft: '30px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  gridTitulo: {
    paddingTop: 0,
    paddingBottom: 0
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium'
  },
  gridSlider: {
    textAlign: 'center'
  },
  slider: {
    width: '95%',
    color: theme.palette.secondary.main
  },
  gridSwitch: {
    textAlign: 'center'
  },
  // Device Parametros
  textAlignCenter: {
    textAlign: 'center'
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end'
  },
  // Device Info
  marginVersion: {
    marginLeft: '1vw'
  },
  alignVersion: {
    alignSelf: 'center',
    marginTop: '0.5vh'
  },
  tituloSinSections: {
    marginTop: '30px',
    marginLeft: '40px'
  },
  botonActivar: {
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff',
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  iconoActivar: {
    fontSize: '1.71875rem',
    color: '#ffffff'
  },
  itemTexto: {
    marginLeft: '1vw'
  },
  botonModificar: {
    position: 'fixed',
    bottom: '5%',
    [theme.breakpoints.up(960)]: {
      right: '45%'
    },
    [theme.breakpoints.down(960)]: {
      right: '4%'
    }
  }
}));
export default useStyles;
