import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/FullscreenOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import useStyles from './styles';
import { State } from './hooks/useReducerVideo/resources/types/typeState';
import { Events } from './hooks/useReducerVideo/resources/types/typeEvents';
import useReducerVideo from './hooks/useReducerVideo/useReducerVideo';
import { DispositivoVideo } from './resources/interfaces/interfaceDispositivoVideo';
import { VideoControls } from './videoControls/VideoControls';
import { dictionary_cctv } from '../../resources/enums/plainText';

/**
 * Componente que muestra el video con sus respectivos controles (de playback o stream) dentro de un dialogo donde
 * poder ajustar la pantalla completa.
 * @param { State | undefined } state State del hook useReducerVideo
 * @param { Events | undefined } events Events del hook useReducerVideo
 * @param { DispositivoVideo | undefined } datosVideo en caso de no crear el hook, datos minimos
 * del dispositivo para su reproducción
 * @param { boolean | undefined } open en caso de no crear el hook, indicar si debe abrise el dialogo de video
 * @returns { JSX.Element } Devuelve el componente
 */
export default function DialogVideo({
  state,
  events,
  datosVideo,
  open,
  setOpen
}: {
  state?: State;
  events?: Events;
  datosVideo?: DispositivoVideo;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const classes = useStyles();
  const reducer =
    state && events
      ? { state, events }
      : useReducerVideo({
          datosVideo: datosVideo,
          openDialog: open,
          setOpenDialog: setOpen
        });

  return (
    <Dialog
      onClose={reducer.events.handleOnClose}
      open={reducer.state.openDialogVideo}
      maxWidth="sm"
      fullScreen={reducer.state.isFullScreen ? true : undefined}
      onDoubleClick={reducer.events.handleDoubleClick}
      onKeyDown={reducer.events.handleKeyDown}
    >
      {reducer.state.isFullScreen ? <></> : <DialogTitle>{reducer.state.title}</DialogTitle>}
      <DialogContent
        style={{ padding: reducer.state.isFullScreen ? 0 : '' }}
        className={classes.dialogVideo}
      >
        {reducer.state.isFullScreen || !reducer.state.subtitle ? (
          <></>
        ) : (
          <DialogContentText>{reducer.state.subtitle}</DialogContentText>
        )}
        {reducer.state.openDialogVideo ? (
          <VideoControls state={reducer.state} events={reducer.events} />
        ) : (
          <></>
        )}
        {reducer.state.isPlaying && !reducer.state.isFullScreen ? (
          <Tooltip title={dictionary_cctv.PANTALLA_COMPLETA} arrow>
            <IconButton color="secondary" onClick={reducer.events.openFullScreen}>
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </DialogContent>
      {reducer.state.isFullScreen ? (
        <></>
      ) : (
        <DialogActions>
          <Tooltip title={dictionary_cctv.CERRAR} arrow>
            <IconButton color="secondary" onClick={reducer.events.handleOnClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      )}
    </Dialog>
  );
}
