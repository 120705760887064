import React from 'react';
import { Autocomplete, Button, FormControl, Grid, Typography, TextField, Box } from '@mui/material';
import useStyles from './styles';
import { TablaTiposActuaciones } from './TablaTiposActuaciones';
import { StateTiposActuaciones } from './resources/interfaces/interfaceStateTiposActuaciones';
import { section } from '../sections/resources/interfaces/interfaceSection';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import {
  dictionary_generic,
  dictionary_tipos_actuaciones,
  enumLabel
} from '../../resources/enums/plainText';

export function TiposActuacionesModificar(props: {
  state: StateTiposActuaciones;
  events: any;
}): JSX.Element {
  const classes = useStyles();
  const { state, events } = props;
  const {
    handleInputChange,
    handleInputInvalid,
    handleSubmitEditar,
    handleAutocompleteSectionChange
  } = events;
  const datosSections = state.sections;

  return (
    <div className={classes.root}>
      <form id="formTiposAcciones" onSubmit={handleSubmitEditar} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<TablaTiposActuaciones />} />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {dictionary_tipos_actuaciones.MODIFICAR_TIPO_ACTUACION}
          </Typography>
        </Box>
        <Grid container sm={5} xs={12} spacing={4} direction="column">
          <Grid item container sm={12} justifyContent="flex-start" spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_TIPO_ACTUACION}
                id="nombre"
                type="text"
                required
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={state.tipoActuacion?.nombre}
              />
            </Grid>
          </Grid>
          <Grid item container sm={12} justifyContent="flex-start" spacing={2} direction="row">
            <Grid item xs={6} sm={6}>
              <FormControl variant="outlined" size="small" fullWidth={true}>
                <Autocomplete
                  size="small"
                  id="idSection"
                  options={datosSections}
                  disableClearable
                  getOptionLabel={(option: section) => option.nombre}
                  value={state.sectionSeleccionada}
                  onChange={handleAutocompleteSectionChange}
                  renderOption={(props, option) => (
                    <li {...props} className={classes.displayBlock}>
                      <span>{option.nombre}</span>
                      <span className={classes.styleSpanSection}>{option.nombrePlugin}</span>
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField {...params} label={enumLabel.SECTION} variant="outlined"></TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.VALOR}
                id="valor"
                type="text"
                required
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={state.tipoActuacion?.valor}
              />
            </Grid>
          </Grid>
          <Grid item container sm={12} justifyContent="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                {dictionary_generic.MODIFICAR}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
