export enum actionsAcciones {
  SET_REGLA = 'setRegla',
  SET_DIALOGO_ACCIONES = 'setDialogoAcciones',
  SET_REGLA_MODIFICAR = 'setDispositivoModificar',
  SET_REGLA_READY = 'setDispositivoReady',
  SET_MOSTRAR_CREAR = 'setMostrarCrear',
  SET_MOSTRAR_MODIFICAR = 'setMostrarModificar',
  SET_DISPOSITIVOS = 'setDispositivos',
  SET_ACCION = 'setAccion',
  SET_LISTA_ACCIONES = 'setListaAcciones',
  SET_PANEL_ACCION_ACTIVO = 'setPanelAccionActivo',
  SET_ACTUACION_COMPLETA = 'setActuacionCompleta',
  SET_TIPOS_DISPOSITIVO = 'setTiposDispositivo',
  SET_CAMBIO_ESTADO_REGLA = 'setCambioEstadoRegla',
  TOGGLE_ESTADO_REGLA = 'toggleEstadoRegla',
  SET_EMAIL = 'setEmail',
  SET_WHATSAPP = 'setWhatsapp',
  SET_ACTUACION_GRUPAL_COMPLETA = 'SET_ACTUACION_GRUPAL_COMPLETA'
}
