import axios, { AxiosRequestConfig } from 'axios';
import { encriptarJSON } from 'functions/encryption';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get tags by array of devices id.
 * @param {Array<number>} devices Array of devices id.
 * @returns {{ key: Key; request: AxiosRequestConfig }} key used by swr and axios request config.
 */
export function getTagsByDevices(props: { devices: Array<number> | undefined }): {
  key: Key;
  request: AxiosRequestConfig;
} {
  const { devices = undefined } = props;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getTagsByDevices,
    headers: getHeaders(dictionary_entornos.BACK),
    data: encriptarJSON({ devices })
  };

  const key: Key | null = devices
    ? `${swrServiceRoutes.getTagsByDevices}-${JSON.stringify(props)}`
    : null;

  return { key, request };
}

/**
 * Gets all the tags of a module, filtering by device type if specified.
 * @param {number} idModulo
 * @param {number} idsTiposDispositivos Optional
 * @returns  {[string | null, (headers: Record<string, unknown>) => Promise<AxiosResponse<any, any>>]} key used by swr and axios request.
 */
export function getTagsByModule(props: {
  idModulo: number;
  idsTiposDispositivos: Array<number> | Array<never>;
}) {
  const { idsTiposDispositivos = undefined, idModulo } = props;

  const key: Key | null = idsTiposDispositivos
    ? `${swrServiceRoutes.getTagsByDevices}-${JSON.stringify(props)}`
    : null;

  const request: AxiosRequestConfig = {
    method: 'POST',
    headers: getHeaders(dictionary_entornos.BACK),
    data: encriptarJSON({ idModulo, idsTiposDispositivos }),
    url: `${process.env.REACT_APP_URL_LOCAL}${swrServiceRoutes.getTagsByModule}`
  };

  return { key, request };
}
