import moment from 'moment';
/**
 * @alias Regla
 * @typedef Regla
 * @property {number} id id de la regla.
 * @property {string} nombre nombre de la regla.
 * @property {Date} fechaCreacion fecha de creación de la regla.
 * @property {string} descripcion descripción de la regla.
 * @property {boolean} activo indica si la regla está activa o no.
 */

export interface regla {
  id?: number;
  nombre: string;
  fechaCreacion: string;
  descripcion?: string;
  activo: boolean;
}

export const objectRegla = (elemento?: regla): regla => {
  let regla: regla;
  elemento === undefined
    ? (regla = {
        nombre: '',
        fechaCreacion: new Date().toISOString().slice(0, 19).replace('T', ' '),
        descripcion: '',
        activo: true
      })
    : (regla = {
        id: elemento.id,
        nombre: elemento.nombre,
        fechaCreacion: elemento.fechaCreacion,
        descripcion: elemento.descripcion,
        activo: elemento.activo
      });

  return regla;
};
