import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme?: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  cajas: {
    marginBottom: '15px'
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium',
    marginBottom: '2px'
  },
  // Usuarios Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  // Usuarios Modificar
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  fabBotonCardConfigurator: {
    position: 'fixed',
    bottom: '5%',
    right: '100px'
  },
  // PreviewPermisos
  rootPreview: {
    width: '100%',
    backgroundColor: theme?.palette.background.paper,
    maxHeight: '450px',
    overflow: 'auto'
  },
  nested: {
    paddingLeft: theme?.spacing(2),
    float: 'left',
    width: '33%'
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#eaeaea',
      borderRadius: '15px'
    }
  },
  listItemText: {
    fontSize: '13px'
  },
  '@media (min-width: 466px)': {
    nested: {
      width: '100%'
    }
  },
  '@media (min-width:740px) and (max-width: 960px)': {
    nested: {
      width: '50%'
    }
  },
  '@media (min-width: 1224px)': {
    nested: {
      width: '50%'
    }
  },
  '@media (min-width: 1624px)': {
    nested: {
      width: '33%'
    }
  }
}));
export default useStyles;
