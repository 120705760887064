import React, { useContext } from 'react';
import { FormGroup, IconButton, Tooltip, Typography } from '@mui/material';

import { MapIcons } from '../iconos/Iconos';
import GISContext from '../../context/GISContext';
import useStyles from './styles';
import { dictionary_dispositivos } from '../../../../../resources/enums/plainText';
import { dispositivo } from '../../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { FiltroInterface } from '../../resources/interfaces/interfaceFiltro';

/**
 * Esta función monta los componentes necesarios que se mostrarán en el filtro, es decir: si tenemos una lista de 20 dispositivos, 10 luminarias y 10 cajas esta función
 * crea un array con sólo 1 dispositivo de cada tipo.
 * @param {dispositivo[]} markers
 * @returns {FiltroInterface[]} tiposDispositivos
 */
function montarFiltro(markers: dispositivo[]): FiltroInterface[] {
  const tiposDispositivos: FiltroInterface[] = [];
  let codigo: string;
  for (let index = 0; index < markers.length; index++) {
    codigo = markers[index].codigoTipoDispositivo as string;
    const p = tiposDispositivos.filter((tipos) => tipos.codigo === codigo);
    if (p.length === 0)
      tiposDispositivos.push({
        codigo: markers[index].codigoTipoDispositivo as string,
        icono: markers[index].icono,
        nombre: markers[index].tipoDispositivo as string
      });
  }
  return tiposDispositivos;
}

export function ElementosFiltro(): JSX.Element {
  const classes = useStyles();
  const { datosGIS, setCodTipoDispositivo, codTipoDispositivo } = useContext(GISContext);
  const listaCheckBox = montarFiltro(datosGIS);

  const handleClick = (codigoTipoDispositivo: string) => {
    const currentIndex = codTipoDispositivo.indexOf(codigoTipoDispositivo);
    const nuevoState = [...codTipoDispositivo];
    if (currentIndex === -1) {
      nuevoState.push(codigoTipoDispositivo);
    } else {
      nuevoState.splice(currentIndex, 1);
    }
    setCodTipoDispositivo(nuevoState);
  };

  return (
    <>
      <Typography variant="h6" color={'primary'} className={classes.titulo}>
        {dictionary_dispositivos.DISPOSITIVOS}
      </Typography>

      <FormGroup row className={classes.filtro}>
        {listaCheckBox.map((tipoDispositivo) => {
          return (
            <Tooltip title={tipoDispositivo.nombre} key={tipoDispositivo.codigo}>
              <div style={{ padding: '5px' }}>
                <IconButton
                  aria-label="filtro"
                  onClick={() => handleClick(tipoDispositivo.codigo)}
                  className={`${classes.botonFiltro} ${
                    codTipoDispositivo.indexOf(tipoDispositivo.codigo) !== -1
                      ? classes.botonSeleccionado
                      : classes.botonNoSeleccionado
                  }`}
                >
                  <MapIcons
                    icono={tipoDispositivo.icono !== undefined ? tipoDispositivo.icono : ''}
                  />
                </IconButton>
              </div>
            </Tooltip>
          );
        })}
      </FormGroup>
    </>
  );
}
