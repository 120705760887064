import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '0 20px 10px 20px'
  },
  tituloDisponibles: {
    paddingLeft: '1vw',
    marginRight: '1vw'
  },
  iconoDisponibles: {
    width: 30,
    height: 30,
    color: '#000000BB'
  },
  lista: {
    width: '100%'
  },
  elemento_lista: {
    float: 'left',
    display: 'inline',
    [theme.breakpoints.down(1330)]: {
      width: '100%'
    },
    [theme.breakpoints.up(1330)]: {
      width: '50%'
    },
    [theme.breakpoints.up(1845)]: {
      width: '33%'
    },
    [theme.breakpoints.up(2320)]: {
      width: '25%'
    },
    [theme.breakpoints.up(2820)]: {
      width: '20%'
    },
    [theme.breakpoints.up(3450)]: {
      width: '16%'
    }
  },
  divisor: {
    width: '100%',
    display: 'inline-flex',
    marginTop: '25px',
    marginBottom: '25px'
  },
  tarjeta: {
    width: '494px',
    display: 'flex',
    height: '198px'
  },
  imagen: {
    width: '150px',
    alignSelf: 'center',
    textAlign: 'center',
    paddingLeft: '16px',
    paddingRight: 0
  },
  logotipo: {
    maxHeight: '130px',
    maxWidth: '130px'
  },
  logotipoPlugin: {
    height: '80px',
    width: '80px'
  },
  detalles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px',
    paddingBottom: 0
  },
  contenido: {
    flex: '1 0 auto',
    padding: '8px 8px 0 8px'
  },
  enlaces: {
    color: '#006BDC'
  },
  controles: {
    alignItems: 'center',
    width: '100%'
  },
  botonInstalar: {
    marginRight: '8px',
    height: '25px',
    width: '95px'
  },
  botonAjustes: {
    padding: 0,
    marginRight: '8px',
    '&.Mui-disabled': {
      color: 'transparent'
    }
  },
  iconoAjustes: {
    height: '35px',
    width: '35px'
  },
  iconoAviso: {
    height: '30px',
    width: '30px',
    color: '#ff9800'
  },
  imagenDialogo: {
    width: '150px',
    alignSelf: 'center',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  logotipoPluginDialogo: {
    height: '110px',
    width: '110px'
  },
  avisoDescatalogado: {
    alignItems: 'center',
    height: '25px',
    marginTop: '8px'
  }
}));
