import React from 'react';
import { DeviceCRU } from './DeviceCRU';
import { device } from './resources/interfaces/interfaceDevice';
import useReducerDevice from './hooks/useReducerDevice/useReducerDevice';
import { DeviceInfo } from './DeviceInfo';
import { TablaDevices } from './TablaDevices';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { plugin } from '../plugins/resources/interfaces/interfacePlugin';

export function DeviceManager(props: {
  deviceSeleccionado?: device;
  pluginSeleccionado?: plugin;
}): JSX.Element {
  const { state, events } = useReducerDevice({
    deviceParaModificar: props.deviceSeleccionado,
    plugin: props.pluginSeleccionado
  });

  /**
   * Función que controla que componente de DEVICE debe mostrarse según la key
   * @returns {JSX.Element} devuelve el componente seleccionado
   */
  const managerDevice = (): JSX.Element => {
    switch (state.key) {
      case enumComponentes.DEVICES:
        events.quitarPluginSeleccionado();
        return <TablaDevices state={state} events={events} />;

      case enumComponentes.DEVICES_POR_PLUGIN:
        return <TablaDevices state={state} events={events} />;

      case enumComponentes.CREAR_DEVICE:
        if (state.tiposDevice !== undefined || state.plugins !== undefined) {
          return <DeviceCRU state={state} events={events} />;
        } else {
          return <></>;
        }

      case enumComponentes.INFORMACION_DEVICE:
        return <DeviceInfo state={state} events={events} />;
      case enumComponentes.INFO_MODIFICAR_DEVICE:
      case enumComponentes.MODIFICAR_DEVICE:
        if (state.deviceModificar !== undefined) return <DeviceCRU state={state} events={events} />;
        else return <></>;

      default:
        return <></>;
    }
  };

  return managerDevice();
}
