import React from 'react';
import ErrorContext from '../context/ErrorContext';
import DialogError from './DialogError';

interface Props {
  children: React.ReactNode;
}

interface State {
  datosError?: {
    error: {
      message: string;
      stack: string;
    };
    info: { componentStack: string };
  };
}

/**
 * Clase para controlar si hubiera algún error de renderizado en la plataforma.
 * Este componente traducido "límite de error" captura errores de JavaScript en
 * cualquier parte de su árbol de componentes hijo, registra el error, y muestra
 * un dialogo de error.
 *
 * Pero no puede capturar errores de manejadores de eventos (handles) y de funciones asíncronas.
 * Para estos casos se hará uso la sentencia try/catch y de un contexto para establecer cuando
 * se ha producido un error
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      datosError: undefined
    };
  }

  static contextType = ErrorContext;

  componentDidCatch(error: any, info: any): void {
    // Establecemos el estado con los datos del error para pasarlo por props al componente hijo y desde ahí al manejador del error
    // para registrarlo en un servicio de reporte de errores de back. Lo hacemos así ya que en una clase no se puede usar hooks y
    // ni más de 1 contexto y necesitamos saber el modulo y el componente seleccionado y el usuario que ha ejecutado la aplicacion
    this.setState({
      datosError: {
        error: error,
        info: info
      }
    });
  }

  render(): React.ReactNode {
    const { hasError } = this.context as any;

    if (hasError.countErrors < 5) {
      // si se produce cualquier error, se abre el dialogo con la ventana de error en el que se puede volver a donde se estaba
      return (
        <>
          {this.props.children}
          <DialogError datosError={this.state.datosError} />
        </>
      );
    } else {
      // si se produce error de renderizado 5 veces seguidas, se abre el dialogo con la ventana de error y solo se podrá reiniciar
      return <DialogError datosError={this.state.datosError} />;
    }
  }
}
export default ErrorBoundary;
