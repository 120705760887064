import React, { useContext, useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';

import useStyles from './styles';
import { InteraccionTarjeta } from './InteraccionTarjeta';
import { TarjetaInterface } from '../../resources/interfaces/interfaceTarjeta';
import { AnimacionCarrusel } from '../../../animacionCarrusel/AnimacionCarrusel';
import { canalesDatos } from '../../../../canales/resources/interfaces/interfaceCanalesDatos';
import { formatToDayStart } from '../../../../../functions/functions';
import { useControlador } from '../../../../../hooks/useControlador/useControlador';
import { action } from '../../../../../hooks/useControlador/resources/enums/enumActions';
import {
  obtenerCanalesDatosByDispositivo,
  obtenerInfoDevicePlugin
} from '../../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { IconosTiposMedida } from '../../../iconosTiposMedida/IconosTiposMedida';
import { rutasServicios } from '../../../../../resources/enums/enumRutasServicios';
import { dispositivo } from '../../../../dispositivos/resources/interfaces/interfaceDispositivo';
import ControlContext from '../../../../../context/control/ControlContext';
import { Modulos } from '../../../../../resources/enums/enumModulo';
import { VideovigilanciaTarjeta } from '../../../../videovigilancia/VideovigilanciaTarjeta';
import { CodigoDispositivo } from '../../../../../resources/enums/enumCodigoDispositivo';
import { formatearValor } from '../../functions/functions';
import { device } from '../../../../device/resources/interfaces/interfaceDevice';

interface canalesDatosDispositivo {
  dispositivo: dispositivo;
  canales: canalesDatos[];
}

export function DatosDispositivoTarjetas({
  datosTarjeta,
  tieneAcceso,
  updateCanales
}: {
  datosTarjeta: TarjetaInterface;
  tieneAcceso: boolean;
  updateCanales: boolean;
}): JSX.Element {
  const classes = useStyles();
  // useContext
  const { moduloSeleccionado } = useContext(ControlContext);
  // useStates
  const [canales, setCanales] = useState<canalesDatos[]>();
  // si el device está desactivado, no mostramos las interacciones
  const [showInteraccion, setShowInteraccion] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const { controllerRequest } = useControlador();

  // petición para obtener los canales
  const actionRequest: obtenerCanalesDatosByDispositivo = {
    type: action.OBTENER_CANALES_DATOS_BY_DISPOSITIVOS,
    payload: {
      servicio: rutasServicios.CANALES,
      idDispositivo: datosTarjeta.id
    }
  };
  // petición para obtener el device
  const actionRequestDevice: obtenerInfoDevicePlugin = {
    type: action.OBTENER_INFO_DEVICE_PLUGIN,
    payload: { servicio: rutasServicios.DEVICES, idDevice: datosTarjeta.idDevice as number }
  };

  useEffect(() => {
    setIsLoading(true);
    const cargarDatos = async (): Promise<void> => {
      const res: canalesDatosDispositivo = await controllerRequest(actionRequest);
      const datosCanales = res.canales.map((canal) => {
        return {
          ...canal,
          fechaMedida: formatToDayStart(canal.fechaMedida),
          valorMostrar: formatearValor(canal.valor)
        };
      });

      setCanales(datosCanales);
      setIsLoading(false);
      if (datosTarjeta.idDevice) {
        const device: device = await controllerRequest(actionRequestDevice);
        setShowInteraccion(device.activo == 1 ? true : false);
      } else if (datosTarjeta.idDevice === null) {
        setShowInteraccion(true);
      }
    };
    // solo cuando la variable updateCanales cambie, se realizará la petición para obtener canales.
    cargarDatos();

    return () => {
      setIsLoading(true);
    };
  }, [datosTarjeta, updateCanales]);

  return (
    <>
      {!isLoading ? (
        <>
          {moduloSeleccionado === Modulos.VIDEOVIGILANCIA &&
          datosTarjeta.codigoTipoDispositivo !== CodigoDispositivo.VIDEOGRABADOR ? (
            <VideovigilanciaTarjeta datosTarjeta={datosTarjeta} />
          ) : (
            <></>
          )}
          <div className={classes.canalesIteraccion}>
            {canales && canales.length > 0 ? (
              <List className={classes.lista}>
                <AnimacionCarrusel openAnimation={isLoading} />
                {isLoading ? (
                  <></>
                ) : (
                  canales.map((canal: canalesDatos) => (
                    <ListItem key={canal.id} className={classes.listItemPadding}>
                      <Tooltip
                        title={canal.nombre}
                        placement="left"
                        leaveDelay={500}
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                      >
                        <ListItemIcon>
                          <IconosTiposMedida icono={canal.codigoMedida} nombre={canal.nombre} />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <>
                            {canal.valorMostrar}
                            {` ${canal.nombreUnidad}`}
                          </>
                        }
                        secondary={canal.fechaMedida}
                      />
                    </ListItem>
                  ))
                )}
              </List>
            ) : (
              <></>
            )}
            {tieneAcceso && canales && showInteraccion ? (
              <InteraccionTarjeta datosTarjeta={datosTarjeta} canales={canales} />
            ) : (
              <></>
            )}
          </div>{' '}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
