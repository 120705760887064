import * as React from 'react';
import { DispositivoManager } from '../../../../../../dispositivos/DispositivoManager';
import DescriptionIcon from '@mui/icons-material/Description';
import useStyles from '../../styles';
import { IconButton, Tooltip } from '@mui/material';
import { TarjetaInterface } from '../../../../resources/interfaces/interfaceTarjeta';
import ControlContext from '../../../../../../../context/control/ControlContext';
import { dictionary_dispositivos } from '../../../../../../../resources/enums/plainText';
import { enumComponentes } from '../../../../../../../resources/enums/enumComponente';
import { DocumentManager } from '../../../../../../documentManager/components/documentManager/DocumentManager';
import { dispositivo } from '../../../../../../dispositivos/resources/interfaces/interfaceDispositivo';

export function InteraccionDocumental(props: { datosTarjeta: TarjetaInterface }): JSX.Element {
  const classes = useStyles();
  const { setControl } = React.useContext(ControlContext);
  return (
    <Tooltip
      title={dictionary_dispositivos.GESTOR_DOCUMENTAL}
      className={classes.tooltip}
      placement="top"
      arrow
    >
      <IconButton
        color="secondary"
        aria-label="configuración dispositivo"
        style={{ padding: '0 6px 0 6px' }}
        onClick={() =>
          setControl(
            <DocumentManager
              dispositivo={props.datosTarjeta as dispositivo}
              key={enumComponentes.ADMINISTRACION}
            />,
            true
          )
        }
      >
        <DescriptionIcon className={classes.botones_header} />
      </IconButton>
    </Tooltip>
  );
}
