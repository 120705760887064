import React, { useState, useRef, useContext } from 'react';
import { Fab, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import useStyles from './styles';
import { dispositivo, objectDispositivo } from './resources/interfaces/interfaceDispositivo';
import { DispositivoManager } from './DispositivoManager';
import PaginationRounded from '../common/table/Paginacion';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import {
  BotonTablaFilaEditar,
  BotonTablaFilaEliminar,
  BotonTablaFilaGenerico,
  BotonTablaFilaUbicacion
} from '../common/table/BotonesTablaFila';
import { DialogoMapa } from '../common/dialogoMapa/DialogoMapa';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { TablaDispositivosComun } from '../common/tablaDispositivosComun/TablaDispositivosComun';
import { enumTabla } from '../common/table/resources/enumTabla';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import ControlContext from '../../context/control/ControlContext';
import { administracion } from '../../resources/enums/enumAdministracion';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { dictionary_dispositivos } from '../../resources/enums/plainText';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { InteraccionDocumental } from '../common/gis/components/tarjetaMarcador/components/interaccion/InteraccionDocumental';
import { buscarRegistro } from '../common/table/buscarRegistro';
import DescriptionIcon from '@mui/icons-material/Description';
import { DocumentModuleManager } from '../documentManager/components/documentModuleManager/DocumentModuleManager';

export function TablaDispositivos({
  dispositivos,
  setDispositivos,
  acceso
}: {
  dispositivos: dispositivo[];
  setDispositivos: (dispositivo: dispositivo[]) => void;
  acceso: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { moduloSeleccionado, setControl } = useContext(ControlContext);
  const theme = useTheme();
  const [ubicacionMapa, setUbicacionMapa] = useState<dispositivo>(objectDispositivo());
  const [openDialogoMapa, setOpenDialogoMapa] = useState<boolean>(false);
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  function confirmarEliminarAlert(): void {
    confirmarEliminar(dispositivos, setDispositivos, rutasServicios.DISPOSITIVOS);
  }

  const columns = [
    {
      name: '',
      label: 'Ubicación',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          return (
            <div key={enumTabla.BOTONES_TABLA}>
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaUbicacion
                    datosTabla={tableMeta}
                    handleOpenUbicacion={handleOpenUbicacion}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </div>
          );
        }
      }
    },
    {
      name: '',
      label: 'Documentación',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          return (
            <div key={enumTabla.BOTONES_TABLA}>
              <ContenedorElementos
                elemento={
                  <InteraccionDocumental
                    datosTarjeta={
                      buscarRegistro(
                        tableMeta.tableData,
                        tableMeta.currentTableData[tableMeta.rowIndex].index
                      ).objeto
                    }
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </div>
          );
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return acceso ? (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={administracion.DISPOSITIVOS}
                    moduloSeleccionado={moduloSeleccionado}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          ) : (
            <></>
          );
        }
      }
    }
  ];

  const optionsAcceso = {
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (dispositivos.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <DispositivoManager
            dispositivoSeleccionado={dispositivos[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_DISPOSITIVO}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((d) => dispositivos[d.dataIndex]));
    }
  };

  const optionsSinAcceso = {
    selectableRows: 'none',
    selectableRowsHeader: false
  };

  const options = {
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    },
    customToolbar: () => (
      <Tooltip title={dictionary_dispositivos.GESTOR_DOCUMENTAL_GENERAL}>
        <IconButton
          onClick={() =>
            setControl(<DocumentModuleManager key={enumComponentes.ADMINISTRACION} />, true)
          }
          sx={{ marginLeft: '10px' }}
        >
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
    )
  };

  Object.assign(options, acceso ? optionsAcceso : optionsSinAcceso);

  const handleOpenUbicacion = (dispositivo: dispositivo): void => {
    setUbicacionMapa(dispositivo);
    setOpenDialogoMapa(true);
  };

  return (
    <>
      <TablaDispositivosComun
        title={enumComponentes.DISPOSITIVOS}
        columns={columns}
        options={options}
        dispositivos={dispositivos}
      ></TablaDispositivosComun>
      {acceso ? (
        <Tooltip title={dictionary_dispositivos.CREAR_DISPOSITIVO} arrow>
          <Fab
            tabIndex={0}
            color="secondary"
            aria-label="add"
            className={classes.fabBoton}
            onClick={() =>
              setControl(<DispositivoManager key={enumComponentes.CREAR_DISPOSITIVO} />)
            }
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      ) : (
        <></>
      )}

      <DialogoMapa
        dispositivoUbicacion={ubicacionMapa}
        open={openDialogoMapa}
        setOpen={setOpenDialogoMapa}
      />
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </>
  );
}
