import React from 'react';
import { EtiquetasCRU } from './EtiquetasCRU';
import { etiqueta } from './resources/interfaces/interfaceEtiquetas';

export function EtiquetasManager(props: { etiquetaSeleccionada?: etiqueta }): JSX.Element {
  return (
    <>
      {props.etiquetaSeleccionada !== undefined ? (
        <EtiquetasCRU etiquetaSeleccionada={props.etiquetaSeleccionada} />
      ) : (
        <EtiquetasCRU />
      )}
    </>
  );
}
