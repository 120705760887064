import { canalesRespuesta } from './interfaceCanalesRespuesta';
/**
 * @alias Canal
 * @typedef Canal
 * @description Esta interfaz es la composición que debe llevar el objeto canales para crear y para editar
 * @property {canal} canal objeto canal.
 * @property {number} idTipoMedida id del tipo de medida
 * @property {number} idDispositivo id del dispositivo.
 */

export interface canales {
  canal: {
    nombre: string;
    activo: boolean | number;
    idTipoUnidad: number;
    idTipoMedida: number;
    idDispositivo: number;
    idSection: number;
  };
}

//Siempre que modifique va a ser del tipo canalesRespuesta y cuando creamos por parámetro no llega nada
export const objectCanal = (elemento?: canalesRespuesta): canales => {
  let canalObj: canales;

  elemento === undefined
    ? (canalObj = {
        canal: {
          nombre: '',
          activo: 1,
          idTipoUnidad: 0,
          idTipoMedida: 0,
          idDispositivo: 0,
          idSection: 0
        }
      })
    : (canalObj = {
        canal: {
          nombre: elemento.nombre,
          activo: elemento.activo,
          idTipoUnidad: elemento.idTipoUnidad as number,
          idTipoMedida: elemento.idTipoMedida as number,
          idDispositivo: elemento.idDispositivo as number,
          idSection: elemento.idSection as number
        }
      });
  return canalObj;
};
