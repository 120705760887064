import React, { useEffect } from 'react';
import { Collapse, Grid, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useStyles from './styles';
import { InteraccionTipoDispositivo } from './components/interaccion/InteraccionTipoDispositivo';
import { InteraccionDevice } from './components/interaccion/InteraccionDevice';
import { useReducerInteraccion } from './components/interaccion/hooks/useReducerInteraccion';
import { TarjetaInterface } from '../../resources/interfaces/interfaceTarjeta';
import { AnimacionCarrusel } from '../../../animacionCarrusel/AnimacionCarrusel';
import { section } from '../../../../sections/resources/interfaces/interfaceSection';
import { canalesDatos } from '../../../../canales/resources/interfaces/interfaceCanalesDatos';
import { dictionary_generic } from '../../../../../resources/enums/plainText';
import { rutasServicios } from '../../../../../resources/enums/enumRutasServicios';
import { useControlador } from '../../../../../hooks/useControlador/useControlador';
import { sectionsByDevice } from '../../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { action } from '../../../../../hooks/useControlador/resources/enums/enumActions';

/**
 * Las interacciones que podemos hacer en la tarjeta al respecto de cada dispositivo.
 * Si el dispositivo seleccionado no tiene asociado un device se mostrará
 * las acciones genericas según su tipoDispositivo. Y en caso de tener asociado un device, este tendrá sections
 * y las acciones que puede realizar el dispositivo corresponde con las sections asociadas al device.
 * @param props
 */
export function InteraccionTarjeta(props: {
  datosTarjeta: TarjetaInterface;
  canales: canalesDatos[];
}): JSX.Element {
  const classes = useStyles();
  const { controllerRequest } = useControlador();
  const actionRequest: sectionsByDevice = {
    type: action.SECTION_BY_DEVICE,
    payload: { servicio: rutasServicios.SECTIONS, idDevice: props.datosTarjeta.idDevice as number }
  };
  const {
    openCloseTransition,
    endTransition,
    doingTransition,
    sections,
    isLoading,
    handleEnter,
    handleOpenCloseTransition,
    handleEndedTransition,
    handleExit
  } = useReducerInteraccion();

  /**
   * Función que examina las sections para establecer el valor real que será indicada en los valores por defecto de las interacciones.
   * Por ejemplo, la section setBrillo (que crea un componente Dimmer) necesita el valor del canal Brillo asociada a la section brillo
   * para que al crear el componente Dimmer tenga como valor inicial el valor del canal.
   * @param {section[]} sections sections del dispositivo que serán examinadas para establecer el valor real correspondiente.
   * @param {canalesDatos[]} canales canales del dispositivo que tienen el valor real del dispositivo.
   * @return {section[]} devuelve las sections modificadas.
   */
  const setValorReal = (sections: section[], canales: canalesDatos[]): section[] => {
    const newSections: section[] = sections;
    if (canales.length > 0 && sections.length > 0) {
      for (const canal of canales) {
        // primero buscamos el indice de la section asociada al canal que tiene un valor
        const indiceSectionDato = sections.findIndex((section) => section.id === canal.idSection);
        // si existe esa section, mediante section.valor, buscamos la section (de tipo set) que está relacionada a esa section
        if (indiceSectionDato !== -1) {
          const indiceSectionModificar = sections.findIndex(
            (section) => section.valor === sections[indiceSectionDato].nombre
          );
          // si existe, finalmente, esa section (de tipo set) la sustituimos por la misma section más el parámetro valorReal que contendrá el valor del canal
          if (indiceSectionModificar !== -1) {
            newSections.splice(indiceSectionModificar, 1, {
              ...newSections[indiceSectionModificar],
              valorReal: canal.valor
            });
          }
        }
      }
    }
    return newSections;
  };

  useEffect(() => {
    // si el dispositivos esta asociado a un device, obtenemos las sections
    const obtenerSections = async () => {
      const sectionsObtenidas: section[] = await controllerRequest(actionRequest);

      // primero buscamos entre los canales los valores reales del dispositivo, para indicar el valor por defecto en las interacciones
      const sectionModificadas: section[] = setValorReal(sectionsObtenidas, props.canales);
      // ahora filtramos según las sections que tienen path (que son interactuables) y que están activas, además de
      // dividir según las sections que serán visibles y las que no lo serán (estas ultimas dentro de una transición)
      const sectionsVer: section[] = [];
      const sectionsNoVer: section[] = [];
      for (const section of sectionModificadas) {
        if (section.activo === 1 && section.path !== '')
          section.siempreVisible === 1 ? sectionsVer.push(section) : sectionsNoVer.push(section);
      }

      handleEnter({ sectionsVisibles: sectionsVer, sectionsOcultas: sectionsNoVer });
    };
    // solo cuando se haya hecho la petición de obtener canales, se hará la petición de obtener sections
    if (props.datosTarjeta.idDevice && props.canales) obtenerSections();

    return () => {
      handleExit();
    };
  }, [props.canales]);

  return (
    <>
      {props.datosTarjeta.idDevice === undefined || props.datosTarjeta.idDevice === null ? (
        <InteraccionTipoDispositivo datosTarjeta={props.datosTarjeta} />
      ) : sections.sectionsVisibles.length > 0 ? (
        <>
          <AnimacionCarrusel openAnimation={isLoading} />
          <InteraccionDevice
            sections={sections.sectionsVisibles}
            idDevice={props.datosTarjeta.idDevice as number}
          />
        </>
      ) : (
        <></>
      )}
      {sections.sectionsOcultas.length > 0 ? (
        <>
          <Collapse
            in={openCloseTransition}
            timeout={500}
            onEntered={handleEndedTransition}
            onExited={handleEndedTransition}
          >
            <>
              <AnimacionCarrusel openAnimation={isLoading} />
              <InteraccionDevice
                sections={sections.sectionsOcultas}
                idDevice={props.datosTarjeta.idDevice as number}
              />
            </>
          </Collapse>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.gridTansition}
          >
            <Typography className={classes.titleTransition}>
              {!endTransition ? dictionary_generic.MAS_OPCIONES : dictionary_generic.MENOS_OPCIONES}
            </Typography>
            <IconButton
              aria-label="opciones"
              className={classes.buttonTransition}
              onClick={handleOpenCloseTransition}
              disabled={doingTransition}
            >
              {!endTransition ? (
                <ExpandMoreIcon
                  aria-label={dictionary_generic.MAS_OPCIONES}
                  className={classes.iconTransition}
                />
              ) : (
                <ExpandLessIcon
                  aria-label={dictionary_generic.MENOS_OPCIONES}
                  className={classes.iconTransition}
                />
              )}
            </IconButton>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
