import React from 'react';
import { TarjetaInterface } from '../common/gis/resources/interfaces/interfaceTarjeta';
import useStyles from './styles';
import { Button, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import LaunchIcon from '@mui/icons-material/LaunchOutlined';
import DialogVideo from './DialogVideo';
import useReducerVideo from './hooks/useReducerVideo/useReducerVideo';
import { DispositivoVideo } from './resources/interfaces/interfaceDispositivoVideo';
import { VideoControls } from './videoControls/VideoControls';
import { dictionary_cctv } from '../../resources/enums/plainText';

/**
 * Componente que muestra el video en directo del dispositivo en la Tarjeta
 * @param { TarjetaInterface } datosTarjeta datos del dispositivo
 * @returns { JSX.Element } Devuelve el componente
 */
export function VideovigilanciaTarjeta(props: { datosTarjeta: TarjetaInterface }): JSX.Element {
  const classes = useStyles();
  const [openVideo, setOpenVideo] = React.useState<boolean>(false);
  const dispositivoVideo: DispositivoVideo = {
    idDevice: props.datosTarjeta.idDevice as number,
    nombre: props.datosTarjeta.nombre
  };
  const videoTarjeta = useReducerVideo({ datosVideo: dispositivoVideo, isDialogoVideo: false });

  return (
    <>
      {videoTarjeta.state.idDevice > 0 ? (
        <>
          <List aria-label="videovigilancia-tarjeta">
            <div id="video" className={classes.root}>
              <div className={classes.resolucionTarjeta}>
                <ListItem key="video-stream">
                  <VideoControls state={videoTarjeta.state} events={videoTarjeta.events} />
                </ListItem>
              </div>
            </div>
            {videoTarjeta.state.isPlaying ? (
              <Tooltip title="Abrir" placement="left">
                <Button onClick={() => setOpenVideo(true)} className={classes.buttonVideo}>
                  <ListItem key="abrir-video-stream">
                    <ListItemIcon>
                      <LaunchIcon color="secondary" className={classes.iconAbrir} />
                    </ListItemIcon>
                    <ListItemText primary={dictionary_cctv.ABRIR} color="primary" />
                  </ListItem>
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
            {openVideo ? (
              <DialogVideo datosVideo={dispositivoVideo} open={openVideo} setOpen={setOpenVideo} />
            ) : (
              <></>
            )}
          </List>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
