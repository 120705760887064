/**
 * @alias Dispositivo
 * @typedef Dispositivo
 * @description Interfaz de etiqueta dispositivo, tiene más datos de los que proporciona la base de datos.
 * @property { number } id id del dispositivo
 * @property { string } nombre nombre del dispositivo
 * @property { number } longitud coordenadas longitud
 * @property { number } latitud coordenadas latitud
 * @property { number } idTipoDispositivo el id del tipo dispositivo correspondiente
 * @property { number| boolean } activo indica si está activo o no
 * @property { Date | string } fechaCreacion opcional* cuando se creo el dispositivo
 * @property { number } idModulo opcional* el id del modulo correspondiente al dispositivo
 * @property { string } icono opcional* icono del dispositivo
 * @property { string } modulo  nombre del modulo al que corresponde el dispositivo
 * @property { string } tipoDispositivo opcional*  nombre del tipo de dispositivo
 * @property { string } grupoDispositivo opcional*  nombre del grupo de dispositivo
 * @property { string } etiquetas opcional*  nombre de las etiquetas del dispositivo
 * @property { string } codigoTipoDispositivo opcional* codigo del tipo de dispositivo
 * @property { number } idDevice id del device asociado
 * @property { string } nombreDevice el nombre del device asociado
 * @property { () => void) } onClick opcional*  cuando se hace click en un dispositivo en el mapa.
 * @property { number } idTipoAlerta id del tipo de alerta que tiene el dispositivo
 * @property { string } nombreTipoAlerta nombre del tipo de alerta que tiene el dispositivo
 * @property { string } fechaAlerta fecha de la alerta que tiene el dispositivo
 * @property { string } mensajeAlerta mensaje de la alerta que tiene el dispositivo
 * @property { string } iconoAlerta icono de la alerta que tiene el dispositivo
 */
export interface dispositivo {
  id?: number;
  nombre: string;
  longitud: number;
  latitud: number;
  activo: boolean | number;
  fechaCreacion?: Date | string;
  marca?: string;
  modelo?: string;
  idTipoDispositivo: number;
  nSerie?: string;
  idModulo: number;
  imagen?: any;
  direccion?: string;
  icono?: string;
  modulo?: string;
  tipoDispositivo?: string;
  grupoDispositivo?: string;
  etiquetas?: string;
  codigoTipoDispositivo?: string;
  idDevice?: number | null;
  nombreDevice?: string;
  fechaAlerta?: string;
  mensajeAlerta?: string;
  idTipoAlerta?: number | null;
  nombreTipoAlerta?: string;
  iconoAlerta?: string | null;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const objectDispositivo = (elemento?: dispositivo, idModulo = 1): dispositivo => {
  let dispositivo: dispositivo;
  elemento === undefined
    ? (dispositivo = {
        nombre: '',
        longitud: 0,
        latitud: 0,
        direccion: '',
        activo: 1,
        idTipoDispositivo: 1,
        idModulo: idModulo,
        idDevice: null
      })
    : (dispositivo = {
        id: elemento.id,
        nombre: elemento.nombre,
        longitud: elemento.longitud,
        latitud: elemento.latitud,
        direccion: elemento.direccion,
        activo: elemento.activo,
        idTipoDispositivo: elemento.idTipoDispositivo,
        idModulo: elemento.idModulo,
        idDevice: elemento.idDevice
      });

  return dispositivo;
};
