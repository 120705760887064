import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  Box,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Grid } from '@mui/material';
import moment from 'moment';
import { TabPanel } from './TabPanel';
import { CollapseTable } from './CollapseTable';
import { Events } from '../hooks/useReducerDevice/resources/types/typeEvents';
import { tipoDeviceConfiguracion } from '../resources/interfaces/interfaceTipoDeviceConfiguracion';
import { HourCalendar } from './HourCalendar';
import { State } from '../hooks/useReducerDevice/resources/types/typeState';

export function WeeklyCalendarParam({
  param,
  state,
  events
}: {
  param: tipoDeviceConfiguracion;
  state: State;
  events: Events;
}): JSX.Element {
  const [open, setOpen] = React.useState('');
  const [tabValue, setTabValue] = React.useState(0);
  const [valves, setValves] = React.useState([
    { name: 'Válvula 1', valveState: false, valveHex: '01', id: '00,01' },
    { name: 'Válvula 2', valveState: false, valveHex: '02', id: '00,01' },
    { name: 'Válvula 3', valveState: false, valveHex: '04', id: '00,01' },
    { name: 'Válvula 4', valveState: false, valveHex: '08', id: '00,01' }
  ]);

  const hexCalendar = React.useRef<Array<string>>(['', '', '', '']);

  function hex2bin2(hex) {
    return parseInt(hex, 16).toString(2).padStart(8, '0').split('').reverse().join('');
  }

  const date = moment('2023-05-01 00:00');
  const arrDates = ['00:00 - 08:00', '08:00 - 16:00', '16:00 - 00:00'];

  function splitString(str, N) {
    let result = '';
    for (let i = 0; i < str.length; i += N) {
      result += hex2bin2(str.substring(i, i + N));
    }

    return result;
  }

  const initCalendar = React.useMemo(() => {
    const defaultCalendar = valves.map(() => {
      const init: Array<{ [key: string]: any; index: number; state: boolean }> = [];
      for (let i = 0; i < 96; i++) {
        init.push({
          index: i,
          hour: `${date.format('HH:mm')} - ${date.add(15, 'm').format('HH:mm')}`,
          state: false
        });
      }
      init.push({ index: 96, day: 'L', state: false });
      init.push({ index: 97, day: 'M', state: false });
      init.push({ index: 98, day: 'X', state: false });
      init.push({ index: 99, day: 'J', state: false });
      init.push({ index: 100, day: 'V', state: false });
      init.push({ index: 101, day: 'S', state: false });
      init.push({ index: 102, day: 'D', state: false });
      return init;
    });

    if (state.deviceModificar?.configuracion) {
      const calendarConfiguration: string = state.deviceModificar?.configuracion.find(
        (c) => c.configuracion === 'weeklyCalendar'
      )?.valor as string;

      if (calendarConfiguration?.length > 1) {
        const parsedJSONCalendar = JSON.parse(calendarConfiguration);
        const newValves = [...valves];
        valves.forEach((item, index) => {
          const valveHexCalendar: string = parsedJSONCalendar[index];
          if (valveHexCalendar.length) {
            newValves[index].valveState = true;
            const id = valveHexCalendar.slice(0, 4);
            const dec = parseInt(id, 16) + 1;
            const hex = ('000' + dec.toString(16)).slice(-4).replace(/(?<=^.{2})/, ',');
            newValves[index].id = hex;

            const calendarBytes = valveHexCalendar.slice(6);
            const binValues = splitString(calendarBytes, 2);
            defaultCalendar[index].forEach((item) => {
              item.state = binValues[item.index] == '1' ? true : false;
            });
          }
        });

        setValves(newValves);
      }
    }

    return defaultCalendar;
  }, []);

  const [calendar, setCalendar] = useState(initCalendar);
  const days = calendar[tabValue].slice(96);
  const hourcalendar = React.useMemo(() => {
    const arr: any[] = [];
    let start = 0;
    let end = 4;
    for (let i = 0; i < 24; i++) {
      const rangeValues = calendar[tabValue].slice(start, end);
      const rowState = !rangeValues.some((item) => item.state === false);
      arr.push({
        date: `${date.format('HH:mm')} - ${date.add(1, 'h').format('HH:mm')}`,
        range: rangeValues,
        rowState
      });
      start += 4;
      end += 4;
    }
    return arr;
  }, [calendar, tabValue]);

  function calendarToHex(
    newCalendar: Array<Array<{ [key: string]: any; index: number; state: boolean }>>
  ) {
    const arranging = newCalendar[tabValue].map((e: any) => (e.state ? 1 : 0));
    const hexArray: string[] = [];
    for (let i = 0; i < 103; i += 8) {
      const byte = arranging
        .slice(i, i + 8)
        .reverse()
        .toString()
        .replaceAll(',', '');
      hexArray.push(('00' + parseInt(byte, 2).toString(16)).slice(-2));
    }
    hexCalendar.current[tabValue] = `${valves[tabValue].id},${
      valves[tabValue].valveHex
    },${hexArray.toString()}`;

    events.handleParametroChange(
      param.id as number,
      JSON.stringify(hexCalendar.current.map((item) => item.replaceAll(',', '')))
    );
    setCalendar(newCalendar);
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleValveState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.currentTarget.checked;
    const newValue = [...valves];
    newValue[tabValue].valveState = check;

    setValves(newValue);
    if (hexCalendar.current[tabValue] && check === false) {
      hexCalendar.current[tabValue] = '';
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newCalendar = [...calendar];
    const check = event.currentTarget.checked;
    newCalendar[tabValue][index].state = check;
    calendarToHex(newCalendar);
  };

  const handleRowCheckboxChange = (
    row: { date: string; range: Array<{ index: number; hour: string; state: boolean }> },
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const check = event.currentTarget.checked;
    const newCalendar = [...calendar];
    row.range.forEach((item) => (newCalendar[tabValue][item.index].state = check));

    calendarToHex(newCalendar);
  };

  React.useEffect(() => {
    return () => setOpen('');
  }, [tabValue]);

  return (
    <Grid item md={12}>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {valves.map(({ name }) => (
            <Tab label={name} key={name} />
          ))}
        </Tabs>
        {valves.map(({ name }, i) => (
          <TabPanel value={tabValue} index={i} key={name}>
            <FormControlLabel
              control={
                <Checkbox checked={valves[tabValue].valveState} onChange={handleValveState} />
              }
              label="Activar calendario"
            />
            {valves[tabValue].valveState ? (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      {arrDates.map((e, index) => (
                        <CollapseTable title={e} key={index} open={open} setOpen={setOpen}>
                          <HourCalendar
                            hourcalendar={hourcalendar}
                            handleCheckboxChange={handleCheckboxChange}
                            handleRowCheckboxChange={handleRowCheckboxChange}
                            index={index}
                          />
                        </CollapseTable>
                      ))}
                    </TableHead>
                  </Table>
                </TableContainer>
                {days.map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.state}
                        onChange={(evt) => handleCheckboxChange(evt, item.index)}
                      />
                    }
                    key={item.day}
                    label={item.day}
                    sx={{ paddingRight: '8px' }}
                  />
                ))}
              </>
            ) : (
              <></>
            )}
          </TabPanel>
        ))}
      </Box>
    </Grid>
  );
}
