import React, { Dispatch, SetStateAction } from 'react';
import { Card } from '@mui/material';
import { TarjetaInterface } from '../../resources/interfaces/interfaceTarjeta';
import { Tarjeta } from './Tarjeta';
import useStyles from './styles';
import { enumComponentes } from '../../../../../resources/enums/enumComponente';

/**
 * La tarjeta que se muestra al seleccionar un dispositivo en el GIS
 * @param props
 * @param {TarjetaInterface} props.datos Los datos que mostrará la tarjeta
 * @param {MutableRefObject<HTMLDivElement>} props.popper El componente que muestra u oculta la tarjeta, necesario para cerrar.
 */
export function InteraccionDesplegable(props: {
  datosTarjeta: TarjetaInterface;
  setDatosTarjeta: Dispatch<SetStateAction<TarjetaInterface>>;
  tieneAcceso: boolean;
  comprobarAcceso: (key: enumComponentes) => boolean;
  abrirCerrarTarjeta: () => void;
}): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root} elevation={0}>
        {props.datosTarjeta.id !== 0 ? (
          <>
            <Tarjeta
              datosTarjeta={props.datosTarjeta}
              setDatosTarjeta={props.setDatosTarjeta}
              tieneAcceso={props.tieneAcceso}
              comprobarAcceso={props.comprobarAcceso}
              abrirCerrarTarjeta={props.abrirCerrarTarjeta}
            />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}
