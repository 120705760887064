import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { MapaSimple } from '../mapaSimple/MapaSimple';
import { MarcadorInterface } from '../gis/resources/interfaces/interfaceMarcador';

export function DialogoMapa(props: {
  dispositivoUbicacion: dispositivo;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const handleClose = () => {
    props.setOpen(false);
  };

  const marcador: MarcadorInterface = {
    id: props.dispositivoUbicacion.id as number,
    lat: props.dispositivoUbicacion.latitud,
    lng: props.dispositivoUbicacion.longitud,
    nombre: props.dispositivoUbicacion.nombre,
    tipo: props.dispositivoUbicacion.tipoDispositivo as string,
    icono: props.dispositivoUbicacion.icono as string
  };
  return (
    <>
      {props.dispositivoUbicacion ? (
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="dialogo-ubicacion-dispositivo"

          // disableEscapeKeyDown={true}
          // maxWidth="md"
          // fullWidth={true}
        >
          <DialogTitle>
            {'Ubicación del Dispositivo: ' + props.dispositivoUbicacion.nombre}
          </DialogTitle>
          <DialogContent>
            <MapaSimple marker={marcador} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
}
