/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useContext } from 'react';
import { dictionary_eliminar } from '../../resources/enums/plainText';
import { actualizarTabla } from '../../components/common/table/functions/actualizarTabla';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../context/SnackBarContext';
import { useControlador } from '../useControlador/useControlador';
import { action } from '../useControlador/resources/enums/enumActions';
/**
 * Hook que coniene las funciones relacionadas con todo el prceso de eliminar. La aparición del alert de confirmación y la eliminación de base de datos.
 * @param {React.MutableRefObject<any>} tituloAlertEliminar titulo que se va a mostrar en el alert de eliminar
 * @param {React.MutableRefObject<any>} textoAlertEliminar texto que se va a mostrar en el alert de eliminar
 * @param {React.MutableRefObject<any>} ocultarCancelarAlertEliminar boolean que sirve para ocultar el botón cancelar en caso de que ser quiera eliminar un elemento de solo lectura
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setOpenAlertEliminar cambiar el estado de abierto y cerrado del alert de eliminar
 */
export function useProcesoEliminar(
  tituloAlertEliminar: React.MutableRefObject<any>,
  setOpenAlertEliminar: React.Dispatch<React.SetStateAction<boolean>>,
  textoAlertEliminar?: React.MutableRefObject<any>,
  ocultarCancelarAlertEliminar?: React.MutableRefObject<any>
): {
  confirmarEliminar: (
    elementos: any,
    setElementos: React.Dispatch<any>,
    servicio: string
  ) => Promise<void>;
  eliminarUnoVariosElementos: (elementos: any) => void;
} {
  const elementoEliminar = useRef<Array<number>>([]);
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();

  /**
   * Función elimina un elemento después de confirmar en el alertDialog
   *
   * @param { any } elemento elementos de la tabla que se almacenan en la variale de estado del hook
   * @param { React.Dispatch<any> } setElementos el actualizador de estado del hook correspondiente
   * @param { string } servicio servicio correspondiente al que hay que llamar para eliminar el elemento
   */
  async function confirmarEliminar(
    elementos: any,
    setElementos: React.Dispatch<any>,
    servicio: string
  ): Promise<void> {
    const { actualizarTablaEliminar } = actualizarTabla(elementos, setElementos);

    if (elementoEliminar.current.length !== 0) {
      setOpenSmartTeliaBackdrop(true);
      await controllerRequest({
        type: action.ELIMINAR_REGISTROS,
        payload: { servicio: servicio, id: elementoEliminar.current }
      });
      //await eliminarRegistros(servicio, elementoEliminar.current);
      setOpenSmartTeliaBackdrop(false);
      if (completadoCorrectamente.current) {
        actualizarTablaEliminar(elementoEliminar.current);
      }
      setSnackBar({
        open: true,
        text: propiedadesSnackBar.current.texto,
        severity: propiedadesSnackBar.current.severity
      });
      elementoEliminar.current = [];
    }
  }

  /**
   * Función que abre el alert para confirmar la eliminación de un elemento o varios elementos
   * Si alguno de los elementos, tiene el campo soloLectura = 1, no será eliminado, avisando de ello
   *
   * @param { any }elementos elementos que queremos eliminar
   */
  function eliminarUnoVariosElementos(elementos: any): void {
    elementoEliminar.current = [];
    let isSoloLectura = false;
    if (textoAlertEliminar !== undefined) textoAlertEliminar.current = '';
    if (ocultarCancelarAlertEliminar !== undefined) ocultarCancelarAlertEliminar.current = false;

    if (elementos.length !== 0) {
      elementos.forEach((elemento: any) => {
        if (elemento.soloLectura !== 1) {
          elementoEliminar.current.push(elemento.id);
        } else {
          isSoloLectura = true;
        }
      });

      if (elementos.length > 1) {
        tituloAlertEliminar.current = dictionary_eliminar.TITULO_ALERT_ELIMINAR_PLURAL;
        if (isSoloLectura) {
          if (textoAlertEliminar !== undefined)
            textoAlertEliminar.current = dictionary_eliminar.TEXTO_ALERT_ELIMINAR_PLURAL;
        }
      } else {
        if (isSoloLectura) {
          tituloAlertEliminar.current = dictionary_eliminar.TITULO_ALERT_ELMINAR;
          if (ocultarCancelarAlertEliminar !== undefined)
            ocultarCancelarAlertEliminar.current = true;
        } else {
          tituloAlertEliminar.current = dictionary_eliminar.TEXTO_ALERT_ELIMINAR;
        }
      }

      setOpenAlertEliminar(true);
    }
  }

  return {
    confirmarEliminar,
    eliminarUnoVariosElementos
  };
}
