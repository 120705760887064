import React, { useEffect, useState } from 'react';
import { Divider, Tooltip, IconButton, List, ListItem, SvgIcon, Typography } from '@mui/material';
import { useStyles } from './styles';
import TarjetaPlugin from './TarjetaPlugin';
import { plugin } from '../resources/interfaces/interfacePlugin';
import { PluginsInstalados } from '../pluginsInstalados/PluginsInstalados';
import { pluginsModulo } from '../resources/interfaces/interfacePluginsModulo';
import ControlContext from '../../../context/control/ControlContext';
import UserContext from '../../../context/UserContext';
import { modulo } from '../../../resources/interfaces/interfaceModulo';
import { dictionary_plugins } from '../../../resources/enums/plainText';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { ReactComponent as iconBox } from '../../../resources/icons/iconsPlugins/box.svg';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { enumComponentes } from '../../../resources/enums/enumComponente';

/**
 * Componente que lista todos los plugins de la plataforma por módulos que tenga el cliente
 */
export function PluginsDisponibles(): JSX.Element {
  const classes = useStyles();
  const { controllerRequest } = useControlador();
  const { setControl } = React.useContext(ControlContext);
  const { datosUsuarioContext } = React.useContext(UserContext);
  const [pluginsPlataforma, setPluginsPlataforma] = useState<pluginsModulo[]>([]);
  let modulos: modulo[] = [];
  let plugins: plugin[] = [];
  if (datosUsuarioContext !== undefined) {
    modulos = datosUsuarioContext.modulos;
    plugins = datosUsuarioContext.plugins;
  }

  const comprobarVersiones = (versionCliente: string, version: string): boolean => {
    if (versionCliente === version) return true;
    const partialVersionCliente = versionCliente.split('.');
    const partialVersion = version.split('.');
    for (let i = 0; i < 3; i++) {
      if (partialVersion[i] > partialVersionCliente[i]) return false;
    }
    return true;
  };

  // Obtenemos todos los plugins del cliente para saber cuales están instalados o no segun los plugins de la PLATAFORMA,
  useEffect(() => {
    const p = async () => {
      const pluginsCliente: plugin[] = await controllerRequest(
        {
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.PLUGINS }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      );
      const pluginsModulo: pluginsModulo[] = [];
      // recorremos los plugins de la plataforma por modulo y averiguamos cuales tiene ya el cliente
      modulos.forEach((modulo: modulo) => {
        const pluginsFiltrados = plugins.filter((plugin: plugin) => plugin.idModulo === modulo.id);
        if (pluginsFiltrados.length !== 0) {
          pluginsFiltrados.map((plugin: plugin) => {
            const pluginCliente = pluginsCliente.find((p) => p.nombre === plugin.nombre);
            if (pluginCliente) {
              plugin.instalado = true;
              plugin.actualizado = comprobarVersiones(pluginCliente.version, plugin.version);
            } else {
              plugin.instalado = false;
            }
          });
          pluginsModulo.push({ nombreModulo: modulo.nombre, plugins: pluginsFiltrados });
        }
      });
      setPluginsPlataforma(pluginsModulo);
    };
    p();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.tituloDisponibles}>
        {dictionary_plugins.PLUGINS_DISPONIBLES}
        <Tooltip title={dictionary_plugins.PLUGINS_INSTALADOS} placement="right">
          <IconButton
            aria-label="icono-plugins"
            onClick={() =>
              setControl(
                <PluginsInstalados
                  key={enumComponentes.PLUGINS_INSTALADOS + dictionary_plugins.DISPONIBLE}
                />
              )
            }
          >
            <SvgIcon
              component={iconBox}
              viewBox="-50 0 600 500"
              className={classes.iconoDisponibles}
            />
          </IconButton>
        </Tooltip>
      </Typography>
      <List className={classes.lista}>
        <>
          {pluginsPlataforma.map((moduloConPlugins: pluginsModulo) => {
            return (
              <>
                <ListItem key={moduloConPlugins.nombreModulo}>
                  <Typography variant="h5">{moduloConPlugins.nombreModulo}</Typography>
                </ListItem>
                {moduloConPlugins.plugins.map((plugin: plugin) => {
                  return (
                    <ListItem key={plugin.nombre} className={classes.elemento_lista}>
                      <TarjetaPlugin plugin={plugin} />
                    </ListItem>
                  );
                })}
                <ListItem>
                  <Divider className={classes.divisor} />
                </ListItem>
              </>
            );
          })}
        </>
      </List>
    </div>
  );
}
