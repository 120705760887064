import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MapIcons } from '../iconos/Iconos';
import { TarjetaInterface, objectTarjeta } from '../../resources/interfaces/interfaceTarjeta';
import { IconosTiposMedida } from '../../../iconosTiposMedida/IconosTiposMedida';
import { ListItem } from '@mui/material';
import useStyles from './styles';
import { Settings } from './components/interaccion/Settings';
import { DatosDispositivoTarjetas } from './DatosDispositivoTarjeta';
import { AlertaTarjeta } from './AlertaTarjeta';
import { dictionary_generic } from '../../../../../resources/enums/plainText';
import { enumComponentes } from '../../../../../resources/enums/enumComponente';
import { GraficasTarjetaGIS } from './components/interaccion/GraficasTarjetaGIS';
import SyncIcon from '@mui/icons-material/Sync';
import ControlContext from '../../../../../context/control/ControlContext';
import { Modulos } from '../../../../../resources/enums/enumModulo';
import { InteraccionDocumental } from './components/interaccion/InteraccionDocumental';

export function Tarjeta(props: {
  datosTarjeta: TarjetaInterface;
  setDatosTarjeta: Dispatch<SetStateAction<TarjetaInterface>>;
  tieneAcceso: boolean;
  comprobarAcceso: (key: enumComponentes) => boolean;
  abrirCerrarTarjeta: () => void;
}): JSX.Element {
  const [updateCanales, setUpdateCanales] = useState<boolean>(false);
  const classes = useStyles();
  function cerrarBusqueda() {
    props.setDatosTarjeta(objectTarjeta());
    props.abrirCerrarTarjeta();
  }

  const { moduloSeleccionado } = React.useContext(ControlContext);
  // handler
  const handleUpdateCanales = () => {
    window.scrollTo(0, 0);
    setUpdateCanales(!updateCanales);
  };

  return (
    <>
      <CardContent tabIndex={0} accessKey="t" className={classes.contenido_final_tarjeta}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} alignItems={'center'} marginLeft={'15px'}>
            <MapIcons icono={props.datosTarjeta.icono} />
            <h3 className={classes.textTitulo}>{props.datosTarjeta.nombre}</h3>
          </Box>

          <Box display={'flex'} alignItems={'center'}>
            {props.comprobarAcceso(enumComponentes.ADMINISTRACION) && props.tieneAcceso ? (
              <>
                <Tooltip title={dictionary_generic.SINCRONIZAR} placement="top">
                  <IconButton onClick={handleUpdateCanales} className={classes.buttonSincronizar}>
                    <SyncIcon aria-label={dictionary_generic.SINCRONIZAR} color="secondary" />
                  </IconButton>
                </Tooltip>
                {moduloSeleccionado === Modulos.VIDEOVIGILANCIA ? (
                  <></>
                ) : (
                  <GraficasTarjetaGIS datosTarjeta={props.datosTarjeta} />
                )}
                <Settings datosTarjeta={props.datosTarjeta} />
                <InteraccionDocumental datosTarjeta={props.datosTarjeta} />
              </>
            ) : (
              <></>
            )}

            <IconButton
              aria-label="cerrar"
              onClick={cerrarBusqueda}
              className={classes.buttonCerrar}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <div className={classes.imagen}>
          <img alt="Imagen ubicacion" src={props.datosTarjeta.imagen}></img>
        </div>

        <CardContent className={classes.contenido_tarjeta}>
          {moduloSeleccionado === Modulos.VIDEOVIGILANCIA && (
            <AlertaTarjeta datosTarjeta={props.datosTarjeta} />
          )}

          <List className={classes.listUbicacion}>
            <ListItem className={classes.listItemPadding}>
              <Tooltip
                title={dictionary_generic.UBICACION}
                placement="left"
                leaveDelay={500}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
              >
                <ListItemIcon>
                  <IconosTiposMedida icono={'location'} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={props.datosTarjeta.direccion}
                secondary={`${props.datosTarjeta.latitud}, ${props.datosTarjeta.longitud}`}
                className={classes.tarjetaText}
              />
            </ListItem>
          </List>
          <Divider />
          <DatosDispositivoTarjetas
            datosTarjeta={props.datosTarjeta}
            tieneAcceso={props.tieneAcceso}
            updateCanales={updateCanales}
          />
        </CardContent>
      </CardContent>
    </>
  );
}
