import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

interface smartTeliaBackdropContext {
  openSmartTeliaBackdrop: boolean;
  setOpenSmartTeliaBackdrop: React.Dispatch<React.SetStateAction<boolean>>;
}

const Context = React.createContext<smartTeliaBackdropContext>({
  openSmartTeliaBackdrop: false,
  setOpenSmartTeliaBackdrop: useState
});

SmartTeliaBackdropContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

/**
 * Este contexto se encarga del estado del SmartTelia backdrop
 */
export function SmartTeliaBackdropContextProvider({
  children
}: InferProps<typeof SmartTeliaBackdropContextProvider.propTypes>): JSX.Element {
  const [openSmartTeliaBackdrop, setOpenSmartTeliaBackdrop] = useState<boolean>(false);

  return (
    <Context.Provider value={{ openSmartTeliaBackdrop, setOpenSmartTeliaBackdrop }}>
      {children}
    </Context.Provider>
  );
}
export default Context;
