/**
 * Este enum se usa para hacer las llamadas de servicios
 */

enum commonRoutes {
  REPORTS = 'reports',
  DISPOSITIVOS = 'dispositivos',
  DOCUMENT_MANAGER = 'gestorDocumental',
  CARD_CONFIGURATOR = 'configuradorTarjetas'
}

export enum rutasServicios {
  ALERTAS = 'alertas',
  ACCIONES = 'acciones',
  CANALES = 'canales',
  CONDICIONES = 'condiciones',
  CONDICIONES_COMPLETAS = 'condiciones/completa',
  CONFIGURACION = 'configuracion',
  DISPOSITIVOS = 'dispositivos',
  DEVICES = 'devices',
  ETIQUETAS = 'tags',
  ENERGIA = 'energia',
  GRUPOS = 'grupos',
  GRAFICAS = 'graficas',
  GESTION_AGUA_POTABLE = 'aguaPotable',
  INFORMES = 'reports',
  ILUMINACION_VIARIA = 'iluminacionViaria',
  MEDIO_AMBIENTE = 'medioAmbiente',
  OPERADORES = 'operador',
  PLANIFICADOR_PETICIONES = 'planificadorPeticiones',
  PLUGINS = 'plugins',
  PERFILES = 'perfiles',
  REGISTRAR_ERROR = 'registrarError',
  PARQUES_Y_JARDINES = 'parquesJardines',
  REGLAS = 'reglas',
  SECTIONS = 'sections',
  TIPOS_DISPOSITIVOS = 'tiposDispositivo',
  TIPOS_MEDIDAS = 'tiposMedidas',
  TIPOS_UNIDADES = 'tiposUnidades',
  TIPOS_CONDICIONES = 'tiposCondiciones',
  TIPOS_ACCIONES = 'tiposAcciones',
  TIPOS_DISPOSITIVO_ACTUACION = 'tiposDispositivo/todos/actuacion',
  TIPOS_DISPOSITIVOS_CON_DISPOSITIVO = 'tiposDispositivo/todos/dispositivo',
  TIPOS_ACTUACIONES = 'tiposActuaciones',
  TIPOS_DEVICES = 'tiposDevice',
  USUARIOS = 'usuarios'
}
// ¡¡ VARIABLE SENSIBLE A REFACTORIZACIÓN DE LOS SERVICIOS!!
export const serviceRoutes = {
  //INFORMES
  INFORME_CALIDAD_AGUA: `${commonRoutes.REPORTS}/parametrosAguaPotable/`,
  DETALLE_INFORME_CALIDAD_AGUA: `${commonRoutes.REPORTS}/detailParametrosAguaPotable/`,
  CONSUMPTION_REPORT: `${commonRoutes.REPORTS}/consumo/`,
  INFORME_PARAMETROS_RESIDUALES: `${commonRoutes.REPORTS}/parametrosResiduales/`,
  DETALLE_INFORME_PARAMETROS_RESIDUALES: `${commonRoutes.REPORTS}/detalleParametrosResiduales`,
  //DISPOSITIVOS
  OBTENER_TODOS_DISPOSITIVOS_POR_MODULO: `${commonRoutes.DISPOSITIVOS}/todos/modulo`,
  //GESTOR DOCUMENTAL
  GET_DOCUMENT_MANAGER: `${commonRoutes.DOCUMENT_MANAGER}/`,
  DOWNLOAD_DOCUMENT: `${commonRoutes.DOCUMENT_MANAGER}/download`,
  UPLOAD_DOCUMENTS: `${commonRoutes.DOCUMENT_MANAGER}/upload`,
  DELETE_DOCUMENT: `${commonRoutes.DOCUMENT_MANAGER}/delete`,
  CONSULT_TOTAL_SIZE: `${commonRoutes.DOCUMENT_MANAGER}/consultTotalSize`,
  GET_DOCUMENTS_BY_MODULE: `${commonRoutes.DOCUMENT_MANAGER}/module`,
  SAVE_DOCUMENT_MANAGER: `${commonRoutes.DOCUMENT_MANAGER}/guardar`,
  //CONFIGURADOR DE TARJETAS
  SAVE_CARD_CONFIGURATION: `${commonRoutes.CARD_CONFIGURATOR}/save`
};
