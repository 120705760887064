/**
 * Contiene 3 funciones, actualizarTablaCrear, actualizarTablaEliminar,actualizarTablaModificar, se encarga de actualizar los datos de la tabla en cada proceso.
 * Necesita como parámetros iniciales los datos de la tabla y el setDatosTabla para poder actualizar.
 * @param {any} datosTabla lista de elementos iniciales que se cargan en la tabla
 * @param {React.Dispatch<any>} setDatosTabla actualizador de los datos de la tabla se utilizará para cargar la nueva lista con los dos necesarios modificados
 * 
 * @return actualizarTablaEliminar - Función que actualiza las filas de la tabla después de eliminar un elemento
 */
export function actualizarTabla(datosTabla : any, setDatosTabla :  React.Dispatch<any>) : {actualizarTablaEliminar : (elementosEliminados : any ) => void} {

    /**
   * Esta función se encarga de actualizar la tabla al realizar un proceso de eliminación, ya sea masivo o individual.
   * @param {any} elementosEliminados Elementos que se han eliminado, uno o varios.
   */
    function actualizarTablaEliminar(elementosEliminados: any) : void {
    let listaElementos = datosTabla;
    if (elementosEliminados.length !== undefined) {
        for (let index = 0; index < elementosEliminados.length; index++) {
            const eliminarElementos = listaElementos.filter(
            (elemento: any) => elemento.id !== elementosEliminados[index]
        );
        listaElementos = eliminarElementos;
        setDatosTabla(listaElementos);
    }
    } else {
      const eliminarElementos = listaElementos.filter(
        (elemento: any) => elemento.id !== elementosEliminados
      );
      listaElementos = eliminarElementos;
      setDatosTabla(listaElementos);
    }
  }

  return {
      actualizarTablaEliminar
  }
}