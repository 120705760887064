import React from 'react';
import { DispositivoVideo } from '../resources/interfaces/interfaceDispositivoVideo';
import { Events } from '../hooks/useReducerVideo/resources/types/typeEvents';
import { State } from '../hooks/useReducerVideo/resources/types/typeState';
import useReducerVideo from '../hooks/useReducerVideo/useReducerVideo';
import { Video } from '../Video';
import { ControlPlayback } from './ControlPlayback';
import { ControlStream } from './ControlStream';

/**
 * Componente que muestra el video con sus respectivos controles (de playback o stream)
 * @param { State | undefined } state State del hook useReducerVideo
 * @param { Events | undefined } events Events del hook useReducerVideo
 * @param { DispositivoVideo | undefined } datosVideo en caso de no crear el hook, datos minimos
 * del dispositivo para su reproducción
 * @param { boolean | undefined } isDialogoVideo en caso de no crear el hook, indicar si estamos en
 * el dialogo de video
 * @returns { JSX.Element } Devuelve el componente
 */
export function VideoControls({
  state,
  events,
  datosVideo,
  isDialogoVideo
}: {
  state?: State;
  events?: Events;
  datosVideo?: DispositivoVideo;
  isDialogoVideo?: boolean;
}): JSX.Element {
  const reducer =
    state && events
      ? { state, events }
      : useReducerVideo({
          datosVideo: datosVideo,
          isDialogoVideo: isDialogoVideo
        });
  return (
    <>
      {!reducer.state.intervalTime ? (
        <ControlStream state={reducer.state} events={reducer.events} />
      ) : (
        <ControlPlayback state={reducer.state} events={reducer.events} />
      )}
      <Video state={reducer.state} events={reducer.events} />
    </>
  );
}
