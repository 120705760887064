/**
 * @description Estos actions enumeran los tipos de peticiones que se pueden hacer al servidor. Cada uno de ellos lleva el nombre identificativo de lo que realiza.
 */

export enum action {
  GUARDAR_RESPUESTA,
  OBTENER_CANALES_BY_DISPOSITIVOS,
  OBTENER_CANALES_DATOS_BY_DISPOSITIVOS,
  CAMBIAR_ESTADO_SECTION,
  OBTENER_TIPOS_DEVICE_BY_PLUGIN,
  OBTENER_PLUGIN_BY_NOMBRE,
  OBTENER_PLUGIN_BY_DEVICE,
  CAMBIAR_ESTADO_PLUGIN,
  OBTENER_CONFIGURACION_BY_TIPO_DEVICE,
  OBTENER_CONFIGURACION_VALOR_BY_DEVICE,
  DEVICES_BY_PLUGIN,
  SECTION_BY_DEVICE,
  CREAR_UN_REGISTRO,
  MODIFICAR_REGISTRO,
  ELIMINAR_REGISTROS,
  OBTENER_TODOS_DATOS,
  OBTENER_DATOS_ELIMINADOS,
  OBTENER_DATOS_BY_MODULO,
  OBTENER_DATOS_BY_ID,
  OBTENER_DATOS_SIN_MODULO,
  OBTENER_ALERTAS_BY_DISPOSITIVO,
  OBTENER_ALERTAS_BY_MODULO,
  OBTENER_ALERTAS_BY_TIPO_ALERTA,
  OBTENER_DISPOSITIVOS_CON_ALERTAS,
  OBTENER_ALERTAS_TIPO_ERROR,
  OBTENER_ALERTAS_SIN_RESOLVER,
  OBTENER_ALERTAS_SIN_RESOLVER_POR_MODULO,
  OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO,
  OBTENER_ELEMENTOS_POR_REGLA,
  OBTENER_TIPO_DISPOSITIVO_BY_DISPOSITIVO,
  OBTENER_DEVICES_BY_TIPO_DEVICE,
  OBTENER_SECTIONS_ESCRITURA_ACTIVAS,
  MODIFICAR_USUARIO,
  OBTENER_PERFILES_USUARIO,
  OBTENER_PERMISOS_USUARIO,
  MODIFICAR_PERMISOS_USUARIO,
  OBTENER_DATOS_USUARIO,
  OBTENER_CONSUMO_HOME,
  OBTENER_DISPOSITIVOS_MAYOR_CONSUMO,
  OBTENER_DISPOSITIVOS_GIS,
  OBTENER_DISPOSITIVO_ACTUACION_BY_TIPO_DISPOSITIVO,
  OBTENER_TIPOS_ACTUACIONES_POR_DISPOSITIVO,
  OBTENER_CALIDAD_AGUA,
  OBTENER_ULTIMOS_VALORES_CALIDAD_AGUA,
  OBTENER_AHORRO_ENERGETICO_BY_RANGE,
  OBTENER_INFO_DEVICE_PLUGIN,
  OBTENER_MINAVGMAX_HOME,
  OBTENER_ENERGIA_PRODUCIDA,
  OBTENER_DATO_SENSOR,
  RESOLVER_ALERTA,
  OBTENER_DATOS_CICLO_DEL_AGUA,
  OBTENER_INFORMES_POR_MODULO,
  OBTENER_ETIQUETAS_BY_DISPOSITIVOS,
  OBTENER_LISTADISPOSITIVOS_BY_TIPOSDISPOSITIVOS,
  OBTENER_CANALES_POR_GRUPO,
  OBTENER_DATOS_POST,
  OBTENER_ALERTAS_TODAS_SIN_RESOLVER,
  OBTENER_TODOS_LOS_DEVICES_DISPONIBLES
}
