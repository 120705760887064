import React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import ControlContext from '../../../../context/control/ControlContext';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { obtenerPluginByDevice } from '../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { rutasServicios } from '../../../../resources/enums/enumRutasServicios';
import { actions } from './resources/enums/enumActions';
import { Action } from './resources/types/typeAction';
import { Events } from './resources/types/typeEvents';
import { State } from './resources/types/typeState';
import { intervalTime } from '../../resources/interfaces/interfaceIntervalTime';
import { Stream } from '../../resources/enums/enumStream';
import { loadPlayer, Player } from 'rtsp-relay/browser';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { useHasError } from '../../../error/hooks/useHasError';
import { plugin } from '../../../plugins/resources/interfaces/interfacePlugin';
import { OpenCloseDialog } from './resources/interfaces/interfaceOpenCloseDialog';
import { DispositivoVideo } from '../../resources/interfaces/interfaceDispositivoVideo';
import { getCanal, getInfoSinCanal, getTitle } from './resources/functions/functions';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { CodigoDispositivo } from '../../../../resources/enums/enumCodigoDispositivo';
import { getDirectionServices, sortArray } from '../../../../functions/functions';

const FILE = 'useReducerVideo';
// tiemo empleado para el tiempo de espera para cargar el video (en ms)
const TIEMPO = 12 * 1000;

/**
 * Función Reducer que modifica el estado actual de los distintos componentes y variables
 * @param {State} state nuevo estado para modificar
 * @param {Action} action contiene el tipo y el payload con el nuevo valor del estado
 * @returns {State} devuelve el nuevo estado
 */
const REDUCER = (state: State, action: Action): State => {
  switch (action.type) {
    /* TARJETA */
    case actions.SET_ID_DEVICE:
      return { ...state, idDevice: action.payload };
    case actions.SET_OPEN_DIALOG_VIDEO:
      return { ...state, openDialogVideo: action.payload };
    /* DIALOG VIDEO */
    case actions.SET_INTERVAL_TIME:
      return { ...state, intervalTime: action.payload };
    case actions.SET_TITLE:
      return { ...state, title: action.payload };
    case actions.SET_SUBTITLE:
      return { ...state, subtitle: action.payload };
    case actions.SET_CANAL:
      return { ...state, canal: action.payload };
    case actions.SET_IS_FULLSCREEN:
      return { ...state, isFullScreen: action.payload };
    /* VIDEO STREAM */
    case actions.SET_STREAM:
      return { ...state, stream: action.payload };
    case actions.SET_IS_PLAYING:
      return { ...state, isPlaying: action.payload };
    case actions.SET_IS_DISCONNECT:
      return { ...state, isDisconnect: action.payload };
    /* VIDEO PLAYBACK */
    case actions.SET_REPLAY:
      return { ...state, replay: action.payload };
    /* CONTROLS PLAYBACK */
    case actions.SET_IS_PAUSE:
      return { ...state, isPause: action.payload };
    /* VIDEO */
    case actions.SET_VIDEOPLAYER:
      return { ...state, videoPlayer: action.payload };
    /* HOME VIDEOVIGILANCIA */
    case actions.SET_CAMARAS_HOME:
      return { ...state, camarasHome: action.payload };
    case actions.SET_SHOW_CAMARAS_HOME:
      return { ...state, showCamarasHome: action.payload };
    /* OPEN-CLOSE DIALOG */
    case actions.HANDLE_OPEN_CLOSE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

/**
 * Custom Hook para el control de la visualización de video ya sea en directo (stream)
 * o ver grabacion (playback)
 * @param { DispositivoVideo | undefined } datosVideo datos minimos para la visualización de
 * video en el cualquier componente (Mapa, Alertas, Home, etc)
 * @param { boolean | undefined } isDialogoVideo por defecto es true, indica si la
 * visualización es en el dialogo de video o no
 * @param { boolean | undefined } openDialog por defecto es true, indica si debe de abrise
 * el dialogo del video (solo es para si se crea el hook en el propio dialogo al no indicarse
 * nada anteriormente)
 * @returns { {state: State, events: Events} } devuelve dos parametros, state para los
 * estados (variables) y events para los eventos (funciones)
 */
const useReducerVideo = ({
  datosVideo,
  isDialogoVideo,
  openDialog,
  setOpenDialog
}: {
  datosVideo?: DispositivoVideo;
  isDialogoVideo?: boolean;
  openDialog?: boolean;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}): {
  state: State;
  events: Events;
} => {
  const { moduloSeleccionado } = useContext(ControlContext);
  const { controllerRequest } = useControlador();
  const { handleError } = useHasError();

  const INITIAL_STATE: State = {
    /* TARJETA */
    idDevice: datosVideo?.idDevice ?? 0,
    openDialogVideo: openDialog ?? false,
    /* DIALOG VIDEO */
    isDialogVideo: isDialogoVideo ?? true,
    intervalTime: datosVideo?.fechaAlerta ? { startTime: datosVideo.fechaAlerta } : undefined,
    title: datosVideo
      ? datosVideo.mensajeAlerta && datosVideo.fechaAlerta
        ? getTitle(datosVideo.nombre, datosVideo.mensajeAlerta, datosVideo.fechaAlerta)
        : datosVideo.nombre
      : '',
    subtitle: datosVideo?.mensajeAlerta ? getInfoSinCanal(datosVideo.mensajeAlerta) : '',
    canal: datosVideo?.mensajeAlerta ? getCanal(datosVideo.mensajeAlerta) : undefined,
    isFullScreen: undefined,
    /* VIDEO STREAM */
    stream: undefined,
    isPlaying: false,
    isDisconnect: false,
    /* VIDEO PLAYBACK */
    replay: false,
    /* CONTROLS PLAYBACK */
    isPause: false,
    /* VIDEO */
    videoPlayer: React.useRef<Player>(),
    canvas: React.useRef<HTMLCanvasElement | null>(null),
    /* HOME VIDEOVIGILANCIA */
    camarasHome: [],
    showCamarasHome: []
  };
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE);
  const {
    idDevice,
    openDialogVideo,
    isDialogVideo,
    intervalTime,
    title,
    subtitle,
    canal,
    isFullScreen,
    stream,
    isPlaying,
    isDisconnect,
    replay,
    isPause,
    videoPlayer,
    canvas,
    camarasHome,
    showCamarasHome
  } = state;

  /* DISPATCHS (SET STATES) */
  /**
   * Función que establece el Id Device
   * @param { number } idDevice
   */
  const setIdDevice = (idDevice: number): void =>
    dispatch({ type: actions.SET_ID_DEVICE, payload: idDevice });
  /**
   * Función que establece el openDialogVideo
   * @param { boolean } openDialogVideo
   */
  const setOpenDialogVideo = (openDialogVideo: boolean): void =>
    dispatch({ type: actions.SET_OPEN_DIALOG_VIDEO, payload: openDialogVideo });
  /* DIALOG VIDEO */
  /**
   * Función que establece el intervalTime
   * @param { intervalTime|undefined } intervalTime
   */
  const setIntervalTime = (intervalTime: intervalTime | undefined): void =>
    dispatch({ type: actions.SET_INTERVAL_TIME, payload: intervalTime });
  /**
   * Función que establece el title
   * @param { string } title
   */
  const setTitle = (title: string): void => dispatch({ type: actions.SET_TITLE, payload: title });
  /**
   * Función que establece el subtitle
   * @param { string } subtitle
   */
  const setSubtitle = (subtitle: string): void =>
    dispatch({ type: actions.SET_SUBTITLE, payload: subtitle });
  /**
   * Función que establece el canal
   * @param { string|undefined } canal
   */
  const setCanal = (canal: string | undefined): void =>
    dispatch({ type: actions.SET_CANAL, payload: canal });
  /**
   * Función que establece el isFullScreen
   * @param { boolean | undefined } isFullScreen
   */
  const setIsFullScreen = (isFullScreen: boolean | undefined): void =>
    dispatch({ type: actions.SET_IS_FULLSCREEN, payload: isFullScreen });
  /* VIDEO STREAM */
  /**
   * Función que establece el stream
   * @param { number | undefined } stream
   */
  const setStream = (stream: number | undefined): void =>
    dispatch({ type: actions.SET_STREAM, payload: stream });
  /**
   * Función que establece el isPlaying
   * @param { boolean } isPlaying
   */
  const setIsPlaying = (isPlaying: boolean): void =>
    dispatch({ type: actions.SET_IS_PLAYING, payload: isPlaying });
  /**
   * Función que establece el isDisconnect
   * @param { boolean } isDisconnect
   */
  const setIsDisconnect = (isDisconnect: boolean): void =>
    dispatch({ type: actions.SET_IS_DISCONNECT, payload: isDisconnect });
  /* VIDEO PLAYBACK */
  /**
   * Función que establece el replay
   * @param { boolean } replay
   */
  const setReplay = (replay: boolean): void =>
    dispatch({ type: actions.SET_REPLAY, payload: replay });
  /* CONTROLS PLAYBACK */
  /**
   * Función que establece el isPause
   * @param { boolean } isPause
   */
  const setIsPause = (isPause: boolean): void =>
    dispatch({ type: actions.SET_IS_PAUSE, payload: isPause });
  /* VIDEO */
  /**
   * Función que establece el videoPlayer
   * @param { Player|undefined } isPause
   */
  const setVideoPlayer = (newVideoPlayer: Player | undefined): void => {
    videoPlayer.current = newVideoPlayer;
    dispatch({ type: actions.SET_VIDEOPLAYER, payload: videoPlayer });
  };
  /* HOME VIDEOVIGILANCIA */
  /**
   * Función que establece las camaras del Home de videovigilancia que hay para ver
   * @param { Player|undefined } isPause
   */
  const setCamarasHome = (camarasHome: dispositivo[]): void => {
    dispatch({ type: actions.SET_CAMARAS_HOME, payload: camarasHome });
  };
  /**
   * Función que establece las camaras que se van a ver en el Home de videovigilancia
   * @param { Player|undefined } isPause
   */
  const setShowCamarasHome = (showCamarasHome: DispositivoVideo[]): void => {
    dispatch({ type: actions.SET_SHOW_CAMARAS_HOME, payload: showCamarasHome });
  };

  /* VARIABLES, HANDLES Y FUNCIONES */

  /* DIALOGO VIDEO */
  /**
   * Handle que se ejecuta al abrir el dialogo de un video en stream
   * @param {DispositivoVideo | undefined} elemento datos necesarios del dispositivo par su
   * visualización
   */
  const handleOpenDialogVideoStream = (elemento?: DispositivoVideo): void => {
    let payload: OpenCloseDialog = {
      openDialogVideo: true,
      stream: stream ?? Stream.DEFECTO
    };
    if (elemento) {
      payload = {
        ...payload,
        idDevice: elemento.idDevice,
        title: elemento.nombre
      };
    }
    dispatch({ type: actions.HANDLE_OPEN_CLOSE, payload: payload });
  };
  /**
   * Función que se ejecuta al abrir el dialogo video
   * @param {DispositivoVideo | undefined} element datos del dispositivo de video
   */
  const handleOpenDialogVideoPlayback = (elemento?: DispositivoVideo): void => {
    let payload: OpenCloseDialog = {
      openDialogVideo: true
    };
    if (elemento) {
      payload = {
        ...payload,
        idDevice: elemento.idDevice as number,
        intervalTime: { startTime: elemento.fechaAlerta as string },
        title: getTitle(
          elemento.nombre,
          elemento.mensajeAlerta as string,
          elemento.fechaAlerta as string
        ),
        subtitle: getInfoSinCanal(elemento.mensajeAlerta as string),
        canal: getCanal(elemento.mensajeAlerta as string)
      };
    }
    dispatch({ type: actions.HANDLE_OPEN_CLOSE, payload: payload });
  };
  /**
   * Función que establece la pantalla completa
   */
  const openFullScreen = (): void => setIsFullScreen(true);
  /**
   * Handle que se ejecuta al cerrar el dialogo
   */
  const handleOnClose = (): void => {
    let payload: OpenCloseDialog;

    if (isFullScreen) {
      payload = {
        isFullScreen: false
      };
    } else {
      if (setOpenDialog) setOpenDialog(false);
      payload = {
        openDialogVideo: false,
        isDisconnect: false,
        isPause: false,
        stream: undefined
      };
    }
    dispatch({ type: actions.HANDLE_OPEN_CLOSE, payload: payload });
  };
  /**
   * Handle que se ejecuta al hacer doble click en el dialogo, quitando o poniendo
   * la pantalla completa
   */
  const handleDoubleClick = (): void => setIsFullScreen(!isFullScreen);
  /**
   * Handle que se ejecuta al pulsar una tecla:
   *  -Espacio -> Pausa y reproduce
   *  -Escape -> Cierra el dialogo
   *  -Retroceso -> Repite el video (si es un playback)
   *  -Tecla '+' -> Canbia al siguiente stream (si es un stream)
   *  -Tecla '-' -> Canbia al anteior stream (si es un stream)
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (isPlaying) {
      switch (event.key) {
        case ' ':
          if (isPause) reproducirVideo();
          else pausarVideo();
          break;
        case 'Escape':
          handleOnClose();
          break;
        case 'Backspace':
          repetirVideo();
          break;
        case '+':
          if (stream !== undefined && stream < 2) setStream(stream + 1);
          break;
        case '-':
          if (stream !== undefined && stream > -1) setStream(stream - 1);
          break;
      }
    }
  };
  /* VIDEO STREAM */
  /**
   * Función que indica si debe mostrarse los controles de stream del video
   * @returns {boolean} true si debe mostrarse, false si no
   */
  const showControlStream = (): boolean => {
    return (isPlaying || isDisconnect) && isFullScreen !== true && openDialogVideo === true;
  };
  /**
   * Handle que se ejecuta al pulsar sobre un stream en el control de streams
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleChangeStream = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const valor = event.target.value;
    setStream(parseInt(valor));
    setIsDisconnect(false);
  };
  /* VIDEO PLAYBACK */
  /**
   * Función que se ejecuta al 'Repetir' video
   */
  const repetirVideo = (): void => {
    if (videoPlayer.current) {
      setReplay(!replay);
      setIsPause(false);
    }
  };
  /**
   * Función que se ejecuta al 'Pausar' video
   */
  const pausarVideo = (): void => {
    if (videoPlayer.current) {
      videoPlayer.current.pause();
      setIsPause(true);
    }
  };
  /**
   * Función que se ejecuta al 'Reanudar' video
   */
  const reproducirVideo = (): void => {
    if (videoPlayer.current) {
      videoPlayer.current.play();
      setIsPause(false);
    }
  };
  /**
   * Función que se ejecuta al 'Parar' video
   */
  const pararVideo = (): void => {
    if (videoPlayer.current) {
      videoPlayer.current.stop();
      setIsPause(true);
    }
  };

  /* HOME VIDEOVIGILANCIA */
  /**
   * Función que establece las cámaras que hay para ver en el Home de
   * videovigilancia, ademas de restablecerlo
   * @param {dispositivo[]} dispositivos
   */
  const handleCamsHome = (dispositivos: dispositivo[]): void => {
    // en caso de no recibir dispositivos, establecemos el state a un array vacio
    if (!dispositivos?.length) setShowCamarasHome([]);
    // filtramos por los que tienen asociado un idDevice y no son videgrabadores
    const filtrado: dispositivo[] = dispositivos.filter(
      (item: dispositivo) =>
        item.idDevice &&
        item.activo &&
        item.codigoTipoDispositivo !== CodigoDispositivo.VIDEOGRABADOR
    );
    // y ahora los ordenamos por orden alfabetico
    const filtradoOrdenado = sortArray(filtrado, 'nombre');

    setCamarasHome(filtradoOrdenado);
  };
  /**
   * Handle al hacer click en 'Todos'. Mostraría la visualización de todas las camaras si no
   * hay ninguna o todas puestas. Si están todas se quitan todas
   */
  const handleAllHome = (): void => {
    const newShowCamaras: DispositivoVideo[] = [];
    if (showCamarasHome.length !== camarasHome.length) {
      camarasHome.map((cam) =>
        newShowCamaras.push({
          idDevice: cam.idDevice as number,
          nombre: cam.nombre
        })
      );
    }

    setShowCamarasHome(newShowCamaras);
  };

  /**
   * Handle al hacer click en la camara. Mostraría la visualización de la cámara seleccionada si
   * no está puesta, si lo está se quita
   * @param {dispositivo} camara
   */
  const handleShowCamHome = (camara: dispositivo): void => {
    // Comprobamos si la cam está ya seleccionada o no según su indice
    const currentIndex = showCamarasHome.findIndex(
      (showCamara) => showCamara.idDevice === camara.idDevice
    );
    // este array nos servirá para mostrar las camaras seleccionadas
    const newShowCamaras = [...showCamarasHome];
    // si la camara se activa (ON), se añade al array, si no (OFF), se elimina
    if (currentIndex === -1) {
      newShowCamaras.push({
        idDevice: camara.idDevice as number,
        nombre: camara.nombre
      });
    } else {
      newShowCamaras.splice(currentIndex, 1);
    }
    // volvemos a ordenar alfabeticamente
    const newShowCamarasOrdenado = sortArray(newShowCamaras, 'nombre');

    setShowCamarasHome(newShowCamarasOrdenado);
  };

  /**
   * Función que comprueba si todas las cámaras están activadas
   * @returns {boolean} verdadero si están todas selecionadas, falso si no
   */
  const isAllSelect = (): boolean => {
    return camarasHome.length === showCamarasHome.length ? true : false;
  };

  /**
   * Función que comprueba si la camará seleccionada está activada
   * @param {dispositivo} camara
   * @returns {boolean} verdadero si están todas selecionadas, falso si no
   */
  const isCamSelect = (camara: dispositivo): boolean => {
    return showCamarasHome.find((cam) => cam.idDevice === camara.idDevice) ? true : false;
  };

  /* VIDEO */
  /**
   * Función que elimina el reproductor de video
   */
  const eliminarVideo = (): void => {
    if (videoPlayer.current) {
      setIsPlaying(false);
      videoPlayer.current.destroy();
    }
  };

  // action request para obtener el puerto
  const actionRequestPuerto: obtenerPluginByDevice = {
    type: action.OBTENER_PLUGIN_BY_DEVICE,
    payload: { servicio: rutasServicios.PLUGINS, idDevice: idDevice }
  };

  /**
   * Función que realizar una petición para obtener el puerto del plugin para la
   * reprodución de video
   * @returns {Promise<number | undefined>} devuelve la promesa con el nº de puerto o no
   */
  const obtenerPuerto = async (): Promise<number | undefined> => {
    const respuesta = await controllerRequest(actionRequestPuerto);
    const plugin: plugin = respuesta.data.result;
    return plugin.puerto;
  };

  /**
   * Función que construye la url del websocket para obtener el video
   *
   * @returns {Promise<string>} url construida
   */
  const getUrl = async (): Promise<string> => {
    if (idDevice) {
      const configuration = await controllerRequest({
        type: action.OBTENER_CONFIGURACION_VALOR_BY_DEVICE,
        payload: {
          servicio: rutasServicios.TIPOS_DEVICES,
          idDevice: idDevice
        }
      });

      const { valor: url } = configuration.find(
        (conf: any) => conf.configuracion == 'displayAddress'
      );

      // si se trata de ver a tiempo real o una grabación la url será diferente
      // if (!intervalTime) {
      //   url += `/videostream/${idDevice}`;
      //   if (stream !== undefined && stream !== Stream.DEFECTO) url += `?stream=${stream}`;
      // } else {
      //   url += `/playback/${idDevice}?startTime=${new Date(intervalTime.startTime).getTime()}`;
      //   if (intervalTime.endTime) url += `&endTime=${new Date(intervalTime.endTime).getTime()}`;
      // }

      // if (canal) url += `&channel=${canal}`;
      console.log('URL DESPUÉS', url);
      return url;
    }
    return '';
  };

  /**
   * Función que carga el reproductor de video
   * @returns {Promise<void>}
   */
  const cargarVideo = async (): Promise<void> => {
    const url = await getUrl();
    // si el video no ha cargado en el tiempo establecido, indicamos que no hay imagen
    const temporizador = setTimeout(() => setIsDisconnect(true), TIEMPO);

    if (canvas.current) {
      loadPlayer({
        url: url,
        canvas: canvas.current,
        disableGl: true,
        disableWebAssembly: true
      })
        .then((result) => {
          setVideoPlayer(result);
          window.clearTimeout(temporizador);
          setIsPlaying(true);
        })
        .catch((error) => handleError(`[${FILE}][${loadPlayer.name}]`, error));
    }
  };

  /* USE EFFECTS */

  /* VIDEO STREAM */
  useEffect(() => {
    if (
      (idDevice && !isDialogVideo && stream === undefined) ||
      (idDevice && openDialogVideo && !intervalTime)
    ) {
      setStream(stream !== undefined ? stream : Stream.DEFECTO);

      return () => setStream(undefined);
    }
  }, [idDevice]);

  /* VIDEO */
  useEffect(() => {
    if (idDevice && ((openDialogVideo && intervalTime) || stream !== undefined)) {
      const loadVideo = async () => await cargarVideo();
      loadVideo();

      return () => eliminarVideo();
    }
  }, [openDialogVideo, stream, replay]);

  const events = {
    /* dialog */
    handleOpenDialogVideoStream,
    handleOpenDialogVideoPlayback,
    openFullScreen,
    handleOnClose,
    handleDoubleClick,
    handleKeyDown,
    /* video steam */
    showControlStream,
    handleChangeStream,
    /* video playback */
    repetirVideo,
    pausarVideo,
    reproducirVideo,
    pararVideo,
    /* home videovigilancia */
    handleCamsHome,
    handleAllHome,
    handleShowCamHome,
    isAllSelect,
    isCamSelect
  };

  return {
    state,
    events
  };
};
export default useReducerVideo;
