import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { RangosString } from '../../resources/enums/enumsRangosString';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { HandleError } from '../../../error/resources/types/typeHandleError';
import { getId, getLabels } from '../../functions/funciones';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { Group } from '../../resources/enums/enumGroup';
import { Operation } from '../../resources/enums/enumOperation';
import { Rango } from '../../resources/enums/enumRango';
import { ServiciosGraficas } from '../../resources/enums/enumServiciosGraficas';
import { AreaChart, NoValues } from '../../resources/interfaces/interfaceCharts';
import { DatosGrafica } from '../../resources/interfaces/interfaceDatosGraficas';
import { servicioObtenerDatosGraficas } from '../../services/obtenerDatosGraficas';
import { differenceInDays, eachYearOfInterval, getYear } from 'date-fns';

const SERVICE_DEFAULT = ServiciosGraficas.SENSOR;
const file = 'createAreaChart';

const agrupacion: any = {
  [Rango.LAST_24H]: Group.HOUR,
  [Rango.WEEKLY]: Group.DAY,
  [Rango.MONTHLY]: Group.DAY,
  [Rango.ANNUALLY]: Group.MONTH,
  [Rango.TODAY]: Group.HOUR,
  [Rango.LAST_MONTH]: Group.DAY,
  [Rango.THIS_MONTH]: Group.DAY,
  [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: Group.HOUR,
  [Rango.LAST_YEAR]: Group.MONTH,
  [Rango.TODAY_IN_THE_PREVIOUS_YEAR]: Group.HOUR,
  [Rango.LAST_WEEK]: Group.DAY,
  [Rango.THIS_WEEK]: Group.DAY,
  [Rango.THIS_YEAR]: Group.MONTH,
  [Rango.YESTERDAY]: Group.HOUR
};

function getRangeGroup(startDate: Date, endDate: Date) {
  const dayDifference = differenceInDays(endDate, startDate);
  if (dayDifference === 0) {
    return { group: Group.HOUR, range: Rango.TODAY };
  } else if (dayDifference >= 1 && dayDifference <= 31) {
    return { group: Group.DAY, range: Rango.THIS_MONTH };
  } else {
    return { group: Group.MONTH, range: Rango.THIS_YEAR };
  }
}

export const createAreaChart = async (
  moduloSeleccionado: number,
  medida: TiposMedida | TiposMedida[],
  rango: Rango,
  operation: Operation,
  tipoGrafica: GraphTypes,
  dispositivos: Array<dispositivo>,
  handleError: HandleError,
  unidad?: dictionary_unidades,
  inicio = new Date(),
  fin = new Date(),
  valorMinimo?: number | string,
  valorMaximo?: number | string,
  type?: 'column' | 'line' | 'area'
): Promise<AreaChart | NoValues> => {
  const getValor = (d: DatosGrafica) => d.valor;

  const rangedProperties = getRangeGroup(inicio, fin);

  const consulta: any = {
    range: rango,
    startDate: inicio,
    endDate: fin,
    dispositivos: getId(dispositivos),
    group: rango === Rango.RANGE ? rangedProperties.group : agrupacion[rango],
    operation: operation,
    measure: medida,
    servicio: Array.isArray(medida)
      ? medida.find(
          (item) =>
            item === TiposMedida.CONSUMO ||
            TiposMedida.CONSUMO_CAUDAL ||
            TiposMedida.CONSUMO_CAUDAL_ENTRADA
        )
        ? ServiciosGraficas.CONSUMO
        : SERVICE_DEFAULT
      : medida == TiposMedida.CONSUMO
      ? ServiciosGraficas.CONSUMO
      : SERVICE_DEFAULT
  };

  const respuesta: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  if (respuesta.isAxiosError) {
    handleError(`[${file}][${servicioObtenerDatosGraficas.name}]`, respuesta.message);
    return { noValues: true };
  }

  const { result } = respuesta.data;

  //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
  //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
  const {
    labels,
    condition = 'fecha',
    formateoFecha,
    getLastValue
  } = getLabels(rango === Rango.RANGE ? rangedProperties.range : rango);

  let unid = '';

  const datos: Array<{ data: Array<any>; name: string; color?: string; type?: string }> = [];
  const hideNames: Array<string> = [];

  function createGraphData(result: Array<any>) {
    //Nos obtenemos el último valor para tratar correctamente los valores a 0 o nulos.
    const ultimoValor = getLastValue(new Date());

    result.forEach(
      (
        { nombre = '', datos: datosResultado }: { nombre: string; datos: Array<DatosGrafica> },
        index
      ) => {
        let data;

        //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
        //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
        if (datosResultado.length !== labels.length) {
          data = labels.map((element, index) => {
            const res = datosResultado.find((item: any) => {
              unid = item.unidad;
              return formateoFecha(new Date(item[condition])).trim() === element;
            });
            return res ? res.valor : ultimoValor >= index ? 0 : null;
          });
        } else {
          data = datosResultado.map(getValor);
        }

        datos.push({ data, name: `${dispositivos[index].nombre} ${nombre}`, type: type ?? 'area' });
      }
    );
  }
  if (result[0].datos.length === 0) return { noValues: true };

  const years = eachYearOfInterval({ start: inicio, end: fin }).map((item: Date) => getYear(item));

  if (years.length > 1) {
    years.forEach((year) => {
      const dataFiltered = result[0].datos.filter((element) => getYear(element.fecha) === year);
      createGraphData([{ ...result[0], datos: dataFiltered, nombre: year.toString() }]);
    });
  } else {
    createGraphData(result);
  }

  //Propiedades genéricas del gráfico, como el título, título del eje Y y el valor max para que el gráfico tenga una altura correcta.

  const title = `Gráfica evolutiva ${RangosString[
    rango === Rango.RANGE ? rangedProperties.range : rango
  ].toLowerCase()}`;
  const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;
  const annotations = {
    y: valorMinimo === 'Sin información' ? null : valorMinimo,
    y2: valorMaximo === 'Sin información' ? null : valorMaximo
  };

  return {
    datos,
    labels,
    title: title,
    titleYAxis: `Evolución de ${unid ?? unidad}`,
    max,
    hideNames,
    annotations,
    unid
  };
};
