import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Fab, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/PriorityHigh';
import useStyles from './styles';
import { grupo } from './resources/interfaces/interfaceGrupo';
import { GruposManager } from './GruposManager';
import Table from '../common/table/Table';
import { enumTabla } from '../common/table/resources/enumTabla';
import PaginationRounded from '../common/table/Paginacion';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import ControlContext from '../../context/control/ControlContext';
import { dictionary_grupos } from '../../resources/enums/plainText';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { useHasError } from '../error/hooks/useHasError';
import { enumComponentes } from '../../resources/enums/enumComponente';

const file = 'TablaGrupos';

export function TablaGrupos(): JSX.Element {
  const classes = useStyles();
  const { setControl } = React.useContext(ControlContext);
  const theme = useTheme();
  const [grupos, setGrupos] = useState<grupo[]>([]);
  const { controllerRequest } = useControlador();
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  function confirmarEliminarAlert(): void {
    confirmarEliminar(grupos, setGrupos, rutasServicios.GRUPOS);
  }
  const { handleError } = useHasError();

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'codigo', label: 'Código' },
    { name: 'fechaCreacion', label: 'Fecha Creación' },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_grupos.GRUPOS}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (grupos.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <GruposManager
            grupoSeleccionado={grupos[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_GRUPO}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((g) => grupos[g.dataIndex]));
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setGrupos(
        await controllerRequest(
          {
            type: action.OBTENER_DATOS_SIN_MODULO,
            payload: { servicio: rutasServicios.GRUPOS }
          },
          { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
        )
      );
    };
    cargarDatos();
  }, []);

  return (
    <div>
      {!openSmartTeliaBackdrop ? (
        <Table
          title={dictionary_grupos.GRUPOS}
          columns={columns}
          data={grupos}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      <Tooltip title={dictionary_grupos.CREAR_GRUPO} arrow>
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => setControl(<GruposManager />)}
          className={classes.fabBoton}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
      {/* Boton para producir error a proposito */}
      <Tooltip title={dictionary_grupos.PROVOCAR_ERROR} arrow>
        <Fab
          aria-label="testError"
          onClick={() => {
            try {
              let error: any;
              error.forEach((element: any) => {
                error++;
              });
            } catch (exception: any) {
              handleError(`[${file}][onClick]`, exception);
            }
          }}
          className={classes.fabBotonError}
        >
          <ErrorIcon fontSize="large" />
        </Fab>
      </Tooltip>
    </div>
  );
}
