import React, { useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, Tooltip, Alert } from '@mui/material';
import Slider from '@mui/material/Slider';
import Autocomplete from '@mui/lab/Autocomplete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from './styles';
import { etiqueta } from '../etiquetas/resources/interfaces/interfaceEtiquetas';
import { grupo } from '../grupos/resources/interfaces/interfaceGrupo';
import {
  dictionary_dispositivos,
  dictionary_generic,
  enumLabel
} from '../../resources/enums/plainText';
import { tiposDispositivo } from '../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { DispositivoManager } from './DispositivoManager';
import { enumComponentes } from '../../resources/enums/enumComponente';

export function DispositivoCreate({ state, events }: { state: any; events: any }): JSX.Element {
  const classes = useStyles();
  const { datosDialogo, checked, dispositivoReady, disabled } = state;
  const { dispositivo } = dispositivoReady;

  useEffect(() => {
    events.comprobarChecked;
  }, []);

  return (
    <div className={classes.root}>
      <form id="formDispositivo" onSubmit={events.handleSubmitCrear} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" marginBottom="30px">
              <VolverAtras backTo={<DispositivoManager key={enumComponentes.ADMINISTRACION} />} />
              <Typography variant="h6">{dictionary_dispositivos.CREAR_DISPOSITIVO}</Typography>
            </Box>
          </Grid>
          <Grid item container xs={12} sm={12} md={8}>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  size="small"
                  id="idDevice"
                  options={datosDialogo !== undefined ? datosDialogo.device : []}
                  getOptionLabel={(option: any) => option.nombre}
                  onChange={events.onhandleAutocompleteDevices}
                  renderInput={(params) => (
                    <TextField {...params} label={enumLabel.DEVICE} variant="outlined"></TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <Tooltip title={dictionary_dispositivos.TOOLTIP_IDENTIFICADOR} placement="left">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={dictionary_dispositivos.IDENTIFICADOR}
                    id="nombre"
                    type="text"
                    required
                    disabled={disabled}
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <Autocomplete
                  size="small"
                  id="idTipoDispositivo"
                  options={datosDialogo.tiposDispositivo}
                  disabled={disabled}
                  getOptionLabel={(option: tiposDispositivo) => option.nombre}
                  onChange={events.handleAutocompleteTiposDispositivo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.TIPO_DISPOSITIVO}
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  multiple
                  size="small"
                  id="grupos"
                  options={
                    datosDialogo.gruposDispositivos === undefined
                      ? []
                      : datosDialogo.gruposDispositivos
                  }
                  disabled={disabled}
                  getOptionLabel={(option: grupo) => option.codigo}
                  onChange={events.handleMultipleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.GRUPOS_DISPOSITIVO}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.MARCA_DISPOSITIVO}
                  id="marca"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  onKeyDown={events.handleInputInvalid}
                  onPaste={events.handleInputInvalid}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.MODELO_DISPOSITIVO}
                  id="modelo"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.NUM_SERIE_DISPOSITIVO}
                  id="nSerie"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.DIRECCION}
                  id="direccion"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.titulo} color="secondary">
              {dictionary_dispositivos.UBICACION}
            </Typography>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.LATITUD}
                  id="latitud"
                  required
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  inputProps={{
                    pattern: dictionary_dispositivos.PATRON_UBICACION,
                    title: dictionary_dispositivos.MENSAJE_UBICACION
                  }}
                />
              </Grid>
              <Grid item sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.LONGITUD}
                  id="longitud"
                  required
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  inputProps={{
                    pattern: dictionary_dispositivos.PATRON_UBICACION,
                    title: dictionary_dispositivos.MENSAJE_UBICACION
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.titulo} color="secondary">
              {dictionary_dispositivos.OTRA_INFORMACION}
            </Typography>
            <Grid item container sm={12} spacing={2} className={classes.cajas}>
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  size="small"
                  multiple
                  id="etiquetas"
                  options={
                    datosDialogo.etiquetasDispositivo !== undefined
                      ? (datosDialogo.etiquetasDispositivo as etiqueta[])
                      : []
                  }
                  getOptionLabel={(option: etiqueta) => option.nombre}
                  filterSelectedOptions
                  disabled={disabled}
                  onChange={events.handleMultipleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={enumLabel.ETIQUETAS} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item container sm={10} justifyContent="space-between" alignItems="center">
                <Grid item xs={5} sm={4} md={3}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        color="secondary"
                        id="activo"
                        checked={checked}
                        disabled={disabled}
                        onChange={events.onchangeCheked}
                        inputProps={{ 'aria-label': 'checkbox-estado' }}
                      />
                    }
                    label={enumLabel.ACTIVAR_DESACTIVAR}
                    labelPlacement="top"
                  />
                </Grid>
                <Grid item xs={5} sm={4} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    disabled={disabled}
                    type="submit"
                  >
                    {dictionary_generic.CREAR}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container sm={10} spacing={2}>
              {datosDialogo.device.length === 0 ? (
                <Alert severity="info" className={classes.alert}>
                  {dictionary_generic.NO_DEVICES}
                </Alert>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
