import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Fab, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import Table from '../common/table/Table';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import PaginationRounded from '../common/table/Paginacion';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import ControlContext from '../../context/control/ControlContext';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { dictionary_tipos_actuaciones } from '../../resources/enums/plainText';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { TiposActuacionesManager } from './TiposActuacionesManager';
import { tipoActuacion } from './resources/interfaces/interfaceTipoActuacion';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { enumTabla } from '../common/table/resources/enumTabla';

export function TablaTiposActuaciones(): JSX.Element {
  const classes = useStyles();
  const { setControl } = React.useContext(ControlContext);
  const theme = useTheme();
  const [tiposActuaciones, setTiposActuaciones] = useState<tipoActuacion[]>([]);
  const { controllerRequest } = useControlador();
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  function confirmarEliminarAlert(): void {
    confirmarEliminar(tiposActuaciones, setTiposActuaciones, rutasServicios.TIPOS_ACTUACIONES);
  }

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'nombre', label: 'Nombre' },
    { name: 'valor', label: 'Valor' },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_tipos_actuaciones.TIPO_ACTUACION}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <TiposActuacionesManager
            tipoSeleccionado={tiposActuaciones[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_TIPO_ACTUACION}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((e) => tiposActuaciones[e.dataIndex]));
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setTiposActuaciones(
        await controllerRequest({
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.TIPOS_ACTUACIONES }
        })
      );
    };
    cargarDatos();
  }, []);

  return (
    <div>
      {!openSmartTeliaBackdrop ? (
        <Table
          title={dictionary_tipos_actuaciones.TIPOS_ACTUACIONES}
          columns={columns}
          data={tiposActuaciones}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      <div aria-label="nuevo" accessKey="a" tabIndex={0}>
        <Tooltip title={dictionary_tipos_actuaciones.CREAR_TIPO_ACTUACION} arrow>
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() =>
              setControl(<TiposActuacionesManager key={enumComponentes.CREAR_TIPO_ACTUACION} />)
            }
            className={classes.fabBoton}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </div>
  );
}
