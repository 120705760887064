/**
 * @readonly
 * @enum {number} tipoAlerta
 * @param INFO = 0,
 * @param SUCCESS = 1,
 * @param WARNING = 2,
 * @param ERROR = 3,
 * @param EVENT = 4
 */
export enum tipoAlerta {
  INFO = 0,
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
  EVENT = 4
}
