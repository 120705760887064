import * as React from 'react';
import { Box, Divider, Grid, Grow, Typography } from '@mui/material';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { MeasuresText } from '../../../graficas/resources/enums/enumMeasuresText';
import { BackdropTarjetasModulos } from './BackdropTarjetasModulos';
import { useStyles } from './styles';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { getId } from '../../../graficas/functions/funciones';
import { Rango } from '../../../graficas/resources/enums/enumRango';
import { useTheme } from '@mui/material/styles';
import { DonutChart } from '../../../graficasApexCharts/DonutChart';
import { dictionary_generic } from '../../../../resources/enums/plainText';

const rangosLegibles: any = {
  [Rango.TODAY]: 'Hoy',
  [Rango.YESTERDAY]: 'Ayer',
  [Rango.THIS_WEEK]: 'Esta semana',
  [Rango.LAST_WEEK]: 'Anterior',
  [Rango.THIS_MONTH]: 'Este mes',
  [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: 'Anterior',
  [Rango.THIS_YEAR]: 'Este año',
  [Rango.LAST_YEAR]: 'Anterior'
};

const rangosComparativos: any = {
  [Rango.TODAY]: { actual: Rango.TODAY, anterior: Rango.YESTERDAY },
  [Rango.THIS_WEEK]: { actual: Rango.THIS_WEEK, anterior: Rango.LAST_WEEK },
  [Rango.THIS_MONTH]: { actual: Rango.THIS_MONTH, anterior: Rango.TODAY_IN_THE_PREVIOUS_MONTH },
  [Rango.THIS_YEAR]: { actual: Rango.THIS_YEAR, anterior: Rango.LAST_YEAR }
};

export function ComparativeCard(props: any): JSX.Element {
  const classes = useStyles();
  const { tarjeta, dispositivos, semaforo, handleCardClick = null } = props;

  const { servicio, codigoTipoMedida, titulo, ...rest } = tarjeta;
  const [transition, setTransition] = React.useState(true);

  const theme = useTheme();

  const { controllerRequest } = useControlador();

  const [graphData, setGraphData] = React.useState<{
    series: Array<number>;
    labels: Array<string>;
    unidad: Array<string>;
    diferencia: number;
  }>({
    series: [],
    labels: [],
    unidad: [],
    diferencia: 0
  });
  const [openBackdrop, setOpenBackdrop] = React.useState(true);

  React.useEffect(() => {
    const dataObject = {
      codigosTipoDispositivo: tarjeta.codigoTipoDispositivo,
      tipoMedida: codigoTipoMedida,
      dispositivos: getId(dispositivos),
      operation: rest.codigoFuncionAgregada,
      idModulo: rest.idModulo,
      range: tarjeta.range
    };
    if (tarjeta.dato === undefined) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: servicio,
          object: { ...dataObject }
        }
      })
        .then((response) => {
          const { actual, anterior, diferencia, unidad } = response;
          const serie = actual !== null && anterior !== null;
          setGraphData({
            series: serie ? [actual, anterior] : [],
            labels: [
              rangosLegibles[rangosComparativos[tarjeta.range].actual],
              rangosLegibles[rangosComparativos[tarjeta.range].anterior]
            ],
            unidad,
            diferencia
          });
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  }, []);

  return (
    <>
      <Grid item xs={12} sm={8} lg={4} sx={{ minHeight: '166px', maxHeight: '182px' }}>
        <Grow in={transition} timeout={650} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', cursor: handleCardClick ? 'context-menu' : 'auto' }}
            onContextMenu={(event) => {
              event.preventDefault();
              if (handleCardClick) {
                handleCardClick(event, tarjeta.position, setTransition);
              }
            }}
          >
            <CustomTooltip
              title={
                titulo.length ? titulo : `Comparativa de ${MeasuresText[tarjeta.codigoTipoMedida]}`
              }
              placement={'bottom'}
            >
              <Box
                style={{
                  boxShadow: `0px 0px 1px 2px #e0e0e0`,
                  borderRadius: '15px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  position: 'relative'
                }}
                alignItems={'center'}
                textAlign={'center'}
              >
                <BackdropTarjetasModulos open={openBackdrop} />
                {!openBackdrop ? (
                  graphData.series.length ? (
                    <>
                      <div style={{ width: '100%' }}>
                        <DonutChart {...graphData} />
                      </div>

                      <div style={{ width: '95%' }}>
                        <Typography className={classes.comparativeText}>
                          <span
                            style={{
                              color: theme.palette.primary.main,
                              fontWeight: 'bold'
                            }}
                          >
                            {graphData.labels[0]}
                          </span>
                        </Typography>
                        <Typography className={classes.comparativeValue}>
                          <span
                            style={{
                              color: semaforo?.color ?? theme.palette.primary.main,
                              fontWeight: 'bold',
                              fontSize:
                                graphData.series[0].toString().length > 5 ? '0.6rem' : 'inherit'
                            }}
                          >
                            {graphData.series[0].toLocaleString()}
                          </span>{' '}
                        </Typography>
                        <Typography className={classes.comparativeValue}>
                          <span className={classes.measure}>{graphData.unidad}</span>
                        </Typography>
                      </div>
                      <Divider
                        orientation="vertical"
                        style={{
                          borderRightWidth: 'medium',
                          height: '50%'
                        }}
                      />
                      <div style={{ width: '95%' }}>
                        <Typography className={classes.comparativeText}>
                          <span
                            style={{
                              color: semaforo?.color ?? theme.palette.primary.main,
                              fontWeight: 'bold'
                            }}
                          >
                            {graphData.labels[1]}
                          </span>
                        </Typography>
                        <Typography className={classes.comparativeValue}>
                          <span
                            style={{
                              color: theme.palette.primary.main,
                              fontWeight: 'bold',
                              fontSize:
                                graphData.series[1].toString().length > 5 ? '0.6rem' : 'inherit'
                            }}
                          >
                            {graphData.series[1].toLocaleString()}
                          </span>
                        </Typography>
                        <Typography className={classes.comparativeValue}>
                          <span className={classes.measure}>{graphData.unidad}</span>
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Typography className={classes.comparativeValue}>
                        <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                          {dictionary_generic.NO_DATA}
                        </span>
                      </Typography>
                    </div>
                  )
                ) : (
                  <></>
                )}
              </Box>
            </CustomTooltip>
          </div>
        </Grow>
      </Grid>
    </>
  );
}
