import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import useStyles from './styles';
import { dispositivo } from '../dispositivos/resources/interfaces/interfaceDispositivo';
import { tipoMedida } from '../tiposMedidas/resources/interfaces/interfaceTipoMedida';
import { section } from '../sections/resources/interfaces/interfaceSection';
import { dictionary_generic, enumLabel } from '../../resources/enums/plainText';
import { administracion } from '../../resources/enums/enumAdministracion';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { TablaCanales } from './TablaCanales';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { Unit } from 'shared/types';

export function CanalesCreate({ state, events }: { state: any; events: any }): JSX.Element {
  const classes = useStyles();
  const { canal, listaItemsSections, checked, datosDialogo } = state;

  return (
    <div className={classes.root}>
      <form id="formCanales" onSubmit={events.handleSubmitCrear} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<TablaCanales key={enumComponentes.ADMINISTRACION} />} />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {administracion.CREAR_CANAL}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item container xs={12} sm={12} md={8}>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  size="small"
                  id="idDispositivo"
                  options={datosDialogo.dispositivos}
                  getOptionLabel={(option: dispositivo) => option.nombre}
                  onChange={events.handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.DISPOSITIVO}
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  key={state.canal.idDispositivo}
                  size="small"
                  id="idSection"
                  options={listaItemsSections}
                  disabled={canal.idDispositivo === undefined ? true : false}
                  getOptionLabel={(option: section) => option.etiqueta as string}
                  onChange={events.handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} label={enumLabel.SECTION} variant="outlined" required />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.NOMBRE_CANAL}
                  id="nombre"
                  type="text"
                  required
                  onChange={events.handleInputChange}
                  onKeyDown={events.handleInputInvalid}
                  onPaste={events.handleInputInvalid}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <Autocomplete
                  size="small"
                  id="idTipoMedida"
                  options={datosDialogo.tipoMedida}
                  getOptionLabel={(option: tipoMedida) => option.nombre}
                  onChange={events.handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.TIPO_MEDIDA}
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              {
                <Grid item xs={12} sm={10} md={5}>
                  <Autocomplete
                    size="small"
                    id="idTipoUnidad"
                    options={datosDialogo.tipoUnidad}
                    getOptionLabel={(option: Unit) => option.nombre}
                    onChange={events.handleAutocompleteChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={enumLabel.TIPO_UNIDAD}
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Grid>
              }
              <Grid item container sm={10} justifyContent="space-between" alignItems="center">
                <Grid item xs={5} sm={4} md={3}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        color="secondary"
                        id="activo"
                        checked={checked}
                        onChange={events.onchangeCheked}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={enumLabel.ACTIVAR_DESACTIVAR}
                    labelPlacement="top"
                  />
                </Grid>
                <Grid item xs={5} sm={4} md={3}>
                  <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                    {dictionary_generic.CREAR}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
