import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path = '';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let respuesta: any;

/**
 * Función que obtiene todas las sections filtradas por device
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } idDevice  id del device por el que queremos filtrar la sections
 * @returns {Promise<any>} retorna una promesa con el resultado de axios que contiene el listado de sections
 */
export async function obtenerSectionsPorDevice(
  servicio: string,
  idDevice: number,
  escritura?: boolean,
  idCanal?: number,
  onlyFree?: boolean
): Promise<any> {
  try {
    if (localStorage.getItem('token')) {
      if (escritura) {
        path = servicio + '/escritura/device/';
        respuesta = await axios.post(
          process.env.REACT_APP_URL_LOCAL + path,
          encriptarJSON({ idDevice: idDevice, idCanal: idCanal }),
          {
            headers: getHeaders(dictionary_entornos.BACK)
          }
        );
      } else {
        path = servicio + '/todos/device/';
        respuesta = await axios.post(
          process.env.REACT_APP_URL_LOCAL + path,
          encriptarJSON({ idDevice: idDevice, idCanal: idCanal, onlyFree: onlyFree }),
          {
            headers: getHeaders(dictionary_entornos.BACK)
          }
        );
      }
    }
    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que realiza la petición para modificar el estado de una section
 *
 * @param {string} servicio ruta del servicio donde hacemos la petición
 * @param {{ idSection: number; activo: number }} objeto registro que contiene el id y el nuevo estado activo
 *
 * @return {Promise<any>} retorna una promesa con la respuesta
 */
export async function servicioModificarEstadoSection(
  servicio: string,
  objeto: { idSection: number; activo: number }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  path = servicio + '/activo/';
  try {
    respuesta = await axios.post(process.env.REACT_APP_URL_LOCAL + path, encriptarJSON(objeto), {
      headers: getHeaders(dictionary_entornos.BACK)
    });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que realiza la petición para obtener todas las sections activas y de escritura
 *
 * @param {string} servicio ruta del servicio donde hacemos la petición

 *
 * @return {Promise<any>} retorna una promesa con la respuesta
 */
export async function servicioObtenerSectionsEscrituraActivas(
  servicio: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  path = servicio + '/activo/escritura';
  try {
    respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
      headers: getHeaders(dictionary_entornos.BACK)
    });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
