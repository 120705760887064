import UserContext from '../../context/UserContext';
import { useContext, useCallback, useRef } from 'react';
import {
  hacerLogin,
  servicioPedirCodigo,
  servicioRestablecerPassword,
  servicioVerificarCodigo
} from '../../components/login/servicios/ServicioLogin';
import { useRespuesta } from '../../hooks/useRespuesta/useRespuesta';
import { respuesta } from '../../resources/interfaces/interfaceRespuesta';
import { DatosUsuario } from '../../components/login/interfaceDatosUsuario';
import { Severity } from '../../components/common/snackbar/resources/types/Severity';
import { enumSeverity } from '../../components/common/snackbar/resources/enums/enumSeverity';
import { modulo } from '../../resources/interfaces/interfaceModulo';
import { Modulos } from '../../resources/enums/enumModulo';
import { useLocation } from 'wouter';
/**
 * Establece los módulos dependiendo del tipo de usuario y además si fuera necesario, ordena los módulos para que aparezcan en el menú correctamente.
 * @param {modulos,usuario}
 * @returns modulos[]
 */
function establecerModulos({ modulos }: { modulos: modulo[] }): modulo[] {
  //Si no es Administrador, se eliminan los módulos de Configuración General y gestión de usuarios y no tenemos por qué ordenarlos.¿Revisar esto en la bd?

  //colocamos el modulo de gestion de usuarios el penultimo, y el de configuracion general el ultimo.
  const moduloGestionUsuario = modulos.find((mod) => mod.id === Modulos['GESTIÓN DE USUARIOS']);
  const moduloConfiguracionGeneral = modulos.find(
    (mod) => mod.id === Modulos['CONFIGURACIÓN GENERAL']
  );

  const formatModul = modulos.filter(
    (mod) =>
      mod.id !== Modulos['GESTIÓN DE USUARIOS'] && mod.id !== Modulos['CONFIGURACIÓN GENERAL']
  );

  if (moduloGestionUsuario) formatModul.push(moduloGestionUsuario);
  if (moduloConfiguracionGeneral) formatModul.push(moduloConfiguracionGeneral);

  return formatModul;
}

/** Hook personalizado que realiza las siguientes funciones:
 * Actualizar el estado del userContext en cualquier momento, lo que nos permite un control en toda la aplicación dependiendo del estado del usuario.
 * Hacer login y guardar el token en el localStorage.
 * Hacer logout y limpiar el token.
 * Devuelve un mensaje al SnackBar y lo abre si el login no es correcto.
 * Si no hubiera conexión con el servidor, el SnackBar muestra un mensaje.
 * Poder restablecer la contraseña:
 *    1º Pidiendo un codigo de verificación al correo del usuario.
 *    2º Verificando ese código para confirmar el usuario.
 *    3º Y por último poder restablecer una nueva contraseña.
 */
export function useLoginUsuario(): {
  isLogged: boolean;
  login: (name: string, pass: string) => Promise<boolean>;
  logout: () => void;
  completadoCorrectamente: React.MutableRefObject<boolean>;
  propiedadesSnackBar: React.MutableRefObject<{
    texto: string;
    severity: Severity;
  }>;
  pedirCodigoRestablecerPass: (nombreUsuario: string, email: string) => Promise<number>;
  verificarCodigoRestablecerPass: (idUsuario: number, codeReset: string) => Promise<boolean>;
  restablecerPassword: (idUsuario: number, nuevaPassword: string) => Promise<boolean>;
} {
  const { logged, setLogged, setDatosUsuario } = useContext(UserContext);
  const completado = useRef<boolean>(false);
  const propiedades = useRef<{
    texto: string;
    severity: Severity;
  }>({ texto: '', severity: enumSeverity.ERROR });
  const { tratarRespuesta, completadoCorrectamente, propiedadesSnackBar } = useRespuesta(
    completado,
    propiedades
  );
  const [, location] = useLocation();

  /**
   * Hace la llamada al servicio para hacer login.
   * @param {string} name nombre del usuario
   * @param {string} pass contraseña del usuario
   */
  const login = useCallback(
    async (name: string, pass: string): Promise<boolean> => {
      const respuesta: respuesta = await hacerLogin(name, pass);
      tratarRespuesta(respuesta);
      if (!respuesta.isAxiosError) {
        if (respuesta.data.token) {
          localStorage.setItem('token', respuesta.data.token);
          localStorage.setItem('tokenGestion', respuesta.data.result.tokenGestion);
          localStorage.setItem('secret_crypto', respuesta.data.result.secret_crypto);
          sessionStorage.setItem('databaseName', respuesta.data.result.databaseName);
        }
        if (respuesta.data.result !== undefined) {
          const resultado = respuesta.data.result as unknown as DatosUsuario;
          setDatosPlataforma(resultado);
          setLogged(true);
          return true;
        }
      }
      setLogged(false);
      return false;
    },
    [tratarRespuesta]
  );

  /**
   * Hace logout actualizando el estado logged y limpia el token del local storage.
   */
  const logout = useCallback((): void => {
    setLogged(false);
    //localStorage.clear();
    sessionStorage.clear();
    location('/');
    window.location.reload();
  }, [setLogged]);

  /**
   * Función que hace la llamada al servicio de pedir codigo al correo para restaurar la contraseña a través del email
   * En esta función no queremos tratar la respuesta porque no queremos indicar si el email es correcto o no.
   * @param {string} email email asociado al ususario
   * @returns {Promise<number>} promesa con el id de usuario, si no lo encuentra, devuelve un 0
   */
  const pedirCodigoRestablecerPass = async (
    nombreUsuario: string,
    email: string
  ): Promise<number> => {
    const respuesta: respuesta = await servicioPedirCodigo(nombreUsuario, email);
    if (respuesta?.data?.result?.idUsuario) return respuesta.data.result.idUsuario;
    return 0;
  };

  /**
   * Función que hace la llamada al servicio para verificar el usuario a través del idUsuario y el código recibido al correo
   * @param {string} idUsuario identificador asociado al ususario
   * @param {string} codeReset codigo de verificación que ha recibido en el correo del usuario
   * @returns {Promise<boolean>} promesa que indica si ha ido bien (true) o mal (false) la petición
   */
  const verificarCodigoRestablecerPass = useCallback(
    async (idUsuario: number, codeReset: string): Promise<boolean> => {
      const respuesta: respuesta = await servicioVerificarCodigo(idUsuario, codeReset);
      tratarRespuesta(respuesta);
      if (!respuesta.isAxiosError) {
        return respuesta.data.success;
      } else {
        return false;
      }
    },
    [tratarRespuesta]
  );

  /**
   * Función que hace la llamada al servicio de restablecer una nueva contraseña para el usuario
   * @param {string} idUsuario identificador asociado al ususario
   * @param {string} nuevaPassword nueva contraseña
   * @returns {Promise<boolean>} promesa que indica si ha ido bien (true) o mal (false) la petición
   */
  const restablecerPassword = useCallback(
    async (idUsuario: number, nuevaPassword: string): Promise<boolean> => {
      const respuesta: respuesta = await servicioRestablecerPassword(idUsuario, nuevaPassword);
      tratarRespuesta(respuesta);
      if (!respuesta.isAxiosError) {
        return respuesta.data.success;
      } else {
        return false;
      }
    },
    [tratarRespuesta]
  );

  /**
   * Función que guarda en contexto y en local Storage los datos necesarios del usuario
   * @param {DatosUsuario} datos Datos del resultado correspondiente al usuario
   */
  const setDatosPlataforma = (datos: DatosUsuario): void => {
    datos.modulos = establecerModulos(datos);
    localStorage.setItem('colorPrimario', datos.cliente.colorPrimario);
    localStorage.setItem('colorSecundario', datos.cliente.colorSecundario);
    localStorage.setItem('urlLogotipo', datos.cliente.urlLogotipo);
    localStorage.setItem('cliente', datos.cliente.nombre);
    setDatosUsuario(datos);
  };

  return {
    isLogged: Boolean(logged),
    login,
    logout,
    completadoCorrectamente,
    propiedadesSnackBar,
    pedirCodigoRestablecerPass,
    verificarCodigoRestablecerPass,
    restablecerPassword
  };
}
