import React, { FormEvent, useContext, useEffect, useReducer } from 'react';
import {
  canalesRespuesta,
  objectCanalRespuesta
} from '../resources/interfaces/interfaceCanalesRespuesta';
import { canalesCRU } from '../resources/interfaces/interfaceCanalesCRU';
import { objectCanal } from '../resources/interfaces/interfaceCanales';
import { objectDispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { section } from '../../sections/resources/interfaces/interfaceSection';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import ControlContext from '../../../context/control/ControlContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { TablaCanales } from '../TablaCanales';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { canal } from '../../reglas/resources/interfaces/interfaceCanal';

export const ACTIONS = {
  SET_CANAL_MODIFICAR: 'setCanalModificar',
  SET_DATOS_DIALOGO: 'setDatosDialogo',
  CREAR_CANAL: 'crearCanal',
  SET_ITEMS_O_SECTIONS: 'setItemsSections',
  SET_CHECKED: 'setChecked',
  SET_MOSTRAR_CREAR: 'setMostrarCrear',
  SET_MOSTRAR_MODIFICAR: 'setMostrarModificar',
  SET_OPEN_AVISO: 'setOpenAviso',
  LIMPIAR_CANAL: 'limpiarCanal'
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_CANAL_MODIFICAR:
      return { ...state, canalModificar: action.payload };
    case ACTIONS.SET_DATOS_DIALOGO:
      return {
        ...state,
        datosDialogo: action.payload,
        mostrarDialogo: true
      };
    case ACTIONS.CREAR_CANAL:
      return { ...state, canal: action.payload };
    case ACTIONS.SET_ITEMS_O_SECTIONS:
      return { ...state, listaItemsSections: action.payload };
    case ACTIONS.SET_MOSTRAR_CREAR:
      return { ...state, mostrarCrear: true };
    case ACTIONS.SET_MOSTRAR_MODIFICAR:
      return { ...state, mostrarModificar: true };
    case ACTIONS.SET_CHECKED:
      return {
        ...state,
        checked: action.payload,
        canal: { ...state.canal, activo: action.payload ? 1 : 0 }
      };
    case ACTIONS.SET_OPEN_AVISO:
      return { ...state, aviso: action.payload };
    case ACTIONS.LIMPIAR_CANAL:
      return {
        ...state,
        canal: { ...state.canal, idSection: undefined }
      };
    default:
      return state;
  }
};

export function useReducerCanales(canalSeleccionado?: canalesRespuesta | any) {
  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { setControl } = React.useContext(ControlContext);

  const [state, dispatch] = useReducer(reducer, {
    canalModificar: undefined,
    datosDialogo: {
      dispositivos: [],
      dispositivoSeleccionado: objectDispositivo(),
      tipoMedida: [],
      tipoUnidad: []
    } as canalesCRU,
    mostrarDialogo: false,
    canal:
      canalSeleccionado !== undefined
        ? objectCanalRespuesta(canalSeleccionado)
        : (objectCanalRespuesta() as canalesRespuesta),
    listaItemsSections: [] as section[],
    checked: canalSeleccionado !== undefined ? canalSeleccionado.activo : true,
    mostrarCrear: false,
    mostrarModificar: false,
    aviso: false
  });

  const { datosDialogo, canal, mostrarDialogo } = state;
  const { handleInputChange, handleAutocompleteChange, handleInputInvalid } = useEventChange(
    canal,
    setCanal
  );

  /**
   * Función que modifica el estadod el check
   * @param { boolean } checked estado del check
   */
  function setChecked(checked: boolean) {
    dispatch({ type: ACTIONS.SET_CHECKED, payload: checked });
  }

  /**
   * Función que modifica los datos del canal
   * @param { canalesRespuesta } canal Objeto canal con la estructura que viene del servicio
   */
  function setCanal(canal: canalesRespuesta) {
    dispatch({ type: ACTIONS.CREAR_CANAL, payload: canal });
  }

  /**
   * Función que carga los datos de los desplegables de canales
   * @param { CanalesCRU } datos listas de datos que van en los desplegables
   */
  function setDatosDialogo(datos: canalesCRU) {
    dispatch({
      type: ACTIONS.SET_DATOS_DIALOGO,
      payload: datos
    });
  }

  /**
   * Función que guarda los datos del canal que vamos a modificar
   * @param { canalesRespuesta } canal el canal a modificar
   */
  function setCanalModificar(canal: canalesRespuesta) {
    dispatch({ type: ACTIONS.SET_CANAL_MODIFICAR, payload: canal });
  }

  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }

  /**
   * Función que abre el diálogo listo para crear un nuevo canal
   */
  function abrirDialogoCrear() {
    dispatch({ type: ACTIONS.SET_MOSTRAR_CREAR });
  }

  /**
   * Función que abre el diálogo de modificar con los datos cargados
   */
  function abrirDialogoModificar() {
    dispatch({ type: ACTIONS.SET_MOSTRAR_MODIFICAR });
  }

  /**
   * Función que abre el alert para preguntar al usuario si está seguro de modificar
   * el canal
   * @param { boolean } aviso indica si el alert debe abrirse o no
   */
  function setOpenAviso(aviso: boolean) {
    dispatch({ type: ACTIONS.SET_OPEN_AVISO, payload: aviso });
  }

  /**
   * Función que muestra una lista de itemsOpenHab o section
   * dependiendo de los datos del dispositivo
   * @param { section[] } lista del desplegable
   */
  function setItemsSections(lista: section[]) {
    dispatch({ type: ACTIONS.SET_ITEMS_O_SECTIONS, payload: lista });
  }

  function limpiarCanal() {
    dispatch({ type: ACTIONS.LIMPIAR_CANAL });
  }
  /**
   * Función que se ejecuta cuando modificamos el check de activar
   * @param {React.ChangeEvent<HTMLInputElement>} event Evento que se ejecuta para activar o desactivar
   */
  function onchangeCheked(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    event.target.checked ? (canal.activo = 1) : (canal.activo = 0);
  }

  useEffect(() => {
    const cargarCanalModificar = async () => {
      const respuesta: any = await controllerRequest(
        {
          type: action.OBTENER_DATOS_BY_ID,
          payload: { servicio: rutasServicios.CANALES, id: canalSeleccionado.id as number }
        },
        {
          openSmartTeliaBackdrop: true,
          closeSmartTeliaBackdrop: true
        }
      );
      setCanalModificar(respuesta);
      abrirDialogoModificar();
    };

    if (mostrarDialogo)
      if (canalSeleccionado !== undefined) {
        cargarCanalModificar();
      } else {
        abrirDialogoCrear();
      }
  }, [mostrarDialogo]);

  /**
   * Este useEffect carga los datos de los desplegables par crear o editar canales
   */
  useEffect(() => {
    const cargarDatosDesplegables = async () => {
      await controllerRequest(
        {
          type: action.OBTENER_DATOS_BY_MODULO,
          payload: { servicio: rutasServicios.DISPOSITIVOS }
        },
        {
          openSmartTeliaBackdrop: true
        }
      ).then((response: any) => {
        datosDialogo.dispositivos = response;
        if (canal.idDispositivo !== undefined) {
          datosDialogo.dispositivoSeleccionado = response.find(
            (dispositivo: any) => dispositivo.id == canal.idDispositivo
          );

          /**
           * Si el dispositivo elegido tiene thing cargamos los
           * ItemsOpenHab, si lo que tiene es un idDevice cargamos una lista de sections
           */
          //datosDialogo.dispositivoSeleccionado.idDevice.length !== 0;
          cargarDatosSections(datosDialogo.dispositivoSeleccionado.idDevice, canal.id);
        } else {
          limpiarCanal();
        }
      });

      datosDialogo.tipoMedida = await controllerRequest({
        type: action.OBTENER_DATOS_SIN_MODULO,
        payload: { servicio: rutasServicios.TIPOS_MEDIDAS }
      });

      datosDialogo.tipoUnidad = await controllerRequest(
        {
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.TIPOS_UNIDADES }
        },
        {
          closeSmartTeliaBackdrop: true
        }
      );
      setDatosDialogo(datosDialogo);
    };

    cargarDatosDesplegables();

    const cargarDatosSections = async (idDevice: number, idCanal?: number) => {
      const resultado: section[] = await controllerRequest({
        type: action.SECTION_BY_DEVICE,
        payload: {
          servicio: rutasServicios.SECTIONS,
          idDevice: idDevice,
          idCanal: idCanal,
          onlyFree: true
        }
      });
      // las sections que contienen path (realizan peticiones) no se asocian a canales
      const sections = resultado.filter((section) => !section.path && section.activo);
      setItemsSections(sections);
    };
  }, [canal.idDispositivo]);

  /**
   * Función que crea el nuevo canal con todos los datos recogidos en el formulario
   * @param {FormEvent<HTMLFormElement>} event este evento se lanza cuando pulsamos en el botón crear
   */
  async function handleSubmitCrear(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const canalReady = objectCanal(canal);
    await controllerRequest(
      {
        type: action.CREAR_UN_REGISTRO,
        payload: { servicio: rutasServicios.CANALES, objeto: canalReady }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );

    respuestaSubmit();
  }

  /**
   * Función que se ejecuta cuando pulsamos el botón de modificar un canal
   * @param { FormEvent<HTMLFormElement> }event
   */
  async function handleSubmitModificar(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setOpenAviso(true);
  }

  /**
   * Función que se ejecuta cuando el usuario acepta modificar el canal.
   * Recoge los datos del formulario y los modifica en la base de atos
   */
  async function confirmarAlertAviso() {
    const canalReady = objectCanal(canal);
    await controllerRequest(
      {
        type: action.MODIFICAR_REGISTRO,
        payload: { servicio: rutasServicios.CANALES, objeto: canalReady, id: canal.id }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );
    respuestaSubmit();
  }

  /**
   * Función que procesa la respuesta de la creación del canal
   */
  function respuestaSubmit(): void {
    if (completadoCorrectamente.current) {
      setControl(<TablaCanales key={enumComponentes.ADMINISTRACION} />);
    }
    mostrarSnackBar(propiedadesSnackBar);
  }

  return {
    state,
    handleInputChange,
    handleAutocompleteChange,
    handleInputInvalid,
    onchangeCheked,
    handleSubmitCrear,
    confirmarAlertAviso,
    handleSubmitModificar,
    setOpenAviso
  };
}
