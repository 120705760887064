import React, { useContext } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import { Divider, Typography, Fab, IconButton, Grid, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckedIcon from '@mui/icons-material/RadioButtonChecked';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useStyles from './styles';
import { DeviceManager } from './DeviceManager';
import { section } from '../sections/resources/interfaces/interfaceSection';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import ControlContext from '../../context/control/ControlContext';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { dictionary_devices, dictionary_sections } from '../../resources/enums/plainText';
import { State } from './hooks/useReducerDevice/resources/types/typeState';
import { Events } from './hooks/useReducerDevice/resources/types/typeEvents';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';

export function DeviceInfo({ state, events }: { state: State; events: Events }): JSX.Element {
  const classes = useStyles();
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { setControl } = useContext(ControlContext);

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item>
          <VolverAtras
            backTo={
              <DeviceManager
                pluginSeleccionado={state.pluginSeleccionado}
                key={
                  (state.pluginSeleccionado?.id
                    ? enumComponentes.DEVICES_POR_PLUGIN
                    : enumComponentes.DEVICES) +
                  state.keySecond +
                  state.keyThird
                }
              />
            }
          />
        </Grid>
        <Grid container md={6} sm={12} xs={12} spacing={2} direction="column">
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4">{state.deviceSeleccionado?.nombre}</Typography>
              <Typography variant="subtitle1">
                <i> {state.deviceSeleccionado?.descripcion}</i>
              </Typography>
            </Grid>
            <Grid item container direction="column">
              <Grid item container>
                <Typography variant="h6">{state.deviceSeleccionado?.nombrePlugin}</Typography>
                <Typography variant="h6" className={classes.marginVersion}>
                  {'('}
                </Typography>
                <Typography variant="subtitle2" className={classes.alignVersion}>
                  {state.deviceSeleccionado?.versionPlugin}
                </Typography>
                <Typography variant="h6">{')'}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <i>{state.deviceSeleccionado?.descripcionPlugin}</i>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container justifyContent="space-around">
            <Grid item xs={1}>
              <Typography variant="h5">{dictionary_sections.SECTIONS}</Typography>
            </Grid>
            <Grid item xs={10}>
              {!openSmartTeliaBackdrop && state.sections.length === 0 ? (
                <Typography variant="h6" className={classes.tituloSinSections}>
                  {dictionary_devices.SIN_SECTIONS}
                </Typography>
              ) : (
                <></>
              )}
              <List dense={true}>
                {state.sections.map((section: section) => {
                  const labelId = `list-label-${section.id}`;

                  return (
                    <>
                      <ListItem divider={false} key={section.id} dense={true}>
                        <ListItemAvatar>
                          <Tooltip
                            title={
                              section.activo === 1
                                ? dictionary_devices.DESACTIVAR
                                : dictionary_devices.ACTIVAR
                            }
                          >
                            <Avatar className={classes.botonActivar}>
                              <IconButton onClick={() => events.handleClickSection(section)}>
                                {section.activo === 1 ? (
                                  <CheckedIcon className={classes.iconoActivar} />
                                ) : (
                                  <UncheckedIcon className={classes.iconoActivar} />
                                )}
                              </IconButton>
                            </Avatar>
                          </Tooltip>
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          className={classes.itemTexto}
                          primary={section.etiqueta}
                          primaryTypographyProps={{ variant: 'h6' }}
                          secondary={
                            <>
                              <Typography variant="body2" color="textPrimary">
                                {section.nombre}
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {section.path}
                              </Typography>
                              <Typography variant="subtitle1" color="textPrimary">
                                {section.tipo !== undefined
                                  ? section.tipo.charAt(0).toUpperCase() + section.tipo.slice(1)
                                  : ''}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div aria-label="editar" accessKey="e" tabIndex={0}>
        <Tooltip title={dictionary_devices.MODIFICAR_DEVICE} arrow>
          <Fab
            aria-label="editar-device"
            color="secondary"
            className={classes.fabBoton}
            onClick={() =>
              setControl(
                <DeviceManager
                  deviceSeleccionado={state.deviceSeleccionado}
                  pluginSeleccionado={state.pluginSeleccionado}
                  key={enumComponentes.INFO_MODIFICAR_DEVICE + state.keySecond + state.keyThird}
                />
              )
            }
          >
            <EditIcon />
          </Fab>
        </Tooltip>
      </div>
      <AlertDialog
        titulo={dictionary_sections.AVISO_ACTIVAR}
        open={state.openAviso}
        setOpen={events.setOpenAviso as React.Dispatch<React.SetStateAction<boolean>>}
        onConfirm={events.handleConfirmarAlertAviso}
      />
    </div>
  );
}
