import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path: string;
let respuesta: any;

/**
 * Función que obtiene los dispositivos por tipo de dispositivo
 * @param { strintg } servicio  servicio al que vamos a llamar
 * @param { number } idTipoDispositivo  id del tipo de dispositivo por el que tenemos que filtrar
 * @returns { Promise<any } Promesa que contiene un listado de dispositivos filtrados por tipo de dispositivo
 */

export async function dispositivoByTipoDispositivo(
  servicio: string,
  idTipoDispositivo: number
): Promise<any> {
  path = servicio + `/todos/tipo/${idTipoDispositivo}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene los dispositivos de actuacionpor tipo de dispositivo
 * @param { strintg } servicio  servicio al que vamos a llamar
 * @param { number } idTipoDispositivo  id del tipo de dispositivo por el que tenemos que filtrar
 * @returns { Promise<any } Promesa que contiene un listado de dispositivos filtrados por tipo de dispositivo
 */

export async function dispositivoActuacionByTipoDispositivo(
  servicio: string,
  idTipoDispositivo: number
): Promise<any> {
  path = servicio + `/todos/tipo/actuacion/${idTipoDispositivo}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene dispositivos que tienen alertas filtrados por módulo
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } idModulo idModulo por el que vamos a filtrar
 * @returns { Promise<any> } Promesa que contiene el listado de dispositivos que tienen alertas
 */
export async function dispositivosConAlertas(
  servicio: string,
  idModulo: number,
  signal: AbortSignal
): Promise<any> {
  path = servicio + `/todos/modulo/alertas`;
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({ idModulo: idModulo }),
      {
        signal: signal,
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 *
 * @param { string } servicio  servicio al que vamos a llamar
 * @param { number[] } tiposDispositivos  ids tipos dispositivos para filtrar
 * @param { number } idModulo id del módulo
 * @param { boolean } conEtiquetas indica si queremos saber las etiquetas también o no
 * @returns { Promis<any> } lista de dispositivos filtrados
 */
export async function dispositivosPorTipoDispositivos(
  servicio: string,
  tiposDispositivos: number[],
  idModulo: number,
  conEtiquetas: boolean
): Promise<any> {
  path = servicio + `/tiposDispositivos`;

  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({
        tiposDispositivos: tiposDispositivos,
        idModulo: idModulo,
        conEtiquetas: conEtiquetas
      }),
      {
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
