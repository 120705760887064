import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../functions/encryption';
import { getHeaders } from '../functions/functions';
import { dictionary_entornos } from '../resources/enums/plainText';

let path: string;
let respuesta: any;

/**
 * Función que obtiene todos los registros que están eliminados logicamente, para la papelera
 * @param {string} servicio hace referencia a la ruta de servicios que hay que llamar
 *
 * @return {Promise<any>} retorna una promesa con el resultado de axios que contiene los registros eliminados
 */
export async function obtenerEliminados(servicio: string): Promise<any> {
  path = servicio + '/todos/eliminados/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_SERVICES + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene todos los registros sin filtrar por nada
 * @param  {string} servicio servicio hace referencia a la ruta de servicios que hay que llamar
 *
 *  @return {Promise<any>} retorna una promesa con el resultado de axios que contiene todos los registros
 * correspondientes al servicio que estamos consultando
 */
export async function obtenerTodosGenerico(servicio: string): Promise<any> {
  path = servicio + '/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene todos los elementos activo sin filtrar po modulo ni cliente
 * @param {string} servicio nombre de la ruta de servicios a la que queremos llamar
 *
 * @return {Promise<any>} retorna una promesa con el resultado de axios que contiene el registro correspondiente al id del parámetro
 */
export async function obtenerActivos(servicio: string): Promise<any> {
  path = servicio + '/todos/activos';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene todos los elementos activos o inactivos filtrados por modulo
 * @param  {string} servicio servicio hace referencia a la ruta de servicios que hay que llamar
 * @param  {number} idModulo id del modulo por el que queremos filtrar los resultados
 *
 * @return {Promise<any>} retorna una promesa con el resultado de axios que contiene el registro correspondiente al id del parámetro
 */
export async function obtenerTodos(
  servicio: string,
  versionTable: string | null | undefined
): Promise<any> {
  path = servicio + '/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK, { versiontables: versionTable as string })
      });
    return respuesta;
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene todos los elementos activos o inactivos filtrados por modulo
 * @param  {string} servicio servicio hace referencia a la ruta de servicios que hay que llamar
 * @param  {number} idModulo id del modulo por el que queremos filtrar los resultados
 *
 * @return {Promise<any>} retorna una promesa con el resultado de axios que contiene el registro correspondiente al id del parámetro
 */
export async function obtenerTodosFiltrado(
  servicio: string,
  idModulo: number,
  versionTable: string | null | undefined
): Promise<any> {
  path = servicio + '/todos/modulo/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idModulo: idModulo }),
        {
          headers: getHeaders(dictionary_entornos.BACK, { versiontables: versionTable as string })
        }
      );

    return respuesta;
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene un registro concreto
 * @param {number} id del registro que queremos obtener
 *
 * @return {Promise<any>} retorna una promesa con el resultado de axios que contiene el registro correspondiente al id del parámetro
 */
export async function obtenerUnRegistro(
  servicio: string,
  id: number,
  versionTable: string | null | undefined
): Promise<any> {
  path = servicio + '/' + id;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        headers: getHeaders(dictionary_entornos.BACK, { versiontables: versionTable as string })
      });

    return respuesta;
  } catch (exception) {
    return exception;
  }
}
