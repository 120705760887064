import { AxiosRequestConfig } from 'axios';
import { encriptarJSON } from 'functions/encryption';
import { swrServiceRoutes } from './serviceRoutes';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';

/**
 * Get devices by device types.
 * @param {Array<string> | undefined} deviceType codes of types.
 * @param {number} moduleId
 * @param {boolean} withTags Optional, default = false.
 * @returns {[string | null, (headers: Record<string, unknown>) => Promise<AxiosResponse<any, any>>]} key used by swr and axios request.
 */
export function getDevicesByDeviceTypes(props: {
  deviceType: Array<string> | undefined;
  moduleId: number;
  withTags?: boolean;
}) {
  const { deviceType, moduleId, withTags = false } = props;
  let key: string | null = `${swrServiceRoutes.getDevicesByDeviceTypes}-${JSON.stringify(props)}`;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getDevicesByDeviceTypes,
    headers: getHeaders(dictionary_entornos.BACK),
    data: encriptarJSON({
      tiposDispositivos: deviceType,
      idModulo: moduleId,
      conEtiquetas: withTags
    })
  };
  Object.entries(props).forEach(([, value]) => {
    if (value === undefined) key = null;
  });

  return { key, request };
}
