/**
 * @alias tipoActuacion
 * @typedef tipoActuacion
 * @property { number } id id del tipo de actuación.
 * @property { number } idPlugin id del plugin asociado.
 * @property { number } idSection id de la section asociada.
 * @property { string } valor valor a actuar sobre el device.
 * @property { string } nombre nombre del tipo de actuación.
 */

export interface tipoActuacion {
  id?: number;
  idPlugin: number;
  idSection: number;
  valor: string;
  nombre: string;
}

export const objectTipoActuacion = (elemento?: tipoActuacion): tipoActuacion => {
  let tipoActuacion: tipoActuacion;
  elemento === undefined
    ? (tipoActuacion = {
        idPlugin: 0,
        idSection: 0,
        valor: '',
        nombre: ''
      })
    : (tipoActuacion = {
        id: elemento.id,
        idPlugin: elemento.idPlugin,
        idSection: elemento.idSection,
        valor: elemento.valor,
        nombre: elemento.nombre
      });

  return tipoActuacion;
};
