const commonRoutes = {
  reports: 'reports',
  dispositivos: 'dispositivos',
  documentManager: 'gestorDocumental',
  cardConfigurator: 'configuradorTarjetas',
  tags: 'tags',
  unitsTypes: 'tiposUnidades'
};

export const swrServiceRoutes = {
  //REPORTS
  getWaterQualityReportData: `${commonRoutes.reports}/waterQuality/`,
  getWaterQualityDetailData: `${commonRoutes.reports}/detailWaterQuality/`,
  getConsumptionReportData: `${commonRoutes.reports}/consumption/`,
  getDetailConsumptionReport: `${commonRoutes.reports}/detailConsumption/`,
  getResidualsParametersData: `${commonRoutes.reports}/residualsParameters/`,
  getResidualsParametersDetail: `${commonRoutes.reports}/detailResidualsParameters`,
  getDeviceTypesByReport: `${commonRoutes.reports}/deviceTypes/`,
  downloadReportInPDF: `${commonRoutes.reports}/consumo/download`,
  //DEVICES
  OBTENER_TODOS_DISPOSITIVOS_POR_MODULO: `${commonRoutes.dispositivos}/todos/modulo`,
  getDevicesByDeviceTypes: `${commonRoutes.dispositivos}/tiposDispositivos/`,
  //DOCUMENT MANAGER
  GET_DOCUMENT_MANAGER: `${commonRoutes.documentManager}/`,
  DOWNLOAD_DOCUMENT: `${commonRoutes.documentManager}/download`,
  UPLOAD_DOCUMENTS: `${commonRoutes.documentManager}/upload`,
  DELETE_DOCUMENT: `${commonRoutes.documentManager}/delete`,
  CONSULT_TOTAL_SIZE: `${commonRoutes.documentManager}/consultTotalSize`,
  GET_DOCUMENTS_BY_MODULE: `${commonRoutes.documentManager}/module`,
  SAVE_DOCUMENT_MANAGER: `${commonRoutes.documentManager}/guardar`,
  //CARD CONFIGURATOR
  SAVE_CARD_CONFIGURATION: `${commonRoutes.cardConfigurator}/save`,
  //SHARED_SERVICE_ROUTES
  getAllByModule: `/todos/modulo/`,
  getAll: '/',
  editRecord: '/',
  createRecord: '/',
  deleteRecords: '/eliminado/fisico',
  //TAGS
  getTagsByDevices: `${commonRoutes.tags}/devices/`,
  getTagsByModule: `${commonRoutes.tags}/modulo/`,
  //Units Types
  unitTypes: `${commonRoutes.unitsTypes}`
};
