import { createStyles, makeStyles } from '@mui/styles';

/**
 * Estilos usados en los componenetes de Videovigilancia
 */
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '100%'
    },
    resolucionTarjeta: {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    buttonVideo: {
      padding: 0
    },
    videoHome: {
      height: '410px',
      width: '100%'
    },
    iconAbrir: {
      width: '25px',
      height: '25px'
    },
    dialogVideo: {
      overflowY: 'hidden'
    },
    sinImagenes: {
      minHeight: '100px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    canvasVideo: {
      width: '100%',
      maxHeight: '100%'
    },
    animacionVideo: {
      minHeight: '100px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);
export default useStyles;
