import { dispositivo } from 'components/dispositivos/resources/interfaces/interfaceDispositivo';
import { useGraficas } from 'components/graficas/hooks/useGraficas/useGraficas';
import { GraphTypes } from 'components/graficas/resources/enums/enumGraphTypes';
import { Group } from 'components/graficas/resources/enums/enumGroup';
import { Operation } from 'components/graficas/resources/enums/enumOperation';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import * as React from 'react';
import { useTriggeredRequest } from 'shared/hooks/useRequest';
import { ReportData } from 'components/Reports/shared/types';
import { ArgsGetDetailWaterQuality } from './types';
import { getDetailWaterQuality } from './services';

const initialState = {
  valorMinimo: 0,
  valorMaximo: 0,
  datosGrafico: {},
  detalles: {},
  ready: false
};

const optionsAutoCompleteFrecuencia = [
  { frecuencia: 'Últimas 24h', option: Rango.TODAY, group: Group.HOUR },
  { frecuencia: 'Últimos 7 días', option: Rango.THIS_WEEK, group: Group.DAY },
  { frecuencia: 'Último Mes', option: Rango.THIS_MONTH, group: Group.DAY },
  { frecuencia: 'Último Año', option: Rango.THIS_YEAR, group: Group.MONTH }
];

const FREQUENCY_DEFAULT_VALUE = optionsAutoCompleteFrecuencia[1];

export function useWaterQualityDetail({
  openDetail,
  setOpenDetail
}: {
  openDetail: { open: boolean; row: any };
  setOpenDetail: React.Dispatch<React.SetStateAction<{ open: boolean; row: any }>>;
}) {
  const [objetoPopUp, setObjetoPopUp] = React.useState<{
    valorMinimo: number;
    valorMaximo: number;
    datosGrafico: any;
    detalles: Record<string, unknown>;
    ready: boolean;
  }>(initialState);
  const [frecuencia, setFrecuencia] = React.useState(FREQUENCY_DEFAULT_VALUE);
  const { row } = openDetail;

  const { trigger: getDetailData, isMutating } = useTriggeredRequest<
    ReportData,
    ArgsGetDetailWaterQuality
  >(getDetailWaterQuality);

  const { obtenerDatosGrafica } = useGraficas();

  function handleAutoCompleteFrecuencia(event: any, value: any) {
    if (value.option !== frecuencia.option) {
      setFrecuencia(
        optionsAutoCompleteFrecuencia.filter((options) => options.option === value.option)[0]
      );
    }
  }

  const handleClose = () => {
    setOpenDetail({ open: false, row: undefined });
    setObjetoPopUp(initialState);
  };

  React.useEffect(() => {
    if (openDetail.open)
      obtenerDatosGrafica(
        parseInt(row.codigoMedida),
        frecuencia.option,
        Operation.AVG,
        GraphTypes.area,
        [{ id: row.idDispositivo, nombre: row.medida } as unknown as dispositivo],
        row.unidad,
        undefined,
        undefined,
        undefined,
        undefined,
        row.valorMinimo,
        row.valorMaximo
      ).then((responseGrafica) => {
        getDetailData({
          deviceId: row.idDispositivo,
          measurementCode: parseInt(row.codigoMedida),
          range: frecuencia.option,
          group: frecuencia.group
        }).then((responseDetail) => {
          setObjetoPopUp({
            valorMinimo: row.valorMinimo,
            valorMaximo: row.valorMaximo,
            datosGrafico: responseGrafica,
            detalles: responseDetail?.data.result as ReportData,
            ready: true
          });
        });
      });
  }, [openDetail.open, frecuencia]);

  return {
    objetoPopUp,
    handleAutoCompleteFrecuencia,
    frecuencia,
    setFrecuencia,
    optionsAutoCompleteFrecuencia,
    isMutating,
    handleClose
  };
}
