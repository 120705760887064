import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  primerGrid: {
    height: '100%',
    width: '100%',
    padding: '8px'
  },
  segundoGrid: {
    height: '20px',
    width: '60px'
  },
  animacionCarrusel: {
    marginTop: '22px',
    width: '15px',
    height: '15px',
    borderRadius: '7.5px',
    background: 'none',
    animationName: '$carrusel',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  },
  '@keyframes carrusel': {
    '0%': {
      boxShadow: `-25px -20px 0 -2px ${theme.palette.secondary.main}, 
                      0px -20px 0 2px ${theme.palette.secondary.main}, 
                      25px -20px 0 -2px ${theme.palette.secondary.main}`
    },
    '50%': {
      boxShadow: `25px -20px 0 -2px ${theme.palette.secondary.main}, 
                      -25px -20px 0 -2px ${theme.palette.secondary.main}, 
                      0px -20px 0 2px ${theme.palette.secondary.main}`
    },
    '100%': {
      boxShadow: `0px -20px 0 2px ${theme.palette.secondary.main}, 
                      25px -20px 0 -2px ${theme.palette.secondary.main}, 
                      -25px -20px 0 -2px ${theme.palette.secondary.main}`
    }
  }
}));

export function AnimacionCarrusel(props: { openAnimation?: boolean }): JSX.Element {
  const classes = useStyles();
  const open = props.openAnimation !== undefined ? props.openAnimation : true;

  return (
    <>
      {open === true ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.primerGrid}>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-end"
            className={classes.segundoGrid}
          >
            <div className={classes.animacionCarrusel} />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
