import React, { useContext, useReducer } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { desencriptarRespuesta } from '../functions/encryption';
import { formatToDayStart } from '../functions/functions';
import ControlContext from './control/ControlContext';
import { Modulos } from '../resources/enums/enumModulo';
import { Medida } from '../components/graficas/resources/types/types';
import { Rango } from '../components/graficas/resources/enums/enumRango';
import UserContext from './UserContext';

const modulos = [
  Modulos['ILUMINACIÓN VIARIA'],
  Modulos['GESTIÓN DE AGUA POTABLE'],
  Modulos['PARQUES Y JARDINES'],
  Modulos['GESTIÓN DE RESIDUOS'],
  Modulos.ENERGÍA,
  Modulos['MEDIO AMBIENTE'],
  Modulos['ADMINISTRACIÓN CIUDADANA'],
  Modulos['MOVILIDAD URBANA'],
  Modulos.VIDEOVIGILANCIA,
  Modulos.TELECOMUNICACIONES
];

export function comprobadorPermisosDispositivos(
  arr: any,
  tabla: string,
  dispositivos: Array<{ idDispositivo: number; idModulo: number }>,
  moduloSeleccionado: number
) {
  let newArray: Array<any> = [];
  let indiceDispositivo;
  if (arr.length > 0) {
    const names: Array<string> = Object.getOwnPropertyNames(arr[0]);
    const containsIdDispositivo =
      names.findIndex((item) => item === 'idDispositivo') !== -1 ? true : false;
    if (tabla.includes('dispositivos') || containsIdDispositivo) {
      const property = containsIdDispositivo ? 'idDispositivo' : 'id';

      if (dispositivos.length > 0) {
        if (moduloSeleccionado) {
          const hayDispositivos = dispositivos.filter(
            (dispositivo) => dispositivo.idModulo === moduloSeleccionado
          );
          if (hayDispositivos.length) {
            hayDispositivos.forEach((dispositivoUsuario: any) => {
              indiceDispositivo = arr.findIndex(
                (item: any) =>
                  item[property] === dispositivoUsuario.idDispositivo &&
                  moduloSeleccionado === dispositivoUsuario.idModulo
              );
              if (indiceDispositivo !== -1) {
                const itemFiltradosPorDispositivo = arr.filter(
                  (item: any) => item[property] === dispositivoUsuario.idDispositivo
                );
                if (itemFiltradosPorDispositivo.length > 0) {
                  newArray = newArray.concat(itemFiltradosPorDispositivo);
                }
              }
            });
          } else {
            newArray = arr;
          }
        } else {
          modulos.forEach((modulo) => {
            let hayDispositivos = dispositivos.filter(
              (dispositivo) => dispositivo.idModulo === modulo
            );

            if (hayDispositivos.length) {
              hayDispositivos.forEach((dispositivoUsuario) => {
                indiceDispositivo = arr.findIndex(
                  (item: any) => item[property] === dispositivoUsuario.idDispositivo
                );
                if (indiceDispositivo !== -1) {
                  const itemFiltradosPorDispositivo = arr.filter(
                    (item: any) => item[property] === dispositivoUsuario.idDispositivo
                  );
                  if (itemFiltradosPorDispositivo.length > 0) {
                    newArray = newArray.concat(itemFiltradosPorDispositivo);
                  }
                }
              });
              hayDispositivos = [];
            } else {
              const itemFiltradosPorModulo = arr.filter((item: any) => item.idModulo === modulo);
              newArray = newArray.concat(itemFiltradosPorModulo);
            }
          });
        }

        return newArray;
      }
    }
  }

  return arr;
}

/**
 * Este contexto nos permite tener acceso en toda la aplicación a los datos.
 *
 */
interface datosContext {
  controladorDatosContexto: (tabla: string, datos: Array<any>) => Array<any>;
  getData: (tabla: string, modulo: boolean, id?: number) => any;
  controladorDatosContextoPorId: (tabla: string, datos: Array<any>, payload: any) => any;
  comprobarDatosGraficas: (
    group: number,
    range: Rango,
    medida: Medida | Array<Medida>,
    idDispositivos: Array<number>
  ) => any;
  guardarDatosGraficas: (
    datos: Array<any>,
    group: number,
    range: Rango,
    medida: Medida | Array<Medida>,
    idDispositivos: Array<number>
  ) => void;
  state: any;
}

const Context = React.createContext<datosContext>({
  controladorDatosContexto: () => [],
  getData: () => '',
  controladorDatosContextoPorId: () => '',
  comprobarDatosGraficas: () => '',
  guardarDatosGraficas: () => undefined,
  state: []
});

DatosContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

const ACTIONS = {
  DATOS_TABLAS: 'datosTablas',
  DATOS_GRAFICAS: 'datosGraficas'
};

const reducer = (state: any, action: any) => {
  switch (action.tipo) {
    case ACTIONS.DATOS_TABLAS:
      return { ...state, datosTablas: { ...state.datosTablas, [action.tabla]: action.valor } };
    case ACTIONS.DATOS_GRAFICAS:
      return { ...state, datosGraficas: { ...state.datosGraficas, [action.tabla]: action.valor } };
    default:
      return state;
  }
};

export function DatosContextProvider({
  children
}: InferProps<typeof DatosContextProvider.propTypes>): JSX.Element {
  const { moduloSeleccionado } = useContext(ControlContext);
  const { datosUsuarioContext } = useContext(UserContext);

  const [state, dispatch] = useReducer(reducer, { datosTablas: [], datosGraficas: [] });

  /**
   * Se encarga de guardar los versionTables, obtener datos del contexto o del servicio y actualizar si es necesario, los datos del contexto correspondiente.
   * IMPORTANTE: Siempre devuelve los datos actualizados.
   * @param tabla nombre de la propiedad versiónTable para guardar en el sessionStorage. (Dependiendo de dónde se llame a la función vendrá con una nomenclatura u otra).
   * @param respuestaServicio respuesta del servicio sin desencriptar.
   */
  function controladorDatosContexto(tabla: string, respuestaServicio: any) {
    let respuestaContexto: Array<any> = [];

    if (respuestaServicio.status === 200) {
      const versionTablesEncriptada =
        respuestaServicio.data.versiontables === undefined
          ? null
          : respuestaServicio.data.versiontables;

      desencriptarRespuesta(respuestaServicio);

      if (respuestaServicio.data.result !== undefined && respuestaServicio.data.result.length > 0) {
        sessionStorage.setItem(tabla, versionTablesEncriptada);
        respuestaContexto = respuestaServicio.data.result;
        formatearDatos(respuestaContexto);

        dispatch({ tabla: tabla, valor: respuestaContexto, tipo: ACTIONS.DATOS_TABLAS });
      }
    } else {
      respuestaContexto = state.datosTablas[tabla];
    }

    return respuestaContexto.length > 0
      ? comprobadorPermisosDispositivos(
          respuestaContexto,
          tabla,
          datosUsuarioContext.dispositivos,
          moduloSeleccionado
        )
      : respuestaContexto;
  }

  /**
   * Obtiene el dato correspondiente al Id, dependiendo del estado de la respuesta, lo obtenemos del contexto o de la propia respuesta del servicio.
   * NO guarda el dato en el contexto ni actualiza el versionTable, ya que sólo obtenemos un dato correspondiente a una tabla.
   * IMPORTANTE: Siempre devuelve el dato actualizado.
   * @param tabla nombre de la propiedad versiónTable.
   * @param respuestaServicio respuesta del servicio sin desencriptar.
   * @param {number} id Id del que queremos obtener el dato.
   */
  function controladorDatosContextoPorId(
    tabla: string,
    respuestaServicio: any,
    { id }: { id: number }
  ) {
    let respuestaContexto: Array<any> = [];
    if (respuestaServicio.status === 200) {
      desencriptarRespuesta(respuestaServicio);
      respuestaContexto = respuestaServicio.data.result;
    } else {
      respuestaContexto = getData(tabla, true, id);
    }

    return respuestaContexto;
  }

  function comprobarDatosGraficas(
    group: number,
    range: Rango,
    medida: Medida | Array<Medida>,
    idDispositivos: Array<number>
  ) {
    let idDispositivo = '';
    idDispositivos.forEach((element) => {
      idDispositivo += element.toString() + ',';
    });
    return state.datosGraficas[
      Modulos[moduloSeleccionado] +
        medida +
        `${group.toString()}, ${range.toString()}` +
        idDispositivo
    ];
  }

  function guardarDatosGraficas(
    respuestaServicio: Array<any>,
    group: number,
    range: Rango,
    medida: Medida | Array<Medida>,
    idDispositivos: Array<number>
  ) {
    let idDispositivo = '';
    idDispositivos.forEach((element) => {
      idDispositivo += element.toString() + ',';
    });
    dispatch({
      tabla:
        Modulos[moduloSeleccionado] +
        medida +
        `${group.toString()}, ${range.toString()}` +
        idDispositivo,
      valor: respuestaServicio,
      tipo: ACTIONS.DATOS_GRAFICAS
    });
  }

  /**
   * Obtiene los datos del contexto.
   * IMPORTANTE: No obtenemos los datos actualizados.
   * @param tabla tabla sobre la que queremos los datos.
   * @param {boolean} modulo True para añadir el módulo si fuera necesario.
   * @param {number} id *opcional* El id que queremos consultar.
   */
  function getData(tabla: string, modulo: boolean, id?: number) {
    const tablaStorage = modulo ? Modulos[moduloSeleccionado] + `:${tabla}` : tabla;

    if (id === undefined) {
      return state[tablaStorage] === undefined
        ? state.datosTablas[tablaStorage]
        : state[tablaStorage];
    } else {
      return state.datosTablas[tablaStorage].filter((dato: any) => dato.id === id)[0];
    }
  }

  /**
   * Formatea los datos (fecha) si es necesario para que aparezcan correctamente.
   * @param data
   */
  function formatearDatos(data: any) {
    const keys = Object.keys(data[0]);

    for (const iterator of keys) {
      if (iterator.includes('fecha')) {
        data.forEach((element: any, index: any) => {
          element[iterator] = formatToDayStart(element[iterator]);
          data[index] = element;
        });
      }
    }

    return data;
  }

  return (
    <Context.Provider
      value={{
        controladorDatosContexto,
        getData,
        guardarDatosGraficas,
        comprobarDatosGraficas,
        controladorDatosContextoPorId,
        state
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
