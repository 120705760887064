import React from 'react';
import { isObjectJson } from '../../../../functions/functions';

/**
 * Función que formatea un array de un objeto JSON para mostrarlo de una forma determinada
 * En caso de haber un array, debe de dividirlo por lineas segun los parametros que haya.
 * @param {any[]} array array a formatear
 * @returns {JSX.Element} devuelve el elemento JSX formateado
 */
const arrayToMensaje = (array: any[]): JSX.Element => {
  return (
    <>
      {array.length}
      <br />
      {array.map((a) => objectToMensaje(a))}
    </>
  );
};

/**
 * En caso de que el valor sea un JSON, se formatea para mostrarlo de una forma determinada:
 * Por cada parametro una linea
 * @param {any} object objeto parseado del JSON
 * @returns {JSX.Element} devuelve el elemento JSX formateado
 */
const objectToMensaje = (object: any): JSX.Element => {
  return (
    <>
      {Object.keys(object).map((key) => {
        const value: string | any[] = object[key as keyof typeof object];

        return (
          <>
            {`${key}: `}
            {Array.isArray(value) ? (
              arrayToMensaje(value)
            ) : (
              <>
                {value}
                <br />
              </>
            )}
          </>
        );
      })}
    </>
  );
};

/**
 * Función que formatea el valor a mostrar en el canal en la tarjeta del gis. Ya que puede recibir un valor numerico,
 * una cadena de texto e incluso un JSON.
 * @param {string} valor valor a formatear
 * @returns {any} devolverá el valor formateado ya sea en un string o un JSX.Element
 */
export const formatearValor = (valor: string): string | JSX.Element => {
  return valor == 'NaN' || valor === undefined || valor === null
    ? '-'
    : isObjectJson(valor)
    ? objectToMensaje(JSON.parse(valor))
    : valor;
};
