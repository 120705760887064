import * as React from 'react';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { useConfiguratorProperties } from '../../types/interfacesCardConfigurator';
import { CardProperties } from '../../types/typesCardConfigurator';
import ControlContext from '../../../../context/control/ControlContext';

export function useConfigurator(): useConfiguratorProperties {
  const { controllerRequest } = useControlador();
  const { handleClickOpenConfiguratorAlert } = React.useContext(ControlContext);
  const [openSpinner, setOpenSpinner] = React.useState(true);

  const { tabState, addCard, selectedUserId, moduleStructureState, copySelectedStructure } =
    useConfiguratorContext();
  const [tab, setTab] = tabState;
  const [structureModule, setStructureModule] = moduleStructureState;
  let id,
    value = 0;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    id = parseInt(event.currentTarget.id);
    value = newValue;

    const tabChange = () => {
      setTab({ selectedModule: id, value: value });
      copySelectedStructure.current = [...structureModule[newValue]];
      structureModule[tab.value] = new Array(12).fill(<div data-visible={true} />);
      setStructureModule([...structureModule]);
    };

    handleClickOpenConfiguratorAlert(tabChange);
  };

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: `configuradorTarjetas/`,
        object: { idUsuario: selectedUserId, idModulo: tab.selectedModule }
      }
    })
      .then((response: Array<CardProperties>) => {
        if (response.length > 0) {
          response.forEach((item: CardProperties) =>
            addCard(item, item.tarjeta.position as number)
          );
        }
        copySelectedStructure.current = [...structureModule[tab.value]];
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => setOpenSpinner(false));

    return () => setOpenSpinner(true);
  }, [tab]);

  return { tab, addCard, handleTabChange, openSpinner };
}
