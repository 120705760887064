import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import mapa from '../../../resources/images/MapaSmart.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: '24px',
    minHeight: '812px'
  },
  button_tarjetaHome: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 3px 0px ',
      borderRadius: '15px'
    }
  },
  tarjeta_mapa: {
    background: `url(${mapa})`,
    backgroundSize: 'cover',
    borderRadius: '15px',
    backgroundPosition: 'bottom'
  },
  gridComponentes: {
    minHeight: '372px'
  },
  divider: {
    margin: '30px 0px 30px 0px',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '3px'
  },
  alto100: {
    height: '100%'
  },
  tarjetaDialogos: {
    fontVariant: 'all-small-caps',
    textAlign: 'right',
    fontSize: '0.63rem'
  },
  typographyAlertasPendientes: {
    fontVariant: 'all-small-caps'
  },
  iconWarning: {
    width: '40px',
    height: '40px',
    color: theme.palette.secondary.main
  },
  typographyAlertasSinResolver: {
    fontWeight: 'bold'
  },
  iconComponentes: {
    marginLeft: '-8px'
  },
  iconArrowComponentes: {
    width: '30px',
    height: '30px',
    marginRight: '-8px'
  },
  typographyNumeroAlertas: {
    textAlign: 'right',
    lineHeight: '0.5',
    letterSpacing: '-3px',
    fontSize: '1.1rem'
  }
}));
