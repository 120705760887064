export const iconosMapa: any = {
  luminaria: `<svg width="27" height="27" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 479.5 479.5" style="enable-background:new 0 0 479.5 479.5;" xml:space="preserve">
  <g>
   <path d="M49.5,467.8c0-187.6,0-265.2,0-453.2c11,0,21.7,0,33,0c0,35.5,0,71,0,107.4c2.4-1.6,4.1-2.7,5.6-3.8
     C133.7,84.6,179.3,51,225,17.5c2.6-1.9,6.3-3.1,9.4-3.1c37.6-0.2,75.2-0.3,112.8-0.1c29,0.1,52.1,20,56.2,48.1c0.2,1,0,2.1,0,3.6
     c-36.6,0-72.9,0-110,0c0-6.2,0-12.1,0-18.7c-15.6,0-30.6-0.1-45.6,0.2c-1.7,0-3.8,2.5-5.1,4.3c-36.6,48.7-73.1,97.4-109.6,146.1
     c-15.8,21.1-31.7,42.2-47.4,63.5c-2,2.7-3.3,6.6-3.3,9.9c-0.2,102.2-0.1,94.3-0.1,196.4C71.5,467.8,60.5,467.8,49.5,467.8z"/>
   <path d="M403.6,72.3c-0.6,29.1-27.1,53-57.1,51.9c-28-1-53.8-26.4-52.3-51.9C330.5,72.3,366.9,72.3,403.6,72.3z"/>
   <path d="M380.5,125c1.1,0.9,3.9,2.3,5.2,4.6c5.2,9.6,10,19.5,14.8,29.3c2,4.1,0.5,8.4-3,10.2c-3.8,2-8.2,0.6-10.4-3.6
     c-5-9.7-10-19.5-14.7-29.4C369.9,130.7,373.2,125.2,380.5,125z"/>
   <path d="M317.5,125c6.7,0.2,10.4,5.4,8.1,10.4c-4.9,10.5-10.1,20.7-15.5,30.9c-1.9,3.7-6.4,4.6-9.9,2.8c-3.3-1.8-5-5.8-3.2-9.4
     c5-10.4,10.1-20.7,15.6-30.9C313.6,126.8,316.5,125.8,317.5,125z"/>
   <path d="M431.5,138.8c0.1,6.6-5.7,10.3-10.5,8c-1.2-0.6-2.3-1.4-3.2-2.3c-6.9-6.8-13.7-13.6-20.5-20.5c-3.9-4-4.2-8.4-1-11.6
     c3.2-3.2,7.6-2.9,11.6,1.1c6.9,6.8,13.7,13.6,20.5,20.5C430,135.5,430.9,137.7,431.5,138.8z"/>
   <path d="M304.8,117.1c-1.6,2.7-2.4,4.8-3.9,6.3c-6.9,7.1-14,14.2-21.1,21.1c-3.9,3.8-8.1,3.9-11.3,0.8c-3.2-3.2-3.1-7.5,0.6-11.3
     c7.2-7.3,14.5-14.5,21.8-21.7c2.4-2.4,5.5-3.4,8.4-1.6C301.5,112.2,302.9,114.8,304.8,117.1z"/>
   <path d="M341.4,158.5c0-4.7-0.1-9.4,0-14.1c0.2-5.1,3.1-8.1,7.3-8.2c4.3-0.1,7.4,3,7.6,8c0.2,9.7,0.2,19.4,0,29.1
     c-0.1,5-3.5,7.9-7.8,7.7c-4.1-0.2-6.9-3.1-7.1-8C341.3,168.1,341.4,163.3,341.4,158.5C341.4,158.5,341.4,158.5,341.4,158.5z"/>
  </g>
  </svg>
  `,
  caudalimetro: `<svg width="27" height="27" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
  viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  
  <path d="M28.2,5.8l-2.1,2.5c1.5,2.1,2.4,4.6,2.4,7.3c0,6.9-5.6,12.5-12.5,12.5S3.5,22.5,3.5,15.6S9.1,3.2,16,3.2
  c2.8,0,5.3,0.9,7.4,2.4l2.4-2.2C20.1-1.1,12-1.1,6.3,3.4c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1C5.8,3.8,5.4,4.2,4.9,4.6
  c-6.1,6.1-6.1,16,0,22.1c6.1,6.1,16,6.1,22.1,0c0.4-0.4,0.8-0.9,1.2-1.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
  C32.8,19.6,32.8,11.5,28.2,5.8z"/>
  <path d="M18.6,16.9C18.6,16.9,18.6,16.9,18.6,16.9c0.2-0.4,0.3-0.8,0.3-1.3c0-1.6-1.3-2.9-2.9-2.9c-0.5,0-0.9,0.1-1.3,0.3
  c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
  c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.1,0.5
  c0,1.6,1.3,2.9,2.9,2.9c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.4-0.2
  c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0,0,0,0C18.5,17.1,18.5,17,18.6,16.9z
  "/>
  <path d="M16.7,5V8c0,0.4-0.3,0.7-0.7,0.7S15.3,8.4,15.3,8V5c0-0.4,0.3-0.7,0.7-0.7C16.4,4.3,16.7,4.6,16.7,5z M16,22.6
  c-0.4,0-0.7,0.3-0.7,0.7v2.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.9C16.7,22.9,16.4,22.6,16,22.6z M10.4,7.5
  c0.2,0.3,0.6,0.5,1,0.3c0.3-0.2,0.5-0.6,0.3-1l-0.4-0.6c-0.2-0.3-0.6-0.5-1-0.3C10,6,9.9,6.5,10.1,6.8L10.4,7.5z M6.4,11l0.6,0.4
  c0.3,0.2,0.8,0.1,1-0.3c0.2-0.3,0.1-0.8-0.3-1L7.2,9.7c-0.3-0.2-0.8-0.1-1,0.3C6,10.3,6.1,10.8,6.4,11z M8.2,20.2
  c-0.2-0.3-0.6-0.5-1-0.3l-0.7,0.4c-0.3,0.2-0.5,0.6-0.3,1c0.2,0.3,0.6,0.5,1,0.3l0.7-0.4C8.2,21,8.4,20.5,8.2,20.2z M25,11.3
  l0.5-0.3c0.3-0.2,0.5-0.6,0.3-1c-0.2-0.3-0.6-0.5-1-0.3L24.3,10c-0.3,0.2-0.5,0.6-0.3,1C24.2,11.4,24.7,11.5,25,11.3z M11.5,23.4
  c-0.3-0.2-0.8-0.1-1,0.3l-0.4,0.8c-0.2,0.3-0.1,0.8,0.3,1c0.3,0.2,0.8,0.1,1-0.3l0.4-0.8C12,24.1,11.8,23.6,11.5,23.4z M22.1,6
  c-0.3-0.3-0.8-0.3-1,0l-0.5,0.5c-0.3,0.3-0.3,0.8,0,1c0.3,0.3,0.8,0.3,1,0l0.5-0.5C22.4,6.8,22.4,6.3,22.1,6z M8.3,14.9H5.4
  c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7S8.7,14.9,8.3,14.9z M26.6,14.9h-2.9c-0.4,0-0.7,0.3-0.7,0.7
  s0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7S27,14.9,26.6,14.9z"/>
  <path class="st0" d="M30.1,28c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.5,0.1-0.8c0-0.8-0.1-1.7-0.3-2.5
  c-0.1-0.6-0.3-1.1-0.6-1.6c-0.5-1.2-1.2-2.3-2.1-3.2c-0.6-0.6-1.2-1.2-1.8-1.5c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0l0,0
  c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
  c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c-1.7,1.9-3.1,4.9-3.1,7.4C20.4,32.2,28.5,32.7,30.1,28
  C30.1,28,30.1,28,30.1,28C30.1,28,30.1,28,30.1,28z"/>
  <path d="M29.2,27.2c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0-0.7-0.1-1.3-0.3-2c-0.1-0.4-0.3-0.9-0.5-1.3
  c-0.4-1-1-1.9-1.6-2.6c-0.5-0.5-1-0.9-1.5-1.2c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0l0,0c-0.1,0.1-0.2,0.2-0.4,0.2c0,0-0.1,0.1-0.1,0.1
  c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1C24,19.9,24,20,23.9,20c0,0,0,0,0,0
  c-1.4,1.5-2.5,3.9-2.5,6C21.4,30.6,27.9,31,29.2,27.2C29.1,27.3,29.1,27.3,29.2,27.2C29.1,27.3,29.1,27.2,29.2,27.2z"/>
  <path d="M28.6,4.1L16.8,17.6l-3.5,2.8c-0.6,0.5-1.4,0.4-1.9-0.1c-0.5-0.5-0.6-1.3-0.1-1.9l2.8-3.5L27.6,3.1c0.3-0.2,0.7-0.2,0.9,0
  C28.8,3.4,28.8,3.8,28.6,4.1z"/>
  </svg>`,
  sensor: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
  <path d="M134.8,33.9c2.9-3,2.6-7.2-0.7-10.4c-12.3-11.9-27.2-17.7-44.4-18C73,5.9,58.3,11.7,46,23.4
   c-3.3,3.2-3.7,7.3-0.8,10.4c2.8,3.1,7.2,3,10.6-0.3c19.9-18.6,48.7-18.6,68.7,0.1C127.8,36.8,132.1,36.9,134.8,33.9 M75.4,50.2
   c9.1-7.9,20.2-7.9,29.4,0.1c3.3,2.9,7.6,2.8,10.3-0.1s2.4-7.3-0.7-10.2c-6.9-6.4-15-9.6-24.4-9.8c-9.2,0.2-17.3,3.3-24,9.6
   c-3.3,3.1-3.7,7.4-1,10.4S72,53.2,75.4,50.2 M90.1,54.9C86.2,54.8,83,58.1,83,62c0,3.8,3.3,7,7.1,6.9c3.8,0,7-3.3,7-7.1
   C97.1,58,93.9,54.9,90.1,54.9 M53.2,111.4c0-5.8-4.8-10.5-10.5-10.5c-5.8,0-10.6,4.9-10.5,10.6c0.1,5.8,4.8,10.4,10.6,10.4
   C48.6,121.8,53.2,117.2,53.2,111.4 M95.2,111.4c0-1.7,0-3.5,0-5.2c-0.1-2.9-2.4-5.2-5.2-5.2c-2.8,0-5.2,2.3-5.3,5.2
   c-0.1,3.5-0.1,7.1,0,10.6c0,3,2.3,5.2,5.2,5.2c2.9,0,5.2-2.2,5.3-5.1C95.3,115,95.2,113.2,95.2,111.4 M147.8,111.4
   c-0.1-5.7-4.8-10.4-10.5-10.4c-5.8,0-10.6,4.7-10.6,10.5c0,5.8,4.7,10.5,10.5,10.5C143.1,121.9,147.9,117.1,147.8,111.4
    M158.3,111.5c0,11.5-9.5,20.9-21,21c-11.6,0-21.1-9.5-21.1-21c0-11.5,9.5-21,21-21C148.8,90.4,158.3,99.9,158.3,111.5 M105.7,117.2
   c-0.2,7.8-6.3,14.5-13.9,15.1c-8.4,0.7-15.6-4.4-17.1-12.5c-0.5-2.7-0.4-5.5-0.5-8.3c0.1,0,0.1,0,0.2,0c0-1.9,0-3.8,0-5.7
   c0.2-8.5,7.2-15.4,15.8-15.3c8.5,0,15.5,6.8,15.7,15.3C105.8,109.5,105.8,113.4,105.7,117.2 M63.8,111.5c-0.1,11.5-9.5,20.9-21,20.9
   c-11.5,0-21-9.4-21-20.9c0-11.5,9.5-21.1,21-21.1C54.3,90.4,63.8,100,63.8,111.5 M179.3,114.8v-7c-0.5-2.1-0.8-4.2-1.4-6.3
   c-4.3-15.8-18.6-26.9-35-26.9c-35.3-0.1-70.6-0.1-105.8,0c-18.3,0-33.7,13.8-36.1,32c-0.1,0.4-0.2,0.8-0.3,1.2v7.3
   c0.4,1.9,0.7,3.8,1.3,5.7c4.4,16.3,18.7,27.2,35.9,27.3c8.7,0,17.3,0,26,0c1.2,0,1.8,0.4,2.3,1.5c1.3,3.5,2.8,7,4.2,10.5
   c0.5,1.2,0.9,2.3,1.5,3.8H37.3v10.4h105.3V164h-34.3c0.2-0.7,0.3-1.2,0.5-1.6c1.7-4.2,3.4-8.4,5-12.6c0.4-1.2,1-1.6,2.3-1.5
   c8.7,0.1,17.3,0.1,26,0c17.2-0.1,31.5-11,35.9-27.3C178.6,118.9,178.9,116.9,179.3,114.8"/>
  </svg>
  `,
  cuadroElectrico: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
   <path d="M415.5,29.2c-106.2,0-212.4,0-318.5,0c-4.7,2.2-9.9,3.9-14.1,6.9c-7.9,5.6-11.7,13.9-12.3,23.5c-0.1,1.2,0,2.5,0,3.7
     c0,128.5,0,257.1,0,385.6c0,16.3,8.7,28.6,23.3,32.5c3.9,1,8,1.3,12,1.3c100.1,0.1,200.3,0.1,300.4,0c22.5,0,35.3-13,35.3-35.6
     c0-126.2,0-252.5,0-378.7C441.5,45.5,436.4,37.8,415.5,29.2z M292.2,385.8c-7,16.3-13.9,32.7-21.1,48.9c-3.6,8-11.9,11.5-19.7,8.7
     c-7.6-2.7-12.3-10.8-10.2-18.6c1-3.9,3-7.5,4.5-11.2c2.6-6.2,5.2-12.3,7.7-18.5c0.1-0.3-0.1-0.7-0.3-1.6c-5.8,0-11.7,0.1-17.6,0
     c-14.6-0.2-21.6-11-15.5-24.4c7.1-15.7,14.2-31.4,21.4-47.1c4.4-9.7,13.4-13.4,22-9.4c8.3,3.9,11.2,13,7,22.5
     c-3.8,8.6-7.8,17.2-12.1,26.7c6.7,0,12.4,0,18.1,0C290.7,361.9,297.8,372.7,292.2,385.8z M346.3,271.7c-24.3,0.1-48.7,0-73,0
     c-6.9,0-13.8,0-20.6,0c-30.9,0-61.7,0-92.6,0c-9.3,0-15.7-5.2-16.9-13.4c-1.4-9,4.8-17.1,14.3-18.2c1.7-0.2,3.5-0.1,5.3-0.1
     c60.1,0,120.2,0,180.4,0c1.2,0,2.5,0,3.7,0c9.4,0.6,16,7.5,15.8,16.4C362.4,265.2,355.7,271.7,346.3,271.7z M401.1,155.6
     c-0.2,18-11.6,29.1-29.8,29.1c-38.4,0-76.9,0-115.3,0c-38.3,0-76.5,0-114.8,0c-19.3,0-30.5-11.2-30.5-30.6c0-16.2,0-32.4,0-48.7
     c0.1-17.9,11.6-29.4,29.6-29.4c77.1,0,154.1,0,231.2,0c18.1,0,29.5,11.2,29.6,29.3C401.2,122.1,401.2,138.9,401.1,155.6z"/>
   <path d="M142.9,152.7c18.2,0,35.9,0,53.8,0c0-14.9,0-29.4,0-44.4c-18.4,0-36.2,0-53.8,0C142.9,123.4,142.9,138.1,142.9,152.7z"/>
   <path d="M229.2,152.8c18,0,35.6,0,53.7,0c0-15.1,0-29.8,0-44.6c-18,0-35.8,0-53.7,0C229.2,123.1,229.2,137.8,229.2,152.8z"/>
   <path d="M315.1,152.7c18.3,0,36,0,53.9,0c0-15,0-29.6,0-44.5c-18.2,0-36,0-53.9,0C315.1,123.2,315.1,137.7,315.1,152.7z"/>
  </g>
  </svg>`,
  estacionMeteorologica: `<svg version="1.1" width="27" height="27" id="Layer_2_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M383.4,129.2c-25.4,0-46.6,17.7-51.9,41.5h-44H253h-23.7h-5.9h-42.8c-5.4-23.7-26.6-41.5-51.9-41.5
   c-29.4,0-53.3,23.9-53.3,53.3s23.9,53.3,53.3,53.3c25.4,0,46.6-17.7,51.9-41.5h42.8h5.9H253h34.3h44c5.4,23.7,26.6,41.5,51.9,41.5
   c29.4,0,53.3-23.9,53.3-53.3S412.8,129.2,383.4,129.2z"/>
  <path d="M383.3,276.3c46.8,0.1,42.7,14.8,42.7,42.4c0.1,47.8,0.1,95.6,0,143.3c0,26.3-15,41.5-41.2,41.6
   c-85.7,0.1-171.4,0.1-257.1,0c-26.6,0-41.6-15-41.6-41.1c-0.1-48.5-0.1-97,0-145.5c0-24.8,15.1-40.3,40-40.6
   C158.8,276.1,329.4,276.1,383.3,276.3z M387.9,314.5c-88.4,0-176,0-263.5,0c0,31.9,0,63.1,0,94.2c88.2,0,175.6,0,263.5,0
   C387.9,377.3,387.9,346.4,387.9,314.5z"/>
  <path d="M375.3,57c-7.6-7.6-15.3-15.3-22.9-22.9c-7.1-7.1-14.1-14.2-21.2-21.2c-3.1-3.1-7.9-2.3-9.2,1.6c-0.4,1.1-0.4,2.3-0.4,3.5
   c0,10.1,0,20.3,0,30.4c0,0.7,0,1.4,0,2.4c-1.5,0-2.6,0-3.8,0c-43.8,0-87.5,0-131.3,0c-0.8,0-1.7-0.1-2.4,0.1
   c-1.6,0.3-2.6-0.4-3.7-1.4c-12.1-12.1-24.2-24.2-36.3-36.3c-2.4-2.4-5-2.8-7.4-1.3c-2.3,1.5-3,3.9-2,7.1
   c3.7,11.4,7.1,22.9,11.2,34.2c2.1,5.8,2.9,11.3,0,17c-0.3,0.6-0.4,1.3-0.6,1.9c-3.5,11-7.1,22-10.6,32.9c-1.1,3.5,0.3,6.5,3.5,7.4
   c2.4,0.7,4.3-0.3,6-2c11.6-11.6,23.3-23.2,34.8-34.9c2-2.1,3.9-2.8,6.9-2.8c18.7,0.2,37.5,0.1,56.2,0.1c0.9,0,1.9,0.1,2.9,0.2
   c0,25.6,0,211.9,0,237.6h22.3c0-25.7,0-212,0-237.6c18.2,0,36.1,0,54.4,0c0,1,0,1.9,0,2.8c0,10,0,20,0,30c0,1.2,0.1,2.4,0.5,3.5
   c1.5,3.9,6.1,4.5,9.4,1.2c14.6-14.6,29.2-29.2,43.8-43.8C378.6,63.1,378.6,60.3,375.3,57z"/>
  </g>
  </svg>`,
  sensorHumedad: `<svg viewBox="0 0 500 500" width="27" height="27" xmlns="http://www.w3.org/2000/svg">
  <g transform="matrix(1, 0, 0, 1, -17.047112, -7.388942)">
    <path d="M55.3,497.8c-1.4-0.5-2.8-1-4.1-1.6c-6.4-2.7-10-9.1-9-15.9c1-6.8,6.4-12,13.4-12.3c6.2-0.3,12.5-0.1,19.5-0.1 c0-6.6-0.2-12.8,0-19c0.2-6.4,0-13,1.4-19.1c3.4-14.2,12.4-24.2,25.9-29.7c12.9-5.3,26-10.3,39-15.3c2.9-1.1,6.1-1.7,9.1-1.7 c70.3-0.1,140.6-0.1,211,0c3,0,6.3,0.6,9.1,1.7c12.8,4.8,25.5,9.7,38.1,14.9c18.4,7.5,28.2,22.3,28.2,42.2c0,8.4,0,16.8,0,25.8 c5.6,0,10.8,0,16,0c9.6,0.1,15.6,4.5,16.8,12.5c1.2,8-3,13.9-12.3,16.9c-0.3,0.1-0.5,0.4-0.8,0.5 C322.9,497.8,189.1,497.8,55.3,497.8z"/>
    <path d="M29.2,311.6c0.9-3.6,1.7-7.3,2.7-10.9c10.2-40.1,43-69.9,83.8-75.8c4.4-0.6,9.3-0.2,13.4,1.3c5.4,1.9,8.4,8.6,7.8,14.2 c-0.8,6.5-5.3,11.3-11.8,13c-8.9,2.3-18.3,3.6-26.3,7.6c-23.2,11.4-36.4,30.5-39.9,56.2c-1.1,8.1-5.6,13.2-12.7,14.3 c-7.3,1.1-12.8-2.3-16.2-9.9c-0.2-0.4-0.5-0.8-0.8-1.2C29.2,317.5,29.2,314.6,29.2,311.6z"/>
    <path d="M255.8,319.3c-34.1-0.1-62.4-28-62.8-62.1c-0.1-11.9,4.4-22.5,9.9-32.7c11.5-21.5,26.2-40.7,42.2-58.9 c6.1-6.9,15.7-7,21.8-0.1c17.6,20.1,33.5,41.4,45.6,65.5C333.1,272.2,300.7,319.2,255.8,319.3z"/>
    <path d="M390.4,81.8c-0.3,28.3-21.8,49.6-47.6,49.5c-26.1-0.1-48-21.7-47-47.7c0.3-7.7,3.4-15.7,6.8-22.9 c7.3-15.4,17.9-28.7,29.3-41.4c5.8-6.5,15.4-7.1,21.1-0.9c13.9,15.2,26.5,31.6,34.2,51C389,74.2,389.7,79.3,390.4,81.8z"/>
    <path d="M168.8,167.2c-33.4-0.3-56.7-34.9-43.1-64.5c8.2-17.9,19.8-33.4,32.8-47.9c5.6-6.2,15.2-6.4,20.8-0.3 c13.4,14.6,25,30.5,33.3,48.7C225.7,131.8,203.2,167.3,168.8,167.2z"/>
    <path d="M134.7,377.6c3.6-18.3,20-31.2,39.8-31.3c39.2-0.1,78.4,0,117.6,0c15.6,0,31.3-0.2,46.9,0.1c18.8,0.3,34.8,13.4,38,29.9 c-76.4-0.7-153.1-0.2-229.8-0.3C143.2,376,139.2,377,134.7,377.6z"/>
    <path d="M482.8,315.7c0.1,9-5.9,15.6-13.8,16.1c-8.1,0.6-14.8-5.1-15.8-13.7c-1.3-11.3-4.7-21.9-11-31.4 c-12.3-18.6-29.4-29.8-51.7-32.6c-10.6-1.4-17.1-9-15.2-17.9c1.6-7.7,8-12.6,16.4-11.8c25.2,2.3,46.4,13,63.7,31.2 C471.6,272.7,480.6,293.1,482.8,315.7z"/>
    <path d="M101.4,340.6C92,340.4,85,332,87.1,322.8c5-21.3,18.4-34.8,39.5-40.4c8.6-2.3,16.2,2.3,18.5,10.4c2.2,8-2.2,15.7-11,18.1 c-9.8,2.7-15.8,8.7-18.4,18.6C113.9,336.7,108.3,340.6,101.4,340.6z"/>
    <path d="M410.3,340.6c-6.6-0.1-12.1-3.8-13.9-10.5c-2.8-10.4-9-16.7-19.4-19.4c-7.6-2-12-9.6-10.5-16.8c1.7-8,8.5-12.8,16.8-11.9 c19.3,2.2,40.2,23.4,41.9,42.7C426.1,333.2,419.1,340.6,410.3,340.6z"/>
    <g>
      <path class="st0" d="M229.4,280c0.7-1.1,1.1-2.3,2-3.2c3.6-3.8,7.4-7.4,11.1-11.1c10.3-10.3,20.6-20.6,30.9-30.9 c1-1,2.1-1.8,3.6-1.8c1.7,0,3,0.8,3.8,2.3c0.8,1.5,0.7,3-0.3,4.4c-0.3,0.4-0.6,0.8-1,1.1c-14,14-28,28-41.9,41.9 c-1.4,1.4-2.9,2.2-4.9,1.5c-1.8-0.6-2.9-2.2-2.9-4.1C229.6,280.2,229.5,280.1,229.4,280z" style="fill: rgb(255, 255, 255);"/>
      <path class="st0" d="M242.6,224.4c7.1,0,12.9,5.9,12.9,12.9c-0.1,7-5.8,12.8-12.8,12.8c-7,0.1-12.9-5.8-12.9-12.9 C229.7,230.2,235.6,224.4,242.6,224.4z M242.6,233c-2.4,0-4.3,1.9-4.3,4.2c0,2.4,2,4.4,4.3,4.3c2.3,0,4.2-2,4.2-4.3 C246.9,234.9,245,233,242.6,233z" style="fill: rgb(255, 255, 255);"/>
      <path class="st0" d="M268.4,267.4c7,0,12.9,5.8,12.9,12.9c0,7-5.9,12.9-12.9,12.9c-7,0-12.8-5.8-12.9-12.8 C255.4,273.3,261.3,267.4,268.4,267.4z M272.7,280.3c0-2.4-1.9-4.3-4.2-4.3c-2.4,0-4.4,1.9-4.4,4.3c0,2.3,2,4.3,4.3,4.3 C270.7,284.5,272.7,282.6,272.7,280.3z" style="fill: rgb(255, 255, 255);"/>
    </g>
  </g>
</svg>
  `,
  electrovalvula: `<svg viewBox="0 0 512 512" width="27" height="27" style="enable-background:new 0 0 512 512;" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style type="text/css">
    .st0{fill:#FFFFFF;}
  </style>
    </defs>
    <g>
      <path d="M15.1,213c0.6-0.9,1.2-1.9,1.7-2.8c3.4-6.1,10-9.2,16.2-7.7c6.8,1.6,11.4,7.2,11.8,14.2c0.1,2.5,0,5,0,7.4 c0,2.4,0,4.9,0,7.7c26.2,0,51.9,0,78.5,0c-11.8,23.9-17.9,48.5-17.9,74.6c0,25.9,6,50.4,17.9,74.5c-13.4,0-26.2,0-38.9,0 c-12.9,0-25.8,0-39.5,0c0,4.3,0,8.6,0,12.8c0,9-4.2,14.9-11.8,16.7c-7,1.7-13.1-1.9-17.3-10c-0.1-0.3-0.5-0.4-0.7-0.6 C15.1,337.4,15.1,275.2,15.1,213z"/>
      <path d="M467.2,380.8c-26.3,0-52,0-78.3,0c23.6-49.7,23.6-99.1,0-148.9c26.3,0,52,0,78.3,0c0-5-0.1-9.6,0-14.1 c0.1-9.1,6.5-15.8,15.1-15.7c8.3,0.1,14.7,6.6,14.7,15.5c0.1,59.2,0.1,118.4,0,177.6c0,8.8-6.6,15.3-14.9,15.3 c-8.3,0-14.7-6.6-14.8-15.4C467.1,390.6,467.2,386,467.2,380.8z"/>
      <path d="M181.6,173.1c0-16,0-31.4,0-47.5c-4.8,0-9.2,0-13.6,0c-9.5-0.1-16.3-6.3-16.2-15c0.1-8.5,6.7-14.7,16-14.8 c58.9,0,117.8,0,176.6,0c9.2,0,15.9,6.3,15.9,14.8c0,8.5-6.6,14.8-15.8,14.9c-4.4,0.1-8.9,0-13.7,0c0,15.8,0,31.3,0,47.5 C280.8,149.5,231.4,149.4,181.6,173.1z"/>
      <path d="M330.5,79c-49.7,0-98.9,0-148.9,0c0-1.5,0-3.1,0-4.7c0-12.6,0-25.1,0-37.7c0-11,5.9-17,16.7-17c38.5,0,77,0,115.6,0 c10.6,0,16.6,6,16.6,16.6C330.5,50.3,330.5,64.4,330.5,79z"/>
      <g>
        <path d="M255.8,172.3c74,0.1,134.1,60.5,133.9,134.4c-0.2,73.8-60.4,133.7-134.2,133.7c-73.9-0.1-134.1-60.5-133.9-134.4 C121.8,232.2,182,172.2,255.8,172.3z M256,261c-27.8,0.5-50,25.9-43.8,52.7c2.5,10.7,0.2,16.9-7.7,22.7c-0.5,0.4-1,0.9-1.4,1.4 c-5.8,6.3-5.8,15.3-0.2,21c5.8,5.9,15,5.9,21.4-0.1c3-2.8,6-5.7,8.7-8.8c2.3-2.6,4.3-2.9,7.7-1.7c30.9,11.2,63.5-14.5,59.3-46.7 c-0.7-5.1-4.1-10.8-2.7-15c1.5-4.6,7.3-7.8,11-11.8c5.7-6.3,5.6-15.4-0.1-21c-5.7-5.6-14.8-5.6-21,0.1c-3.4,3.1-6.4,6.7-10,9.7 c-1.3,1.1-3.7,1.5-5.4,1.1C266.5,263.6,261.2,262.2,256,261z"/>
        <path class="st0" d="M256.3,259.9c5.3,1.2,10.5,2.7,15.8,3.6c1.7,0.3,4.1-0.1,5.4-1.1c3.5-3,6.6-6.5,10-9.7 c6.3-5.8,15.3-5.8,21-0.1c5.7,5.6,5.8,14.8,0.1,21c-3.7,4-9.4,7.2-11,11.8c-1.4,4.3,2,9.9,2.7,15c4.1,32.2-28.4,57.9-59.3,46.7 c-3.4-1.2-5.4-0.9-7.7,1.7c-2.7,3.1-5.7,6-8.7,8.8c-6.4,6-15.6,6-21.4,0.1c-5.6-5.7-5.5-14.7,0.2-21c0.4-0.5,0.9-1,1.4-1.4 c7.9-5.8,10.1-12,7.7-22.7C206.3,285.8,228.5,260.4,256.3,259.9z M270.9,305.1c-0.1-8-6.8-14.8-14.9-14.8c-8,0-14.8,6.7-14.9,14.7 c-0.1,8.2,6.9,15.2,15.1,15C264.3,320,270.9,313.2,270.9,305.1z"/>
        <path d="M270.9,305.1c0.1,8-6.6,14.9-14.6,15c-8.2,0.1-15.2-6.8-15.1-15c0.1-8,6.8-14.7,14.9-14.7 C264,290.4,270.8,297.1,270.9,305.1z"/>
        <path class="st0" d="M262.7,198.9v30.4c0,4.1-3,7.1-7.1,7.1c-4.1,0-7.1-3-7.1-7.1v-30.4c0-4.1,3-7.1,7.1-7.1 C259.7,191.9,262.7,194.9,262.7,198.9z M255.6,377.2c-4.1,0-7.1,3-7.1,7.1v29.4c0,4.1,3,7.1,7.1,7.1c4.1,0,7.1-3,7.1-7.1v-29.4 C262.7,380.2,259.7,377.2,255.6,377.2z M198.9,224.3c2,3,6.1,5.1,10.1,3c3-2,5.1-6.1,3-10.1l-4.1-6.1c-2-3-6.1-5.1-10.1-3 c-3,1-4.1,6.1-2,9.1L198.9,224.3z M158.4,259.7l6.1,4.1c3,2,8.1,1,10.1-3c2-3,1-8.1-3-10.1l-5.1-4.1c-3-2-8.1-1-10.1,3 C154.3,252.6,155.4,257.7,158.4,259.7z M176.6,352.9c-2-3-6.1-5.1-10.1-3l-7.1,4.1c-3,2-5.1,6.1-3,10.1c2,3,6.1,5.1,10.1,3 l7.1-4.1C176.6,361,178.6,355.9,176.6,352.9z M346.8,262.8l5.1-3c3-2,5.1-6.1,3-10.1c-2-3-6.1-5.1-10.1-3l-5.1,3 c-3,2-5.1,6.1-3,10.1C338.7,263.8,343.7,264.8,346.8,262.8z M210,385.3c-3-2-8.1-1-10.1,3l-4.1,8.1c-2,3-1,8.1,3,10.1 c3,2,8.1,1,10.1-3l4.1-8.1C215.1,392.4,213.1,387.3,210,385.3z M317.4,209.1c-3-3-8.1-3-10.1,0l-5.1,5.1c-3,3-3,8.1,0,10.1 c3,3,8.1,3,10.1,0l5.1-5.1C320.5,217.2,320.5,212.1,317.4,209.1z M177.6,299.2h-29.4c-4.1,0-7.1,3-7.1,7.1c0,4.1,3,7.1,7.1,7.1 h29.4c4.1,0,7.1-3,7.1-7.1C184.7,302.3,181.7,299.2,177.6,299.2z M363,299.2h-29.4c-4.1,0-7.1,3-7.1,7.1c0,4.1,3,7.1,7.1,7.1H363 c4.1,0,7.1-3,7.1-7.1C370.1,302.3,367,299.2,363,299.2z"/>
      </g>
    </g>
  </svg>
  `,
  sensorResiduos: `<svg viewBox="0 0 512 512" width="27" height="27" style="enable-background:new 0 0 512 512;" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
  </defs>
  <g>
    <path d="M414.8,202.3c-105.9,0-211.4,0-317,0c-2.6,17.2,1.9,33,19.2,41.2c2.2,1,3,2.1,3.2,4.6c2.3,37.8,4.7,75.7,7.1,113.5 c1.5,23.8,2.8,47.7,4.6,71.5c1.9,24.5,21.7,45.3,46,49c0.9,0.1,1.9,0.5,2.8,0.7c50.2,0,100.4,0,150.7,0c4.8-1.3,9.9-2.1,14.4-4.1 c22-9.5,33.4-26.6,34.9-50.4c2.6-41.4,5.1-82.8,7.7-124.2c1.2-18.9,2.4-37.8,3.7-56.7c0.1-1.2,1.3-2.9,2.4-3.4 c13.4-6.4,20.3-17,20.4-31.9C414.9,208.9,414.8,205.7,414.8,202.3z"/>
    <path d="M414.8,187.7c-106.1,0-211.6,0-317.3,0c-1.4-14.8,0.5-28.2,13.4-37.7c6-4.5,12.9-6.5,20.4-6.5c18.3,0,36.7-0.1,55,0.1 c3,0,4.3-1,5.4-3.5c1.9-4.4,3.9-8.8,6.1-13c4.9-9.4,12.8-14.5,23.5-14.5c23.1-0.1,46.3-0.1,69.4,0c10.8,0.1,18.7,5.2,23.7,14.8 c2.2,4.4,4.4,8.8,6.3,13.3c1,2.3,2.2,3,4.7,3c18.1-0.1,36.2-0.1,54.3-0.1c21.1,0,35.2,14.1,35.2,35.2 C414.8,181.6,414.8,184.5,414.8,187.7z"/>
    <path class="st0" d="M240,136.5c0,8.6,7,15.6,15.6,15.5c8.5,0,15.4-6.9,15.5-15.4c0.1-8.5-6.9-15.6-15.5-15.6 C247,120.9,240,127.9,240,136.5"/>
    <path d="M255.3,64.9c-16,0.2-29.9,5.9-41.5,17.1c-4.7,4.5-5,11.5-0.7,16.3c4.2,4.7,11.3,5.3,16.2,1c5.2-4.7,10.8-8.5,17.8-10 c12.4-2.7,23.4,0.1,32.8,8.5c2.4,2.1,4.8,4,8.1,4.3c5.3,0.5,9.8-2.1,11.9-6.8c2.1-4.5,1.1-9.8-2.6-13.3 C285.6,70.8,271.7,65.1,255.3,64.9"/>
    <path d="M253.4,29.2c-26.1,0.4-50.6,10.2-71,29.9c-4,3.9-5.1,9-3,13.6c2.1,4.6,6.6,7.5,11.7,6.8c2.2-0.3,4.5-1.3,6.3-2.6 c3.5-2.5,6.5-5.7,10-8.3c16.8-12.5,35.8-17.5,56.6-15.4c17.1,1.7,32.2,8.4,45.1,19.8c1.5,1.4,3,2.8,4.6,4.1 c4.7,3.7,11.4,3.3,15.5-0.9c4.2-4.2,4.7-11,0.7-15.5c-5-5.7-11-10.3-17.3-14.4C295.3,35,276.2,29.4,253.4,29.2"/>
    <path class="st0" d="M184.7,369.5c-3,5.2-6.8,8.2-12.6,7.3c-7.4-1.2-11.7-9.1-8.1-15.7c5.2-9.6,10.7-19.1,16.4-28.4 c3-5,9.1-6.4,14.3-3.6c9.4,5.2,18.6,10.5,27.8,16c5.3,3.2,6.7,9.7,3.8,14.9c-3,5.2-9.5,7-15.1,4.1c-1.7-0.9-3.3-1.8-5.9-3.2 c4.2,9.5,9.9,16.5,17.4,22.1c23.4,17.5,54.9,14.3,74.6-7.6c3.1-3.5,6.6-6,11.5-5.2c8.6,1.5,12.3,11.2,6.8,17.9 c-12.8,15.4-29.1,25-49,27.4c-34.9,4.3-65.2-12.5-80.7-43.9C185.6,371,185.3,370.5,184.7,369.5z"/>
    <path class="st0" d="M305.7,316.2c-3.1-8.9-8.7-15.8-16.1-21.4c-23.2-17.8-55.2-14.6-74.9,7.4c-3.1,3.5-6.6,6-11.5,5.1 c-8.4-1.4-12.4-11.1-6.9-17.6c17.4-20.7,39.8-30.7,66.8-28.1c29.4,2.9,50.4,18.5,63.4,44.9c0.2,0.3,0.4,0.6,0.8,1.1 c0.8-1.1,1.4-2,2.1-3c3.5-4.4,9.6-5.5,14.3-2.7c4.8,2.8,7.1,9.1,4.4,14.1c-5.3,9.8-11,19.5-16.8,29c-2.9,4.7-8.8,6.1-13.7,3.4 c-9.6-5.3-19.1-10.7-28.4-16.4c-5.1-3.1-6.4-9.6-3.5-14.6c3-5.1,9.3-6.9,14.8-4.2C302.3,314.2,304,315.3,305.7,316.2z"/>
  </g>
</svg>
  `,
  metering: `<svg width="27" height="27" enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
    <path d="m372.6 405.9c-4.7 4.8-7 10.3-4.5 16.8 0.9 2.4 2.6 4.7 4.4 6.6 14.4 14.6 28.9 29.1 43.4 43.5 7.5 7.5 16.1 7.3 23.6-0.4 39-39.8 62.6-87.3 71.1-142.3 0.9-5.5 1.5-11.1 2.3-16.7 0.1-2 0.1-4 0.1-6h-93.4c-3.3 13.3-5.7 26.1-9.7 38.3-7.5 23-20.3 42.9-37.3 60.2m-278.2-98.5h-93.4v6c0.7 5.6 1.4 11.2 2.4 16.7 8.5 55 32.1 102.5 71.1 142.3 7.5 7.7 16.1 7.9 23.6 0.4 14.5-14.4 29-28.9 43.4-43.5 1.8-1.9 3.5-4.2 4.4-6.6 2.5-6.5 0.2-12-4.5-16.8-17-17.3-29.8-37.2-37.3-60.2-4-12.2-6.4-25-9.7-38.3m37.6-117.9c-22.4-22.5-44.5-44.6-67-67.2-0.4 0.6-0.8 1.4-1.3 2-29.2 33.6-48.6 72.1-57.6 115.7-2.2 10.4-3.4 20.9-5.1 31.4v6h94.7c3.5-33.6 15.7-62.7 36.3-87.9m103-93.6c1-4.6 3.4-8.6 6.6-11.6v-47.7c-59 4.1-110.4 25.3-154.2 63.7 22.6 22.5 44.6 44.6 66.9 67 21.4-17.5 45.6-28.7 72.5-33.9 4.1-18.8 7.1-32.5 8.2-37.5m69 186.3c0.1-32.7-29-164.9-32.4-179.9-0.9-3.9-3.2-7.1-6.3-9.2-2.4-1.6-5.3-2.6-8.4-2.6-7 0-13.1 4.9-14.6 11.7-3.3 15-32.5 147.2-32.5 180 0 26 21.1 47.1 47.1 47.1 4.9 0 9.6-0.7 14-2.1 1.5-0.5 2.9-1 4.3-1.6l2.1-0.9c15.8-7.6 26.7-23.8 26.7-42.5m55.9-114.7 66.7-66.7c-30.7-33.2-111.5-66.6-154.4-63.7v47.1c3.3 3 5.8 7.1 6.8 11.7 1.1 5 4.2 18.7 8.2 37.6 27.1 5.1 51.4 16.5 72.7 34m58.5 109.9h94.6v-6c-0.2-1.2-0.7-2.5-0.8-3.8-3-31.8-11.7-62-26.1-90.5-10-19.9-22.4-38.1-36.8-54.1-22.3 22.4-44.5 44.6-66.8 67 20.4 25 32.5 54.3 35.9 87.4"/>
    </svg>
    
  
  `,
  wifi: `<svg width="27" height="27" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M469,170.9c-68.8-66.3-151.2-95.1-246.1-85.6c-65.4,6.6-122.4,33.2-171.2,77.3c-10.5,9.5-19.9,19.3-22.4,33.9
   c0,3.8,0,7.7,0,11.5c0.7,2.4,1.4,4.7,2.2,7.1c10.3,30.3,46.9,39.2,70.1,16.7c35.7-34.7,77.7-56,127.2-62.1
   c42.5-5.3,83.2,1.2,121.9,19.7c22.5,10.7,42.2,25.2,60,42.6c13.4,13.1,33,16.2,48.6,8C485.9,226,490.9,192.1,469,170.9z"/>
  <path d="M259.4,213.6c-25.3,0.2-46.5,4.1-66.9,12.2c-22.9,9.1-43.4,22.2-60.1,40.3c-12,13-14.6,28.6-7.2,44.8
   c7.5,16.4,20.8,25.3,39.2,25.5c12.3,0.1,22.2-5.6,31-13.9c22.2-20.8,48.6-28.1,78.4-22.5c16.8,3.2,31.3,11.1,43.6,23
   c8,7.8,17.3,12.8,28.6,13.4c17.8,0.9,34-9.4,41.2-25.9c7.1-16.2,3.4-34.2-9.9-47.1C343.5,230.7,302.9,214.4,259.4,213.6z"/>
  <path d="M255.7,343.9c-23.1,0.1-42.2,19.3-42.1,42.5c0.1,23.2,19.2,42.1,42.5,42.1c23.1,0,42.1-19.2,42-42.5
   C298,362.7,279,343.9,255.7,343.9z"/>
  </g>
  </svg>
  `,
  parking: `<svg version="1.1" width="27" height="27" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  
  <g>
  <path class="st0" d="M29.2,454.5c0.7,2.2,1.4,4.5,2.2,6.7c4.7,12.2,16.2,20.3,29.2,20.7c1,0,2.1,0,3.1,0c127.9,0,255.7,0,383.6,0
   c18.2,0,31.2-10.5,34.1-27.5c0.4-2.6,0.4-5.3,0.4-7.9c0-127.1,0-254.3,0-381.4c0-19.3-8.2-30.1-26.7-35.4c-0.3-0.1-0.5-0.3-0.7-0.5
   c9.4,0,18.9,0,28.3,0c0,151.2,0,302.4,0,453.5c-151.2,0-302.4,0-453.5,0C29.2,473.3,29.2,463.9,29.2,454.5z"/>
  <path class="st0" d="M56.6,29.2c-7.2,2-14.5,4.5-19,10.9c-3.5,5-5.7,11-8.4,16.5c0-9.1,0-18.3,0-27.4
   C38.4,29.2,47.5,29.2,56.6,29.2z"/>
  <path class="st0" d="M223.3,320.2c0,21.6,0,42.9,0,64.4c-21.7,0-42.9,0-64.4,0c0-85.9,0-171.8,0-258.2c1.7,0,3.3,0,4.8,0
   c30.2,0,60.4,0,90.6,0c49.8,0.1,89.5,34.1,97.3,83.3c7.9,49.7-27.6,99.8-77.2,108.7c-6.9,1.2-14,1.6-21.1,1.8
   C243.5,320.4,233.7,320.2,223.3,320.2z M223.5,191.1c0,21.7,0,43,0,64.3c11.8,0,23.3,0.5,34.7-0.1c16.5-0.9,29.3-15.1,29.6-31.7
   c0.3-16.2-12.2-31.1-28.4-32.3C247.7,190.4,235.7,191.1,223.5,191.1z"/>
  <g>
   <path d="M455.2,29.7c-0.3-0.1-0.5-0.3-0.7-0.5c-132.6,0-265.2,0-397.8,0c-7.2,2-14.5,4.5-19,10.9c-3.5,5-5.7,11-8.4,16.5
     c0,132.6,0,265.2,0,397.8c0.7,2.2,1.4,4.5,2.2,6.7c4.7,12.2,16.2,20.3,29.2,20.7c1,0,2.1,0,3.1,0c127.9,0,255.7,0,383.6,0
     c18.2,0,31.2-10.5,34.1-27.5c0.4-2.6,0.4-5.3,0.4-7.9c0-127.1,0-254.3,0-381.4C481.9,45.7,473.7,34.9,455.2,29.7z M274.4,318.3
     c-6.9,1.2-14,1.6-21.1,1.8c-9.8,0.3-19.7,0.1-30.1,0.1c0,21.6,0,42.9,0,64.4c-21.7,0-42.9,0-64.4,0c0-85.9,0-171.8,0-258.2
     c1.7,0,3.3,0,4.8,0c30.2,0,60.4,0,90.6,0c49.8,0.1,89.5,34.1,97.3,83.3C359.6,259.2,324.1,309.4,274.4,318.3z"/>
   <path d="M259.5,191.3c-11.8-0.9-23.8-0.2-36-0.2c0,21.7,0,43,0,64.3c11.8,0,23.3,0.5,34.7-0.1c16.5-0.9,29.3-15.1,29.6-31.7
     C288.2,207.4,275.7,192.5,259.5,191.3z"/>
  </g>
  </g>
  </svg>
  `,
  anemometro: `<svg version="1.1" width="27" height="27" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  
  <g>
  <path d="M93.5,5.3c5,1.8,10.4,2.9,15,5.5c15.6,8.7,23.6,22.4,23,40.2c-0.6,17.4-9.4,30.3-25,38c-3.4,1.7-4.3,3.4-4.2,6.9
   c0.2,20.6,0.1,41.1,0.1,61.7c0,1.6,0,3.1,0,5.4c7-3.2,13.4-6.1,19.7-9c11.1-5.1,22.1-10.4,33.3-15.4c3.6-1.6,5.1-3.4,5-7.5
   c-0.4-9.3-0.2-18.6-0.1-27.9c0.1-9.5,6.2-16,14.9-15.9c8.5,0.1,14.4,6.5,14.5,15.7c0,4.6,0,9.1,0,14.1c10.1,1.9,19.9,3.8,29.8,5.7
   c4.6,0.9,9.3,2,13.9,2.6c3.4,0.4,4.2,1.9,4.1,5.1c-0.1,21.4-0.1,42.8-0.1,64.1c0,34.4-0.1,68.9,0.1,103.3c0,4.7-1.4,6.1-5.7,6.7
   c-13.9,2-27.7,4.4-42.2,6.8c0,4.5,0,9,0,13.5c-0.1,9.5-6.1,16.2-14.6,16.2c-8.5,0-14.7-6.7-14.7-16.1c-0.1-9.8,0.1-19.6-0.1-29.4
   c0-1.5-0.8-3.8-1.9-4.3c-18.2-8.7-36.5-17.2-55.8-26.1c0,2.6,0,4.4,0,6.2c0,72.5,0,144.9,0,217.4c0,2.6-0.1,5.3-0.9,7.7
   c-2,6.8-8.7,11-15.4,10.1c-7-0.9-12.5-6.6-13-13.8c-0.1-2-0.1-3.9-0.1-5.9c0-130.1,0-260.1,0.1-390.2c0-4-0.9-6.2-4.8-8.1
   c-16.8-7.9-26.6-26.1-24.5-44.1C45.9,25.8,59.6,10.5,78,6.3c1.3-0.3,2.5-0.7,3.7-1.1C85.6,5.3,89.5,5.3,93.5,5.3z M159.8,169.2
   c-0.6,0.1-1.1,0.1-1.5,0.3c-17.9,8.3-35.8,16.5-53.5,24.9c-1.3,0.6-2.4,2.9-2.4,4.5c-0.2,10.1,0,20.2-0.2,30.3
   c0,2.7,0.8,4.1,3.2,5.2c8.8,3.9,17.4,8,26.1,12.1c9.3,4.3,18.5,8.6,28.2,13.1C159.8,229,159.8,199.2,159.8,169.2z"/>
  <path d="M313.8,291.1c-15.4,2.5-31,5-46.9,7.6c0-55.7,0-110.9,0-166.8c8.6,1.6,17,3.1,25.4,4.7c6.2,1.2,12.5,2.3,18.7,3.8
   c1.3,0.3,3.2,2.3,3.2,3.5c0.1,48.4,0.1,96.9,0.1,145.3C314.3,289.7,314,290.2,313.8,291.1z"/>
  <path d="M391.1,278.5c-15.7,2.6-31.2,5.1-47,7.6c0-46.6,0-92.9,0-139.6c15.9,3,31.3,5.9,47,8.9
   C391.1,196.5,391.1,237.2,391.1,278.5z"/>
  <path d="M421.1,273.6c0-37.7,0-74.9,0-112.5c12.5,2.4,24.7,4.5,36.8,7.2c6.7,1.5,10.7,7.1,10.7,14.1c0.1,23.7,0.1,47.3,0,71
   c0,7.3-4.7,13-11.7,14.3C445.1,269.8,433.3,271.6,421.1,273.6z"/>
  <path d="M248.6,461.7c-23.8,0-47.6,0.1-71.5,0c-10.4,0-17.4-8.5-15.1-18.1c1.6-6.8,7.5-11.2,15.2-11.2c13.4-0.1,26.8,0,40.1,0
   c34.1,0,68.2,0,102.3,0c8.3,0,14.4,4.7,15.7,11.8c1.7,9.4-4.9,17.5-14.9,17.5C296.5,461.8,272.6,461.7,248.6,461.7z"/>
  <path d="M394.6,393.1c-18.6,0-37.2,0-55.8,0c-10,0-16.7-5.8-16.8-14.5c-0.1-8.8,6.8-14.9,17-14.9c37.2,0,74.4,0,111.6,0
   c11.6,0,19,9.9,15,20c-2.3,5.9-7.5,9.3-14.7,9.3C432.2,393.2,413.4,393.1,394.6,393.1z"/>
  <path d="M390.8,461.7c-3.8,0-7.5,0.1-11.3,0c-8-0.3-14.2-6.7-14.3-14.5c-0.1-7.8,6-14.5,14-14.8c7.8-0.3,15.7-0.3,23.5,0
   c8.1,0.3,14.1,6.9,14,14.7c0,8.1-6.3,14.3-14.7,14.6C398.3,461.8,394.5,461.7,390.8,461.7C390.8,461.7,390.8,461.7,390.8,461.7z"/>
  <path class="st0" d="M159.8,169.2c0,30,0,59.9,0,90.3c-9.7-4.5-19-8.8-28.2-13.1c-8.7-4-17.4-8.2-26.1-12.1
   c-2.5-1.1-3.3-2.5-3.2-5.2c0.2-10.1,0-20.2,0.2-30.3c0-1.5,1.1-3.9,2.4-4.5c17.8-8.5,35.7-16.7,53.5-24.9
   C158.7,169.3,159.2,169.3,159.8,169.2z"/>
  </g>
  </svg>
  `,
  pluviometro: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M267.6,486.5c-9.3-2.1-18.8-3.4-27.8-6.3c-47.9-15.6-81.1-61.1-81.4-111.4c-0.1-16.4,5-31.7,11-46.7
   c11.6-28.7,26.7-55.7,43.9-81.4c19.8-29.6,40.9-58.4,61.4-87.6c0.7-1.1,1.7-2,2.9-3.4c5.1,6.7,10,12.9,14.8,19.4
   c29.9,40.7,58.4,82.3,81.4,127.4c9,17.7,17.2,35.8,21,55.5c5,25.9-0.4,50.3-13.2,72.9c-20.1,35.4-50.7,55.8-91.2,61
   c-1,0.1-2,0.5-3,0.8C280.8,486.5,274.2,486.5,267.6,486.5z"/>
  <path d="M124.7,57.4c23.1,30.4,45.2,61,60.9,95.9c18.2,40.6-9.8,86.7-53,91.7c-52,6.1-86.9-44.9-71.4-86c7.6-20,18.2-38.3,30-55.8
   c10-14.8,20.9-29.1,31.4-43.6C123.2,58.9,123.9,58.3,124.7,57.4z"/>
  <path d="M402,27.5c18.3,24,36.2,47.3,47.7,74.7c8.4,19.8,5.4,38.2-8.7,54.2c-14.1,15.9-32.2,21.4-52.7,15.7
   c-21.1-5.8-33.6-20.3-37.9-41.6c-2.1-10.5,0.3-20.5,4.5-30.1c9.6-21.8,23.1-41,37.2-60C395.2,36.3,398.4,32.3,402,27.5z"/>
  </g>
  </svg>
  `,
  energiaSolar: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
  <g>
   <defs>
     <rect id="SVGID_1_" x="9.9" y="11.7" width="160.1" height="156.6"/>
   </defs>
   <clipPath id="SVGID_2_">
     <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
   </clipPath>
   <path d="M125,82.4h-9.6v9.5h28.5v-9.5h-9.5v-6.9c0-0.8,0.2-1.6,0.3-2.4h-3h-7.2C124.6,76,124.7,79.1,125,82.4
      M80.2,19.5c0.1-1.6,0.4-4.7,0.5-6.2c0.1-1.6-4.8-1.6-4.8-1.6H57c-4.9,0-4.9,0.4-4.9,0.4v1.4v5.4v24.7c0,0.8,0,1.4-1,1.9
     c-5.9,2.8-8.6,7.4-8.6,13.8v21.9v4.6v1.1c0,0.2,1.6,0.2,1.6,0.2h6.3c1.6,0,1.6-1.6,1.6-1.6v-6.2c0-1.6,1.6-1.6,1.6-1.6h6.3
     c1.6,0,1.6,1.6,1.6,1.6v6.2c0,1.6,1.6,1.6,1.6,1.6h6.2c1.6,0,1.6-0.2,1.6-0.2c0-0.2,0.1-0.4,0.1-0.6c0-9.5,0.1-19-0.1-28.5
     c-0.1-5.7-3.1-9.8-8.2-12.3c-1-0.5-1.3-1-1.3-2c0.1-8.8,0-11.9,0-20.7v-1.3c0-0.4,3.2-0.4,3.2-0.4H77
     C80.1,21.1,80.2,19.5,80.2,19.5 M68.9,134l-6.3,18.2c-0.7,2.1-2.7,3.5-4.9,3.5c-0.6,0-1.2-0.1-1.7-0.3c-2.7-0.9-4.2-3.9-3.2-6.6
     l3.9-11.3h-5.3c-0.6,0-1.1-0.1-1.7-0.3c-2.7-0.9-4.2-3.9-3.2-6.6l6.3-18.2c1-2.7,3.9-4.1,6.6-3.2c2.7,0.9,4.2,3.9,3.2,6.6
     l-3.9,11.3H64c0.6,0,1.2,0.1,1.7,0.2C68.4,128.3,69.9,131.3,68.9,134 M89.7,87.3h-6.3c0,0-1.6,0-1.6,1.6V95c0,0,0,1.6-5.1,1.6H38.9
     c0,0-5.1,0-5.1-1.6v-6.2c0,0,0-1.6-1.6-1.6h-6.3c0,0-1.6,0-1.6,1.6v6.3c0,0,0,1.6-2.4,1.6h-9.5c0,0-2.4,0-2.4,5.1v61.4
     c0,0,0,5.1,5.1,5.1h85.3c0,0,5.1,0,5.1-5.1v-61.5c-0.1,0-0.1-5-2.4-5h-9.4c0,0-2.4,0-2.4-1.6v-6.2C91.3,88.9,91.3,87.3,89.7,87.3
      M127.6,44.1H91.4c-0.8,9.6-1.6,19-2.4,28.6h2c11.6,0.1,23.1,0.1,34.7,0.1c0.6,0,1.2,0.1,1.7,0.1c0.1-0.6,0.3-1.2,0.3-1.8V45.8
     C127.7,45.3,127.7,44.7,127.6,44.1 M92.4,40.5h35.4V12h-0.1c-0.4,0-0.7-0.1-1-0.1H96c-1.2,0-1.4,0.5-1.4,1.5
     c-0.5,6.1-1,12.2-1.5,18.2C92.9,34.5,92.6,37.5,92.4,40.5 M166.6,40.5c0-0.4,0.1-0.8,0-1.2c-0.7-8.7-1.5-17.4-2.2-26.1
     c-0.1-1.2-0.6-1.3-1.6-1.3h-30.2c-0.4,0-0.8,0-1.3,0.1v28.5H166.6z M170.1,72.7v-0.3v-1.3c-0.1-0.3-0.1-0.4-0.1-0.4
     c0-0.2-0.2-0.5-0.2-0.9c-0.5-5.5-0.9-11-1.3-16.5c-0.2-3-0.5-6.1-0.7-7.6v-0.1c0-0.5-0.1-0.9-0.1-1.4h-4.1h-1h-26h-3.2h-1.8v0.1
     c0,0.2-0.1,0.5-0.1,0.8v26.4v0.1v0.1c0,0.4,0.2,0.8,0.3,1.1c0.4,0,0.8-0.1,1.2-0.1H170.1z"/>
  </g>
  </svg>
   `,
  gateway: `<svg version="1.1"  width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  
  <path d="M108.1,266.4c-10.5,0-20.1,0-30.5,0c0-2,0-3.8,0-5.7c0-65.1,0-75.1,0-140.2c0-2.9,0.2-5.9,1.1-8.6c2-6.3,8.9-10.6,15.3-10
  c7.3,0.7,13.1,6.4,14,13.7c0.2,1.4,0.1,2.8,0.1,4.1c0,65.4,0,75.8,0,141.2C108.1,262.7,108.1,264.4,108.1,266.4z"/>
  <path d="M436.3,266.4c-10.6,0-20.2,0-30.2,0c-0.1-1.7-0.3-3.1-0.3-4.6c0-66,0-76.8,0-142.7c0-12.1,9.4-19.8,19.9-16.3
  c5.7,1.9,9.2,5.9,10.2,11.9c0.4,2.2,0.4,4.5,0.4,6.7c0,64.6,0,74,0,138.6C436.3,262,436.3,264,436.3,266.4z"/>
  <path d="M483.7,309.8c-0.1-20.4-12.2-32.4-32.5-32.4c-129.5,0-259,0-388.5,0c-20.3,0-32.4,12.1-32.5,32.4c-0.1,24.5-0.1,48.9,0,73.4
  c0.1,20.4,12.1,32.3,32.5,32.3c64.6,0,129.1,0,193.7,0c64.9,0,129.8,0,194.8,0c20.4,0,32.4-11.9,32.5-32.3
  C483.7,358.7,483.7,334.3,483.7,309.8z"/>
  <g>
  <path class="st0" d="M390.4,360.5c-3.6,0-6.8,0-9.9,0c-3.2,0-4.9-1.7-4.9-4.8c-0.1-12.1-0.1-24.2,0-36.3c0-4,2.6-5,6.1-5
   c12.7,0,25.3,0,38,0c10,0,20.1,0,30.1,0c5.3,0,6.6,1.3,6.6,6.6c0,11,0,22,0,33c0,5.3-1.2,6.5-6.6,6.5c-2.6,0-5.2,0-8.3,0
   c0,4.4,0.1,8.3,0,12.2c-0.1,4.2-1.5,5.7-5.7,5.7c-13.2,0-26.4,0-39.6,0c-4.2,0-5.6-1.5-5.7-5.7C390.4,369,390.4,365.1,390.4,360.5z
    M384.6,323.2c0,9.7,0,19,0,28.7c2.1,0,4,0,5.9,0c8.3,0,8.7,0.4,8.7,8.9c0,2.8,0,5.7,0,8.8c11.3,0,22.1,0,33.4,0
   c0-3.8,0-7.3,0-10.8c0-5.7,1.1-6.8,6.7-6.9c2.6,0,5.2,0,7.9,0c0-9.9,0-19.2,0-28.7C426.3,323.2,405.7,323.2,384.6,323.2z"/>
  <path d="M384.6,323.2c21.1,0,41.7,0,62.6,0c0,9.5,0,18.9,0,28.7c-2.7,0-5.3,0-7.9,0c-5.5,0-6.7,1.2-6.7,6.9c0,3.5,0,7.1,0,10.8
   c-11.3,0-22.1,0-33.4,0c0-3.1,0-5.9,0-8.8c0-8.5-0.4-8.9-8.7-8.9c-1.9,0-3.8,0-5.9,0C384.6,342.2,384.6,332.9,384.6,323.2z"/>
  </g>
  <path class="st0" d="M288.5,360.5c-3.6,0-6.8,0-9.9,0c-3.2,0-4.9-1.7-4.9-4.8c-0.1-12.1-0.1-24.2,0-36.3c0-4,2.6-5,6.1-5
  c12.7,0,25.3,0,38,0c10,0,20.1,0,30.1,0c5.3,0,6.6,1.3,6.6,6.6c0,11,0,22,0,33c0,5.3-1.2,6.5-6.6,6.5c-2.6,0-5.2,0-8.3,0
  c0,4.4,0.1,8.3,0,12.2c-0.1,4.2-1.5,5.7-5.7,5.7c-13.2,0-26.4,0-39.6,0c-4.2,0-5.6-1.5-5.7-5.7C288.4,369,288.5,365.1,288.5,360.5z
  M282.7,323.2c0,9.7,0,19,0,28.7c2.1,0,4,0,5.9,0c8.3,0,8.7,0.4,8.7,8.9c0,2.8,0,5.7,0,8.8c11.3,0,22.1,0,33.4,0c0-3.8,0-7.3,0-10.8
  c0-5.7,1.1-6.8,6.7-6.9c2.6,0,5.2,0,7.9,0c0-9.9,0-19.2,0-28.7C324.4,323.2,303.7,323.2,282.7,323.2z"/>
  <path d="M282.7,323.2c21.1,0,41.7,0,62.6,0c0,9.5,0,18.9,0,28.7c-2.7,0-5.3,0-7.9,0c-5.5,0-6.7,1.2-6.7,6.9c0,3.5,0,7.1,0,10.8
  c-11.3,0-22.1,0-33.4,0c0-3.1,0-5.9,0-8.8c0-8.5-0.4-8.9-8.7-8.9c-1.9,0-3.8,0-5.9,0C282.7,342.2,282.7,332.9,282.7,323.2z"/>
  <g>
  <path class="st0" d="M186.5,360.5c-3.6,0-6.8,0-9.9,0c-3.2,0-4.9-1.7-4.9-4.8c-0.1-12.1-0.1-24.2,0-36.3c0-4,2.6-5,6.1-5
   c12.7,0,25.3,0,38,0c10,0,20.1,0,30.1,0c5.3,0,6.6,1.3,6.6,6.6c0,11,0,22,0,33c0,5.3-1.2,6.5-6.6,6.5c-2.6,0-5.2,0-8.3,0
   c0,4.4,0.1,8.3,0,12.2c-0.1,4.2-1.5,5.7-5.7,5.7c-13.2,0-26.4,0-39.6,0c-4.2,0-5.6-1.5-5.7-5.7C186.5,369,186.5,365.1,186.5,360.5z
    M180.7,323.2c0,9.7,0,19,0,28.7c2.1,0,4,0,5.9,0c8.3,0,8.7,0.4,8.7,8.9c0,2.8,0,5.7,0,8.8c11.3,0,22.1,0,33.4,0
   c0-3.8,0-7.3,0-10.8c0-5.7,1.1-6.8,6.7-6.9c2.6,0,5.2,0,7.9,0c0-9.9,0-19.2,0-28.7C222.4,323.2,201.8,323.2,180.7,323.2z"/>
  <path d="M180.7,323.2c21.1,0,41.7,0,62.6,0c0,9.5,0,18.9,0,28.7c-2.7,0-5.3,0-7.9,0c-5.5,0-6.7,1.2-6.7,6.9c0,3.5,0,7.1,0,10.8
   c-11.3,0-22.1,0-33.4,0c0-3.1,0-5.9,0-8.8c0-8.5-0.4-8.9-8.7-8.9c-1.9,0-3.8,0-5.9,0C180.7,342.2,180.7,332.9,180.7,323.2z"/>
  </g>
  <g>
  <path class="st0" d="M84.6,360.5c-3.6,0-6.8,0-9.9,0c-3.2,0-4.9-1.7-4.9-4.8c-0.1-12.1-0.1-24.2,0-36.3c0-4,2.6-5,6.1-5
   c12.7,0,25.3,0,38,0c10,0,20.1,0,30.1,0c5.3,0,6.6,1.3,6.6,6.6c0,11,0,22,0,33c0,5.3-1.2,6.5-6.6,6.5c-2.6,0-5.2,0-8.3,0
   c0,4.4,0.1,8.3,0,12.2c-0.1,4.2-1.5,5.7-5.7,5.7c-13.2,0-26.4,0-39.6,0c-4.2,0-5.6-1.5-5.7-5.7C84.5,369,84.6,365.1,84.6,360.5z
    M78.8,323.2c0,9.7,0,19,0,28.7c2.1,0,4,0,5.9,0c8.3,0,8.7,0.4,8.7,8.9c0,2.8,0,5.7,0,8.8c11.3,0,22.1,0,33.4,0c0-3.8,0-7.3,0-10.8
   c0-5.7,1.1-6.8,6.7-6.9c2.6,0,5.2,0,7.9,0c0-9.9,0-19.2,0-28.7C120.5,323.2,99.8,323.2,78.8,323.2z"/>
  <path d="M78.8,323.2c21.1,0,41.7,0,62.6,0c0,9.5,0,18.9,0,28.7c-2.7,0-5.3,0-7.9,0c-5.5,0-6.7,1.2-6.7,6.9c0,3.5,0,7.1,0,10.8
   c-11.3,0-22.1,0-33.4,0c0-3.1,0-5.9,0-8.8c0-8.5-0.4-8.9-8.7-8.9c-1.9,0-3.8,0-5.9,0C78.8,342.2,78.8,332.9,78.8,323.2z"/>
  </g>
  <path d="M190.8,185.9c38-35.5,92.5-35.5,130.3-0.2c6.5,6.1,14.7,6.3,20.1,0.5c5.3-5.8,4.7-13.6-1.6-19.7
  c-23.3-22.3-51.3-33.3-83.1-34c-32.6,0.6-60.7,11.6-84.1,34.1c-6.3,6-6.8,14-1.3,19.7C176.3,192,184.5,191.8,190.8,185.9z"/>
  <path d="M303.5,223.9c5.1-5.7,4.4-13.9-1.9-19.7c-12.8-11.9-28.1-17.8-45.5-18.1c-17.8,0.3-33.2,6.3-46.2,18.5
  c-5.9,5.6-6.5,13.9-1.4,19.4s13.3,5.6,19.5,0.1c17.4-15.3,38.5-15.3,55.7-0.2C290.1,229.6,298.3,229.6,303.5,223.9z"/>
  <path d="M242.7,242.6c0,7.2,6,13.3,13.2,13.4c7.2,0.1,13.3-5.9,13.4-13.1c0.1-7.4-6-13.6-13.4-13.5
  C248.7,229.4,242.7,235.4,242.7,242.6z"/>
  </svg>
  `,
  actuador: `<svg width="27" height="27" enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
  
      <path d="m511.1 14.6c0-11.2-2.5-13.7-13.7-13.7h-482.9c-11.2 0-13.6 2.4-13.6 13.6v482.9c0 11.2 2.4 13.7 13.7 13.7h482.9c11.2 0 13.7-2.5 13.7-13.7v-240.9c-0.1-80.6-0.1-161.3-0.1-241.9zm-109.8 164.2c-3.8 27.6-7.9 55.2-11.8 82.8-0.5 3.4-0.4 6.8-0.4 10.2v151.5c0 21.4-11.7 33.2-33 33.2h-200.4c-21 0-32.8-11.8-32.8-32.8 0-41.9-0.6-83.9 0.2-125.8 0.5-26.4-3-52.2-6.6-78.1-5.1-37-10.8-73.9-15.1-111-1.7-14.6-0.5-29.6-0.4-44.4 0-5.4 3.5-8.2 8.7-8.7 1.7-0.2 3.4-0.1 5.1-0.1h282.4c11.6 0 13.3 2.3 13.9 14 2 36.9-4.8 72.9-9.8 109.2z"/>
      <path d="m141.1 281.6v141.2c0 11.9 3.4 15.4 15.2 15.4h199.3c11.8 0 15.3-3.5 15.3-15.2v-147.5h-229.8v6.1zm134.1 36.4c5.2-1.6 8.6 1 11.4 5.2 6 9.2 12.2 18.3 18.3 27.4 1 1.5 2.1 2.9 4.2 5.8v-23.6c0-2.5-0.1-4.9 0.2-7.4 0.6-4.8 3.6-7.6 8.3-7.8 5-0.3 8.2 2.4 9.3 7.3 0.3 1.5 0.4 3 0.4 4.5v54.6c0 5.3-1.1 10-6.9 11.6-5.5 1.6-8.8-1.8-11.6-6l-20.7-31.2c-0.4 0.1-0.8 0.2-1.3 0.3v26c0 7.1-3.6 11.3-9.2 11.2-5.5-0.1-9-4.4-9-11.5v-55.7c0-5.1 1.6-9.1 6.6-10.7zm-90 18.2c0.6-11 9.6-18.5 20.7-18.7 5.3-0.1 10.6-0.1 15.9 0 12.2 0.2 21.1 8.9 21.6 21.1 0.2 6.1 0 12.1 0 18.2 0 5.9 0.1 11.8 0 17.6-0.3 12.5-9.2 21.4-21.7 21.6-5.3 0.1-10.6 0.1-15.9 0-11-0.3-20-8-20.6-18.9-0.8-13.6-0.8-27.3 0-40.9z"/>
      <path d="m140.1 256.7h231.9c6.5-47.6 13-94.8 19.6-142.1h-271c6.5 47.7 12.9 94.8 19.5 142.1zm160.4-98.8c0-8.5 3.1-11.6 11.7-11.6 11.9-0.1 23.9-0.1 35.8 0 6.9 0 10.8 3.3 10.8 8.9s-3.8 9.1-10.6 9.2c-9.6 0.1-19.2 0-29.1 0-0.2 1.7-0.3 2.8-0.3 3.9v8c10.1 0 19.7-0.4 29.3 0.2 3.3 0.2 7.6 2 9.6 4.5 4.4 5.6-0.3 13.2-7.8 13.4-10 0.2-20.1 0.1-31 0.1v18.3c0 7.7-3.2 12-8.9 12.1s-9.3-4.4-9.3-11.8c-0.2-18.4-0.2-36.8-0.2-55.2zm-73.8-1.3c0-6.9 3.4-10.2 10.2-10.2 12.7-0.1 25.4-0.1 38.1 0 6.1 0.1 9.7 3.5 9.9 8.7 0.2 5.4-3.6 9.2-9.9 9.4-4.9 0.2-9.9 0.1-14.8 0.1h-15v11.9h24.4c2.3 0 4.6-0.1 6.8 0.1 5.3 0.5 9 4.6 8.8 9.4-0.2 4.9-4 8.5-9.5 8.6-8 0.1-15.9 0-23.9 0.1h-7c0 7.3 0.1 13.7 0 20.1-0.1 6.3-3.7 10.3-9 10.2-5.2 0-9.1-4.1-9.1-10.3-0.1-19.4-0.1-38.8 0-58.1zm-73.9 28.8c0-5.7-0.2-11.4 0-17.1 0.4-13.5 8.9-21.7 22.4-22 5.1-0.1 10.3-0.2 15.4 0 11.8 0.5 20.5 8.9 20.8 20.6 0.3 12.3 0.3 24.7 0 37-0.3 11.7-8.8 20.2-20.6 20.9-5.7 0.4-11.4 0.3-17.1 0-12.2-0.7-20.3-9.1-20.8-21.3-0.3-6-0.1-12.1-0.1-18.1z"/>
      <path d="m119.5 95.6h273v-21.4h-273v21.4z"/>
  
  <path d="m225.2 358.4c0 5.1-0.1 10.3 0 15.4 0.1 3.9-1.6 5.5-5.5 5.3-4-0.2-8-0.1-12 0-3.4 0.1-4.9-1.5-4.8-4.8 0-11-0.2-22 0.2-33 0.1-1.5 2.8-2.9 4.4-4.4 0.1-0.1 0.4 0 0.6 0 20.5 0.4 16.7-3.2 17.1 16.4v5.1z"/>
  <path d="m193.3 187.3c0 5.3-0.1 10.6 0 15.9 0 3.3-1.5 4.9-4.8 4.9h-0.6c-20-0.3-16.6 2.7-16.9-16-0.1-7 0.1-14 0-21-0.1-3.8 1.4-5.5 5.3-5.4 20.5 0.4 16.5-2.8 16.9 15.9 0.1 1.9 0.1 3.8 0.1 5.7z"/>
  </svg>
  
  `,
  colmena: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
<g>
 <path d="M45.4,94.4c8-10.3,8.4-19.5,1.3-27c-2.9-3-6.5-4.9-10.7-5.4c-6.4-0.8-11.8,1.4-16.7,6.4
   c-1.5-1.8-2.9-3.5-4-4.7c-3.7,3.6-7.1,7-10.9,10.8c1.5,1.2,3.1,2.5,4.8,4C5.9,81.7,4.1,85.6,3,89.8v5.4c0.3,0.8,0.6,1.5,0.8,2.3
   c3.1,10.3,13.6,15.8,23.7,12c2.8-1,5.2-3,8.4-4.9c1.1,1.4,2.4,3,3.6,4.5c3.7-3.7,7.1-7.1,10.9-10.9C48.9,97,47.1,95.7,45.4,94.4z"
   />
 <path d="M253,200.6c-0.3-0.8-0.5-1.7-0.8-2.5c-3-10.1-13.1-15.6-23.1-12.2c-3.1,1-5.8,3.1-9.1,5
   c-1.1-1.3-2.5-2.8-3.6-4.2c-3.5,3.5-6.9,6.9-10.7,10.7c1.5,1.1,3.1,2.4,4.7,3.6c-7.9,11.6-8.1,20.4-0.8,27.4
   c3.4,3.3,7.6,5.1,12.3,5.2c5.7,0.2,10.4-2.2,14.3-6.2c1.7,1.6,3.3,3.1,4.7,4.5c3.5-3.5,6.8-6.9,10.6-10.6c-1.7-1.2-3.4-2.6-5.3-4
   c3.9-3.6,5.7-7.5,6.7-11.9V200.6z"/>
 <path d="M191.6,145.9c2.9-11.4-0.8-19.8-10.9-25.4c0.2-0.6,0.3-1.2,0.5-1.7c3.2-12.2-5-24.3-17.6-25.1
   c-8.8-0.6-17.6-0.3-26.3-0.3c-1,0-1.9,0-3,0V34.6h109.3V20.2H9.9v14.5h109.3v58.7c-1,0-1.9,0-2.7,0c-7.8,0-15.6,0-23.4,0
   c-10.6,0.1-18.1,5.6-20.8,15.4c-1.1,3.9-0.7,7.9,0.6,11.8c-0.4,0.3-0.7,0.5-1.1,0.7c-8.8,5-12.4,13.2-10.1,23.1
   c0.4,1.7-0.4,2.1-1.5,2.7c-9.8,5.3-13.3,17.7-7.9,27.4c2.3,4.1,5.7,6.8,9.7,8.8c-2.9,11.4,0.8,19.7,10.8,25.4
   c-0.1,0.5-0.2,0.9-0.3,1.4c-3.4,13.3,5.6,25.5,19.4,25.6c23.4,0.2,46.7,0.2,70.1,0c9.8-0.1,17.4-6.2,19.5-15.7
   c0.8-3.7,0.1-7.7,0.1-11.8c10-6.4,12.5-12.2,10.2-25c7.6-3.9,12.1-9.9,12.1-18.7C203.7,155.7,199.1,149.8,191.6,145.9z"/>
 <path d="M207.4,98.4c1.2-1.5,2.6-3,4-4.7c10,8,19.1,8.3,26.7,1.5c3-2.7,4.9-6.1,5.5-10.1c1.1-6.7-1.1-12.4-6.3-17.6
   c1.8-1.5,3.5-2.8,4.8-3.9c-3.6-3.6-6.9-7-10.5-10.6c-1.2,1.5-2.5,3.1-3.8,4.7c-10.9-7.9-19.5-8.3-27-1.3c-2.9,2.7-4.8,6.2-5.4,10.1
   c-1,6.6,1.2,12.3,6.3,17.4c-1.8,1.5-3.4,2.9-4.8,4.1C200.5,91.5,203.8,94.9,207.4,98.4z"/>
</g>
</svg>`,
  aemet: `<svg version="1.1" width="27" height="27" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M502.5,308.6c-32.3-32.3-64.7-64.7-97-97c-1.4-1.4-2.8-2.9-4.5-4.8c3.9-3.7,7.5-6.8,10.7-10.3c7.1-7.6,7.3-18.2,0-25.6
   c-23.3-23.6-46.8-47.1-70.4-70.4c-7.6-7.5-18.3-7.3-26,0.2c-3.4,3.3-6.6,6.7-10.1,10.3c-2-1.9-3.5-3.3-5-4.7
   C267.6,73.7,235,41,202.4,8.5c-10.2-10.1-19.9-10.1-30.1,0.1c-17.9,17.8-35.7,35.7-53.5,53.5c-11.3,11.3-11.3,20.5-0.1,31.7
   c14.2,14.2,28.4,28.4,42.8,42.7c-5.1,8.5-6,16.8,0.9,24.3c7.5,8.1,16.4,7.1,24.5,2.5c11.1,11.1,21.7,21.8,32.8,32.9
   c-9.2,8.3-16.7,19-29.7,24c-22.4,8.7-44.3,18.8-66.4,28.2c-5.5,2.3-9.9,5.6-12,11.4c-2.8,7.8-0.6,14.5,5.2,20.2
   c14.9,14.8,29.9,29.6,45.2,44.9c-7.3,7.2-14.4,13.8-20.9,20.9c-2.6,2.8-4.8,6.5-5.6,10.2c-1.8,8,2.1,15.5,9,19.1
   c7.1,3.8,15.6,2.7,21.5-3.2c7.1-7,13.9-14.3,21-21.7c15.3,15.3,29.7,29.8,44.3,44.2c12.2,12.1,26,9.3,32.9-6.6
   c9.8-22.8,19.9-45.5,29.2-68.5c4.8-11.9,15.4-18.1,22.8-27.1c11.3,11.3,22,22.1,32.8,32.9c-4.7,9.9-4.5,17.4,1.1,23.4
   c7.7,8.2,16.6,7.6,25.8,2.3c14.4,14.5,28.6,28.7,42.8,43c10.7,10.7,20.3,10.8,30.9,0.2c17.7-17.7,35.4-35.4,53.1-53.1
   C514,329.2,514,320.1,502.5,308.6z M156,77.6c10-10,20.8-20.8,31.4-31.4c10.3,10.4,21,21.1,31.7,31.9
   c-10.4,10.4-21.1,21.1-31.4,31.3C177.3,98.9,166.5,88.1,156,77.6z M245.9,168.1c-10.1-10.1-20.8-20.8-31.4-31.4
   c10.5-10.5,21.3-21.3,31.9-31.9c10.3,10.4,21,21.1,31.3,31.4C267.1,146.8,256.4,157.6,245.9,168.1z M375.3,297.5
   c-10.3-10.3-21-21-31.2-31.3c10.3-10.3,21-21.1,31.8-31.8c10.3,10.3,21,20.9,31.4,31.2C396.5,276.3,385.7,287.1,375.3,297.5z
    M433.4,356.1c-9.9-9.9-20.6-20.6-30.6-30.7c10.6-10.6,21.4-21.4,32.1-32.1c9.8,9.9,20.5,20.6,30.7,30.8
   C454.9,334.6,444.1,345.5,433.4,356.1z"/>
  <path d="M196.5,465.6c-1.8,0.6-3.5,1.4-5.3,2c-42.6,14.1-81.5,7.3-115.2-22.3c-37.2-32.7-47.9-81.1-30-128.7
   c4.3-11.5,0.6-21.6-9.5-25.6c-10-4-19.7,0.9-24.4,12.2c-7.4,18.1-11,37-11.2,59.9c0.3,3.4,0.5,10.3,1.5,17
   c0.9,6.4,2.2,12.7,3.9,18.9c17.2,63,71.1,107.2,136.4,111.6c23.3,1.6,45.7-2.4,67.3-11.3c10.2-4.2,14.6-14.1,11-23.6
   C217.1,466,207.1,461.8,196.5,465.6z"/>
  <path d="M149.3,444.5c15.4,0.5,32.2-3.8,45.1-11.5c5.4-3.2,8.7-7.8,9.2-14.2c0.6-7-1.9-12.8-7.7-16.7c-6.2-4.2-12.8-4.5-19.4-0.8
   c-11,6.2-22.7,8.5-35.1,5.7c-31.5-7.2-46.7-41.1-31.5-70.1c5.7-10.9,3.3-21.4-6.1-26.6c-9.7-5.4-19.9-1.6-26,9.5
   C47.6,374.9,86.1,442.3,149.3,444.5z"/>
  </g>
  </svg>
  `,
  contadorFiscal: `<svg version="1.1" width="27" height="27" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  
  <g>
  <g>
   <path d="M91.8,300.6c30.5,0,60.7,0,90.9,0c4.7-20,15.3-35.6,32-47.2c1-0.7,1.2-3.5,0.7-4.9c-4.4-12.7-9.1-25.3-13.6-37.9
     c-3.4-9.5-0.2-18,8.1-21.1c8.4-3.1,16.5,1.3,20.1,11.1c4.2,11.4,8.2,22.8,12.4,34.1c0.9,2.4,1.9,4.8,2.9,7.1
     c19.9-2.4,37.8,1.7,53.8,13c16,11.3,25.8,26.8,30.2,45.8c30.5,0,60.6,0,90.8,0c0-69.8,0-139.2,0-208.7c-109.6,0-218.8,0-328.2,0
     C91.8,161.4,91.8,230.8,91.8,300.6z M126.8,171c20.7-18.5,44.3-32,70.8-40.4c19.1-6.1,38.7-9.1,63.5-9.1
     c43.8,0.7,86.5,16.7,123.2,48.6c7.3,6.4,8.4,15.3,2.7,22c-5.6,6.6-14.9,7-22.2,0.6c-24.5-21.4-52.7-35-85-39.5
     c-49.3-6.9-92.9,6.4-131.1,38.1c-3.1,2.5-6.9,4.8-10.8,5.6c-6.3,1.3-12.3-2.6-15-8.3C120.1,182.4,121.4,175.8,126.8,171z"/>
   <path d="M255.8,271c-18.7,0.1-36.9,13-41.4,29.6c28,0,55.6,0,83.3,0C293,283.7,274.8,270.9,255.8,271z"/>
   <path d="M106.7,390.5c-8.3-0.1-15.2,6.9-15.1,15.1c0.1,8.1,6.9,14.8,15,14.7c8.1,0,14.8-6.8,14.9-14.9
     C121.5,397.5,114.8,390.6,106.7,390.5z"/>
   <path d="M405.4,390.5c-8.1,0-14.8,6.8-14.9,14.9c0,8.1,6.7,14.9,14.7,15c8.3,0.1,15.2-6.9,15.1-15.1
     C420.3,397.2,413.5,390.5,405.4,390.5z"/>
   <path d="M501.4,25.1C493.3,12,480.9,4.4,465.5,1.8c-0.8-0.1-1.5-0.6-2.2-0.9c-138.2,0-276.4,0-414.6,0c-8.1,3.3-16.9,5.3-24,10
     C8.6,21.5,1,37.3,0.9,56.6c-0.1,36.9,0,73.7,0,110.6c0,95.8,0,191.6,0,287.4c0,27.2,16.6,48.8,42.3,55c4.6,1.1,9.5,1.5,14.3,1.5
     c132.3,0.1,264.6,0.1,397,0.1c28.9,0,50.5-17.9,55.9-46.1c0.1-0.6,0.5-1.2,0.8-1.8c0-138.2,0-276.4,0-414.6
     C508,40.8,505.9,32.3,501.4,25.1z M106.4,450.3c-24.5-0.1-44.6-20.2-44.7-44.7c0-24.8,20.2-45,45-44.9
     c24.6,0.1,44.6,20.2,44.7,44.7C151.4,430.1,131.2,450.4,106.4,450.3z M405.3,450.3c-24.7-0.1-44.8-20.5-44.7-45.3
     c0.2-24.5,20.4-44.5,45-44.4c24.7,0.1,44.8,20.4,44.7,45.2C450.1,430.4,429.9,450.4,405.3,450.3z M450.3,313.1
     c0,12-5.7,17.6-17.7,17.6c-117.7,0-235.4,0-353.1,0c-12,0-17.8-5.7-17.8-17.6c0-78,0-156.1,0-234.1c0-11.6,5.8-17.4,17.5-17.4
     c58.9,0,117.9,0,176.8,0c58.8,0,117.5,0,176.3,0c12.4,0,18,5.5,18,17.8C450.3,157.4,450.3,235.2,450.3,313.1z"/>
  </g>
  <path class="st0" d="M266,428.7c-11.4,0-21.6-0.1-31.9,0c-4.3,0-8.4-0.4-10.7-4.7c-2.2-4.2-0.3-7.6,2-11c10.7-16,21.3-32,32.1-48
   c3-4.5,8.1-5.6,12.1-3c4.1,2.7,4.9,7.4,2.1,12.2c-0.6,1-1.3,1.9-1.9,2.9c-6.5,9.8-13,19.6-19.6,29.4c-0.9,1.4-1.8,2.8-3.3,5.1
   c3.5,0,6.1,0,8.8,0c8.3,0,16.6,0.1,24.9,0c3.8,0,7.6,0.8,8.5,4.6c0.7,3,0.5,7.1-1.1,9.6c-10.4,16.3-21.3,32.4-32.1,48.5
   c-3.6,5.3-8.3,6.7-12.6,3.8c-4.5-3-5.1-7.9-1.3-13.5C249.7,453,257.6,441.4,266,428.7z"/>
  </g>
  </svg>
  `,
  camara: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M210.8,367.7c-1.9,0.7-3.8,1.9-5.6,1.9c-30.4,0.1-60.8,0.1-91.2,0.1c-1.3,0-2.6-0.2-4-0.3c-1.7-12.9-6.7-24-15.6-33.3
   c-13-13.4-29.1-18-47.4-17.6c-8.5,0.2-14.5,2.8-17.8,10.8c0,36.6,0,73.2,0,109.9c3.2,8.1,9.1,11.5,17.7,10.7c2.6-0.2,5.3,0,8-0.1
   c17.2-0.5,31.3-7.5,42.4-20.6c7.6-9,11.6-19.7,12.9-31.7c2.5,0,4.9,0,7.2,0c33.4,0,66.7,0,100.1,0c6.1,0,11.7-1.6,14.4-7.5
   c2.2-4.9,3.4-10.2,5.1-15.6c-9.2-2.9-17.7-5.5-26.3-8.2C210.7,366.8,210.8,367.2,210.8,367.7z"/>
  <path d="M482,170.4c-1.7-0.6-2.7-1-3.7-1.3C364.8,133.9,251.4,98.6,138,63.4c-12-3.7-18.1-0.5-21.8,11.4
   c-15.1,48.5-30.2,97.1-45.2,145.6c-3.7,11.9-0.4,18.2,11.3,21.9c79.5,24.7,159,49.4,238.5,74.1c2.9,0.9,4.7,0.5,7-1.6
   c49.8-46.8,99.7-93.5,149.6-140.2C478.8,173.4,480.1,172.1,482,170.4z"/>
  <path d="M471.9,222.8c-2.7-0.9-4.5-0.8-6.7,1.3c-28.4,26.7-56.8,53.4-85.3,80c-1,0.9-2.1,1.5-3.2,2.3c0.2,0.5,0.5,0.9,0.7,1.4
   c21.2,6.6,42.4,13.4,63.7,19.6c8,2.4,14.6-1.7,17.1-9.9c8-25.3,15.9-50.6,23.6-76C484.6,232.5,480.8,225.9,471.9,222.8z"/>
  <path d="M181.4,311.9c-2.8,9.5,0.7,16.2,10.1,19.3c7.1,2.3,14.3,4.5,21.5,6.7c18.1,5.6,36.3,11.3,54.4,16.9
   c8.3,2.6,15.2,0.5,18.4-5.9c1.9-3.9,2.9-8.2,4.5-12.8c-35.7-11.1-70.9-22-106.2-33C183,306.6,182.2,309.2,181.4,311.9z"/>
  </g>
  </svg>
  `,
  ptz: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M472.8,45c-144.6,0-289.1,0-433.7,0c-7.7,3.5-10.4,9.6-9.9,17.9c0.4,6.6,0.1,13.3,0.1,20c0,10.3,5.2,15.4,15.6,15.4
   c35.1,0,70.2,0,105.3,0c105.6,0,211.1,0,316.7,0c10.4,0,15.6-5.1,15.6-15.4c0-6.7-0.4-13.3,0.1-20C483.3,54.7,480.5,48.6,472.8,45z
   "/>
  <path d="M55.9,113.4c-1.4,79.9,29,143.7,97.9,184.3c71.4,42.2,145,40.1,214.1-6c62.7-41.8,90-103.2,88.2-178.3
   C322.5,113.4,189.6,113.4,55.9,113.4z M256,273.1c-36.7,0-66.6-30-66.6-66.7c0-36.7,29.9-66.6,66.7-66.6
   c36.7,0,66.6,29.9,66.6,66.7C322.6,243.2,292.7,273.1,256,273.1z"/>
  <path d="M321.2,413.7c-38,35.5-92.5,35.5-130.3,0.2c-6.5-6.1-14.7-6.3-20.1-0.5c-5.3,5.8-4.7,13.6,1.6,19.7
   c23.3,22.3,51.3,33.3,83.1,34c32.6-0.6,60.7-11.6,84.1-34.1c6.3-6,6.8-14,1.3-19.7C335.7,407.6,327.5,407.8,321.2,413.7z"/>
  <path d="M208.4,375.7c-5.1,5.7-4.4,13.9,1.9,19.7c12.8,11.9,28.1,17.8,45.5,18.1c17.8-0.3,33.2-6.3,46.2-18.5
   c5.9-5.6,6.5-13.9,1.4-19.4c-5.1-5.5-13.3-5.6-19.5-0.1c-17.4,15.3-38.5,15.3-55.7,0.2C221.8,370,213.6,370,208.4,375.7z"/>
  <path d="M269.3,347c0-7.2-6-13.3-13.2-13.4c-7.2-0.1-13.3,5.9-13.4,13.1c-0.1,7.4,6,13.6,13.4,13.5
   C263.3,360.2,269.3,354.2,269.3,347z"/>
  <path d="M256,166.5c-21.9,0-39.9,17.9-40,39.7c-0.1,22.1,17.9,40.2,39.9,40.2c21.9,0,39.9-17.8,40-39.7
   C296.1,184.6,278.1,166.5,256,166.5z M256.3,219.8c-7.4,0.2-13.6-6-13.6-13.4c0-7.2,6-13.2,13.2-13.3c7.2,0,13.3,5.9,13.4,13.1
   C269.4,213.5,263.5,219.6,256.3,219.8z"/>
  </g>
  </svg>`,
  videograbador: `<svg version="1.1" width="27" height="27" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  <g>
  <path d="M482.8,370.1c0,20.2,0,40.5,0,60.7c0,13.4-8.9,20.5-22,17.3c-20.8-5.1-41.5-10.2-62.3-15.5c-2.8-0.7-4.1-0.3-5.8,2.1
   c-6.7,9.7-16.1,14.4-28,14.4c-30.4,0-60.7,0-91.1,0c-20.2,0-33.9-13.8-33.9-34.1c0-30.2,0-60.4,0-90.6c0-20.2,13.8-34,34-34.1
   c30.2-0.1,60.4,0,90.6,0c12,0,21.7,4.6,28.4,14.6c1.7,2.5,3.3,2.6,5.9,2c20.2-5.2,40.5-10.2,60.8-15.3c14.6-3.7,23.3,3.1,23.3,18.2
   C482.8,330,482.8,350,482.8,370.1z"/>
  <path d="M163.8,214.3c-18.7,0-37.4,0.1-56.2,0c-37.5-0.2-66.5-30.5-64.9-68c0.6-13.7,5.4-25.9,13.6-36.9c1.8-2.4,3.3-5.5,4-8.4
   c7.5-35,34.5-59.9,70.4-64.6c32.9-4.3,66.1,13.7,81.5,44.7c2,4,4.7,3.5,7.8,3.5c37.7,0.2,66.7,29.9,65.6,67.3
   c-0.9,33.4-29.1,61.6-62.6,62.2C203.3,214.5,183.6,214.2,163.8,214.3C163.8,214.3,163.8,214.3,163.8,214.3z"/>
  <path d="M94,314c0,21.1,0.1,42.2-0.1,63.3c0,3.2,0.7,5,3.9,6.4c11.1,4.9,19.1,13.1,24,24.2c1.2,2.8,2.7,3.6,5.6,3.6
   c20.6-0.1,41.2-0.1,61.7-0.1c10.7,0,18.1,6.6,18.1,16.1c0,9.6-7.3,16.2-18,16.2c-20.4,0-40.8,0.1-61.2-0.1c-3.3,0-5,0.8-6.4,4
   c-9.7,21.4-32.6,32.5-55.2,27.2c-22.6-5.3-38.5-26-37.2-49.6c1.1-19.3,10.8-33.3,28.3-41.6c3.1-1.5,4-3.1,4-6.4
   c-0.1-42.3-0.1-84.7,0-127c0-12.3,11.1-20.3,22-16c6.4,2.5,10.3,8.4,10.3,15.9C94,271.5,94,292.7,94,314z"/>
  <path d="M360.1,119.8c19.1,0,37,0,54.8,0c31.1,0,51.6,20.6,51.6,51.7c0,19.9,0,39.8,0,59.7c0,10.8-6.6,18.1-16.2,18.1
   c-9.5,0-16.2-7.4-16.2-18.1c0-20.2,0-40.5,0-60.7c0-12-6.4-18.3-18.5-18.4c-16.7,0-33.4,0-50.1,0c-1.5,0-2.9,0-5.4,0
   c2.1,2.2,3.4,3.6,4.7,5c5.9,6.7,5.6,16.3-0.5,22.4c-6.2,6.1-16,6.5-22.4,0.3c-11.2-10.8-22.1-21.8-32.9-32.9
   c-6.3-6.5-6.1-15.8,0.2-22.3c10.7-11,21.6-21.8,32.6-32.6c6.6-6.5,16.4-6.5,22.8-0.3c6.5,6.3,6.5,16.2,0.1,23.2
   C363.5,116.3,362.2,117.6,360.1,119.8z"/>
  </g>
  </svg>`,
  calidadDelAguaAbastecimiento: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<g>
 <path d="M118.4,130.8c-2.5,0-4.7,2.1-4.7,4.7c0,2.5,2.1,4.7,4.7,4.7c2.5-0.1,4.7-2.3,4.7-4.8C123.1,132.8,121,130.7,118.4,130.8z"
   />
 <path d="M128.6,94.5c-1.8-0.1-3.3,1.3-3.3,3c0,1.7,1.4,3,3.1,3.1c1.6,0,3.1-1.4,3.1-3C131.7,95.9,130.3,94.5,128.6,94.5z"/>
 <path d="M92,76.3c-1.8,0-3.5,1.6-3.5,3.5s1.6,3.7,3.7,3.5c1.8,0,3.4-1.6,3.4-3.5S94,76.3,92,76.3z"/>
 <path d="M145,79.2c-5.6-10.7-12.8-20.4-20.2-29.9c-10.1-13.1-20.5-25.8-29.1-39.9c-1.1-1.8-2.3-3.5-4.4-4.4c-0.8,0-1.7,0-2.8,0.1
   c-1.2,1-2.7,1.7-3.4,3c-8.3,13.5-18.1,25.8-27.7,38.2c-8.2,10.5-16.4,21.1-22.6,33c-6.7,12.8-10.2,26.2-8,40.8
   c2.4,16.4,10,29.9,22.7,40.4c15.4,12.4,33,17,52.6,13.3c14.1-2.7,26-9.6,35.6-20.4c10-11.4,15.3-24.7,15.9-39.8
   C154.2,101.2,150.6,90,145,79.2z M119.3,70.9c1.7,0,3.1,1.4,3.1,3c0,1.7-1.6,3.1-3.3,3.1s-3-1.4-3-3.1S117.4,70.9,119.3,70.9z
    M100.2,46.3c1.7,0,3.1,1.3,3.1,3c0,1.7-1.3,3.1-3.1,3.1c-1.7,0-3-1.3-3-3.1C97.2,47.7,98.5,46.3,100.2,46.3z M62.3,96.1
   c0-1.7,1.4-3,3.1-3c1.7,0,3.1,1.4,3.1,3.1s-1.6,3.1-3.3,3.1C63.6,99.2,62.3,97.8,62.3,96.1z M78,151.2c-1.7,0-3.1-1.4-3.1-3.1
   c0-1.7,1.3-3,3.1-3c1.7,0,3.1,1.3,3.1,3C81.1,149.8,79.7,151.2,78,151.2z M76.4,128.3c-5.1,0-9.2-4.1-9.2-9.2
   c0-5.2,4.2-9.3,9.3-9.3c5.1,0.1,9.2,4.2,9.2,9.3C85.8,124.2,81.5,128.3,76.4,128.3z M94.8,89.8c-0.1,0.8,0,1.7-0.1,2.4
   c-0.1,1.8-1.4,3-3.1,3s-3-1.3-3-3.1c0-0.7,0-1.6,0-2.3c0-0.4-0.3-0.8-0.7-1c-0.3-0.1-0.8,0-1.1,0.3c-0.7,0.6-1.1,1.1-1.8,1.7
   c-1.4,1.1-3.1,1-4.2-0.1c-1.1-1.3-1.1-2.8,0-4.2c0.6-0.6,1.3-1.1,1.7-1.8c0.3-0.3,0.3-0.8,0.1-1.1c-0.1-0.3-0.6-0.6-0.8-0.6
   c-0.7-0.1-1.6,0-2.3,0c-1.8-0.1-3.1-1.4-3.1-3.1c0-1.8,1.3-3,3.1-3.1c0.6,0,1.3,0.1,1.8,0c0.4-0.1,1.1-0.3,1.3-0.6
   c0.1-0.3-0.1-1-0.4-1.4c-0.4-0.6-1-1-1.6-1.6c-1.1-1.4-1.1-3.1,0.1-4.2c1.1-1.1,3-1.1,4.2,0c0.4,0.4,0.7,0.8,1.3,1.1
   c0.6,0.3,1.1,0.6,1.7,0.8c0.1-0.6,0.4-1.3,0.6-1.8c0.1-0.6,0-1.3,0.1-1.8c0.1-1.6,1.4-2.7,2.8-2.7c1.6,0,2.8,0.8,3.1,2.4
   c0.1,1,0,2,0.1,3c0,0.4,0.3,1,0.6,1c0.3,0.1,0.8,0,1.1-0.3c0.7-0.6,1.3-1.3,1.8-1.8c1-0.8,2.3-1,3.3-0.3c1.4,0.6,2,1.6,2.7,2.5
   c-0.7,1.1-1.1,2-1.7,2.7c-0.4,0.4-0.8,0.8-1.3,1.3c-0.1,0.3-0.4,0.8-0.3,1c0.1,0.3,0.6,0.7,1,0.7c0.8,0.1,1.7,0,2.4,0
   c1.7,0.1,3,1.3,3,3c0,1.6-1.1,3-2.8,3.1c-0.8,0.1-1.7,0-2.7,0.1c-0.4,0-0.8,0.3-1,0.6c-0.1,0.3,0,0.8,0.3,1.1
   c0.6,0.7,1.1,1.1,1.7,1.8c1.1,1.3,1.1,3,0,4.2c-1.1,1.3-3,1.3-4.4,0.1c-0.6-0.6-1.1-1.1-1.8-1.7c-0.3-0.3-0.8-0.4-1-0.3
   C95.2,89,94.8,89.4,94.8,89.8z M98.8,108.8c-1.7,0.1-3-1.3-3.1-3c0-1.7,1.3-3.1,3.1-3.1c1.7,0,3,1.4,3.1,3
   C101.9,107.4,100.5,108.8,98.8,108.8z M133.1,138.6c-1,0-1.8,0.1-2.8,0c-0.8,0-1.4,0.3-1.7,1.1c-0.4,0.8,0,1.3,0.6,1.8
   c0.7,0.7,1.4,1.3,2,2c1.1,1.4,1.1,3.1-0.1,4.4c-1.1,1.1-3,1.1-4.4,0c-0.3-0.3-0.6-0.7-1-1c-0.8-0.6-1.1-2.1-2.8-1.4
   c-1.7,0.7-0.8,2-1,3c-0.1,0.6,0,1.3-0.1,1.8c-0.1,1.6-1.4,2.7-3,2.7c-1.6,0-3-1-3.1-2.7c-0.1-1.1,0-2.3-0.1-3.4
   c-0.1-0.6-0.6-1.1-1-1.4c-0.4-0.1-1.3-0.1-1.7,0.3c-0.8,0.6-1.4,1.4-2.3,2.1c-1.4,1.1-3.1,1.1-4.4-0.1c-1.1-1.1-1.1-3,0-4.2
   c0.6-0.7,1.3-1.4,2-2c0.6-0.6,0.8-1.1,0.6-2c-0.4-1-1-1-1.8-1c-1,0-2,0-3,0c-1.7-0.1-2.8-1.6-2.8-3.1c0-1.6,1.1-2.8,2.8-3
   c1-0.1,1.8-0.1,2.8,0c0.8,0,1.4,0,1.8-1c0.4-1,0-1.6-0.7-2.1c-0.6-0.6-1-1-1.6-1.6c-1.4-1.4-1.4-3.3-0.3-4.5
   c1.3-1.3,3.1-1.3,4.5,0.1c0.7,0.6,1.3,1.4,2,2c0.4,0.3,1.1,0.4,1.6,0.3c0.4-0.3,1-0.8,1.1-1.3c0.1-1,0-2,0.1-3
   c0.1-2,1.4-3.3,3.1-3.3s3,1.3,3.1,3.3c0,0.8,0,1.7,0,2.4c0,0.8,0,1.4,1,1.8c1,0.4,1.6,0.1,2.1-0.6c0.7-0.7,1.4-1.7,2.3-2.1
   c1-0.4,2.3-0.6,3.1-0.1c0.8,0,1.4,1.3,2.1,2.1c-0.6,1-1,1.8-1.4,2.5c-0.6,0.7-1.4,1.3-2,2.1c-0.3,0.4-0.3,1.1,0,1.6
   c0.1,0.4,0.7,0.8,1.1,1c1.1,0.1,2.1,0,3.3,0.1c1.8,0.1,3.1,1.4,3.1,3.1S135,138.5,133.1,138.6z M128.4,106.7
   c-5.1,0-9.2-4.2-9.2-9.3c0-4.9,4.1-9,9.2-9.2c5.1,0,9.3,4.1,9.3,9.2C137.8,102.4,133.5,106.7,128.4,106.7z"/>
 <path d="M76.4,116c-1.6-0.1-3,1.3-3,3c0,1.7,1.3,3.1,3,3.1c1.7,0,3.3-1.4,3.1-3.1C79.5,117.3,78.1,116,76.4,116z"/>
</g>
</svg>`,
  sensorContaminacionEntrada: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<path d="M175.1,140.1c-7.7-0.8-15-3.6-22.5-5.4c-9.6-2.3-19.4-3.5-29.3-2.6c-5.4,0.5-10.5,2-15.4,4.2c-11.7,5.3-23.4,5.4-35.1,0.1
c-13-5.9-26.4-5.7-39.9-2.4c-5.3,1.3-10.3,3.3-15.6,4.6c-3.9,1-7.9,1.3-12.1,2c0-2,0-3.5,0-5.1c0-3.8,0-7.6,0-11.5
c0-3.2,1.5-4.8,4.8-4.9c8.3-0.1,16.4-1.3,24.1-4.7c2.3-1,4.7-1.9,7.2-2.5c10.2-2.6,20.1-1.5,29.7,2.5c6,2.5,12,4.5,18.5,4.6
c5,0.1,9.8-0.5,14.4-2.5c6.9-2.9,11.1-8.1,11.6-15.7c0.6-10.3,0.5-20.7-1.3-30.9c-0.3-1.5-0.8-3-1.4-4.4c-1.5-3.6-4.2-5.6-8.1-5.9
c-0.6,0-1.2-0.1-1.8-0.2c-2.4-0.2-3.9-1.7-4-4.2c-0.1-1.5,0-3.1,0-4.6c0-6.7,0-13.4,0-20.1c0-3.5,1.6-5.1,5.1-5
c12.8,0.4,23.7,5.5,33,14c12.1,11,19.1,24.9,20.7,41.1c0.8,8.1,0.5,16.3,0.7,24.5c0,1.5,0.1,3.1,0.4,4.6c0.9,5.1,4.5,8.4,9.7,9
c0.6,0.1,1.2,0.2,1.8,0.2c2.4-0.1,4.1,1,5.1,3.2C175.1,128.2,175.1,134.2,175.1,140.1z"/>
<path d="M175.1,167c-1.2,2.3-3,3.2-5.7,3c-7.4-0.4-14.5-1.9-21.5-4.6c-6.8-2.6-13.8-4-21.1-3.8c-5.1,0.1-10.1,1.3-14.8,3.4
c-7.6,3.5-15.6,5.3-24,5c-6.8-0.3-13.2-2.1-19.5-4.8c-10.5-4.5-21.1-5.1-31.8-0.3c-3.5,1.5-7.1,2.9-10.8,3.5
c-5.5,0.9-11.1,1.2-16.6,1.6c-2.2,0.2-4.1-1.5-4.2-3.7c-0.1-5.8,0-11.6,0-17.3c4.3-0.6,8.5-1,12.5-1.9c4.2-0.9,8.3-2.3,12.4-3.6
c11.5-3.5,23.1-4.7,34.7-1c3.5,1.1,6.9,2.8,10.4,3.9c11.7,3.8,23.4,3.3,34.6-1.8c9.6-4.3,19.4-4.8,29.5-3.7
c8.4,0.9,16.3,3.6,24.3,5.6c3.7,1,7.6,1.3,11.4,1.9C175.1,154.6,175.1,160.8,175.1,167z"/>
<path d="M56.1,46.9c0,9.3,0,18.7,0,28c0,1.4-0.4,1.8-1.8,1.8c-11.8-0.1-23.7,0-35.5,0c-3.7,0-5.3-1.5-5.3-5.2c0-16.4,0-32.7,0-49.1
c0-3.7,1.5-5.2,5.3-5.2c11.8,0,23.7,0,35.5,0c1.4,0,1.8,0.4,1.8,1.8C56.1,28.2,56.1,37.5,56.1,46.9z"/>
<path d="M90.1,46.8c0,11,0,22,0,33c0,3.7-1.5,5.2-5.2,5.2c-5.1,0-10.3,0-15.4,0c-3.2,0-4.8-1.7-4.8-4.8c0-22.3,0-44.6,0-66.9
c0-3.1,1.7-4.8,4.8-4.8c5.2,0,10.4,0,15.6,0c3.6,0,5.1,1.5,5.1,5.2C90.1,24.8,90.1,35.8,90.1,46.8z"/>
</svg>`,
  sensorContaminacionSalida: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<g>
<path d="M108.5,67.7c-0.8,1.9-2.3,2.5-4.3,2.5c-8.5-0.1-17,0-25.4,0c-2.6,0-4.2-2.2-3.4-4.5c0.5-1.3,1.7-2.2,3.1-2.2
 c1.1,0,2.1,0,3.3,0c0-3.3,0-6.6,0-10c-2.1,0-4.2,0-6.3,0c-0.2,0-0.5,0.3-0.7,0.5c-7,8.2-18.8,8.2-25.7,0c-0.2-0.3-0.6-0.5-0.9-0.5
 c-5.4,0-10.7,0-16.1,0c0,0-0.1,0-0.2,0.1c0,1,0,2.2,0,3.3c-2.3,0-4.4,0-6.6,0c0-0.2-0.1-0.4-0.1-0.6c0-10.9,0-21.8,0-32.6
 c0-2.5,2.3-4.2,4.5-3.3c1.3,0.5,2.1,1.7,2.1,3.3c0,3,0,5.9,0,8.9c0,0.3,0,0.5,0,0.9c0.3,0,0.6,0.1,0.8,0.1c5,0,10.1,0,15.1,0
 c0.4,0,0.9-0.1,1.1-0.4c2.5-3.1,5.7-5,9.6-5.9c0-4.5,0-9,0-13.7c-0.4,0-0.7,0-1.1,0c-3.8,0-7.5,0-11.3,0c-2.4,0-3.8-0.8-4.2-2.6
 c-0.4-1.7,0.4-3,2.4-4.1c11.7,0,23.4,0,35,0c2,1.1,2.8,2.4,2.4,4.1c-0.4,1.8-1.8,2.6-4.2,2.6c-3.8,0-7.5,0-11.3,0
 c-0.4,0-0.7,0-1.1,0c0,4.7,0,9.2,0,13.7c3.9,0.9,7.1,2.9,9.7,6c0.2,0.2,0.7,0.3,1,0.3c2.1,0,4.2,0,6.4,0c9,0.1,16.9,6.4,19,15.1
 c0.8,3.1,0.6,6.2,0.6,9.3c0,1.8,0,3.7,0,5.6c2.6,0.1,5.4-0.7,6.7,2.5C108.5,66.6,108.5,67.2,108.5,67.7z"/>
<g>
 <path d="M91.6,92c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2c3.4,0,6.2-2.8,6.2-6.2C97.8,94.8,95,92,91.6,92z M90.4,97
   c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.3,1.3-1.2c0.7,0,1.2,0.6,1.2,1.3C91.6,96.4,91,97,90.4,97z M92.9,102c-0.7,0-1.3-0.6-1.3-1.3
   c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2C94.1,101.4,93.5,101.9,92.9,102z"/>
 <path d="M104.5,88.5c-3.9-5.2-7.8-10.5-11.7-15.7c-0.7-0.9-1.6-0.9-2.3,0c-3.9,5.2-7.8,10.5-11.7,15.7c-3.4,4.6-4.3,9.7-2.4,15.1
   c1.9,5.5,5.8,9,11.4,10.4c0.9,0.2,1.8,0.3,2.7,0.5c0.7,0,1.4,0,2.2,0c0.9-0.1,1.7-0.2,2.6-0.4c7-1.6,12.3-8,12.5-15.1
   C107.9,95.1,106.8,91.6,104.5,88.5z M101.4,99.5c-0.4,0-0.8,0-1.2,0c-0.2,0.7-0.3,1.3-0.5,2c0.4,0.2,0.8,0.4,1.2,0.7
   c0.4,0.3,0.6,0.7,0.6,1.2c-0.1,0.5-0.4,0.9-1,1c-0.3,0-0.6,0-0.9-0.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.5,0.5-0.9,1-1.5,1.5
   c0.1,0.2,0.3,0.6,0.5,1c0.4,0.7,0.2,1.5-0.4,1.8c-0.6,0.3-1.3,0.1-1.7-0.6c-0.2-0.3-0.4-0.7-0.6-1c-0.7,0.2-1.3,0.3-2,0.5
   c0,0.4,0,0.8,0,1.2c0,0.8-0.5,1.4-1.2,1.4c-0.7,0-1.3-0.6-1.3-1.4c0-0.4,0-0.8,0-1.2c-0.6-0.2-1.2-0.3-1.8-0.5
   c-0.1,0-0.3,0.1-0.3,0.1c-0.2,0.3-0.4,0.6-0.5,0.9c-0.4,0.6-1.1,0.8-1.7,0.5c-0.6-0.3-0.8-1.1-0.5-1.7c0.2-0.4,0.4-0.8,0.6-1.1
   c-0.5-0.5-1-1-1.5-1.5c-0.3,0.2-0.6,0.3-0.9,0.5c-0.7,0.4-1.5,0.3-1.8-0.4c-0.4-0.6-0.1-1.4,0.6-1.8c0.3-0.2,0.8-0.3,0.9-0.6
   c0.1-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.1-0.6-0.2-0.9c-0.4,0-0.8,0-1.3,0c-0.8,0-1.3-0.5-1.3-1.2c0-0.7,0.5-1.2,1.3-1.2
   c0.4,0,0.8,0,1.2,0c0.2-0.6,0.3-1.2,0.5-1.8c0-0.1-0.1-0.3-0.2-0.3c-0.3-0.2-0.6-0.3-0.9-0.5c-0.6-0.4-0.8-1.1-0.5-1.7
   c0.3-0.6,1.1-0.8,1.7-0.4c0.4,0.2,0.8,0.4,1.1,0.6c0.5-0.5,0.9-1,1.4-1.5c-0.2-0.3-0.4-0.6-0.6-1c-0.4-0.7-0.2-1.4,0.4-1.8
   c0.6-0.4,1.3-0.1,1.7,0.5c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.6-0.1,1.1-0.3,1.8-0.5c0-0.4,0-0.8,0-1.2
   c0-0.8,0.5-1.3,1.2-1.3c0.7,0,1.2,0.6,1.2,1.3c0,0.4,0,0.8,0,1.2c0.7,0.2,1.3,0.3,2,0.5c0.2-0.4,0.4-0.8,0.7-1.2
   c0.3-0.4,0.7-0.7,1.2-0.6c0.5,0.1,0.9,0.4,1,1c0,0.3,0,0.6-0.1,0.9c-0.2,0.4-0.4,0.7-0.6,1.1c0.5,0.5,1,1,1.5,1.5
   c0.3-0.2,0.7-0.4,1.1-0.6c0.6-0.4,1.4-0.2,1.7,0.4c0.3,0.6,0.1,1.3-0.5,1.7c-0.4,0.2-0.7,0.4-1.1,0.7c0.2,0.7,0.3,1.3,0.5,2
   c0.4,0,0.8,0,1.2,0c0.8,0,1.4,0.5,1.4,1.2C102.8,99,102.2,99.5,101.4,99.5z"/>
</g>
</g>
<path d="M175.1,173c-1.2,2.3-3,3.2-5.7,3c-7.4-0.4-14.5-1.9-21.5-4.6c-6.8-2.6-13.8-4-21.1-3.8c-5.1,0.1-10.1,1.3-14.8,3.4
c-7.6,3.5-15.6,5.3-24,5c-6.8-0.3-13.2-2.1-19.5-4.8c-10.5-4.5-21.1-5.1-31.8-0.3c-3.5,1.5-7.1,2.9-10.8,3.5
c-5.5,0.9-11.1,1.2-16.6,1.6c-2.2,0.2-4.1-1.5-4.2-3.7c-0.1-5.8,0-11.6,0-17.3c4.3-0.6,8.5-1,12.5-1.9c4.2-0.9,8.3-2.3,12.4-3.6
c11.5-3.5,23.1-4.7,34.7-1c3.5,1.1,6.9,2.8,10.4,3.9c11.7,3.8,23.4,3.3,34.6-1.8c9.6-4.3,19.4-4.8,29.5-3.7
c8.4,0.9,16.3,3.6,24.3,5.6c3.7,1,7.6,1.3,11.4,1.9C175.1,160.6,175.1,166.8,175.1,173z"/>
<path d="M175.1,143c-1.2,2.3-3,3.2-5.7,3c-7.4-0.4-14.5-1.9-21.5-4.6c-6.8-2.6-13.8-4-21.1-3.8c-5.1,0.1-10.1,1.3-14.8,3.4
c-7.6,3.5-15.6,5.3-24,5c-6.8-0.3-13.2-2.1-19.5-4.8c-10.5-4.5-21.1-5.1-31.8-0.3c-3.5,1.5-7.1,2.9-10.8,3.5
c-5.5,0.9-11.1,1.2-16.6,1.6c-2.2,0.2-4.1-1.5-4.2-3.7c-0.1-5.8,0-11.6,0-17.3c4.3-0.6,8.5-1,12.5-1.9c4.2-0.9,8.3-2.3,12.4-3.6
c11.5-3.5,23.1-4.7,34.7-1c3.5,1.1,6.9,2.8,10.4,3.9c11.7,3.8,23.4,3.3,34.6-1.8c9.6-4.3,19.4-4.8,29.5-3.7
c8.4,0.9,16.3,3.6,24.3,5.6c3.7,1,7.6,1.3,11.4,1.9C175.1,130.6,175.1,136.8,175.1,143z"/>
</svg>
`,
  caudalimetroEntradaCaudal: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<path d="M150.1,136.6c-6.7,0-13.2-2.1-18.7-6c-0.8-0.6-1.9-0.6-2.7,0c-11.2,7.8-26.1,7.8-37.3,0c-0.8-0.6-1.9-0.6-2.7,0
c-11.2,7.8-26.1,7.8-37.3,0c-0.8-0.6-1.9-0.6-2.7,0c-9.9,7.1-23,7.8-33.7,2c-4.8-2.8-6.4-8.9-3.7-13.7c2.8-4.8,8.9-6.4,13.7-3.7
c3.9,2,8.6,1.6,12.1-1c7.8-5.5,18.2-5.5,25.9,0c4.2,3,9.9,3,14.1,0c7.8-5.5,18.2-5.5,25.9,0c4.2,3,9.9,3,14.1,0
c7.8-5.5,18.2-5.5,25.9,0c3.5,2.6,8.2,3,12,1c4.8-2.8,10.9-1.1,13.7,3.7c2.8,4.8,1.1,10.9-3.7,13.7
C160.5,135.2,155.3,136.6,150.1,136.6z"/>
<path d="M165.1,166c4.8-2.8,6.4-8.9,3.7-13.7c-2.8-4.8-8.9-6.4-13.7-3.7c-3.9,2-8.5,1.6-12-1c-7.8-5.5-18.2-5.5-25.9,0
c-4.2,3-9.9,3-14.1,0c-7.8-5.5-18.2-5.5-25.9,0c-4.2,3-9.9,3-14.1,0c-7.8-5.5-18.2-5.5-25.9,0c-3.5,2.6-8.2,3-12.1,1
c-4.8-2.8-10.9-1.2-13.7,3.6c-2.8,4.8-1.2,10.9,3.6,13.7c10.7,5.8,23.8,5.1,33.7-2c0.8-0.6,1.9-0.6,2.7,0c11.2,7.8,26.1,7.8,37.3,0
c0.8-0.6,1.9-0.6,2.7,0c11.2,7.8,26.1,7.8,37.3,0c0.8-0.6,1.9-0.6,2.7,0c5.5,3.9,12,6,18.7,6C155.4,170,160.5,168.6,165.1,166z"/>
<path d="M114.6,56.5H100V20c0-5.5-4.5-10-10-10s-10,4.5-10,10v36.5H65.5c-3.7,0.1-6.7,3.1-6.6,6.8c0,1.7,0.7,3.4,1.9,4.6l22.5,22.5
c3.7,3.7,9.8,3.7,13.5,0c0,0,0,0,0,0l0,0l22.5-22.5c2.6-2.7,2.5-6.9-0.1-9.5C117.9,57.3,116.3,56.6,114.6,56.5z"/>
</svg>`,
  caudalimetroCaudal: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<path d="M150.1,136.6c-6.7,0-13.2-2.1-18.7-6c-0.8-0.6-1.9-0.6-2.7,0c-11.2,7.8-26.1,7.8-37.3,0c-0.8-0.6-1.9-0.6-2.7,0
c-11.2,7.8-26.1,7.8-37.3,0c-0.8-0.6-1.9-0.6-2.7,0c-9.9,7.1-23,7.8-33.7,2c-4.8-2.8-6.4-8.9-3.7-13.7c2.8-4.8,8.9-6.4,13.7-3.7
c3.9,2,8.6,1.6,12.1-1c7.8-5.5,18.2-5.5,25.9,0c4.2,3,9.9,3,14.1,0c7.8-5.5,18.2-5.5,25.9,0c4.2,3,9.9,3,14.1,0
c7.8-5.5,18.2-5.5,25.9,0c3.5,2.6,8.2,3,12,1c4.8-2.8,10.9-1.1,13.7,3.7c2.8,4.8,1.1,10.9-3.7,13.7
C160.5,135.2,155.3,136.6,150.1,136.6z"/>
<path d="M165.1,166c4.8-2.8,6.4-8.9,3.7-13.7c-2.8-4.8-8.9-6.4-13.7-3.7c-3.9,2-8.5,1.6-12-1c-7.8-5.5-18.2-5.5-25.9,0
c-4.2,3-9.9,3-14.1,0c-7.8-5.5-18.2-5.5-25.9,0c-4.2,3-9.9,3-14.1,0c-7.8-5.5-18.2-5.5-25.9,0c-3.5,2.6-8.2,3-12.1,1
c-4.8-2.8-10.9-1.2-13.7,3.6c-2.8,4.8-1.2,10.9,3.6,13.7c10.7,5.8,23.8,5.1,33.7-2c0.8-0.6,1.9-0.6,2.7,0c11.2,7.8,26.1,7.8,37.3,0
c0.8-0.6,1.9-0.6,2.7,0c11.2,7.8,26.1,7.8,37.3,0c0.8-0.6,1.9-0.6,2.7,0c5.5,3.9,12,6,18.7,6C155.4,170,160.5,168.6,165.1,166z"/>
<path d="M65.5,46.7H80v36.5c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10V46.7h14.5c3.7-0.1,6.7-3.1,6.6-6.8c0-1.7-0.7-3.4-1.9-4.6
L96.8,12.8c-3.7-3.7-9.8-3.7-13.5,0c0,0,0,0,0,0l0,0L60.8,35.3c-2.6,2.7-2.5,6.9,0.1,9.5C62.1,46,63.7,46.7,65.5,46.7z"/>
</svg>`,
  sondaNivelDeAbastecimiento: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FFFFFF;}
</style>
<g>
<path d="M15.9,349.9c34.2,18.7,76,16.2,107.8-6.4c2.6-2,6.1-2,8.7,0c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0
 c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0c17.5,12.4,38.3,19.1,59.7,19.2c16.9,0,33.4-4.5,48-12.9
 c15.3-8.9,20.5-28.5,11.6-43.8c-8.9-15.3-28.5-20.5-43.8-11.6c-12.4,6.4-27.3,5.1-38.4-3.2c-24.8-17.6-58-17.6-82.8,0
 c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0
 c-11.2,8.3-26.1,9.6-38.5,3.2c-15.3-8.9-34.9-3.7-43.9,11.6C-4.7,321.3,0.5,341,15.9,349.9z"/>
<path d="M463.9,392.4c-12.4,6.4-27.3,5.2-38.4-3.2c-24.8-17.6-58-17.6-82.8,0c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0
 c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0c-11.2,8.4-26.1,9.6-38.5,3.2c-15.3-8.9-34.9-3.8-43.8,11.5
 s-3.8,34.9,11.5,43.8c34.2,18.8,76.1,16.3,107.8-6.3c2.6-2,6.1-2,8.7,0c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0
 c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0c17.4,12.4,38.2,19.1,59.6,19.2c16.9,0,33.5-4.5,48.1-12.9v0
 c15.3-8.9,20.5-28.5,11.6-43.8S479.2,383.5,463.9,392.4z"/>
<path d="M15.9,251.7c34.2,18.8,76.1,16.4,107.8-6.3c2.6-2,6.1-2,8.7,0c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0
 c35.9,24.9,83.4,24.9,119.3,0c2.6-2,6.1-2,8.7,0c17.4,12.4,38.2,19.1,59.6,19.2c16.9,0,33.5-4.5,48.1-12.9v0
 c15.3-8.9,20.5-28.5,11.6-43.8c-8.9-15.3-28.5-20.5-43.8-11.6c-12.4,6.3-27.3,5.1-38.4-3.2c-24.8-17.6-58-17.6-82.8,0
 c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0c-13.6,9.4-31.6,9.4-45.2,0c-24.8-17.6-58-17.6-82.8,0
 c-11.2,8.4-26.2,9.6-38.6,3.2c-15.3-8.9-34.9-3.7-43.8,11.6S0.6,242.9,15.9,251.7z"/>
</g>
<g>
<path class="st0" d="M446.5,25.1C442,20,432,16.8,421.2,17c-11,0.2-20.8,3.9-24,9.4c-1.3,2.2-1.6,4.5-1.6,6.8
 c-0.1,89.1-0.1,178.2-0.1,267.4c0,1-0.1,2,0.1,3c0.9,6.5,10.1,12,21.7,13c13.1,1.2,24.9-2.3,29.9-8.7c0.6-0.7,1.2-1.4,1.9-2.1
 c0-92.6,0-185.1,0-277.7C448.2,27.1,447.4,26.1,446.5,25.1z"/>
<path class="st0" d="M273.5,44.4c0-1.3,0-2.6-0.1-3.9c-0.6-6.9-10.2-12.5-22.7-13.3c-13.4-0.9-25.5,3-29.2,9.7
 c-1.2,2.2-1.4,4.5-1.4,6.8c-0.1,88.8-0.1,177.7,0,266.5c0,1.6,0.1,3.2,0.5,4.8c1.7,6.3,11.3,11.1,23.3,11.8
 c12.7,0.7,24.3-3.1,28.1-9.3c1.3-2.2,1.5-4.7,1.5-7.1c0.1-44.5,0.1-88.9,0.1-133.4C273.5,132.7,273.5,88.6,273.5,44.4z"/>
<path class="st0" d="M361.1,60.8c-0.1-6.9-9.7-12.7-22.2-13.6c-13.4-1-25.7,2.7-29.5,9.3c-1.4,2.3-1.7,4.9-1.7,7.4
 c-0.1,88.6-0.1,177.3,0,265.9c0,2.1,0.2,4.2,1,6.3c2.2,5.6,11.5,9.9,22.2,10.6c17.1,1.1,30.2-5.3,30.3-14.8c0-44.9,0-89.7,0-134.6
 c0-28.5,0-57.1,0-85.6C361.2,94.7,361.3,77.7,361.1,60.8z"/>
</g>
<path d="M263.8,206.9c0,28.5,0,56.9,0,85.4c0,1.5-0.1,3.1-1,4.5c-2.4,4-9.8,6.4-18,6c-7.6-0.4-13.8-3.5-14.9-7.5
c-0.3-1-0.3-2-0.3-3.1c0-56.8,0-113.7,0-170.5c0-1.5,0.1-3,0.9-4.4c2.3-4.3,10.1-6.8,18.7-6.2c8,0.5,14.2,4.1,14.6,8.5
c0.1,0.8,0.1,1.7,0.1,2.5C263.8,150.4,263.8,178.6,263.8,206.9z"/>
<path d="M351.6,231.9c0,28.5,0,56.9,0,85.4c0,1.5-0.1,3.1-1,4.5c-2.4,4-9.8,6.4-18,6c-7.6-0.4-13.8-3.5-14.9-7.5
c-0.3-1-0.3-2-0.3-3.1c0-56.8,0-113.7,0-170.5c0-1.5,0.1-3,0.9-4.4c2.3-4.3,10.1-6.8,18.7-6.2c8,0.5,14.2,4.1,14.6,8.5
c0.1,0.8,0.1,1.7,0.1,2.5C351.6,175.4,351.6,203.6,351.6,231.9z"/>
<path d="M439.4,202.9c0,28.5,0,56.9,0,85.4c0,1.5-0.1,3.1-1,4.5c-2.4,4-9.8,6.4-18,6c-7.6-0.4-13.8-3.5-14.9-7.5
c-0.3-1-0.3-2-0.3-3.1c0-56.8,0-113.7,0-170.5c0-1.5,0.1-3,0.9-4.4c2.3-4.3,10.1-6.8,18.7-6.2c8,0.5,14.2,4.1,14.6,8.5
c0.1,0.8,0.1,1.7,0.1,2.5C439.4,146.4,439.4,174.6,439.4,202.9z"/>
</svg>
`,
  estacionMedioambiental: `<svg version="1.1" width="27" height="27" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 180 180" style="enable-background:new 0 0 180 180;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FFFFFF;}
</style>
<g>
<path class="st0" d="M5.4,166c0.6,1.9,2,3.1,3.7,4c-1.2,0-2.4,0-3.7,0C5.4,168.7,5.4,167.3,5.4,166z"/>
<path class="st0" d="M172.4,170c1.7-0.9,3-2.1,3.7-4c0,1.3,0,2.7,0,4C174.9,170,173.6,170,172.4,170z"/>
<path d="M90.8,10c25.2,0,50.4,0,75.6,0c3.7,0,4.8,1.2,4.6,4.8c-0.7,10.2-9,18.4-19.2,18.8c-0.6,0-1.2,0-1.8,0c-39.5,0-79,0-118.4,0
 c-9,0-15.6-4-19.4-12.1c-1-2.3-1.4-4.9-1.7-7.4c-0.3-2.7,1.2-4.1,3.9-4.1c13,0,26.1,0,39.1,0C65.9,10,78.4,10,90.8,10z"/>
<g>
 <path d="M61.6,116.7c-0.2,0-0.3-0.1-0.3-0.1c-4.2,0-8.4,0-12.6,0c-0.3,0-0.6,0.3-0.8,0.5c-1.6,3.1-2.9,6.4-3.5,9.9
   c-0.3,1.7-0.5,3.4-0.7,5.2c5.4,0,10.6,0,15.9,0C59.7,127.1,60.3,121.9,61.6,116.7z"/>
 <path d="M61.5,151.6c-1.4-5.2-1.9-10.4-2.1-15.7c-5.2,0-10.4,0-15.6,0c0.3,5.4,1.7,10.4,4.1,15.2c0.2,0.5,0.6,0.5,1,0.5
   c3.9,0,7.8,0,11.7,0C60.9,151.6,61.1,151.6,61.5,151.6z"/>
 <path d="M76.7,116.7c-3.9,0-7.8,0-11.7,0c-1.4,5.1-2.1,10.3-2.1,15.7c4.6,0,9.2,0,13.8,0C76.7,127.1,76.7,121.9,76.7,116.7z"/>
 <path d="M64.9,151.1c0.1,0.5,0.4,0.5,0.8,0.5c3.4,0,6.9,0,10.3,0c0.2,0,0.4,0,0.7,0c0-5.3,0-10.5,0-15.7c-4.6,0-9.2,0-13.7,0
   c0,0.1,0,0.1,0,0.2C63.1,141.2,63.7,146.2,64.9,151.1z"/>
 <path d="M62,113.3c0.2,0,0.5-0.3,0.6-0.5c1.7-5,3.8-9.7,7.4-13.7c-8.2,2.4-14.7,7.1-19.7,14c0.2,0.1,0.2,0.1,0.2,0.1
   C54.4,113.3,58.2,113.3,62,113.3z"/>
 <path d="M62.6,155.6c-0.1-0.2-0.4-0.6-0.6-0.6c-3.9,0-7.7,0-11.8,0c5.1,7,11.6,11.7,19.7,14.2c-1.1-1.5-2.2-2.9-3.2-4.5
   C65,161.9,63.7,158.8,62.6,155.6z"/>
 <path d="M76.7,113.3c0-5,0-9.9,0-14.8c-3.8,1.2-10.2,10.2-10.6,14.8C69.7,113.3,73.2,113.3,76.7,113.3z"/>
 <path d="M66.1,155.1c0.1,0.3,0.1,0.5,0.2,0.7c1,2.2,1.9,4.5,3.1,6.6c1.4,2.6,3.2,4.9,5.7,6.6c0.5,0.4,1.1,0.7,1.7,1.1
   c0-5.1,0-9.9,0-14.9C73.2,155.1,69.7,155.1,66.1,155.1z"/>
</g>
<g>
 <path d="M116.2,154.2C116.2,154.2,116.2,154.2,116.2,154.2C116.2,154.2,116.2,154.2,116.2,154.2L116.2,154.2z"/>
 <path d="M136,124.6c-1.3-8.8-3.1-17.5-5.3-26.1c-0.2-1-1.5-1.5-2.4-0.9c-0.3,0.2-0.5,0.4-0.7,0.6c-2.9,3.1-5.7,6.2-8.5,9.4
   c-4.6,5.2-9,10.5-12.9,16.2c-1.6,2.4-3.2,4.8-4.1,7.6c-2.2,7,0.4,15.1,6.5,19.5c1.3,1,2.7,1.8,4.4,2.4c3.5-9.8,6.8-19.6,8.2-30
   c1.1,0.1,2.2,0.2,3.4,0.3c-0.3,2.1-0.6,4.1-1,6c-1.6,7.8-4,15.3-6.7,22.7c-0.2,0.7-0.4,1.3-0.6,2c0.2,0,0.3-0.1,0.5,0
   c4.4,0.6,8.6-0.3,12.3-2.8c4.9-3.3,7.8-7.9,8-13.9C137.1,133.1,136.6,128.8,136,124.6z"/>
 <polygon points="116.2,154.2 116.2,154.2 116.2,154.2 		"/>
 <path d="M108.6,169.7c1.2,0.1,2.2,0.2,3.3,0.3c0.1-0.2,0.1-0.3,0.1-0.4c0.7-2.9,1.3-5.9,2.1-8.8c0.6-2.2,1.4-4.4,2.1-6.6
   c-1.1-0.3-2.2-0.7-3.4-1.1C111.1,158.5,109.3,163.9,108.6,169.7z"/>
</g>
<path d="M122.3,44.3c-6.3,0-11.6,4.4-12.9,10.3H98.6H90h-5.9h-1.5H72c-1.3-5.9-6.6-10.3-12.9-10.3c-7.3,0-13.2,5.9-13.2,13.2
 s5.9,13.2,13.2,13.2c6.3,0,11.6-4.4,12.9-10.3h10.6h1.5H90h8.5h10.9c1.3,5.9,6.6,10.3,12.9,10.3c7.3,0,13.2-5.9,13.2-13.2
 S129.6,44.3,122.3,44.3z"/>
<rect x="85.3" y="37.6" width="10.7" height="132.4"/>
</g>
</svg>`
};
