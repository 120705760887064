import * as React from 'react';
import { tiposDispositivo } from 'components/tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { getDeviceTypesByReport } from 'components/Reports/shared/services/services';
import ControlContext from 'context/control/ControlContext';
import { dispositivo } from 'components/dispositivos/resources/interfaces/interfaceDispositivo';
import { getId } from 'components/graficas/functions/funciones';
import { etiqueta } from 'components/etiquetas/resources/interfaces/interfaceEtiquetas';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useGraficas } from 'components/graficas/hooks/useGraficas/useGraficas';
import { GraphTypes } from 'components/graficas/resources/enums/enumGraphTypes';
import { Operation } from 'components/graficas/resources/enums/enumOperation';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import { TiposMedida } from 'resources/enums/enumTiposMedida';
import SmartteliaBackdropContext from 'context/SmartTeliaBackdropContext';
import { DateObject } from 'react-multi-date-picker';
import {
  ConsumptionReportArgs,
  StateUseConsumptionReportFilter,
  ValuesUseConsumptionReportFilter
} from './types';
import { useReportContext } from 'components/Reports/Context/index.hook';
import { getTagsByDevices } from 'shared/services/tags';
import { getDevicesByDeviceTypes } from 'shared/services/devices';
import { useRequest, useTriggeredRequest } from 'shared/hooks/useRequest';
import { getConsumptionReportData } from './services';
import { ReportData, reportInfo } from 'components/Reports/shared/types';
import { dictionary_generic } from 'resources/enums/plainText';

const measuresByReport = {
  '301': [TiposMedida.CONSUMO_CAUDAL, TiposMedida.CONSUMO_CAUDAL_ENTRADA],
  '601': [TiposMedida.CONSUMO],
  '201': [TiposMedida.CONSUMO]
};

const initial_state: StateUseConsumptionReportFilter = {
  selectedDeviceType: undefined,
  selectedDevices: [],
  selectedTags: [],
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date())
};

export function useConsumptionReportFilter(): ValuesUseConsumptionReportFilter {
  const {
    reportData: [, setReportData],
    reportObject: [reportContextObject = initial_state, setReportObjectContext],
    handleDrawerClose,
    selectedReport: [selectedReport]
  } = useReportContext();

  const { reportInfo } = selectedReport;

  const { id: reportId, codigo: reportCode } = reportInfo as reportInfo;

  const { moduloSeleccionado } = React.useContext(ControlContext);
  const { setOpenSmartTeliaBackdrop } = React.useContext(SmartteliaBackdropContext);
  const { obtenerDatosGrafica } = useGraficas();

  const { endDate, startDate, selectedDeviceType, selectedDevices, selectedTags } =
    reportContextObject as StateUseConsumptionReportFilter;

  const { data: deviceTypesByReport, mutate: mutateDeviceTypes } = useRequest<
    Array<tiposDispositivo>
  >(() => getDeviceTypesByReport({ reportCode }));

  const { data: devices } = useRequest<Array<dispositivo>>(() =>
    getDevicesByDeviceTypes({
      deviceType: selectedDeviceType,
      moduleId: moduloSeleccionado
    })
  );
  const { data: tags } = useRequest<Array<etiqueta>>(() =>
    getTagsByDevices({ devices: selectedDevices ? getId(selectedDevices) : undefined })
  );

  const { trigger: getData } = useTriggeredRequest<ReportData, ConsumptionReportArgs>(
    getConsumptionReportData
  );

  const [dateError, setDateError] = React.useState(false);

  function handleGetReport() {
    obtenerDatosGrafica(
      measuresByReport[reportCode],
      Rango.THIS_YEAR,
      Operation.SUM,
      GraphTypes.comparativo,
      selectedDevices as dispositivo[],
      undefined,
      undefined,
      undefined,
      { openSmarteliaBackdrop: true },
      selectedDevices.length > 4
    ).then((datosGrafica) => {
      getData({
        tiposDispositivos: selectedDeviceType as string[],
        dispositivos: getId(selectedDevices),
        startDate,
        endDate,
        etiquetas: selectedTags,
        modulos: [moduloSeleccionado]
      })
        .then((result) => {
          result?.data.result.body.forEach((item: any) => {
            item['valor'] =
              item['valor'] !== null
                ? `${item.valor.toLocaleString(undefined, { useGrouping: 'always' })} ${
                    item.unidad
                  }`
                : dictionary_generic.NO_DATA;
            item['contadorInicial'] =
              item['contadorInicial'] !== null
                ? `${item.contadorInicial.toLocaleString(undefined, {
                    useGrouping: 'always'
                  })}`
                : dictionary_generic.NO_DATA;
            item['contadorFinal'] =
              item['contadorFinal'] !== null
                ? `${item.contadorFinal.toLocaleString(undefined, {
                    useGrouping: 'always'
                  })}`
                : dictionary_generic.NO_DATA;
          });
          setReportData({
            report: result?.data.result as ReportData,
            graphData: { ...datosGrafica, idChart: 'report-chart' }
          });
          //reset();
          handleDrawerClose();
        })
        .catch((error) => error)
        .finally(() => setOpenSmartTeliaBackdrop(false));
    });
  }

  function handleAutoCompleteDeviceTypes(value: Array<tiposDispositivo> | []) {
    if (value.length === 0) {
      setReportObjectContext(initial_state);
    } else {
      setReportObjectContext({
        ...reportContextObject,
        selectedDeviceType: value.length > 0 ? value.map((v) => v.codigo) : undefined
      });
    }
  }

  function handleAutoCompleteDevices(value: Array<dispositivo>) {
    setReportObjectContext({
      ...reportContextObject,
      selectedDevices: value.length > 0 ? value : undefined
    });
  }

  function handleAutoCompleteTags(value: Array<etiqueta>) {
    setReportObjectContext({
      ...reportContextObject,
      selectedTags: value.length > 0 ? getId(value) : []
    });
  }

  function handleDate(value: Array<DateObject>) {
    const startDate = value[0].toDate();
    const endDate = value[1]
      ? value[1].add(23, 'h').add(59, 'm').add(59, 's').toDate()
      : setDateError(true);

    setReportObjectContext({
      ...reportContextObject,
      startDate,
      endDate
    });

    if (value.length === 2) setDateError(false);
  }
  function resetReport() {
    setReportObjectContext(initial_state);
    mutateDeviceTypes(undefined);
  }

  return {
    deviceTypesByReport,
    devices,
    tags,
    endDate,
    startDate,
    selectedDevices,
    handleAutoCompleteDeviceTypes,
    handleAutoCompleteDevices,
    handleAutoCompleteTags,
    handleDate,
    handleGetReport,
    resetReport,
    dateError
  };
}
