import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  filtro: {
    justifyContent: 'left'
  },
  titulo: {
    marginLeft: '0px'
  },

  botonNoSeleccionado: {
    opacity: '0.5',
    '&:hover': {
      opacity: '1'
    }
  },
  botonFiltro: {
    height: '50px',
    width: '50px',
    padding: '0px',
    margin: '0px 25px 0px 0px'
  },

  botonSeleccionado: {
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    opacity: '1'
  }
}));

export default useStyles;
