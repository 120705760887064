import React from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ReactComponent as iconoTarjeta } from '../../resources/icons/iconoGrafica.svg';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Medida } from '../../resources/types/types';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';

export function Tarjeta({
  iconTitulo,
  tipoConsulta,
  titulo,
  controladorSliderGrafica,
  deshabilitarTarjetas,
  agruparDispositivos
}: {
  iconTitulo: JSX.Element;
  tipoConsulta: Medida;
  titulo: string;
  controladorSliderGrafica: (mostrar: boolean, tipoConsulta: Medida) => void;
  deshabilitarTarjetas: boolean;
  agruparDispositivos: boolean;
}): JSX.Element {
  const theme = useTheme();
  const disabled =
    deshabilitarTarjetas || (agruparDispositivos && tipoConsulta !== TiposMedida.CONSUMO);
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          controladorSliderGrafica(true, tipoConsulta);
        }}
        disabled={disabled}
        style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <SvgIcon
          component={iconoTarjeta}
          viewBox={'-50 0 600 476.6'}
          color="primary"
          style={{
            width: '70%',
            height: '100%',
            marginLeft: '30px',
            color: theme.palette.primary.main
          }}
        ></SvgIcon>
        <CardContent>
          {iconTitulo}
          <Typography variant={'h5'} align={'center'} style={{ width: '90%' }}>
            {titulo}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
