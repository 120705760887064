import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

export function AreaChart(props: {
  title: string;
  labels: string[];
  titleYAxis: string;
  datos: any;
  max: number;
  stacked: boolean;
  limitesGrafico?: any;
  hideNames?: Array<string>;
  annotations?: any;
}): JSX.Element {
  const { title, labels, titleYAxis, datos, max, hideNames } = props;
  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.secondary.light
  ];
  const colorsRange = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.success.main,
    theme.palette.success.main,
    theme.palette.success.light
  ];

  const height = props.limitesGrafico ? props.limitesGrafico.alto : 350;

  const options: ApexCharts.ApexOptions = {
    title: {
      text: title,
      align: 'left'
    },
    chart: {
      type: 'area',
      height: 350,
      id: 'chart',
      animations: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: labels
    },
    yaxis: {
      title: {
        text: titleYAxis
      },
      max: max
    },
    colors: colors.slice(0, datos.length),
    annotations: { yaxis: [] }
  };

  if (props.annotations) {
    if (props.annotations.y) {
      {
        options.annotations?.yaxis?.push({
          y: props.annotations.y,
          //  y2: props.annotations.y2,
          strokeDashArray: 2,
          borderColor: theme.palette.primary.main,

          label: {
            text: 'Min',

            style: {
              color: '#fff',
              background: theme.palette.primary.main
            }
          }
        });
      }
    }
    if (props.annotations.y2) {
      {
        options.annotations?.yaxis?.push({
          y: props.annotations.y2,
          strokeDashArray: 2,
          borderColor: theme.palette.primary.main,

          label: {
            text: 'Max',

            style: {
              color: '#fff',
              background: theme.palette.primary.main
            }
          }
        });
      }
    }
  }

  if (datos.length === 3) options.colors = colors;
  if (datos.length > 3) options.colors = colorsRange;

  useEffect(() => {
    // ApexCharts.exec('chart', 'hideSeries', 'Diferencia');
    if (hideNames)
      hideNames.forEach((element) => {
        ApexCharts.exec('chart', 'hideSeries', element);
      });
  }, [hideNames]);
  return (
    <div id="chart">
      <ReactApexChart options={options} series={[...datos]} type="area" height={height} />
    </div>
  );
}
