import React, { MutableRefObject, useContext, useEffect } from 'react';
import { useReducer } from 'react';
import { ACTIONS_REDUCER_PERMISOS } from '../resources/enums/enumControlPermisos';
import { ActionsReducerPermisos } from '../resources/types/ActionsReducerPermisos';
import { ObjReducerPermisos } from '../resources/types/ObjReducerPermisos';
import UserContext from '../../../context/UserContext';
import { permisosComponentes } from '../../../resources/enums/enumPermisosComponentes';
import { componente } from '../../../resources/interfaces/interfaceComponente';
import { enumComponentes } from '../../../resources/enums/enumComponente';

const acceso: any = {
  [permisosComponentes.CONTROL_TOTAL]: true,
  [permisosComponentes.LECTURA]: false
};

const reducer = (state: ObjReducerPermisos, action: ActionsReducerPermisos): ObjReducerPermisos => {
  switch (action.type) {
    case ACTIONS_REDUCER_PERMISOS.SET_CONTROL:
      return { ...state, control: action.payload, tieneAcceso: action.tieneAcceso };
    case ACTIONS_REDUCER_PERMISOS.SET_COMPONENTES_FILTRADOS:
      return { ...state, componentesFiltrados: action.payload };
    default:
      return state;
  }
};

const initial_state: ObjReducerPermisos = {
  control: <></>,
  componentesFiltrados: [],
  tieneAcceso: false
};

export function useReducerControlPermisos(
  moduloSeleccionado: number,
  previousComponent: MutableRefObject<{ component: JSX.Element; tmp: boolean }>
) {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { datosUsuarioContext, logged } = useContext(UserContext);

  const { control, componentesFiltrados, tieneAcceso } = state;

  /**
   * Comprueba si el componente tiene acceso o no
   * @param {enumComponentes} key El componente que se va a comprobar.
   * @returns {boolean} Devuelve True -> CONTROL TOTAL , false -> SOLO LECTURA.
   */
  function comprobarAcceso(key: enumComponentes): boolean {
    const controlAcceso =
      acceso[
        componentesFiltrados.find((componente) => componente.nombre === key)?.permiso as number
      ];
    return controlAcceso ?? false;
  }

  //Se encarga de renderizar un componente u otro y además añadirle a las props de ese componente el nivel de "acceso" para poder aplicarle los permisos correspondientes a cada componente.
  function setControl(element: JSX.Element, tmp = false) {
    //Necesitamos comprobar si se trata de el primer componente cargado antes de que haya uno previo (tmp sea false), de esta forma vemos si tiene un componente anterior, y lo cargará en
    //VolverAtras.tsx en caso de haberlo.
    if (!tmp) previousComponent.current.component = element;
    previousComponent.current.tmp = tmp;

    dispatch({
      type: ACTIONS_REDUCER_PERMISOS.SET_CONTROL,
      payload: element,
      tieneAcceso: comprobarAcceso(element.key as enumComponentes)
    });
  }

  useEffect(() => {
    if (logged) {
      const componentsWithPermissions = datosUsuarioContext.componentes.map((componente) => {
        return {
          ...componente,
          permiso: datosUsuarioContext.permisos.find(
            (permiso) => permiso.idComponente === componente.id
          )?.id
        };
      });
      dispatch({
        type: ACTIONS_REDUCER_PERMISOS.SET_COMPONENTES_FILTRADOS,
        payload: componentsWithPermissions
      });
    }
  }, [logged]);

  return { tieneAcceso, comprobarAcceso, control, setControl, componentesFiltrados };
}
