import React, { useRef } from 'react';
import Slide from '@mui/material/Slide';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Print } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import Autocomplete from '@mui/material/Autocomplete';
import { Frecuencia, Medida } from '../../resources/types/types';
import useStyles from './style';
import { dictionary_generic, enumLabel } from '../../../../resources/enums/plainText';
import { Rango } from '../../resources/enums/enumRango';
import es from 'date-fns/locale/es';
import 'react-date-range/dist/styles.css'; // estilos para el DateRange
import 'react-date-range/dist/theme/default.css'; // estilos para el DateRange
import { DateRange } from 'react-date-range';
import { useTheme } from '@mui/material/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { formateoFechaGrafico } from '../../functions/funciones';

const formateoFecha = formateoFechaGrafico.format('%d/%m/%Y');

const optionsAutoCompleteFrecuencia = [
  { frecuencia: 'Hoy', option: Rango.TODAY },
  { frecuencia: 'Esta semana', option: Rango.THIS_WEEK },
  { frecuencia: 'Este mes', option: Rango.THIS_MONTH },
  { frecuencia: 'Este año', option: Rango.THIS_YEAR },
  { frecuencia: 'Rango de fechas', option: Rango.RANGE }
];

export function SliderGrafica({
  controladorSliderGrafica,
  componenteGrafica,
  dispositivosSeleccionados,
  actualizarAutoCompleteFrecuencia,
  handleFechaFin,
  handleFechaInicio,
  handleRangeFocus,
  cargarDatosGraficas
}: {
  controladorSliderGrafica: (mostrar: boolean) => void;
  componenteGrafica: any;
  dispositivosSeleccionados: Array<dispositivo>;
  actualizarAutoCompleteFrecuencia: (frecuencia: Frecuencia) => void;
  handleFechaFin: (item: any) => void;
  handleFechaInicio: (item: any) => void;
  handleRangeFocus: (item: Array<number>) => void;
  cargarDatosGraficas: (medida: Medida) => void;
}): JSX.Element {
  const classes = useStyles();
  const componentRef = useRef<HTMLDivElement>(null); // Necesario para el botón imprimir.
  const theme = useTheme();

  const [open, setOpen] = React.useState('');

  const { fechaInicio, fechaFin } = componenteGrafica;

  const handlePrint = useReactToPrint({
    content: () => (componentRef.current as unknown) as Element
  });

  const handleAutoCompleteFrecuencia = (event: any, value: any) => {
    if (value.option !== componenteGrafica.frecuencia) {
      actualizarAutoCompleteFrecuencia(value.option);
    }
  };

  function obtenerFrecuencia(frecuencia: Frecuencia) {
    const c = optionsAutoCompleteFrecuencia.filter((options) => options.option === frecuencia);
    return c[0].frecuencia;
  }

  function handleOpenCalendars({ currentTarget }: { currentTarget: any }) {
    const value = open === currentTarget.id;

    value ? setOpen('') : setOpen(currentTarget.id);
  }

  function handleClickGraphRange() {
    cargarDatosGraficas(componenteGrafica.tipoConsulta);
  }

  return (
    <>
      <Slide direction="up" in={componenteGrafica.abrirSlider} mountOnEnter unmountOnExit>
        <Paper elevation={4} className={classes.paper}>
          <div ref={componentRef}>
            <Grid container justifyContent={'space-between'} marginBottom={'20px'}>
              <Grid item xs={12} sm={12} md={12} lg={7}>
                <Typography variant={'h6'} style={{ color: 'GrayText' }}>
                  {dispositivosSeleccionados.length === 0
                    ? 'Para visualizar las gráficas seleccione uno o varios dispositivos'
                    : dispositivosSeleccionados.length < 7
                    ? 'Dispositivos Seleccionados: ' +
                      dispositivosSeleccionados.map((dispositivo: any, index) => {
                        if (index > 2) {
                          return '...';
                        } else {
                          return ' ' + dispositivo.nombre;
                        }
                      })
                    : `Se han seleccionado ${dispositivosSeleccionados.length} dispositivos.`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className={`print-ocultar ${
                  window.innerWidth <= 1024 ? classes.autocompleteSlider : ''
                }`}
              >
                <Autocomplete
                  id="combo-box-frecuencia"
                  options={optionsAutoCompleteFrecuencia}
                  getOptionLabel={(option) => option.frecuencia}
                  style={{ width: '200px' }}
                  isOptionEqualToValue={(option, value) => option.frecuencia === value.frecuencia}
                  onChange={handleAutoCompleteFrecuencia}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField {...params} label={enumLabel.FRECUENCIA} variant="outlined" />
                  )}
                  value={{
                    frecuencia: obtenerFrecuencia(componenteGrafica.frecuencia),
                    option: componenteGrafica.frecuencia
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                md={1}
                className={`print-ocultar ${
                  window.innerWidth <= 1024 ? classes.autocompleteSlider : ''
                }`}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Print />}
                  onClick={handlePrint}
                  style={{ height: ' 44.82px' }}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={1}
                className={`print-ocultar ${
                  window.innerWidth <= 1024 ? classes.autocompleteSlider : ''
                }`}
              >
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  className={classes.closeButton}
                  onClick={() => {
                    controladorSliderGrafica(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            {componenteGrafica.grafica !== undefined ? (
              <Grid container justifyContent={'flex-start'} spacing={2}>
                <Grid item xs={12} md={9} className={'grafica'} marginBottom={'80px'}>
                  <Grid item xs={10} md={12} className={'grafica'}>
                    {componenteGrafica.grafica}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={10}
                  md={2}
                  className={`${window.innerWidth <= 1024 ? classes.autocompleteSlider : ''}`}
                  display={componenteGrafica.frecuencia === Rango.RANGE ? 'flex' : 'none'}
                  justifyContent={'start'}
                  flexDirection={'column'}
                >
                  <TextField
                    id="input-with-icon-textfield"
                    label="Periodo Inicial"
                    className={`${classes.autoCompleteRangoInicio} print-margin-top`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleOpenCalendars} edge="end" id="start">
                            <DateRangeIcon color="secondary" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    value={`${formateoFecha(fechaInicio[0].startDate)} - ${formateoFecha(
                      fechaInicio[0].endDate
                    )}`}
                  />

                  <DateRange
                    locale={es}
                    weekStartsOn={1}
                    editableDateInputs={false}
                    onChange={handleFechaInicio}
                    ranges={fechaInicio}
                    showMonthAndYearPickers={false}
                    showDateDisplay={false}
                    disabledDay={(day) => day > new Date()}
                    rangeColors={[theme.palette.secondary.main]}
                  />

                  <TextField
                    id="input-with-icon-textfield"
                    label="Periodo Comparativo"
                    error={componenteGrafica.dateError}
                    className={classes.autoCompleteRangoFinal}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleOpenCalendars} edge="end" id="end">
                            <DateRangeIcon color="primary" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    value={
                      fechaFin[0].startDate
                        ? `${formateoFecha(fechaFin[0].startDate)} - ${formateoFecha(
                            fechaFin[0].endDate
                          )}`
                        : null
                    }
                  />

                  <DateRange
                    locale={es}
                    weekStartsOn={1}
                    editableDateInputs={false}
                    onChange={handleFechaFin}
                    onRangeFocusChange={handleRangeFocus}
                    ranges={fechaFin}
                    showMonthAndYearPickers={false}
                    showDateDisplay={false}
                    disabledDay={(day) => day >= fechaInicio[0].startDate}
                    rangeColors={[theme.palette.primary.main]}
                  />

                  <Button
                    variant="contained"
                    style={{ width: '30%' }}
                    onClick={handleClickGraphRange}
                    className={'print-ocultar'}
                    color="secondary"
                    disabled={
                      componenteGrafica.frecuencia !== Rango.RANGE || componenteGrafica.dateError
                    }
                  >
                    {dictionary_generic.VER}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div style={{ marginTop: '150px' }}>
                <></>
              </div>
            )}
          </div>
        </Paper>
      </Slide>
    </>
  );
}
