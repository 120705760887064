import React from 'react';
import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import IconTabs from './CarrouselIcons';
import useStyles from './styles';
import {
  tiposDispositivo,
  objectTiposDispositivo
} from './resources/interfaces/interfaceTiposDispositivo';
import { TablaTiposDispositivo } from './TablaTiposDispositivo';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { icon } from '../common/gis/components/iconos/Iconos';
import ControlContext from '../../context/control/ControlContext';
import SnackBarContext from '../../context/SnackBarContext';
import { limpiarCampos } from '../../functions/functions';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { useEventChange } from '../../hooks/useEventChange/useEventChange';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import {
  dictionary_generic,
  dictionary_tiposDispositivos,
  enumLabel
} from '../../resources/enums/plainText';

export function TiposDispositivoCRU(props: { tipoModificar?: tiposDispositivo }): JSX.Element {
  const classes = useStyles();
  const [tiposDispositivo, setTiposDispositivo] = React.useState<tiposDispositivo>(
    objectTiposDispositivo(props.tipoModificar)
  );
  const [valueTab, setValueTab] = React.useState(0);
  const [, setLimpiar] = React.useState<string>('');
  const { setControl } = React.useContext(ControlContext);
  const { setSnackBar } = React.useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { handleInputChange, handleInputInvalid } = useEventChange(
    tiposDispositivo,
    setTiposDispositivo
  );
  const openCloseSmarTeliaBackdrop = {
    openSmartTeliaBackdrop: true,
    closeSmartTeliaBackdrop: true
  };
  const iconos = Object.keys(icon);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (props.tipoModificar) {
      await controllerRequest(
        {
          type: action.MODIFICAR_REGISTRO,
          payload: {
            servicio: rutasServicios.TIPOS_DISPOSITIVOS,
            objeto: tiposDispositivo,
            id: tiposDispositivo.id as number
          }
        },
        openCloseSmarTeliaBackdrop
      );
    } else {
      await controllerRequest(
        {
          type: action.CREAR_UN_REGISTRO,
          payload: { servicio: rutasServicios.TIPOS_DISPOSITIVOS, objeto: tiposDispositivo }
        },
        openCloseSmarTeliaBackdrop
      );
    }
    if (completadoCorrectamente.current) {
      limpiarCampos('formTipoDispositivo', setLimpiar);
      setControl(<TablaTiposDispositivo key={enumComponentes.TIPOS_DISPOSITIVOS} />);
    }
    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  }

  /**
   * Evento de CarrouselIcons.
   * @param {any} event evento capturado que contiene el nombre del icono
   * @param {number} newValue nuevo valor para el tab (pestaña)
   */
  const handleTabChange = (event: any, newValue: number) => {
    setTiposDispositivo({
      ...tiposDispositivo,
      icon:
        event.currentTarget.children[0].id ??
        event.currentTarget.children[0].children[0].id ??
        event.target.alt
    });

    setValueTab(newValue);
  };

  React.useEffect(() => {
    if (props.tipoModificar)
      iconos.map((icono, index) => {
        if (icono === props.tipoModificar?.icon) setValueTab(index);
      });
  }, []);

  return (
    <div className={classes.root}>
      <form id="formTipoDispositivo" onSubmit={handleSubmit} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<TablaTiposDispositivo />} />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {props.tipoModificar
              ? dictionary_tiposDispositivos.MODIFICAR_TIPOS_DISPOSITIVOS
              : dictionary_tiposDispositivos.CREAR_TIPOS_DISPOSITIVOS}
          </Typography>
        </Box>
        <Grid container md={5} sm={8} xs={12} spacing={4} direction="column">
          <Grid item>
            <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
              {dictionary_tiposDispositivos.INFORMACION_TIPO_DISPOSITIVO}
            </Typography>
          </Grid>
          <Grid item container spacing={4} justifyContent="space-between" className={classes.cajas}>
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.CODIGO_TIPO_DISPOSITIVO}
                id="codigo"
                type="number"
                inputProps={{ min: '1001' }}
                required
                disabled={!!props.tipoModificar?.soloLectura}
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={props.tipoModificar?.codigo}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_TIPO_DISPOSITIVO}
                id="nombre"
                type="text"
                required
                disabled={!!props.tipoModificar?.soloLectura}
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={props.tipoModificar?.nombre}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
              {dictionary_tiposDispositivos.ICONO_TIPOS_DISPOSITIVOS}
            </Typography>
          </Grid>
          <Grid item container spacing={4} justifyContent="space-between" className={classes.cajas}>
            <Grid
              item
              xs={12}
              style={{
                opacity: props.tipoModificar?.soloLectura ? 0.5 : 1
              }}
            >
              <IconTabs
                onChange={handleTabChange}
                valueTab={valueTab}
                soloLectura={props.tipoModificar?.soloLectura}
              />
            </Grid>
          </Grid>
          <Grid item>
            {props.tipoModificar?.soloLectura ? (
              <Alert severity="info" className={classes.alert}>
                {dictionary_generic.INFO_SOLO_LECTURA}
              </Alert>
            ) : (
              <Grid container spacing={4} justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={12} sm={3}>
                  <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                    {props.tipoModificar ? dictionary_generic.MODIFICAR : dictionary_generic.CREAR}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
