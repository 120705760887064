import React, { useContext } from 'react';
import SnackBar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { AlertProps } from '@mui/lab';
import MuiAlert from '@mui/material/Alert';
import SnackBarContext from '../../../context/SnackBarContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Componente común SnackBar, tendrá 3 parámetros, severity (error,warning,info,success) ,defaultText (texto por defecto) y autohideduration (tiempo que permanece visible)
 * Usa un contexto para mostrar o cambiar el texto del componente cuando se necesite.
 * para más información https://material-ui.com/es/components/snackbars/
 */

export function CommonSnackBar(props: {
  defaultSeverity: 'error' | 'success' | 'warning' | 'info';
  autoHideDuration: number;
  defaultText: string;
}): JSX.Element {
  const { defaultSeverity, autoHideDuration, defaultText } = props;

  const { snackBar, setSnackBar } = useContext(SnackBarContext);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (setSnackBar) setSnackBar({ ...snackBar, open: false });
  };

  return (
    <SnackBar
      open={snackBar.open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        <Alert
          severity={!snackBar.severity ? defaultSeverity : snackBar.severity}
          onClose={handleClose}
        >
          {!snackBar.text ? defaultText : snackBar.text}
        </Alert>
      </div>
    </SnackBar>
  );
}
