import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { MultipleYaxisChart } from 'components/graficasApexCharts/MultipleYaxisChart';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataTableReport } from 'components/Reports/shared/DataTableReport/DataTableReport';
import { BotonTablaFilaGenerico } from 'components/common/table/BotonesTablaFila';
import { ContenedorElementos } from 'components/common/table/contenedorElementos';
import { enumTabla } from 'components/common/table/resources/enumTabla';
import { useTheme } from '@mui/material/styles';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import { useReportContext } from '../../Reports/Context/index.hook';
import { ConsumptionReportDetails } from './Details';

import { dictionary_informes } from 'resources/enums/plainText';
import { useStateDialogReports } from '../shared/hooks/useStateDialogDetails';

export function ConsumptionReport() {
  const theme = useTheme();

  const {
    reportData: [data],
    downloadReport,
    selectedReport: [selectedReport]
  } = useReportContext();

  const {
    graphData,
    report: { head, body, foot }
  } = data;

  const { openDetail, handleOpen, handleClose } = useStateDialogReports();

  const columns = [
    { name: 'dispositivo', label: 'Identificador' },
    { name: 'direccion', label: 'Dirección' },
    { name: 'fechaUltimaLectura', label: 'Fecha Última Lectura' },
    { name: 'valor', label: 'Consumo' },
    { name: 'contadorInicial', label: `Contador inicial (${selectedReport.headerUnit})` },
    { name: 'contadorFinal', label: `Contador final (${selectedReport.headerUnit})` },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          if (tableMeta.rowData[2] === 'Sin información') return <></>;

          return (
            <div key={enumTabla.BOTONES_TABLA}>
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaGenerico
                    datosTabla={tableMeta}
                    handleClick={handleOpen}
                    nombreBoton={'Ver detalles'}
                    icono={<ArtTrackIcon />}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </div>
          );
        }
      }
    }
  ];

  return (
    <>
      {data.report.body ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid container item xs={12}>
            <Grid container item xs={4} flexDirection={'row'} spacing={2}>
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent={'space-between'}
                style={{ marginRight: '15px' }}
              >
                <div>
                  <Typography variant="h6">{dictionary_informes.CONSUMO_MEDIO}</Typography>
                  <Typography variant="caption">Consumo medio del periodo actual</Typography>
                </div>
                <Typography variant="h6">{head.data.media} </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent={'space-between'}
                style={{ marginRight: '15px' }}
              >
                <div>
                  <Typography variant="h6">{dictionary_informes.CONSUMO_MAXIMO}</Typography>
                  <Typography variant="caption">Consumo máximo del periodo actual</Typography>
                </div>
                <Typography variant="h6">{head.data.maximo}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent={'space-between'}
                style={{ marginRight: '15px' }}
              >
                <div>
                  <Typography variant="h6">{dictionary_informes.CONSUMO_MINIMO}</Typography>
                  <Typography variant="caption">Consumo mínimo del periodo actual</Typography>
                </div>
                <Typography variant="h6">{head.data.minimo}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <MultipleYaxisChart
                {...graphData}
                showDetails={false}
                limitesGrafico={{ alto: 200 }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end'
            }}
          >
            <Button
              startIcon={<FileDownloadIcon />}
              color="secondary"
              onClick={() => downloadReport(graphData.idChart)}
            ></Button>
            <DataTableReport body={body} columns={columns} />
            <Typography style={{ fontWeight: 'bold', marginRight: '9.3%', padding: '10px 0px' }}>
              {dictionary_informes.TOTAL_CONSUMO} {foot}
            </Typography>
          </Grid>
          <ConsumptionReportDetails openDetail={openDetail} handleClose={handleClose} />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
