import React from 'react';
import { TiposDispositivoCRU } from './TiposDispositivoCRU';
import { tiposDispositivo } from './resources/interfaces/interfaceTiposDispositivo';

export function TiposDispositivoManager(props: {
  tipoSeleccionado?: tiposDispositivo;
}): JSX.Element {
  return (
    <>
      {props.tipoSeleccionado !== undefined ? (
        <TiposDispositivoCRU tipoModificar={props.tipoSeleccionado} />
      ) : (
        <TiposDispositivoCRU />
      )}
    </>
  );
}
