import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { dictionary_generic } from '../../../resources/enums/plainText';
import { Color } from '../../../resources/enums/enumColores';

// function rgbToHex(r, g, b) {
//     return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
//   }

/**
 * Componente que pinta un texto en un div con un color de fondo
 * @param {string | number | JSX.Element } elemento es el texto, número o componente que vamos a pintar
 * @param {string} colorElemento es color que van a tener las letas o números que vayamos a pintar
 * @param {string} colorFondo es el background-color que el vamos a poner al div que contiene el elemento
 * @param {string} ancho es el ancho medido en px que le queremos dar al div
 *
 * @return {JSX.Element} Elemento html que se va a pintar
 */
export function ContenedorElementos(props: {
  elemento: string | number | JSX.Element;
  colorElemento: string;
  colorFondo: string;
  altura?: string;
  ancho?: string;
  clase: string;
}): JSX.Element {
  const useStyles = makeStyles((theme: Theme) => ({
    contenedor: {
      backgroundColor: `${props.colorFondo}`,
      width: `${props.ancho}`,
      color: `${props.colorElemento}`,
      padding: '0.5em',
      textAlign: 'center',
      fontWeight: 'bold'
    },
    botones: {
      textAlign: 'center',
      backgroundColor: `${props.colorFondo}`,
      float: 'left',
      marginRight: '5px',
      borderRadius: '12%',
      height: props.altura ? props.altura : '32px',
      width: props.ancho ? props.ancho : '32px',
      '& div button span svg': {
        fill: `${props.colorElemento}` //icono del botón editar
      },
      '& button:hover': {
        backgroundColor: `${props.colorFondo}`
      },
      '& button': {
        borderRadius: '12%',
        borderColor: `${props.colorElemento}`,
        height: props.altura ? props.altura : '32px',
        width: props.ancho ? props.ancho : '32px'
      },
      '& button:hover svg': {
        fill: '#ffffff'
      },
      '& button svg': {
        fill: theme.palette.primary.main
      }
    }
  }));
  const classes: any = useStyles();
  return <div className={classes[props.clase]}>{props.elemento}</div>;
}

/**
 * Función que pinta el contenedor Activado
 * @returns {JSX.Element} Elemento contenedor activado
 */
export function ContenedorActivado(): JSX.Element {
  return (
    <ContenedorElementos
      elemento={dictionary_generic.ACTIVADO}
      colorElemento={Color.ACTIVADO_ELEMENTO}
      colorFondo={Color.ACTIVADO_FONDO}
      ancho="80px"
      clase="contenedor"
    />
  );
}
/**
 * Función que pinta el contenedor Desactivado
 * @returns {JSX.Element} Elemento contenedor desactivado
 */
export function ContenedorDesactivado(): JSX.Element {
  return (
    <ContenedorElementos
      elemento={dictionary_generic.DESACTIVADO}
      colorElemento={Color.DESACTIVADO_ELEMENTO}
      colorFondo={Color.DESACTIVADO_FONDO}
      ancho="80px"
      clase="contenedor"
    />
  );
}
