import { useContext, useReducer } from 'react';
import { ACTIONS_REDUCER_NAV } from '../resources/enums/enumActionsNav';
import { interfaceUseReducerNav } from '../resources/interfaces/interfaceUseReducerNav';
import { actionsReducerNav } from '../resources/types/actionsReducerNav';
import { useReconocimientoVoz } from '../../../../hooks/useReconocimientoVoz/useReconocimientoVoz';
import { objReducerNav } from '../resources/types/objReducerNav';
import { useLoginUsuario } from '../../../../hooks/useLogin/useLoginUsuario';
import ControlContext from '../../../../context/control/ControlContext';

const reducer = (state: objReducerNav, action: actionsReducerNav) => {
  switch (action.type) {
    case ACTIONS_REDUCER_NAV.CARGAR_ALERTAS:
      return { ...state, alertas: action.payload };
    case ACTIONS_REDUCER_NAV.ABRIR_DIALOGO_NOTIFICACIONES:
      return { ...state, abrirNotificaciones: action.payload };
    case ACTIONS_REDUCER_NAV.ABRIR_MI_CUENTA:
      return { ...state, abrirMiCuenta: action.payload };
    default:
      return state;
  }
};

export function useReducerNav(): interfaceUseReducerNav {
  const initialState: objReducerNav = {
    alertas: [],
    abrirNotificaciones: false,
    abrirMiCuenta: false
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { comenzarEscuchar } = useReconocimientoVoz();
  const { logout } = useLoginUsuario();
  const { setModuloSeleccionado, handleClickOpenConfiguratorAlert } = useContext(ControlContext);

  function escuchar() {
    comenzarEscuchar();
  }
  function cerrarSesion() {
    handleClickOpenConfiguratorAlert(() => {
      logout();
      setModuloSeleccionado(0);
    });
  }
  /**
   * Abre diálogo notificaciones
   */
  function setAbrirNotificaciones() {
    dispatch({
      type: ACTIONS_REDUCER_NAV.ABRIR_DIALOGO_NOTIFICACIONES,
      payload: !state.abrirNotificaciones
    });
  }

  function setAbrirMiCuenta() {
    dispatch({ type: ACTIONS_REDUCER_NAV.ABRIR_MI_CUENTA, payload: !state.abrirMiCuenta });
  }

  const events = {
    escuchar,
    setAbrirNotificaciones,
    cerrarSesion,
    setAbrirMiCuenta
  };

  return { state, events };
}
