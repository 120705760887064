import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import UserContext from '../../../context/UserContext';
import { useStyles } from '../../../pages/Main/styles';
import { IconosModulos } from '../../common/iconosModulos/IconosModulos';
import { Modulos } from '../../../resources/enums/enumModulo';
import logoSmarttelia from '../../../resources/images/smartTownLogo.jpg';

export default function MenuPrincipal({
  open,
  handleDrawerClose,
  handleClickModulo,
  moduloSeleccionado
}: {
  open: boolean;
  handleDrawerClose: () => void;
  handleClickModulo: (idModulo: number) => void;
  moduloSeleccionado: number;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const { datosUsuarioContext } = useContext(UserContext);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <img
          src={logoSmarttelia}
          alt={'Logo_Smarttelia'}
          style={{ width: '85%', cursor: 'pointer' }}
          onClick={() => handleClickModulo(0)}
        ></img>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {datosUsuarioContext.modulos.map((modulo) => (
          <React.Fragment key={modulo.id}>
            {modulo.id === Modulos.REGLAS ? (
              <Divider
                style={{
                  width: '25%',
                  float: 'right',
                  borderBottomWidth: 'medium',
                  marginRight: '18px',
                  padding: '10px 0px'
                }}
              />
            ) : (
              <></>
            )}
            <ListItem
              button
              style={{
                textAlign: 'right',
                marginTop: '4px',
                backgroundColor:
                  moduloSeleccionado === modulo.id ? theme.palette.grey[200] : 'white'
              }}
              onClick={() => handleClickModulo(modulo.id)}
            >
              <ListItemText primary={modulo.nombre} className={classes.textModulos} />
              <IconosModulos idmodulo={modulo.id} moduloseleccionado={moduloSeleccionado} />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}
