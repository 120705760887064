import { useContext, useRef } from 'react';
import UserContext from '../../context/UserContext';

import {
  crearUnRegistro,
  eliminarUnoVariosRegistros,
  modificarUnRegistro
} from '../../services/servicioCud';

export function useCud(): {
  crearRegistro: (servicio: string, objeto: any, idUsuario?: number) => Promise<void>;
  eliminarRegistros: (servicio: string, id: number[]) => Promise<void>;
  modificarRegistro: (servicio: string, objeto: any, id: any) => Promise<void>;
  registroGenerado: React.MutableRefObject<any>;
} {
  const registroGenerado = useRef<any>();

  /**
   * Esta función llama al servicio crearUnRegistro para dar de alta un nuevo item en la base de datos
   *
   * @param { string } servicio ruta del servicio al que vamos a lanzar la petición de crear
   * @param { any } objeto objeto que vamos a dar de alta en la base de datos
   * @param { number } idUsuario id del usuario que hace la operación
   */
  async function crearRegistro(
    servicio: string,
    objeto: any,
    idUsuario?: number | undefined
  ): Promise<void> {
    const respuesta: any = await crearUnRegistro(servicio, objeto, idUsuario);
    if (!respuesta.isAxiosError) registroGenerado.current = respuesta.data.result;
    return respuesta;
  }

  /**
   * Esta función llama al servicio eliminarUnoVariosRegistros para eliminar uno o varios registros
   *
   * @param { string } servicio ruta del servicio al que vamos a lanzar la petición de eliminar
   * @param { number[] } id objeto o lista de ids cuyos objetos queremos eliminar de la base de datos
   */
  async function eliminarRegistros(servicio: string, id: number[]): Promise<void> {
    const respuesta: any = await eliminarUnoVariosRegistros(servicio, id);
    if (!respuesta.isAxiosError) registroGenerado.current = respuesta.config.data;
    return respuesta;
  }

  /**
   * Esta función llama al servicio eliminarUnoVariosRegistros para eliminar uno o varios registros
   *
   * @param { string } servicio ruta del servicio al que vamos a lanzar la petición de eliminar
   * @param { any } objeto objeto que vamos a modificar
   * @param { number } id objeto o lista de ids cuyos objetos queremos eliminar de la base de datos
   */
  async function modificarRegistro(servicio: string, objeto: any, id: number): Promise<void> {
    const respuesta: any = await modificarUnRegistro(servicio, objeto, id);
    return respuesta;
  }

  return {
    crearRegistro,
    eliminarRegistros,
    modificarRegistro,
    registroGenerado
  };
}
