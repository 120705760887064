import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Icon,
  Typography,
  useTheme
} from '@mui/material';
import { BackdropTarjetasModulos } from './BackdropTarjetasModulos';
import { useStyles } from './styles';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { dictionary_generic, dictionary_unidades } from '../../../../resources/enums/plainText';
import { useGraficas } from '../../../graficas/hooks/useGraficas/useGraficas';
import { GraphTypes } from '../../../graficas/resources/enums/enumGraphTypes';
import AddIcon from '@mui/icons-material/Add';
import ReactApexChart from 'react-apexcharts';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { MeasuresText } from '../../../graficas/resources/enums/enumMeasuresText';

export const agregateOptions: any = {
  [1]: 'AVG',
  [2]: 'SUM',
  [3]: 'MAX',
  [4]: 'MIN'
};

export function EvolutiveGraphCard(props: any): JSX.Element {
  const classes = useStyles();
  const { tarjeta, dispositivos, handleCardClick = null } = props;

  const { codigoTipoMedida, titulo } = tarjeta;
  const [, setTransition] = React.useState(true);

  const { obtenerDatosGrafica } = useGraficas();
  const theme = useTheme();
  const [state, setState] = React.useState<any>();
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const commonGraphOptions: ApexCharts.ApexOptions = {
    stroke: {
      curve: 'smooth'
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontFamily: 'Open Sans',
          fontWeight: 400
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          let res;
          val ? (res = (val + ' ' + state.unid) as dictionary_unidades) : (res = '');
          return res;
        }
      }
    }
  };

  const cardGraphOptions: ApexCharts.ApexOptions = {
    ...commonGraphOptions,
    title: {
      text: tarjeta.titulo,
      style: {
        fontWeight: 400,
        fontFamily: 'Open Sans'
      }
    },
    chart: {
      type: 'line',
      id: 'chart',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
      animations: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      show: false
    }
  };
  const dialogGraphOptions: ApexCharts.ApexOptions = {
    ...commonGraphOptions,
    chart: {
      type: 'line',
      id: 'chart',
      toolbar: {
        show: true
      }
    },
    title: {
      text: tarjeta.titulo,
      style: {
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        fontSize: '20px'
      }
    },
    xaxis: {
      labels: {
        show: true
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    legend: {
      show: true,
      position: 'bottom'
    }
  };

  React.useEffect(() => {
    obtenerDatosGrafica(
      codigoTipoMedida,
      tarjeta.range,
      agregateOptions[tarjeta.codigoFuncionAgregada],
      GraphTypes.area,
      dispositivos,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      'line',
      tarjeta.idModulo
    )
      .then((response) => {
        if (response.noValues) {
          setState(undefined);
          return '';
        }

        if (codigoTipoMedida == TiposMedida.CONSUMO || codigoTipoMedida == TiposMedida.GENERADO) {
          const { datos }: { datos: Array<{ data: Array<number>; name: string; type: string }> } =
            response;
          const consumoTotal: { data: Array<number>; name: string; type: string } = {
            data: [],
            name: `${MeasuresText[codigoTipoMedida]} total`,
            type: 'line'
          };
          if (datos === undefined) {
            setState(undefined);
            return '';
          }
          datos.forEach(({ data }) => {
            consumoTotal.data = data.map((item, index) => {
              let valor = consumoTotal.data[index];
              valor = valor ? Number.parseFloat((valor + item).toFixed(2)) : item;

              return valor;
            });
          });
          response.datos.push(consumoTotal);
        }
        setState(response);
      })
      .finally(() => setOpenBackdrop(false));
  }, []);

  return (
    <>
      <Grid item xs={12} sm={8} lg={4} sx={{ minHeight: '166px', maxHeight: '182px' }}>
        <Grow in timeout={650} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', cursor: handleCardClick ? 'context-menu' : 'auto' }}
            onContextMenu={(event) => {
              event.preventDefault();
              if (handleCardClick) {
                handleCardClick(event, tarjeta.position, setTransition);
              }
            }}
          >
            <CustomTooltip title={titulo} placement={'bottom'}>
              <Box
                style={{
                  boxShadow: `0px 0px 1px 2px #e0e0e0`,
                  borderRadius: '15px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative'
                }}
                alignItems={'center'}
                textAlign={'center'}
              >
                <BackdropTarjetasModulos open={openBackdrop} />
                {!openBackdrop ? (
                  state ? (
                    <>
                      <div style={{ width: '100%', height: '100%' }}>
                        <ReactApexChart
                          options={cardGraphOptions}
                          series={[...(state.datos != undefined ? state.datos : '')]}
                          height={150}
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Typography className={classes.comparativeValue}>
                        <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                          {dictionary_generic.NO_DATA}
                        </span>
                      </Typography>
                    </div>
                  )
                ) : (
                  <></>
                )}
                {state && (
                  <Icon
                    className={classes.extraIcon}
                    component={'div'}
                    onClick={() => handleOpenDialog()}
                    style={{ position: 'absolute', top: '12px', right: '15px' }}
                  >
                    <AddIcon color="primary" fontSize="large" />
                  </Icon>
                )}
              </Box>
            </CustomTooltip>
          </div>
        </Grow>
      </Grid>
      <div>
        {openDialog && (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="dialog-chart"
            maxWidth={'xl'}
          >
            <DialogTitle style={{ cursor: 'move' }} id="dialog-chart"></DialogTitle>
            <DialogContent>
              <ReactApexChart
                options={dialogGraphOptions}
                series={state != undefined ? (state.datos != undefined ? state.datos : '') : ''}
                height={450}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>{dictionary_generic.CERRAR}</Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
