import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// Constantes
export const ANCHO_DRAWER = 280; // Ancho del Drawer -> componente MenuPrincipal
export const MARGEN_IZQUIERDO = 10; // Margen del contenido con el Drawer -> componente MenuPrincipal
export const MARGEN_SUPERIOR = 80; // Margen del contenido con el AppBar -> componente NavPrincipal

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${ANCHO_DRAWER}px)`,
      marginLeft: ANCHO_DRAWER,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      position: 'absolute',
      left: 0,
      marginLeft: '10px'
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: ANCHO_DRAWER,
      flexShrink: 0
    },
    drawerPaper: {
      width: ANCHO_DRAWER
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContentContent: 'flex-end'
    },
    content: {
      flexGrow: 1,
      padding: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -ANCHO_DRAWER,
      marginTop: MARGEN_SUPERIOR
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: MARGEN_IZQUIERDO
    },
    textModulos: {
      '& span': {
        fontSize: '17px',
        fontFamily: 'Open Sans',
        fontVariant: 'all-small-caps',
        marginRight: '10px'
      }
    }
  })
);
