import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from './styles';
import { InteraccionSections } from './InteraccionSections';
import { planificadorPeticion } from './resources/interfaces/interfacePlanificadorPeticion';
import { section } from '../../../../../../sections/resources/interfaces/interfaceSection';
import { rutasServicios } from '../../../../../../../resources/enums/enumRutasServicios';
import { useControlador } from '../../../../../../../hooks/useControlador/useControlador';
import { action } from '../../../../../../../hooks/useControlador/resources/enums/enumActions';

/**
 * Este componente recibe las sections del device asociado al dispositivo. Las recorre y las
 * muestra una a una. Las sections son las distintas acciones que podemos ejecutar en el
 * dispositivo: apagar, encender, ajustar brillo, etc.
 * @param props
 */
export function InteraccionDevice(props: { sections: section[]; idDevice: number }): JSX.Element {
  const classes = useStyles();
  const { controllerRequest } = useControlador();

  const handleChangeSection = async (section: section, valor?: string) => {
    const registro: planificadorPeticion = {
      idPlugin: section.idPlugin as number,
      idDevice: props.idDevice,
      idSection: section.id as number,
      datosPeticion: valor,
      procesado: 0
    };
    await controllerRequest({
      type: action.CREAR_UN_REGISTRO,
      payload: { servicio: rutasServicios.PLANIFICADOR_PETICIONES, objeto: registro }
    });
  };

  return (
    <>
      {props.sections.map((section: section) => {
        return (
          <div key={section.id} className={classes.section}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                id={section.nombre}
                variant="subtitle1"
                className={classes.etiquetaSection}
              >
                {section.etiqueta}
              </Typography>
              <InteraccionSections section={section} handleChange={handleChangeSection} />
            </Grid>
          </div>
        );
      })}
    </>
  );
}
