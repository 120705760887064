import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pxToVw } from '../../functions/functions';

const useStylesTarjetas = makeStyles((theme: Theme) => ({
  tarjetaTipoAccion: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '15px'
    },
    zIndex: 1
  },
  tarjetaCondicionAccion: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '15px'
    }
  },
  textoTarjeta: {
    cursor: 'pointer',
    fontVariant: 'all-small-caps',
    textAlign: 'center',
    fontSize: pxToVw(15),
    paddingTop: '20%'
  },
  tarjetaDesactivada: {
    backgroundColor: '#CBCBCB'
  },
  cajas: {
    marginBottom: '15px'
  },

  botonCerrarDialogo: {
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 2
  },
  botonAddCondicion: {
    backgroundColor: theme.palette.secondary.main
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium'
  },
  root: {
    width: '100%',
    '& > * + *': {},
    paddingTop: '10px',
    paddingLeft: '30px'
  },
  botonCrear: {
    flexDirection: 'row-reverse'
  }
}));
export default useStylesTarjetas;
