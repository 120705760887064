/**
 * Usuario
 * @alias Usuario
 * @typedef Usuario
 * @description Interfaz del objeto usuario como se almacena en la base de datos de SmartTeliaGestion
 * @property {number} id identificador del usuario
 * @property {string} nombreUsuario nombre de usuario para el login de acceso en la plataforma SmartTelia
 * @property {string} password contraseña de usuario para el login de acceso en la plataforma SmartTelia
 * @property {string} nombre  nombre del usuario
 * @property {string} apellidos apellidos del usuario
 * @property {string} direccion dirección del usuario
 * @property {string} provincia provincia del usuario
 * @property {string} poblacion población del usuario
 * @property {string} telefono telefono del usuario
 * @property {string} email email del usuario
 * @property {string} estado estado del usuario
 * @property {number} eliminado indica si el usuario está eliminado de forma lógica
 * @property {number} sincronizado indica si el usuario está sincronizado
 * @property {number} administrador indica si el usuario es administrador
 * @property {number} idCliente id del cliente asociado
 * @property {number} idCodigoPostal id del código postal asociado
 * @property {number} usuarioGlobal indica si el usuario es usuarioGlobal
 * @property {number} tecnico indica si el usuario es tecnico
 * @property {number | null} idPerfil identificador del perfil de usuario
 * @property {number} dispositivosBloqueados dispositivos bloqueados del usuario
 * @property {string | null} tokenSecret token secreto del usuario
 */

export interface usuario {
  id?: number;
  nombreUsuario: string;
  password: string;
  nombre: string;
  apellidos: string;
  direccion: string;
  provincia: string;
  poblacion: string;
  telefono: number;
  email: string;
  estado: number;
  eliminado: number;
  sincronizado: number;
  administrador: number;
  idCliente: number;
  idCodigoPostal: number;
  usuarioGlobal: number;
  tecnico: number;
  idPerfil: number | null;
  dispositivosBloqueados: number;
  tokenSecret: string | null;
  latitude: number | null;
  longitude: number | null;
}

export const objectUsuario = (elemento?: usuario): usuario => {
  let usuario: usuario;

  elemento === undefined
    ? (usuario = {
        nombreUsuario: '',
        password: '',
        nombre: '',
        apellidos: '',
        direccion: '',
        provincia: '',
        poblacion: '',
        telefono: 0,
        email: '',
        estado: 0,
        eliminado: 0,
        sincronizado: 0,
        administrador: 0,
        idCliente: 0,
        idCodigoPostal: 0,
        usuarioGlobal: 0,
        tecnico: 0,
        idPerfil: null,
        dispositivosBloqueados: 0,
        tokenSecret: null,
        latitude: null,
        longitude: null
      })
    : (usuario = {
        id: elemento.id,
        nombreUsuario: elemento.nombreUsuario,
        password: elemento.password,
        nombre: elemento.nombre,
        apellidos: elemento.apellidos,
        direccion: elemento.direccion,
        provincia: elemento.provincia,
        poblacion: elemento.poblacion,
        telefono: elemento.telefono,
        email: elemento.email,
        estado: elemento.estado,
        eliminado: elemento.eliminado,
        sincronizado: elemento.sincronizado,
        administrador: elemento.administrador,
        idCliente: elemento.idCliente,
        idCodigoPostal: elemento.idCodigoPostal,
        usuarioGlobal: elemento.usuarioGlobal,
        tecnico: elemento.tecnico,
        idPerfil: elemento.idPerfil,
        dispositivosBloqueados: elemento.dispositivosBloqueados,
        tokenSecret: elemento.tokenSecret,
        latitude: elemento.latitude,
        longitude: elemento.longitude
      });
  return usuario;
};
