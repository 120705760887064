import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Tarjeta } from '../../principal/common/Tarjeta';
import { useStyles } from '../../principal/styles';
import imgHeader from '../../../../resources/images/headerConfiguracion.jpg';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { IconosComponentes } from '../../../common/iconosComponentes/iconosComponentes';
import { Transitions } from '../../../../resources/enums/enumTransitions';
import { DeviceManager } from '../../../device/DeviceManager';
import { TablaEtiquetas } from '../../../etiquetas/TablaEtiquetas';
import { TablaGrupos } from '../../../grupos/TablaGrupos';
import { PluginsDisponibles } from '../../../plugins/pluginsDisponibles/PluginsDisponibles';
import { PluginsInstalados } from '../../../plugins/pluginsInstalados/PluginsInstalados';
import { TablaTiposDispositivo } from '../../../tiposDispositivo/TablaTiposDispositivo';
import { TablaTiposMedidas } from '../../../tiposMedidas/TablaTiposMedidas';
import { TablaTiposActuaciones } from '../../../tiposActuaciones/TablaTiposActuaciones';
import { UnitsTypes } from 'components/UnitTypes';

//Estos son los componentes que se renderizarán en el módulo Configuración general.
const componentesConfiguracionGeneral = [
  <TablaGrupos key={enumComponentes.GRUPOS} data-Transition={Transitions.Zoom} />,
  <TablaEtiquetas key={enumComponentes.ETIQUETAS} data-Transition={Transitions.Zoom} />,
  <TablaTiposDispositivo
    key={enumComponentes.TIPOS_DISPOSITIVOS}
    data-Transition={Transitions.Zoom}
  />,
  <UnitsTypes key={enumComponentes.TIPOS_UNIDADES} data-Transition={Transitions.Zoom} />,
  <TablaTiposMedidas key={enumComponentes.TIPOS_MEDIDAS} data-Transition={Transitions.Zoom} />,
  <PluginsDisponibles key={enumComponentes.PLUGINS} data-Transition={Transitions.Zoom} />,
  <PluginsInstalados key={enumComponentes.PLUGINS_INSTALADOS} data-Transition={Transitions.Zoom} />,
  <DeviceManager key={enumComponentes.DEVICES} data-Transition={Transitions.Zoom} />,
  <TablaTiposActuaciones key={enumComponentes.TIPOS_ACCIONES} />
  // <Configurador key={enumComponentes.CONFIGURADOR} />
];

export function ConfiguracionGeneral({
  setControl
}: {
  setControl: (element: JSX.Element) => void;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          style={{
            position: 'absolute',
            top: '180px',
            marginLeft: '30px',
            backgroundColor: 'white'
          }}
        >
          <Typography variant={'h2'} style={{ fontWeight: 'bold' }}>
            CONFIGURACIÓN
          </Typography>
          <Typography variant={'h2'} style={{ fontWeight: 'bold', marginLeft: '300px' }}>
            GENERAL
          </Typography>
        </Box>
        <img src={imgHeader} alt="Imagen header" style={{ width: '100%' }}></img>
        <Box style={{ position: 'absolute', top: '130px', marginLeft: '30px' }}>
          <Typography variant={'h3'} style={{ fontWeight: 'bold' }}></Typography>
        </Box>
      </Grid>
      {componentesConfiguracionGeneral.map((item: JSX.Element) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={2} key={item.key}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'space-around'}
              onClick={() => setControl(item)}
              className={classes.button_tarjetaHome}
            >
              <IconosComponentes componente={item.key as enumComponentes} />
              <Typography
                paragraph
                variant={'body1'}
                style={{
                  fontVariant: 'all-small-caps',
                  textAlign: 'center'
                }}
              >
                {item.key}
              </Typography>
            </Tarjeta>
          </Grid>
        );
      })}
    </Grid>
  );
}
