import React, { useContext, useRef, useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Link } from '@mui/material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { SettingsOutlined, Extension } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { useStyles } from './styles';
import DialogoInfoPlugin from './DialogoInfoPlugin';
import { plugin } from '../resources/interfaces/interfacePlugin';
import { changeStatusPlugin } from '../resources/enums/enumCambiarEstado';
import SnackBarContext from '../../../context/SnackBarContext';
import { dictionary_generic, dictionary_plugins } from '../../../resources/enums/plainText';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import ControlContext from '../../../context/control/ControlContext';
import { AlertDialog } from '../../common/alertDialog/AlertDialog';
import { DeviceManager } from '../../device/DeviceManager';
import { enumComponentes } from '../../../resources/enums/enumComponente';

export default function TarjetaPlugin(props: { plugin: plugin }): JSX.Element {
  const classes = useStyles();
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { setSnackBar } = useContext(SnackBarContext);
  const [openDialogoInfo, setOpenDialogoInfo] = useState(false);
  const { setControl } = useContext(ControlContext);
  const tituloAlertInstallUninstall = useRef<string>(dictionary_plugins.AVISO_DESINSTALAR);
  const [openAlertInstallUninstall, setOpenAlertInstallUninstall] = useState<boolean>(false);

  /**
   * Handle cuando hacemos click en instalar/desintalar plugin.
   */
  const handleInstallUninstall = async () => {
    setOpenAlertInstallUninstall(true);
    // si el plugin no está instalado, lo instalamos, y si no, lo desinstalamos
    tituloAlertInstallUninstall.current = props.plugin.instalado
      ? dictionary_plugins.AVISO_DESINSTALAR
      : dictionary_plugins.AVISO_INSTALAR;
  };

  /**
   * Función que se llama cuando hacemos clic en aceptar en el dialogo de aviso
   */
  const confirmAlertInstallUninstall = async (): Promise<void> => {
    if (props.plugin.instalado) {
      await handleRequest(changeStatusPlugin.DESINSTALAR, { nombre: props.plugin.nombre });
    } else {
      await handleRequest(changeStatusPlugin.INSTALAR, {
        imagenDocker: props.plugin.imagenDocker,
        version: props.plugin.version,
        puerto: props.plugin.puerto
      });
    }
    if (completadoCorrectamente.current) {
      //actualizamos los cambios del plugin
      props.plugin.instalado = !props.plugin.instalado;
    }

    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  };

  /**
   * Handle para realizar la petición
   * @param accion accion que queremos realizar (ejecutar o pausar)
   * @param objeto objeto que contiene el nombre del plugin
   */
  const handleRequest = async (accion: string, objeto: any) => {
    await controllerRequest(
      {
        type: action.CAMBIAR_ESTADO_PLUGIN,
        payload: { servicio: accion, objeto: objeto }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );
  };

  /**
   * Handle cuando hacemos click en el icono de ajustes. Se nos abrirá los devices del plugin.
   */
  const handleOpenAjustes = async () => {
    // obtenemos los datos plugin seleccionado a través de su nombre
    const respuesta = await controllerRequest({
      type: action.OBTENER_PLUGIN_BY_NOMBRE,
      payload: { servicio: rutasServicios.PLUGINS, nombre: props.plugin.nombre }
    });
    const pluginObtenido = respuesta.data.result;
    if (pluginObtenido !== null && pluginObtenido !== undefined) {
      setControl(
        <DeviceManager
          pluginSeleccionado={pluginObtenido}
          key={enumComponentes.DEVICES_POR_PLUGIN + dictionary_plugins.DISPONIBLE}
        />
      );
    }
  };

  /**
   * Handle cuando hacemos click en el icono de warning o en 'más informacion'
   */
  const handleClickOpenDialogoInfo = () => {
    setOpenDialogoInfo(true);
  };

  return (
    <>
      <Card className={classes.tarjeta} elevation={8}>
        <div className={classes.imagen}>
          {props.plugin.logotipo !== '' ? (
            <CardMedia
              className={classes.logotipo}
              component="img"
              image={props.plugin.logotipo}
              title={props.plugin.nombre}
            />
          ) : (
            <Extension className={classes.logotipoPlugin} color="secondary" />
          )}
        </div>
        <div className={classes.detalles}>
          <CardContent className={classes.contenido}>
            <Typography variant="h5" paragraph>
              {props.plugin.nombre}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {props.plugin.descripcion.length > 100
                ? props.plugin.descripcion.substr(0, 100) + '...'
                : props.plugin.descripcion}
            </Typography>
            {props.plugin.descripcion.length > 100 ? (
              <Link
                href="#"
                className={classes.enlaces}
                variant="body1"
                onClick={handleClickOpenDialogoInfo}
              >
                {dictionary_generic.MAS_INFORMACION}
              </Link>
            ) : (
              <></>
            )}
          </CardContent>
          <CardActions className={classes.controles}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography component="em" variant="subtitle1" color="textSecondary">
                  {`Versión: ${props.plugin.version}`}
                </Typography>
              </Grid>
              <Grid item>
                {props.plugin.descatalogado === 1 || props.plugin.actualizado === false ? (
                  <IconButton
                    aria-label="descatalogado/desactualizado"
                    className={classes.botonAjustes}
                    onClick={handleClickOpenDialogoInfo}
                  >
                    <WarningIcon className={classes.iconoAviso} />
                  </IconButton>
                ) : (
                  <></>
                )}
                {/* <Button
                  className={classes.botonInstalar}
                  variant="contained"
                  type="button"
                  aria-label="install/uninstall"
                  color="secondary"
                  onClick={handleInstallUninstall}
                >
                  {props.plugin.instalado
                    ? dictionary_generic.DESINSTALAR
                    : dictionary_generic.INSTALAR}
                </Button> */}
                <IconButton
                  aria-label="settings"
                  className={classes.botonAjustes}
                  onClick={handleOpenAjustes}
                  disabled={!props.plugin.instalado}
                >
                  <SettingsOutlined className={classes.iconoAjustes} />
                </IconButton>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
      <DialogoInfoPlugin
        openDialogoInfo={openDialogoInfo}
        setOpenDialogoInfo={setOpenDialogoInfo}
        plugin={props.plugin}
      />
      <AlertDialog
        titulo={tituloAlertInstallUninstall.current}
        open={openAlertInstallUninstall}
        setOpen={setOpenAlertInstallUninstall}
        onConfirm={confirmAlertInstallUninstall}
      />
    </>
  );
}
