import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Etiquetas Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  // Etiquetas CRU
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  }
});
export default useStyles;
