import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pxToVw } from '../../../../functions/functions';

export const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    padding: '24px'
  },
  gridMapa3d: {
    height: '400px',
    paddingBottom: '15px'
  },
  boxTypographyMapa: {
    position: 'absolute',
    top: '190px',
    marginLeft: '30px'
  },
  typographyBienvenidoA: {
    fontWeight: 'bold'
  },
  typographyFechaMapa: {
    marginTop: '15px'
  },
  typographyPrevision: {},
  imgTiempoMapa: {
    width: '100%',
    height: '53px'
  },
  typographyMaxMin: {},
  bMaxMin: {
    marginRight: '2px'
  },
  tarjeta: {
    textAlign: 'end',
    width: '100%',
    justifyContent: 'end'
  },
  typographyHoy: {
    fontSize: '70px',
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  typographyTarjetas: {
    fontSize: pxToVw(18),
    fontVariant: 'all-small-caps'
  },
  typographyNumerosTarjetas: {
    fontSize: '70px',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  typographyClima: {
    fontSize: '70px',
    textAlign: 'end',
    letterSpacing: '-5px'
  },
  imgSalidaSolLuna: {
    width: pxToVw(40)
  },
  box: {
    height: '100%'
  },
  imgIntereses: {
    width: '60px'
  },
  iconoAdd: {
    marginBottom: 'auto',
    marginLeft: 'auto'
  },
  boxIntereses: {
    marginBottom: '15px',
    alignItems: 'end'
  },
  typographyAlert: {
    fontSize: '70px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'end',
    lineHeight: '1'
  },
  boxContainer: {
    boxShadow: `0px 0px 1px 2px #e0e0e0`,
    borderRadius: '15px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    width: '100%',
    marginTop: '16px',
    marginLeft: '16px',
    '&:hover': {
      boxShadow: '0px 0px 1px 2px #646868'
    }
  },
  boxColumn: (props: any) => ({
    display: 'flex',
    boxShadow: `0px 0px 1px 2px ${props.color}`,
    borderRadius: '15px',
    width: '100%',
    height: '166px',
    '&:hover': {
      boxShadow: '0px 0px 1px 2px #646868'
    },
    position: 'relative'
  }),
  iconMedida: {
    width: '50%',
    height: '100%',
    textAlign: 'left'
  },
  iconContainer: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  svg: {
    fontSize: '1.65rem',
    width: '1.5em'
  },
  extraIcon: {
    width: '30px',
    height: '30px',
    marginRight: '-8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  iconInfo: {
    position: 'absolute',
    top: '21px',
    left: '30px'
  },
  value: {
    fontSize: '0.85rem',
    textAlign: 'right',
    lineHeight: '1',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: '100%'
  },
  comparativeValue: {
    fontSize: '0.95rem',
    textAlign: 'center',
    lineHeight: '1',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: '100%'
  },
  lowValue: {
    fontSize: '0.7rem',
    textAlign: 'right',
    lineHeight: '0.95',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },

  valueTitle: {
    fontWeight: 'bold',
    fontSize: '0.62rem',
    color: theme.palette.primary.main,
    fontVariant: 'all-small-caps',
    textAlign: 'right'
  },
  measure: {
    fontWeight: 'normal',
    fontSize: '0.55rem'
  },
  measureUnity: {
    fontWeight: 'normal',
    fontVariant: 'normal'
  },
  valueText: {
    fontSize: '0.55rem',
    fontVariant: 'all-small-caps',
    textAlign: 'right'
  },
  comparativeText: {
    fontSize: '0.55rem',
    fontVariant: 'all-small-caps',
    textAlign: 'center'
  },
  divider: {
    width: '50%',
    height: '3px',
    borderBottomWidth: 'medium',
    margin: '0px 50px'
  },
  graphInfo: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    lineHeight: '1.3'
  },
  graphTitle: {
    fontVariant: 'all-small-caps'
  },
  graphExtraInfo: {
    color: 'red'
  },
  elements: {
    margin: '1px'
  }
}));
