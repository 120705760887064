import React from 'react';
import { AnimacionCarrusel } from '../common/animacionCarrusel/AnimacionCarrusel';
import { State } from './hooks/useReducerVideo/resources/types/typeState';
import { Events } from './hooks/useReducerVideo/resources/types/typeEvents';
import { DispositivoVideo } from './resources/interfaces/interfaceDispositivoVideo';
import useReducerVideo from './hooks/useReducerVideo/useReducerVideo';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { dictionary_cctv } from '../../resources/enums/plainText';

/**
 * Componente que muestra el video con sus sin controles (de playback o stream)
 * @param { State | undefined } state State del hook useReducerVideo
 * @param { Events | undefined } events Events del hook useReducerVideo
 * @param { DispositivoVideo | undefined } datosVideo en caso de no crear el hook, datos minimos
 * del dispositivo para su reproducción
 * @param { boolean | undefined } isDialogoVideo en caso de no crear el hook, indicar si estamos en
 * el dialogo de video
 * @returns { JSX.Element } Devuelve el componente
 */
export function Video({
  state,
  events,
  datosVideo,
  isDialogoVideo
}: {
  state: State;
  events: Events;
  datosVideo?: DispositivoVideo;
  isDialogoVideo?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const reducer =
    state && events
      ? { state, events }
      : useReducerVideo({
          datosVideo: datosVideo,
          isDialogoVideo: isDialogoVideo
        });

  return (
    <>
      {!reducer.state.isPlaying && reducer.state.isDisconnect ? (
        <Typography variant="subtitle1" className={classes.sinImagenes}>
          {dictionary_cctv.NO_HAY_IMAGENES}
        </Typography>
      ) : (
        <>
          <canvas className={classes.canvasVideo} ref={reducer.state.canvas} />

          {!reducer.state.isPlaying ? (
            <div className={classes.animacionVideo}>
              <AnimacionCarrusel />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
