import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, ButtonGroup, Grid } from '@mui/material';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import useStyles from './styles';

const BRILLO = 'Establecer brillo';
const POWER = 'Apagar/Encender';
const VALVE = 'Cerrar/Abrir Válvula';

/**
 * Botones de interacción de la tarjeta para DEMO COMERCIAL
 */
export function InteraccionBasica(props: { isLight?: boolean; isValve?: boolean }): JSX.Element {
  const classes = useStyles();
  const botones = ['Apagar', 'Encender'];
  const [botonSelect, setBotonSelect] = useState('');

  return (
    <>
      {props.isLight ? (
        <div key="interaction-basic" className={classes.section}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography id="slider" variant="subtitle1" className={classes.etiquetaSection}>
              {BRILLO}
            </Typography>
            <Slider
              className={classes.widthSection}
              defaultValue={0}
              getAriaValueText={(value: number) => `${value}%`}
              aria-labelledby="slider"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={0}
              max={100}
            />
          </Grid>
        </div>
      ) : (
        <></>
      )}
      {props.isValve ? (
        <div key="interaction-basic" className={classes.section}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography id="slider" variant="subtitle1" className={classes.etiquetaSection}>
              {VALVE}
            </Typography>
            <Switch
              defaultChecked={false}
              color="secondary"
              name="switch"
              inputProps={{ 'aria-label': 'switch' }}
              size={'small'}
            />
          </Grid>
        </div>
      ) : (
        <div key="interaction-basic" className={classes.section}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography id="switch" variant="subtitle1" className={classes.etiquetaSection}>
              {POWER}
            </Typography>
            <ButtonGroup
              key="Button-Group"
              disableElevation
              variant="outlined"
              color="secondary"
              size="small"
              aria-label="Button-Group"
            >
              {botones.map((boton) => {
                return (
                  <Button
                    variant={botonSelect === boton ? 'contained' : 'outlined'}
                    key={boton}
                    onClick={() => setBotonSelect(boton)}
                  >
                    {boton}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
        </div>
      )}
    </>
  );
}
