import { makeStyles } from '@mui/styles';
import imgLogin from '../../resources/images/Inicio.png';

//const imgFondo = localStorage.getItem('cliente') !== null ? imgLoginSaaS : imgLogin;

const useStyles = makeStyles({
  gridLogin: {
    height: '100%',
    background: `url(${imgLogin})  20% /cover`
    //linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url(${fondo1}) 0% -27% /cover
  },
  gridLogo: {
    width: `max(20vw, 300px)`
  },
  divLogo: {
    width: '100%',
    height: '150px',
    marginTop: '40px'
  },
  imgLogo: {
    height: 'cover',
    width: 'cover'
  }
});
export default useStyles;
