import { Group } from 'components/graficas/resources/enums/enumGroup';
import { differenceInDays } from 'date-fns';

export function getGroup(startDate: Date, endDate: Date) {
  const dayDifference = differenceInDays(endDate, startDate);
  if (dayDifference === 0) {
    return Group.HOUR;
  } else if (dayDifference >= 1 && dayDifference <= 31) {
    return Group.DAY;
  } else {
    return Group.MONTH;
  }
}
