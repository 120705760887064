import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Grupo Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  fabBotonError: {
    position: 'fixed',
    bottom: '5%',
    right: '150px',
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  // Grupo CRU
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  gridSwitch: {
    textAlign: 'center'
  }
});
export default useStyles;
