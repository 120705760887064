import * as React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Stream } from '../resources/enums/enumStream';
import { Events } from '../hooks/useReducerVideo/resources/types/typeEvents';
import { State } from '../hooks/useReducerVideo/resources/types/typeState';
import { dictionary_cctv } from '../../../resources/enums/plainText';

/**
 * Componente que muestra los controles del Stream (reproducir) para cambiar de tipo de stream
 * @param { State } state State del hook useReducerVideo
 * @param { Events } events Events del hook useReducerVideo
 * @returns { JSX.Element } Devuelve el componente
 */
export function ControlStream({ state, events }: { state: State; events: Events }): JSX.Element {
  return (
    <>
      {events.showControlStream() ? (
        <FormControl component="fieldset">
          <FormLabel component="legend">{dictionary_cctv.STREAM}</FormLabel>
          <RadioGroup
            row
            aria-label="stream"
            name="stream-radio-buttons-group"
            value={state.stream}
            onChange={events.handleChangeStream}
          >
            <FormControlLabel
              value={Stream.DEFECTO}
              control={<Radio />}
              label={dictionary_cctv.POR_DEFECTO}
            />
            <FormControlLabel
              value={Stream.PRINCIPAL}
              control={<Radio />}
              label={dictionary_cctv.PRINCIPAL}
            />
            <FormControlLabel
              value={Stream.SECUNDARIO}
              control={<Radio />}
              label={dictionary_cctv.SECUNDARIO}
            />
            <FormControlLabel
              value={Stream.TERCIARIO}
              control={<Radio />}
              label={dictionary_cctv.OTRO}
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <></>
      )}
    </>
  );
}
