import * as React from 'react';
import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { dictionary_generic, enumLabel } from 'resources/enums/plainText';
import useStyles from './index.styles';
import { useUnitTypeCRU } from './index.hook';
import { VolverAtras } from 'components/common/botonVolverAtras/VolverAtras';
import { UnitsTypes } from '..';
import { enumComponentes } from 'resources/enums/enumComponente';
import { Unit } from 'shared/types';

export function UnitsTypesCRU({
  unitTypeSelected = undefined
}: {
  unitTypeSelected?: Unit | undefined;
}) {
  const { unitType, handleInputChange, handleInputInvalid, handleSubmit } = useUnitTypeCRU({
    unitType: unitTypeSelected
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form id="formTipoUnidad" onSubmit={handleSubmit} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<UnitsTypes key={enumComponentes.TIPOS_UNIDADES} />} />
          <Typography variant="h5" className={classes.tittleForm}>
            {unitType.title}
          </Typography>
        </Box>

        <Grid container item md={5} sm={8} xs={12} spacing={4} direction="column">
          <Grid item container spacing={4} justifyContent="space-between">
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.CODIGO_UNIDAD}
                id="codigo"
                type="number"
                inputProps={{ min: '1001' }}
                required
                disabled={Boolean(unitType.soloLectura)}
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={unitType.codigo}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_UNIDAD}
                id="nombre"
                type="text"
                required
                disabled={Boolean(unitType.soloLectura)}
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={unitType.nombre}
              />
            </Grid>
          </Grid>
          <Grid item>
            {unitType.soloLectura ? (
              <Alert severity="info" className={classes.alert}>
                {dictionary_generic.INFO_SOLO_LECTURA}
              </Alert>
            ) : (
              <Grid container spacing={4} justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={12} sm={3}>
                  <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                    {unitType.buttonTitle}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
