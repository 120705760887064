import { grupo, objectGrupo } from '../../../grupos/resources/interfaces/interfaceGrupo';
import {
  objectTipoDevice,
  tipoDevice
} from '../../../device/resources/interfaces/interfaceTipoDevice';
import { objectSection, section } from '../../../sections/resources/interfaces/interfaceSection';

export interface actuacionGrupalCompleta {
  grupo: grupo;
  tipoDevice: tipoDevice;
  section: section;
  valor: string;
}

export function objectActuacionGrupalCompleta(): actuacionGrupalCompleta {
  return {
    grupo: objectGrupo(),
    tipoDevice: objectTipoDevice(),
    section: objectSection(),
    valor: ''
  };
}
