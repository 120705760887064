import React, { useContext, useEffect, useReducer } from 'react';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { obtenerAlertasTodasSinFiltrar } from '../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { rutasServicios } from '../../../../resources/enums/enumRutasServicios';
import { seleccionarIcono } from '../../../alertas/functions/functions';
import { alertaPendientesResolver } from '../../../alertas/resources/interfaces/interfaceAlertasPendientesResolver';
import { ACTIONS_REDUCER_HOME } from '../resources/enums/enumsActionsHome';
import { interfaceUseReducerHome } from '../resources/interfaces/interfaceUseReducerHome';
import { actionsReducerHome } from '../resources/types/actionsReducerHome';
import { WeatherAlerts, objReducerHome } from '../resources/types/objReducerHome';
import { formatToDayStart } from '../../../../functions/functions';
import UserContext from '../../../../context/UserContext';
import { codigosTiempo } from '../resources/codigosTiempo';
import { namesLocalStorage } from '../../../../resources/enums/enumNamesLocalStorage';
import { comprobadorPermisosDispositivos } from '../../../../context/DatosContext';
import { WeatherColors } from '../../../../resources/enums/enumColores';
import { obtenerActivos } from 'services/servicioObtener';
import { useGetAirPollution } from 'shared/hooks/weather/useGetAirPollution';
import { useGetCurrentWeather } from 'shared/hooks/weather/useGetCurrentWeather';
import { useGetDailyForecast } from 'shared/hooks/weather/useGetDailyForecast';
import {
  weatherAlertsColors,
  weatherAlertIcons,
  DEFAULT_ALERT_LEVEL,
  DEFAULT_ALERT_ICON
} from '../resources/weatherAlerts';

const obtenerAlertasTodasSinResolver: obtenerAlertasTodasSinFiltrar = {
  type: action.OBTENER_ALERTAS_TODAS_SIN_RESOLVER,
  payload: { servicio: rutasServicios.ALERTAS }
};

const initial_State: objReducerHome = {
  clima: {
    current: {
      temp: 0,
      tempMAX: 0,
      tempMIN: 0,
      feelLike: 0,
      sunrise: 0,
      sunset: 0,
      humidity: 0,
      wind: 0,
      weather: { description: '', icon: <></> },
      uv: 0,
      pop: 0,
      clouds: 0
    },
    dailyForecast: []
  },
  airPollution: {
    aqi: 0,
    color: '#fff',
    value: ''
  },
  alerts: null,
  openAllAlertsBackdrop: true,
  openClimateBackdrop: true,
  allGisDevices: []
};

const reducer = (state: objReducerHome, action: actionsReducerHome) => {
  switch (action.type) {
    case ACTIONS_REDUCER_HOME.SET_CLIMA:
      return {
        ...state,
        clima: action.payload.clima,
        airPollution: action.payload.airPollution,
        alerts: action.payload.alerts,
        openClimateBackdrop: action.payload.openBackdrop
      };
    case ACTIONS_REDUCER_HOME.SET_AIR_POLLUTION:
      return { ...state, airPollution: action.payload };
    case ACTIONS_REDUCER_HOME.SET_ALERTAS_TODAS_SIN_RESOLVER:
      return {
        ...state,
        alertasTodasSinResolver: action.payload.listaAlertas,
        openAllAlertsBackdrop: action.payload.openBackdrop,
        allGisDevices: action.payload.allGisDevices
      };
    default:
      return state;
  }
};

export function useReducerHome(): interfaceUseReducerHome {
  const [state, dispatch] = useReducer(reducer, initial_State);

  const { controllerRequest } = useControlador();
  const { datosUsuarioContext } = useContext(UserContext);

  const urlParams =
    datosUsuarioContext.usuario.latitude && datosUsuarioContext.usuario.longitude
      ? `lat=${datosUsuarioContext.usuario.latitude}&lon=${datosUsuarioContext.usuario.longitude}`
      : `zip=${datosUsuarioContext.usuario.idCodigoPostal},es`;

  const { currentWeather } = useGetCurrentWeather(urlParams);

  const { coord } = currentWeather ?? { coord: { lat: null, lon: null } };

  const { airPollution } = useGetAirPollution(coord.lat, coord.lon);

  const { data: dailyForecast } = useGetDailyForecast(
    coord.lat,
    coord.lon,
    datosUsuarioContext.usuario.nombreUsuario
  );

  useEffect(() => {
    const cargarAlertasTodasSinResolver = async () => {
      let listaAlertas: any = [];
      let allGisDevices: any = [];
      const lista = await controllerRequest(obtenerAlertasTodasSinResolver);
      const devices = await obtenerActivos(rutasServicios.DISPOSITIVOS);
      const { alertas } = lista;
      allGisDevices = comprobadorPermisosDispositivos(
        devices.data.result,
        'dispositivosAlertas',
        datosUsuarioContext.dispositivos,
        0
      );
      alertas.forEach((alerta: any) => {
        const fila: alertaPendientesResolver = {
          iconoImg: seleccionarIcono(alerta.idTipoAlerta as number).icono,
          fecha: formatToDayStart(alerta.fechaRegistro.toString()),
          ...alerta
        };
        listaAlertas.push(fila);
      });

      listaAlertas = comprobadorPermisosDispositivos(
        listaAlertas,
        'dispositivosAlertas',
        datosUsuarioContext.dispositivos,
        0
      );

      dispatch({
        type: ACTIONS_REDUCER_HOME.SET_ALERTAS_TODAS_SIN_RESOLVER,
        payload: { listaAlertas, allGisDevices, openBackdrop: false }
      });
    };

    cargarAlertasTodasSinResolver();

    () =>
      dispatch({
        type: ACTIONS_REDUCER_HOME.SET_ALERTAS_TODAS_SIN_RESOLVER,
        payload: { listaAlertas: [], allGisDevices: [], openBackdrop: true }
      });
  }, []);

  React.useEffect(() => {
    if (airPollution && dailyForecast && currentWeather) {
      const weatherObject = currentWeather.weather[0].id
        ? codigosTiempo[currentWeather.weather[0].id]
        : codigosTiempo[1];
      const { list } = airPollution;

      const colorAqi: any = {
        0: { color: null, value: null },
        1: { color: WeatherColors.GOOD, value: 'Excelente' },
        2: { color: WeatherColors.FAIR, value: 'Bueno' },
        3: { color: WeatherColors.MODERATE, value: 'Moderado' },
        4: { color: WeatherColors.POOR, value: 'Pobre' },
        5: { color: WeatherColors.VERY_POOR, value: 'Muy pobre' }
      };

      const air = {
        aqi: list.length ? list[0].main.aqi : null,
        ...colorAqi[list.length ? list[0].main.aqi : 0]
      };

      const weatherAlerts: Array<WeatherAlerts> = localStorage.getItem(
        namesLocalStorage.weatherAlerts
      )
        ? JSON.parse(localStorage.getItem(namesLocalStorage.weatherAlerts) as string)
        : null;

      let alerts;

      if (weatherAlerts) {
        alerts = weatherAlerts.map((item) => {
          const eventString = item.event.toLowerCase().split(' ');

          const even = (element) => {
            const c = eventString.includes(element.toLowerCase());

            return c;
          };

          const filteredWeatherColors =
            weatherAlertsColors.filter((item) => item.level.find(even))[0] ?? DEFAULT_ALERT_LEVEL;
          const alertIcon =
            weatherAlertIcons.filter((item) => even(item.id))[0] ?? DEFAULT_ALERT_ICON;

          return {
            ...item,
            alertColor: filteredWeatherColors ?? DEFAULT_ALERT_LEVEL,
            alertIcon: alertIcon,
            start: item.start,
            end: item.end
          };
        });
      }

      dispatch({
        type: ACTIONS_REDUCER_HOME.SET_CLIMA,
        payload: {
          clima: {
            current: {
              temp: Math.round(currentWeather.main.temp),
              tempMAX: Math.round(currentWeather.main.temp_max),
              tempMIN: Math.round(currentWeather.main.temp_min),
              feelLike: Math.round(currentWeather.main.feels_like),
              sunrise: currentWeather.sys.sunrise,
              sunset: currentWeather.sys.sunset,
              humidity: currentWeather.main.humidity,
              wind: currentWeather.wind.speed,
              weather: weatherObject,
              uv: parseInt(localStorage.getItem(namesLocalStorage.uvIndex) as string),
              pop: parseInt(localStorage.getItem(namesLocalStorage.pop) as string),
              clouds: parseInt(localStorage.getItem(namesLocalStorage.clouds) as string)
            },
            dailyForecast
          },
          openBackdrop: false,
          airPollution: { ...air },
          alerts
        }
      });
    }
  }, [airPollution, currentWeather, dailyForecast]);

  return { state };
}
