import React from 'react';
import { Button, Grid, Link, TextField, Tooltip, Typography } from '@mui/material';
import useStyles from './styles';
import { InputAdornmentLock, InputAdornmentPerson } from './InputsAdornment';
import useReducerLogin from './hooks/useReducerLogin/useReducerLogin';
import { formulario } from './hooks/useReducerLogin/resources/enums/enumFormulario';
import { dictionary_login, enumLabel } from '../../resources/enums/plainText';

export function Login(): JSX.Element {
  const classes = useStyles();
  const { events } = useReducerLogin();
  const { handleSubmit, handleInputUserChange, handleInputPasswordChange } = events;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
        className={classes.gridMain}
      >
        <form
          id={formulario.LOGIN}
          onSubmit={handleSubmit}
          className={classes.formLogin}
          autoComplete="off"
        >
          <Grid item xs={12} className={classes.gridLogin}>
            <TextField
              fullWidth
              placeholder={enumLabel.USUARIO}
              type="name"
              id="name"
              onChange={handleInputUserChange}
              autoFocus
              required
              InputProps={{
                className: classes.inputLogin,
                startAdornment: <InputAdornmentPerson />
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridLogin}>
            <TextField
              fullWidth
              placeholder={enumLabel.CONTRASENA}
              type="password"
              id="password"
              color="primary"
              onChange={handleInputPasswordChange}
              required
              InputProps={{
                className: classes.inputLogin,
                startAdornment: <InputAdornmentLock />
              }}
            />
          </Grid>
          <Grid item sm={12} className={classes.gridLogin}>
            <Tooltip title={dictionary_login.TEXTO_ACCEDER} arrow placement="bottom">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                aria-label="acceder"
                className={classes.buttonLogin}
              >
                {dictionary_login.ACCEDER}
              </Button>
            </Tooltip>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} lg={8}>
              <Typography className={classes.textCenterBlack} variant="body2">
                <Tooltip title={dictionary_login.RESTABLECER_PASS} arrow placement="bottom">
                  <Link href="/restart-password" className={classes.textCenterBlack}>
                    {dictionary_login.TEXTO_CONTRASENA}
                  </Link>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography
                className={classes.textCenterBlack}
                variant="body2"
              >{`versión ${process.env.REACT_APP_VERSION}`}</Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
