/**
 * @readonly
 * @enum {string} TipoSection
 * @param Texto = text
 * @param Number = number
 * @param Button = button
 * @param Switch = switch
 * @param SwitchExtended = switchExtended
 * @param Dimmer = dimmer
 * @param Input = 'input'
 * @param Autocomplete = 'autocomplete'
 * @param ControlPtz = 'controlPtz'
 */

export enum TipoSection {
  Texto = 'text',
  Number = 'number',
  Button = 'button',
  Switch = 'switch',
  SwitchExtended = 'switchExtended',
  Dimmer = 'dimmer',
  Input = 'input',
  Autocomplete = 'autocomplete',
  ControlPtz = 'controlPtz'
}
