import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path = '';
let respuesta: any;

/**
 * Función para obtener los canales que pertenecen a un dispositivo concreto
 * @param { string } servicio nombre del servicio
 * @param { number } idDispositivo id del dispositivo cuyos canales queremos saber
 * @return { respuesta | exception} Si  la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerCanalesDispositivo(
  servicio: string,
  idDispositivo: number
): Promise<any> {
  path = servicio + '/todos/dispositivo/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idDispositivo: idDispositivo }),
        {
          timeout: 10000,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función para obtener los datos de los canales que pertenecen a un dispositivo concreto
 * @param { string } servicio nombre del servicio
 * @param { number } idDispositivo id del dispositivo cuyos canales queremos saber
 * @return { respuesta | exception} Si  la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerCanalesDatosDispositivo(
  servicio: string,
  idDispositivo: number
): Promise<any> {
  path = servicio + '/todos/dispositivo/valores';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idDispositivo: idDispositivo }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función para obtener los datos de los canales que pertenecen a un grupo de dispositivos concreto
 * @param { string } servicio nombre del servicio
 * @param { number } idGRupo id del dispositivo cuyos canales queremos saber
 * @return { respuesta | exception} Si  la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerNombresCanalesGrupo(servicio: string, idGrupo: number): Promise<any> {
  path = servicio;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idGrupo: idGrupo }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
