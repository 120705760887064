import axios from 'axios';
import * as React from 'react';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { formateoFechaGrafico } from '../../../graficas/functions/funciones';
import UserContext from '../../../../context/UserContext';
import { desencriptarRespuesta } from '../../../../functions/encryption';
import { serviceRoutes } from '../../../../resources/enums/enumRutasServicios';
import SnackBarContext from '../../../../context/SnackBarContext';
import { enumSeverity } from '../../../common/snackbar/resources/enums/enumSeverity';
import { documentManager } from '../../types/interfacesDocumentManager';
import { docValues } from '../../types/typesDocumentManager';
import { dictionary_entornos } from 'resources/enums/plainText';
import { getHeaders } from 'functions/functions';

const formateoFecha = formateoFechaGrafico.format('%Y-%m-%d %H:%M:%S');

enum actionType {
  SET_INITIAL_DATA = 'setInitialData',
  SET_DATA = 'setData',
  SET_MAINTENANCE_DATE = 'setMaintenanceDate',
  SET_DOCUMENTS = 'setDocuments',
  SET_USED_CAPACITY = 'setUsedCapacity',
  SET_LOADING = 'setLoading'
}

const initial_state: documentManager = {
  datosAdicionales: {
    fechaInstalacion: '',
    fechaExpiracionGarantia: '',
    datosFabricante: '',
    servicioTecnico: '',
    instalador: '',
    cups: ''
  },
  fechasMantenimiento: [],
  documentos: [],
  usedCapacity: 0,
  loading: false,
  exceededCapacity: false
};

function reducer(
  state: documentManager,
  action: { type: actionType; payload: any }
): documentManager {
  switch (action.type) {
    case actionType.SET_INITIAL_DATA:
      return { ...state, ...action.payload };
    case actionType.SET_DATA:
      return { ...state, datosAdicionales: { ...state.datosAdicionales, ...action.payload } };
    case actionType.SET_MAINTENANCE_DATE:
      return { ...state, fechasMantenimiento: [...action.payload] };
    case actionType.SET_DOCUMENTS:
      return { ...state, documentos: [...action.payload] };
    case actionType.SET_USED_CAPACITY:
      return {
        ...state,
        usedCapacity: action.payload.capacity,
        exceededCapacity: action.payload.exceededCapacity
      };
    case actionType.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

export function useDocumentManager(idDispositivo: number) {
  const {
    datosUsuarioContext: { cliente }
  } = React.useContext(UserContext);
  const capacidadCliente = cliente.capacidad;
  const { setSnackBar } = React.useContext(SnackBarContext);
  const [state, dispatch] = React.useReducer(reducer, initial_state);
  const { controllerRequest } = useControlador();
  const capacidadUsada = state.usedCapacity ?? 0;

  const capacidadUsadaPorcentaje =
    parseFloat(((capacidadUsada * 100) / capacidadCliente).toFixed(2)) || 0;

  function setFechaInstalacion(fecha: any) {
    dispatch({
      type: actionType.SET_DATA,
      payload: { fechaInstalacion: fecha.toDate() }
    });
  }
  function setFechaExpiracion(fecha: any) {
    dispatch({
      type: actionType.SET_DATA,
      payload: { fechaExpiracionGarantia: fecha.toDate() }
    });
  }
  function setFechaMantenimiento(value: any) {
    const dateArray = value.map((element: any) => element.toDate());
    dispatch({
      type: actionType.SET_MAINTENANCE_DATE,
      payload: dateArray
    });
  }
  const handleChangeTextField =
    (prop: keyof docValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: actionType.SET_DATA, payload: { [prop]: event.target.value } });
    };

  function setDroppedFiles(documents: File[]) {
    dispatch({ type: actionType.SET_DOCUMENTS, payload: documents });
  }

  function setUsedCapacity(capacity: number) {
    dispatch({
      type: actionType.SET_USED_CAPACITY,
      payload: { capacity: capacity, exceededCapacity: capacity > capacidadCliente }
    });
  }

  function updateData(event: any) {
    event.preventDefault();

    dispatch({ type: actionType.SET_LOADING, payload: true });

    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: serviceRoutes.SAVE_DOCUMENT_MANAGER,
        object: {
          datosAdicionales: { ...state.datosAdicionales, idDispositivo: idDispositivo },
          fechasMantenimiento: state.fechasMantenimiento
        }
      }
    })
      .then(() =>
        setSnackBar({
          open: true,
          severity: enumSeverity.SUCCESS,
          text: `Se han actualizado los datos correctamente`
        })
      )
      .catch((error) =>
        setSnackBar({
          open: true,
          severity: enumSeverity.ERROR,
          text: `No se han actualizado los datos, ${error}`
        })
      )
      .finally(() => dispatch({ type: actionType.SET_LOADING, payload: false }));
  }

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: serviceRoutes.GET_DOCUMENT_MANAGER,
        object: {
          idDispositivo: idDispositivo
        }
      }
    }).then((res: documentManager) => {
      //Si al obtenernos alguna información viene a null, se deben inicializar.
      if (res.documentos) {
        res.documentos.forEach(
          (item) => (item.uploadDate = formateoFecha(new Date(item.uploadDate as string)))
        );
      } else {
        res.documentos = initial_state.documentos;
      }
      if (res.datosAdicionales) {
        res.datosAdicionales.fechaInstalacion = new Date(res.datosAdicionales.fechaInstalacion);
        res.datosAdicionales.fechaExpiracionGarantia = new Date(
          res.datosAdicionales.fechaExpiracionGarantia
        );
      } else {
        res.datosAdicionales = initial_state.datosAdicionales;
      }
      if (res.fechasMantenimiento) {
        res.fechasMantenimiento = res.fechasMantenimiento.map((item) =>
          formateoFecha(new Date(item))
        );
      } else {
        res.fechasMantenimiento = initial_state.fechasMantenimiento;
      }

      dispatch({ type: actionType.SET_INITIAL_DATA, payload: res });
    });
  }, []);

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL_LOCAL + serviceRoutes.CONSULT_TOTAL_SIZE, {
        headers: getHeaders(dictionary_entornos.BACK)
      })
      .then((res) => {
        const response = desencriptarRespuesta(res);
        setUsedCapacity(parseFloat(response.data.result.toFixed(2)));
      });
  }, [state.documentos]);

  return {
    state,
    setFechaInstalacion,
    setFechaExpiracion,
    setFechaMantenimiento,
    updateData,
    handleChangeTextField,
    capacidadCliente,
    capacidadUsadaPorcentaje,
    capacidadUsada,
    setDroppedFiles
  };
}
