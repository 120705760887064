import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { RangosString } from '../../resources/enums/enumsRangosString';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { HandleError } from '../../../error/resources/types/typeHandleError';
import { getId, getLabels } from '../../functions/funciones';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { Group } from '../../resources/enums/enumGroup';
import { Operation } from '../../resources/enums/enumOperation';
import { Rango } from '../../resources/enums/enumRango';
import { ServiciosGraficas } from '../../resources/enums/enumServiciosGraficas';
import { NoValues, SolarEnergyChart } from '../../resources/interfaces/interfaceCharts';
import { DatosGrafica } from '../../resources/interfaces/interfaceDatosGraficas';
import { servicioObtenerDatosGraficas } from '../../services/obtenerDatosGraficas';

const file = 'createAreaChart';

const agrupacion: any = {
  [Rango.LAST_24H]: Group.HOUR,
  [Rango.WEEKLY]: Group.DAY,
  [Rango.MONTHLY]: Group.DAY,
  [Rango.ANNUALLY]: Group.MONTH,
  [Rango.TODAY]: Group.HOUR,
  [Rango.LAST_MONTH]: Group.DAY,
  [Rango.THIS_MONTH]: Group.DAY,
  [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: Group.HOUR,
  [Rango.LAST_YEAR]: Group.MONTH,
  [Rango.TODAY_IN_THE_PREVIOUS_YEAR]: Group.HOUR,
  [Rango.LAST_WEEK]: Group.DAY,
  [Rango.THIS_WEEK]: Group.DAY,
  [Rango.THIS_YEAR]: Group.MONTH,
  [Rango.YESTERDAY]: Group.HOUR
};

export const createSolarEnergyChart = async (
  moduloSeleccionado: number,
  medida: TiposMedida | TiposMedida[],
  rango: Rango,
  operation: Operation,
  tipoGrafica: GraphTypes,
  dispositivos: Array<dispositivo>,
  handleError: HandleError,
  unidad?: dictionary_unidades,
  inicio?: string,
  fin?: string
): Promise<SolarEnergyChart | NoValues> => {
  const getValor = (d: DatosGrafica) => d.valor;
  const consulta: any = {
    range: rango,
    startDate: '',
    endDate: '',
    dispositivos: getId(dispositivos),
    group: agrupacion[rango],
    operation: operation,
    measure: medida,
    servicio: ServiciosGraficas.VARIAS_MEDIDAS
  };

  if (rango === Rango.RANGE) {
    consulta.startDate = inicio as string;
    consulta.endDate = fin as string;
  }

  //Obtención de los datos del rango actual.
  const respuesta: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  if (respuesta.isAxiosError) {
    handleError(`[${file}][${servicioObtenerDatosGraficas.name}]`, respuesta.message);
    return { noValues: true };
  }
  //Desestructuración de los datos actuales y comparativos.
  const { result } = respuesta.data;

  //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
  //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
  const { labels, condition = 'fecha', formateoFecha, getLastValue } = getLabels(rango);

  let unid;

  const datos: Array<{ data: Array<any>; name: string; color?: string }> = [];
  const hideNames: Array<string> = [];

  function createGraphData(result: Array<any>) {
    //Nos obtenemos el último valor para tratar correctamente los valores a 0 o nulos.
    const ultimoValor = getLastValue(new Date());

    dispositivos.forEach((dispositivo) => {
      const datosFiltradosPorDispositivo = result.filter((item) => item.id === dispositivo.id);

      datosFiltradosPorDispositivo.forEach(
        ({ nombre, datos: datosResultado }: { nombre: string; datos: Array<DatosGrafica> }) => {
          let data;

          //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
          //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
          if (datosResultado.length !== labels.length) {
            data = labels.map((element, index) => {
              const res = datosResultado.find((item: any) => {
                unid = item.unidad;
                return formateoFecha(new Date(item[condition])).trim() === element;
              });
              return res ? res.valor : ultimoValor >= index ? 0 : null;
            });
          } else {
            data = datosResultado.map(getValor);
          }

          datos.push({ data, name: nombre });
        }
      );
      datos.push({
        data: datos[1].data.map((item, index) => {
          if (item === null && datos[0].data[index] === null) {
            return null;
          } else {
            const valor = parseInt((item - datos[0].data[index]).toFixed(2));

            return valor > 0 ? valor : 0;
          }
        }),
        name: `Excedencia ${dispositivo.nombre}`
      });
      hideNames.push(`Excedencia ${dispositivo.nombre}`);
    });
  }
  createGraphData(result);

  //Propiedades genéricas del gráfico, como el título, título del eje Y y el valor max para que el gráfico tenga una altura correcta.

  const title = `Producción y consumo de energía ${RangosString[rango].toLowerCase()}`;
  const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;

  return {
    datos,
    labels,
    title: title,
    titleYAxis: `Producción y consumo de energía ${unid ?? unidad}`,
    max,
    hideNames,
    unid
  };
};
