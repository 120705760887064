import * as React from 'react';
import { Grid, Box, Typography, TextField, Grow, Divider, Button } from '@mui/material';
import { useStyles } from './styles';
import { useDistributionWaterCard } from './useConfigurableDistributionWaterCard';
import { useDrag } from 'react-dnd';
import { DonutChart } from '../../../graficasApexCharts/DonutChart';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { useTheme } from '@mui/material/styles';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { DevicesTableDialog } from '../DevicesTableDialog';
import { dictionary_configurator } from '../../../../resources/enums/plainText';
import { subDays, format } from 'date-fns';

export function ConfigurableDistributionWaterCard(props: any): JSX.Element {
  const {
    tabState: [{ selectedModule }]
  } = useConfiguratorContext();

  const cardProps = props.tarjeta
    ? {
        tarjeta: props.tarjeta,
        dispositivos: props.dispositivos,
        semaforo: props.semaforo
      }
    : null;

  const { handleAutoCompleteInputDevices, handleAutoCompleteOutputDevices, obj, handleCardTitle } =
    useDistributionWaterCard(selectedModule, cardProps);

  const {
    dispositivosDisponiblesEntrada,
    dispositivosDisponiblesSalida,
    dataObject,
    dispositivosElegidosEntrada,
    dispositivosElegidosSalida
  } = obj;
  const { tarjeta } = dataObject;
  const classes = useStyles({ color: obj.cardReady ? '#94c390' : '#e0e0e0' });
  const [openInputDeviceDialog, setOpenInputDeviceDialog] = React.useState(false);
  const handleCloseInputDeviceDialog = () => {
    setOpenInputDeviceDialog(false);
  };
  const [openOutputDeviceDialog, setOpenOutputDeviceDialog] = React.useState(false);
  const handleCloseOutputDeviceDialog = () => {
    setOpenOutputDeviceDialog(false);
  };
  const [, drag] = useDrag(
    () => ({
      type: 'tarjeta',
      item: {
        ...dataObject,
        tarjeta: { ...dataObject.tarjeta, idTipoTarjeta: cardType.WaterCard }
      },
      canDrag: obj.cardReady,

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        canDrag: monitor.canDrag()
      })
    }),
    [obj]
  );
  const graphData: {
    series: any[];
    labels: any[];
    unidad: Array<string>;
    diferencia: number;
  } = {
    series: [100, 100],
    labels: ['Hoy', 'Ayer'],
    unidad: ['Unidad'],
    diferencia: 0
  };
  const theme = useTheme();
  const date = format(subDays(new Date(), 1), 'dd-MM-yyyy');

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      justifyContent={'space-evenly'}
      marginTop={'15px'}
      flexDirection={'row'}
    >
      <Grid item xs={7} container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            onChange={handleCardTitle}
            id="titulo"
            label={dictionary_configurator.TITLE}
            placeholder="Máx, 20 caracteres."
            className={classes.elements}
            fullWidth
            inputProps={{ maxLength: '20' }}
            error={Boolean(tarjeta.titulo.length === 0)}
            value={dataObject.tarjeta.titulo}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            sx={{ height: '100%', backgroundColor: 'lightgray', fontSize: '0.4rem' }}
            onClick={() => setOpenInputDeviceDialog(true)}
          >
            {`${dictionary_configurator.SELECT_INPUT_DEVICES} (${dispositivosElegidosEntrada.length})`}
          </Button>
          <DevicesTableDialog
            devices={dispositivosDisponiblesEntrada}
            openDialog={openInputDeviceDialog}
            handleCloseDialog={handleCloseInputDeviceDialog}
            onRowSelected={handleAutoCompleteInputDevices}
            selectedItems={dispositivosElegidosEntrada ?? []}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            sx={{ height: '100%', backgroundColor: 'lightgray', fontSize: '0.4rem' }}
            onClick={() => setOpenOutputDeviceDialog(true)}
          >
            {`${dictionary_configurator.SELECT_OUTPUT_DEVICES} (${dispositivosElegidosSalida.length})`}
          </Button>
          <DevicesTableDialog
            devices={dispositivosDisponiblesSalida}
            openDialog={openOutputDeviceDialog}
            handleCloseDialog={handleCloseOutputDeviceDialog}
            onRowSelected={handleAutoCompleteOutputDevices}
            selectedItems={dispositivosElegidosSalida ?? []}
          />
        </Grid>
      </Grid>
      <Grid item container xs={5} justifyContent="flex-end" component={'div'} ref={drag}>
        <Grid
          item
          xs={12}
          style={{
            cursor: obj.cardReady && tarjeta.titulo.length > 0 ? 'move' : 'not-allowed',
            maxWidth: '95%'
          }}
        >
          <Grow in timeout={650} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>
              <CustomTooltip title={tarjeta.titulo} placement={'bottom'}>
                <Box
                  style={{
                    boxShadow:
                      obj.cardReady && tarjeta.titulo.length > 0
                        ? '0px 0px 1px 2px #94c390'
                        : `0px 0px 1px 2px #e0e0e0`,
                    borderRadius: '15px',
                    width: '100%',
                    height: '166px',
                    display: 'flex',
                    position: 'relative'
                  }}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  <div style={{ width: '100%' }}>
                    <DonutChart {...graphData} />
                  </div>

                  <Divider
                    orientation="vertical"
                    style={{
                      borderRightWidth: 'medium',
                      height: '50%'
                    }}
                  />
                  <div style={{ width: '100%' }}>
                    <Typography className={classes.comparativeValue}>
                      <span
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold'
                        }}
                      >
                        {dictionary_configurator.DIFFERENCE}
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span
                        className={classes.measureDate}
                      >{`${dictionary_configurator.MEASURE_DATE} ${date}`}</span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span className={classes.measure}>{graphData.unidad}</span>
                    </Typography>
                  </div>
                </Box>
              </CustomTooltip>
            </div>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
}
