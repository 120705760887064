import React from 'react';
import {
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { modulo } from '../../../resources/interfaces/interfaceModulo';
import { green, grey } from '@mui/material/colors';
import { Modulos } from '../../../resources/enums/enumModulo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '300px'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    float: 'left',
    width: '45%'
  }
}));

export function EstablecerModulosYComponentes({
  state,
  events
}: {
  state: any;
  events: any;
}): JSX.Element {
  const classes = useStyles();
  const { permisosUsuario } = state;
  const activeColor = green[500];
  const desactiveColor = grey[500];

  function setDisabledCheckBox(id: number) {
    return permisosUsuario.usuario.idPerfil !== 1
      ? id === Modulos['GESTIÓN DE USUARIOS'] ||
        id === Modulos['CONFIGURACIÓN GENERAL'] ||
        id === Modulos['REGLAS']
        ? true
        : false
      : false;
  }

  function setDisabledExpandButton(id: number, acceso: boolean) {
    return permisosUsuario.usuario.idPerfil !== 1
      ? id === Modulos['GESTIÓN DE USUARIOS'] ||
        id === Modulos['CONFIGURACIÓN GENERAL'] ||
        id === Modulos['REGLAS']
        ? true
        : !acceso
      : !acceso;
  }

  function setColorAction(index: number) {
    return permisosUsuario.permisos[index].id;
  }

  return (
    <List className={classes.root}>
      {permisosUsuario.modulos.map((value: modulo) => {
        const labelId = `checkbox-list-label-${value}`;
        const [open, setOpen] = useState(false);

        return (
          <>
            <ListItem key={value.id} role={undefined}>
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  checked={
                    permisosUsuario.usuario.idPerfil !== 1
                      ? value.id === Modulos['GESTIÓN DE USUARIOS'] ||
                        value.id === Modulos['CONFIGURACIÓN GENERAL'] ||
                        value.id === Modulos['REGLAS']
                        ? false
                        : value.acceso
                      : value.acceso
                  }
                  name={'modulo'}
                  id={value.id.toString()}
                  onChange={(evt, checked) => events.onChangeElementsPermisos(evt, checked, value)}
                  disabled={setDisabledCheckBox(value.id)}
                />
              </ListItemIcon>
              {value.id !== Modulos['GESTIÓN DE USUARIOS'] &&
              value.id !== Modulos['CONFIGURACIÓN GENERAL'] &&
              value.id !== Modulos['REGLAS'] ? (
                <ListItemIcon>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => setOpen(!open)}
                    disabled={setDisabledExpandButton(value.id, value.acceso as boolean)}
                  >
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemIcon>
              ) : (
                <></>
              )}

              <ListItemText
                id={labelId}
                primary={value.nombre}
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {permisosUsuario.componentes.map((componente: any, index: number) => {
                  if (componente.idModulo == value.id) {
                    return (
                      <ListItem className={classes.nested} key={componente.id}>
                        <ListItemText id={componente.id.toString()} primary={componente.nombre} />
                        <Tooltip title={'Control total'}>
                          <IconButton
                            style={{ marginRight: '12px' }}
                            id={index.toString()}
                            name={'total'}
                            onClick={events.onChangeAcciones}
                            data-idmodulo={componente.idModulo}
                          >
                            <EditIcon
                              fontSize="large"
                              style={{
                                color: setColorAction(index) === 1 ? activeColor : desactiveColor
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Sólo lectura'}>
                          <IconButton
                            name={'lectura'}
                            id={index.toString()}
                            onClick={events.onChangeAcciones}
                          >
                            <LocalLibraryIcon
                              fontSize="large"
                              style={{
                                color: setColorAction(index) !== 0 ? activeColor : desactiveColor
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    );
                  }
                })}
              </List>
            </Collapse>
          </>
        );
      })}
    </List>
  );
}
