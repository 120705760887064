import React, { useContext, useReducer, FormEvent, useMemo } from 'react';
import { dispositivo, objectDispositivo } from '../resources/interfaces/interfaceDispositivo';
import { dialogoDispositivo } from '../resources/interfaces/interfaceDialogoDispositivo';
import { dispositivoCRU } from '../resources/interfaces/interfaceDispositivoCRU';
import { datosAdicionales } from '../resources/interfaces/interfaceDatosAdicionales';
import ControlContext from '../../../context/control/ControlContext';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { calcularFrecuenciaObtener } from '../../../functions/functions';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { DispositivoManager } from '../DispositivoManager';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { infoDevice } from '../resources/interfaces/interfaceInfoDevice';
import UserContext from '../../../context/UserContext';

export const ACTIONS = {
  SET_DISPOSITIVO: 'setDispositivo',
  SET_DATOS_DIALOGO: 'setDatosDialogo',
  SET_DISPOSITIVO_MODIFICAR: 'setDispositivoModificar',
  SET_DISPOSITIVO_READY: 'setDispositivoReady',
  SET_CHECKED: 'setCheck',
  SET_DISABLED: 'setDisabled',
  SET_MOSTRAR_CREAR: 'setMostrarCrear',
  SET_MOSTRAR_MODIFICAR: 'setMostrarModificar',
  SET_OPEN_AVISO: 'setOpenAviso',
  SET_DISPOSITIVOS_TABLA: 'setDispositivoTabla',
  SET_KEY_MANAGER: 'setKeyManager'
};
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_DISPOSITIVO:
      return {
        ...state,
        dispositivoReady: { ...state.dispositivoReady, dispositivo: action.payload }
      };
    case ACTIONS.SET_DATOS_DIALOGO:
      return { ...state, datosDialogo: action.payload, desplegablesCargados: true };
    case ACTIONS.SET_DISPOSITIVO_MODIFICAR:
      return {
        ...state,
        datosDialogo: action.payload.datosDialogo,
        infoDevice: action.payload.infoDevice,
        dispositivoModificar: action.payload.dispositivo
      };
    case ACTIONS.SET_DISPOSITIVO_READY:
      return {
        ...state,
        ...state.dispositivoReady.dispositivo,
        dispositivoReady: action.payload
      };
    case ACTIONS.SET_CHECKED:
      return { ...state, checked: action.payload };
    case ACTIONS.SET_DISABLED:
      return { ...state, disabled: action.payload };
    case ACTIONS.SET_MOSTRAR_CREAR:
      return { ...state, mostrarCrear: true };
    case ACTIONS.SET_MOSTRAR_MODIFICAR:
      return { ...state, mostrarModificar: true };
    case ACTIONS.SET_OPEN_AVISO:
      return { ...state, openAviso: action.payload };
    case ACTIONS.SET_DISPOSITIVOS_TABLA:
      return { ...state, dispositivosTabla: action.payload };
    case ACTIONS.SET_KEY_MANAGER:
      return { ...state, keyManager: action.payload };
  }
};

export function useReducerDispositivos(dispositivoSeleccionado?: dispositivo | any) {
  const { setSnackBar } = useContext(SnackBarContext);
  const {
    controllerRequest,
    completadoCorrectamente,
    propiedadesSnackBar,
    registroGenerado
  } = useControlador();
  const {
    moduloSeleccionado,
    setControl,
    control,
    tieneAcceso,
    previousComponent
  } = React.useContext(ControlContext);
  const { datosUsuarioContext } = useContext(UserContext);
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const [state, dispatch] = useReducer(reducer, {
    datosDialogo: {
      tiposDispositivo: [],
      gruposDispositivos: [],
      etiquetasDispositivo: [],
      device: []
    } as dialogoDispositivo,
    desplegablesCargados: false,
    dispositivoModificar: undefined,
    //objeto listo para mandar a base de datos
    dispositivoReady: {
      dispositivo:
        dispositivoSeleccionado !== undefined
          ? objectDispositivo(dispositivoSeleccionado)
          : objectDispositivo(undefined, moduloSeleccionado),
      grupos: undefined, // lo iniciamos así, ya que en caso de que se envíe un array vacío significa
      etiquetas: undefined // que se tienen que eliminar todos los grupos/etiquetas, y si es undefined no hará nada
    },
    infoDevice: undefined,
    checked:
      dispositivoSeleccionado !== undefined && dispositivoSeleccionado.activo === 0 ? false : true,
    disabled: dispositivoSeleccionado !== undefined ? false : true,
    mostrarDialogo: false,
    mostrarCrear: false,
    mostrarModificar: false,
    openAviso: false,
    dispositivosTabla: [] as dispositivo[],
    keyManager: undefined
  });

  const { dispositivoReady, datosDialogo, dispositivosTabla, keyManager } = state;
  const { dispositivo } = dispositivoReady;

  const { handleMultipleAutocompleteChange, handleAutocompleteChange } = useEventChange(
    dispositivoReady,
    setDispositivoReady
  );
  const {
    handleInputChange,
    handleInputInvalid,
    handleAutocompleteChange: handleAutocompleteTiposDispositivo
  } = useEventChange(dispositivo, setDispositivo);

  /**
   * Función que cambia el valor del dispositivo
   * @param { dispositivo } dispositivo dispositivo
   */
  function setDispositivo(dispositivo: dispositivo) {
    dispatch({ type: ACTIONS.SET_DISPOSITIVO, payload: dispositivo });
  }

  /**
   * Función que modifica la acción de abrir el alert que le pregunta al usuario
   * si realmente quiere modificar el dispositivo o no
   *
   * @param { boolean } aviso indica si el alert debe abrirse o no
   */
  function setOpenAviso(aviso: boolean) {
    dispatch({ type: ACTIONS.SET_OPEN_AVISO, payload: aviso });
  }

  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }

  /**
   * Evento que modifica el checked
   * @param { React.ChangeEvent<HTMLInputElement> }}event event Evento que se ejecuta para activar o desactivar
   */
  function onchangeCheked(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    event.target.checked ? (dispositivo.activo = 1) : (dispositivo.activo = 0);
  }

  /**
   * Función que compruebe si el dispositivo está activo o no para mostrar el check
   * activado o desactivado
   */
  function comprobarChecked() {
    setChecked(true);
  }

  /**
   * Función que modifica los valores del objeto dispositivoReady
   * @param { datosAdicionales } datos contiene los datos que van a formar el objeto dispositivoReady para enviar a bd
   */
  function setDispositivoReady(datos: datosAdicionales) {
    dispatch({ type: ACTIONS.SET_DISPOSITIVO_READY, payload: datos });
  }

  /**
   * Función que habilita o deshabilita campos del formulario
   * @param { boolean } habilitar  indica si el campo está habilitado o deshabilitado
   */
  function setDisabled(habilitar: boolean) {
    dispatch({ type: ACTIONS.SET_DISABLED, payload: habilitar });
  }

  /**
   * Función que modifica el valor del check de activar y desactivar
   * @param { boolean } checkear indica si está checkeqado o no
   */
  function setChecked(checkear: boolean) {
    dispatch({ type: ACTIONS.SET_CHECKED, payload: checkear });
  }

  /**
   * Función que cambia el valor de los desplegables
   * @param { dialogoDispositivo } datos datos que se cargan en los desplegables
   */
  function setDatosDialogo(datos: dialogoDispositivo) {
    dispatch({ type: ACTIONS.SET_DATOS_DIALOGO, payload: datos });
  }

  /**
   * Función que almacena el dispositivo que vamos a modificar
   * @param { dispositivoCRU } dispositivo dispositivo que queremos modificar
   */
  function setDispositivoModificar(
    dispositivo: dispositivoCRU,
    infoDevice: infoDevice | undefined,
    datos: dialogoDispositivo
  ) {
    dispatch({
      type: ACTIONS.SET_DISPOSITIVO_MODIFICAR,
      payload: { datosDialogo: datos, infoDevice: infoDevice, dispositivo: dispositivo }
    });
  }

  function setDispositivosTabla(dispositivos: dispositivo[]) {
    dispatch({ type: ACTIONS.SET_DISPOSITIVOS_TABLA, payload: dispositivos });
  }

  /**
   * Evento que captura el device seleccionado del desplegable
   * @param { React.ChangeEvent<any> } event evento del desplegable
   * @param { any } value el device elegido
   */
  function onhandleAutocompleteDevices(event: React.ChangeEvent<any>, value: any) {
    if (value !== null) {
      setDisabled(false);
      dispositivoReady.dispositivo.idDevice = value.id;
    } else {
      dispositivoReady.dispositivo.idDevice = undefined;
      setDisabled(true);
    }
  }

  /**
   * Obtiene el device asociado al dispositivo que se va a modificar.
   * @returns device elegido por el usuario
   */

  function getDevices() {
    return datosDialogo.device.filter(
      (d: any) => d.id === dispositivoReady.dispositivo.idDevice
    )[0];
  }

  function setKeyManager(key: enumComponentes) {
    dispatch({ type: ACTIONS.SET_KEY_MANAGER, payload: key });
  }

  /**
   * Función que se ejecuta cuando el usuario acepta el alert de modificar el dispositivo.
   * Llama al useCud y le pasa lo necesario para modificar el registro
   */
  async function confirmarAlertAviso() {
    await controllerRequest({
      type: action.MODIFICAR_REGISTRO,
      payload: {
        servicio: rutasServicios.DISPOSITIVOS,
        objeto: dispositivoReady,
        id: dispositivo.id
      }
    });

    respuestaSubmit();
  }

  /**
   * Función que crea el nuevo dispositivo con todos los datos recogidos en el formulario
   * @param {FormEvent<HTMLFormElement>} event este evento se lanza cuando pulsamos en el botón crear
   */
  async function handleSubmitCrear(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setOpenSmartTeliaBackdrop(true);
    await controllerRequest({
      type: action.CREAR_UN_REGISTRO,
      payload: {
        servicio: rutasServicios.DISPOSITIVOS,
        objeto: dispositivoReady,
        idUsuario: datosUsuarioContext.usuario.id
      }
    });

    if (registroGenerado.current.addPermitido) {
      const { idModulo, id: idDispositivo } = registroGenerado.current;

      datosUsuarioContext.dispositivos.push({ idModulo, idDispositivo });
    }

    respuestaSubmit();
    setOpenSmartTeliaBackdrop(false);
  }

  function handleSubmitEditar(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setOpenAviso(true);
  }

  /**
   * Función que se ejecuta tras intentar guardar o editar un dispositivo y se procesa la respuesta
   * correspondiente.
   */
  function respuestaSubmit() {
    //Si se hace correctamente, se limpia el formulario y se vuelve a la tabla dispositivos.
    if (completadoCorrectamente.current) {
      setDisabled(true);
      setControl(
        previousComponent.current.tmp ? (
          previousComponent.current.component
        ) : (
          <DispositivoManager key={enumComponentes.ADMINISTRACION} />
        )
      );
    }
    mostrarSnackBar(propiedadesSnackBar);
  }

  const cargarDatos = async (dispositivo?: any) => {
    const datos: dialogoDispositivo = {
      tiposDispositivo: [],
      gruposDispositivos: [],
      etiquetasDispositivo: [],
      device: []
    };
    datos.tiposDispositivo = await controllerRequest({
      type: action.OBTENER_DATOS_SIN_MODULO,
      payload: { servicio: rutasServicios.TIPOS_DISPOSITIVOS }
    });
    datos.gruposDispositivos = await controllerRequest({
      type: action.OBTENER_DATOS_SIN_MODULO,
      payload: { servicio: rutasServicios.GRUPOS }
    });
    datos.etiquetasDispositivo = await controllerRequest({
      type: action.OBTENER_DATOS_SIN_MODULO,
      payload: { servicio: rutasServicios.ETIQUETAS }
    });

    // Pasamos el IdDispositivo para obtener el devices asociado al dispositivo y los devices disponibles.
    datos.device = await controllerRequest({
      type: action.OBTENER_TODOS_LOS_DEVICES_DISPONIBLES,
      payload: {
        servicio: rutasServicios.DEVICES,
        idDispositivo: dispositivo != undefined ? dispositivo.id : null
      }
    });

    dispositivo !== undefined
      ? setDispositivoModificar(
          await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: { servicio: rutasServicios.DISPOSITIVOS, id: dispositivo.id }
          }),
          dispositivo.idDevice
            ? await controllerRequest({
                type: action.OBTENER_INFO_DEVICE_PLUGIN,
                payload: { servicio: rutasServicios.DEVICES, idDevice: dispositivo.idDevice }
              })
            : undefined,
          datos
        )
      : setDatosDialogo(datos);
  };
  const formarComponente = useMemo(async () => {
    const cargarDatosComponente: any = {
      [enumComponentes.ADMINISTRACION]: async () => {
        const dispositivos: [] = await controllerRequest({
          type: action.OBTENER_DATOS_BY_MODULO,
          payload: { servicio: rutasServicios.DISPOSITIVOS }
        });
        setDispositivosTabla(dispositivos);
      },
      [enumComponentes.CREAR_DISPOSITIVO]: async () => await cargarDatos(),
      [enumComponentes.MODIFICAR_DISPOSITIVO]: async () =>
        await cargarDatos(dispositivoSeleccionado)
    };

    await cargarDatosComponente[control.key as enumComponentes]();
    setKeyManager(control.key as enumComponentes);
  }, []);

  return {
    state,
    setOpenSmartTeliaBackdrop,
    handleAutocompleteChange,
    handleAutocompleteTiposDispositivo,
    handleMultipleAutocompleteChange,
    handleInputChange,
    comprobarChecked,
    onchangeCheked,
    handleSubmitCrear,
    handleSubmitEditar,
    onhandleAutocompleteDevices,
    confirmarAlertAviso,
    setOpenAviso,
    getDevices,
    setDispositivosTabla,
    dispositivosTabla,
    keyManager,
    calcularFrecuenciaObtener,
    handleInputInvalid,
    tieneAcceso
  };
}
