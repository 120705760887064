import React, { useState, useRef, useEffect, useContext } from 'react';

import { getInfo } from '../marcador/getInfo';
import { dispositivo } from '../../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { TarjetaInterface } from '../../resources/interfaces/interfaceTarjeta';
import UserContext from '../../../../../context/UserContext';
import { useHasError } from '../../../../error/hooks/useHasError';
import { defaultGoogleMapStyle, googleMapStyleModule } from './googleMapStyles';
import { Filter } from '../filtro/Filtro';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Modulos } from '../../../../../resources/enums/enumModulo';
import { useTheme } from '@mui/material/styles';
import { iconosMapa } from '../../resources/iconosMapa';
import { tipoAlerta } from '../../../../alertas/resources/enums/enumTipoAlerta';
import { Color } from '../../../../../resources/enums/enumColores';
import GoogleMapReact from 'google-map-react';

const file = 'Mapa';

const alertColor: any = {
  [tipoAlerta.WARNING]: {
    color: Color.WARNING,
    icon: `<svg version="1.1" fill="${Color.WARNING}" width="25px" height="25px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
 <g>
   <path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
     C512,114.497,397.492,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
     c119.393,0,216,96.615,216,216C472,375.393,375.384,472,256,472z"/>
 </g>
</g>
<g>
 <g>
   <path d="M256,214.33c-11.046,0-20,8.954-20,20v128.793c0,11.046,8.954,20,20,20s20-8.955,20-20.001V234.33
     C276,223.284,267.046,214.33,256,214.33z"/>
 </g>
</g>
<g>
 <g>
   <circle cx="256" cy="162.84" r="27"/>
 </g>
</g>`
  },
  [tipoAlerta.ERROR]: {
    color: Color.ERROR,
    icon: `<svg version="1.1" fill="${Color.ERROR}" id="Capa_1" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 511.995 511.995" style="enable-background:new 0 0 511.995 511.995;" xml:space="preserve">
<g>
 <g>
   <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005
     s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874
     C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0
     c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0
     C493.435,187.359,493.435,324.651,409.08,409.006z"/>
 </g>
</g>
<g>
 <g>
   <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046
     c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02
     c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046
     c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111
     c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z"/>
 </g>
</g>`
  }
};

/**
 * Mapa de google con funcionalidades extra, como dibujar polígono para seleccionar un Área o mostrar una tarjeta haciendo click en un marcador.
 * @param {dispositivosGIS} props Los dispositivos que usaremos en el GIS
 */
export function Mapa(props: {
  markers: dispositivo[];
  datosTarjeta: TarjetaInterface;
  setDatosTarjeta: React.Dispatch<React.SetStateAction<TarjetaInterface>>;
  isHome?: boolean;
  divStyle: any;
  moduloSeleccionado: Modulos;
  codTipoDispositivo: Array<string>;
}): JSX.Element {
  const theme = useTheme();
  const { handleError } = useHasError();
  const { datosUsuarioContext } = useContext(UserContext);
  //Hay que tiparlos a number para que el componente de GoogleMap no de error en el tipado, ya que por interface latitud y longitud pueden ser indefinidos.
  const [center] = useState({
    lat: datosUsuarioContext.usuario.latitude ?? (datosUsuarioContext.cliente.latitud as number),
    lng: datosUsuarioContext.usuario.longitude ?? (datosUsuarioContext.cliente.longitud as number)
  });
  const [zoom] = useState(15);
  const geocoder = useRef<google.maps.Geocoder | any>();

  const copyMarkers = useRef<Array<any>>([]); //Referencia a los Markers que se generan en el mapa, necesario para filtrar cuando se selecciona un tipo de dispositivo en el filtro.
  const clusterMap = useRef<MarkerClusterer>(); // Referencia al MarkerCluster que se genera cuando se carga el mapa, necesario para "regenerar" el MarkerCluster cuando se filtren los tipos de dispositivo.

  function createMapOptions() {
    return {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      gestureHandling: 'greedy',
      styles: googleMapStyleModule[props.moduloSeleccionado] || defaultGoogleMapStyle
    };
  }

  /**
   * Corta un icono svg (en string) para añadirle el color que necesite, si fuera una alerta añade el color del tipo de la alerta, si no, usa el color del cliente.
   * @param {string} textIcon SVG en string
   * @param idTipoAlerta Si fuera una alerta, para cambiar el color del icono
   * @returns {string} SVG en string para usarlo en el Mapa con el color adecuado.
   */
  function getTextIconSVG(textIcon: string, idTipoAlerta = 0) {
    const defaultColor = theme.palette.secondary.main;

    const finCadena = textIcon.slice(4);
    const inicioCadena = `<svg fill="${
      idTipoAlerta ? alertColor[idTipoAlerta].color : defaultColor
    }"`;

    return inicioCadena.concat(finCadena);
  }

  //Calcula el zoom correcto al hacer click en los MarkerCluster del mapa.
  function getCorrectZoom(zoom = 15) {
    const z = zoom / 2;
    const res = z < 5 ? z * 3 : z > 7.5 ? zoom + 2 : z * 2.5;

    return Math.round(res);
  }

  const handleGoogleMapApi = (map: any, google: any, markersPrueba: Array<any>) => {
    const markers: Array<any> = markersPrueba.map((item) => {
      const infowindow = new google.InfoWindow({
        content: `<div class=info-window-box>${getTextIconSVG(
          iconosMapa[item.icono as string]
        )}<h3 class=info-window-title>${item.nombre}</h3></div><p class=info-window-text>${
          item.direccion
        }</p><div class=info-window-box>${
          item.idTipoAlerta ? alertColor[item.idTipoAlerta].icon : ''
        }<p class=info-window-text>${item.fecha ? item.fecha + ' - ' : ''}  ${
          item.informacion ?? ''
        }</p></div>`
      });

      const marker = new google.Marker({
        position: {
          lat: item.latitud,
          lng: item.longitud
        },
        icon: {
          url: `data:image/svg+xml;base64,${window.btoa(
            getTextIconSVG(iconosMapa[item.icono as string], item.idTipoAlerta as number)
          )}`,
          scaledSize: new google.Size(25, 25)
        },
        map,
        id: item.id,
        codigoTipo: item.codigoTipoDispositivo,
        className: `marker${item.id}`
      });

      marker.addListener('click', () => {
        handleClick(marker);
      });
      marker.addListener('mouseover', (event: any) => {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false
        });
      });
      marker.addListener('mouseout', (event: any) => {
        infowindow.close();
      });

      return marker;
    });

    copyMarkers.current = markers;

    const renderer = {
      render: ({ count, position }: Cluster) => {
        const svg = window.btoa(`
        <svg width="32px" height="32px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Maps"><path d="M16,1C9.38,1,4,6.38,4,13c0,6.42,10.83,17.25,11.3,17.71C15.49,30.9,15.75,31,16,31s0.51-0.1,0.7-0.29   C17.17,30.25,28,19.42,28,13C28,6.38,22.62,1,16,1z" fill="${theme.palette.secondary.main}"/><circle cx="16" cy="13" fill="${theme.palette.secondary.main}" r="5"/></g></svg>`);

        return new google.Marker({
          label: { text: String(count), color: 'white', fontSize: '11px', fontWeight: 'bold' },
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.Size(40, 40)
          },
          position,
          zIndex: Number(google.Marker.MAX_ZINDEX) + count
        });
      }
    };

    const onClusterClick = (
      event: google.maps.MapMouseEvent,
      cluster: Cluster,
      map: google.maps.Map
    ): void => {
      map.setCenter(cluster.bounds?.getCenter() as any);
      map.setZoom(getCorrectZoom(map.getZoom()));
    };

    clusterMap.current = new MarkerClusterer({ markers, map, renderer, onClusterClick });

    geocoder.current = new google.Geocoder();
  };

  /**
   * Al hacer click en un dispositivo se ejecuta este evento, se encarga de abrir la tarjeta y añadirle los datos correspondientes.
   * Siempre que el componente no sea el home
   * @param event
   */
  const handleClick = (event: any) => {
    try {
      getInfo(props.markers, event.id, geocoder).then((value) => {
        props.setDatosTarjeta(value);
      });
    } catch (exception: any) {
      handleError(`[${file}][${handleClick.name}]`, exception);
    }
  };
  //Este es el nuevo filtro del GIS, cuando cambia el tipo del dispositivo que seleccionamos en la barrita de arriba del GIS, limpia los markers y añade los nuevos filtrados por ese tipo.
  //Si no hubiera ninguno seleccionado, se añaden todos.
  useEffect(() => {
    const newMarkers: any = [];
    if (props.codTipoDispositivo.length > 0) {
      props.codTipoDispositivo.forEach((codTip) => {
        copyMarkers.current.forEach((mark: any, index: number) => {
          if (mark.codigoTipo === codTip) {
            newMarkers.push(copyMarkers.current[index]);
          }
        });
      });

      clusterMap.current?.clearMarkers();
      clusterMap.current?.addMarkers(newMarkers);
    } else {
      clusterMap.current?.addMarkers(copyMarkers.current);
    }
  }, [props.codTipoDispositivo]);

  return (
    <div
      accessKey="m"
      tabIndex={0}
      style={{
        height: props.divStyle.height,
        width: props.divStyle.width,
        position: props.divStyle.position,
        transition: props.divStyle.transition
      }}
    >
      <Filter />
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBiQ2p69A_5krInjp2q-0EufD-jr-V0sso',
          language: 'es',
          libraries: ['drawing', 'geometry']
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleGoogleMapApi(map, maps, props.markers)}
      ></GoogleMapReact>
    </div>
  );
}
