import { etiquetasByDispositivos } from '../services/serviciosEtiquetas';

export function useEtiquetas(): {
  obtenerEtiquetasByDispositivo(servicio: string, listaDispositivos: []): Promise<any>;
} {
  /**
   *  Función que obtiene las etiquetas que corresponden a una lista de dispositivos
   * @param { string } servicio servicio al que llamamos
   * @param {[] } listaDispositivos lista de dispositivos para filtrar
   * @returns { Promise<any> } lista de etiquetas
   */
  async function obtenerEtiquetasByDispositivo(
    servicio: string,
    listaDispositivos: []
  ): Promise<any> {
    const respuesta: any = await etiquetasByDispositivos(servicio, listaDispositivos);
    if (!respuesta.isAxiosError && respuesta.data !== undefined) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }
  return {
    obtenerEtiquetasByDispositivo
  };
}
