import React from 'react';

import { TiposActuacionesCrear } from './TiposActuacionesCrear';
import { tipoActuacion } from './resources/interfaces/interfaceTipoActuacion';
import { useReducerTipoActuacion } from './hooks/useReducerTiposActuaciones';
import { TiposActuacionesModificar } from './TiposActuacionesModificar';
export function TiposActuacionesManager(props: { tipoSeleccionado?: tipoActuacion }): JSX.Element {
  const { state, events } = useReducerTipoActuacion(props.tipoSeleccionado);

  return (
    <>
      {props.tipoSeleccionado === undefined && (
        <TiposActuacionesCrear state={state} events={events} />
      )}
      {props.tipoSeleccionado != undefined && (
        <TiposActuacionesModificar state={state} events={events} />
      )}
    </>
  );
}
