export enum actionsReglas {
  SET_REGLA = 'setRegla',
  SET_REGLA_MODIFICAR = 'setDispositivoModificar',
  SET_REGLA_READY = 'setDispositivoReady',
  SET_ACCION = 'setAccion',
  SET_LISTA_CONDICIONES = 'setListaCondiciones',
  SET_LISTA_ACCIONES = 'setListaAcciones',
  SET_PANEL_ACCION_ACTIVO = 'setPanelAccionActivo',
  ADD_CONDICION = 'addCondicion',
  ADD_ACCION = 'addAccion',
  SET_REGLA_ACTIVA = 'setReglaActiva',
  SET_TIPOS_ACCIONES = 'setTiposAcciones',
  SET_TIPO_ACCION_ACTIVA = 'setTipoAccionActiva',
  SET_ESTADO_DIALOGO = 'setEstadoDialogo',
  SET_LISTA_REGLAS = 'setListaReglas',
  HANDLE_BUTTON_ASISTENTE = 'handleButtonAsistente',
  SET_ASISTENTE_REGLAS = 'setAsistenteReglas',
  CERRAR_DIALOGO_REGLAS = 'cerarDialogoReglas',
  SET_ESTADO_TELIA = 'setEstadoTelia',
  SET_MENSAJE_TELIA = 'setMensajeTelia',
  SET_DIALOGO_CONDICIONES = 'setDialogoCondiciones',
  SET_DIALOGO_ACCIONES = 'setDialogoAcciones',
  MOSTRAR_INFO_ACCION = 'mostrarAccion',
  CERRAR_MOSTRAR_INFO_ACCION = 'cerrarMostrarAccion',
  SET_TEXTO_MOSTRAR_ACCION = 'setTextoMostrarAccion'
}
