/**
 * @readonly
 * @enum {string} Operation
 * @param MAX = MAX
 * @param MIN = MIN
 * @param AVG = AVG
 */
export enum Operation {
  MAX = 'MAX',
  MIN = 'MIN',
  AVG = 'AVG',
  SUM = 'SUM',
  ACTUAL = 'ACT'
}
