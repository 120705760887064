/**
 * Enumerado que permite distinguir entre los id de los modulos
 */
export enum Modulos {
  HOME = 0,
  'GESTIÓN DE USUARIOS' = 1,
  'ILUMINACIÓN VIARIA' = 2,
  'GESTIÓN DE AGUA POTABLE' = 3,
  'PARQUES Y JARDINES' = 4,
  'GESTIÓN DE RESIDUOS' = 5,
  'ENERGÍA' = 6,
  'MEDIO AMBIENTE' = 7,
  'ADMINISTRACIÓN CIUDADANA' = 8,
  'MOVILIDAD URBANA' = 9,
  'VIDEOVIGILANCIA' = 10,
  'CONTROL DE ACCESOS' = 11,
  'TELECOMUNICACIONES' = 12,
  'IA Y BIG DATA' = 13,
  'REGLAS' = 14,
  'CONFIGURACIÓN GENERAL' = 99
}

export function obtenerRutaModulo(idModulo: number): string {
  let ruta: string;
  switch (idModulo) {
    case 1:
      ruta = 'gestion-usuarios';
      break;
    case 2:
      ruta = 'iluminacion-viaria';
      break;
    case 3:
      ruta = 'gestion-agua-potable';
      break;
    case 4:
      ruta = 'parques-y-jardines';
      break;
    case 5:
      ruta = 'gestion-residuos';
      break;
    case 6:
      ruta = 'energia';
      break;
    case 7:
      ruta = 'medio-ambiente';
      break;
    case 8:
      ruta = 'administracion-ciudadana';
      break;
    case 9:
      ruta = 'movilidad-urbana';
      break;
    case 10:
      ruta = 'videovigilancia';
      break;
    case 11:
      ruta = 'control-accesos';
      break;
    case 12:
      ruta = 'telecomunicaciones';
      break;
    case 13:
      ruta = 'ia-bigdata';
      break;
    case 14:
      ruta = 'reglas';
      break;
    case 99:
      ruta = 'configuracion-general';
      break;
    default:
      ruta = '';
      break;
  }

  return ruta;
}
