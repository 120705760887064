import axios from 'axios';
import { encriptarJSON, desencriptarRespuesta } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path = '';
let respuesta: any;

/**
 * Función que obtiene los tipos de devices filtrados por plugin
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } idPlugin id del plugin por el que queremos filtrar los tipos devices
 * @returns {Promise<any>} lista de tipos devices de un plugin concreto
 */
export async function tiposDevicesByPlugin(servicio: string, idPlugin: number): Promise<any> {
  path = servicio + '/plugin/';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idPlugin: idPlugin }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene un plugin por nombre
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } nombrePlugin nombre del plugin
 * @returns {Promise<any>}
 */
export async function getPluginPorNombre(servicio: string, nombrePlugin: string): Promise<any> {
  path = servicio + '/obtener/nombre';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ nombre: nombrePlugin }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene un plugin por el id del device asociado
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } idDevice id del device asociado al plugin
 * @returns {Promise<any>}
 */
export async function getPluginPorDevice(servicio: string, idDevice: number): Promise<any> {
  path = servicio + '/obtener/device';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idDevice: idDevice }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que cambia el estado de un Plugin: instalar, pausar, reanudar y desisntalar
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } objeto objeto que contiene el nombre del plugin o la imagenDocker y version que queremos modificar su estado
 * @returns {Promise<any>}
 */
export async function estadoPlugin(servicio: string, objeto: any): Promise<any> {
  path = rutasServicios.PLUGINS + '/' + servicio;
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(process.env.REACT_APP_URL_LOCAL + path, encriptarJSON(objeto), {
        headers: getHeaders(dictionary_entornos.BACK)
      });
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
