import React, { useContext } from 'react';
import { ArrowBack } from '@mui/icons-material';
import ControlContext from '../../../context/control/ControlContext';
import { IconButton } from '@mui/material';

export function VolverAtras({ backTo: backTo }: { backTo: JSX.Element }): JSX.Element {
  const { setControl, previousComponent } = useContext(ControlContext);

  const handleClickVolver = () => {
    previousComponent.current.tmp
      ? setControl(previousComponent.current.component)
      : setControl(backTo);
  };

  return (
    <IconButton color="secondary" onClick={handleClickVolver}>
      <ArrowBack />
    </IconButton>
  );
}
