import * as React from 'react';
import { downloadReportInPDF } from '../shared/services/services';
import ReportContext from '.';
import { useTheme } from '@mui/material/styles';
import { useTriggeredRequest } from 'shared/hooks/useRequest';
import BackdropContext from 'context/SmartTeliaBackdropContext';

const generateFile = (content: string, fileName: string) => {
  const binary = atob(content.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export function useReportContext() {
  const context = React.useContext(ReportContext);
  if (!context) {
    throw new Error('useReportContext must be used whitin the ReportProvider');
  }
  const theme = useTheme();
  const { setOpenSmartTeliaBackdrop } = React.useContext(BackdropContext);
  //const { trigger } = useTriggerRequest(downloadReportInPDF);

  const { trigger } = useTriggeredRequest(downloadReportInPDF);

  const {
    openFilter: [, setOpenFilter],
    reportData: [reportData],
    selectedReport: [selectedReport]
  } = context;

  const handleDrawerClose = () => {
    setOpenFilter(false);
  };

  const handleDrawerOpen = () => {
    setOpenFilter(true);
  };

  async function downloadReport(chartId = 'chart') {
    //Genera en base64 el gráfico. ¡¡Importante que el id del gráfico coincida con el primer parámetro de esta función!!
    const {
      report: { head, body, foot }
    } = reportData;
    const { imgURI } = await ApexCharts.exec(chartId, 'dataURI');
    head.chart = imgURI;
    setOpenSmartTeliaBackdrop(true);
    trigger({
      color: theme.palette.secondary.main,
      head,
      body,
      foot
    })
      .then((res) =>
        generateFile(res?.data.result as string, selectedReport.reportInfo?.nombre as string)
      )
      .finally(() => setOpenSmartTeliaBackdrop(false));
  }

  return { ...context, handleDrawerClose, handleDrawerOpen, downloadReport };
}
