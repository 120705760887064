import { device } from '../../../device/resources/interfaces/interfaceDevice';
import { statusPlugin } from '../enums/enumEstado';

/**
 * @alias Plugin
 * @typedef Plugin
 * @description Interfaz del plugin
 * @property {number} id identificador del plugin.
 * @property {string} nombre nombre del plugin.
 * @property {string} descripcion descripción del plugin.
 * @property {string} version version del plugin.
 * @property {string} logotipo logotipo del plugin.
 * @property {string} imagenDocker imagenDocker del plugin.
 * @property {number} idModulo id del modulo al que va asociado el plugin.
 * @property {number} descatalogado indica si el plugin está descatalogado o no.
 * @property {number} puerto indica el puerto donde debe ejecutarse el plugin.
 * @property {statusPlugin} estado indica el estado del plugin. Sus posibles estados son:
 * 0 (instalado), 1 (error), 2 (pausado), 3 (ejecutando) y 4 (listo).
 * @property {boolean} instalado indica si el plugin está instalado o no en el cliente.
 * @property {boolean} actualizar indica si el plugin está actualizado o no en el cliente.
 */
export interface plugin {
  id?: number;
  nombre: string;
  descripcion: string;
  version: string;
  logotipo: string;
  imagenDocker?: string;
  idModulo?: number;
  descatalogado?: number;
  puerto?: number;
  estado?: statusPlugin;
  instalado?: boolean;
  actualizado?: boolean;
}

export const objectPlugin = (elemento?: plugin): plugin => {
  let plugin: plugin;
  elemento === undefined
    ? (plugin = {
        nombre: '',
        descripcion: '',
        version: '',
        logotipo: '',
        imagenDocker: '',
        idModulo: 0,
        descatalogado: 0
      })
    : (plugin = {
        id: elemento.id,
        nombre: elemento.nombre,
        descripcion: elemento.descripcion,
        version: elemento.version,
        logotipo: elemento.logotipo,
        imagenDocker: elemento.imagenDocker,
        idModulo: elemento.idModulo,
        descatalogado: elemento.descatalogado,
        puerto: elemento.puerto,
        estado: elemento.estado,
        instalado: elemento.instalado,
        actualizado: elemento.actualizado
      });

  return plugin;
};

export const objectDevicetoPlugin = (elemento: device): plugin => {
  const plugin: plugin = {
    id: elemento.idPlugin,
    nombre: elemento.nombrePlugin as string,
    descripcion: elemento.descripcionPlugin as string,
    version: elemento.versionPlugin as string,
    logotipo: elemento.logotipoPlugin as string
  };

  return plugin;
};
