import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Fab, Grid, Tooltip } from '@mui/material';
import {
  BotonTablaFilaEditar,
  BotonTablaFilaEliminar
} from 'components/common/table/BotonesTablaFila';
import { ContenedorElementos } from 'components/common/table/contenedorElementos';
import PaginationRounded from 'components/common/table/Paginacion';
import { enumTabla } from 'components/common/table/resources/enumTabla';
import { enumComponentes } from 'resources/enums/enumComponente';
import { dictionary_tiposUnidades } from 'resources/enums/plainText';
import useStyles from './index.styles';
import { useUnitType } from './index.hook';
import AddIcon from '@mui/icons-material/Add';
import Table from 'components/common/table/Table';
import { BackdropTarjetasModulos } from 'components/home/homeModulos/common/BackdropTarjetasModulos';
import { UnitsTypesCRU } from './UnitTypeCRU';
import { RefactorizedAlertDialog } from 'shared/components/AlertDialog';

export function UnitsTypes() {
  const classes = useStyles();
  const theme = useTheme();
  const {
    unitType,
    setControl,
    openAlertEliminar,
    setOpenAlertEliminar,
    isLoading,
    selectRecordsToDelete,
    alertObject,
    onConfirm
  } = useUnitType();

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'codigo', label: 'Código' },
    { name: 'nombre', label: 'Nombre' },
    { name: 'soloLectura', options: { display: 'excluded' } },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          const { rowIndex, tableData } = tableMeta;
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_tiposUnidades.TIPOS_UNIDADES}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={selectRecordsToDelete}
                    disabled={tableData[rowIndex].soloLectura}
                    callBack={() => setOpenAlertEliminar(true)}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    rowsPerPage: window.innerWidth >= 1920 ? 10 : 8,
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (unitType.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <UnitsTypesCRU
            unitTypeSelected={unitType[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_TIPO_UNIDAD}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      selectRecordsToDelete(rowsDeleted.data.map((g) => unitType[g.dataIndex]));
      setOpenAlertEliminar(true);
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <BackdropTarjetasModulos open={true} />
      ) : (
        <>
          <Table
            title={dictionary_tiposUnidades.TITULO}
            columns={columns}
            data={unitType}
            options={options}
          ></Table>
          <Tooltip title={dictionary_tiposUnidades.CREAR_TIPOS_UNIDADES} arrow>
            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => setControl(<UnitsTypesCRU />)}
              className={classes.fabBoton}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <RefactorizedAlertDialog
            {...alertObject}
            openAlert={openAlertEliminar}
            setOpenAlert={setOpenAlertEliminar}
            onConfirm={onConfirm}
            showCancelButton={true}
          />
        </>
      )}
    </div>
  );
}
