import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import { namesLocalStorage } from '../../../../resources/enums/enumNamesLocalStorage';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { formateoFechaGrafico } from '../../../graficas/functions/funciones';
import { PrevisionClimaDias } from '../../../../components/home/principal/resources/interfaces/interfaceClima';
import { useStyles } from './styles';
import { Rango } from '../../../graficas/resources/enums/enumRango';
import { useDrag } from 'react-dnd';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';

export function ConfigurableWeatherCard(props: any): JSX.Element {
  const formateoPrevision = formateoFechaGrafico.format('%e %a');
  const dailyForecast = localStorage.getItem(namesLocalStorage.dailyForecast);
  const arrForecast: PrevisionClimaDias[] = JSON.parse(dailyForecast as string);

  const classes = useStyles({ color: '#94c390' });
  const { tarjeta, handleCardClick = null } = props;

  const [cardData, setCardData] = React.useState(tarjeta);
  const [transition, setTransition] = React.useState(true);
  const [dialogInfo, setDialogInfo] = React.useState<{
    openDialogInfo: boolean;
    datos: any;
  }>({
    openDialogInfo: false,
    datos: null
  });

  const initialState: {
    [key: string]: any;
    tarjeta: {
      titulo: string;
      codigoTipoDispositivo: string | null;
      range: null | Rango;
      codigoFuncionAgregada: null | string;
      codigoTipoMedida: string | null;
      mostrarGrafica: boolean;
      mostrarSemaforo: boolean;
      codigoTipoUnidad: number | null;
      servicio: string;
      idTipoTarjeta: number;
      idUsuario: number;
      idModulo: number;
    };
    dispositivos: Array<any> | null;
    semaforo: { [key: string]: any; min: number | null; max: number | null } | null;
  } = {
    tarjeta: {
      titulo: 'Título',
      codigoTipoDispositivo: null,
      range: null,
      codigoFuncionAgregada: null,
      codigoTipoMedida: null,
      mostrarGrafica: false,
      mostrarSemaforo: false,
      codigoTipoUnidad: null,
      servicio: '',
      idTipoTarjeta: 4,
      idUsuario: 0,
      idModulo: 7
    },
    dispositivos: null,
    semaforo: null
  };

  const [{ isDragging, canDrag }, drag] = useDrag(
    () => ({
      type: 'tarjeta',
      item: {
        ...initialState,
        tarjeta: { ...initialState.tarjeta, idTipoTarjeta: cardType.WeatherCard }
      },
      canDrag: true,
      canDragTarget: true,
      collect: (monitor) => ({
        isDragging: true,
        handlerId: monitor.getHandlerId(),
        canDrag: true
      })
    }),
    [initialState]
  );

  return (
    <>
      <Grid item xs={5} ref={drag} style={{ cursor: canDrag ? 'move' : 'not-allowedone' }}>
        <Box
          style={{
            border: '2px',
            borderStyle: 'solid',
            borderColor: canDrag ? '#94c390' : ' #e0e0e0',
            borderRadius: '15px',
            display: 'grid',
            width: '100%',
            height: '180px'
          }}
          alignItems="center"
          textAlign={'center'}
          gridAutoFlow={'column'}
          justifyContent={'space-evenly'}
        >
          {arrForecast.map((item) => (
            <Box
              display="flex"
              alignItems="center"
              key={item.unixUTCDay}
              flexDirection={'column'}
              sx={{ minHeight: '102px' }}
            >
              <Typography className={classes.typographyPrevision}>
                {formateoPrevision(new Date(item.unixUTCDay * 1000))}
              </Typography>
              <svg className={classes.imgTiempoMapa}>
                <image xlinkHref={item.weather.icono} />
              </svg>
              <Typography className={classes.typographyMaxMin}>
                <b className={classes.bMaxMin}>
                  {item.tempMAX}
                  {dictionary_unidades.grados}
                </b>{' '}
                {item.tempMIN}
                {dictionary_unidades.grados}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
}
