import React from 'react';
import { Button, DialogContent, DialogTitle, Step, StepLabel, Stepper, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { dictionary_asistente_permisos } from '../../resources/enums/plainText';
import { EstablecerPerfil } from './asistentePermisos/EstablecerPerfil';
import { EstablecerModulosYComponentes } from './asistentePermisos/EstablecerModulosYComponentes';
import { EstablecerDispostivos } from './asistentePermisos/EstablecerDispositivos';
import { DatosUsuario } from '../login/interfaceDatosUsuario';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

enum title {
  'Establezca el perfil del usuario',
  'Seleccione los módulos y las acciones de cada componente',
  'Para modificar el permiso sobre algún dispositivo, seleccione el módulo al que pertenece'
}
export function AsistentePermisos({ events, state }: { events: any; state: any }): JSX.Element {
  const classes = useStyles();
  const { asistentePermisos } = state;

  //Este es el componente que va cambiando dependiendo de cada paso en el Stepper.
  const object: any = {
    0: <EstablecerPerfil state={state} onChange={events.onChangeSelectPerfil} />,
    1: <EstablecerModulosYComponentes state={state} events={events} />,
    2: <EstablecerDispostivos state={state} events={events} />
  };

  return (
    <>
      <DialogTitle id="Asistente permisos">{title[asistentePermisos.activeStep]}</DialogTitle>
      <DialogContent style={{ height: '470px' }}>
        {object[asistentePermisos.activeStep]}
      </DialogContent>
      <DialogContent>
        <Stepper activeStep={asistentePermisos.activeStep} color="secondary">
          {asistentePermisos.steps.map((label: string) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div>
            <Button
              disabled={asistentePermisos.activeStep === 0}
              onClick={events.handleButtonAsistente}
              className={classes.button}
              color="primary"
              id={dictionary_asistente_permisos.BOTON_ATRAS}
            >
              {dictionary_asistente_permisos.BOTON_ATRAS}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={events.handleButtonAsistente}
              className={classes.button}
              id={
                asistentePermisos.activeStep === asistentePermisos.steps.length - 1
                  ? dictionary_asistente_permisos.BOTON_FINALIZAR
                  : dictionary_asistente_permisos.BOTON_SIGUIENTE
              }
            >
              {asistentePermisos.activeStep === asistentePermisos.steps.length - 1
                ? dictionary_asistente_permisos.BOTON_FINALIZAR
                : dictionary_asistente_permisos.BOTON_SIGUIENTE}
            </Button>
          </div>
        </div>
      </DialogContent>
    </>
  );
}
