import * as React from 'react';
import { Grid } from '@mui/material';
import { EmptyCard } from '../common/EmptyCard';
import { availableCards } from '../resources/commonResources';
import { sizeCardType } from '../../../configurador/resources/commonResources';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { BackdropTarjetasModulos } from '../common/BackdropTarjetasModulos';

export function ModuleStructure({
  userId,
  selectedModule
}: {
  userId: number;
  selectedModule: number;
}): JSX.Element {
  const [cards, setCards] = React.useState<Array<JSX.Element> | null>(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);

  const { controllerRequest } = useControlador();

  //Carga las tarjetas del usuario.
  React.useEffect(() => {
    const cards = new Array(12).fill(<EmptyCard />);
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: `configuradorTarjetas/`,
        object: { idUsuario: userId, idModulo: selectedModule }
      }
    })
      .then((response) => {
        const arrCards = response.sort((item1: any, item2: any) => item1.id < item2.id);

        arrCards.forEach((item, index) => {
          const {
            tarjeta: { idTipoTarjeta, position }
          } = item;
          const key = `${item.tarjeta.idModulo}-${index}`;
          const sizeCard = sizeCardType[idTipoTarjeta];

          //If the size exists, a React Fragment must be added in the following position.
          if (sizeCard) {
            cards[position + sizeCard] = <React.Fragment />;
          }

          return (cards[position] = React.cloneElement(availableCards[idTipoTarjeta], {
            ...item,
            key
          }));
        });

        setCards(cards);
      })
      .catch(() => setCards([]))
      .finally(() => setOpenBackdrop(false));

    return () => {
      setOpenBackdrop(true);
      setCards(null);
    };
  }, [selectedModule]);

  return (
    <Grid item container xs={12} spacing={2} position={'relative'} minHeight={'372px'}>
      <BackdropTarjetasModulos open={openBackdrop} />
      {!openBackdrop ? cards : <></>}
    </Grid>
  );
}
