import * as React from 'react';
import { dispositivo } from 'components/dispositivos/resources/interfaces/interfaceDispositivo';
import { etiqueta } from 'components/etiquetas/resources/interfaces/interfaceEtiquetas';
import { useReportContext } from 'components/Reports/Context/index.hook';
import { Modulos } from 'resources/enums/enumModulo';
import { getDevicesByDeviceTypes } from 'shared/services/devices';
import { getTagsByModule } from 'shared/services/tags';
import {
  ArgsGetWaterQualityReportData,
  StateWaterQualityReportFilter,
  ValuesWaterQualityReportFilter
} from './types';
import { useRequest, useTriggeredRequest } from 'shared/hooks/useRequest';
import { ValueGetDevicesByDeviceTypes } from '../../shared/types';
import { ReportData } from 'components/Reports/shared/types';
import { getWaterQualityReportData } from './services';

const initial_state: StateWaterQualityReportFilter = {
  selectedTag: null,
  devicesFilteredByTag: [],
  selectedDevices: []
};

export function useWaterQualityReportFilter(): ValuesWaterQualityReportFilter {
  const {
    reportObject: [state = initial_state, setState],
    reportData: [, setReportData],
    handleDrawerClose
  } = useReportContext();

  const { selectedTag, devicesFilteredByTag, selectedDevices } =
    state as StateWaterQualityReportFilter;

  const { data: tagsByModule, isLoading: loadingTags } = useRequest<Array<etiqueta>>(() =>
    getTagsByModule({
      idModulo: Modulos['GESTIÓN DE AGUA POTABLE'],
      idsTiposDispositivos: [22, 23, 24]
    })
  );

  const { data: devices } = useRequest<Array<ValueGetDevicesByDeviceTypes>>(() =>
    getDevicesByDeviceTypes({
      deviceType: ['22', '23', '24'],
      moduleId: Modulos['GESTIÓN DE AGUA POTABLE'],
      withTags: true
    })
  );

  const { trigger: getData } = useTriggeredRequest<ReportData, ArgsGetWaterQualityReportData>(
    getWaterQualityReportData
  );

  function handleAutoCompleteTag(
    event: React.SyntheticEvent<Element, Event>,
    value: etiqueta | null
  ) {
    setState({ ...state, selectedTag: value });
  }

  function handleAutoCompleteDevices(value: Array<dispositivo>) {
    setState({ ...state, selectedDevices: value });
  }

  function resetReport() {
    setState(initial_state);
    setReportData({
      report: { head: undefined, body: undefined, foot: undefined },
      graphData: undefined
    });
  }

  function getReportData() {
    getData({
      devicesIds: selectedDevices.map((device) => device.id) as Array<number>,
      tagId: selectedTag?.id as number
    }).then((res) => {
      setReportData({ report: res?.data.result as ReportData });
      handleDrawerClose();
    });
  }

  React.useEffect(() => {
    if (selectedTag) {
      setState({
        ...state,
        devicesFilteredByTag: devices.filter((device) =>
          device.etiquetas.some((etiqueta) => etiqueta.id === selectedTag.id)
        )
      });
    }
  }, [selectedTag]);

  return {
    tagsByModule,
    selectedTag,
    handleAutoCompleteTag,
    devicesFilteredByTag,
    handleAutoCompleteDevices,
    resetReport,
    selectedDevices,
    loadingTags,
    getReportData
  };
}
