import React, { useEffect } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { useReducerGraficasParent } from '../hooks/useReducerGraficasParent';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { tiposDispositivo } from '../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { TarjetaManager } from './tarjetasGraficas/TarjetaManager';
import { SliderGrafica } from './sliderGrafica/SliderGrafica';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';

import { MapIcons } from '../../common/gis/components/iconos/Iconos';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { dictionary_informes, enumLabel } from '../../../resources/enums/plainText';
import { SelectAllAutocomplete } from '../../common/selectAllAutocomplete/SelectAllAutocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function GraficasParent(): JSX.Element {
  const {
    tiposDispositivos,
    tipoFiltrado,
    setDispositivos,
    setTiposDispositivos,
    actualizarAutocompleteTiposDispositivos,
    actualizarAutocompleteDispositivos,
    controladorSliderGrafica,
    componenteSlider,
    mostrarTarjetas,
    deshabilitarTiposDispositivos,
    deshabilitarTarjetas,
    dispositivosSeleccionados,
    limpiarAutoCompleteDispositivos,
    actualizarAutoCompleteFrecuencia,
    setAgruparDispositivos,
    agruparDispositivos,
    handleFechaFin,
    handleFechaInicio,
    handleRangeFocus,
    cargarDatosGraficas
  } = useReducerGraficasParent();
  const { controllerRequest } = useControlador();
  const handleChangeTiposDispositivos = (event: any, value: any) => {
    actualizarAutocompleteTiposDispositivos(value);
  };

  const handleChangeDispositivos = (value: any) => {
    actualizarAutocompleteDispositivos(value);
  };

  /**
   * Obtiene todos los dispositivos del módulo y todos los tipos de dispositivo.
   */
  useEffect(() => {
    const cargarDatosIniciales = () =>
      //Obtiene todos los dispositivos del módulo.
      controllerRequest(
        {
          type: action.OBTENER_DATOS_BY_MODULO,
          payload: { servicio: rutasServicios.DISPOSITIVOS }
        },
        { openSmartTeliaBackdrop: true }
      ).then((dispositivos: dispositivo[]) => {
        setDispositivos(dispositivos);

        //Obtiene todos los tipos de dispositivo.
        controllerRequest(
          {
            type: action.OBTENER_DATOS_SIN_MODULO,
            payload: { servicio: rutasServicios.TIPOS_DISPOSITIVOS }
          },
          { closeSmartTeliaBackdrop: true }
        ).then((tiposDispositivos: tiposDispositivo[]) => {
          const c: tiposDispositivo[] = [];
          //Filtra los tipos de dispositivos en función de los dispositivos del módulo, para saber que tipos de dispositivos tiene ese módulo.
          tiposDispositivos.forEach((tiposDispositivo, index) => {
            if (dispositivos.find((element) => element.idTipoDispositivo === tiposDispositivo.id))
              c.push(tiposDispositivos[index]);
          });
          setTiposDispositivos(c);
        });
      });

    cargarDatosIniciales();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {tiposDispositivos.length >= 1 ? (
            <Grid container spacing={4} style={{ marginLeft: '15px' }}>
              <Grid item xs={12} sm={10} md={3}>
                <Autocomplete
                  disableClearable
                  id="combo-box-tipoDispositivos"
                  options={tiposDispositivos}
                  getOptionLabel={(option: tiposDispositivo) => option.nombre}
                  fullWidth
                  renderOption={(props, option) => (
                    <li {...props}>
                      <MapIcons icono={option.icon as string} />
                      <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={enumLabel.TIPO_DISPOSITIVO} variant="outlined" />
                  )}
                  onChange={handleChangeTiposDispositivos}
                  disabled={deshabilitarTiposDispositivos}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={4}>
                {tipoFiltrado.mostrarAgrupar ? (
                  <SelectAllAutocomplete
                    id={'combo-box-dispositivos'}
                    items={tipoFiltrado.dispositivosFiltrados}
                    label={enumLabel.DISPOSITIVOS}
                    selectAllLabel={dictionary_informes.SELECCIONAR_TODOS}
                    onChange={(value: any) => {
                      if (!tipoFiltrado.mostrarAgrupar && value.length > 4) {
                        value.pop();
                      } else {
                        handleChangeDispositivos([...value]);
                      }
                    }}
                    fullwidth
                    limitTags={1}
                    disabled={tipoFiltrado.deshabilitarDispositivos}
                    getOptionLabel={(option: any) => option.nombre}
                    limpiarAutoComplete={limpiarAutoCompleteDispositivos}
                  />
                ) : (
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={1}
                    key={limpiarAutoCompleteDispositivos}
                    id="combo-box-dispositivos"
                    options={tipoFiltrado.dispositivosFiltrados}
                    getOptionLabel={(option: any) => option.nombre}
                    fullWidth
                    onChange={(e, value) => {
                      if (!tipoFiltrado.mostrarAgrupar && value.length > 4) {
                        value.pop();
                      } else {
                        handleChangeDispositivos([...value]);
                      }
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.nombre}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={enumLabel.DISPOSITIVOS} variant="outlined" />
                    )}
                    disabled={tipoFiltrado.deshabilitarDispositivos}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={10} md={4}>
                {tipoFiltrado.mostrarAgrupar ? (
                  <FormControlLabel
                    style={{ height: '100%' }}
                    label="Agrupar Dispositivos"
                    control={
                      <Checkbox
                        disabled={
                          dispositivosSeleccionados.length <= 1 ||
                          dispositivosSeleccionados.length > 4
                        }
                      />
                    }
                    onChange={setAgruparDispositivos}
                    checked={agruparDispositivos}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                {mostrarTarjetas ? (
                  tipoFiltrado.tipoSeleccionado !== undefined ? (
                    <TarjetaManager
                      tipoDispositivo={tipoFiltrado.tipoSeleccionado}
                      controladorSliderGrafica={controladorSliderGrafica}
                      deshabilitarTarjetas={deshabilitarTarjetas}
                      dispositivosSeleccionados={dispositivosSeleccionados}
                      agruparSeleccionado={agruparDispositivos}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <SliderGrafica
                    controladorSliderGrafica={controladorSliderGrafica}
                    componenteGrafica={componenteSlider}
                    dispositivosSeleccionados={dispositivosSeleccionados}
                    actualizarAutoCompleteFrecuencia={actualizarAutoCompleteFrecuencia}
                    handleFechaFin={handleFechaFin}
                    handleFechaInicio={handleFechaInicio}
                    handleRangeFocus={handleRangeFocus}
                    cargarDatosGraficas={cargarDatosGraficas}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
