import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconosTiposMedida } from '../../../common/iconosTiposMedida/IconosTiposMedida';
import { Tarjeta } from './Tarjeta';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { CodigoDispositivo } from '../../../../resources/enums/enumCodigoDispositivo';
import { Medida } from '../../resources/types/types';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';

const TIPOS_GRAFICAS: any = {
  consumo: { consulta: TiposMedida.CONSUMO, icon: TiposMedida.CONSUMO, titulo: 'Consumo' },
  temperatura: {
    consulta: TiposMedida.TEMPERATURA,
    icon: TiposMedida.TEMPERATURA,
    titulo: 'Temperatura'
  },
  humedad: { consulta: TiposMedida.HUMEDAD, icon: TiposMedida.HUMEDAD, titulo: 'Humedad' },
  distancia: { consulta: TiposMedida.DISTANCIA, icon: TiposMedida.DISTANCIA, titulo: 'Capacidad' },
  lluvia: { consulta: TiposMedida.LLUVIA, icon: TiposMedida.LLUVIA, titulo: 'Lluvia' },
  aire: {
    consulta: TiposMedida.VELOCIDAD_VIENTO,
    icon: TiposMedida.VELOCIDAD_VIENTO,
    titulo: 'Viento'
  },
  energia_solar: {
    consulta: [TiposMedida.CONSUMO, TiposMedida.GENERADO],
    icon: TiposMedida.GENERADO,
    titulo: 'Energía Solar'
  },
  radiacion: {
    consulta: TiposMedida.RADIACION_SOLAR,
    icon: TiposMedida.RADIACION_SOLAR,
    titulo: 'Radiación'
  },
  nivelLLenado: {
    consulta: TiposMedida.NIVEL_LLENADO_AGUA,
    icon: TiposMedida.NIVEL_LLENADO_AGUA,
    titulo: 'Capacidad'
  },
  temperaturaAgua: {
    consulta: TiposMedida.TEMPERATURA_AGUA_ABASTECIMIENTO,
    icon: TiposMedida.TEMPERATURA_AGUA_ABASTECIMIENTO,
    titulo: 'Temperatura'
  },
  contaminacionEntrada: {
    consulta: TiposMedida.CONTAMINACION_ENTRADA,
    icon: TiposMedida.CONTAMINACION_ENTRADA,
    titulo: 'Contaminación de entrada'
  },
  contaminacionSalida: {
    consulta: TiposMedida.CONTAMINACION_SALIDA,
    icon: TiposMedida.CONTAMINACION_SALIDA,
    titulo: 'Contaminación de salida'
  },
  ConsumoCaudalEntrada: {
    consulta: TiposMedida.CONSUMO_CAUDAL_ENTRADA,
    icon: TiposMedida.CONSUMO_CAUDAL_ENTRADA,
    titulo: 'Consumo caudal de entrada'
  },
  ConsumoCaudal: {
    consulta: TiposMedida.CONSUMO_CAUDAL,
    icon: TiposMedida.CONSUMO_CAUDAL,
    titulo: 'Consumo caudal'
  },
  pHAgua: {
    consulta: TiposMedida.PH_AGUA_ABASTECIMIENTO,
    icon: TiposMedida.PH_AGUA_ABASTECIMIENTO,
    titulo: 'pH del Agua'
  },
  orpAgua: {
    consulta: TiposMedida.ORP_AGUA_ABASTECIMIENTO,
    icon: TiposMedida.ORP_AGUA_ABASTECIMIENTO,
    titulo: 'ORP del Agua'
  },
  dioxidoCarbono: {
    consulta: TiposMedida.CO_2,
    icon: TiposMedida.CO_2,
    titulo: 'Dióxido de carbono (CO₂)'
  },
  monoxidoCarbono: {
    consulta: TiposMedida.MONOXIDO_CARBONO,
    icon: TiposMedida.MONOXIDO_CARBONO,
    titulo: 'Monóxido de carbono (CO)'
  },
  dioxidoNitrogeno: {
    consulta: TiposMedida.DIOXIDO_NITROGENO,
    icon: TiposMedida.DIOXIDO_NITROGENO,
    titulo: 'Dióxido de nitrógeno (NO₂)'
  },
  dioxidoAzufre: {
    consulta: TiposMedida.DIOXIDO_AZUFRE,
    icon: TiposMedida.DIOXIDO_AZUFRE,
    titulo: 'Dióxido de azufre (SO₂)'
  },
  ozono: {
    consulta: TiposMedida.OZONO,
    icon: TiposMedida.OZONO,
    titulo: 'Ozono (O₃)'
  },
  particulasPM1: {
    consulta: TiposMedida.PARTICULAS_PM1,
    icon: TiposMedida.PARTICULAS_PM1,
    titulo: 'Partículas en suspensión PM1'
  },
  particulasPM25: {
    consulta: TiposMedida.PARTICULAS_PM25,
    icon: TiposMedida.PARTICULAS_PM25,
    titulo: 'Partículas en suspensión PM2.5'
  },
  particulasPM10: {
    consulta: TiposMedida.PARTICULAS_PM10,
    icon: TiposMedida.PARTICULAS_PM10,
    titulo: 'Partículas en suspensión PM10'
  },
  calidadAire: {
    consulta: TiposMedida.INDICE_CALIDAD_AIRE,
    icon: TiposMedida.INDICE_CALIDAD_AIRE,
    titulo: 'Índice de calidad del aire'
  }
};

export const codigoDispositivo: any = {
  [CodigoDispositivo.LUMINARIA]: [TIPOS_GRAFICAS.consumo],
  [CodigoDispositivo.CAUDALIMETRO]: [TIPOS_GRAFICAS.consumo],
  [CodigoDispositivo.SENSOR]: [
    TIPOS_GRAFICAS.temperatura,
    TIPOS_GRAFICAS.humedad,
    TIPOS_GRAFICAS.dioxidoCarbono
  ],
  [CodigoDispositivo.CUADRO_ELECTRICO]: [TIPOS_GRAFICAS.consumo],
  [CodigoDispositivo.ESTACION_METEOROLOGICA]: [TIPOS_GRAFICAS.temperatura, TIPOS_GRAFICAS.humedad],
  [CodigoDispositivo.SENSOR_HUMEDAD]: [TIPOS_GRAFICAS.humedad],
  [CodigoDispositivo.ELECTROVALVULA]: [TIPOS_GRAFICAS.temperatura, TIPOS_GRAFICAS.humedad],
  [CodigoDispositivo.SENSOR_RESIDUOS]: [TIPOS_GRAFICAS.temperatura, TIPOS_GRAFICAS.distancia],
  [CodigoDispositivo.METERING]: [TIPOS_GRAFICAS.consumo],
  [CodigoDispositivo.ANEMOMETRO]: [TIPOS_GRAFICAS.aire],
  [CodigoDispositivo.PLUVIOMETRO]: [TIPOS_GRAFICAS.lluvia],
  [CodigoDispositivo.ENERGIA_SOLAR]: [TIPOS_GRAFICAS.energia_solar, TIPOS_GRAFICAS.radiacion],
  [CodigoDispositivo.AEMET]: [
    TIPOS_GRAFICAS.temperatura,
    TIPOS_GRAFICAS.humedad,
    TIPOS_GRAFICAS.aire
  ],
  [CodigoDispositivo.CONTADOR_FISCAL]: [TIPOS_GRAFICAS.consumo],
  [CodigoDispositivo.CALIDAD_AGUA_ABASTECIMIENTO]: [
    TIPOS_GRAFICAS.orpAgua,
    TIPOS_GRAFICAS.pHAgua,
    TIPOS_GRAFICAS.temperaturaAgua
  ],
  [CodigoDispositivo.SENSOR_CONTAMINACION_ENTRADA]: [TIPOS_GRAFICAS.contaminacionEntrada],
  [CodigoDispositivo.SENSOR_CONTAMINACION_SALIDA]: [TIPOS_GRAFICAS.contaminacionSalida],
  [CodigoDispositivo.CAUDALIMETRO_ENTRADA_CAUDAL]: [TIPOS_GRAFICAS.ConsumoCaudalEntrada],
  [CodigoDispositivo.CAUDALIMETRO_CAUDAL]: [TIPOS_GRAFICAS.ConsumoCaudal],
  [CodigoDispositivo.SONDA_NIVEL_ABASTECIMIENTO]: [TIPOS_GRAFICAS.nivelLLenado],
  [CodigoDispositivo.ESTACION_MEDIOAMBIENTAL]: [
    TIPOS_GRAFICAS.monoxidoCarbono,
    TIPOS_GRAFICAS.dioxidoNitrogeno,
    TIPOS_GRAFICAS.dioxidoAzufre,
    TIPOS_GRAFICAS.ozono,
    TIPOS_GRAFICAS.particulasPM1,
    TIPOS_GRAFICAS.particulasPM25,
    TIPOS_GRAFICAS.particulasPM10,
    TIPOS_GRAFICAS.calidadAire,
    TIPOS_GRAFICAS.dioxidoCarbono,
    TIPOS_GRAFICAS.temperatura,
    TIPOS_GRAFICAS.humedad
  ]
};

export function TarjetaManager({
  tipoDispositivo,
  controladorSliderGrafica,
  deshabilitarTarjetas,
  dispositivosSeleccionados,
  agruparSeleccionado
}: {
  tipoDispositivo: string;
  controladorSliderGrafica: (mostrar: boolean, tipoConsulta: Medida) => void;
  deshabilitarTarjetas: boolean;
  dispositivosSeleccionados: Array<dispositivo>;
  agruparSeleccionado: boolean;
}): JSX.Element {
  const theme = useTheme();
  return (
    <>
      {codigoDispositivo[tipoDispositivo] !== undefined ? (
        <Grid container justifyContent={'flex-start'} spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant={'h6'}
              style={{ color: 'GrayText', paddingBottom: '30px', marginTop: '-20px' }}
            >
              {dispositivosSeleccionados.length === 0
                ? 'Para visualizar las gráficas seleccione uno o varios dispositivos'
                : dispositivosSeleccionados.length < 7
                ? 'Dispositivos Seleccionados: ' +
                  dispositivosSeleccionados.map((dispositivo: any, index) => {
                    if (index > 2) {
                      return '...';
                    } else {
                      return ' ' + dispositivo.nombre;
                    }
                  })
                : `Se han seleccionado ${dispositivosSeleccionados.length} dispositivos.`}
            </Typography>
          </Grid>

          {codigoDispositivo[tipoDispositivo].map((element: any) => {
            const icono = (
              <IconosTiposMedida
                icono={element.icon}
                style={{ width: 25, height: 25, float: 'left' }}
                key={element.icon}
                htmlColor={theme.palette.primary.main}
              />
            );
            return (
              <Grid item xs={12} sm={5} md={3} key={element.consulta}>
                <Tarjeta
                  iconTitulo={icono}
                  tipoConsulta={element.consulta}
                  titulo={element.titulo}
                  controladorSliderGrafica={controladorSliderGrafica}
                  deshabilitarTarjetas={deshabilitarTarjetas}
                  agruparDispositivos={agruparSeleccionado}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
