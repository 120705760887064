/**
 * @alias TipoMedida
 * @typedef TipoMedida
 * @property {number} id identificador del tipo de medida.
 * @property {string} codigo codigo del tipo de medida.
 * @property {string} nombre nombre del tipo de medida.
 * @property {number} soloLectura indica si el tipo de medida
 * es de solo lectura: 0 indica que no, 1 indica que si.
 */

export interface tipoMedida {
  id?: number;
  codigo: string;
  nombre: string;
  soloLectura: number;
}

export const objectTipoMedida = (elemento?: tipoMedida): tipoMedida => {
  let tipoMedida: tipoMedida;
  elemento === undefined
    ? (tipoMedida = {
        codigo: '',
        nombre: '',
        soloLectura: 0
      })
    : (tipoMedida = {
        id: elemento.id,
        codigo: elemento.codigo,
        nombre: elemento.nombre,
        soloLectura: 0
      });

  return tipoMedida;
};
