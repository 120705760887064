import React from 'react';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { useReducerAcciones } from '../hooks/useReducerAcciones';
import { dictionary_generic, enumLabel } from '../../../resources/enums/plainText';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { Button, Grid, TextField } from '@mui/material';

export function FormularioEmail(props: { state?: any; events?: any }): JSX.Element {
  const { stateAcciones, events: eventosAcciones } = useReducerAcciones(
    props.events,
    enumTiposAcciones.emails
  );
  const { state } = props;

  const { setEmail, handleCreateAccion } = eventosAcciones;
  const { emailCompleto } = stateAcciones;
  const { handleInputChange, handleInputInvalid } = useEventChange(emailCompleto, setEmail);

  return (
    <form id="panelEmail">
      <Grid container direction="column">
        <Grid padding={1}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth={true}
            label={enumLabel.DIRECCION_EMAIL}
            id="direccion"
            type="email"
            required
            onChange={handleInputChange}
            onKeyDown={handleInputInvalid}
            onPaste={handleInputInvalid}
            multiline
            rows={2}
          />
        </Grid>
        <Grid padding={1}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth={true}
            label={enumLabel.ASUNTO}
            id="asunto"
            type="text"
            required
            onChange={handleInputChange}
            onKeyDown={handleInputInvalid}
            onPaste={handleInputInvalid}
          />
        </Grid>
        <Grid padding={1}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth={true}
            label={enumLabel.MENSAJE}
            id="mensaje"
            type="text"
            required
            onChange={handleInputChange}
            onKeyDown={handleInputInvalid}
            onPaste={handleInputInvalid}
            multiline
            rows={2}
          />
        </Grid>

        <Grid container direction="row-reverse" padding={1}>
          <Button variant="contained" color="secondary" onClick={handleCreateAccion}>
            {dictionary_generic.CREAR}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
