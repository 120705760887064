import * as React from 'react';
import useSWR from 'swr';
import { CurrentWeather } from './types';
import { namesLocalStorage } from 'resources/enums/enumNamesLocalStorage';

const appid = '513ac38e5c52621f75668173c5688921';
/**
 * https://openweathermap.org/current
 */
export function useGetCurrentWeather(urlParams: string | null) {
  let key: string | null = null;

  const url = `https://api.openweathermap.org/data/2.5/weather?${urlParams}&appid=${appid}&lang=es&units=metric`;

  if (urlParams) {
    key = `currentWeather-${urlParams}`;
  }

  const { data: currentWeather } = useSWR<CurrentWeather>(
    key,
    () => fetch(url).then((res) => res.json()),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  React.useEffect(() => {
    if (currentWeather) {
      localStorage.setItem(namesLocalStorage.expDateCurrentWeather, currentWeather.dt.toString());
    }
  }, [currentWeather]);

  return { currentWeather };
}
