import React from 'react';
import { Dialog, Fab, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Button, Grid, TextField, Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { CheckPassword } from './common/CheckPassword';
import useStyles from './styles';
import { AsistentePermisos } from './AsistentePermisos';
import PreviewPermisos from './PreviewPermisos';
import {
  dictionary_generic,
  dictionary_usuarios,
  enumLabel
} from '../../resources/enums/plainText';
import no_permisos from '../../resources/images/no_permisos.png';
import WidgetsIcon from '@mui/icons-material/Widgets';

export function UsuariosUpdate({ state, events }: { state: any; events: any }): JSX.Element {
  const classes = useStyles();
  const { usuario, grow, permisosUsuario, usuarioFinal } = state;

  const [openCollapsePreview, setOpenCollapsePreview] = React.useState(0);

  React.useEffect(() => {
    setOpenCollapsePreview(0);
  }, [usuario, state.openAsistente]);

  return (
    <>
      <div className={classes.root}>
        <form id="formDispositivo" onSubmit={events.handleSubmitEditar} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5" className={classes.tituloFormulario}>
                {dictionary_usuarios.MODIFICAR_USUARIO}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" className={classes.tituloFormulario}>
                {dictionary_usuarios.PERMISOS}
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={6}>
              <Grid
                item
                container
                sm={12}
                justifyContent="flex-start"
                spacing={2}
                className={classes.cajas}
              >
                <Grid item xs={12} sm={10} md={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.NOMBRE}
                    id="nombre"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.nombre}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.APELLIDOS}
                    id="apellidos"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.apellidos}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.DIRECCION}
                    id="direccion"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.direccion}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.CODIGO_POSTAL}
                    id="idCodigoPostal"
                    type="number"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.idCodigoPostal}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.POBLACION}
                    id="poblacion"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.poblacion}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.PROVINCIA}
                    id="provincia"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.provincia}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.CORREO_ELECTRONICO}
                    id="email"
                    type="email"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.email}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.TELEFONO}
                    id="telefono"
                    type="tel"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.telefono}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.LATITUD}
                    id="latitude"
                    type="number"
                    onChange={events.handleLatitude}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.latitude}
                    inputProps={{ step: 'any' }}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.LONGITUD}
                    id="longitude"
                    type="number"
                    onChange={events.handleLongitude}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.longitude}
                    inputProps={{ step: 'any' }}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    label={enumLabel.NOMBRE_USUARIO}
                    id="nombreUsuario"
                    type="text"
                    required
                    onChange={events.handleInputChange}
                    onKeyDown={events.handleInputInvalid}
                    onPaste={events.handleInputInvalid}
                    defaultValue={usuario.nombreUsuario}
                    inputProps={{
                      pattern: dictionary_usuarios.PATRON_NOMBRE_USUARIO,
                      title: dictionary_usuarios.MENSAJE_NOMBRE_USUARIO
                    }}
                    helperText={dictionary_usuarios.AVISO_NOMBRE_USUARIO}
                  />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                  <FormControlLabel
                    control={<Switch checked={grow} onChange={events.handleGrow} />}
                    label={enumLabel.CAMBIAR_CONTRASENA}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  style={{
                    position: grow ? 'relative' : 'absolute',
                    visibility: grow ? 'visible' : 'hidden'
                  }}
                >
                  <CheckPassword state={state} events={events} />
                </Grid>
              </Grid>
              <Grid item container sm={12} spacing={2} className={classes.cajas}>
                <Grid item container sm={10} justifyContent="flex-end">
                  <Grid item xs={5} sm={4} md={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth={true}
                      type="submit"
                      disabled={
                        grow
                          ? state.newPasswordWrong || state.oldPasswordWrong
                            ? true
                            : false
                          : false
                      }
                    >
                      {dictionary_generic.MODIFICAR}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              {permisosUsuario.usuarioNuevo ? (
                <img src={no_permisos} alt="Usuario sin permisos" />
              ) : (
                <PreviewPermisos
                  permisosUsuarioAdmin={permisosUsuario}
                  permisosUsuarioEditar={usuarioFinal}
                  openCollapsePreview={openCollapsePreview}
                  setOpenCollapsePreview={setOpenCollapsePreview}
                />
              )}
              <Tooltip title={'Configurador de tarjetas'} arrow>
                <Fab
                  color="secondary"
                  aria-label="add"
                  className={classes.fabBotonCardConfigurator}
                  onClick={events.openConfigurador}
                  disabled={permisosUsuario.usuarioNuevo}
                >
                  <WidgetsIcon />
                </Fab>
              </Tooltip>
              <Tooltip title={dictionary_usuarios.ASISTENTE_PERMISOS} arrow>
                <Fab
                  color="secondary"
                  aria-label="add"
                  className={classes.fabBoton}
                  onClick={events.openAsistente}
                >
                  <VpnKeyIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </div>
      <Dialog
        open={state.openAsistente}
        maxWidth="xl"
        fullWidth
        onBackdropClick={events.closeAsistente}
      >
        <AsistentePermisos state={state} events={events} />
      </Dialog>
    </>
  );
}
