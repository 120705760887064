import React, { useContext, useReducer, FormEvent } from 'react';

import ControlContext from '../../../context/control/ControlContext';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';

import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { useEffect } from 'react';
import { TablaTiposActuaciones } from '../TablaTiposActuaciones';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { tipoActuacion } from '../resources/interfaces/interfaceTipoActuacion';
import { section, objectSection } from '../../sections/resources/interfaces/interfaceSection';
import { limpiarCampos } from '../../../functions/functions';

import { dictionary_tipos_actuaciones } from '../../../resources/enums/plainText';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';
import { StateTiposActuaciones } from '../resources/interfaces/interfaceStateTiposActuaciones';
import { enumComponentes } from '../../../resources/enums/enumComponente';

export const ACTIONS = {
  SET_TIPO_ACTUACION: 'setTipoActuacion',
  SET_SECTIONS: 'setSections',
  SET_SECTION_SELECCIONADA: 'setSectionSeleccionada'
};
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_TIPO_ACTUACION:
      return {
        ...state,
        tipoActuacion: action.payload
      };
    case ACTIONS.SET_SECTIONS:
      return { ...state, sections: action.payload };
    case ACTIONS.SET_SECTION_SELECCIONADA:
      return {
        ...state,
        sectionSeleccionada: action.payload
      };
  }
};

export function useReducerTipoActuacion(tipoActuacionSeleccionado?: tipoActuacion | any) {
  const stateTiposActuaciones: StateTiposActuaciones = {
    sections: [],
    sectionSeleccionada: objectSection(),
    tipoActuacion: tipoActuacionSeleccionado !== undefined ? tipoActuacionSeleccionado : undefined
  };
  const [state, dispatch] = useReducer(reducer, stateTiposActuaciones);
  const {
    handleInputChange,
    handleInputInvalid,
    handleAutocompleteChange,
    handleAutocompleteObjetoChange
  } = useEventChange(state.tipoActuacion, setTipoActuacion);

  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { setControl } = React.useContext(ControlContext);
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }

  function setTipoActuacion(tipoActuacion: tipoActuacion) {
    dispatch({ type: ACTIONS.SET_TIPO_ACTUACION, payload: tipoActuacion });
  }
  function setSectionSeleccionada(section: any) {
    dispatch({ type: ACTIONS.SET_SECTION_SELECCIONADA, payload: section });
  }
  /**
   * Función que cambia el valor de las sections
   * @param { tipoAccion[] } datos datos de los tipos de acciones
   */
  function setSections(sections: section[]) {
    dispatch({ type: ACTIONS.SET_SECTIONS, payload: sections });
  }

  /**
   * Función que crea el nuevo dispositivo con todos los datos recogidos en el formulario
   * @param {FormEvent<HTMLFormElement>} event este evento se lanza cuando pulsamos en el botón crear
   */
  async function handleSubmitEditar(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const registro = {
      ...state.tipoActuacion,
      idSection: state.sectionSeleccionada.id,
      idPlugin: state.sectionSeleccionada.idPlugin
    };
    if (estanAutocompletesCorrectos()) {
      await controllerRequest({
        type: action.MODIFICAR_REGISTRO,
        payload: {
          servicio: rutasServicios.TIPOS_ACTUACIONES,
          objeto: {
            tipoActuacion: {
              ...registro
            }
          },
          id: registro.id as number
        }
      });
      setOpenSmartTeliaBackdrop(false);
    }
    respuestaSubmit();
  }

  const handleAutocompleteSectionChange = (event: any, value?: any) => {
    value != null && setSectionSeleccionada(value);
  };

  async function handleSubmitCrear(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const registro = {
      ...state.tipoActuacion,
      idSection: state.sectionSeleccionada.id,
      idPlugin: state.sectionSeleccionada.idPlugin
    };
    if (estanAutocompletesCorrectos()) {
      setOpenSmartTeliaBackdrop(true);

      await controllerRequest({
        type: action.CREAR_UN_REGISTRO,
        payload: {
          servicio: rutasServicios.TIPOS_ACTUACIONES,
          objeto: { tipoActuacion: registro }
        }
      });
      setOpenSmartTeliaBackdrop(false);
    }
    respuestaSubmit();
  }

  //Función que comprueba si los valores de los dos autocompletes están rellenos
  function estanAutocompletesCorrectos(): boolean {
    //Compruebo que la section esté bien
    if (state.sectionSeleccionada.nombre == '') {
      propiedadesSnackBar.current.texto = dictionary_tipos_actuaciones.NO_SECTION;
      propiedadesSnackBar.current.severity = enumSeverity.ERROR;
      return false;
    }
    //Compruebo que el nombre del tipo de actuación esté bien
    if (state.tipoActuacion.nombre == undefined) {
      propiedadesSnackBar.current.texto = dictionary_tipos_actuaciones.NO_NOMBRE;
      propiedadesSnackBar.current.severity = enumSeverity.ERROR;
    }
    //Compruebo que el valor del tipo de actuación esté bien
    if (state.tipoActuacion.valor == undefined) {
      propiedadesSnackBar.current.texto = dictionary_tipos_actuaciones.NO_VALOR;
      propiedadesSnackBar.current.severity = enumSeverity.ERROR;
    }

    return true;
  }

  /**
   * Función que se ejecuta tras intentar guardar o editar un dispositivo y se procesa la respuesta
   * correspondiente.
   */
  function respuestaSubmit() {
    if (completadoCorrectamente.current) {
      limpiarCampos('formTiposActuaciones');
      setControl(<TablaTiposActuaciones key={enumComponentes.TIPOS_ACTUACIONES} />);
    }
    mostrarSnackBar(propiedadesSnackBar);
  }

  const cargarDatos = async (tipoActuacion?: tipoActuacion) => {
    setOpenSmartTeliaBackdrop(true);
    setSections(
      await controllerRequest(
        {
          type: action.OBTENER_SECTIONS_ESCRITURA_ACTIVAS,
          payload: { servicio: rutasServicios.SECTIONS }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      )
    );
    //Si es una regla para modificar, se cargan también las condiciones y las acciones
    if (tipoActuacion) {
      setSectionSeleccionada(
        await controllerRequest({
          type: action.OBTENER_DATOS_BY_ID,
          payload: { servicio: rutasServicios.SECTIONS, id: tipoActuacion.idSection }
        })
      );
    }
    setOpenSmartTeliaBackdrop(false);
  };
  //Carga inicial de datos
  useEffect(() => {
    cargarDatos(tipoActuacionSeleccionado);
  }, []);
  const events = {
    handleSubmitEditar,
    handleSubmitCrear,
    handleInputChange,
    handleInputInvalid,
    handleAutocompleteChange,
    handleAutocompleteSectionChange
  };
  return {
    state,
    events
  };
}
