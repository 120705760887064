import * as React from 'react';
import { Collapse, Box } from '@mui/material';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export function CollapseTable(props: {
  children?: React.ReactNode;
  title: string;
  setOpen: (title: string) => void;
  open: string;
}): JSX.Element {
  const openCollapse = props.title === props.open;

  return (
    <>
      <Box display={'flex'} sx={{ borderBottom: '1px solid lightgrey' }} p={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => props.setOpen(openCollapse ? '' : props.title)}
        >
          {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Typography variant="h6" gutterBottom>
            {props.title}
          </Typography>
        </Box>
      </Box>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>{props.children}</Box>
      </Collapse>
    </>
  );
}
