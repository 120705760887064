import React from 'react';
import { decodeToken } from './encryption';
import { Color } from '../resources/enums/enumColores';
import { dictionary_entornos, dictionary_generic } from '../resources/enums/plainText';

/**
 * Función que convierte la fecha de ISOString a formato Local
 *
 * @param { Date } date fecha
 *
 * @return { string } fechaString fecha formateada a Local
 */
export function formatToDayStart(date: string): string {
  const fecha = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const fechaString = fecha.toLocaleString('es-ES', options);

  return fechaString;
}

/**
 * la función .reset() de una etiqueta HTML <form /> deja en blanco todos los inputs. Está controlado de esta manera por TypeScript, ya que al usar .reset() si
 * no declaras en una variable el document.getElementById y haces una condición de un posible null, TypeScript no te lo permite.
 */
export function limpiarCampos(
  idFormulario: string,
  setLimpiar?: React.Dispatch<React.SetStateAction<string>>
): void {
  const formulario: any = document.getElementById(idFormulario);
  if (formulario !== null) formulario.reset();

  // Para limpiar los autoComplete de Material-ui, necesario añadir en esos componentes la propiedad key={limpiar} (declarar un useState : limpiar,setLimpiar)
  //para poder limpiarlos correctamente, no influye en su comportamiento normal.
  if (setLimpiar !== undefined) setLimpiar(formulario.textContent);
}

/**
 * Calcula y devuelve la frecuencia de actualización de un dispositivo,da valores en milisegundos.
 * @param {string} frecuencia Minutos,Horas,Dias.. (en mayúscula la primera letra).
 * @param {number} valor Valor por el que multiplica.
 */
export function calcularFrecuenciaCrear(frecuencia: string, valor: number): number {
  const calculos = {
    factor: 1000,
    resultado: 0
  };

  switch (frecuencia) {
    case dictionary_generic.MINUTOS:
      calculos.resultado = calculos.factor * 60 * valor;
      break;
    case dictionary_generic.HORAS:
      calculos.resultado = calculos.factor * 60 * 60 * valor;
      break;
    case dictionary_generic.DIAS:
      calculos.resultado = calculos.factor * 60 * 60 * 24 * valor;
      break;
  }

  return calculos.resultado;
}

/**
 * Calcula y devuelve la frecuencia de actualización de un dispositivo, en formato tiempo/frecuencia.
 * @param {number} dato del tiempo en ms.
 */
export function calcularFrecuenciaObtener(dato: number): { tiempo: number; frecuencia: string } {
  const factor = 1000;
  let respuesta = {
    tiempo: 0,
    frecuencia: dictionary_generic.MINUTOS
  };

  if (dato / factor <= 3600)
    respuesta = {
      tiempo: dato / factor / 60,
      frecuencia: dictionary_generic.MINUTOS
    };
  if (dato / factor > 3600 && dato / factor < 219600)
    respuesta = {
      tiempo: dato / factor / 60 / 60,
      frecuencia: dictionary_generic.HORAS
    };
  if (dato / factor >= 219600)
    respuesta = {
      tiempo: dato / factor / 60 / 60 / 24,
      frecuencia: dictionary_generic.DIAS
    };

  return respuesta;
}

/**
 * Comprueba si el sessiónStorage contiene dicha tabla, si la tiene devuelve la versión de la tabla, si no devolverá un null (esto permite obtener todos los datos)
 * @param {string} tabla el nombre de la tabla para buscar en el sessionStorage
 */
export function comprobarSessionStorage(tabla: string): string | null | undefined {
  if (typeof Storage !== 'undefined') {
    const versionTable: string | null = sessionStorage.getItem(tabla);
    return versionTable;
  } else {
    // Sorry! No Web Storage support..
  }
}

/**
 * Función que compara la estructura de dos objeto para saber si son de la mismo tipo.
 * Por ejemplo un objeto cualquiera con el objectCanal que se crea en la interfaz por defecto
 * si la estructura es igual quiere decir que el objeto es de tipo canal.
 *
 * @param { any } objeto objeto que queremos comparar
 * @param { any } objDefaultValue objeto por defecto creado en la interfaz con el que queremos comparar
 *
 * @returns {boolean} true si son iguales y false si son distintos
 */
export function getInterfaceObject(objeto: any, objDefaultValue: any): boolean {
  if (objeto !== undefined && objDefaultValue !== undefined) {
    const obj = JSON.stringify(Object.keys(objeto));
    const objInterface = JSON.stringify(Object.keys(objDefaultValue));

    if (obj.toLowerCase() === objInterface.toLowerCase()) {
      return true;
    }
    return false;
  }

  return false;
}

/**
 * Función que comprueba si el token está caducado
 *
 * @returns { boolean } retorna true o false dependiendo de si el token está caducado o no.
 */
export function tokenCaducado(): boolean {
  const token = decodeToken();
  if (token.exp * 1000 < new Date().getTime()) {
    return true;
  }
  return false;
}

/**
 * Función que comprueba con una expresión regular si una cadena es un color
 * (caracter '#' seguido de un valor Hexadecimal)
 * y devuelve true en caso afirmativo o false en caso negativo
 * @param {string} cadena
 * @returns {boolean}
 */
export function isColor(cadena: string | null | undefined): boolean {
  if (!cadena) return false;
  if (cadena.charAt(0) !== '#') return false;

  const nuevaCadena = cadena.slice(1);
  const expresionRegular = /^[0-9A-F]+$/gi;

  return expresionRegular.test(nuevaCadena.toUpperCase());
}

/**
 * Función que obtiene el color del Cliente. Primero revisa si están guardados ya en local Storage,
 * Si no, introduce los colores que tenga el cliente. En caso de que tampoco tenga, se pondrá el por defecto
 * En el futuro el cliente solo tendrá un color
 * @param {string|undefined} colorUno del cliente
 * @param {string|undefined} colorDos del cliente
 * @returns {string} devuelve el color ya sea el del cliente o el por defecto
 */
export function getColorCliente(colorUno?: string, colorDos?: string): string {
  // Obtener el color del local Storage y si no, del cliente
  const colorPrimario = localStorage.getItem('colorPrimario') ?? colorUno;
  const colorSecundario = localStorage.getItem('colorSecundario') ?? colorDos;
  // si el color primario del cliente no es el mismo que el de por defecto, devolveremos el 1º color
  if (isColor(colorPrimario) && colorPrimario !== Color.PRIMARIO_POR_DEFECTO)
    return colorPrimario as string;
  // si el color primario del cliente es el mismo que el de por defecto, devolveremos el 2º color
  if (isColor(colorPrimario) && colorPrimario === Color.PRIMARIO_POR_DEFECTO) {
    if (isColor(colorSecundario)) return colorSecundario as string;
  }
  // En caso de que no tenga colores, devolvemos el de por defecto
  return Color.SECUNDARIO_POR_DEFECTO;
}

/**
 * Calcula el tamaño para el ancho en las etiquetas de los inputs en función de la palabra que se le pase.
 * @param label
 * @returns width - Devuelve el tamaño de la etiqueta
 */
export function labelWidth(label: string): number {
  const width = label.length > 7 ? label.length * 7 + label.length / 2 - 1 : label.length * 9;

  return width;
}

export function pxToVw(px: number): string {
  return `min(max(1.3rem, 2.1vw), ${px}px)`;
}

/**
 * Ordena cualquier array de objetos.
 * @param arr Array que queremos ordenar
 * @param variableOrden la variable del objeto para acceder y por la cual se ordenará
 * @returns {Array} Array
 */
export const sortArray = (arr: Array<any>, variableOrden: string): Array<any> =>
  arr.sort((a: any, b: any) =>
    a[variableOrden] < b[variableOrden] ? -1 : a[variableOrden] > b[variableOrden] ? 1 : 0
  );

/**
 * Función que comprueba si una cadena de texto es un JSON
 * @param {string} json Cadena a comprobar
 * @returns {boolean} devuelve true si es un JSON, si no, false
 */
export const isObjectJson = (json: string): boolean => {
  try {
    JSON.parse(json, (key, value) => {
      if (key === '' && typeof value !== 'object') throw 'Invalid JSON';
    });
  } catch (error) {
    return false;
  }
  return true;
};

/**
 * Función que obtiene la dirección del server donse se alojan los servicios y los plugins
 * @returns {string} direccion del server donde se alojan los servicios y los plugins
 */
export const getDirectionServices = (): string => {
  return process.env.REACT_APP_URL_LOCAL?.split('//')[1].split(':')[0] as string;
};

/**
 * Obtiene las headers para cualquier petición HTTP hacia back smarttelia
 * @param {dictionary_entornos} direccion indica si la petición va hacia gestión o hacia el backend
 * @param {string} extraHeaders headers adicionales que hagan falta en la petición
 */
export function getHeaders(direccion: dictionary_entornos, extraHeaders?: any): any {
  const headers = {
    'x-access-token':
      direccion == dictionary_entornos.GESTION
        ? (localStorage.getItem('tokenGestion') as string)
        : (localStorage.getItem('token') as string),
    'x-database-name': sessionStorage.getItem('databaseName') as string
  };
  return { ...headers, ...extraHeaders };
}
