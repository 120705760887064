import { useContext } from 'react';
import ControlContext from '../../../../context/control/ControlContext';
import SmartTeliaBackdropContext from '../../../../context/SmartTeliaBackdropContext';
import { Rango } from '../../resources/enums/enumRango';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { Operation } from '../../resources/enums/enumOperation';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { createComparativeGraph } from './createComparativeGraph';
import { createSolarEnergyChart } from './createSolarEnergyChart';
import { createAreaChart } from './createAreaChart';
import { useHasError } from '../../../error/hooks/useHasError';
import { getLabels } from '../../functions/funciones';
import { RangosString } from '../../resources/enums/enumsRangosString';
import { createComparativeRangedChart } from './createComparativeRangedGraph';
import { NoValues } from '../../resources/interfaces/interfaceCharts';
import { createSolarEnergyChartRange } from './createSolarEnergyChartRange';

export function useGraficas(): {
  obtenerDatosGrafica: (
    medida: TiposMedida | TiposMedida[],
    rango: Rango,
    operation: Operation,
    tipoGrafica: GraphTypes,
    dispositivos: Array<dispositivo>,
    unidad?: dictionary_unidades,
    inicio?: string | Array<string>,
    fin?: string | Array<string>,
    backdrop?: { openSmarteliaBackdrop?: boolean; closeSmarteliaBackdrop?: boolean },
    agruparDispositivos?: boolean,
    valorMinimo?: number | string,
    valorMaximo?: number | string,
    type?: 'column' | 'line' | 'area',
    idModulo?: number
  ) => Promise<any>;
  formatearDatosGraficaRendimientoResiduos: (
    valores: Array<any>,
    dispositivo: dispositivo,
    rango: Rango
  ) =>
    | {
        datos: Array<any>;
        labels: Array<string>;
        title: string;
        titleYAxis: string;
        max: number;
      }
    | NoValues;
} {
  const { moduloSeleccionado } = useContext(ControlContext);
  const { handleError } = useHasError();

  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  /**
   *
   * @param {TiposMedida | Array<TiposMedida>} medida
   * @param {Rango} rango
   * @param {Operation} operation
   * @param {Operation} tipoGrafica
   * @param {Array<dispositivo> | undefined} dispositivos
   * @returns
   */
  async function obtenerDatosGrafica(
    medida: TiposMedida | TiposMedida[],
    rango: Rango,
    operation: Operation = Operation.AVG,
    tipoGrafica: GraphTypes,
    dispositivos: Array<dispositivo>,
    unidad?: dictionary_unidades,
    inicio?: string | Array<string>,
    fin?: string | Array<string>,
    backdrop?: { openSmarteliaBackdrop?: boolean; closeSmarteliaBackdrop?: boolean },
    agruparDispositivos = false,
    valorMinimo?: number | string,
    valorMaximo?: number | string,
    type?: 'column' | 'line' | 'area',
    idModulo?: number
  ) {
    const initialProps: any = {
      [GraphTypes.comparativo]: () =>
        createComparativeGraph(
          idModulo || moduloSeleccionado,
          medida,
          rango,
          operation,
          tipoGrafica,
          dispositivos,
          handleError,
          unidad,
          inicio as string,
          fin as string,
          agruparDispositivos
        ),
      [GraphTypes.energia_solar]: () =>
        createSolarEnergyChart(
          moduloSeleccionado,
          medida,
          rango,
          operation,
          tipoGrafica,
          dispositivos,
          handleError,
          unidad,
          inicio as string,
          fin as string
        ),
      [GraphTypes.area]: () =>
        createAreaChart(
          idModulo || moduloSeleccionado,
          medida,
          rango,
          operation,
          tipoGrafica,
          dispositivos,
          handleError,
          unidad,
          inicio as unknown as Date,
          fin as unknown as Date,
          valorMinimo,
          valorMaximo,
          type
        ),
      [GraphTypes.comparativo_rango_fechas]: () =>
        createComparativeRangedChart(
          moduloSeleccionado,
          medida,
          rango,
          operation,
          tipoGrafica,
          dispositivos,
          handleError,
          inicio as Array<string>,
          fin as Array<string>,
          unidad,
          agruparDispositivos
        ),
      [GraphTypes.comparative_solar_energy]: () =>
        createSolarEnergyChartRange(
          moduloSeleccionado,
          medida,
          rango,
          operation,
          tipoGrafica,
          dispositivos,
          handleError,
          inicio as Array<string>,
          fin as Array<string>,
          unidad
        )
    };

    if (backdrop?.openSmarteliaBackdrop) setOpenSmartTeliaBackdrop(true);

    const graphProps = await initialProps[tipoGrafica]();

    if (backdrop?.closeSmarteliaBackdrop) setOpenSmartTeliaBackdrop(false);

    return graphProps;
  }

  function formatearDatosGraficaRendimientoResiduos(
    valores: Array<any>,
    dispositivo: dispositivo,
    rango: Rango
  ) {
    const getValor = (d: any) => d.valorMostrar;
    //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
    //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
    const { labels, formateoFecha, getLastValue } = getLabels(rango);

    const datos: Array<{
      data: Array<any>;
      name: string;
      color?: string;
      type?: string;
    }> = [];

    //Nos obtenemos el último valor para tratar correctamente los valores a 0 o nulos.
    const ultimoValor = getLastValue(new Date());

    let data;

    if (valores.length === 0) return { noValues: true };

    //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
    //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
    if (valores.length !== labels.length) {
      data = labels.map((element, index) => {
        const res: any = valores.find((item: any) => {
          return formateoFecha(new Date(item['fechaMedida'])).trim() === element;
        });

        return res
          ? +res.valorMostrar.substring(0, res.valorMostrar.indexOf('%'))
          : ultimoValor >= index
          ? 0
          : null;
      });
    } else {
      data = valores.map(getValor);
    }

    datos.push({ data, name: dispositivo.nombre, type: 'area' });

    const title = `Gráfica evolutiva ${RangosString[rango].toLowerCase()}`;
    const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;

    return {
      datos,
      labels,
      title: title,
      titleYAxis: `Evolución del rendimiento en %`,
      max
    };
  }

  return {
    obtenerDatosGrafica,
    formatearDatosGraficaRendimientoResiduos
  };
}
