import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Color } from '../../resources/enums/enumColores';

export function LineColumnChart(props: {
  title: string;
  labels: string[];
  datos: Array<any>;
  titleYAxis: string;
  limitesGrafico?: any;
}): JSX.Element {
  const theme = useTheme();
  const max: Array<number> = [];
  const { title, titleYAxis, datos, labels } = props;
  const height = props.limitesGrafico ? props.limitesGrafico.alto : 350;

  datos.forEach(({ data }, index) => (max[index] = Math.max(...(data as number[]))));

  const options: ApexCharts.ApexOptions = {
    title: {
      text: ` ${title}`,
      align: 'left'
    },
    chart: {
      height: 350,
      type: 'line'
    },
    stroke: { width: [4, 1] },
    xaxis: {
      categories: labels
    },
    yaxis: {
      title: {
        text: titleYAxis
      },
      min: 0,
      max: Math.max(...max) * 1.15
    },
    colors: [theme.palette.secondary.main, Color.GRIS_GRAFICAS],
    tooltip: {
      followCursor: true
    }
  };

  return (
    <div id="chart" style={{ marginTop: `5px` }}>
      <ReactApexChart options={options} series={[...datos]} type="line" height={height} />
    </div>
  );
}
