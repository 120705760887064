import { useRef, useContext } from 'react';
import {
  obtenerEliminados,
  obtenerTodosGenerico,
  obtenerTodos,
  obtenerTodosFiltrado,
  obtenerUnRegistro
} from '../../services/servicioObtener';
import ControlContext from '../../context/control/ControlContext';
import DatosContext from '../../context/DatosContext';
import { comprobarSessionStorage } from '../../functions/functions';
import { Modulos } from '../../resources/enums/enumModulo';

export function useObtener(): {
  obtenerDatosServicioGenerico: (servicio: string) => Promise<any>;
  obtenerDatosEliminados: (servicio: string) => Promise<any>;
  obtenerDatosContextoPorModulo: (servicio: string, modulo?: Modulos) => Promise<any>;
  obtenerDatosContextoPorId: (servicio: string, id: number) => Promise<any>;
  obtenerDatosContextoSinModulo: (servicio: string) => any;
  obtenerDatosContextoGenerico: (servicio: string, modulo: boolean, id?: number) => any;
} {
  const listaDatos = useRef();
  const { moduloSeleccionado } = useContext(ControlContext);
  const { state } = useContext(DatosContext);

  /**
   * Función que obtiene todos los datos independientemente de su estado
   * @param { string } servicio nombre de la ruta donde queremos consultar todos los registros
   * @returns { Promise<any> } listaDatos
   */
  async function obtenerDatosServicioGenerico(servicio: string): Promise<any> {
    const respuesta: any = await obtenerTodosGenerico(servicio);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que obtiene datos eliminados
   * @param {string} servicio nombre de la ruta del servicio
   * @returns { Promise<any> } listaDatos
   */
  async function obtenerDatosEliminados(servicio: string): Promise<any> {
    const respuesta: any = await obtenerEliminados(servicio);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }
  /**
   * Llama al servicio para obtener los datos y actualiza o no los datos del contexto, usar cuando la obtención de datos sea de un Módulo (por ejemplo, los dispositivos del módulo Iluminación).
   * @param { string } nombreTabla Nombre de la tabla que queremos obtener.
   * @returns { Promise<any> } que contiene el resultado de la llamada al servicio
   */
  async function obtenerDatosContextoPorModulo(
    nombreTabla: string,
    modulo?: Modulos
  ): Promise<any> {
    const moduloAsignado = modulo !== undefined ? modulo : moduloSeleccionado;
    const storage = Modulos[moduloAsignado] + `:${nombreTabla}`;
    const respuesta: any = await obtenerTodosFiltrado(
      nombreTabla,
      moduloAsignado,
      comprobarSessionStorage(storage)
    );
    return respuesta;
  }

  /**
   * LLama al servicio para obtener un dato en función del Id.
   * Sólo obtiene datos correspondientes con Módulos.
   * No guarda ni actualiza el dato ni la versionTable, sólo obtiene del contexto o del servicio si no estuviera presente o no estuviera actualizado.
   * @param { string } servicio nombre del servicio
   * @param { number } id id del elemento del que queremos obtener los datos
   * @returns { Promise<any> } que contiene el resultado de la llamada al servicio
   */
  async function obtenerDatosContextoPorId(nombreTabla: string, id: number): Promise<any> {
    const respuesta: any = await obtenerUnRegistro(nombreTabla, id, null);
    return respuesta;
  }

  /**
   * Llama al servicio para obtener los datos y actualiza o no los datos del contexto, usar cuando la obtención de datos no sea de un Módulo (por ejemplo, los Tipos de Medida).
   * @param { string } servicio Nombre del servicio.
   */
  async function obtenerDatosContextoSinModulo(servicio: string) {
    const respuesta = await obtenerTodos(servicio, comprobarSessionStorage(servicio));

    return respuesta;
  }

  /**
   * Obtiene los datos del contexto *NO llama a servicios*, puede obtener datos que no sean de un módulo, datos correspondientes a módulo y un dato en concreto usando el id (puede ser de módulo o no).
   * @param { string } nombreTabla tabla sobre la que queremos los datos.
   * @param { boolean } modulo true para añadir el módulo si fuera necesario.
   * @param { number } id *opcional* El id que queremos consultar.
   */
  function obtenerDatosContextoGenerico(nombreTabla: string, modulo: boolean, id?: number) {
    const tablaStorage = modulo ? Modulos[moduloSeleccionado] + `:${nombreTabla}` : nombreTabla;

    if (id === undefined) {
      return state[tablaStorage];
    } else {
      const p = state.datosTablas[tablaStorage].filter((dato: any) => dato.id === id);
      return p[0];
    }
  }

  return {
    obtenerDatosEliminados,
    obtenerDatosServicioGenerico,
    obtenerDatosContextoPorModulo,
    obtenerDatosContextoPorId,
    obtenerDatosContextoSinModulo,
    obtenerDatosContextoGenerico
  };
}
