import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { etiqueta } from '../etiquetas/resources/interfaces/interfaceEtiquetas';
import { grupo } from '../grupos/resources/interfaces/interfaceGrupo';
import useStyles from './styles';
import {
  dictionary_dispositivos,
  dictionary_generic,
  dictionary_devices,
  enumLabel
} from '../../resources/enums/plainText';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { DispositivoManager } from './DispositivoManager';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { enumComponentes } from '../../resources/enums/enumComponente';
import InfoDevice from './InfoDevice';

export function DispositivoUpdate({ state, events }: { state: any; events: any }): JSX.Element {
  const {
    datosDialogo,
    dispositivoModificar,
    checked,
    dispositivoReady,
    disabled,
    openAviso,
    infoDevice
  } = state;
  const { dispositivo } = dispositivoReady;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form id="formDispositivo" onSubmit={events.handleSubmitEditar} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item container xs={12} sm={12} md={6}>
            <Box display="flex" marginBottom="30px" alignItems={'center'}>
              <VolverAtras backTo={<DispositivoManager key={enumComponentes.ADMINISTRACION} />} />
              <Typography variant="h6">{dictionary_dispositivos.MODIFICAR_DISPOSITIVO}</Typography>
            </Box>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  size="small"
                  id="idDevice"
                  defaultValue={dispositivo.idDevice !== null ? events.getDevices() : undefined}
                  options={datosDialogo.device}
                  getOptionLabel={(option: any) => option.nombre}
                  onChange={events.onhandleAutocompleteDevices}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.DEVICE}
                      variant="outlined"
                      size="small"
                    ></TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={dictionary_dispositivos.IDENTIFICADOR}
                  id="nombre"
                  type="text"
                  required
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  onKeyDown={events.handleInputInvalid}
                  onPaste={events.handleInputInvalid}
                  defaultValue={dispositivoModificar.dispositivo.nombre}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <Autocomplete
                  size="small"
                  id="idTipoDispositivo"
                  defaultValue={{
                    nombre: dispositivoModificar?.dispositivo.tipoDispositivo as string
                  }}
                  options={datosDialogo.tiposDispositivo}
                  disabled={disabled}
                  getOptionLabel={(option: any) => option.nombre}
                  onChange={events.handleAutocompleteTiposDispositivo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.TIPO_DISPOSITIVO}
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  multiple
                  size="small"
                  defaultValue={dispositivoModificar.grupos}
                  id="grupos"
                  options={datosDialogo.gruposDispositivos}
                  disabled={disabled}
                  getOptionLabel={(option: grupo) => option.codigo}
                  onChange={events.handleMultipleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={enumLabel.GRUPOS_DISPOSITIVO}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.MARCA_DISPOSITIVO}
                  id="marca"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.marca}
                  onKeyDown={events.handleInputInvalid}
                  onPaste={events.handleInputInvalid}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.MODELO_DISPOSITIVO}
                  id="modelo"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.modelo}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.NUM_SERIE_DISPOSITIVO}
                  id="nSerie"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.nSerie}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.DIRECCION}
                  id="direccion"
                  type="text"
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.direccion}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.titulo} color="secondary">
              {dictionary_dispositivos.UBICACION}
            </Typography>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.LATITUD}
                  id="latitud"
                  required
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.latitud}
                  inputProps={{
                    pattern: '(^-?[0-9]{1,3}[.][0-9]+$)',
                    title: 'Formato de coordenadas válidas: 39.154448, -3.02021'
                  }}
                />
              </Grid>
              <Grid item sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.LONGITUD}
                  id="longitud"
                  required
                  disabled={disabled}
                  onChange={events.handleInputChange}
                  defaultValue={dispositivoModificar.dispositivo.longitud}
                  inputProps={{
                    pattern: '(^-?[0-9]{1,3}[.][0-9]+$)',
                    title: 'Formato de coordenadas válidas: 39.154448, -3.02021'
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.titulo} color="secondary">
              {dictionary_dispositivos.OTRA_INFORMACION}
            </Typography>
            <Grid item container sm={12} spacing={2} className={classes.cajas}>
              <Grid item xs={12} sm={10}>
                <Autocomplete
                  size="small"
                  multiple
                  id="etiquetas"
                  defaultValue={dispositivoModificar?.etiquetas}
                  options={
                    datosDialogo.etiquetasDispositivo !== undefined
                      ? (datosDialogo.etiquetasDispositivo as etiqueta[])
                      : []
                  }
                  getOptionLabel={(option: any) => option.nombre}
                  filterSelectedOptions
                  disabled={disabled}
                  onChange={events.handleMultipleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={enumLabel.ETIQUETAS} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item container sm={10} justifyContent="space-between" alignItems="center">
                <Grid item xs={5} sm={4} md={3}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        color="secondary"
                        id="activo"
                        checked={checked}
                        disabled={disabled}
                        onChange={events.onchangeCheked}
                        inputProps={{ 'aria-label': 'checkbox-estado' }}
                      />
                    }
                    label={enumLabel.ACTIVAR_DESACTIVAR}
                    labelPlacement="top"
                  />
                </Grid>
                <Grid item xs={5} sm={4} md={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    disabled={disabled}
                    type="submit"
                  >
                    {dictionary_generic.MODIFICAR}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AlertDialog
            titulo={dictionary_dispositivos.AVISO_MODIFICAR}
            open={openAviso}
            setOpen={events.setOpenAviso}
            onConfirm={events.confirmarAlertAviso}
          />
          <Grid item xs={12} sm={12} md={5.8}>
            {infoDevice ? (
              <>
                <Box display="flex" marginBottom="30px">
                  <Typography variant="h6">{dictionary_devices.INFO_DEL_DEVICE}</Typography>
                </Box>
                <InfoDevice device={infoDevice}></InfoDevice>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
