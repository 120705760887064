import { AxiosRequestConfig } from 'axios';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get the detail of the consumption report.
 * @param {number} idDispositivo
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Array<TiposMedida>} measures
 * @param {Group} group
 * @returns {[string | null, (headers: Record<string, unknown>) => Promise<AxiosResponse<any, any>>]} key used by swr and axios request.
 */
export function getConsumptionReportDetail(): { key: Key; request: AxiosRequestConfig } {
  const key: Key = `${swrServiceRoutes.getDetailConsumptionReport}}`;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getDetailConsumptionReport,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  return { key, request };
}
