import * as React from 'react';
import { Grid, Box, Typography, Grow, Divider } from '@mui/material';
import { DonutChart } from '../../../graficasApexCharts/DonutChart';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { getId } from '../../../graficas/functions/funciones';
import { Unidades } from '../../../../resources/enums/enumUnidades';
import { dictionary_configurator, dictionary_generic } from '../../../../resources/enums/plainText';
import { subDays, format } from 'date-fns';
export function WaterDistributionCard(props: any): JSX.Element {
  const classes = useStyles();
  const { tarjeta, dispositivos, handleCardClick = null } = props;
  const { servicio } = tarjeta;
  const [transition, setTransition] = React.useState(true);
  const theme = useTheme();
  const [graphData, setGraphData] = React.useState<{
    series: Array<number>;
    labels: Array<string>;
    unidad: Array<string>;
    diferencia: number;
  }>({
    series: [],
    labels: [],
    unidad: [],
    diferencia: 0
  });
  const { controllerRequest } = useControlador();
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  const date = format(subDays(new Date(), 1), 'dd-MM-yyyy');

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: servicio,
        object: { dispositivos: getId(dispositivos) }
      }
    })
      .then((response) => {
        const { consumoCaudal, consumoCaudalEntrada, diferencia } = response;
        setGraphData({
          series: [consumoCaudalEntrada, consumoCaudal],
          labels: ['Entrada', 'Salida'],
          unidad: [Unidades[3]],
          diferencia
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  }, []);

  return (
    <Grid item xs={12} sm={8} lg={4} sx={{ minHeight: '166px', maxHeight: '182px' }}>
      <Grow in={transition} timeout={650} style={{ height: '100%' }}>
        <div
          style={{ height: '100%', cursor: 'context-menu' }}
          onContextMenu={(event) => {
            event.preventDefault();
            if (handleCardClick) {
              handleCardClick(event, tarjeta.position, setTransition);
            }
          }}
        >
          <CustomTooltip
            title={tarjeta.titulo.length ? tarjeta.titulo : 'Distribución caudalímetros'}
            placement={'bottom'}
          >
            <Box
              style={{
                boxShadow: `0px 0px 1px 2px #e0e0e0`,
                borderRadius: '15px',
                width: '100%',
                height: '100%',
                display: 'flex',
                position: 'relative'
              }}
              alignItems={'center'}
              textAlign={'center'}
            >
              {graphData.series[0] && graphData.series[1] ? (
                <>
                  {' '}
                  <div style={{ width: '100%' }}>
                    <DonutChart {...graphData} />
                  </div>
                  <Divider
                    orientation="vertical"
                    style={{
                      borderRightWidth: 'medium',
                      height: '50%'
                    }}
                  />
                  <div style={{ width: '100%' }}>
                    <Typography className={classes.comparativeValue}>
                      <span
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: 'bold'
                        }}
                      >
                        Diferencia
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span
                        className={classes.measureDate}
                      >{`${dictionary_configurator.MEASURE_DATE} ${date}`}</span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span className={classes.measure}>
                        {graphData.diferencia}
                        {Unidades[3]}
                      </span>
                    </Typography>
                    <Typography className={`${classes.graphInfoCard} ${classes.graphTitle}`}>
                      {graphData.diferencia != null ? (
                        <span
                          style={{
                            color: graphData.diferencia > 0 ? 'green' : 'red',
                            fontWeight: 'bold'
                          }}
                        >
                          {graphData.diferencia < 0 ? 'Ganancia Caudal' : 'Pérdida caudal'}
                        </span>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </div>
                </>
              ) : (
                <div style={{ width: '100%' }}>
                  <Typography className={classes.comparativeValue}>
                    <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                      {dictionary_generic.NO_DATA}
                    </span>
                  </Typography>
                </div>
              )}
            </Box>
          </CustomTooltip>
        </div>
      </Grow>
    </Grid>
  );
}
