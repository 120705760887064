/**
 * @readonly
 * @enum {string} CodigoDispositivo
 * @member Luminaria = 1
 * @member Caudalimetro = 2
 * @member Sensor = 3
 * @member Cuadro_Electrico = 4
 * @member Estacion_Meteorologica = 5
 * @member Sensor_Humedad = 6,
 * @member Electrovalvula = 7
 * @member Sensor_Residuos = 8
 * @member Metering = 9
 * @member Camara = 10
 * @member Wifi = 11
 * @member Parking = 12
 * @member Anemometro = 13
 * @member Pluviometro = 14
 * @member Energia_Solar = 15
 * @member Gateway = 16
 * @member Actuador = 17
 * @member AEMET = 18
 * @member Contador_Fiscal = 19
 * @member PTZ = 20
 * @member Videograbador = 21
 */

export enum CodigoDispositivo {
  LUMINARIA = '1',
  CAUDALIMETRO = '2',
  SENSOR = '3',
  CUADRO_ELECTRICO = '4',
  ESTACION_METEOROLOGICA = '5',
  SENSOR_HUMEDAD = '6',
  ELECTROVALVULA = '7',
  SENSOR_RESIDUOS = '8',
  METERING = '9',
  CAMARA = '10',
  WIFI = '11',
  PARKING = '12',
  ANEMOMETRO = '13',
  PLUVIOMETRO = '14',
  ENERGIA_SOLAR = '15',
  GATEWAY = '16',
  ACTUADOR = '17',
  AEMET = '18',
  CONTADOR_FISCAL = '19',
  PTZ = '20',
  VIDEOGRABADOR = '21',
  CALIDAD_AGUA_ABASTECIMIENTO = '22',
  SENSOR_CONTAMINACION_ENTRADA = '23',
  SENSOR_CONTAMINACION_SALIDA = '24',
  CAUDALIMETRO_ENTRADA_CAUDAL = '25',
  CAUDALIMETRO_CAUDAL = '26',
  SONDA_NIVEL_ABASTECIMIENTO = '27',
  ESTACION_MEDIOAMBIENTAL = '28',
  COLMENA = '29'
}
