import React, { useEffect } from 'react';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { obtenerDatosByModulo } from '../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { rutasServicios } from '../../../../resources/enums/enumRutasServicios';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { DispositivoVideo } from '../../../videovigilancia/resources/interfaces/interfaceDispositivoVideo';
import useReducerVideo from '../../../videovigilancia/hooks/useReducerVideo/useReducerVideo';
import DialogVideo from '../../../videovigilancia/DialogVideo';
import { VideoControls } from '../../../videovigilancia/videoControls/VideoControls';
import useStyles from '../../../videovigilancia/styles';
import { dictionary_cctv } from '../../../../resources/enums/plainText';

export function Videovigilancia(): JSX.Element {
  const classes = useStyles();
  const { controllerRequest } = useControlador();
  const { state, events } = useReducerVideo({});

  const obtenerDispositivosCctv: obtenerDatosByModulo = {
    type: action.OBTENER_DATOS_BY_MODULO,
    payload: { servicio: rutasServicios.DISPOSITIVOS }
  };
  const openCloseSmarTeliaBackdrop = {
    openSmartTeliaBackdrop: true,
    closeSmartTeliaBackdrop: true
  };

  useEffect(() => {
    const cargarDispositivosVideovigilancia = async () => {
      await controllerRequest(
        obtenerDispositivosCctv,
        openCloseSmarTeliaBackdrop
      ).then((response) => events.handleCamsHome(response));
    };

    cargarDispositivosVideovigilancia();

    return () => {
      events.handleCamsHome([]);
    };
  }, []);

  return (
    <>
      {state.camarasHome.length > 0 ? (
        <Grid item xs={12} container spacing={2}>
          <ButtonGroup aria-label="camaras">
            <Button
              key="todo"
              color="secondary"
              onClick={events.handleAllHome}
              variant={events.isAllSelect() ? 'contained' : 'outlined'}
            >
              {dictionary_cctv.VER_TODO}
            </Button>
            {state.camarasHome.map((camara: dispositivo) => {
              return (
                <Button
                  key={camara.id}
                  color="secondary"
                  onClick={() => events.handleShowCamHome(camara)}
                  variant={events.isCamSelect(camara) ? 'contained' : 'outlined'}
                >
                  {camara.nombre}
                </Button>
              );
            })}
          </ButtonGroup>
          {state.showCamarasHome.length > 0 ? (
            <>
              <Grid item xs={12} container spacing={2}>
                {state.showCamarasHome.map((showCamara: DispositivoVideo) => {
                  return (
                    <Grid item xs={12} sm={6} key={showCamara.idDevice}>
                      <Typography variant="h5">{showCamara.nombre}</Typography>
                      <Button
                        fullWidth
                        key={showCamara.idDevice}
                        className={classes.buttonVideo}
                        onClick={() => events.handleOpenDialogVideoStream(showCamara)}
                      >
                        <div className={classes.videoHome}>
                          <VideoControls datosVideo={showCamara} isDialogoVideo={false} />
                        </div>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
              {state.openDialogVideo ? <DialogVideo state={state} events={events} /> : <></>}
            </>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
