import { obtenerElementosByRegla } from '../resources/servicioReglas';

export function useObtenerReglas(): {
  obtenerElementosPorRegla: (servicio: string, idRegla: number) => Promise<any>;
} {
  /**
   * @description Función que obtiene todas las condiciones o acciones asociadas a una regla
   * @param { string } servicio servicio al que necesitamos llamar
   * @param { number } idRegla identificador de la regla asociada
   */
  async function obtenerElementosPorRegla(servicio: string, idRegla: number): Promise<any> {
    const respuesta: any = await obtenerElementosByRegla(servicio, idRegla);
    if (!respuesta.isAxiosError) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  return {
    obtenerElementosPorRegla
  };
}
