/**
 * @alias tipoAccion
 * @typedef tipoAccion
 * @property {number} id id del tipo de acción.
 * @property {string} tipo nombre del tipo de acción.
 */

export interface tipoAccion {
  id?: number;
  tipo: string;
}

export const objectTipoAccion = (elemento?: tipoAccion): tipoAccion => {
  let tipoAccion: tipoAccion;
  elemento === undefined
    ? (tipoAccion = {
        tipo: ''
      })
    : (tipoAccion = {
        id: elemento.id,
        tipo: elemento.tipo
      });

  return tipoAccion;
};
