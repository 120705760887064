import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { etiqueta, objectEtiqueta } from './resources/interfaces/interfaceEtiquetas';
import useStyles from './styles';
import { TablaEtiquetas } from './TablaEtiquetas';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import ControlContext from '../../context/control/ControlContext';
import SnackBarContext from '../../context/SnackBarContext';
import { limpiarCampos } from '../../functions/functions';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { useEventChange } from '../../hooks/useEventChange/useEventChange';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import {
  dictionary_etiquetas,
  dictionary_generic,
  enumLabel
} from '../../resources/enums/plainText';

export function EtiquetasCRU(props: { etiquetaSeleccionada?: etiqueta }): JSX.Element {
  const classes = useStyles();
  const [etiqueta, setEtiqueta] = React.useState<etiqueta>(
    objectEtiqueta(props.etiquetaSeleccionada)
  );
  const { setControl } = React.useContext(ControlContext);
  const { setSnackBar } = React.useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { handleInputChange, handleInputInvalid } = useEventChange(etiqueta, setEtiqueta);
  const openCloseSmarTeliaBackdrop = {
    openSmartTeliaBackdrop: true,
    closeSmartTeliaBackdrop: true
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (props.etiquetaSeleccionada) {
      await controllerRequest(
        {
          type: action.MODIFICAR_REGISTRO,
          payload: {
            servicio: rutasServicios.ETIQUETAS,
            objeto: etiqueta,
            id: etiqueta.id as number
          }
        },
        openCloseSmarTeliaBackdrop
      );
    } else {
      await controllerRequest(
        {
          type: action.CREAR_UN_REGISTRO,
          payload: { servicio: rutasServicios.ETIQUETAS, objeto: etiqueta }
        },
        openCloseSmarTeliaBackdrop
      );
    }
    if (completadoCorrectamente.current) {
      limpiarCampos('formEtiquetas');
      setControl(<TablaEtiquetas key={enumComponentes.ETIQUETAS} />);
    }
    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  }

  return (
    <div className={classes.root}>
      <form id="formEtiquetas" onSubmit={handleSubmit} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<TablaEtiquetas />} />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {props.etiquetaSeleccionada
              ? dictionary_etiquetas.MODIFICAR_ETIQUETAS
              : dictionary_etiquetas.CREAR_ETIQUETAS}
          </Typography>
        </Box>
        <Grid container md={5} sm={8} xs={12} spacing={4} direction="column">
          <Grid item container spacing={4} justifyContent="space-between">
            <Grid item sm={4} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.CODIGO_ETIQUETA}
                id="codigo"
                type="text"
                required
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={props.etiquetaSeleccionada?.codigo}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_ETIQUETA}
                id="nombre"
                type="text"
                required
                onChange={handleInputChange}
                onKeyDown={handleInputInvalid}
                onPaste={handleInputInvalid}
                defaultValue={props.etiquetaSeleccionada?.nombre}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={4} justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={12} sm={3}>
                <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                  {props.etiquetaSeleccionada
                    ? dictionary_generic.MODIFICAR
                    : dictionary_generic.CREAR}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
