import React, { useEffect } from 'react';
import { useReducerDispositivos } from './hook/useReducerDispositivo';
import { dispositivo } from './resources/interfaces/interfaceDispositivo';
import { DispositivoCreate } from './DispositivoCreate';
import { DispositivoUpdate } from './DispositivoUpdate';
import { TablaDispositivos } from './TablaDispositivos';
import { enumComponentes } from '../../resources/enums/enumComponente';

export function DispositivoManager(props: {
  dispositivoSeleccionado?: dispositivo | any;
  acceso?: boolean;
}): JSX.Element {
  const {
    state,
    setOpenSmartTeliaBackdrop,
    handleAutocompleteChange,
    handleMultipleAutocompleteChange,
    handleAutocompleteTiposDispositivo,
    handleInputChange,
    onchangeCheked,
    comprobarChecked,
    handleSubmitCrear,
    handleSubmitEditar,
    onhandleAutocompleteDevices,
    confirmarAlertAviso,
    setOpenAviso,
    getDevices,
    calcularFrecuenciaObtener,
    setDispositivosTabla,
    tieneAcceso
  } = useReducerDispositivos(props.dispositivoSeleccionado);

  const eventosReducer = {
    handleAutocompleteChange,
    handleMultipleAutocompleteChange,
    handleAutocompleteTiposDispositivo,
    handleInputChange,
    onchangeCheked,
    comprobarChecked,
    handleSubmitCrear,
    handleSubmitEditar,
    onhandleAutocompleteDevices,
    confirmarAlertAviso,
    setOpenAviso,
    getDevices,
    calcularFrecuenciaObtener
  };
  const { keyManager, dispositivosTabla } = state;

  const component: any = {
    [enumComponentes.ADMINISTRACION]: (
      <TablaDispositivos
        dispositivos={dispositivosTabla}
        setDispositivos={setDispositivosTabla}
        acceso={tieneAcceso}
      />
    ),
    [enumComponentes.CREAR_DISPOSITIVO]: (
      <DispositivoCreate state={state} events={eventosReducer} />
    ),
    [enumComponentes.MODIFICAR_DISPOSITIVO]: (
      <DispositivoUpdate state={state} events={eventosReducer} />
    )
  };

  useEffect(() => {
    setOpenSmartTeliaBackdrop(true);
    if (keyManager !== undefined) setOpenSmartTeliaBackdrop(false);
  }, [keyManager]);

  return keyManager !== undefined ? component[keyManager] : <></>;
}
