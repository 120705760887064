import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Tarjeta } from '../../home/principal/common/Tarjeta';
import { IconosComponentes } from '../../common/iconosComponentes/iconosComponentes';
import { pxToVw } from '../../../functions/functions';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import useStylesTarjetas from '../../reglas/stylesTarjetas';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { dictionary_tipos_acciones } from '../../../resources/enums/plainText';

export function DialogoSeleccionTipoAccion(props: { events: any; state: any }): JSX.Element {
  const classes = useStylesTarjetas();
  const { events } = props;

  return (
    <Grid container spacing={2}>
      <Tooltip title="Actuar sobre dispositivo">
        <Grid item md={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-around'}
            onClick={() =>
              events.handlePanelAccionActivoChange(enumTiposAcciones.actuacion_dispositivo)
            }
            className={classes.tarjetaTipoAccion}
          >
            <IconosComponentes componente={enumComponentes.ACTUACION_DISPOSITIVO} />
            <Typography
              paragraph
              style={{
                fontVariant: 'all-small-caps',
                textAlign: 'center',
                fontSize: pxToVw(15),
                flex: '1 1 auto'
              }}
            >
              {dictionary_tipos_acciones.CREAR_ACTUACION_DISPOSITIVO}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
      <Tooltip title="Enviar WhatsApp">
        <Grid item md={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-around'}
            onClick={() => events.handlePanelAccionActivoChange(enumTiposAcciones.whatsapp)}
            id={enumComponentes.ENVIAR_WHATSAPP}
            className={classes.tarjetaTipoAccion}
          >
            <IconosComponentes componente={enumComponentes.WHATSAPP} />
            <Typography
              paragraph
              style={{
                fontVariant: 'all-small-caps',
                textAlign: 'center',
                fontSize: pxToVw(15)
              }}
            >
              {dictionary_tipos_acciones.CREAR_WHATSAPP}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
      <Tooltip title="Enviar email">
        <Grid item md={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-around'}
            onClick={() => events.handlePanelAccionActivoChange(enumTiposAcciones.emails)}
            id={enumComponentes.ENVIAR_EMAIL}
            className={classes.tarjetaTipoAccion}
          >
            <IconosComponentes componente={enumComponentes.EMAIL} />
            <Typography
              paragraph
              style={{
                fontVariant: 'all-small-caps',
                textAlign: 'center',
                fontSize: pxToVw(15)
              }}
            >
              {dictionary_tipos_acciones.CREAR_EMAIL}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
      <Tooltip title="Cambiar estado de regla">
        <Grid item md={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-around'}
            onClick={() =>
              events.handlePanelAccionActivoChange(enumTiposAcciones.cambio_estado_regla)
            }
            id={enumComponentes.CAMBIO_ESTADO_REGLA}
            className={classes.tarjetaTipoAccion}
          >
            <IconosComponentes componente={enumComponentes.CAMBIO_ESTADO_REGLA} />
            <Typography
              paragraph
              style={{
                fontVariant: 'all-small-caps',
                textAlign: 'center',
                fontSize: pxToVw(15)
              }}
            >
              {dictionary_tipos_acciones.CREAR_CAMBIO_REGLA}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>

      <Tooltip title="Crear actuación grupal">
        <Grid item md={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-around'}
            onClick={() => events.handlePanelAccionActivoChange(enumTiposAcciones.actuacion_grupal)}
            id={enumComponentes.ACTUACION_GRUPAL}
            className={classes.tarjetaTipoAccion}
          >
            <IconosComponentes componente={enumComponentes.ACTUACION_GRUPAL} />
            <Typography
              paragraph
              style={{
                fontVariant: 'all-small-caps',
                textAlign: 'center',
                fontSize: pxToVw(15)
              }}
            >
              {dictionary_tipos_acciones.CREAR_ACTUACION_GRUPAL}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
    </Grid>
  );
}
