import { regla, objectRegla } from './interfaceRegla';
export interface cambioEstadoReglaCompleta {
  id?: number;
  regla: regla;
}

export const objectCambioEstadoReglaCompleta = (): cambioEstadoReglaCompleta => {
  return {
    regla: objectRegla()
  };
};
