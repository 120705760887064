import React, { useContext, useReducer } from 'react';
import ControlContext from '../../../../context/control/ControlContext';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { useReducerAlertas } from '../../../alertas/hooks/useReducerAlertas';
import { ACTIONS_REDUCER_HOME_MODULOS } from '../resources/enums/enumActionsHomeModulos';
import { interfaceUseReducerHomeModulos } from '../resources/interfaces/interfaceUseReducerHomeModulos';
import { actionsReducerHomeModulos } from '../resources/types/actionsReducerHomeModulos';
import { objReducerHomeModulos } from '../resources/types/objReducerHomeModulos';
import { AlertasPendientesORegistradas } from '../../../alertas/AlertasPendientesORegistradas';
import { TablaCanales } from '../../../canales/TablaCanales';
import { DispositivoManager } from '../../../dispositivos/DispositivoManager';
import { Informes } from '../../../Reports';
import { Transitions } from '../../../../resources/enums/enumTransitions';
import { GraficasParent } from '../../../graficas/components/GraficasParent';
import { GISContextProvider } from '../../../common/gis/context/GISContext';
import { Gis } from '../../../common/gis/pages/Gis';
import { Box } from '@mui/material';
import { TablaTiposActuaciones } from '../../../tiposActuaciones/TablaTiposActuaciones';
import { InProgress } from '../../../common/inProgress/InProgress';
import { AlertasRegistradas } from '../../../alertas/AlertasRegistradas';
import { ReportProvider } from 'components/Reports/Context';

const initial_State: objReducerHomeModulos = {
  alertasSinResolver: [],
  alertasRegistradas: []
};

const reducer = (state: objReducerHomeModulos, action: actionsReducerHomeModulos) => {
  switch (action.type) {
    case ACTIONS_REDUCER_HOME_MODULOS.SET_ALERTAS_REGISTRADAS:
      return { ...state, alertasRegistradas: action.payload };
    case ACTIONS_REDUCER_HOME_MODULOS.SET_ALERTAS_SIN_RESOLVER:
      return { ...state, alertasSinResolver: action.payload };
    default:
      return state;
  }
};
export function useReducerHomeModulos(): interfaceUseReducerHomeModulos {
  const [thisstate, dispatch] = useReducer(reducer, initial_State);

  const {
    setControl,
    moduloSeleccionado,
    setModuloSeleccionado,
    control,
    componentesFiltrados,
    abortRequest
  } = useContext(ControlContext);
  const reducerAlertas = useReducerAlertas();
  const {
    state: { alertas, alertasSinResolver, alertasSinResolverPorModulo },
    cargarAlertasSinResolverPorModulo
  } = reducerAlertas;

  const handleClickTarjeta = (event: { currentTarget: { id: string } }, values?: any) => {
    abortRequest();
    const componente: any = {
      [enumComponentes.MAPA]: (
        <GISContextProvider key={enumComponentes.MAPA}>
          <Box display={'flex'} height={'100%'}>
            <Gis />
          </Box>
        </GISContextProvider>
      ),
      [enumComponentes.CANALES]: (
        <TablaCanales key={enumComponentes.ADMINISTRACION} data-Transition={Transitions.Zoom} />
      ),
      [enumComponentes.INFORMES]: (
        <ReportProvider>
          <Informes key={enumComponentes.INFORMES} />
        </ReportProvider>
      ),
      [enumComponentes.DISPOSITIVOS]: (
        <DispositivoManager
          key={enumComponentes.ADMINISTRACION}
          data-Transition={Transitions.Zoom}
        />
      ),
      [enumComponentes.ALERTAS_REGISTRADAS]: (
        <AlertasRegistradas key={enumComponentes.ALERTAS} data-Transition={Transitions.Zoom} />
      ),
      [enumComponentes.TIPOS_ACCIONES]: (
        <TablaTiposActuaciones
          key={enumComponentes.TIPOS_ACCIONES}
          data-Transition={Transitions.Zoom}
        />
      ),
      [enumComponentes.ALERTAS_PENDIENTES]: (
        <AlertasPendientesORegistradas
          key={enumComponentes.ALERTAS}
          data-Transition={Transitions.Zoom}
        />
      ),
      [enumComponentes.CONTROL]: <InProgress key={enumComponentes.CONTROL} />
    };

    setControl(componente[event.currentTarget.id]);
  };

  const handleClickGraficas = () => {
    setModuloSeleccionado(moduloSeleccionado);
    setControl(<GraficasParent />);
  };

  const events = {
    handleClickTarjeta,
    handleClickGraficas
  };

  return {
    thisstate,
    alertas,
    alertasSinResolver,
    events,
    setControl,
    moduloSeleccionado,
    setModuloSeleccionado,
    control,
    alertasSinResolverPorModulo,
    componentesFiltrados,
    cargarAlertasSinResolverPorModulo,
    abortRequest
  };
}
