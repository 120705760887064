import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { PropsSnackBar } from '../components/common/snackbar/resources/interfaces/interfaceSnackBar';
import { enumSeverity } from '../components/common/snackbar/resources/enums/enumSeverity';

/**
 * Este contexto se encarga de mostrar el SnackBar y actualizar el texto.
 */
interface snackBarContext {
  snackBar: PropsSnackBar;
  setSnackBar: React.Dispatch<React.SetStateAction<PropsSnackBar>>;
}
const initialStateSnackBar: PropsSnackBar = {
  open: false,
  text: '',
  severity: enumSeverity.ERROR
};
const Context = React.createContext<snackBarContext>({
  snackBar: initialStateSnackBar,
  setSnackBar: useState
});

SnackBarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export function SnackBarContextProvider({
  children
}: InferProps<typeof SnackBarContextProvider.propTypes>): JSX.Element {
  const [snackBar, setSnackBar] = useState(initialStateSnackBar);
  return (
    <Context.Provider
      value={{
        snackBar,
        setSnackBar
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
