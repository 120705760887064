import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Icon, IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDocument } from './useDocument';
import { BackdropTarjetasModulos } from '../../../home/homeModulos/common/BackdropTarjetasModulos';
import useStyleDocumentCard from './styles';

const fileIcons: any = {
  image: <ImageIcon />,
  video: <VideoFileIcon />,
  application: <ArticleIcon />
};

export function DocumentCard({
  files,
  deleteFile,
  isLoading,
  tieneAcceso,
  setIsLoading
}: {
  files: Array<any>;
  deleteFile: (fileId: string, fileName: string) => void;
  isLoading: boolean;
  tieneAcceso: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const { downloadFile } = useDocument(setIsLoading);
  const [openBackdrop, setOpenBackdrop] = React.useState('');
  const classes = useStyleDocumentCard();

  React.useEffect(() => {
    if (isLoading === false) setOpenBackdrop('');
  }, [isLoading]);

  return (
    <>
      <Grid item container sm={12} spacing={1} className={classes.cardContainer}>
        {files.map((item) => (
          <Grid key={item.name} item xs={6} xl={3}>
            <Card className={classes.card}>
              <BackdropTarjetasModulos open={!item.fileId || openBackdrop === item.fileId} />
              <div style={{ opacity: item.fileId ? 1 : 0.5 }}>
                <Tooltip title={`${item.name}`} placement={'left'}>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div" noWrap>
                      <Icon color="secondary" className={classes.fileIcon}>
                        {fileIcons[item.icon]}
                      </Icon>
                      {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`Tamaño: ${item.formatSize} mb`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`Fecha de subida:`}
                      <br />
                      {item.fileId ? `${item.uploadDate}` : ''}
                    </Typography>
                  </CardContent>
                </Tooltip>
                <CardActions className={classes.cardAction}>
                  <IconButton
                    onClick={() => {
                      deleteFile(item.fileId, item.name);
                      setOpenBackdrop(item.fileId);
                    }}
                    disabled={!tieneAcceso || !item.fileId || isLoading}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      downloadFile(item);
                      setOpenBackdrop(item.fileId);
                    }}
                    disabled={!item.fileId || isLoading}
                  >
                    <DownloadIcon />
                  </IconButton>
                </CardActions>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
