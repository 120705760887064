import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { dictionary_alertDialog } from '../../../resources/enums/plainText';

export function AlertDialog(props: {
  titulo: string;
  texto?: string;
  textoSecundario?: string;
  ocultarBotonCancelar?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: any;
}): JSX.Element {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
    >
      <InfoOutlinedIcon
        color="secondary"
        style={{ fontSize: '70', display: 'block', margin: 'auto' }}
      />
      <DialogTitle id="confirm-dialog" style={{ alignSelf: 'center' }}>
        {props.titulo}
      </DialogTitle>
      {props.texto !== undefined && props.texto.length > 0 ? (
        <DialogContentText style={{ margin: '8px 24px' }}>{props.texto}</DialogContentText>
      ) : null}
      {props.textoSecundario !== undefined && props.textoSecundario.length > 0 ? (
        <DialogContent>{props.textoSecundario}</DialogContent>
      ) : null}
      <DialogActions style={{ padding: '24px 24px 8px 8px' }}>
        <Button
          variant="contained"
          onClick={() => {
            props.setOpen(false);
            props.onConfirm();
          }}
          color="secondary"
        >
          {props.ocultarBotonCancelar !== true
            ? dictionary_alertDialog.ACEPTAR
            : dictionary_alertDialog.CERRAR}
        </Button>
        {props.ocultarBotonCancelar !== true ? (
          <Button variant="contained" onClick={() => props.setOpen(false)} color="secondary">
            {dictionary_alertDialog.CANCELAR}
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
}
