import React, { useEffect } from 'react';
import {
  AppBar,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { modulo } from '../../resources/interfaces/interfaceModulo';
import { Modulos } from '../../resources/enums/enumModulo';
import { Block, Check } from '@mui/icons-material';
import useStyles from './styles';
import Table from '../common/table/Table';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { BackdropTarjetasModulos } from '../home/homeModulos/common/BackdropTarjetasModulos';

function a11yProps(index: any) {
  return {
    id: index,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export default function PreviewPermisos({
  permisosUsuarioAdmin,
  permisosUsuarioEditar,
  openCollapsePreview,
  setOpenCollapsePreview
}: {
  permisosUsuarioAdmin: any;
  permisosUsuarioEditar: any;
  openCollapsePreview: number;
  setOpenCollapsePreview: React.Dispatch<React.SetStateAction<number>>;
}) {
  const classes = useStyles();

  function TabComponent({ id }: { id: number }): JSX.Element {
    const handleChange = (event: any, newValue: number) => {
      setSelectedTab(newValue);
    };
    const [selectedTab, setSelectedTab] = useState(0);

    const selected: any = {
      0: <Componentes id={id} key={`comp-${id}`} />,
      1: <DispositivosPermitidos idModulo={id} key={Math.random()} />
    };

    return (
      <>
        <AppBar position="static" color="transparent">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="tabs-Preview-Permisos"
          >
            <Tab label={'Componentes'} {...a11yProps(0)} />
            <Tab label={`Dispositivos permitidos`} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        {selected[selectedTab]}
      </>
    );
  }

  /**
   * Se encarga de formar la lista que mostrará los componentes de cada módulo así como sus permisos.
   * @param {number} id - El id del módulo, para añadir sólo los componentes que corresponden a ese módulo.
   * @param {boolean} open - El estado del item Collapse (true o false)
   * @returns {JSX.Element} Devuelve una lista con los componentes del módulo y sus correspondientes permisos.
   */
  function Componentes({ id }: { id: number }): JSX.Element {
    return (
      <List component="div" disablePadding>
        {permisosUsuarioAdmin.componentes.map((componente: any) => {
          if (componente.idModulo == id) {
            return (
              <ListItem className={classes.nested} key={componente.id}>
                <ListItemText
                  id={componente.id.toString()}
                  primary={componente.nombre}
                  primaryTypographyProps={{ fontSize: '0.4rem' }}
                />
                {comprobarAccesoComponente(componente.id)}
              </ListItem>
            );
          }
        })}
      </List>
    );
  }

  function DispositivosPermitidos({ idModulo }: { idModulo: number }) {
    const { controllerRequest } = useControlador();
    const [state, setState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const columns = [{ name: 'nombre', label: 'Nombre' }];

    const options = {
      rowsPerPageOptions: [],
      download: false,
      filter: false,
      viewColumns: false,
      print: false,
      search: false,
      rowsPerPage: 2,
      selectableRows: 'none'
    };

    React.useEffect(() => {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: { object: { idModulo: idModulo }, service: 'dispositivos/todos/modulo/' }
      }).then((response) => {
        const datosArray: Array<any> = [];
        permisosUsuarioEditar.dispositivos.forEach((dispositivosPermitidos: any) => {
          const c = response.filter(
            (item: any) =>
              item.id === dispositivosPermitidos.idDispositivo &&
              item.idModulo === dispositivosPermitidos.idModulo
          )[0];
          if (c) datosArray.push(c);
        });
        setState(datosArray);
        setIsLoading(false);
      });
    }, []);

    return (
      <React.Fragment>
        {isLoading ? (
          <div style={{ position: 'relative', height: '42px' }}>
            <BackdropTarjetasModulos open={isLoading} />
          </div>
        ) : state.length === 0 ? (
          <Typography p={2}>Todos los dispositivos permitidos</Typography>
        ) : (
          <Table title={''} columns={columns} data={state} options={options}></Table>
        )}
      </React.Fragment>
    );
  }

  /**
   * Función que se encarga de comprobar si el usuario tiene acceso a ese módulo o no.
   * @param {number} idModulo - El id del módulo.
   * @returns {boolean} Devuelve true o false si se encuentra o no dicho módulo.
   */
  function comprobarAccesoModulo(idModulo: number): boolean {
    return permisosUsuarioEditar.modulos.find(({ id }: { id: number }) => id === idModulo);
  }

  /**
   * Función que se encarga de comprobar que permiso tiene cada componente.
   * @param {number} idComponente - El id del componente.
   * @returns {JSX.Element} Devuelve un componente de React dependiendo de cada tipo de permiso.
   */
  function comprobarAccesoComponente(idComponente: number): JSX.Element {
    const objetoListaComponente: any = {
      denegado: { title: 'Acceso denegado', component: <Block /> },
      1: { title: 'Control total', component: <EditIcon /> },
      2: { title: 'Solo lectura', component: <LocalLibraryIcon /> }
    };

    //Filtramos primero para ver si el componente existe en el usuario que estamos editando.
    const c = permisosUsuarioEditar.permisos.filter(
      (permiso: any) => permiso.idComponente === idComponente
    );
    // Si no existe, debemos tratarlo como acceso "denegado", este estado no existe como tal en la BBDD.
    const component =
      c.length >= 1 ? objetoListaComponente[c[0].id] : objetoListaComponente.denegado;

    return (
      <Tooltip title={component.title}>
        <ListItemIcon>{component.component}</ListItemIcon>
      </Tooltip>
    );
  }

  return (
    <List className={classes.rootPreview}>
      {permisosUsuarioAdmin.modulos.map((value: modulo) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <>
            <Tooltip
              title={comprobarAccesoModulo(value.id) ? 'Acceso permitido' : 'Acceso denegado'}
              placement="top-end"
            >
              <ListItem key={value.id} role={undefined} className={classes.listItem}>
                {value.id !== Modulos['GESTIÓN DE USUARIOS'] &&
                value.id !== Modulos['CONFIGURACIÓN GENERAL'] &&
                value.id !== Modulos['REGLAS'] &&
                comprobarAccesoModulo(value.id) ? (
                  <ListItemIcon>
                    <IconButton
                      edge="end"
                      aria-label="componentes"
                      onClick={() =>
                        setOpenCollapsePreview(value.id === openCollapsePreview ? 0 : value.id)
                      }
                    >
                      {openCollapsePreview === value.id ? (
                        <ExpandLess color={'secondary'} />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon></ListItemIcon>
                )}

                <ListItemText
                  id={labelId}
                  primary={value.nombre}
                  primaryTypographyProps={{ variant: 'overline', fontSize: '0.4rem' }}
                />
                <ListItemIcon>
                  {comprobarAccesoModulo(value.id) ? (
                    <Check fontSize="medium" style={{ color: '#008000ab' }}></Check>
                  ) : (
                    <Block fontSize="medium" style={{ color: '#ff0000ba' }}></Block>
                  )}
                </ListItemIcon>
              </ListItem>
            </Tooltip>
            <Collapse
              in={value.id === openCollapsePreview}
              timeout="auto"
              style={{ width: '94%', float: 'right' }}
              unmountOnExit
              key={value.id}
            >
              <TabComponent id={value.id} />
            </Collapse>
          </>
        );
      })}
    </List>
  );
}
