import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { usuario } from '../../resources/interfaces/interfaceUsuario';
import { UsuariosManager } from './UsuariosManager';

export function MiCuenta({
  abrirMiCuenta,
  setAbrirMiCuenta,
  usuario
}: {
  abrirMiCuenta: boolean;
  setAbrirMiCuenta: () => void;
  usuario: usuario;
}): JSX.Element {
  return (
    <Dialog open={abrirMiCuenta}>
      <DialogContent>
        <UsuariosManager
          usuarioSeleccionado={usuario}
          editarPerfil={true}
          abrirDialogoPerfil={setAbrirMiCuenta}
        />
      </DialogContent>
    </Dialog>
  );
}
