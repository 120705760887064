/**
 *  @description Estos tipos son para clasidifcar los tipos de respuesta que se obtienen de los servicios
 *  information -> Es el tipo de respuesta que hay que tratar para que salga el snackbar con un color especifico, un texto informativo ...
 *  naturalResponse -> Es la respuesta a la que no hay que hacerle nada. Se retorna directamente porque así lo espera el componente que la llama.
 *  contexto -> Es aquella respuesta que almacenamos en el contexto para poder usar posteriormente sin tener que llamar de nuevo al mismo servicio
 *  contexto_by_id -> Es la respuesta que almacenamos en el contexto indicando un modulo concreto al que pertenece para no tener que llamar al mismo servcio más tarde
 *  error -> Es cuando la respuesta falla, se ejecuta el handleError para registrar el error y además sale telio con los cables cruzados
 */

export enum typeResponse {
  information,
  naturalResponse,
  contexto,
  contexto_by_id,
  error
}
