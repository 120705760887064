import clearly from '../../../../resources/icons/iconosTiempo/day.svg';
import cloudy_day_1 from '../../../../resources/icons/iconosTiempo/cloudy-day-1.svg';
import cloudy_day_2 from '../../../../resources/icons/iconosTiempo/cloudy-day-2.svg';
import cloudy from '../../../../resources/icons/iconosTiempo/cloudy.svg';
import rain_1 from '../../../../resources/icons/iconosTiempo/rain_1.svg';
import rain_5 from '../../../../resources/icons/iconosTiempo/rain_5.svg';
import rain_6 from '../../../../resources/icons/iconosTiempo/rain_6.svg';
import rain_7 from '../../../../resources/icons/iconosTiempo/rain_7.svg';
import snow_5 from '../../../../resources/icons/iconosTiempo/snow_5.svg';
import snow_6 from '../../../../resources/icons/iconosTiempo/snow_6.svg';
import thunder from '../../../../resources/icons/iconosTiempo/thunder.svg';
import fog from '../../../../resources/icons/iconosTiempo/fog.svg';

const nublado = { descripcion: 'y un cielo nublado.', icono: cloudy };
const lluvia = { descripcion: 'y está lloviendo', icono: rain_5 };
const lluviaIntensa = { descripcion: 'Lluvia intensa', icono: rain_6 };
const lluviaHelada = { descripcion: 'Lluvia helada', icono: rain_7 };
const nieveLigera = { descipcion: 'está nevando ligeramente', icono: snow_5 };
const nieve = { descipcion: 'está nevando', icono: snow_6 };
const nieveIntensa = { descipcion: 'Nieve intensa ', icono: snow_6 };
const aguaNieve = { descipcion: 'Aguanieve ', icono: snow_6 };
const lluviaNieve = { descipcion: 'Lluvia y nieve ', icono: snow_6 };
const llovizna = { descripcion: 'Llovizna', icono: rain_1 };
const tormenta = { descripcion: 'y hay tormenta', icono: thunder };
const fuertesTormentas = { descripcion: 'fuertes tormentas', icono: thunder };
const despejado = { descripcion: 'y un cielo despejado', icono: clearly };
const mayormenteSoleado = { descripcion: 'y un cielo mayormente soleado', icono: cloudy_day_2 };
const mayormenteNublado = { descripcion: 'y un cielo mayormente nublado', icono: cloudy_day_1 };
const niebla = { descripcion: 'y hay niebla', icono: fog };
const sand = { descripcion: 'Tormenta de arena', icono: fog };
const tornado = { descripcion: 'Tornado', icono: fog };
const volcanicAsh = { descripcion: 'Cenizas volcánicas', icono: fog };
const undeterminate = { descripcion: 'sin determinar', icono: clearly };

export const codigosTiempo: any = {
  1: undeterminate,
  200: tormenta,
  201: tormenta,
  202: tormenta,
  210: tormenta,
  211: tormenta,
  212: fuertesTormentas,
  221: fuertesTormentas,
  230: fuertesTormentas,
  231: fuertesTormentas,
  232: fuertesTormentas,
  300: llovizna,
  301: llovizna,
  302: llovizna,
  310: llovizna,
  311: llovizna,
  312: llovizna,
  313: llovizna,
  314: llovizna,
  321: llovizna,
  500: lluvia,
  501: lluvia,
  502: lluviaIntensa,
  503: lluviaIntensa,
  504: lluviaIntensa,
  511: lluviaHelada,
  520: lluviaHelada,
  521: lluviaHelada,
  522: lluviaHelada,
  531: lluviaHelada,
  600: nieveLigera,
  601: nieve,
  602: nieveIntensa,
  611: aguaNieve,
  612: aguaNieve,
  613: nieve,
  615: lluviaNieve,
  616: lluviaNieve,
  620: lluviaNieve,
  621: lluviaNieve,
  622: lluviaNieve,
  701: niebla,
  711: niebla,
  721: niebla,
  731: niebla,
  741: niebla,
  751: sand,
  761: niebla,
  762: volcanicAsh,
  771: niebla,
  781: tornado,
  800: despejado,
  801: mayormenteSoleado,
  802: mayormenteNublado,
  803: nublado,
  804: nublado
};
