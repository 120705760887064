import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Tipos Unidades CRU
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tittleForm: {
    marginTop: '9px'
  },
  alert: {
    alignItems: 'center'
  }
});
export default useStyles;
