import * as React from 'react';
import { decryptResponse, encriptarJSON } from 'functions/encryption';
import { tokenCaducado } from 'functions/functions';
import { useLoginUsuario } from 'hooks/useLogin/useLoginUsuario';
import useSWR, { Key, SWRResponse } from 'swr';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import SnackBarContext from 'context/SnackBarContext';
import { enumSeverity } from 'components/common/snackbar/resources/enums/enumSeverity';
import { ArgsDeleteRecords } from 'shared/services/types';
import { dictionary_eliminar } from 'resources/enums/plainText';

export type GetRequest = AxiosRequestConfig;

interface AlertObject {
  ids: Array<number>;
  mainText: string;
  secondaryText: string;
  title: string;
}

interface Return<Data = unknown, Error = unknown>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'error' | 'mutate' | 'isLoading'
  > {
  data: Data | Array<never>;
  response: AxiosResponse<Data> | undefined;
}

interface TriggeredReturn<Data = unknown, Error = unknown, ExtraArg = Record<string, unknown>>
  extends Pick<
    SWRMutationResponse<AxiosResponse<Data>, AxiosError<Error>, ExtraArg>,
    'error' | 'reset' | 'isMutating' | 'trigger'
  > {
  data: Data | Array<never>;
  response: AxiosResponse<Data> | undefined;
}

interface DeleteReturn<Data = unknown, Error = unknown>
  extends Pick<
    SWRMutationResponse<AxiosResponse<Data>, AxiosError<Error>, ArgsDeleteRecords>,
    'error' | 'reset' | 'isMutating' | 'trigger'
  > {
  data: Data | Array<never>;
  response: AxiosResponse<Data> | undefined;
  selectRecordsToDelete: <T extends { id: number }>(items: Array<T>) => void;
  alertObject: AlertObject;
}

const swrMutationOptions = {
  revalidate: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 400.
    if (error.response.status === 400) return;

    // Never retry if is AxiosError.
    if (error.name === 'AxiosError') return;

    // Only retry up to 1 times.
    if (retryCount >= 1) return;

    // Retry after 3 seconds.
    setTimeout(() => revalidate({ retryCount }), 3000);
  }
};

/**
 * * Custom hook for making axios requests.
 * @param {()=>{ key: Key; request: GetRequest }} callback The callback that returns the necessary parameters for the request.
 * @returns {Data & { result: Data }, Error}
 */
export function useRequest<Data = unknown, Error = unknown>(
  callback: () => { key: Key; request: GetRequest }
): Return<Data & { result: Data }, Error> {
  const { logout } = useLoginUsuario();
  const { key, request } = callback();

  const fetcher = () => {
    if (tokenCaducado()) logout();

    return axios
      .request<Data & { result }>({
        ...request,
        transformResponse: (res) => decryptResponse(JSON.parse(res))
      })
      .catch((error) => {
        throw error;
      });
  };
  const {
    data: response,
    error,
    isLoading,
    mutate,
    isValidating
  } = useSWR(key, fetcher, swrOptions);

  return {
    data: response && response.data.result,
    response,
    error,
    isLoading,
    mutate,
    isValidating
  };
}

/**
 * * Custom hook for making axios requests.
 * @param callback
 * @returns
 */
export function useTriggeredRequest<Data = unknown, ExtraArg = unknown, Error = unknown>(
  callback: () => { key: Key; request: GetRequest },
  openSnackBar = false
): TriggeredReturn<Data & { result: Data; message: string }, Error, ExtraArg> {
  const { logout } = useLoginUsuario();
  const { key, request } = callback();
  const { setSnackBar } = React.useContext(SnackBarContext);

  const fetcher = (url, { arg }) => {
    if (tokenCaducado()) logout();

    if (request?.method === 'PUT') {
      request.url = `${request.url}${arg.id}`;
    }
    return axios
      .request<Data & { result; message }>({
        ...request,
        data: encriptarJSON({ ...arg }),
        transformResponse: (res) => decryptResponse(JSON.parse(res))
      })
      .catch((error) => {
        throw error;
      });
  };

  const {
    data: response,
    error,
    isMutating,
    trigger,
    reset
  } = useSWRMutation(key, fetcher, swrMutationOptions);

  React.useEffect(() => {
    if (error) {
      setSnackBar({
        open: true,
        text: error.response.data.message,
        severity: enumSeverity.ERROR
      });
    } else if (response && openSnackBar) {
      setSnackBar({
        open: true,
        text: response.data.message,
        severity: enumSeverity.SUCCESS
      });
    }
  }, [isMutating]);

  return {
    data: response && response.data.result,
    response,
    trigger,
    error,
    reset,
    isMutating
  };
}

export function useDeleteRecords<Data = unknown, Error = unknown>(
  callback: () => { key: Key; request: GetRequest },
  openSnackBar = false
): DeleteReturn<Data & { result: Data; message: string }, Error> {
  const { logout } = useLoginUsuario();
  const { key, request } = callback();
  const { setSnackBar } = React.useContext(SnackBarContext);
  const [alertObject, setAlertObject] = React.useState<{
    ids: Array<number>;
    mainText: string;
    secondaryText: string;
    title: string;
  }>({ ids: [], mainText: 'Maintext', secondaryText: 'secondaryText', title: '' });

  const selectRecordsToDelete = <T extends { id: number }>(items: Array<T>) => {
    const recordsIds = items.map((item) => item.id as number);

    if (items.length === 1) {
      setAlertObject({
        ids: recordsIds,
        mainText: '',
        secondaryText: '',
        title: dictionary_eliminar.TEXTO_ALERT_ELIMINAR
      });
    } else {
      setAlertObject({
        ids: recordsIds,
        mainText: '',
        secondaryText: '',
        title: dictionary_eliminar.TITULO_ALERT_ELIMINAR_PLURAL
      });
    }
  };

  const fetcher = (url, { arg }) => {
    if (tokenCaducado()) logout();

    return axios
      .request<Data & { result; message }>({
        ...request,
        data: encriptarJSON({ ids: alertObject.ids }),
        transformResponse: (res) => decryptResponse(JSON.parse(res))
      })
      .catch((error) => {
        throw error;
      });
  };

  const {
    data: response,
    error,
    isMutating,
    trigger,
    reset
  } = useSWRMutation(key, fetcher, swrMutationOptions);

  React.useEffect(() => {
    if (error) {
      setSnackBar({
        open: true,
        text: error.response.data.message,
        severity: enumSeverity.ERROR
      });
    } else if (response && openSnackBar) {
      setSnackBar({
        open: true,
        text: response.data.message,
        severity: enumSeverity.SUCCESS
      });
    }
  }, [isMutating]);

  return {
    data: response && response.data.result,
    response,
    trigger,
    error,
    reset,
    isMutating,
    selectRecordsToDelete,
    alertObject
  };
}

export function useRefreshData(callback: () => { key: Key; request: AxiosRequestConfig }) {
  const { mutate: refresh } = useRequest(() => callback());

  return { refresh };
}
