import * as React from 'react';
import { codigosTiempo } from 'components/home/principal/resources/codigosTiempo';
import { PrevisionClimaDias } from 'components/home/principal/resources/interfaces/interfaceClima';
import { getDayOfYear } from 'date-fns';
import { namesLocalStorage } from 'resources/enums/enumNamesLocalStorage';
import useSWR from 'swr';

const appid = '513ac38e5c52621f75668173c5688921';
let key: string | null = null;

const fetcher = ([key, url]) => {
  if (key === null) {
    return JSON.parse(localStorage.getItem(namesLocalStorage.dailyForecast) as string);
  } else {
    return fetch(url)
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem(namesLocalStorage.uvIndex, data.daily[0].uvi);
        localStorage.setItem(namesLocalStorage.pop, (data.daily[0].pop * 100).toString());
        localStorage.setItem(namesLocalStorage.clouds, data.daily[0].clouds.toString());
        if (data.alerts) {
          localStorage.setItem(namesLocalStorage.weatherAlerts, JSON.stringify(data.alerts));
        } else if (localStorage.getItem(namesLocalStorage.weatherAlerts)) {
          localStorage.removeItem(namesLocalStorage.weatherAlerts);
        }
        data.daily.shift();
        const response = data.daily.map((element: any) => {
          return {
            unixUTCDay: element.dt,
            tempMAX: Math.round(element.temp.max),
            tempMIN: Math.round(element.temp.min),
            weather: element.weather[0].id ? codigosTiempo[element.weather[0].id] : codigosTiempo[1]
          };
        });
        localStorage.setItem(namesLocalStorage.dailyForecast, JSON.stringify(response));

        return response;
      });
  }
};

export function useGetDailyForecast(lat: number | null, lon: number | null, userName: string) {
  const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=minutely,hourly&appid=${appid}&lang=es&units=metric`;

  const forecast = localStorage.getItem(namesLocalStorage.dailyForecast);

  React.useMemo<Array<PrevisionClimaDias>>(() => {
    if (forecast && userName === localStorage.getItem(namesLocalStorage.user)) {
      const lastDaySaved = getDayOfYear(new Date());
      const parsedItem = JSON.parse(forecast as string);

      const thisDay = getDayOfYear(new Date(parsedItem[0].unixUTCDay * 1000));

      if (lastDaySaved < thisDay) {
        return parsedItem;
      }
    }
    if (lat && lon) {
      key = `dailyForecast-${lat}-${lon}`;
      localStorage.setItem(namesLocalStorage.user, userName);
    }

    return undefined;
  }, [lat, lon]);

  const { data } = useSWR(lat && lon ? [key, url] : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  return { data };
}
