/**
 * @readonly
 * @enum {string} ServiciosGraficas
 * Define el servicio para las graficas a consultar
 * @member CONSUMO = 'consumo',
 * @member SENSOR = 'sensor',
 * @member ETIQUETAS = 'medidasPorEtiqueta',
 * @member VARIAS = 'variasMedidas',
 */
export enum ServiciosGraficas {
  CONSUMO = 'consumo',
  SENSOR = 'sensor',
  MEDIDAS_POR_ETIQUETAS = 'medidasPorEtiqueta',
  VARIAS_MEDIDAS = 'variasMedidas'
}
