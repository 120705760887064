/**
 * Este enumerado se utilizará como key y determinará el tipo de home que se tiene que cargar dependiendo del modulo en el que entremos
 */
export enum keyHome {
  COMUN = 'homeComun',
  BIG_DATA = 'homeBigDATA',
  REGLAS = 'Reglas',
  GESTION_USUARIOS = 'homeGestionUsuarios',
  CONFIGURACION_GENERAL = 'homeConfiguracionGeneral'
}
