/**
 * @alias Tarjeta
 * @typedef Tarjeta
 * @description Esta interfaz es la composición que debe llevar el objeto tarjeta que se va a mostrar en el mapa
 * @property { number } id identificador del dispositivo
 * @property { string } nombre nombre del dispositivo que se va mostrar en la tarjeta
 * @property { number } latitud latitud del dispositivo
 * @property { string } longitud longitud del dispositivo
 * @property { string } direccion donde está ubicado el dispositivo
 * @property { string } imagen imagen de google maps que indica donde está el dispositivo
 * @property { number } activo indica si el dispositivo está activo o no
 * @property { Date | string } fechaCreacion fecha de creación del dispositivo
 * @property { number } idModulo identificador del modulo del dispositivo
 * @property { string } modulo nombre del modulo asociado al dispositivo
 * @property { string } icono icono que refleja el tipo de dispsitivo que estamo mostrando
 * @property { number } idTipoDispositivo identificador del tipo de dispositivo
 * @property { string } tipoDispositivo nombre del tipo del dispositivo
 * @property { string } codigoTipoDispositivo el codigo del tipo de dispositivo
 * @property { number | null | undefined } idDevice id de device asociado al dispositivo si existiese
 * @property { string | null | undefined } nombreDevice nombre del device asociado al dispositivo
 * @property { number | null | undefined } idTipoAlerta identificador del tipo de alerta
 * @property { string | null | undefined } nombreTipoAlerta nombre del tipo de alerta
 * @property { Date | string | null | undefined } fechaAlerta fecha de la alerta
 * @property { string | null | undefined } mensajeAlerta mensaje de la alerta
 * @property { string | null | undefined } iconoAlerta icono de la alerta
 * @property { Date | string | null | undefined } fecha fecha de la alerta
 * @property { string | null | undefined } informacion mensaje de la alerta
 */
export interface TarjetaInterface {
  id: number;
  nombre: string;
  latitud: number;
  longitud: number;
  direccion: string;
  imagen: string;
  activo: number;
  fechaCreacion: Date | string;
  idModulo: number;
  modulo: string;
  icono: string;
  idTipoDispositivo: number;
  tipoDispositivo: string;
  codigoTipoDispositivo: string;
  idDevice?: number | null;
  nombreDevice?: string | null;
  idTipoAlerta?: number | null;
  nombreTipoAlerta?: string | null;
  fechaAlerta?: Date | string | null;
  mensajeAlerta?: string | null;
  iconoAlerta?: string | null;
  fecha?: Date | string;
  informacion?: string;
}

export const objectTarjeta = (elemento?: TarjetaInterface): TarjetaInterface => {
  let tarjeta: TarjetaInterface;
  elemento === undefined
    ? (tarjeta = {
        id: 0,
        nombre: '',
        latitud: 0,
        longitud: 0,
        direccion: '',
        imagen: '',
        icono: '',
        activo: 0,
        fechaCreacion: '',
        idModulo: 0,
        modulo: '',
        idTipoDispositivo: 0,
        tipoDispositivo: '',
        codigoTipoDispositivo: ''
      })
    : (tarjeta = {
        id: elemento.id,
        nombre: elemento.nombre,
        latitud: elemento.latitud,
        longitud: elemento.longitud,
        direccion: elemento.direccion,
        imagen: elemento.imagen,
        icono: elemento.icono,
        activo: elemento.activo,
        fechaCreacion: elemento.fechaCreacion,
        idModulo: elemento.idModulo,
        modulo: elemento.modulo,
        idTipoDispositivo: elemento.idTipoDispositivo,
        tipoDispositivo: elemento.tipoDispositivo,
        codigoTipoDispositivo: elemento.codigoTipoDispositivo,
        idDevice: elemento.idDevice,
        nombreDevice: elemento.nombreDevice,
        idTipoAlerta: elemento.idTipoAlerta,
        nombreTipoAlerta: elemento.nombreTipoAlerta,
        fechaAlerta: elemento.fechaAlerta,
        mensajeAlerta: elemento.mensajeAlerta,
        iconoAlerta: elemento.iconoAlerta,
        fecha: elemento.fecha,
        informacion: elemento.informacion
      });

  return tarjeta;
};
