import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  cajaTexto: {
    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 0,
      zIndex: 1,
      borderStyle: 'solid',
      borderWidth: '0 19px 20px 0',
      borderColor: 'transparent #f7b6eb transparent transparent',
      top: '30%',
      left: '-19px',
      marginTop: '-10px',
      filter: 'drop-shadow(0.0625rem 0 0.0625rem 0 rgba(0, 0, 0, .1))'
    },
    // layout
    position: 'relative',
    maxWidth: '20em',
    // looks
    backgroundColor: '#fff',
    padding: '1.125em 1.5em',
    fontSize: '1.25em',
    borderRadius: '.5rem',
    boxShadow: '0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2)',
    float: 'left',
    display: 'inline'
  }
}));
export default useStyles;
