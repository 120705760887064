import React, { useContext, useEffect, useState } from 'react';
import { Mapa } from '../components/mapa/Mapa';
import { InteraccionDesplegable } from '../components/tarjetaMarcador/InteraccionDesplegable';
import GISContext from '../context/GISContext';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { obtenerDispositivosGIS } from '../services/obtenerDispositivosGIS';
import { TarjetaInterface, objectTarjeta } from '../resources/interfaces/interfaceTarjeta';
import ControlContext from '../../../../context/control/ControlContext';
import useStyles from './styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Box } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useHasError } from '../../../error/hooks/useHasError';
import { mapStyle } from '../components/mapa/googleMapStyles';
import UserContext from '../../../../context/UserContext';
import SmartTeliaBackdropContext from '../../../../context/SmartTeliaBackdropContext';
import { comprobadorPermisosDispositivos } from '../../../../context/DatosContext';

const file = 'Gis';

/**
 * El contenedor del GIS y sus componentes adicionales como el filtro, aquí cargamos los datos de inicio y mostramos los distintos filtros o componentes que necesite.
 */
export function Gis(props: { isHome?: boolean; markers?: any }): JSX.Element {
  const { handleError } = useHasError();
  const { setDatosGIS, datosGIS, datosGISFiltrados, codTipoDispositivo } = useContext(GISContext);
  const { moduloSeleccionado, tieneAcceso, comprobarAcceso } = useContext(ControlContext);
  const classes = useStyles();
  const [datosTarjeta, setDatosTarjeta] = useState<TarjetaInterface>(objectTarjeta());
  const [ocultarTarjeta, setOcultarTarjeta] = useState<boolean>(false);
  const [markers, setMarkers] = useState<dispositivo[]>();
  const [divStyle, setDivStyle] = useState(mapStyle());
  const { datosUsuarioContext } = useContext(UserContext);
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  useEffect(() => {
    const datosGIS = async () => {
      setOpenSmartTeliaBackdrop(true);
      const respuesta = await obtenerDispositivosGIS(moduloSeleccionado);
      setOpenSmartTeliaBackdrop(false);
      let resGIS: any = respuesta.data;

      if (datosUsuarioContext.dispositivos.length > 0 && respuesta.data.length > 0) {
        resGIS = [];
        resGIS = comprobadorPermisosDispositivos(
          respuesta.data,
          'dispositivosGIS',
          datosUsuarioContext.dispositivos,
          moduloSeleccionado
        );
      }

      setMarkers(props.markers ?? resGIS);
      setDatosGIS(props.markers ?? resGIS);

      if (respuesta.error.isAxiosError) {
        handleError(`[${file}][${datosGIS.name}]`, respuesta.error);
      }
    };
    datosGIS();
  }, [moduloSeleccionado]);

  useEffect(() => {
    if (datosGIS.length !== 0 && datosGISFiltrados.length === 0) {
      setMarkers(datosGIS);
    } else if (datosGISFiltrados.length > 0) {
      setMarkers(datosGISFiltrados);
    }
  }, [datosGIS, datosGISFiltrados]);

  useEffect(() => {
    if (datosTarjeta.id !== 0) {
      setOcultarTarjeta(true);
    }
  }, [datosTarjeta]);

  function abrirCerrarTarjeta() {
    setOcultarTarjeta(!ocultarTarjeta);
  }

  useEffect(() => {
    ocultarTarjeta
      ? setDivStyle({
          ...divStyle,
          width: '72.5%'
        })
      : setDivStyle({
          ...divStyle,
          width: '100%'
        });
  }, [ocultarTarjeta]);

  return (
    <>
      <>
        {datosTarjeta.id !== 0 ? (
          <IconButton
            accessKey="a"
            aria-label="abrirTarjeta"
            className={classes.botonTarjetaCerrada}
            onClick={abrirCerrarTarjeta}
            disabled={ocultarTarjeta}
            hidden={ocultarTarjeta}
          >
            {ocultarTarjeta ? <></> : <NavigateBeforeIcon color="primary" />}
          </IconButton>
        ) : (
          <></>
        )}
      </>

      {markers !== undefined ? (
        <>
          <Mapa
            aria-label="marker"
            markers={markers as dispositivo[]}
            datosTarjeta={datosTarjeta}
            setDatosTarjeta={setDatosTarjeta}
            divStyle={{
              height: '86%',
              width: '99%',
              transition: 'width 0.5s'
            }}
            moduloSeleccionado={moduloSeleccionado}
            codTipoDispositivo={codTipoDispositivo}
          ></Mapa>

          <Slide direction="left" in={ocultarTarjeta} timeout={500}>
            <Box className={classes.gridTarjeta}>
              <IconButton
                accessKey="c"
                aria-label="cerrarTarjeta"
                className={classes.botonTarjetaAbierta}
                onClick={abrirCerrarTarjeta}
                disabled={!ocultarTarjeta}
                hidden={!ocultarTarjeta}
              >
                <NavigateNextIcon color="primary" />
              </IconButton>
              <InteraccionDesplegable
                datosTarjeta={datosTarjeta}
                setDatosTarjeta={setDatosTarjeta}
                tieneAcceso={tieneAcceso}
                comprobarAcceso={comprobarAcceso}
                abrirCerrarTarjeta={abrirCerrarTarjeta}
              />
            </Box>
          </Slide>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
