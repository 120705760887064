import React, { useState, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Fab, Grid, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import { DeviceManager } from './DeviceManager';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import { buscarRegistro } from '../common/table/buscarRegistro';
import {
  ContenedorActivado,
  ContenedorDesactivado,
  ContenedorElementos
} from '../common/table/contenedorElementos';
import PaginationRounded from '../common/table/Paginacion';
import Table from '../common/table/Table';
import { dispositivo } from '../dispositivos/resources/interfaces/interfaceDispositivo';
import ControlContext from '../../context/control/ControlContext';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { dictionary_devices, dictionary_plugins } from '../../resources/enums/plainText';
import { State } from './hooks/useReducerDevice/resources/types/typeState';
import { Events } from './hooks/useReducerDevice/resources/types/typeEvents';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { enumTabla } from '../common/table/resources/enumTabla';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { PluginsDisponibles } from '../plugins/pluginsDisponibles/PluginsDisponibles';
import { PluginsInstalados } from '../plugins/pluginsInstalados/PluginsInstalados';

export function TablaDevices({ state, events }: { state: State; events: Events }): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { setControl } = useContext(ControlContext);
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  function confirmarEliminarAlert(): void {
    confirmarEliminar(state.devicesTabla, events.setDevicesTabla, rutasServicios.DEVICES);
  }

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    {
      name: 'logotipoPlugin',
      label: 'Logotipo del plugin',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (
          value: string,
          tableMeta: {
            tableData: dispositivo[];
            currentTableData: { index: number }[];
            rowIndex: number;
          }
        ): JSX.Element => {
          const { objeto } = buscarRegistro(
            tableMeta.tableData,
            tableMeta.currentTableData[tableMeta.rowIndex].index
          );
          if (value === '') {
            return <Avatar variant="rounded">{objeto.nombrePlugin.charAt(0).toUpperCase()}</Avatar>;
          } else {
            return <Avatar variant="rounded" alt={objeto.nombrePlugin} src={value} />;
          }
        }
      }
    },
    { name: 'nombrePlugin', label: 'Nombre del plugin' },
    { name: 'nombreTipoDevice', label: 'Nombre del tipo de device' },
    { name: 'nombre', label: 'Nombre del device' },
    { name: 'descripcion', options: { display: 'excluded' } },
    { name: 'tiempoRefresco', options: { display: 'excluded' } },
    { name: 'tiempoInactividad', options: { display: 'excluded' } },
    { name: 'idTipoDevice', options: { display: 'excluded' } },
    { name: 'idPlugin', options: { display: 'excluded' } },
    { name: 'descripcionPlugin', options: { display: 'excluded' } },
    { name: 'versionPlugin', options: { display: 'excluded' } },
    {
      name: 'activo',
      label: 'Activo',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (value: number): JSX.Element => {
          if (value === 1) return <ContenedorActivado />;
          else return <ContenedorDesactivado />;
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.botonesTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_devices.DEVICES}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (state.devicesTabla.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <DeviceManager
            deviceSeleccionado={state.devicesTabla[cellMeta.dataIndex]}
            pluginSeleccionado={state.pluginSeleccionado}
            key={enumComponentes.INFORMACION_DEVICE + state.keySecond + state.keyThird}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((g) => state.devicesTabla[g.dataIndex]));
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  /**
   * Título con opción de volver a atrás según si estabamos en plugins instalados o disponibles
   * @returns {JSX.Element}
   */
  const Titulo = (): JSX.Element => {
    return (
      <div className={classes.tituloTabla}>
        {state.pluginSeleccionado?.id ? (
          <VolverAtras
            backTo={
              state.keyThird === dictionary_plugins.INSTALADO ||
              state.keySecond === dictionary_plugins.INSTALADO ? (
                <PluginsInstalados key={enumComponentes.PLUGINS_INSTALADOS + state.keySecond} />
              ) : (
                <PluginsDisponibles key={enumComponentes.PLUGINS} />
              )
            }
          />
        ) : (
          <></>
        )}
        <Typography component="h6" variant="h6" key={dictionary_devices.DEVICES}>
          {dictionary_devices.DEVICES}
          {state.pluginSeleccionado?.id
            ? ` de ${state.pluginSeleccionado.nombre.toUpperCase()}`
            : ''}
        </Typography>
      </div>
    );
  };

  return (
    <div>
      {!openSmartTeliaBackdrop ? (
        <Table
          title={<Titulo />}
          columns={columns}
          data={state.devicesTabla}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      <div aria-label="nuevo" accessKey="a" tabIndex={0}>
        <Tooltip title={dictionary_devices.CREAR_DEVICE} arrow>
          <Fab
            aria-label="crear-device"
            color="secondary"
            className={classes.fabBoton}
            onClick={() =>
              setControl(
                <DeviceManager
                  pluginSeleccionado={state.pluginSeleccionado}
                  key={enumComponentes.CREAR_DEVICE + state.keySecond + state.keyThird}
                />
              )
            }
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </div>
  );
}
