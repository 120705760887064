import {
  Grid,
  Divider,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropTargetCard } from '../dropTargetCard/DropTargetCard';
import { Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { dictionary_generic } from '../../../../resources/enums/plainText';
import Menu from '@mui/material/Menu';
import { ContextMenuOptions } from '../../resources/configuratorEnums';
import { useConfiguratorStructure } from './useConfiguratorStructure';
import ConfiguratorContext from '../../context/ConfiguratorContext';
import { BackdropTarjetasModulos } from '../../../home/homeModulos/common/BackdropTarjetasModulos';

/**
 * Genera la estructura completa del configurador dependiendo del módulo.
 * @param {Modulo} modulo El módulo para filtrar la estructura.
 * @returns {JSX.Element} La estructura de cada módulo
 */
export function ConfiguratorStructure({ openSpinner }: { openSpinner: boolean }): JSX.Element {
  const {
    tipoTarjeta,
    changeAvailableCard,
    saveConfiguration,
    cards,
    editCard,
    handleOpenDialog,
    handleCloseDialog,
    dialogState,
    handleContextMenu,
    handleCloseContextMenu,
    contextMenu,
    deleteConfiguration
  } = useConfiguratorStructure();
  const {
    tabState: [tab],
    selectedStructure,
    copySelectedStructure
  } = useConfiguratorContext();

  const { userDevicesConfiguration, openBackdrop } = React.useContext(ConfiguratorContext);
  const [userDives] = userDevicesConfiguration;
  function isEmptyConfiguration() {
    if (copySelectedStructure.current.filter((s) => s.props.tarjeta != null).length >= 1) {
      handleOpenDialog(ContextMenuOptions.save);
    } else {
      saveConfiguration();
    }
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Grid container direction={'row'} justifyContent={'end'}>
          <Grid
            container
            flexDirection={'row'}
            spacing={2}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item xs={12} md={2}>
              <List
                subheader={
                  <ListSubheader component="div" id="list-cardTypes">
                    Tipos de tarjeta
                  </ListSubheader>
                }
              >
                {cards.map((item) => (
                  <ListItemButton
                    key={item.cardTypeId}
                    selected={item.cardTypeId === tipoTarjeta.cardTypeId}
                    onClick={() => changeAvailableCard(item)}
                    disabled={userDives.length === 0 || openBackdrop}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
              </List>
            </Grid>

            <Grid item xs={10}>
              {tipoTarjeta.component ? (
                tipoTarjeta.component
              ) : (
                <Box
                  display={'flex'}
                  sx={{
                    backgroundColor: 'lightgray',
                    height: '350px',
                    borderRadius: '15px',
                    opacity: 0.6,
                    position: 'relative'
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {!openBackdrop ? (
                    <Typography variant="h5" color="primary">
                      {userDives.length > 0
                        ? 'Selecciona un tipo de tarjeta'
                        : 'No tiene dispositivos'}
                    </Typography>
                  ) : (
                    <BackdropTarjetasModulos open={openBackdrop} />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>

          <Divider
            orientation="horizontal"
            style={{
              margin: '10px 0px',
              backgroundColor: '#919394',
              width: '100%',
              height: '3px'
            }}
          />
          <Tooltip title={'Eliminar configuración'}>
            <IconButton onClick={() => handleOpenDialog(ContextMenuOptions.delete_configuration)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Guardar configuración'}>
            <IconButton onClick={isEmptyConfiguration}>
              <Save />
            </IconButton>
          </Tooltip>

          <Grid
            container
            item
            xs={12}
            flexDirection={'row'}
            spacing={2}
            position={'relative'}
            minHeight={'372px'}
          >
            <BackdropTarjetasModulos open={openSpinner} />
            {!openSpinner ? (
              selectedStructure.map((item: JSX.Element, index: number) => {
                if (typeof item.type === 'function') {
                  return React.cloneElement(item, {
                    key: `${tab.selectedModule}-${index}`,
                    handleCardClick: handleContextMenu
                  });
                }
                if (item.props['data-visible'])
                  return <DropTargetCard posicion={index} key={`${tab.selectedModule}-${index}`} />;
              })
            ) : (
              <></>
            )}
          </Grid>
          <Dialog
            open={dialogState.openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogState.dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogState.dialogContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (dialogState.option === ContextMenuOptions.save) {
                    saveConfiguration();
                  } else if (dialogState.option === ContextMenuOptions.delete_configuration) {
                    deleteConfiguration();
                  } else {
                    editCard();
                  }
                }}
                autoFocus
              >
                {dictionary_generic.ACEPTAR}
              </Button>
              <Button onClick={handleCloseDialog}>{dictionary_generic.CANCELAR}</Button>
            </DialogActions>
          </Dialog>

          <Menu
            open={contextMenu !== null}
            onContextMenu={() => ''}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            style={{ width: '20%' }}
          >
            {contextMenu && contextMenu.items.map((item) => item)}
          </Menu>
        </Grid>
      </DndProvider>
    </>
  );
}
