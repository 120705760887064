import { useContext, useRef } from 'react';
import {
  obtenerAlertasDispositivo,
  obtenerAlertasModulo,
  obtenerAlertasTipoAlerta,
  obtenerAlertasTipoError,
  obtenerAlertasPendientesResolver,
  obtenerAlertasPendientesResolverPorModulo,
  resolverAlertaService,
  obtenerTodasAlertasPendientesResolver
} from '../services/servicioAlertas';
import { Rango } from '../../../components/graficas/resources/enums/enumRango';
import ControlContext from '../../../context/control/ControlContext';
import { Modulos } from '../../../resources/enums/enumModulo';
import { comprobarSessionStorage } from '../../../functions/functions';

export function useAlertas(): {
  obtenerAlertasByDispositivo(
    servicio: string,
    idDispositivo: number,
    range: Rango,
    fechaDesde: string,
    fechaHasta: string,
    componente: string
  ): Promise<any>;
  obtenerAlertasByModulo(
    servicio: string,
    range: Rango,
    fechaDesde: string | undefined,
    fechaHasta: string | undefined
  ): Promise<any>;
  obtenerAlertasByTipoAlerta(servicio: string, idTipoAlerta: number): Promise<any>;
  obtenerAlertasByTipoError(
    servicio: string,
    range: Rango,
    fechaDesde: string | undefined,
    fechaHasta: string | undefined
  ): Promise<any>;
  obtenerAlertasSinResolver(servicio: string): Promise<any>;
  obtenerAlertasSinResolverPorModulo(servicio: string, signal: AbortSignal): Promise<any>;
  resolverAlerta(
    servicio: string,
    idAlerta: number,
    mensaje: string,
    signal: AbortSignal
  ): Promise<any>;
  obtenerTodasAlertasSinResolver(servicio: string): Promise<any>;
} {
  const listaDatos = useRef();
  const { moduloSeleccionado } = useContext(ControlContext);

  /**
   * Función que obtiene todas las alertas filtrados por dispositivo
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { number } idDispositivo id del dispositivo cuyas alertas queremos obtener
   * @param { Rango } range enumerado que hace referencia al rango de fechas
   * @param { string | undefined } fechaDesde fecha desde la cual hay que filtrar los datos
   * @param { string | undefined } fechaHasta fecha hasta la cual hay que filtrar los datos
   * @return { React.MutableRefObject  | [] } listaDatos lista que contiene el resultado de la petición o un array vacío
   */
  async function obtenerAlertasByDispositivo(
    servicio: string,
    idDispositivo: number,
    range: Rango,
    fechaDesde?: string | undefined,
    fechaHasta?: string | undefined,
    componente?: string | undefined
  ): Promise<any> {
    const respuesta: any = await obtenerAlertasDispositivo(
      servicio,
      idDispositivo,
      range,
      fechaDesde,
      fechaHasta,
      componente
    );
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   *  Función que obtiene las alertas de un modulo
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { number } idModulo id del modulo cuyas alertas queremos obtener
   * @param { Rango } range enumerado que hace referencia al rango de fechas
   * @param { string | undefined } fechaDesde fecha desde la cual hay que filtrar los datos
   * @param { string | undefined } fechaHasta fecha hasta la cual hay que filtrar los datos
   * @return { React.MutableRefObject  | []} listaDatos lista que contiene el resultado de la petición o un array vacío
   */
  async function obtenerAlertasByModulo(
    servicio: string,
    range: Rango,
    fechaDesde?: string | undefined,
    fechaHasta?: string | undefined
  ): Promise<any> {
    const storage =
      Modulos[moduloSeleccionado] + `:${servicio}:${range}:${fechaDesde}:${fechaHasta}`;
    const respuesta: any = await obtenerAlertasModulo(
      servicio,
      moduloSeleccionado,
      range,
      comprobarSessionStorage(storage),
      fechaDesde,
      fechaHasta
    );
    return respuesta;
  }

  /**
   * Función que obtiene las alertas filtrando por el tipo de alerta
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idTipoAlerta id del tipo de alerta por el que queremos filtrar
   * @returns { React.MutableRefObject | [] }listaDatos lista que contiene el resultado de la petición o un array vacío.
   */
  async function obtenerAlertasByTipoAlerta(servicio: string, idTipoAlerta: number): Promise<any> {
    const respuesta: any = await obtenerAlertasTipoAlerta(servicio, idTipoAlerta);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.resut;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que devuelve una lista de alertas de tipo error
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { Rango } range enumerado que hace referencia al rango de fechas
   * @param { string | undefined } fechaDesde fecha desde la cual hay que filtrar los datos
   * @param { string | undefined } fechaHasta fecha hasta la cual hay que filtrar los datos
   * @return { React.MutableRefObject  | [] } listaDatos lista que contiene el resultado de la petición o un array vacío
   */
  async function obtenerAlertasByTipoError(
    servicio: string,
    range: Rango,
    fechaDesde?: string | undefined,
    fechaHasta?: string | undefined
  ): Promise<any> {
    const respuesta: any = await obtenerAlertasTipoError(servicio, range, fechaDesde, fechaHasta);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que devuelve una lista de alertas de tipo error o warning que están sin resolver y sin filtrar por modulos
   * @param { string } servicio servicio al que vamos a llamar
   * @returns { romise<any> } lista de las alertas pendientes de resolver de todos los modulos
   */
  async function obtenerAlertasSinResolver(servicio: string): Promise<any> {
    const respuesta: any = await obtenerAlertasPendientesResolver(servicio);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }
  /**
   * Función que devuelve una lista de alertas de tipo error o warning que están sin resolver y sin filtrar por modulos
   * @param { string } servicio servicio al que vamos a llamar
   * @returns { romise<any> } lista de las alertas pendientes de resolver de todos los modulos
   */
  async function obtenerTodasAlertasSinResolver(servicio: string): Promise<any> {
    const respuesta: any = await obtenerTodasAlertasPendientesResolver(servicio);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que devuelve una lista de alertas de tipo error o warning que están sin resolver y sin filtrar por modulos
   * @param { string } servicio servicio al que vamos a llamar
   * @returns { romise<any> } lista de las alertas pendientes de resolver de todos los modulos
   */
  async function obtenerAlertasSinResolverPorModulo(
    servicio: string,
    signal: AbortSignal
  ): Promise<any> {
    const respuesta: any = await obtenerAlertasPendientesResolverPorModulo(
      servicio,
      moduloSeleccionado,
      signal
    );
    if (!respuesta.isAxiosError && respuesta.data !== undefined) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Esta función llama al servicio eliminarUnoVariosRegistros para eliminar uno o varios registros
   *
   * @param {string } servicio ruta del servicio al que vamos a lanzar la petición de eliminar
   * @param { number } idAlerta idAlerta a resolver
   * @param { string } mensaje mensaje de resolución
   * @param { AbortSignal } signal signal de axios para poder cancelar la petición
   */
  async function resolverAlerta(
    servicio: string,
    idAlerta: number,
    mensaje: string,
    signal: AbortSignal
  ): Promise<void> {
    const respuesta: any = await resolverAlertaService(servicio, idAlerta, mensaje, signal);
    return respuesta;
  }

  return {
    obtenerAlertasByDispositivo,
    obtenerAlertasByModulo,
    obtenerAlertasByTipoAlerta,
    obtenerAlertasByTipoError,
    obtenerAlertasSinResolver,
    obtenerAlertasSinResolverPorModulo,
    resolverAlerta,
    obtenerTodasAlertasSinResolver
  };
}
