import React from 'react';
import styled from '@mui/styles/styled';
import { Tooltip, tooltipClasses, Theme, TooltipProps } from '@mui/material';

/**
 * Tooltip con estilos por defecto pero se puede personalizar pasándole un objeto.
 * @param { TooltipProps } props - Propiedades de los tooltip de material-ui
 * @param customStyle - Es opcional, para pasarle un objeto con el estilo deseado.(Si no se aplicarán los valores por defecto)
 * @returns  { JSX.Element } Tooltip con el estilo personalizado
 */

export const CustomTooltip = styled(
  (props: TooltipProps): JSX.Element => <Tooltip {...props} classes={{ popper: props.className }} />
)(
  ({
    theme,
    customStyle = {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15
    }
  }: {
    theme: Theme;
    customStyle?: any;
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      ...customStyle
    }
  })
);
