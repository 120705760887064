import * as React from 'react';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { rutasServicios } from '../../../../resources/enums/enumRutasServicios';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import UserContext from '../../../../context/UserContext';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { CardProperties, reducerStateWatercard } from '../../types/typesCardConfigurator';
import { TIPOS_DISPOSITIVO } from '../../../tiposDispositivo/resources/enum/enumTipoDispositivo';
import { tiposDispositivo } from '../../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { AgregateCode } from '../../../../resources/enums/enumAggregateCode';
import ConfiguratorContext from '../../context/ConfiguratorContext';

enum typeActions {
  SET_DATA = 'setData'
}

type setData = {
  type: typeActions.SET_DATA;
  payload: Record<string, unknown>;
};

const initialCardProperties: CardProperties = {
  tarjeta: {
    titulo: 'Distribución de caudalímetros',
    codigoTipoDispositivo: null,
    range: null,
    codigoFuncionAgregada: AgregateCode.SUM,
    codigoTipoMedida: null,
    mostrarGrafica: false,
    mostrarSemaforo: false,
    servicio: 'configuradorTarjetas/waterDistribution',
    position: null,
    idTipoTarjeta: null
  },
  dispositivos: null,
  semaforo: null
};

const initialCardObject: reducerStateWatercard = {
  dispositivosDisponiblesEntrada: [],
  dispositivosDisponiblesSalida: [],
  dispositivosElegidosEntrada: [],
  dispositivosElegidosSalida: [],
  cardReady: false,
  dataObject: initialCardProperties
};

export function useDistributionWaterCard(modulo: number, cardProps: CardProperties | null) {
  const reducer = (obj: reducerStateWatercard, action: setData) => {
    return { ...obj, ...action.payload };
  };
  const { userDevicesConfiguration } = React.useContext(ConfiguratorContext);
  const [userDives] = userDevicesConfiguration;

  const [obj, dispatch] = React.useReducer(reducer, {
    ...initialCardObject,
    dataObject: cardProps
      ? { ...cardProps, tarjeta: { ...cardProps.tarjeta, dato: undefined } }
      : initialCardProperties
  });
  const { dataObject } = obj;

  const { controllerRequest } = useControlador();

  function handleCardTitle(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, titulo: event.target.value }
        }
      }
    });
  }

  function handleAutoCompleteInputDevices(
    dispositivos: Array<{ canal: string; id: number; nombre: string; idCanal: number }>
  ) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dispositivosElegidosEntrada: dispositivos,
        dataObject: {
          ...dataObject,
          dispositivos: [...obj.dispositivosElegidosSalida, ...dispositivos]
        }
      }
    });
  }

  function handleAutoCompleteOutputDevices(
    dispositivos: Array<{ canal: string; id: number; nombre: string; idCanal: number }>
  ) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dispositivosElegidosSalida: dispositivos,
        dataObject: {
          ...dataObject,
          dispositivos: [...obj.dispositivosElegidosEntrada, ...dispositivos]
        }
      }
    });
  }

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_TODOS_DATOS,
      payload: { servicio: rutasServicios.TIPOS_DISPOSITIVOS }
    }).then((response: Array<tiposDispositivo>) => {
      const waterDeviceTypes = response.filter(
        (item) =>
          parseInt(item.codigo as string) === TIPOS_DISPOSITIVO.CAUDALIMETRO_ENTRADA_CAUDAL ||
          parseInt(item.codigo as string) === TIPOS_DISPOSITIVO.CAUDALIMETRO_CAUDAL
      );

      const devices = waterDeviceTypes.map((item) =>
        controllerRequest({
          type: action.OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO,
          payload: {
            servicio: rutasServicios.DISPOSITIVOS,
            idTipoDispositivo: item.id as number
          }
        })
      );

      Promise.all(devices).then((deviceRes: any) => {
        const allDevices = deviceRes.flat();
        const dispositivosFiltradosUser = userDives;
        const allFilteredDevices: Array<dispositivo> = allDevices.filter((p) => {
          return (
            dispositivosFiltradosUser.filter((y: any) => (y.id | y.idDispositivo) === p.id).length >
            0
          );
        });

        if (cardProps && cardProps.dispositivos) {
          const cardDispositivo = cardProps.dispositivos;
          const filterDevices = allDevices.filter((p) => {
            return cardDispositivo.filter((y: any) => y.id === p.id).length > 0;
          });
          dispatch({
            type: typeActions.SET_DATA,
            payload: {
              dispositivosElegidosSalida: filterDevices.filter(
                (outputDevice) =>
                  outputDevice.idTipoDispositivo ===
                  waterDeviceTypes.find(
                    (deviceType) =>
                      parseInt(deviceType.codigo as string) ===
                      TIPOS_DISPOSITIVO.CAUDALIMETRO_CAUDAL
                  )?.id
              ),
              dispositivosElegidosEntrada: filterDevices.filter(
                (outputDevice) =>
                  outputDevice.idTipoDispositivo ===
                  waterDeviceTypes.find(
                    (deviceType) =>
                      parseInt(deviceType.codigo as string) ===
                      TIPOS_DISPOSITIVO.CAUDALIMETRO_ENTRADA_CAUDAL
                  )?.id
              )
            }
          });
        }

        dispatch({
          type: typeActions.SET_DATA,
          payload: {
            dispositivosDisponiblesSalida: allFilteredDevices.filter(
              (outputDevice) =>
                outputDevice.idTipoDispositivo ===
                waterDeviceTypes.find(
                  (deviceType) =>
                    parseInt(deviceType.codigo as string) === TIPOS_DISPOSITIVO.CAUDALIMETRO_CAUDAL
                )?.id
            ),
            dispositivosDisponiblesEntrada: allFilteredDevices.filter(
              (outputDevice) =>
                outputDevice.idTipoDispositivo ===
                waterDeviceTypes.find(
                  (deviceType) =>
                    parseInt(deviceType.codigo as string) ===
                    TIPOS_DISPOSITIVO.CAUDALIMETRO_ENTRADA_CAUDAL
                )?.id
            )
          }
        });
      });
    });
  }, []);

  React.useEffect(() => {
    if (obj.dispositivosElegidosEntrada.length > 0 && obj.dispositivosElegidosSalida.length > 0) {
      dispatch({
        type: typeActions.SET_DATA,
        payload: {
          cardReady: true
        }
      });
    } else {
      dispatch({
        type: typeActions.SET_DATA,
        payload: {
          cardReady: false
        }
      });
    }
  }, [obj.dispositivosElegidosEntrada, obj.dispositivosElegidosSalida]);

  return {
    handleAutoCompleteOutputDevices,
    handleAutoCompleteInputDevices,
    obj,
    controllerRequest,
    handleCardTitle
  };
}
