import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium',
    marginBottom: '10px'
  },
  cajas: {
    marginBottom: '15px'
  },
  gridLista: {
    marginBottom: '10px'
  },
  buttonClose: {
    width: '10%',
    height: '10%',
    marginBottom: '5px'
  },
  buttonAdd: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  gridSwitch: {
    marginTop: '5px'
  }
}));
export default useStyles;
