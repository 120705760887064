import React, { useContext, useRef, useState } from 'react';
import { dictionary_alertas, dictionary_generic } from '../../resources/enums/plainText';
import PaginationRounded from '../common/table/Paginacion';
import Table from '../common/table/Table';
import { dictionary_dispositivos } from '../../resources/enums/plainText';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import TimeLine from './TimeLine';
import { DialogoVerMas } from './DialogoVerMas';
import { useReducerAlertas } from './hooks/useReducerAlertas';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import { BotonTablaFilaVer } from '../common/table/BotonesTablaFila';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import ControlContext from '../../context/control/ControlContext';
import { seleccionarIcono } from './functions/functions';
import { formatToDayStart } from '../../functions/functions';
import { dispositivo } from '../dispositivos/resources/interfaces/interfaceDispositivo';
import { comprobadorPermisosDispositivos } from '../../context/DatosContext';
import UserContext from '../../context/UserContext';
import { useTheme } from '@mui/material/styles';

export function AlertasPendientesORegistradas(): JSX.Element {
  const theme = useTheme();
  const { controllerRequest } = useControlador();
  const { state, setDispositivoSeleccionado, setOpenVerMas, cargarLog } = useReducerAlertas(
    enumComponentes.ALERTAS_PENDIENTES
  );
  const { datosUsuarioContext } = React.useContext(UserContext);
  const [alertasPendientes, setAlertasPendientes] = useState([]);
  const { moduloSeleccionado } = useContext(ControlContext);
  const [id, setId] = useState(null);
  const { control, openVerMas, log, dispositivoSeleccionado } = state;

  const handleClose = () => {
    setOpenVerMas(false);
  };
  const rowsSelected = {
    row: useRef<any>(),
    dataIndex: useRef<number>()
  };

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'icono', label: 'Tipo' },
    { name: 'nombre', label: 'Nombre' },
    { name: 'direccion', label: 'Dirección' },
    { name: 'ultimaAlarma', label: 'Última Alarma' },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ paddingRight: '8px' }}
            >
              <ContenedorElementos
                elemento={<BotonTablaFilaVer />}
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase={'botones'}
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    textLabels: {
      body: {
        noMatch: dictionary_generic.NO_SE_HAN_ENCONTRADO_REGISTROS,
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column: { label: any }) => `Ordenar por ${column.label}`
      }
    },

    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    selectableRows: false,
    onCellClick: (colData: any, cellMeta: { dataIndex: number }) => {
      if ((control.key as string) === enumComponentes.ALERTAS_REGISTRADAS) {
        if (alertasPendientes.length)
          setDispositivoSeleccionado(alertasPendientes[cellMeta.dataIndex]);
      } else {
        if (alertasPendientes.length)
          setDispositivoSeleccionado(alertasPendientes[cellMeta.dataIndex]);
      }
    },
    setRowProps: (row: any, index: any) => {
      return { style: { backgroundColor: index === id ? '#E5E3E3' : '' } };
    },
    onRowClick: (rowData: any, rowMetaData: any) => {
      const id = rowMetaData.dataIndex;
      setId(id);
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
          tableFooter={true}
        />
      );
    }
  };

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: 'dispositivos/todos/modulo/alertas',
        object: { idModulo: moduloSeleccionado }
      }
    }).then((response) => {
      let listaPendientes = response.map((dispositivo: any) => {
        return {
          idDispositivo: dispositivo.id,
          icono: seleccionarIcono(dispositivo.idTipoAlerta as number).icono,
          nombre: dispositivo.nombre,
          ultimaAlarma: formatToDayStart(
            (dispositivo.fechaAlerta.toString() as dispositivo['fechaAlerta']) as string
          )
        };
      });
      listaPendientes = comprobadorPermisosDispositivos(
        listaPendientes,
        'dispositivosAlertas',
        datosUsuarioContext.dispositivos,
        moduloSeleccionado
      );
      setAlertasPendientes(listaPendientes);
    });
  }, []);

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={4}>
        <Table
          title={dictionary_dispositivos.DISPOSITIVOS}
          columns={columns}
          data={alertasPendientes}
          options={options}
        ></Table>
      </Grid>

      {dispositivoSeleccionado !== undefined ? (
        <Grid item xs={8} style={{ textAlign: 'center' }}>
          <TimeLine log={log} />
          <Tooltip title={dictionary_alertas.VER_MAS_ALERTAS}>
            <Button variant="contained" color="secondary" onClick={() => setOpenVerMas(true)}>
              {dictionary_alertas.VER_MAS_ALERTAS}
            </Button>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item xs={8} style={{ textAlign: 'center', margin: 'auto' }}>
          <Typography paragraph width={'100%'}>
            SELECCIONE UN DISPOSITIVO PARA VER LAS ALERTAS
          </Typography>
        </Grid>
      )}

      <DialogoVerMas
        open={openVerMas}
        setOpen={setOpenVerMas}
        nombreDispositivo={dispositivoSeleccionado?.nombre}
        cargarLog={cargarLog}
      />
    </Grid>
  );
}
