import React from 'react';

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Step, StepLabel, Stepper, Theme } from '@mui/material';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles, createStyles } from '@mui/styles';
import { dictionary_asistente_permisos } from '../../../resources/enums/plainText';
import { ContenedorCrearCondicion } from '../../condiciones/dialogos/ContenedorCrearCondicion';
import { DialogoDatosRegla } from './DialogoDatosRegla';
import useStylesTarjetas from '../../reglas/stylesTarjetas';
import { DialogoSeleccionTipoAccion } from './DialogoSeleccionTipoAccion';
import { HelpOutline } from '@mui/icons-material';

import { TextBoxTelia } from '../../common/textboxTelia/TextBoxTelia';

const useStylesDialogo = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

export function DialogoCrearRegla(props: {
  state: any;
  events: any;
  stateCondiciones: any;
}): JSX.Element {
  enum title {
    'Crear regla',
    'Añadir condiciones',
    'Seleccionar tipo de acción',
    'Añadir acción'
  }
  const classes = useStylesTarjetas();
  const classesDialogo = useStylesDialogo();
  const { state, stateCondiciones, events } = props;
  const { asistenteReglas } = state;
  const componentesDialogo: any = {
    0: <DialogoDatosRegla state={state} events={events} />,
    1: (
      <ContenedorCrearCondicion state={state} events={events} stateCondiciones={stateCondiciones} />
    ),
    2: <DialogoSeleccionTipoAccion state={state} events={events} />,
    3: state.panelAccionActivo
  };
  const mensajesTelia: any = {
    0: 'Crear regla. Rellene el nombre y también la descripción.',
    1: 'Seleccione el tipo de condición: Temporal si quiere activar la regla a cierta hora, y de dispositivo si quiere utilizar algún sensor.',
    2: 'Seleccione el tipo de actuación que se ejecutará cuando se cumplan todas tus condiciones.',
    3: state.mensajeTelia
  };

  return (
    <Dialog open={state.estadoDialogo} onClose={events.cerrarDialogoReglas}>
      <DialogTitle>
        {title[asistenteReglas.activeStep]}
        <IconButton
          aria-label="close"
          onClick={events.cerrarDialogoReglas}
          className={classes.botonCerrarDialogo}
        >
          <CloseIcon />
        </IconButton>
        <IconButton aria-label="help" color="secondary" onClick={events.handleButtonTelia}>
          <HelpOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {state.estadoTelia && <TextBoxTelia texto={mensajesTelia[asistenteReglas.activeStep]} />}
      </DialogContent>
      <DialogContent>{componentesDialogo[asistenteReglas.activeStep]}</DialogContent>
      <DialogContent>
        <Stepper activeStep={asistenteReglas.activeStep} color="secondary">
          {asistenteReglas.steps.map((label: string, index: number) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div style={{ marginTop: '10px' }}>
            <Button
              variant="contained"
              disabled={asistenteReglas.activeStep === 0}
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={dictionary_asistente_permisos.BOTON_ATRAS}
            >
              {dictionary_asistente_permisos.BOTON_ATRAS}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={
                asistenteReglas.activeStep === asistenteReglas.steps.length - 1 ||
                state.listaAcciones.length > 0
                  ? dictionary_asistente_permisos.BOTON_FINALIZAR
                  : dictionary_asistente_permisos.BOTON_SIGUIENTE
              }
            >
              {asistenteReglas.activeStep === asistenteReglas.steps.length - 1 ||
              state.listaAcciones.length > 0
                ? dictionary_asistente_permisos.BOTON_FINALIZAR
                : dictionary_asistente_permisos.BOTON_SIGUIENTE}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
