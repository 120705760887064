import React from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import {
  dictionary_generic,
  dictionary_usuarios,
  enumLabel
} from '../../resources/enums/plainText';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { CheckPassword } from './common/CheckPassword';
import useStyles from './styles';

export function PerfilUpdate({ events, state }: { events: any; state: any }): JSX.Element {
  const classes = useStyles();
  const { usuario, grow } = state;

  return (
    <form id="formDispositivo" onSubmit={events.handleSubmitEditar} autoComplete="off">
      <Grid container spacing={2}>
        <Box display="flex" marginBottom="10px">
          <IconButton color="secondary" onClick={events.abrirDialogoPerfil}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" className={classes.tituloFormulario}>
            {dictionary_usuarios.MODIFICAR_PERFIL}
          </Typography>
        </Box>
        <Grid item container xs={12}>
          <Grid
            item
            container
            sm={12}
            justifyContent="flex-start"
            spacing={2}
            className={classes.cajas}
          >
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE}
                id="nombre"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.nombre}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.APELLIDOS}
                id="apellidos"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.apellidos}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.DIRECCION}
                id="direccion"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.direccion}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.CODIGO_POSTAL}
                id="idCodigoPostal"
                type="number"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.idCodigoPostal}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.POBLACION}
                id="poblacion"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.poblacion}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.PROVINCIA}
                id="provincia"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.provincia}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.CORREO_ELECTRONICO}
                id="email"
                type="email"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.TELEFONO}
                id="telefono"
                type="tel"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.telefono}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_USUARIO}
                id="nombreUsuario"
                type="text"
                required
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={usuario.nombreUsuario}
                inputProps={{
                  pattern: dictionary_usuarios.PATRON_NOMBRE_USUARIO,
                  title: dictionary_usuarios.MENSAJE_NOMBRE_USUARIO
                }}
                helperText={dictionary_usuarios.AVISO_NOMBRE_USUARIO}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch checked={grow} onChange={events.handleGrow} />}
                label={enumLabel.CAMBIAR_CONTRASENA}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                position: grow ? 'relative' : 'absolute',
                visibility: grow ? 'visible' : 'hidden'
              }}
            >
              <CheckPassword state={state} events={events} />
            </Grid>
          </Grid>
          <Grid item container sm={12} spacing={2} className={classes.cajas}>
            <Grid item container justifyContent="flex-end">
              <Grid item xs={5} sm={4} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  type="submit"
                  disabled={
                    grow ? (state.newPasswordWrong || state.oldPasswordWrong ? true : false) : false
                  }
                >
                  {dictionary_generic.MODIFICAR}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          titulo={dictionary_usuarios.MODIFICAR_NOMBRE_USUARIO}
          open={state.openAviso}
          setOpen={events.abrirAlerta}
          onConfirm={events.confirmarAlerta}
        />
      </Grid>
    </form>
  );
}
