import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ANCHO_DRAWER } from '../../../pages/Main/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${ANCHO_DRAWER}px)`,
    marginLeft: ANCHO_DRAWER,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    justifyContent: 'flex-end',
    paddingRight: '10px'
  },
  menuButton: {
    position: 'absolute',
    left: 0,
    marginLeft: '10px',
    color: theme.palette.primary.main
  },
  hide: {
    display: 'none'
  },
  botonModulo: {
    color: theme.palette.secondary.main,
    fontSize: '0.4rem',
    border: 'none',
    '&:hover': {
      border: 'none'
    }
  },
  botonNavDerecha: {
    marginRight: '2.4%',
    '& svg ': {
      fontSize: '0.73rem'
    }
  },
  alertas: {
    marginBottom: '6px'
  },
  divider: {
    margin: '0 1%'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
    marginRight: '10px'
  },
  nombreUsuario: {
    background: '#f1f4f7',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    fontSize: '0.45rem'
  },
  buttonCerrarSesion: {
    marginLeft: '10px'
  }
}));
