import { useContext, useReducer, useEffect } from 'react';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';

import { objectDispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';

import {
  dictionary_error_reglas,
  dictionary_correcto_reglas
} from '../../../resources/enums/plainText';

import { reducerCondiciones } from '../resources/reducers/reducerCondiciones';
import { TipoCondicionTemporal } from '../../reglas/resources/enums/enumTipoCondicionTemporal';
import { dialogoCondiciones } from '../../reglas/resources/interfaces/interfaceDialogoCondiciones';
import {
  condicionCompleta,
  objectCondicionCompleta
} from '../../reglas/resources/interfaces/interfaceCondicionCompleta';
import { actionsCondiciones } from '../../reglas/resources/enums/enumActionsCondiciones';
import { objectOperador } from '../../reglas/resources/interfaces/interfaceOperador';
import { objectCanal } from '../../reglas/resources/interfaces/interfaceCanal';
import { TipoCondicion } from '../resources/enums/enumTipoCondicion';
import { enumOperadores } from '../../reglas/resources/enums/enumOperadores';

const initialStateCondiciones: any = {
  dialogoCondiciones: {
    grupos: [],
    tiposDispositivo: [],
    canales: [],
    dispositivos: [],
    operadores: [],
    opcionesTiposMedida: [],
    canalesGrupo: []
  } as dialogoCondiciones,
  condicionCompleta: objectCondicionCompleta(),
  isInfoCondicionOpen: false,
  condicionActiva: objectCondicionCompleta(),
  tipoCondicion: '',
  hora: new Date(),
  opcionTemporalEscogida: { label: '', value: '' },
  diaSemana: { label: '', value: '' },
  momentoDia: { label: '', value: '' }
};

export function useReducerCondiciones(eventosRegla: any) {
  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, propiedadesSnackBar } = useControlador();
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
  const { HORA, SEMANA, FECHA, SOLAR } = TipoCondicionTemporal;

  const [stateCondiciones, dispatch] = useReducer(reducerCondiciones, initialStateCondiciones);
  // ### Getters y setters del reducer ###
  /**
   * Función que cambia el valor de los desplegables de condiciones
   * @param { dialogoCondiciones } datos datos que se cargan en los desplegables
   */
  function setDialogoCondiciones(datos: dialogoCondiciones) {
    dispatch({ type: actionsCondiciones.SET_DIALOGO_CONDICIONES, payload: datos });
  }
  /**
   * Función que establece la condición completa
   * @param { condicionCompleta } condicion condición a establecer
   */
  function setCondicionCompleta(condicion: condicionCompleta) {
    dispatch({ type: actionsCondiciones.SET_CONDICION_COMPLETA, payload: condicion });
  }

  /**
   * Función que establece el tipo de condición
   * @param { string } tipo condición a establecer
   */
  function setTipoCondicion(tipo: string) {
    dispatch({ type: actionsCondiciones.SET_TIPO_CONDICION, payload: tipo });
  }

  function limpiarCondicion() {
    dispatch({ type: actionsCondiciones.LIMPIAR_CONDICION });
  }
  // ### Manejadores de eventos ###
  /**
   * Función que maneja la creación de la condición en el componente
   */
  function handleCreateCondicion() {
    if (isPanelRellenadoCorrectamente()) {
      //Añado la condición a la regla
      eventosRegla.addCondicionRegla(
        stateCondiciones.condicionCompleta,
        stateCondiciones.tipoCondicion
      );
      //Limpio la condición
      limpiarCondicion();
    } else {
      mostrarSnackBar(propiedadesSnackBar);
    }
  }

  /**
   * Función que maneja la activación del diálogo de condiciones
   * @param { condicionCompleta } condicion condición a mostrar
   */
  function mostrarInfoCondicion(condicion: condicionCompleta) {
    dispatch({ type: actionsCondiciones.MOSTRAR_INFO_CONDICION, payload: condicion });
  }
  /**
   * Función que maneja la desactivación del diálogo de condiciones
   */
  function cerrarInfoCondicion() {
    dispatch({ type: actionsCondiciones.CERRAR_MOSTRAR_INFO_CONDICION });
  }
  /**
   * Función que maneja la hora de las condiciones temporales
   */
  function setHora(hora: Date | null) {
    dispatch({ type: actionsCondiciones.SET_HORA, payload: hora });
  }

  /**
   * Función que establece la opción temporal escogida de las condiciones temporales
   */
  function setOpcionTemporalEscogida(opcionTemporal: any) {
    dispatch({ type: actionsCondiciones.SET_OPCION_TEMPORAL_ESCOGIDA, payload: opcionTemporal });
  }

  /**
   * Función que establece el día de la semana de las condiciones temporales
   */
  function setDiaSemana(diaSemana: any) {
    dispatch({ type: actionsCondiciones.SET_DIA_SEMANA, payload: diaSemana });
  }

  /**
   * Función que establece el momento del dia (amanecer o anochecer) de las condiciones temporales
   */
  function setMomentoDia(momentoDia: any) {
    dispatch({ type: actionsCondiciones.SET_MOMENTO_DIA, payload: momentoDia });
  }

  // ### Funciones auxiliares ###

  const mensajesTemporales = {
    [HORA]: (condicion: condicionCompleta) => {
      const hora = new Date(Number.parseInt(condicion.valor));
      return (
        'Se ejecuta ' +
        eventosRegla.convertirOperadorAFrase(condicion.operador.operador, condicion.tipoTemporal) +
        ' ' +
        hora.toTimeString().split(':')[0] +
        ':' +
        hora.toTimeString().split(':')[1]
      );
    },
    [SEMANA]: (condicion: condicionCompleta) => {
      return (
        'Se ejecuta ' +
        eventosRegla.convertirOperadorAFrase(condicion.operador.operador, condicion.tipoTemporal) +
        ' ' +
        diasSemana[new Date(Number.parseInt(condicion.valor)).getDay()]
      );
    },
    [FECHA]: (condicion: condicionCompleta) =>
      'Se ejecuta ' +
      eventosRegla.convertirOperadorAFrase(condicion.operador.operador, condicion.tipoTemporal) +
      ' ' +
      new Date(Number.parseInt(condicion.valor)).toLocaleString('es-ES', {
        month: 'long',
        day: 'numeric'
      }),
    [SOLAR]: (condicion: condicionCompleta) =>
      'Se ejecuta ' +
      eventosRegla.convertirOperadorAFrase(condicion.operador.operador, condicion.tipoTemporal) +
      ' ' +
      condicion.valor
  };
  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }

  /**
   * Función que comprueba que el panel de condiciones está rellenado correctamente
   */
  function isPanelRellenadoCorrectamente(): boolean {
    //Si no hay un tipo de condición
    if (!stateCondiciones.tipoCondicion) {
      propiedadesSnackBar.current.severity = enumSeverity.ERROR;
      propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_TIPO_CONDICION;
      return false;
    }
    //En el caso de que haya un tipo de condición, hay que comprobar que todos los campos están rellenados correctamente
    switch (stateCondiciones.tipoCondicion) {
      case TipoCondicion.CONDICION_TEMPORAL: {
        if (
          stateCondiciones.condicionCompleta.operador == undefined ||
          stateCondiciones.condicionCompleta.operador.operador == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_OPERADOR;
          return false;
        }
        if (stateCondiciones.condicionCompleta.valor == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_HORA;
          return false;
        }
        break;
      }

      case TipoCondicion.CONDICION_DISPOSITIVO:
        {
          //Voy comprobando que cada elemento del panel exista correctamente
          if (
            stateCondiciones.condicionCompleta.tipoDispositivo === undefined ||
            stateCondiciones.condicionCompleta.tipoDispositivo.nombre == ''
          ) {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_TIPO_DISPOSITIVO;
            return false;
          }
          if (
            stateCondiciones.condicionCompleta.dispositivo === undefined ||
            stateCondiciones.condicionCompleta.dispositivo.nombre == ''
          ) {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_DISPOSITIVO;
            return false;
          }
          if (
            stateCondiciones.condicionCompleta.canal == undefined ||
            stateCondiciones.condicionCompleta.canal.nombre == ''
          ) {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_CANAL;
            return false;
          }
          if (
            stateCondiciones.condicionCompleta.operador == undefined ||
            stateCondiciones.condicionCompleta.operador.operador == ''
          ) {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_OPERADOR;
            return false;
          }
          if (stateCondiciones.condicionCompleta.valor == '') {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_VALOR;
            return false;
          }
        }
        break;
      case TipoCondicion.CONDICION_GRUPAL:
        if (
          stateCondiciones.condicionCompleta.nombreCanal == undefined ||
          stateCondiciones.condicionCompleta.nombreCanal == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_CANAL;
          return false;
        }
        if (
          stateCondiciones.condicionCompleta.operador == undefined ||
          stateCondiciones.condicionCompleta.operador.operador == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_OPERADOR;
          return false;
        }
        if (stateCondiciones.condicionCompleta.valor == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_VALOR;
          return false;
        }
        if (
          stateCondiciones.condicionCompleta.grupo == undefined ||
          stateCondiciones.condicionCompleta.grupo.codigo == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_GRUPO;
          return false;
        }
        break;
    }
    //Si no da error, es que está correctamente realizado
    propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
    propiedadesSnackBar.current.texto = dictionary_correcto_reglas.CONDICION_CORRECTA;
    return true;
  }

  // ### HOOKS ###
  //Carga inicial de datos
  useEffect(() => {
    const cargarDatos = async () => {
      setOpenSmartTeliaBackdrop(true);

      setDialogoCondiciones({
        ...stateCondiciones.dialogoCondiciones,
        tiposDispositivo: await controllerRequest({
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.TIPOS_DISPOSITIVOS_CON_DISPOSITIVO }
        }),
        operadores: await controllerRequest({
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.OPERADORES }
        }),
        grupos: await controllerRequest({
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.GRUPOS + '/todos/dispositivos' }
        })
      });
      //Si es una regla para modificar, se cargan también las condiciones y las acciones

      setOpenSmartTeliaBackdrop(false);
    };
    cargarDatos();
  }, []);

  //Cada vez que cambia el tipo dedispositivo seleccionado,
  //debe cargar los dispositivos y limpiar el resto de inputs
  useEffect(() => {
    const cargarDatosDispositivos = async () => {
      stateCondiciones.condicionCompleta.tipoDispositivo !== undefined &&
        stateCondiciones.condicionCompleta.tipoDispositivo.nombre !== '' &&
        setDialogoCondiciones({
          ...stateCondiciones.dialogoCondiciones,
          dispositivos: await controllerRequest({
            type: action.OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO,
            payload: {
              servicio: rutasServicios.DISPOSITIVOS,
              idTipoDispositivo: stateCondiciones.condicionCompleta.tipoDispositivo.id as number
            }
          }),
          canales: []
        });
      setCondicionCompleta({
        ...stateCondiciones.condicionCompleta,
        dispositivo: objectDispositivo()
      });
    };
    cargarDatosDispositivos();
  }, [stateCondiciones.condicionCompleta.tipoDispositivo]);

  //Cada vez que cambia el tipo de condición seleccionado, debe cargar los operadores
  useEffect(() => {
    const cargarOperadores = async () => {
      if (stateCondiciones.tipoCondicion !== undefined && stateCondiciones.tipoCondicion !== '') {
        const operadores: any[] = await controllerRequest({
          type: action.OBTENER_DATOS_SIN_MODULO,
          payload: { servicio: rutasServicios.OPERADORES }
        });
        //Si la condición no es de dispositivo, se elimina el operador de 'incluye'
        stateCondiciones.tipoCondicion !== TipoCondicion.CONDICION_DISPOSITIVO && operadores.pop();
        setDialogoCondiciones({
          ...stateCondiciones.dialogoCondiciones,
          operadores: operadores
        });
      }
    };
    cargarOperadores();
  }, [stateCondiciones.tipoCondicion]);

  //Cada vez que cambia el dispositivo seleccionado, debe cargar los canales y limpiar el resto de inputs
  useEffect(() => {
    const cargarDatosCanales = async () => {
      stateCondiciones.condicionCompleta.dispositivo !== undefined &&
        stateCondiciones.condicionCompleta.dispositivo.nombre !== '' &&
        setDialogoCondiciones({
          ...stateCondiciones.dialogoCondiciones,
          canales: await controllerRequest({
            type: action.OBTENER_CANALES_BY_DISPOSITIVOS,
            payload: {
              servicio: rutasServicios.CANALES,
              idDispositivo: stateCondiciones.condicionCompleta.dispositivo.id as number
            }
          })
        });

      setCondicionCompleta({
        ...stateCondiciones.condicionCompleta,
        canal: objectCanal(),
        operador: objectOperador(),
        valor: ''
      });
    };
    cargarDatosCanales();
  }, [stateCondiciones.condicionCompleta.dispositivo]);

  //Cada vez que se cambia el canal, hay que obtener los tipos de medidas y limpiar el resto de inputs
  useEffect(() => {
    const cargarOpcionesTiposMedida = async () => {
      if (
        stateCondiciones.condicionCompleta.canal !== undefined &&
        stateCondiciones.condicionCompleta.canal.nombre !== ''
      ) {
        setCondicionCompleta({
          ...stateCondiciones.condicionCompleta,
          operador: objectOperador(),
          valor: ''
        });
        setDialogoCondiciones({
          ...stateCondiciones.dialogoCondiciones,
          opcionesTiposMedida: await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: {
              servicio: 'tiposMedidas/opciones',
              id: stateCondiciones.condicionCompleta.canal.idTipoMedida
            }
          })
        });
      }
    };
    cargarOpcionesTiposMedida();
  }, [stateCondiciones.condicionCompleta.canal]);

  //Cada vez que se cambia el grupo de dispositivo, hay que cargar los canales
  useEffect(() => {
    const cargarDatosCanalesGrupo = async () => {
      if (
        stateCondiciones.condicionCompleta.grupo !== undefined &&
        stateCondiciones.condicionCompleta.grupo.codigo !== ''
      ) {
        setCondicionCompleta({
          ...stateCondiciones.condicionCompleta,
          operador: objectOperador(),
          valor: '',
          nombreCanal: ''
        });

        setDialogoCondiciones({
          ...stateCondiciones.dialogoCondiciones,
          canalesGrupo: await controllerRequest({
            type: action.OBTENER_CANALES_POR_GRUPO,
            payload: {
              servicio: 'canales/grupo',
              idGrupo: stateCondiciones.condicionCompleta.grupo.id
            }
          })
        });
      }
    };
    cargarDatosCanalesGrupo();
  }, [stateCondiciones.condicionCompleta.grupo]);

  const events = {
    setOpenSmartTeliaBackdrop,
    setCondicionCompleta,
    handleCreateCondicion,
    mostrarInfoCondicion,
    cerrarInfoCondicion,
    isPanelRellenadoCorrectamente,
    setDialogoCondiciones,
    setTipoCondicion,
    setHora,
    setOpcionTemporalEscogida,
    setDiaSemana,
    setMomentoDia,
    mensajesTemporales
  };
  return {
    stateCondiciones,
    events
  };
}
