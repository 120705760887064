import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Table from '../../common/table/Table';

export function DevicesTableDialog({
  devices,
  handleCloseDialog,
  openDialog,
  onRowSelected,
  selectedItems
}: {
  devices: Array<{ canal: string; id: number; nombre: string; idCanal: number }>;
  handleCloseDialog: () => void;
  openDialog: boolean;
  onRowSelected: (
    dispositivos: Array<{ canal: string; id: number; nombre: string; idCanal: number }>
  ) => void;
  selectedItems: Array<{ canal: string; id: number; nombre: string }>;
}): JSX.Element {
  function handleClose() {
    handleCloseDialog();
  }

  function handleRowSelectionChange(
    currentRowsSelected: Array<number>,
    allRowsSelected: Array<number>,
    rowsSelected: Array<number>
  ) {
    onRowSelected(rowsSelected.map((row) => devices[row]));
  }

  function checkRowsSelected(): Array<number> {
    return selectedItems.map((selectedItem) =>
      devices.findIndex(
        (device) => device.canal === selectedItem.canal && device.id === selectedItem.id
      )
    );
  }

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    {
      name: 'nombre',
      label: 'Dispositivos',
      options: {
        setCellHeaderProps: () => {
          return {
            className: 'cellHeaderConfiguratorDevices'
          };
        }
      }
    },
    {
      name: 'canal',
      label: 'Canales',
      options: {
        setCellHeaderProps: () => {
          return {
            className: 'cellHeaderConfiguratorDevices'
          };
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    search: true,
    rowsPerPage: 7,
    selectableRows: true,
    onRowSelectionChange: handleRowSelectionChange,
    searchAlwaysOpen: true,
    rowsSelected: checkRowsSelected(),
    selectToolbarPlacement: 'none'
  };

  return (
    <Dialog open={openDialog} fullWidth maxWidth={'lg'} onClose={handleClose}>
      <DialogTitle>Seleccionar dispositivos</DialogTitle>
      <DialogContent>
        <Table title={'Dispositivos'} columns={columns} data={devices} options={options} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} sx={{ fontSize: '0.4rem' }}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
