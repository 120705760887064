import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Dispositivo Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  // Dispositivo Create y Update
  root: {
    width: '100%',
    padding: '0 20px 10px 20px'
  },
  cajas: {
    marginBottom: '15px'
  },
  displayBlock: {
    display: 'block'
  },
  styleSpanOpenHab: {
    display: 'block',
    margin: 0,
    padding: 0,
    fontSize: '10px',
    fontWeight: 'lighter'
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium',
    marginBottom: '2px'
  },
  slider: {
    width: '99%',
    float: 'right'
  },
  // Dispositivos info Device
  tarjeta: {
    width: '700px',
    display: 'flex',
    height: '350px'
  },
  imagen: {
    width: '150px',
    alignSelf: 'center',
    textAlign: 'center',
    paddingLeft: '16px',
    paddingRight: 0
  },
  logotipo: {
    maxHeight: '130px',
    maxWidth: '130px'
  },
  detalles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px',
    paddingBottom: 0,
    marginLeft: '30px'
  },
  contenido: {
    flex: '1 0 auto',
    padding: '8px 8px 0 8px'
  },
  tituloPlugin: {
    fontWeight: 600
  },
  textoTarjeta: {
    textAlign: 'left',
    width: '100%',
    marginBottom: '1em'
  },
  controles: {
    alignItems: 'center',
    width: '100%'
  },
  alert: {
    alignItems: 'center'
  }
});
export default useStyles;
