import React from 'react';
import { useContext, useEffect, useReducer, useRef } from 'react';
import ControlContext from '../../../../context/control/ControlContext';
import SnackBarContext from '../../../../context/SnackBarContext';
import {
  calcularFrecuenciaCrear,
  calcularFrecuenciaObtener,
  limpiarCampos
} from '../../../../functions/functions';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import {
  cambiarEstadoSection,
  configuracionValorByDevice,
  devicesByPlugin,
  obtenerDatosSinModulo,
  sectionsByDevice,
  tipoDeviceByPlugin
} from '../../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { useEventChange } from '../../../../hooks/useEventChange/useEventChange';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { rutasServicios } from '../../../../resources/enums/enumRutasServicios';
import { dictionary_generic } from '../../../../resources/enums/plainText';
import { objectPlugin, plugin } from '../../../plugins/resources/interfaces/interfacePlugin';
import { section } from '../../../sections/resources/interfaces/interfaceSection';
import { DeviceManager } from '../../DeviceManager';
import { device, objectDevice } from '../../resources/interfaces/interfaceDevice';
import { deviceCRU } from '../../resources/interfaces/interfaceDeviceCRU';
import { deviceTipoDeviceConfiguracion } from '../../resources/interfaces/interfaceDeviceTipoDeviceConfiguracion';
import { tipoDevice } from '../../resources/interfaces/interfaceTipoDevice';
import { tipoDeviceConfiguracion } from '../../resources/interfaces/interfaceTipoDeviceConfiguracion';
import { actions } from './resources/enums/enumActions';
import { Action } from './resources/types/typeAction';
import { Events } from './resources/types/typeEvents';
import { State } from './resources/types/typeState';

const REDUCER = (state: State, action: Action): State => {
  switch (action.type) {
    case actions.SET_DEVICE_SELECCIONADO:
      return { ...state, deviceSeleccionado: action.payload };
    case actions.SET_PLUGIN_SELECCIONADO:
      return { ...state, pluginSeleccionado: action.payload };
    // MANAGER
    case actions.SET_TIPOS_DEVICE:
      return { ...state, tiposDevice: action.payload };
    case actions.SET_PLUGINS:
      return { ...state, plugins: action.payload };
    case actions.SET_DEVICE_MODIFICAR:
      return { ...state, deviceModificar: action.payload };
    // TABLA
    case actions.SET_DEVICES_TABLA:
      return { ...state, devicesTabla: action.payload };
    // CRU
    case actions.SET_PARAMETROS_CONFIGURACION:
      return { ...state, parametrosConfiguracion: action.payload };
    case actions.SET_DEVICE:
      return { ...state, device: action.payload };
    case actions.SET_CONFIGURACION:
      return { ...state, configuracion: action.payload };
    case actions.SET_CHECKED:
      return { ...state, checked: action.payload };
    case actions.SET_DISABLED:
      return { ...state, disabled: action.payload };
    case actions.SET_DISABLED_TIPO_DEVICE:
      return { ...state, disabledTipoDevice: action.payload };
    case actions.SET_AUTOREFRESH:
      return { ...state, autoRefresh: action.payload };
    case actions.SET_LIMPIAR:
      return { ...state, limpiar: action.payload };
    case actions.HANDLE_AUTOCOMPLETE_TIPO_DEVICE_NULL:
      return {
        ...state,
        disabled: true,
        autoRefresh: 0,
        configuracion: [],
        parametrosConfiguracion: []
      };
    case actions.HANDLE_AUTOCOMPLETE_PLUGIN_NULL:
      return {
        ...state,
        disabled: true,
        autoRefresh: 0,
        configuracion: [],
        parametrosConfiguracion: [],
        disabledTipoDevice: true,
        tiposDevice: undefined
      };
    // INFO
    case actions.SET_SECTIONS:
      return { ...state, sections: action.payload };
    case actions.SET_SECTIONS_MODIFICAR:
      return { ...state, sectionModificar: action.payload };
    case actions.SET_OPEN_AVISO:
      return { ...state, openAviso: action.payload };
    default:
      return state;
  }
};

/**
 * Custom Hook para el control de la interación con sections, y el control de la transición (expander)
 */
const useReducerDevice = ({
  deviceParaModificar,
  plugin
}: {
  deviceParaModificar?: device;
  plugin?: plugin;
}): {
  state: State;
  events: Events;
} => {
  const { control, setControl } = useContext(ControlContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar, registroGenerado } =
    useControlador();
  // dividimos el key para tener opción de volver a atrás según si estabamos en plugins instalados o disponibles
  const keyDivide = (control.key as string).split('-');

  const INITIAL_STATE: State = {
    deviceSeleccionado: deviceParaModificar,
    pluginSeleccionado: plugin,
    key: keyDivide[0] ?? '',
    keySecond: keyDivide[1] ? '-' + keyDivide[1] : '',
    keyThird: keyDivide[2] ? '-' + keyDivide[2] : '',
    // TABLA
    devicesTabla: [],
    // MANAGER
    tiposDevice: undefined,
    plugins: undefined,
    deviceModificar: undefined,
    // CRU
    parametrosConfiguracion: [],
    device: objectDevice(deviceParaModificar),
    configuracion: [],
    checked: deviceParaModificar === undefined || deviceParaModificar.activo === 1 ? true : false,
    disabled: deviceParaModificar !== undefined ? false : true,
    disabledTipoDevice: false,
    autoRefresh: 0,
    limpiar: '',
    // INFO
    sections: [],
    sectionModificar: undefined,
    openAviso: false
  };
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE);
  const {
    deviceSeleccionado,
    pluginSeleccionado,
    key,
    keySecond,
    keyThird,
    deviceModificar,
    device,
    configuracion,
    sections,
    sectionModificar
  } = state;

  // SET STATES
  /**
   * Función que establece el device seleccionado
   * @param { device } device
   */
  const setDeviceSeleccionado = (device: device): void =>
    dispatch({ type: actions.SET_DEVICE_SELECCIONADO, payload: device });
  /**
   * Función que establece el plugin seleccionado
   * @param { plugin } plugin
   */
  const setPluginSeleccionado = (plugin: plugin): void =>
    dispatch({ type: actions.SET_PLUGIN_SELECCIONADO, payload: plugin });
  // DEVICES TABLA
  /**
   * Función que establece devicesTabla
   * @param { device[] } devicesTabla
   */
  const setDevicesTabla = (devicesTabla: device[]): void =>
    dispatch({ type: actions.SET_DEVICES_TABLA, payload: devicesTabla });

  // DEVICE MANAGER
  /**
   * Función que establece tiposDevice
   * @param { tipoDevice[] | undefined } tiposDevice
   */
  const setTiposDevice = (tiposDevice: tipoDevice[] | undefined): void =>
    dispatch({ type: actions.SET_TIPOS_DEVICE, payload: tiposDevice });

  /**
   * Función que establece plugins
   * @param { plugin[] } plugins
   */
  const setPlugins = (plugins: plugin[]): void =>
    dispatch({ type: actions.SET_PLUGINS, payload: plugins });

  /**
   * Función que establece deviceModificar
   * @param { deviceCRU } deviceModificar
   */
  const setDeviceModificar = (deviceModificar: deviceCRU): void =>
    dispatch({ type: actions.SET_DEVICE_MODIFICAR, payload: deviceModificar });

  // DEVICE CRU
  /**
   * Función que establece parametrosConfiguracion
   * @param { tipoDeviceConfiguracion[] } parametrosConfiguracion
   */
  const setParametrosConfiguracion = (parametrosConfiguracion: tipoDeviceConfiguracion[]): void =>
    dispatch({ type: actions.SET_PARAMETROS_CONFIGURACION, payload: parametrosConfiguracion });

  /**
   * Función que establece device
   * @param { device } device
   */
  const setDevice = (device: device): void =>
    dispatch({ type: actions.SET_DEVICE, payload: device });

  /**
   * Función que establece configuracion de un device
   * @param { deviceTipoDeviceConfiguracion[] } configuracion
   */
  const setConfiguracion = (configuracion: deviceTipoDeviceConfiguracion[]): void =>
    dispatch({ type: actions.SET_CONFIGURACION, payload: configuracion });

  /**
   * Función que establece el check de un device
   * @param {boolean} checked
   */
  const setChecked = (checked: boolean): void =>
    dispatch({ type: actions.SET_CHECKED, payload: checked });

  /**
   * Función que establece disabled de los distintos inputs
   * @param {boolean} disabled
   */
  const setDisabled = (disabled: boolean): void =>
    dispatch({ type: actions.SET_DISABLED, payload: disabled });
  /**
   * Función que establece el disabled en el autocompletado de tipo device
   * @param {boolean} disabledTipoDevice
   */
  const setDisabledTipoDevice = (disabledTipoDevice: boolean): void =>
    dispatch({ type: actions.SET_DISABLED_TIPO_DEVICE, payload: disabledTipoDevice });
  /**
   * Función que establece autoRefresh
   * @param {number} autoRefresh
   */
  const setAutoRefresh = (autoRefresh: number): void =>
    dispatch({ type: actions.SET_AUTOREFRESH, payload: autoRefresh });
  /**
   * Función que establece limpiar
   * @param {string} limpiar
   */
  const setLimpiar = (limpiar: string): void =>
    dispatch({ type: actions.SET_LIMPIAR, payload: limpiar });
  /**
   * Función que se ejecuta al borrar el autocompletado de tipos devices
   */
  const handleAutocompleteTipoDeviceNull = (): void =>
    dispatch({ type: actions.HANDLE_AUTOCOMPLETE_TIPO_DEVICE_NULL });
  /**
   * Función que se ejecuta al borrar el autocompletado de plugins
   */
  const handleAutocompletePluginNull = (): void =>
    dispatch({ type: actions.HANDLE_AUTOCOMPLETE_PLUGIN_NULL });

  // INFO DEVICE
  /**
   * Función que establece sections
   * @param {section[]} sections
   */
  const setSections = (sections: section[]): void =>
    dispatch({ type: actions.SET_SECTIONS, payload: sections });
  /**
   * Función que establece la sectionModificar
   * @param {section} sectionModificar
   */
  const setSectionModificar = (sectionModificar: section): void =>
    dispatch({ type: actions.SET_SECTIONS_MODIFICAR, payload: sectionModificar });
  /**
   * Función que establece el openAviso
   * @param {boolean} openAviso
   */
  const setOpenAviso = (openAviso: boolean): void =>
    dispatch({ type: actions.SET_OPEN_AVISO, payload: openAviso });

  // VARIABLES, HANDLES Y FUNCIONES
  // Comun
  const openCloseSmarTeliaBackdrop = {
    openSmartTeliaBackdrop: true,
    closeSmartTeliaBackdrop: true
  };
  // Componente DeviceCRU
  const { setSnackBar } = useContext(SnackBarContext);
  const { handleInputChange, handleInputInvalid } = useEventChange(device, setDevice);
  const tiempoRefresco = useRef(
    deviceParaModificar !== undefined
      ? calcularFrecuenciaObtener(deviceParaModificar.tiempoRefresco as number)
      : { tiempo: 30, frecuencia: dictionary_generic.MINUTOS }
  );
  const tiempoInactividad = useRef(
    deviceParaModificar !== undefined
      ? calcularFrecuenciaObtener(deviceParaModificar.tiempoInactividad as number)
      : { tiempo: 24, frecuencia: dictionary_generic.HORAS }
  );
  const elemento = {
    device: device,
    configuracion: configuracion
  };

  /**
   * Evento que captura el tipoDevice seleccionado.
   * @param {React.ChangeEvent<unknown>} _event
   * @param {tipoDevice | null} value tipoDevice
   */
  const handleAutocompleteTipoDevice = (
    _event: React.ChangeEvent<unknown>,
    value: tipoDevice | null
  ) => {
    handleAutocompleteTipoDeviceNull();
    if (value !== null) {
      obtenerParametrosConfiguracion(value);
    }
  };

  /**
   * Evento que captura el plugin seleccionado.
   * @param {React.ChangeEvent<unknown>} _event
   * @param {plugin | null} value plugin
   */
  const handleAutocompletePlugins = (_event: React.ChangeEvent<unknown>, value: plugin | null) => {
    handleAutocompletePluginNull();
    limpiarCampos(
      'formDevice',
      setLimpiar as React.Dispatch<React.SetStateAction<string>> | undefined
    );
    if (value !== null) {
      obtenerTiposDevicesByPlugin(value);
    }
  };

  /** Función que obtiene la configuración del device guardada en base de datos y devuelve los inputs (parametros de configuración)
   * @param {any} object objeto de device del que queremos obtener los parametros de configuracion para crear un device
   */
  const obtenerParametrosConfiguracion = async (object: any) => {
    let id: number;
    if (deviceParaModificar === undefined) id = object.id;
    else id = object.idTipoDevice;
    const parametros: tipoDeviceConfiguracion[] = await controllerRequest(
      {
        type: action.OBTENER_CONFIGURACION_BY_TIPO_DEVICE,
        payload: { servicio: rutasServicios.TIPOS_DEVICES, idTipoDevice: id }
      },
      openCloseSmarTeliaBackdrop
    );
    setAutoRefresh(object.autoRefresh as number);
    // Cuando se cree un device se rellenan todos los campos vacíos
    if (deviceParaModificar === undefined) {
      setConfiguracion(
        parametros.map((parametro) => {
          return {
            idTipoDeviceConfiguracion: parametro.id,
            valor: parametro.valorDefecto
          };
        }) as deviceTipoDeviceConfiguracion[]
      );
    }
    setParametrosConfiguracion(parametros);
    device.idTipoDevice = id as number;
    setDisabled(false);
  };

  /**
   * Funcion que obtine los tipos devices según un plugin
   * @param {plugin} plugin
   */
  const obtenerTiposDevicesByPlugin = async (plugin: plugin) => {
    setTiposDevice(
      await controllerRequest(
        {
          type: action.OBTENER_TIPOS_DEVICE_BY_PLUGIN,
          payload: {
            servicio: rutasServicios.TIPOS_DEVICES,
            idPlugin: plugin.id as number
          }
        },
        openCloseSmarTeliaBackdrop
      )
    );
    setDisabledTipoDevice(false);
  };

  /**
   * Evento que captura el input de frecuencia al seleccionar un valor en el slider
   * @param {Event | React.SyntheticEvent<Element, Event>} _event evento
   * @param {number} newValue nuevo valor establecido
   */
  const handleSliderFrecuencia = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ): void => {
    const nuevoValor = Array.isArray(newValue) ? newValue[0] : newValue;
    tiempoRefresco.current.tiempo = nuevoValor;
    if (tiempoRefresco.current.tiempo !== undefined)
      device.tiempoRefresco = calcularFrecuenciaCrear(
        tiempoRefresco.current.frecuencia,
        tiempoRefresco.current.tiempo
      );
  };

  /**
   * Evento que captura cuando se cambia el valor en el autocomplete de frecuencia.
   * @param {React.ChangeEvent<unknown>} _event
   * @param {{ nombre: string } | null} value
   */
  const handleSelectFrecuencia = (
    _event: React.ChangeEvent<unknown>,
    value?: { nombre: string } | null
  ) => {
    if (value !== null) {
      device.tiempoRefresco = calcularFrecuenciaCrear(
        value?.nombre as string,
        tiempoRefresco.current.tiempo
      );
      tiempoRefresco.current.frecuencia = value?.nombre as string;
    }
  };

  /**
   * Evento que captura el input de frecuencia al seleccionar un valor en el slider
   * @param {Event | React.SyntheticEvent<Element, Event>} _event evento
   * @param {number} newValue nuevo valor establecido
   */
  const handleSliderInactividadFrecuencia = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ): void => {
    const nuevoValor = Array.isArray(newValue) ? newValue[0] : newValue;
    tiempoInactividad.current.tiempo = nuevoValor;
    if (tiempoInactividad.current.tiempo !== undefined)
      device.tiempoInactividad = calcularFrecuenciaCrear(
        tiempoInactividad.current.frecuencia,
        tiempoInactividad.current.tiempo
      );
  };

  /**
   * Evento que captura cuando se cambia el valor en el autocomplete de frecuencia.
   * @param {React.ChangeEvent<unknown>} _event
   * @param {{ nombre: string } | null} value
   */
  const handleSelectInactividadFrecuencia = (
    _event: React.ChangeEvent<unknown>,
    value?: { nombre: string } | null
  ) => {
    if (value !== null) {
      device.tiempoInactividad = calcularFrecuenciaCrear(
        value?.nombre as string,
        tiempoInactividad.current.tiempo
      );
      tiempoInactividad.current.frecuencia = value?.nombre as string;
    }
  };

  /**
   * Evento que establece el parametro activo de un device
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    event.target.checked ? (device.activo = 1) : (device.activo = 0);
  };

  /**
   * Función que se ejecuta después de realizar la petición al hacer submit
   */
  const respuestaSubmit = () => {
    //Si se hace correctamente, se limpia el formulario y se vuelve a la tabla devices.
    if (completadoCorrectamente.current) {
      limpiarCampos(
        'formDevice',
        setLimpiar as React.Dispatch<React.SetStateAction<string>> | undefined
      );
      setDisabled(true);
      tiempoRefresco.current.tiempo = 1;
      setControl(
        <DeviceManager
          pluginSeleccionado={pluginSeleccionado}
          key={
            (pluginSeleccionado?.id
              ? enumComponentes.DEVICES_POR_PLUGIN
              : enumComponentes.DEVICES) +
            keySecond +
            keyThird
          }
        />
      );
    }
    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  };

  /**
   * Evento que se ejecuta al confirmar el formulario de Crear/Modificar Device
   * @param {React.FormEvent<HTMLFormElement>} event evento al hacer submit
   */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //Definición del elemento (en este caso DEVICE) para crear o modificar.
    if (deviceModificar !== undefined) {
      await controllerRequest(
        {
          type: action.MODIFICAR_REGISTRO,
          payload: {
            servicio: rutasServicios.DEVICES,
            objeto: elemento,
            id: elemento.device.id as number
          }
        },
        openCloseSmarTeliaBackdrop
      );
    } else {
      await controllerRequest(
        {
          type: action.CREAR_UN_REGISTRO,
          payload: { servicio: rutasServicios.DEVICES, objeto: elemento }
        },
        openCloseSmarTeliaBackdrop
      );
    }
    respuestaSubmit();
  };

  /**
   * Evento que se ejectua al cambiar los valores de un parametro de un device
   * @param {number} id id del parametro a modificar
   * @param {string} valor nuevo valor a establecer en el parametro
   */
  const handleParametroChange = (id: number, valor: string) => {
    const nuevoParametro: deviceTipoDeviceConfiguracion = {
      idTipoDeviceConfiguracion: id,
      valor: valor
    };
    let indice = -1;
    const nuevoConfiguracion = [...configuracion];

    if (nuevoConfiguracion.length > 0) {
      nuevoConfiguracion.forEach((parametro) => {
        if (parametro.idTipoDeviceConfiguracion === id) {
          indice = nuevoConfiguracion.indexOf(parametro);
        }
      });
      if (indice === -1) {
        nuevoConfiguracion.push(nuevoParametro);
      } else {
        nuevoConfiguracion.splice(indice, 1, nuevoParametro);
      }
      setConfiguracion(nuevoConfiguracion);
    } else {
      setConfiguracion([nuevoParametro]);
    }
  };

  /**
   * Función que obtiene el valor del parametro de configuracion que se va a modificar.
   * @param {number} id identificador del parametro
   * @returns {string} devuelve el valor del parámetro
   */
  const getValor = (id: number): string => {
    const paramEncontrado = deviceModificar?.configuracion.find(
      (parametroModificar) => parametroModificar.tipoDeviceConfiguracion === id
    );
    if (paramEncontrado !== undefined) return paramEncontrado.valor;
    else return '';
  };

  /**
   * Evento que se ejecuta al hacer click en una section para activar/desactivar
   * @param {section} section section que se va a modificar
   */
  const handleClickSection = async (section: section) => {
    setSectionModificar(section);
    setOpenAviso(true);
  };

  /**
   * Función que cambia el parametro activo de una section realizando la petición al servicio
   * según el nuevo valor de la variable de estado sectionModificar
   */
  const handleConfirmarAlertAviso = async () => {
    let nuevoActivo: number;
    if (sectionModificar?.activo === 1) nuevoActivo = 0;
    else nuevoActivo = 1;

    const actionRequest: cambiarEstadoSection = {
      type: action.CAMBIAR_ESTADO_SECTION,
      payload: {
        servicio: rutasServicios.SECTIONS,
        id: sectionModificar?.id as number,
        nuevoActivo: nuevoActivo
      }
    };

    await controllerRequest(actionRequest);

    if (completadoCorrectamente.current) {
      const indice = sections.indexOf(sectionModificar as section);
      const nuevoSections = [...sections];
      nuevoSections.splice(indice, 1, registroGenerado.current);
      setSections(nuevoSections);
    } else {
      setSnackBar({
        open: true,
        text: propiedadesSnackBar.current.texto,
        severity: propiedadesSnackBar.current.severity
      });
    }
  };

  /**
   * Función que quita el plugin seleccionado del state si lo hay
   */
  const quitarPluginSeleccionado = (): void => {
    if (pluginSeleccionado?.id) setPluginSeleccionado(objectPlugin());
  };

  //#region Carga de los DATOS necesarios para cada componente
  // actions Request
  const actionRequestDevices: obtenerDatosSinModulo = {
    type: action.OBTENER_DATOS_SIN_MODULO,
    payload: { servicio: rutasServicios.DEVICES }
  };
  const actionRequestDevicesByPlugin: devicesByPlugin = {
    type: action.DEVICES_BY_PLUGIN,
    payload: { servicio: rutasServicios.DEVICES, idPlugin: pluginSeleccionado?.id as number }
  };
  const actionRequestTiposDevice: tipoDeviceByPlugin = {
    type: action.OBTENER_TIPOS_DEVICE_BY_PLUGIN,
    payload: {
      servicio: rutasServicios.TIPOS_DEVICES,
      idPlugin: pluginSeleccionado?.id as number
    }
  };
  const actionRequestConfiguracion: configuracionValorByDevice = {
    type: action.OBTENER_CONFIGURACION_VALOR_BY_DEVICE,
    payload: {
      servicio: rutasServicios.TIPOS_DEVICES,
      idDevice: deviceParaModificar?.id as number
    }
  };
  const actionRequestPlugins: obtenerDatosSinModulo = {
    type: action.OBTENER_DATOS_SIN_MODULO,
    payload: {
      servicio: rutasServicios.PLUGINS
    }
  };
  const actionRequestSections: sectionsByDevice = {
    type: action.SECTION_BY_DEVICE,
    payload: {
      servicio: rutasServicios.SECTIONS,
      idDevice: deviceSeleccionado?.id as number
    }
  };

  // funciones cargar datos
  const cargarTodosDevices = async () => {
    setDevicesTabla(await controllerRequest(actionRequestDevices, openCloseSmarTeliaBackdrop));
  };

  const cargarDevicesPorPlugin = async () => {
    setDevicesTabla(
      await controllerRequest(actionRequestDevicesByPlugin, openCloseSmarTeliaBackdrop)
    );
  };

  const cargarTiposDevice = async () => {
    setTiposDevice(await controllerRequest(actionRequestTiposDevice, openCloseSmarTeliaBackdrop));
  };

  const cargarPlugins = async () => {
    setPlugins(await controllerRequest(actionRequestPlugins, openCloseSmarTeliaBackdrop));
  };

  const cargarDispositivoModificar = async () => {
    const device: device = deviceParaModificar as device;
    const configuracion: deviceTipoDeviceConfiguracion[] = await controllerRequest(
      actionRequestConfiguracion,
      openCloseSmarTeliaBackdrop
    );
    const respuesta: deviceCRU = {
      device: device,
      configuracion: configuracion
    };
    setDeviceModificar(respuesta);
    await obtenerParametrosConfiguracion(device);
  };

  const cargarSections = async () => {
    setSections(await controllerRequest(actionRequestSections, openCloseSmarTeliaBackdrop));
  };

  useEffect(() => {
    switch (key) {
      case enumComponentes.DEVICES:
        cargarTodosDevices();
        break;
      case enumComponentes.DEVICES_POR_PLUGIN:
        cargarDevicesPorPlugin();
        break;
      case enumComponentes.CREAR_DEVICE:
        if (pluginSeleccionado?.id) cargarTiposDevice();
        else cargarPlugins();
        break;
      case enumComponentes.INFO_MODIFICAR_DEVICE:
      case enumComponentes.MODIFICAR_DEVICE:
        cargarDispositivoModificar();
        break;
      case enumComponentes.INFORMACION_DEVICE:
        cargarSections();
    }
  }, []);
  //#endregion

  const events = {
    handleInputChange,
    handleInputInvalid,
    handleAutocompleteTipoDevice,
    handleAutocompletePlugins,
    handleSliderFrecuencia,
    handleSelectFrecuencia,
    handleSliderInactividadFrecuencia,
    handleSelectInactividadFrecuencia,
    handleChecked,
    handleSubmit,
    handleParametroChange,
    getValor,
    handleClickSection,
    handleConfirmarAlertAviso,
    setOpenAviso,
    setDevicesTabla,
    setDeviceSeleccionado,
    quitarPluginSeleccionado
  };

  return {
    state,
    events
  };
};
export default useReducerDevice;
