import * as React from 'react';
import {
  Grid,
  Box,
  Icon,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Grow,
  Button
} from '@mui/material';
import { useStyles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import { IconosTiposMedida } from '../../../common/iconosTiposMedida/IconosTiposMedida';
import { dictionary_configurator } from '../../../../resources/enums/plainText';
import { MapIcons } from '../../../common/gis/components/iconos/Iconos';
import { useStandardCard } from './useConfigurableStandardCard';
import { useDrag } from 'react-dnd';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { CardProperties } from '../../types/typesCardConfigurator';
import { DevicesTableDialog } from '../DevicesTableDialog';

export function ConfigurableStandardCard(props: CardProperties | unknown): JSX.Element {
  const {
    tabState: [{ selectedModule }]
  } = useConfiguratorContext();

  const cardProps =
    Object.keys(props as Record<string, unknown>).length > 0
      ? { ...(props as CardProperties) }
      : null;

  const {
    handleAutoCompleteTiposMedida,
    handleAutoCompleteDispositivos,
    controlStates,
    handleAutoCompleteTiposDispositivo,
    obj,
    optionsAgregateFunctions,
    optionsAutoCompleteFrecuencia,
    handleAutoCompleteAgregateFunctions,
    handleCheckBox,
    handleCardTitle,
    handleSemaphoreValues,
    handleRange,
    handleSemaphoreCheckBox
  } = useStandardCard(selectedModule, cardProps);

  const classes = useStyles({ color: obj.cardReady ? '#94c390' : '#e0e0e0' });

  const { tiposDispositivo, tiposMedida, dispositivosDisponibles, dataObject } = obj;

  const { tarjeta } = dataObject;
  const [openDeviceDialog, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [, drag] = useDrag(
    () => ({
      type: 'tarjeta',
      item: {
        ...dataObject,
        tarjeta: {
          ...dataObject.tarjeta,
          idTipoTarjeta: cardType.STANDARD_CARD
        }
      },
      canDrag: obj.cardReady,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        canDrag: monitor.canDrag()
      })
    }),
    [obj]
  );

  return (
    <Grid item container xs={12} spacing={2} justifyContent={'space-evenly'} marginTop={'15px'}>
      <Grid item xs={12} md={9} container spacing={2}>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            onChange={handleCardTitle}
            id="titulo"
            label={dictionary_configurator.TITLE}
            placeholder="Máx, 20 caracteres."
            className={classes.elements}
            fullWidth
            inputProps={{ maxLength: '20' }}
            value={dataObject.tarjeta.titulo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="codigoTipoDispositivo"
            options={tiposDispositivo}
            disabled={tiposDispositivo.length === 0}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <MapIcons icono={option.icon as string} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => {
              return (
                <div>
                  <TextField
                    {...params}
                    label={dictionary_configurator.DEVICES_TYPES}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: obj.tipoDispositivo ? (
                        <MapIcons icono={obj.tipoDispositivo.icon} />
                      ) : undefined
                    }}
                  ></TextField>
                </div>
              );
            }}
            className={classes.elements}
            onChange={handleAutoCompleteTiposDispositivo}
            value={obj.tipoDispositivo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="tiposMedida"
            options={tiposMedida}
            key={tiposMedida[0]}
            disabled={!tiposMedida.length}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <IconosTiposMedida icono={option.codigo.toString()} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.MEASURE_TYPES}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: obj.medida ? (
                    <IconosTiposMedida icono={obj.medida.codigo} />
                  ) : undefined
                }}
              />
            )}
            className={classes.elements}
            onChange={handleAutoCompleteTiposMedida}
            value={obj.medida}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            sx={{ height: '100%', backgroundColor: 'lightgray', fontSize: '0.4rem' }}
            onClick={() => setOpen(true)}
            disabled={!obj.medida}
          >
            {`${dictionary_configurator.SELECT_DEVICES} (${dataObject.dispositivos?.length ?? 0})`}
          </Button>
          <DevicesTableDialog
            devices={dispositivosDisponibles}
            openDialog={openDeviceDialog}
            handleCloseDialog={handleClose}
            onRowSelected={handleAutoCompleteDispositivos}
            selectedItems={dataObject.dispositivos ?? []}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="range"
            options={optionsAutoCompleteFrecuencia}
            getOptionLabel={(option) => option.frecuencia}
            renderInput={(params) => (
              <TextField {...params} label={dictionary_configurator.RANGE} variant="outlined" />
            )}
            className={classes.elements}
            onChange={handleRange}
            value={obj.range}
            disabled={controlStates.deshabilitarRango}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="operation"
            options={optionsAgregateFunctions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.AGGREGATE_FUNCTION}
                variant="outlined"
              />
            )}
            className={classes.elements}
            getOptionDisabled={(option) =>
              dataObject.dispositivos && dataObject.dispositivos.length > 1
                ? option.name !== 'Media' && option.name !== 'Acumulado'
                : false
            }
            onChange={handleAutoCompleteAgregateFunctions}
            disabled={controlStates.operationDisabled || dispositivosDisponibles.length === 0}
            value={obj.operation}
          />
        </Grid>
        <Grid item xs={4} flexDirection={'row'} display={'flex'}>
          <Tooltip title={`${dictionary_configurator.MESSAGE_SHOW_GRAPH}`}>
            <FormControlLabel
              value="start"
              control={<Checkbox checked={Boolean(tarjeta.mostrarGrafica)} />}
              label={dictionary_configurator.SHOW_GRPAH}
              labelPlacement="end"
              className={classes.elements}
              onChange={(event, checked) => handleCheckBox(event, checked, 'mostrarGrafica')}
              disabled={controlStates.deshabilitarGrafica}
            />
          </Tooltip>
          <Tooltip title="Añade el valor máximo y mínimo para aplicar un color identificativo dependiendo de dichos valores.">
            <FormControlLabel
              value="start"
              control={<Checkbox checked={Boolean(tarjeta.mostrarSemaforo)} />}
              label="Mostrar semáforo"
              labelPlacement="end"
              className={classes.elements}
              onChange={handleSemaphoreCheckBox}
              disabled={controlStates.deshabilitarSemaforo}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Grow in={Boolean(tarjeta.mostrarSemaforo)}>
            <TextField
              variant="outlined"
              onChange={(event) => handleSemaphoreValues(event, 'min')}
              id="valorMínimo"
              label="Valor Mínimo"
              className={classes.elements}
              value={dataObject.semaforo ? dataObject.semaforo.min : ''}
            ></TextField>
          </Grow>
        </Grid>
        <Grid item xs={3}>
          <Grow
            in={Boolean(tarjeta.mostrarSemaforo)}
            style={{ transformOrigin: '0 0 0' }}
            {...(tarjeta.mostrarSemaforo ? { timeout: 1000 } : {})}
          >
            <TextField
              variant="outlined"
              onChange={(event) => handleSemaphoreValues(event, 'max')}
              id="valorMaximo"
              label="Valor Máximo"
              className={classes.elements}
              value={dataObject.semaforo ? dataObject.semaforo.max : ''}
            ></TextField>
          </Grow>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={3}
        justifyContent="center"
        alignItems={'center'}
        component={'div'}
        ref={drag}
      >
        <Grid
          item
          xs={6}
          md={10}
          maxHeight={'166px'}
          minHeight={'166px'}
          style={{ cursor: obj.cardReady ? 'move' : 'not-allowed' }}
        >
          <Box component="div" className={classes.boxColumn}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Icon color="secondary" className={classes.iconMedida}>
                <IconosTiposMedida
                  icono={obj.medida?.codigo as string}
                  style={{ fontSize: '1.35rem', width: '1em', height: '1em' }}
                  color={'primary'}
                />
              </Icon>

              <Icon
                className={classes.extraIcon}
                component={'div'}
                sx={{ display: tarjeta.mostrarGrafica ? 'inherit' : 'none' }}
              >
                <AddIcon color="primary" fontSize="large" />
              </Icon>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography className={classes.value} style={{ position: 'relative' }}>
                <>
                  <span>Valor</span> <span className={classes.measure}></span>
                </>
              </Typography>
            </Box>
            <Typography className={classes.valueText}>{tarjeta.titulo ?? 'Título'}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
