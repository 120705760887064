import React, { useState } from 'react';
import { ButtonGroup, Grid, IconButton, TextField, Typography } from '@mui/material';
import UpIcon from '@mui/icons-material//ArrowDropUp';
import DownIcon from '@mui/icons-material//ArrowDropDown';
import LeftIcon from '@mui/icons-material//ArrowLeft';
import RightIcon from '@mui/icons-material//ArrowRight';

import useStyles from './styles';
import { section } from '../../../../../../sections/resources/interfaces/interfaceSection';
import { dictionary_cctv } from '../../../../../../../resources/enums/plainText';
/**
 * Componente ControlPtzSection: es un grupo de botones para el tipo controlPtz.
 * Es utilizado para controlar una cámara PTZ
 * La etiqueta y el valor de cada boton está establecido en el tipo de sections despues de un '#'
 * @param props
 */
export const ControlPtzSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();
  const [speed, setSpeed] = useState<number>();

  const handleSpeed = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSpeed(parseInt(event.target.value));
  };

  const isDisabled = (): boolean => {
    return !(speed && speed > 0 && speed < 9);
  };

  return (
    <>
      <div key="speed" className={classes.section}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography id="title-speed" variant="subtitle1" className={classes.etiquetaSection}>
            {dictionary_cctv.VELOCIDAD}
          </Typography>
          <TextField
            id="speed"
            type="number"
            InputProps={{
              inputProps: { min: '1', max: '8' },
              className: classes.widthInputSection
            }}
            onChange={handleSpeed}
          />
        </Grid>
      </div>
      <div key="horizontal" className={classes.section}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography id="title-speed" variant="subtitle1" className={classes.etiquetaSection}>
            {dictionary_cctv.HORIZONTAL}
          </Typography>
          <div className={classes.botonesPtz}>
            <ButtonGroup
              disableElevation
              variant="outlined"
              color="secondary"
              size="small"
              aria-label={props.section.nombre}
            >
              <IconButton
                aria-label="Left"
                id="Left"
                className={classes.buttonSection}
                disabled={isDisabled()}
                onClick={() =>
                  props.handleChange(props.section, JSON.stringify({ code: 'Left', speed: speed }))
                }
              >
                <LeftIcon aria-label="Left" id="Left" className={classes.iconSection} />
              </IconButton>
              <IconButton
                aria-label="Right"
                id="Right"
                className={classes.buttonSection}
                disabled={isDisabled()}
                onClick={() =>
                  props.handleChange(props.section, JSON.stringify({ code: 'Right', speed: speed }))
                }
              >
                <RightIcon aria-label="Right" id="Right" className={classes.iconSection} />
              </IconButton>
            </ButtonGroup>
          </div>
        </Grid>
      </div>
      <div key="vertical" className={classes.section}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography id="title-speed" variant="subtitle1" className={classes.etiquetaSection}>
            {dictionary_cctv.VERTICAL}
          </Typography>
          <div className={classes.botonesPtz}>
            <ButtonGroup
              disableElevation
              variant="outlined"
              color="secondary"
              size="small"
              aria-label={props.section.nombre}
              orientation="vertical"
            >
              <IconButton
                aria-label="Up"
                id="Up"
                className={classes.buttonSection}
                disabled={isDisabled()}
                onClick={() =>
                  props.handleChange(props.section, JSON.stringify({ code: 'Up', speed: speed }))
                }
              >
                <UpIcon aria-label="Up" id="Up" className={classes.iconSection} />
              </IconButton>
              <IconButton
                aria-label="Down"
                id="Down"
                className={classes.buttonSection}
                disabled={isDisabled()}
                onClick={() =>
                  props.handleChange(props.section, JSON.stringify({ code: 'Down', speed: speed }))
                }
              >
                <DownIcon aria-label="Down" id="Down" className={classes.iconSection} />
              </IconButton>
            </ButtonGroup>
          </div>
        </Grid>
      </div>
    </>
  );
};
