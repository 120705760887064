import React from 'react';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { useReducerAcciones } from '../hooks/useReducerAcciones';
import { regla } from '../../reglas/resources/interfaces/interfaceRegla';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { dictionary_generic, enumLabel } from '../../../resources/enums/plainText';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Grid, TextField } from '@mui/material';

export function FormularioCambiarEstadoRegla(props: { state?: any; events?: any }): JSX.Element {
  const { stateAcciones, events: eventosAcciones } = useReducerAcciones(
    props.events,
    enumTiposAcciones.cambio_estado_regla
  );

  const { setCambioEstadoRegla, onChangeCambioEstadoRegla, handleCreateAccion } = eventosAcciones;
  const { state } = props;
  const { cambioEstadoReglaCompleta } = stateAcciones;
  const { handleAutocompleteObjetoChange } = useEventChange(
    cambioEstadoReglaCompleta,
    setCambioEstadoRegla
  );

  return (
    <form id="panelCambiarEstadoRegla">
      <Grid container direction="column">
        <Grid container item direction="row">
          <Grid item xs={9}>
            <Autocomplete
              value={cambioEstadoReglaCompleta.regla}
              disableClearable
              id="regla"
              options={state.listaReglas as regla[]}
              size="small"
              getOptionLabel={(option) => option.nombre}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.REGLAS} variant="outlined" />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  color="secondary"
                  id={'activo'}
                  checked={cambioEstadoReglaCompleta.regla.activo}
                  onChange={onChangeCambioEstadoRegla}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label={enumLabel.ACTIVAR_DESACTIVAR}
              labelPlacement="top"
            />
          </Grid>
        </Grid>
        <Grid container direction="row-reverse">
          <Button variant="contained" color="secondary" onClick={handleCreateAccion}>
            {dictionary_generic.CREAR}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
