import React from 'react';
import TypeWriterEffect from 'react-typewriter-effect';
import avatarTelia from '../../../resources/images/telia_avatar.png';
import useStyles from './styles';
/**
 * @function TextBoxTelia
 * @description Componente que crea un textbox con la imagen de Telia, como si fuera el diálogo de un videojuego.
 * @param { string } texto Texto a mostrar en el TextBox. Ten cuidado a la longitud del texto, ya que se puede salir del bocadillo.
 * @param { number } velocidad (Opcional) Velocidad de escritura en milisegundos. Por defecto, está en 20 ms.
 * @returns { JSX.Element } Devuelve el componente JSX con el texto indicado.
 */
export function TextBoxTelia(props: { texto: string; velocidad?: number }): JSX.Element {
  const { texto } = props;
  const velocidad = props.velocidad !== undefined ? props.velocidad : 20;
  const classes = useStyles();
  return (
    <div style={{ position: 'relative', marginTop: '15px' }}>
      <img
        src={avatarTelia}
        style={{
          zIndex: 1,
          width: '30%',
          height: '30%',
          float: 'left',
          display: 'inline'
        }}
        id={'teliaAvatar'}
      />
      <div className={classes.cajaTexto}>
        <TypeWriterEffect
          textStyle={{
            fontFamily: 'Montserrat',
            color: '#3F3D56',
            fontWeight: 500,
            fontSize: '1em'
          }}
          cursorColor="#3F3D56"
          text={texto}
          typeSpeed={velocidad}
          hideCursorAfterText={true}
        />
      </div>
    </div>
  );
}
