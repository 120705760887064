import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  contenido_final_tarjeta: {
    padding: '0 8px'
  },
  contenido_tarjeta: {
    padding: '0px 8px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  listItemTitulo: {
    paddingBottom: '0px'
  },
  textTitulo: {
    margin: '0 15px'
  },
  buttonCerrar: {
    padding: '0 6px 0 6px'
  },
  listUbicacion: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  botones_header: {
    height: '25px',
    width: '25px'
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    margin: '0px 0px 0px 0px'
  },
  iconRoot: {
    textAlign: 'center'
  },
  dimmer: {
    maxWidth: 200
  },
  controles: {
    textAlign: 'center'
  },
  botones_tarjeta: {
    padding: '5px'
  },
  imagen: {
    padding: '5px',
    display: 'flex'
  },
  canalesIteraccion: {
    paddingBottom: '10vh'
  },
  lista: {
    padding: '0',
    textAlign: 'center',
    listStyleType: 'disc',
    columns: 2,
    listStylePosition: 'inside'
  },
  gridTansition: {
    padding: '8px 24px 8px 24px'
  },
  titleTransition: {
    textAlign: 'center'
  },
  buttonTransition: {
    backgroundColor: theme.palette.secondary.main,
    width: '30px',
    height: '30px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main + 'dd'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  tooltip: {
    fontSize: '20px'
  },
  iconTransition: {
    color: '#FFFFFF'
  },
  iconAlerta: {
    height: '30px',
    width: '30px'
  },
  listSincronizar: {
    padding: 0
  },
  buttonSincronizar: {
    padding: 0
  },
  iconSincronizar: {
    width: '25px',
    height: '25px'
  },
  textSincronizar: {
    color: '#000000DE'
  },
  listItemPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'inline-flex'
  },
  tarjetaText: {
    fontSize: '0.3rem'
  }
}));
export default useStyles;
