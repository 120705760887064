import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';

/**
 * @readonly
 * @enum {string} MeasureText
 * Define el texto de cada medida, necesario para las gráficas comparativas cuando viene algun valor sin datos.
 * @property Consumo
 * @property Generado
 * @property ORP del Agua
 * @property pH del Agua
 * @property Temperatura
 * @property Capacidad de llenado
 * @property Entrada contaminación
 * @property Salida contaminación
 * @property Entrada caudal
 * @property Salida caudal
 */
export enum MeasuresText {
  ' Consumo' = TiposMedida.CONSUMO,
  ' Generado' = TiposMedida.GENERADO,
  ' ORP del Agua' = TiposMedida.ORP_AGUA_ABASTECIMIENTO,
  ' pH del Agua ' = TiposMedida.PH_AGUA_ABASTECIMIENTO,
  ' Temperatura agua potable' = TiposMedida.TEMPERATURA_AGUA_ABASTECIMIENTO,
  ' Capacidad de llenado ' = TiposMedida.NIVEL_LLENADO_AGUA,
  ' Entrada contaminación ' = TiposMedida.CONTAMINACION_ENTRADA,
  ' Salida contaminación ' = TiposMedida.CONTAMINACION_SALIDA,
  ' Entrada caudal ' = TiposMedida.CONSUMO_CAUDAL_ENTRADA,
  ' Salida caudal ' = TiposMedida.CONSUMO_CAUDAL,
  ' Nivel de llenado ' = TiposMedida.DISTANCIA,
  ' Temperatura ' = TiposMedida.TEMPERATURA,
  ' Humedad ' = TiposMedida.HUMEDAD,
  ' Velocidad del viento ' = TiposMedida.VELOCIDAD_VIENTO,
  ' Precipitaciones ' = TiposMedida.LLUVIA,
  'Dióxido de carbono' = TiposMedida.CO_2,
  'Monóxido de carbono' = TiposMedida.MONOXIDO_CARBONO,
  'Dióxido de nitrógeno' = TiposMedida.DIOXIDO_NITROGENO,
  'Dióxido de azufre' = TiposMedida.DIOXIDO_AZUFRE,
  'Partículas PM1' = TiposMedida.PARTICULAS_PM1,
  'Partículas PM2.5' = TiposMedida.PARTICULAS_PM25,
  'Partículas PM10' = TiposMedida.PARTICULAS_PM10,
  'Ozono' = TiposMedida.OZONO,
  'Índice de calidad del aire' = TiposMedida.INDICE_CALIDAD_AIRE,
  'Radiación solar' = TiposMedida.RADIACION_SOLAR,
  'Intensidad del sonido' = TiposMedida.INTENSIDAD_SONIDO,
  'Peso' = TiposMedida.PESO,
  'Decibelios' = TiposMedida.DECIBELIOS,
  'Brillo' = TiposMedida.BRILLO,
  'Presión' = TiposMedida.PRESION,
  'Voltaje' = TiposMedida.VOLTAJE,
  'Lúmenes' = TiposMedida.LUMENES
}
