import axios from 'axios';
import { encriptarJSON, desencriptarRespuesta } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path = '';
let respuesta: any;

/**
 * Servicio que obtiene todos los devices filtrados por un plugin concreto
 * @param { string } servicio  servicio al que vamos a llamar
 * @param { number } idPlugin id del plugin cuyos devies asociados queremos obtener
 * @returns { Promise<any> } Promesa que contiene un listado de devices asociados a un plugin concreto
 */
export async function devicesPlugin(servicio: string, idPlugin: number): Promise<any> {
  path = servicio + '/plugin/';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idPlugin: idPlugin }),
        {
          timeout: 10000,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Servicio que obtiene todos los devices filtrados por tipo
 * @param { string } servicio  servicio al que vamos a llamar
 * @param { number } idDevice id del plugin cuyos devies asociados queremos obtener
 * @returns { Promise<any> } Promesa que contiene un listado de devices asociados a un plugin concreto
 */
export async function devicesTipoDevice(servicio: string, idPlugin: number): Promise<any> {
  path = servicio + '/tipoDevice/';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idDevice: idPlugin }),
        {
          timeout: 10000,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene la configuración por device
 * @param { string } servicio nombre del servicio al que se va llamar
 * @param { number } idDevice id del device cuya configuración queremos obtener
 * @returns  { Promise<any> } promesa que contiene la configuración de un device
 */
export async function configuracionValorByDevice(servicio: string, idDevice: number): Promise<any> {
  path = servicio + '/configuracionValor/';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idDevice: idDevice }),
        {
          timeout: 10000,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene la info del device a partir del un dispositivo
 * @param { string } servicio nombre del servicio al que se va llamar
 * @param { number } idDevice id del dispositivo cuya info del device queremos obtener
 * @returns  { Promise<any> } promesa que contiene la info de un device
 */
export async function infoDevicePlugin(servicio: string, idDevice: number): Promise<any> {
  path = servicio + `/infoDevicePlugin/${idDevice}`;
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Funciíon que obtiene la configuración de un tipo device
 * @param { string } servicio servicio al que queremos llamar
 * @param { number } idTipoDevice id del tipo device del que queremos saber la configuración
 * @returns { Promise<any> }  Promesa que contiene la configuración de un tipo device
 */
export async function configuracionTipoDevice(
  servicio: string,
  idTipoDevice: number
): Promise<any> {
  path = servicio + '/configuracion/';
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idTipoDevice: idTipoDevice }),
        {
          timeout: 10000,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
/**
 * Función que obtiene la info del device a partir del un dispositivo
 * @param { string } servicio nombre del servicio al que se va llamar
 * @param { number } idDevice id del dispositivo cuya info del device queremos obtener
 * @returns  { Promise<any> } promesa que contiene la info de un device
 */
export async function getAllDeviceFree(servicio: string, idDevice: number): Promise<any> {
  path = servicio + `/getAllDeviceFree/${idDevice != null ? idDevice : 0}`;
  try {
    if (localStorage.getItem('token')) {
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        headers: getHeaders(dictionary_entornos.BACK),
        timeout: 10000
      });
    }

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
