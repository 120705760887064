import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';

/**
 * Función que devuelve el registro para eliminar,modificar o activar correctamente, si ordenabas la tabla se perdía el registro, por lo tanto se creó esta función.
 * Podría hacerse directamente en el código desde donde se llama, pero facilita la lectura y comprensión del código ya que acceder a los objetos que devuelve el componente puede ser un poco lioso.
 * @param datos
 * @param index
 */
export function buscarRegistro(
  datos: Array<dispositivo>,
  index: number
): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objeto: any;
  indice: number;
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let objeto: any = undefined;
  let indice = 0;

  objeto = datos[index];
  indice = index;

  return { objeto, indice };
}
