import { useRef } from 'react';
import {
  obtenerCanalesDispositivo,
  obtenerCanalesDatosDispositivo,
  obtenerNombresCanalesGrupo
} from '../services/canales';

export function useObtenerCanales(): {
  obtenerCanalesByDispositivo: (servicio: string, idDispositivo: number) => Promise<any>;
  obtenerCanalesDatosByDispositivo: (servicio: string, idDispositivo: number) => Promise<any>;
  obtenerNombresCanalesByGrupo: (servicio: string, idGrupo: number) => Promise<any>;
} {
  const listaDatos = useRef();

  /**
   * Función que obtiene todos los canales filtrados por dispositivo
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { number } idDispositivo id del dispositivo cuyos canales queremos obtener
   * @return { useRef } listaDatos lista que contiene el resultado de la petición
   */
  async function obtenerCanalesByDispositivo(
    servicio: string,
    idDispositivo: number
  ): Promise<any> {
    const respuesta: any = await obtenerCanalesDispositivo(servicio, idDispositivo);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   *  Función que obtiene los elementos de canalesDatos por dispositivo
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { number } idDispositivo id del dispositivo cuyos canales queremos obtener
   * @return { useRef } listaDatos lista que contiene el resultado de la petición
   */
  async function obtenerCanalesDatosByDispositivo(
    servicio: string,
    idDispositivo: number
  ): Promise<any> {
    const respuesta: any = await obtenerCanalesDatosDispositivo(servicio, idDispositivo);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   *  Función que obtiene los nombres de los canales relacionados con un grupo
   * @param { servicio } servicio servicio al que tenemos que llamar
   * @param { number } idGrupo id del grupo cuyos canales queremos obtener
   * @return { useRef } listaDatos lista que contiene el resultado de la petición
   */
  async function obtenerNombresCanalesByGrupo(
    servicio: string,
    idDispositivo: number
  ): Promise<any> {
    const respuesta: any = await obtenerNombresCanalesGrupo(servicio, idDispositivo);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result.map((canal: any) => canal.nombre);
      return listaDatos.current;
    } else {
      return [];
    }
  }

  return {
    obtenerCanalesByDispositivo,
    obtenerCanalesDatosByDispositivo,
    obtenerNombresCanalesByGrupo
  };
}
