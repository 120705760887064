import React, { useEffect } from 'react';
import { Grid, Icon, Typography, Divider, Tooltip, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tarjeta } from '../principal/common/Tarjeta';
import { dictionary_alertas } from '../../../resources/enums/plainText';
import { IconosComponentes } from '../../common/iconosComponentes/iconosComponentes';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { useReducerHomeModulos } from './hooks/useReducerHomeModulos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Modulos } from '../../../resources/enums/enumModulo';
import { useStyles } from './styles';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { Videovigilancia } from './videovigilancia/Videovigilancia';
import { BackdropTarjetasModulos } from './common/BackdropTarjetasModulos';
import { ModuleStructure } from './estructuraModulos/ModuleStructure';
import UserContext from '../../../context/UserContext';

export function HomeComun(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();

  const { datosUsuarioContext } = React.useContext(UserContext);

  const { events, moduloSeleccionado, componentesFiltrados } = useReducerHomeModulos();

  const [tarjetas, setTarjetas] = React.useState<Array<JSX.Element>>([]);
  const arrayComponents = [
    enumComponentes.ADMINISTRACION,
    enumComponentes.ALERTAS_REGISTRADAS,
    enumComponentes.GRAFICAS,
    enumComponentes.INFORMES,
    enumComponentes.ALERTAS_PENDIENTES
  ];

  //Se crea un componente aparte para realizar correctamente la renderización del Home y actué como compoente individual para que su carga sea independiente de las demás.
  function AlertasRegistradas(): JSX.Element {
    return (
      <Tooltip title="Ir a alertas registradas" key={'Alertas Registradas'}>
        <Grid item xs={6} md={4} lg={6}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'start'}
            className={classes.button_tarjetaHome}
            onClick={(event: any) => events.handleClickTarjeta(event)}
            id={enumComponentes.ALERTAS_REGISTRADAS}
          >
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <Badge
                badgeContent={'i'}
                color="primary"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className={'badge-home'}
              >
                <IconosComponentes componente={enumComponentes.ALERTAS_PENDIENTES} />
              </Badge>

              <Icon>
                <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
              </Icon>
            </Box>

            <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
              {dictionary_alertas.ALERTAS_REGISTRADAS}
            </Typography>
            <Typography
              paragraph
              width={'100%'}
              className={classes.typographyNumeroAlertas}
              position={'relative'}
            ></Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
    );
  }

  //Se crea un componente aparte para realizar correctamente la renderización del Home y actué como compoente individual para que su carga sea independiente de las demás.
  function AlertasPendientes(): JSX.Element {
    const [openBackdropAlertasSinResolver, setOpenBackdropAlertasSinResolver] =
      React.useState(true);

    const { controllerRequest } = useControlador();
    const [numAlertasPendientes, setNumAlertasPendientes] = React.useState([]);

    const dispositivosPermitidos = datosUsuarioContext.dispositivos
      .filter(({ idModulo }: { idModulo: number }) => idModulo === moduloSeleccionado)
      .map((item) => item.idDispositivo);

    React.useEffect(() => {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: 'alertas/numeroAlertas/sinResolver',
          object: { idModulo: moduloSeleccionado, idDispositivos: dispositivosPermitidos }
        }
      })
        .then((response) => {
          setNumAlertasPendientes(response.numeroAlertas);
          setOpenBackdropAlertasSinResolver(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    return (
      <Tooltip title="Ir a alertas pendientes" key={'Alertas Pendientes'}>
        <Grid item xs={6} md={4} lg={6}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'start'}
            className={classes.button_tarjetaHome}
            onClick={(event: any) => events.handleClickTarjeta(event)}
            id={enumComponentes.ALERTAS_PENDIENTES}
          >
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <Badge
                badgeContent={'!'}
                color="secondary"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className={'badge-home'}
              >
                <IconosComponentes componente={enumComponentes.ALERTAS_PENDIENTES} />
              </Badge>
              <Icon>
                <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
              </Icon>
            </Box>

            <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
              {dictionary_alertas.ALERTAS_PENDIENTES}
            </Typography>

            <Typography
              paragraph
              width={'100%'}
              className={classes.typographyNumeroAlertas}
              position={'relative'}
            >
              <BackdropTarjetasModulos open={openBackdropAlertasSinResolver} />
              {numAlertasPendientes && !openBackdropAlertasSinResolver
                ? numAlertasPendientes || ''
                : ''}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
    );
  }

  // Genera tarjetas deshabilitadas cuando el usuario no tiene acceso a ellas.
  function TarjetaDeshabilitada(element: enumComponentes, altTitle?: string): JSX.Element {
    const titulo =
      element.includes('Informes') && moduloSeleccionado != 3
        ? 'No hay informes disponibles'
        : 'No tienes permisos suficientes';

    if (element == enumComponentes.ADMINISTRACION) {
      return TarjetaDeshabilitadaAdministracion();
    }

    return (
      <Tooltip title={altTitle ?? titulo} key={`${element}`}>
        <Grid
          item
          xs={6}
          md={4}
          lg={element.includes('Alertas') ? 6 : 3}
          style={{ opacity: '0.5' }}
        >
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'start'}
            className={classes.button_tarjetaHome}
          >
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <IconosComponentes componente={element} />
              <Icon>
                <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
              </Icon>
            </Box>
            <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
              {element}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
    );
  }
  function TarjetaDeshabilitadaAdministracion(): JSX.Element {
    const titulo = 'No tienes permisos suficientes';

    return (
      <>
        <Tooltip title={titulo} key={`${enumComponentes.CANALES}`}>
          <Grid item xs={6} md={4} lg={3} style={{ opacity: '0.5' }}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'start'}
              className={classes.button_tarjetaHome}
            >
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <IconosComponentes componente={enumComponentes.CANALES} />
                <Icon>
                  <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
                </Icon>
              </Box>
              <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
                {enumComponentes.CANALES}
              </Typography>
            </Tarjeta>
          </Grid>
        </Tooltip>
        <Tooltip title={titulo} key={`${enumComponentes.DISPOSITIVOS}`}>
          <Grid item xs={6} md={4} lg={3} style={{ opacity: '0.5' }}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'start'}
              className={classes.button_tarjetaHome}
            >
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <IconosComponentes componente={enumComponentes.DISPOSITIVOS} />
                <Icon>
                  <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
                </Icon>
              </Box>
              <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
                {enumComponentes.DISPOSITIVOS}
              </Typography>
            </Tarjeta>
          </Grid>
        </Tooltip>
      </>
    );
  }

  const comp: any = {
    [enumComponentes.MAPA]: <></>,
    [enumComponentes.ADMINISTRACION]: (
      <>
        <Tooltip title={`Ir a ${enumComponentes.CANALES}`} key={'Canales'}>
          <Grid item xs={6} md={4} lg={3}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'start'}
              className={classes.button_tarjetaHome}
              onClick={events.handleClickTarjeta}
              id={enumComponentes.CANALES}
            >
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <IconosComponentes componente={enumComponentes.CANALES} />
                <Icon>
                  <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
                </Icon>
              </Box>

              <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
                {enumComponentes.CANALES}
              </Typography>
            </Tarjeta>
          </Grid>
        </Tooltip>
        <Tooltip title={`Ir a ${enumComponentes.DISPOSITIVOS}`} key={'Dispositivos'}>
          <Grid item xs={6} md={4} lg={3}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'start'}
              className={classes.button_tarjetaHome}
              onClick={events.handleClickTarjeta}
              id={enumComponentes.DISPOSITIVOS}
            >
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <IconosComponentes componente={enumComponentes.DISPOSITIVOS} />
                <Icon>
                  <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
                </Icon>
              </Box>

              <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
                {enumComponentes.DISPOSITIVOS}
              </Typography>
            </Tarjeta>
          </Grid>
        </Tooltip>
      </>
    ),
    [enumComponentes.INFORMES]: (
      <Tooltip title={`Ir a ${enumComponentes.INFORMES}`} key={'Informes'}>
        <Grid item xs={6} md={4} lg={3}>
          <Tarjeta
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'start'}
            className={classes.button_tarjetaHome}
            onClick={events.handleClickTarjeta}
            id={enumComponentes.INFORMES}
          >
            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <IconosComponentes componente={enumComponentes.INFORMES} />
              <Icon>
                <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
              </Icon>
            </Box>

            <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
              {enumComponentes.INFORMES}
            </Typography>
          </Tarjeta>
        </Grid>
      </Tooltip>
    ),
    [enumComponentes.GRAFICAS]:
      moduloSeleccionado === Modulos.VIDEOVIGILANCIA ? (
        TarjetaDeshabilitada(enumComponentes.GRAFICAS, 'No hay gráficas disponibles.')
      ) : (
        <Tooltip title={`Ir a ${enumComponentes.GRAFICAS}`} key={'Graficas'}>
          <Grid item xs={6} md={4} lg={3} onClick={events.handleClickGraficas}>
            <Tarjeta
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'start'}
              className={classes.button_tarjetaHome}
            >
              <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <IconosComponentes componente={enumComponentes.GRAFICAS} />
                <Icon>
                  <ArrowForwardIosIcon htmlColor={theme.palette.primary.main} />
                </Icon>
              </Box>

              <Typography paragraph width={'100%'} className={classes.tarjetaDialogos}>
                {enumComponentes.GRAFICAS}
              </Typography>
            </Tarjeta>
          </Grid>
        </Tooltip>
      ),
    [enumComponentes.ALERTAS_PENDIENTES]: <AlertasPendientes />,
    [enumComponentes.ALERTAS_REGISTRADAS]: <AlertasRegistradas />
  };

  useEffect(() => {
    const arr: Array<any> = [];

    if (componentesFiltrados.length) {
      const alertFound = componentesFiltrados.find(
        (componente: any) =>
          componente.nombre === 'Alertas' && componente.idModulo === moduloSeleccionado
      );
      if (alertFound) {
        componentesFiltrados.push(
          {
            nombre: 'Alertas pendientes',
            permiso: alertFound.permiso,
            idModulo: alertFound.idModulo
          },
          {
            nombre: 'Alertas registradas',
            permiso: alertFound.permiso,
            idModulo: alertFound.idModulo
          }
        );
      }
      arrayComponents.forEach((element: any) => {
        const com: any = componentesFiltrados.find(
          (componente) =>
            componente.nombre === element && componente.idModulo === moduloSeleccionado
        );
        if (!com) {
          arr.push(TarjetaDeshabilitada(element));
        } else {
          arr.push(comp[element]);
        }
      });
      setTarjetas(arr);
    }

    return () => setTarjetas([]);
  }, [moduloSeleccionado]);

  return (
    <Grid container flexWrap={'nowrap'} flexDirection={'column'} className={classes.gridContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5} className={classes.gridComponentes}>
          {componentesFiltrados.find((item) => item.nombre === enumComponentes.MAPA) ===
          undefined ? (
            <></>
          ) : (
            <Tooltip title={`Ir a ${enumComponentes.MAPA}`} key={'Mapa'}>
              <Grid item xs={12} className={classes.alto100}>
                <Tarjeta
                  className={classes.tarjeta_mapa}
                  style={{ cursor: 'pointer' }}
                  onClick={events.handleClickTarjeta}
                  id={enumComponentes.MAPA}
                  height={'100%'}
                ></Tarjeta>
              </Grid>
            </Tooltip>
          )}
        </Grid>
        <Grid item container xs={12} lg={7} spacing={2}>
          {tarjetas.length && tarjetas.map((tarjeta: any) => tarjeta)}
        </Grid>
      </Grid>

      {componentesFiltrados.find(
        (item) => item.nombre === enumComponentes.HOME && item.idModulo === moduloSeleccionado
      ) === undefined ? (
        <></>
      ) : (
        <>
          <Divider orientation="horizontal" className={classes.divider} />
          {moduloSeleccionado !== Modulos.VIDEOVIGILANCIA ? (
            <ModuleStructure
              userId={datosUsuarioContext.usuario.id as number}
              selectedModule={moduloSeleccionado}
            />
          ) : (
            <Videovigilancia />
          )}
        </>
      )}
    </Grid>
  );
}
