import { useRef } from 'react';
import {
  configuracionTipoDevice,
  configuracionValorByDevice,
  devicesPlugin,
  devicesTipoDevice,
  infoDevicePlugin,
  getAllDeviceFree
} from '../services/servicioDevice';

export function useDevice(): {
  obtenerDevicesByPlugin: (servicio: string, idPlugin: number) => Promise<any>;
  obtenerConfiguraciónTipoDevice: (servicio: string, idTipoDevice: number) => Promise<any>;
  obtenerConfiguracionValorByDevice: (servicio: string, idDevice: number) => Promise<any>;
  obtenerDevicesByTipoDevice: (servicio: string, idTipoDevice: number) => Promise<any>;
  obtenerInfoDevicePlugin: (servicio: string, idDispositivo: number) => Promise<any>;
  getAllDevicesFree: (servicio: string, idDevice: number) => Promise<any>;
} {
  const listaDatos = useRef();

  /**
   * Función que obtiene los devices de un plugin
   * @param { string } servicio  servicio al que queremos llamar
   * @param { number } idPlugin id del plugin porque el queremos filtrar
   * @returns { Promise<any> } Promesa que contiene un listado de devices filtrados por plugin
   */
  async function obtenerDevicesByPlugin(servicio: string, idPlugin: number): Promise<any> {
    const respuesta = await devicesPlugin(servicio, idPlugin);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que obtiene los devices de un tipo de device
   * @param { string } servicio  servicio al que queremos llamar
   * @param { number } idTipoDevice id del tipo de device por el que queremos filtrar
   * @returns { Promise<any> } Promesa que contiene un listado de devices filtrados por tipo de device
   */
  async function obtenerDevicesByTipoDevice(servicio: string, idTipoDevice: number): Promise<any> {
    const respuesta = await devicesTipoDevice(servicio, idTipoDevice);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que obtiene la configuración por tipo de device
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idTipoDevice id del tipo device del que queremos la configuración
   * @returns { Promise<any> } promesa que contiene la configuración de un tipo de device
   */
  async function obtenerConfiguraciónTipoDevice(
    servicio: string,
    idTipoDevice: number
  ): Promise<any> {
    const respuesta: any = await configuracionTipoDevice(servicio, idTipoDevice);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   *  Función que obtiene la info de un device y de su plugin a partir del id
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idDevice id del device del que queremos la info
   * @returns { Promise<any> } promesa que contiene la info de un device
   */
  async function obtenerInfoDevicePlugin(servicio: string, idDevice: number) {
    const respuesta: any = await infoDevicePlugin(servicio, idDevice);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   *  Función que obtiene la configuración por device
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idTipoDevice id del device del que queremos la configuración
   * @returns { Promise<any> } promesa que contiene la configuración de un device
   */
  async function obtenerConfiguracionValorByDevice(servicio: string, idDevice: number) {
    const respuesta: any = await configuracionValorByDevice(servicio, idDevice);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }
  /**
   *  Función que obtiene todos los devices que no estan asignados a un dispositivo, añade el device del dispositivo
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idDevice id del device del que queremos la info
   * @returns { Promise<any> } promesa que contiene la info de un device
   */
  async function getAllDevicesFree(servicio: string, idDevice: number) {
    const respuesta: any = await getAllDeviceFree(servicio, idDevice);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  return {
    obtenerConfiguraciónTipoDevice,
    obtenerConfiguracionValorByDevice,
    obtenerDevicesByPlugin,
    obtenerDevicesByTipoDevice,
    obtenerInfoDevicePlugin,
    getAllDevicesFree
  };
}
