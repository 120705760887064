import React from 'react';
import { Button, Grid, Typography, Box, TextField } from '@mui/material';
import useStyles from './styles';
import { useEventChange } from '../../hooks/useEventChange/useEventChange';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { enumLabel, dictionary_reglas, dictionary_generic } from '../../resources/enums/plainText';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import { ReglasManager } from './ReglasManager';
import { enumComponentes } from '../../resources/enums/enumComponente';

import { Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DialogoCrearAccion } from '../accionesMotor/dialogos/DialogoCrearAccion';
import { Tarjeta } from '../home/principal/common/Tarjeta';
import useStylesTarjetas from './stylesTarjetas';
import CloseIcon from '@mui/icons-material/Close';
import { TeliaMostrarAccion } from '../accionesMotor/TeliaMostrarAccion';
import { TipoCondicionTemporal } from './resources/enums/enumTipoCondicionTemporal';
import { DialogoCrearCondicion } from '../condiciones/dialogos/DialogoCondiciones';
import { TeliaMostrarCondicion } from '../condiciones/TeliaMostrarCondicion';

export function ReglasModificar(props: {
  state: any;
  events: any;
  stateCondiciones: any;
}): JSX.Element {
  const { state, events, stateCondiciones } = props;
  const { listaCondiciones, listaAcciones } = state;

  const classes = useStyles();
  const classesTarjetas = useStylesTarjetas();
  const { handleInputChange, handleInputInvalid } = useEventChange(
    state.reglaReady.regla,
    events.setRegla
  );

  const { HORA, SEMANA, FECHA, SOLAR } = TipoCondicionTemporal;
  const diasSemana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];

  return (
    <div className={classes.root}>
      <form id="formReglas" onSubmit={events.handleSubmitEditar} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" marginBottom="30px">
              <VolverAtras backTo={<ReglasManager key={enumComponentes.REGLAS} />} />
              <Typography variant="h5" className={classes.tituloFormulario}>
                {dictionary_reglas.MODIFICAR_REGLA}
              </Typography>
            </Box>
          </Grid>

          <Grid item container xs={12} sm={12} md={8}>
            <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
              {dictionary_reglas.INFORMACION_REGLA}
            </Typography>

            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={4} md={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.NOMBRE_REGLA}
                  id="nombre"
                  type="text"
                  required
                  onChange={handleInputChange}
                  onKeyDown={handleInputInvalid}
                  onPaste={handleInputInvalid}
                  defaultValue={state.reglaReady.regla.nombre}
                />
              </Grid>

              <Grid item xs={12} sm={10} md={7}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  label={enumLabel.DESCRIPCION_REGLA}
                  id="descripcion"
                  type="text"
                  required
                  onChange={handleInputChange}
                  onKeyDown={handleInputInvalid}
                  onPaste={handleInputInvalid}
                  defaultValue={state.reglaReady.regla.descripcion}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={10} justifyContent="flex-end">
              <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
                {dictionary_reglas.CONDICIONES}
              </Typography>
              {/* Aquí va la listilla con las condiciones */}
              <Grid item container xs={12} sm={10} className={classes.gridLista} spacing={2}>
                {listaCondiciones.map((condicion: any, i: React.Key | null | undefined) => {
                  let label = '';
                  if (condicion.canal)
                    label = `${condicion.canal.nombre} ${events.convertirOperadorAFrase(
                      condicion.operador.operador
                    )} ${condicion.valor}`;

                  if (condicion.tipoTemporal) {
                    const mensajesTemporales = {
                      [HORA]: () => {
                        const hora = new Date(Number.parseInt(condicion.valor));
                        return (
                          'Se ejecuta ' +
                          events.convertirOperadorAFrase(
                            condicion.operador.operador,
                            condicion.tipoTemporal
                          ) +
                          ' ' +
                          hora.toTimeString().split(':')[0] +
                          ':' +
                          hora.toTimeString().split(':')[1]
                        );
                      },
                      [SEMANA]: () =>
                        'Se ejecuta ' +
                        events.convertirOperadorAFrase(
                          condicion.operador.operador,
                          condicion.tipoTemporal
                        ) +
                        ' ' +
                        diasSemana[new Date(Number.parseInt(condicion.valor)).getDay()],
                      [FECHA]: () =>
                        'Se ejecuta ' +
                        events.convertirOperadorAFrase(
                          condicion.operador.operador,
                          condicion.tipoTemporal
                        ) +
                        ' ' +
                        new Date(Number.parseInt(condicion.valor)).toLocaleString('es-ES', {
                          month: 'long',
                          day: 'numeric'
                        }),
                      [SOLAR]: () =>
                        'Se ejecuta ' +
                        events.convertirOperadorAFrase(
                          condicion.operador.operador,
                          TipoCondicionTemporal.SOLAR
                        ) +
                        ' ' +
                        condicion.valor
                    };
                    label = mensajesTemporales[condicion.tipoTemporal as TipoCondicionTemporal]();
                  }
                  if (condicion.nombreCanal) {
                    label = `${condicion.nombreCanal} de ${
                      condicion.grupo.codigo
                    } ${events.convertirOperadorAFrase(condicion.operador.operador)} ${
                      condicion.valor
                    }`;
                  }

                  return (
                    <Grid item md={3} key={i}>
                      <Tarjeta
                        flexDirection="column"
                        className={classesTarjetas.tarjetaCondicionAccion}
                        textAlign={'center'}
                      >
                        <IconButton
                          aria-label="close"
                          onClick={() => events.quitarCondicionLista(condicion)}
                          className={classes.buttonClose}
                        >
                          <CloseIcon />
                        </IconButton>
                        <Grid
                          container
                          style={{ height: '100%' }}
                          onClick={() => {
                            events.mostrarInfoCondicion(condicion);
                          }}
                        >
                          <Typography paragraph className={classesTarjetas.textoTarjeta}>
                            {label}
                          </Typography>
                        </Grid>
                      </Tarjeta>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.gridLista} spacing={2}>
                <Tooltip accessKey="a" title={dictionary_reglas.CREAR_CONDICION} arrow>
                  <IconButton
                    aria-label="add"
                    className={classes.buttonAdd}
                    onClick={() => events.toggleDialogoCondiciones()}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={10} justifyContent="flex-end">
              <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
                {dictionary_reglas.ACCIONES}
              </Typography>
              {/* Aquí va la listilla con las acciones */}
              <Grid item container xs={12} sm={10} className={classes.gridLista} spacing={2}>
                {listaAcciones.map((elemento: any, i: React.Key | null | undefined) => {
                  return (
                    <Grid item md={3} key={i}>
                      <Tarjeta
                        flexDirection="column"
                        className={classesTarjetas.tarjetaCondicionAccion}
                        textAlign={'center'}
                      >
                        <IconButton
                          aria-label="close"
                          onClick={() => events.quitarAccionLista(elemento)}
                          className={classes.buttonClose}
                        >
                          <CloseIcon />
                        </IconButton>

                        <Grid
                          container
                          onClick={() => events.mostrarInfoAccion(elemento)}
                          style={{ height: '100%', width: '100%' }}
                        >
                          <Typography paragraph className={classesTarjetas.textoTarjeta}>
                            {elemento.tipoAccion.tipo}
                          </Typography>
                        </Grid>
                      </Tarjeta>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12} sm={10} className={classes.gridLista} spacing={2}>
                <Tooltip accessKey="a" title={dictionary_reglas.CREAR_ACCION} arrow>
                  <IconButton
                    aria-label="add"
                    className={classes.buttonAdd}
                    onClick={() => events.toggleDialogoAcciones()}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid
              item
              container
              sm={10}
              justifyContent="space-between"
              alignItems="center"
              className={classes.gridSwitch}
            >
              <Grid item xs={5} sm={4} md={3}>
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      color="secondary"
                      id="activo"
                      checked={state.reglaReady.regla.activo}
                      onChange={events.onChangeChecked}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label={enumLabel.ACTIVAR_DESACTIVAR}
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={5} sm={4} md={3}>
                <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                  {dictionary_generic.MODIFICAR}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TeliaMostrarCondicion state={state} events={events} stateCondiciones={stateCondiciones} />
        <TeliaMostrarAccion state={state} events={events} />
        <DialogoCrearCondicion state={state} events={events} stateCondiciones={stateCondiciones} />
        <DialogoCrearAccion state={state} events={events} />
      </form>
    </div>
  );
}
