import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import useStyles from '../styles';
import { dictionary_dispositivos, enumLabel } from '../../../../resources/enums/plainText';
import { VolverAtras } from '../../../common/botonVolverAtras/VolverAtras';
import { DispositivoManager } from '../../../dispositivos/DispositivoManager';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { useDocumentManager } from './useDocumentManager';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { MapIcons } from '../../../common/gis/components/iconos/Iconos';
import { DocumentsContainer } from '../documentsContainer/DocumentsContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DateComponent, MultipleDateComponent } from '../../../common/dateComponent/DateComponent';

export function DocumentManager({ dispositivo }: { dispositivo: dispositivo }): JSX.Element {
  const classes = useStyles();
  const {
    state,
    setFechaExpiracion,
    setFechaInstalacion,
    setFechaMantenimiento,
    updateData,
    handleChangeTextField,
    capacidadCliente,
    capacidadUsada,
    capacidadUsadaPorcentaje,
    setDroppedFiles
  } = useDocumentManager(dispositivo.id as number);

  const {
    fechaExpiracionGarantia,
    fechaInstalacion,
    datosFabricante,
    servicioTecnico,
    instalador,
    cups
  } = state.datosAdicionales;
  const { fechasMantenimiento, documentos, loading, exceededCapacity } = state;

  return (
    <div className={classes.root}>
      <Box display="flex" marginBottom="30px" alignItems={'center'}>
        <VolverAtras backTo={<DispositivoManager key={enumComponentes.ADMINISTRACION} />} />
        <Typography variant="h6">{dictionary_dispositivos.GESTOR_DOCUMENTAL}</Typography>
      </Box>
      <Grid container spacing={2} height={'100%'}>
        <Grid item container xs={12} sm={12} md={6}>
          <form id="formDispositivo" autoComplete="off" onSubmit={updateData}>
            <Grid
              item
              container
              sm={12}
              justifyContent="flex-start"
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={12} sm={10} md={10}>
                <Typography variant="h6">Datos del dispositivo</Typography>
              </Grid>

              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={dictionary_dispositivos.IDENTIFICADOR}
                  id="nombre"
                  type="text"
                  value={dispositivo.nombre}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.TIPO_DISPOSITIVO}
                  type="text"
                  disabled
                  value={dispositivo.tipoDispositivo}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MapIcons icono={dispositivo.icono as string} />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.UBICACION_DEL_DISPOSITIVO}
                  id="direccion"
                  type="text"
                  value={dispositivo.direccion}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.NUM_SERIE_DISPOSITIVO}
                  id="nSerie"
                  type="text"
                  value={dispositivo.nSerie}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.DATOS_FABRICANTE}
                  id="datosFabricante"
                  type="text"
                  required
                  onChange={handleChangeTextField('datosFabricante')}
                  value={datosFabricante}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.SERVICIO_TECNICO}
                  id="servicioTecnico"
                  type="text"
                  required
                  onChange={handleChangeTextField('servicioTecnico')}
                  value={servicioTecnico}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <DateComponent
                  label={enumLabel.FECHA_DE_INSTALACION}
                  onChange={setFechaInstalacion}
                  value={fechaInstalacion as string}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.INSTALADOR}
                  id="instalador"
                  type="text"
                  required
                  onChange={handleChangeTextField('instalador')}
                  value={instalador}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={enumLabel.CUPS}
                  id="cups"
                  type="text"
                  onChange={handleChangeTextField('cups')}
                  value={cups}
                />
              </Grid>
              <Grid item xs={12} sm={10} md={5}>
                <DateComponent
                  label={enumLabel.FECHA_DE_EXPIRACION_GARANTIA}
                  onChange={setFechaExpiracion}
                  value={fechaExpiracionGarantia as string}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <MultipleDateComponent
                  onChange={setFechaMantenimiento}
                  values={fechasMantenimiento as Array<string>}
                  label={enumLabel.FECHAS_DE_MANTENIMIENTO_PREVENTIVO}
                />
              </Grid>

              <Grid item xs={5} sm={4} md={3} position={'relative'}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  Actualizar datos
                </Button>
                {loading && <CircularProgress size={24} className={classes.circularProgress} />}
              </Grid>
            </Grid>
          </form>

          <Grid item xs={12} sm={10}>
            <Tooltip
              title={`${capacidadCliente} mb de capacidad total, ${capacidadUsada} mb usados.`}
            >
              <Typography>
                {exceededCapacity
                  ? 'Capacidad excedida, libere capacidad o póngase en contacto con el administador de sistemas.'
                  : `Capacidad total de documentos : ${capacidadCliente} mb`}
              </Typography>
            </Tooltip>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={exceededCapacity ? 100 : capacidadUsadaPorcentaje}
                  color={'secondary'}
                />
              </Box>
              <Box sx={{ minWidth: 85 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${capacidadUsadaPorcentaje}% Usado`}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <DndProvider backend={HTML5Backend}>
          <DocumentsContainer
            idDispositivo={dispositivo.id as number}
            documents={documentos}
            setDroppedFiles={setDroppedFiles}
            idModulo={null}
            exceededCapacity={exceededCapacity}
          />
        </DndProvider>
      </Grid>
    </div>
  );
}
