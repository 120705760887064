import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '8px 8px'
  },
  section: {
    padding: '8px 16px',
    width: '100%'
  },
  etiquetaSection: {
    width: '9.5vw',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down(1115)]: {
      width: '30vw'
    },
    [theme.breakpoints.up(1115)]: {
      width: '9vw'
    }
  },
  buttonSection: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    width: '28px',
    height: '28px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main + '88'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main + 'dd'
    }
  },
  iconSection: {
    color: '#FFFFFF'
  },
  widthSection: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(1115)]: {
      width: '30vw'
    },
    [theme.breakpoints.up(1115)]: {
      width: '8vw'
    }
  },
  widthInputSection: {
    height: '35px',
    [theme.breakpoints.down(960)]: {
      width: '20vw'
    },
    [theme.breakpoints.up(960)]: {
      width: '6vw'
    }
  },
  botonesPtz: {
    width: '80px',
    textAlign: 'center'
  }
}));
export default useStyles;
