import * as React from 'react';
import {
  Tooltip,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import { dictionary_generic } from '../../../../../../../resources/enums/plainText';

import useStyles from '../../styles';
import { TarjetaInterface } from '../../../../resources/interfaces/interfaceTarjeta';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useGraficas } from '../../../../../../graficas/hooks/useGraficas/useGraficas';

import { codigoDispositivo } from '../../../../../../graficas/components/tarjetasGraficas/TarjetaManager';
import { Rango } from '../../../../../../graficas/resources/enums/enumRango';
import { Operation } from '../../../../../../graficas/resources/enums/enumOperation';
import { GraphTypes } from '../../../../../../graficas/resources/enums/enumGraphTypes';
import { dispositivo } from '../../../../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { LineColumnChart } from '../../../../../../graficasApexCharts/LineColumnChart';
import { BackdropTarjetasModulos } from '../../../../../../home/homeModulos/common/BackdropTarjetasModulos';
import { TabPanelProps } from './resources/interfaces/interfaceTabPanelProps';
import { TiposMedida } from '../../../../../../../resources/enums/enumTiposMedida';
import { TipoGrafica } from '../../../../../../graficas/resources/interfaces/interfaceCharts';

const initial_state_controlTabs = { value: 0, isLoading: true };

function TabPanel(props: TabPanelProps) {
  const { children, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function DialogGrafica({
  datosTarjeta,
  open,
  setOpen
}: {
  datosTarjeta: TarjetaInterface;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { obtenerDatosGrafica } = useGraficas();
  const [controlTabs, setControlTabs] = React.useState(initial_state_controlTabs);
  const graficasDisponibles = codigoDispositivo[datosTarjeta.codigoTipoDispositivo];
  const [grafica, setGrafica] = React.useState<any>(null);

  const handleClose = () => setOpen(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setControlTabs({ value: newValue, isLoading: true });
  };

  React.useEffect(() => {
    const generarGrafica = async () => {
      const tipoGrafica: TipoGrafica = graficasDisponibles[controlTabs.value];
      const datosGrafica = await obtenerDatosGrafica(
        tipoGrafica.consulta,
        Rango.TODAY,
        Operation.AVG,
        tipoGrafica.icon === TiposMedida.GENERADO
          ? GraphTypes.energia_solar
          : GraphTypes.comparativo,
        [datosTarjeta as dispositivo]
      );
      //Puede que el usuario cierre el diálogo antes de obtener los datos.
      if (open) {
        setGrafica(datosGrafica);
        setControlTabs({ ...controlTabs, isLoading: false });
      }
    };

    if (graficasDisponibles) generarGrafica();
  }, [open, controlTabs.value]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
      style={{ minHeight: '230px' }}
    >
      <DialogContent>
        {graficasDisponibles.length > 0 ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={controlTabs.value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {graficasDisponibles.map((item: any, index: number) => (
                  <Tab
                    label={item.titulo}
                    key={index}
                    {...a11yProps(index)}
                    disabled={controlTabs.isLoading}
                  />
                ))}
              </Tabs>
            </Box>
            <TabPanel value={controlTabs.value} index={0} style={{ position: 'relative' }}>
              {!controlTabs.isLoading && grafica !== null ? (
                grafica.max > 0 ? (
                  <LineColumnChart {...grafica} limitesGrafico={{ alto: '400px' }} />
                ) : (
                  dictionary_generic.SIN_DATOS_GRAFICA
                )
              ) : (
                <BackdropTarjetasModulos open={controlTabs.isLoading} marginTop={'30px'} />
              )}
            </TabPanel>
          </Box>
        ) : (
          dictionary_generic.SIN_GRAFICAS_DISPONIBLES
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{dictionary_generic.CERRAR}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function GraficasTarjetaGIS({
  datosTarjeta
}: {
  datosTarjeta: TarjetaInterface;
}): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpenCharts = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Tooltip title={'Ver gráfica'} className={classes.tooltip} placement="top" arrow>
        <IconButton
          color="secondary"
          aria-label="Ver gráfica"
          style={{ padding: '0 6px 0 6px' }}
          onClick={handleClickOpenCharts}
        >
          <BarChartIcon className={classes.botones_header} />
        </IconButton>
      </Tooltip>
      {open ? <DialogGrafica datosTarjeta={datosTarjeta} open={open} setOpen={setOpen} /> : <></>}
    </React.Fragment>
  );
}
