import {
  objectTiposDispositivo,
  tiposDispositivo
} from '../../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import {
  objectDispositivo,
  dispositivo
} from '../../../dispositivos/resources/interfaces/interfaceDispositivo';

import { operador, objectOperador } from './interfaceOperador';
import { TipoCondicionTemporal } from '../enums/enumTipoCondicionTemporal';
import { canal, objectCanal } from './interfaceCanal';
import { grupo, objectGrupo } from '../../../grupos/resources/interfaces/interfaceGrupo';

/**
 * @alias CondicionCompleta
 * @typedef CondicionCompleta
 * @description Interfaz del objeto "Condición Completa", que tiene los objetos instanciados de todos los atributos de una condición.
 * @property { tiposDispositivo } tipoDispositivoSeleccionado Objeto "tipoDispositivo" vinculado a la condición.
 * @property { dispositivo } dispositivoSeleccionado Objeto "dispositivo" vinculado a la condición.
 * @property { canal } canalSeleccionado Objeto "canal" vinculado a la condición.
 * @property { operador } operadorSeleccionado Objeto "operador" vinculado a la condición.
 * @property { string } valorSeleccionado String que tiene el valor de la condición.
 */
export interface condicionCompleta {
  tipoDispositivo: tiposDispositivo;
  dispositivo: dispositivo;
  canal: canal;
  operador: operador;
  valor: string;
  tipoTemporal?: TipoCondicionTemporal;
  grupo?: grupo;
  nombreCanal?: string;
}

export const objectCondicionCompleta = (): condicionCompleta => {
  return {
    tipoDispositivo: objectTiposDispositivo(),
    dispositivo: objectDispositivo(),
    canal: objectCanal(),
    operador: objectOperador(),
    valor: '',
    grupo: objectGrupo(),
    nombreCanal: ''
  };
};
