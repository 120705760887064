/**
 * Enumerado de los tipos de Medida
 */
export enum TiposMedida {
  SIN_ASIGNAR = 0,
  TEMPERATURA = 1,
  HUMEDAD = 2,
  CONSUMO = 3, // CONSUMO ELECTRICO Y CONSUMO CAUDAL AGUA
  CO_2 = 4,
  BATERIA = 5,
  DISTANCIA = 6,
  INCLINACION = 7,
  ESTADO_PARKING = 8,
  ON_OFF = 9, // ESTADO ENCENDIDO O APAGADO
  VELOCIDAD_VIENTO = 10,
  VIBRACION = 11,
  LLUVIA = 12,
  GENERADO = 13, // ELECTRICIDAD
  BRILLO = 14, // LUMINARIAS
  UBICACION = 15,
  POTENCIA = 16, // ELECTRICIDAD
  PERFIL = 17, // LUMINARIAS SOLAR POWER
  IDENTIFICACION = 18,
  CORRIENTE = 19, // ELECTRICIDAD
  PH = 20, // DEL AGUA
  CONDUCTIVIDAD_ELECTRICA = 21, // DEL AGUA
  ORP = 22, // POTENCIAL DE OXIDACIÓN Y REDUCCIÓN DEL AGUA
  PRESION = 23,
  RADIACION_SOLAR = 24,
  ABIERTO_CERRADO = 25, // VALVULA
  DECIBELIOS = 26,
  LUMENES = 27,
  SENAL_WIFI = 28,
  VOLTAJE = 29, // ELECTRICIDAD
  ALTITUD = 30,
  ESTADO_CIELO = 31,
  PROBABILIDAD_LLUVIA = 32,
  REGION = 33, // LUMINARIAS PHILIPS
  CALLE = 34, // LUMINARIAS PHILIPS
  GRUPO = 35, // LUMINARIAS PHILIPS
  VARIACION_CONSUMO = 36, // ELECTRICIDAD
  SAC_ABSORCION_ESPECTRAL_UV = 37, // CALIDAD DEL AGUA
  COD_QUIMICA_OXIGENO = 38, // CALIDAD DEL AGUA
  BOD_BIOQUIMICA_OXIGENO = 39, // CALIDAD DEL AGUA
  CONTAMINACION_ENTRADA = 40, // CALIDAD DEL AGUA, ANTIGUO TOC_CARBONO_ORGANICO
  TE_TURBIDEZ = 41, // AGUA
  UV_ABSORBANCIA = 42, // AGUA
  GR_ABSORBANCIA = 43, // AGUA
  UV_TRANSMITANCIA = 44, // AG // AGUAUA
  GR_TRANSMITANCIA = 45, // AGUA
  NUM_PERSONAS = 46, // CCTV DAHUA
  NUM_VEHICULOS = 47, // CCTV DAHUA
  NUM_VEHICULOS_PEQ = 48, // CCTV DAHUA
  NUM_ROSTROS = 49, // CCTV DAHUA
  NUM_INTRUSIONES = 50, // CCTV DAHUA
  ROSTRO_DETECTADO = 51, // CCTV DAHUA
  MATRICULA_DETECTADA = 52, // CCTV DAHUA
  EVENTO_DETECTADO = 53, // CCTV DAHUA
  TEMPERATURA_MIN = 54,
  TEMPERATURA_MAX = 55,
  DIRECCION_VIENTO = 56,
  DIRECCION_RAFAGA = 57,
  VELOCIDAD_RAFAGA = 58,
  TEMPERATURA_SUELO = 59,
  NIVEL_LLENADO_AGUA = 60, // DEPOSITO AGUA
  TEMPERATURA_AGUA_ABASTECIMIENTO = 61, // CALIDAD DEL AGUA DE ABASTECIMIENTO
  CONTAMINACION_SALIDA = 62, // CALIDAD DEL AGUA, ANTIGUO TOC_CARBONO_ORGANICO
  CONSUMO_CAUDAL_ENTRADA = 63, // AGUA
  CONSUMO_CAUDAL = 64, // AGUA
  PH_AGUA_ABASTECIMIENTO = 65, // CALIDAD DEL AGUA DE ABASTECIMIENTO
  ORP_AGUA_ABASTECIMIENTO = 66, // CALIDAD DEL AGUA DE ABASTECIMIENTO
  TEMP_MIN = 69,
  TEMP_MAX = 70,
  TEMP_AVG = 71,
  HUMEDAD_MIN = 72,
  HUMEDAD_MAX = 73,
  HUMEDAD_AVG = 74,
  VIENTO_MIN = 75,
  VIENTO_MAX = 76,
  VIENTO_AVG = 77,
  LLUVIA_MIN = 78,
  LLUVIA_MAX = 79,
  LLUVIA_AVG = 80,
  MONOXIDO_CARBONO = 81,
  DIOXIDO_NITROGENO = 82,
  DIOXIDO_AZUFRE = 83,
  PARTICULAS_PM1 = 84,
  PARTICULAS_PM25 = 85,
  PARTICULAS_PM10 = 86,
  OZONO = 87,
  INDICE_CALIDAD_AIRE = 88,
  INTENSIDAD_SONIDO = 91,
  PESO = 92
}
