import * as React from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { desencriptarRespuesta } from '../functions/encryption';
import { serviceRoutes } from '../resources/enums/enumRutasServicios';
import UserContext from '../context/UserContext';
import { dictionary_entornos } from 'resources/enums/plainText';
import { getHeaders } from 'functions/functions';

export function useUploadFileGoogDrive() {
  const {
    datosUsuarioContext: { cliente }
  } = React.useContext(UserContext);

  async function uploadDocument(data: File, dataForBD: any) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('file', data);

      formData.append(
        'folderId',
        CryptoJS.AES.encrypt(
          JSON.stringify(cliente.folderId),
          localStorage.getItem('secret_crypto') as string
        ).toString()
      );
      formData.append(
        'datosFichero',
        CryptoJS.AES.encrypt(
          JSON.stringify(dataForBD),
          localStorage.getItem('secret_crypto') as string
        ).toString()
      );

      axios
        .post(process.env.REACT_APP_URL_LOCAL + serviceRoutes.UPLOAD_DOCUMENTS, formData, {
          headers: getHeaders(dictionary_entornos.BACK)
        })
        .then((response) => {
          const res = desencriptarRespuesta(response);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return { uploadDocument };
}
