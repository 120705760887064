/* eslint-disable react/display-name */
import React from 'react';
import { CodigoDispositivo } from '../../../../../../../resources/enums/enumCodigoDispositivo';
import { TarjetaInterface } from '../../../../resources/interfaces/interfaceTarjeta';
import { InteraccionBasica } from './InteraccionBasica';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tipo: any = (datosTarjeta: TarjetaInterface) => ({
  [CodigoDispositivo.LUMINARIA]: (
    <InteraccionBasica key={CodigoDispositivo.LUMINARIA} isLight={true} />
  ),
  [CodigoDispositivo.CAUDALIMETRO]: <></>,
  [CodigoDispositivo.SENSOR]: <></>,
  [CodigoDispositivo.CUADRO_ELECTRICO]: (
    <InteraccionBasica key={CodigoDispositivo.CUADRO_ELECTRICO} />
  ),
  [CodigoDispositivo.ESTACION_METEOROLOGICA]: <></>,
  [CodigoDispositivo.SENSOR_HUMEDAD]: <></>,
  [CodigoDispositivo.ELECTROVALVULA]: (
    <InteraccionBasica key={CodigoDispositivo.ELECTROVALVULA} isValve={true} />
  ),
  [CodigoDispositivo.SENSOR_RESIDUOS]: <></>,
  [CodigoDispositivo.METERING]: <></>,
  [CodigoDispositivo.CAMARA]: <></>,
  [CodigoDispositivo.WIFI]: <></>,
  [CodigoDispositivo.PARKING]: <></>,
  [CodigoDispositivo.ANEMOMETRO]: <></>,
  [CodigoDispositivo.PLUVIOMETRO]: <></>,
  [CodigoDispositivo.AEMET]: <></>
});

/**
 * Función que recibe datosTarjeta, y según sea el tipoDispositivo creará el componente interacción correspondiente.
 * @param props
 */
export function InteraccionTipoDispositivo(props: { datosTarjeta: TarjetaInterface }): JSX.Element {
  const controladorInteraccion = tipo(props.datosTarjeta)[props.datosTarjeta.codigoTipoDispositivo];
  return controladorInteraccion ? controladorInteraccion : <></>;
}
