import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { useReducerAcciones } from '../hooks/useReducerAcciones';
import { tiposDispositivo } from '../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { tipoActuacion } from '../../tiposActuaciones/resources/interfaces/interfaceTipoActuacion';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { enumLabel } from '../../../resources/enums/plainText';

export function FormularioActuacionDispositivo(props: { state?: any; events?: any }): JSX.Element {
  const { stateAcciones, events } = useReducerAcciones(
    props.events,
    enumTiposAcciones.actuacion_dispositivo
  );
  const { setActuacionCompleta, handleCreateAccion } = events;

  const { dialogoAcciones, actuacionCompleta } = stateAcciones;

  const { handleAutocompleteObjetoChange, handleInputChange } = useEventChange(
    actuacionCompleta,
    setActuacionCompleta
  );

  return (
    <form id="panelActuacion">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Box p={1} flexGrow={1}>
            <Autocomplete
              value={actuacionCompleta.tipoDispositivo}
              disableClearable
              id={'tipoDispositivo'}
              options={dialogoAcciones.tiposDispositivo as tiposDispositivo[]}
              size={'small'}
              getOptionLabel={(option) => option.nombre}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.TIPO_DISPOSITIVO} variant="outlined" />
              )}
            />
          </Box>

          <Box p={1} flexGrow={1}>
            <Autocomplete
              value={actuacionCompleta.dispositivo}
              disableClearable
              id={'dispositivo'}
              options={dialogoAcciones.dispositivos as dispositivo[]}
              size={'small'}
              getOptionLabel={(option) => option.nombre}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.DISPOSITIVO} variant="outlined" />
              )}
            />
          </Box>

          <Box p={1} flexGrow={1}>
            <Autocomplete
              value={actuacionCompleta.tipoActuacion}
              disableClearable
              id={'tipoActuacion'}
              options={dialogoAcciones.tiposActuaciones as tipoActuacion[]}
              size={'small'}
              getOptionLabel={(option) => option.nombre}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.ACCION} variant="outlined" />
              )}
            />
          </Box>
        </Box>
        {parseInt(actuacionCompleta.tipoActuacion.valor) < 2 && (
          <Box p={1} flexGrow={1}>
            <TextField
              id={'duration'}
              type={'number'}
              label={`${enumLabel.DURATION} (Min)`}
              size={'small'}
              onChange={handleInputChange}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="row-reverse">
          <Box p={1}>
            <Button variant="contained" color="secondary" onClick={handleCreateAccion}>
              Crear
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
