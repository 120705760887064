/**
 * Enumerado con los distintos colores de la aplicación
 */
export enum Color {
  PRIMARIO_POR_DEFECTO = '#646868', // gris oscuro
  SECUNDARIO_POR_DEFECTO = '#B4B4B4', // gris claro
  // Contenedor Elementos
  ACTIVADO_ELEMENTO = '#008200',
  ACTIVADO_FONDO = '#E5F9ED',
  DESACTIVADO_ELEMENTO = '#C2271D',
  DESACTIVADO_FONDO = '#FADBDE',
  // Contenedor Fila Plugin
  GRIS = '#484848',
  ROJO = '#B7271D',
  ROSA = '#D10083',
  VERDE = '#007900',
  AZUL = '#0056FF',
  // GRAFICAS
  GRIS_GRAFICAS = '#c6c6c6',
  //TIPO_ALERTAS y COLOR SEMÁFORO
  WARNING = '#ffb100',
  ERROR = '#d32f2f',
  OK = '#008000'
}

export enum WeatherColors {
  GOOD = '#79bc6a',
  FAIR = '#bbcf4c',
  MODERATE = '#eec20b',
  POOR = '#f29305',
  VERY_POOR = '#e8416f'
}
