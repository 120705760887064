import React from 'react';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@mui/material';
import useStylesTarjetas from '../reglas/stylesTarjetas';
import { TextBoxTelia } from '../common/textboxTelia/TextBoxTelia';

export function TeliaMostrarAccion(props: { state: any; events: any }): JSX.Element {
  const classes = useStylesTarjetas();
  const { state, events } = props;

  return (
    <Dialog open={state.isInfoAccionOpen} onClose={events.cerrarInfoAccion}>
      <DialogTitle>
        Información de acción
        <IconButton
          aria-label="close"
          onClick={events.cerrarInfoAccion}
          className={classes.botonCerrarDialogo}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextBoxTelia texto={state.textoMostrarAccion} />
      </DialogContent>
    </Dialog>
  );
}
