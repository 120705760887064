import React, { useContext } from 'react';
import { regla } from './resources/interfaces/interfaceRegla';
import { ReglasModificar } from './ReglasModificar';
import { dictionary_reglas } from '../../resources/enums/plainText';
import { useReducerReglas } from './hooks/useReducerReglas';
import { Transitions } from '../../resources/enums/enumTransitions';
import ControlContext from '../../context/control/ControlContext';

import { TablaReglas } from './TablaReglas';
import { enumComponentes } from '../../resources/enums/enumComponente';

export function ReglasManager(props: { reglaSeleccionada?: regla }): JSX.Element {
  const { state, stateCondiciones, events } = useReducerReglas(props.reglaSeleccionada);
  const { control } = useContext(ControlContext);

  /**
   * Función que controla que componente de REGLAS debe mostrarse según la key
   * @returns {JSX.Element} devuelve el componente seleccionado
   */
  const managerReglas = (): JSX.Element => {
    switch (control.key) {
      case enumComponentes.REGLAS:
        return <TablaReglas state={state} events={events} stateCondiciones={stateCondiciones} />;

      case enumComponentes.MODIFICAR_REGLA:
        if (state.reglaSeleccionada !== undefined) {
          return (
            <ReglasModificar
              stateCondiciones={stateCondiciones}
              state={state}
              events={events}
              key={dictionary_reglas.MODIFICAR_REGLA}
              data-Transition={Transitions.Zoom}
            />
          );
        } else {
          return <></>;
        }

      default:
        return <></>;
    }
  };

  return managerReglas();
}
