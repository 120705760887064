import * as React from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Slide
} from '@mui/material';
import { AreaChart } from 'components/graficasApexCharts/AreaChart';
import { dictionary_generic, enumLabel } from 'resources/enums/plainText';
import { TransitionProps } from '@mui/material/transitions';
import { useWaterQualityDetail } from './index.hook';
import DownloadIcon from '@mui/icons-material/FileDownload';
import { DataTableReport } from 'components/Reports/shared/DataTableReport/DataTableReport';
import { BackdropTarjetasModulos } from 'components/home/homeModulos/common/BackdropTarjetasModulos';

const components = {
  icons: { DownloadIcon }
};

const columnsOptions = {
  options: {
    setCellProps: () => {
      return {
        style: { fontSize: '0.365rem', padding: '12px' }
      };
    },
    setCellHeaderProps: () => {
      return {
        className: 'cellHeaderCustomTable'
      };
    }
  }
};

const columns = [
  {
    name: 'valorMostrar',
    label: 'ÚLT. VALOR DEL PARÁMETRO',
    ...columnsOptions
  },
  {
    name: 'fechaMedida',
    label: 'ÚLTIMA MEDICIÓN',
    ...columnsOptions
  },
  {
    name: 'valoracion',
    label: 'VALORACIÓN',
    ...columnsOptions
  },
  { name: 'color', options: { display: 'excluded' } }
];

const options = {
  rowsPerPageOptions: [],
  download: false,
  filter: false,
  viewColumns: false,
  print: true,
  search: false,
  rowsPerPage: 7,
  selectableRows: 'none',
  setRowProps: (row: Array<any>) => {
    return {
      style: { backgroundColor: row[3].length ? row[3] + '5c' : 'white', padding: '10px' }
    };
  }
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function WaterQualityDetail({
  openDetail,
  setOpenDetail
}: {
  openDetail: { open: boolean; row: any };
  setOpenDetail: React.Dispatch<React.SetStateAction<{ open: boolean; row: any }>>;
}) {
  const {
    frecuencia,
    handleAutoCompleteFrecuencia,
    optionsAutoCompleteFrecuencia,
    objetoPopUp,
    isMutating,
    handleClose
  } = useWaterQualityDetail({ openDetail, setOpenDetail });

  return (
    <>
      <Dialog
        open={openDetail.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle>
          <IconButton color="secondary" onClick={handleClose}>
            <ArrowBack /> {'  Volver atrás'}
          </IconButton>
        </DialogTitle>
        <BackdropTarjetasModulos open={!objetoPopUp.ready || isMutating} />
        {objetoPopUp.ready ? (
          <DialogContent>
            <DialogContentText id="vp-parameters">
              <Grid container>
                <Grid
                  item
                  xs={6}
                  display={'flex'}
                  flexDirection={'column'}
                  textAlign={'center'}
                  justifyContent={'space-evenly'}
                >
                  <Typography gutterBottom variant="h4" paragraph>
                    Valor Mínimo {objetoPopUp.valorMinimo}
                  </Typography>
                  <Typography gutterBottom variant="h4" paragraph>
                    Valor Máximo {objetoPopUp.valorMaximo}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DialogContentText id="chart-content">
                    {objetoPopUp.datosGrafico.noValues ? (
                      <Typography gutterBottom variant="h4" paragraph textAlign={'center'}>
                        {dictionary_generic.NO_DATA}
                      </Typography>
                    ) : (
                      <AreaChart {...objetoPopUp.datosGrafico} limitesGrafico={{ alto: 250 }} />
                    )}
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                  <DialogContentText id="frequency">
                    <Autocomplete
                      id="combo-box-frecuencia"
                      options={optionsAutoCompleteFrecuencia}
                      getOptionLabel={(option) => option.frecuencia}
                      style={{ width: 200 }}
                      isOptionEqualToValue={(option, value) =>
                        option.frecuencia === value.frecuencia
                      }
                      defaultValue={{ ...frecuencia }}
                      value={{ ...frecuencia }}
                      onChange={handleAutoCompleteFrecuencia}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField {...params} label={enumLabel.FRECUENCIA} variant="outlined" />
                      )}
                    />
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <DialogContentText id="detail">
                    <DataTableReport
                      body={objetoPopUp.detalles.body}
                      columns={columns}
                      variableOptions={options}
                      components={components}
                    />
                  </DialogContentText>
                </Grid>
                s
              </Grid>
            </DialogContentText>
          </DialogContent>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}
