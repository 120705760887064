import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ElementosFiltro } from './ElementosFiltro';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  accordion: {
    justifyContent: 'center',
    padding: '5px'
  }
}));

export function Filter(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AccordionDetails className={classes.accordion}>
        <ElementosFiltro />
      </AccordionDetails>
    </div>
  );
}
