import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide
} from '@mui/material';
import { dictionary_generic } from 'resources/enums/plainText';
import { DataTableReport } from 'components/Reports/shared/DataTableReport/DataTableReport';
import { useConsumptionReportDetails } from './index.hook';
import { ArrowBack } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { AreaChart } from 'components/graficasApexCharts/AreaChart';
import { BackdropTarjetasModulos } from 'components/home/homeModulos/common/BackdropTarjetasModulos';
import { PropsReportsDetails } from 'components/Reports/shared/types';

const detailColumns = [
  {
    name: 'dispositivo',
    label: 'Dispositivo'
  },
  {
    name: 'fechaUltimaLectura',
    label: 'Fecha última lectura'
  },
  {
    name: 'valor',
    label: 'Consumo'
  }
];

const detailOptions = {
  rowsPerPageOptions: [],
  download: false,
  filter: false,
  viewColumns: false,
  print: true,
  search: false,
  rowsPerPage: 7,
  selectableRows: 'none'
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {{open:Boolean,row:Record<string,unknown>}} openDetail Object with the selected row and open value to control the dialog.
 * @param {function()} handleCloseDetails Function to close the dialog and clear the selected row.
 * @returns {JSX.Element}
 */

export function ConsumptionReportDetails({ openDetail, handleClose }: PropsReportsDetails) {
  const { details } = useConsumptionReportDetails(openDetail);

  return (
    <>
      <Dialog
        open={openDetail.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle>
          <IconButton color="secondary" onClick={handleClose}>
            <ArrowBack /> {'  Volver atrás'}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {details.details ? (
            <DialogContentText id="vp-parameters">
              <Grid container>
                <Grid
                  item
                  xs={6}
                  display={'flex'}
                  flexDirection={'column'}
                  textAlign={'center'}
                  justifyContent={'space-evenly'}
                >
                  <Typography gutterBottom variant="h4" paragraph>
                    Mínimo {details.min}
                  </Typography>
                  <Typography gutterBottom variant="h4" paragraph>
                    Máximo {details.max}
                  </Typography>
                  <Typography gutterBottom variant="h4" paragraph>
                    Media {details.average}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DialogContentText id="chart-content">
                    {details.graphData.noValues ? (
                      <Typography gutterBottom variant="h4" paragraph textAlign={'center'}>
                        {dictionary_generic.NO_DATA}
                      </Typography>
                    ) : (
                      <AreaChart {...details.graphData} limitesGrafico={{ alto: 250 }} />
                    )}
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <DialogContentText id="detail">
                    <DataTableReport
                      columns={detailColumns}
                      variableOptions={detailOptions}
                      body={details.details}
                    />
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContentText>
          ) : (
            <BackdropTarjetasModulos open={true} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
