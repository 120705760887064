import { actionsCondiciones } from '../../../reglas/resources/enums/enumActionsCondiciones';
import { objectCondicionCompleta } from '../../../reglas/resources/interfaces/interfaceCondicionCompleta';

export const reducerCondiciones = (state: any, action: any) => {
  switch (action.type) {
    case actionsCondiciones.SET_DIALOGO_CONDICIONES:
      return { ...state, dialogoCondiciones: action.payload };
    case actionsCondiciones.SET_CONDICION_COMPLETA:
      return { ...state, condicionCompleta: action.payload };
    case actionsCondiciones.SET_LISTA_CONDICIONES:
      return { ...state, listaCondiciones: action.payload };
    case actionsCondiciones.ADD_CONDICION:
      return { ...state, listaCondiciones: [...state.listaCondiciones, action.payload] };
    case actionsCondiciones.MOSTRAR_INFO_CONDICION:
      return { ...state, condicionActiva: action.payload, isInfoCondicionOpen: true };
    case actionsCondiciones.CERRAR_MOSTRAR_INFO_CONDICION:
      return { ...state, condicionActiva: objectCondicionCompleta(), isInfoCondicionOpen: false };
    case actionsCondiciones.SET_TIPO_CONDICION:
      return {
        ...state,
        tipoCondicion: action.payload,
        condicionCompleta: objectCondicionCompleta(),
        opcionTemporalEscogida: { label: '', value: '' }
      };
    case actionsCondiciones.SET_OPCIONES_TIPOS_MEDIDA:
      return { ...state, opcionesTiposMedida: action.payload };
    case actionsCondiciones.SET_HORA:
      return {
        ...state,
        hora: action.payload,
        condicionCompleta: {
          ...state.condicionCompleta,
          valor: action.payload.getTime().toString()
        }
      };
    case actionsCondiciones.SET_OPCION_TEMPORAL_ESCOGIDA:
      return {
        ...state,
        opcionTemporalEscogida: action.payload,
        hora: new Date(),
        diaSemana: { label: '', value: '' },
        condicionCompleta: { ...state.condicionCompleta, tipoTemporal: action.payload.value, valor: new Date().getTime().toString() }
      };
    case actionsCondiciones.SET_DIA_SEMANA:
      return {
        ...state,
        diaSemana: action.payload,
        condicionCompleta: { ...state.condicionCompleta, valor: action.payload.value }
      };
    case actionsCondiciones.SET_STATE:
      return state;
    case actionsCondiciones.LIMPIAR_CONDICION:
      return {
        ...state,
        dialogoCondiciones: {
          ...state.dialogoCondiciones,
          canales: [],
          dispositivos: [],
          canalesGrupo: []
        },
        tipoCondicion: action.payload,
        condicionCompleta: objectCondicionCompleta(),
        opcionTemporalEscogida: { label: '', value: '' },
        diaSemana: { label: '', value: '' },
        momentoDia: { label: '', value: '' }
      };
    case actionsCondiciones.SET_MOMENTO_DIA:
      return {
        ...state,
        momentoDia: action.payload,
        condicionCompleta: { ...state.condicionCompleta, valor: action.payload.value }
      };
  }
};
