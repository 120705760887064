import React from 'react';
import { Button, Grid, TextField, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import useStyles from './styles';
import { InputAdornmentEmail, InputAdornmentLock, InputAdornmentPerson } from './InputsAdornment';
import useReducerLogin from './hooks/useReducerLogin/useReducerLogin';
import { formulario } from './hooks/useReducerLogin/resources/enums/enumFormulario';
import { dictionary_login, dictionary_password, enumLabel } from '../../resources/enums/plainText';

export function RestartPassword(): JSX.Element {
  const classes = useStyles();
  const { state, events } = useReducerLogin();

  const { email, idUsuario, isCodeCorrect } = state;
  const {
    handleInputNombreUsuarioChange,
    handleInputEmailChange,
    handleInputCodeSecretChange,
    handleInputNuevaPasswordChange,
    handleInputRepetirPasswordChange,
    isErrorPassword,
    helperText,
    isDisabledButton,
    volverToLogin,
    handleSubmit
  } = events;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
        className={classes.gridMain}
      >
        <form
          id={formulario.RESTART_PASS}
          onSubmit={handleSubmit}
          className={classes.formLogin}
          autoComplete="off"
        >
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={1}>
                <Tooltip title={dictionary_login.TEXTO_VOLVER} arrow placement="bottom">
                  <IconButton color="secondary" onClick={volverToLogin}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6" className={classes.textCenterBlack}>
                  {dictionary_login.RESTABLECER_PASS.toLocaleUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!isCodeCorrect ? (
            idUsuario === undefined ? (
              <>
                <Grid item xs={12} className={classes.gridLogin}>
                  <Typography variant="body2" className={classes.textCenterBlack}>
                    {dictionary_login.TEXTO_EMAIL}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridLogin}>
                  <TextField
                    fullWidth
                    placeholder={enumLabel.USUARIO}
                    type="name"
                    id="name"
                    onChange={handleInputNombreUsuarioChange}
                    autoFocus
                    required
                    InputProps={{
                      className: classes.inputLogin,
                      startAdornment: <InputAdornmentPerson />
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridLogin}>
                  <TextField
                    fullWidth
                    placeholder={enumLabel.EMAIL}
                    type="email"
                    id="email"
                    color="primary"
                    required
                    onChange={handleInputEmailChange}
                    InputProps={{
                      className: classes.inputLogin,
                      startAdornment: <InputAdornmentEmail />
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} className={classes.gridLogin}>
                  <Typography variant="body2" className={classes.textCenterBlack}>
                    {dictionary_login.TEXTO_CODIGO_VERIFICACION} {email}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridLogin}>
                  <TextField
                    fullWidth
                    placeholder={enumLabel.CODIGO_VERIFICACION}
                    type="password"
                    autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
                    id="codeSecret"
                    color="primary"
                    required
                    autoFocus
                    onChange={handleInputCodeSecretChange}
                    InputProps={{
                      className: classes.inputLogin,
                      startAdornment: <InputAdornmentLock />
                    }}
                  />
                </Grid>
              </>
            )
          ) : (
            <>
              <Grid item xs={12} className={classes.gridLogin}>
                <Typography variant="body2" className={classes.textCenterBlack}>
                  {dictionary_login.TEXTO_NUEVA_CONTRASENA}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridLogin}>
                <TextField
                  fullWidth
                  placeholder={enumLabel.CONTRASENA_NUEVA}
                  type="password"
                  id="newPassword"
                  autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
                  color="primary"
                  required
                  autoFocus
                  onChange={handleInputNuevaPasswordChange}
                  InputProps={{
                    className: classes.inputLogin,
                    startAdornment: <InputAdornmentLock />,
                    inputProps: {
                      pattern: dictionary_password.PATRON_CONTRASENA,
                      title: dictionary_password.TEXTO_PATRON_CONTRASENA
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridLogin}>
                <TextField
                  fullWidth
                  placeholder={enumLabel.CONTRASENA_REPETIR}
                  type="password"
                  id="repeatPassword"
                  autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
                  color="primary"
                  required={true}
                  onChange={handleInputRepetirPasswordChange}
                  InputProps={{
                    className: classes.inputLogin,
                    startAdornment: <InputAdornmentLock />
                  }}
                  error={isErrorPassword()}
                  helperText={helperText()}
                />
              </Grid>
            </>
          )}
          <Grid item sm={12} className={classes.gridLogin}>
            <Tooltip title={dictionary_login.TEXTO_ENVIAR} arrow placement="bottom">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                aria-label="enviar"
                className={classes.buttonLogin}
                disabled={isDisabledButton()}
              >
                {dictionary_login.ENVIAR}
              </Button>
            </Tooltip>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
