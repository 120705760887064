import React, { useContext } from 'react';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import MenuPrincipal from '../../components/navegacion/menuPrincipal/MenuPrincipal';
import { NavPrincipal } from '../../components/navegacion/navPrincipal/NavPrincipal';
import { useStyles } from './styles';
import ControlContext from '../../context/control/ControlContext';
import { SelectComponente } from '../../components/main/SelectComponente';
import { Home } from '../../components/home/principal/Home';
import { UsuariosManager } from '../../components/usuarios/UsuariosManager';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { ConfiguracionGeneral } from '../../components/home/homeModulos/configuracionGeneral/ConfiguracionGeneral';
import { Modulos } from '../../resources/enums/enumModulo';
import { HomeComun } from '../../components/home/homeModulos/HomeComun';
import { ReglasManager } from '../../components/reglas/ReglasManager';
import UserContext from '../../context/UserContext';
import { useLoginUsuario } from '../../hooks/useLogin/useLoginUsuario';
import { getColorCliente } from '../../functions/functions';
import { keyHome } from '../../components/home/homeModulos/resources/enums/enumHome';

interface PropsPageMain {
  setColorCliente: React.Dispatch<React.SetStateAction<string>>;
}

export default function PageMain(props: PropsPageMain): JSX.Element {
  const { setColorCliente } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const {
    setModuloSeleccionado,
    moduloSeleccionado,
    setControl,
    setPage,
    handleClickOpenConfiguratorAlert
  } = useContext(ControlContext);

  const { datosUsuarioContext } = useContext(UserContext);
  const { logout } = useLoginUsuario();

  // Si los datos de usuario está vacío, volvemos a la pag de login
  if (!Object.keys(datosUsuarioContext).length) logout();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleClickModulo(idModulo: number) {
    const cases = {
      [Modulos['GESTIÓN DE USUARIOS']]: <UsuariosManager key={enumComponentes.USUARIOS} />,
      [Modulos['CONFIGURACIÓN GENERAL']]: (
        <ConfiguracionGeneral setControl={setControl} key={Modulos['CONFIGURACIÓN GENERAL']} />
      ),
      [Modulos['IA Y BIG DATA']]: <></>,
      [Modulos['REGLAS']]: <ReglasManager key={enumComponentes.REGLAS} />
    };

    const selectedCase = cases[idModulo] ?? <HomeComun key={keyHome.COMUN} />;

    handleClickOpenConfiguratorAlert(() => {
      setPage(1);
      setModuloSeleccionado(idModulo);
      setControl(selectedCase);
    });
  }

  React.useEffect(() => {
    const colorPrimario = datosUsuarioContext?.cliente?.colorPrimario;
    const colorSecundario = datosUsuarioContext?.cliente?.colorSecundario;
    // Establecemos el color de la plataforma con el color del cliente comprobando que sea correcto y exista
    setColorCliente(getColorCliente(colorPrimario, colorSecundario));
  }, []);

  return (
    <>
      {Object.keys(datosUsuarioContext).length ? (
        <div className={classes.root}>
          <CssBaseline />
          <NavPrincipal
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleClickModulo={handleClickModulo}
            moduloSeleccionado={moduloSeleccionado}
          />
          <MenuPrincipal
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleClickModulo={handleClickModulo}
            moduloSeleccionado={moduloSeleccionado}
          />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open
            })}
          >
            {moduloSeleccionado !== 0 ? <SelectComponente /> : <Home />}
          </main>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
