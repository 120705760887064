import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { HandleError } from '../../../error/resources/types/typeHandleError';
import { getId, getLabelsRange } from '../../functions/funciones';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { Operation } from '../../resources/enums/enumOperation';
import { Rango } from '../../resources/enums/enumRango';
import { ServiciosGraficas } from '../../resources/enums/enumServiciosGraficas';
import { SolarEnergyChart, NoValues } from '../../resources/interfaces/interfaceCharts';
import { DatosGrafica } from '../../resources/interfaces/interfaceDatosGraficas';
import { servicioObtenerDatosGraficas } from '../../services/obtenerDatosGraficas';

const file = 'createAreaChart';

export const createSolarEnergyChartRange = async (
  moduloSeleccionado: number,
  medida: TiposMedida | TiposMedida[],
  rango: Rango,
  operation: Operation,
  tipoGrafica: GraphTypes,
  dispositivos: Array<dispositivo>,
  handleError: HandleError,
  inicio: Array<string>,
  fin: Array<string>,
  unidad?: dictionary_unidades
): Promise<SolarEnergyChart | NoValues> => {
  const getValor = (d: DatosGrafica) => d.valor;

  const objectLabel = getLabelsRange(inicio, fin);

  const consulta: any = {
    range: rango,
    startDate: inicio[0],
    endDate: inicio[1],
    dispositivos: getId(dispositivos),
    group: objectLabel.agrupacionValue,
    operation: operation,
    measure: medida,
    servicio: ServiciosGraficas.VARIAS_MEDIDAS
  };

  //Obtención de los datos del rango actual.
  const respuestaPeriodoInicial: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  if (respuestaPeriodoInicial.isAxiosError) {
    handleError(`[${file}][${servicioObtenerDatosGraficas.name}]`, respuestaPeriodoInicial.message);
    return { noValues: true };
  }

  //Obtención de los datos del rango anterior para comparar.
  consulta.startDate = fin[0];
  consulta.endDate = fin[1];
  const respuestaValorComparativo: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  //Desestructuración de los datos actuales y comparativos.
  const { result } = respuestaPeriodoInicial.data;
  const { result: resultValorComparativo } = respuestaValorComparativo.data;

  //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
  //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
  // const { condition = 'fecha', formateoFecha, getLastValue } = getLabels(rango);

  let unid;

  const datos: Array<{ data: Array<any>; name: string; color?: string }> = [];

  const hideNames: Array<string> = [];

  function createGraphData(result: Array<any>, anterior = false) {
    //Nos obtenemos el último valor para tratar correctamente los valores a 0 o nulos.
    //const ultimoValor = getLastValue(new Date());

    dispositivos.forEach((dispositivo) => {
      const datosFiltradosPorDispositivo = result.filter((item) => item.id === dispositivo.id);

      datosFiltradosPorDispositivo.forEach(
        ({ nombre, datos: datosResultado }: { nombre: string; datos: Array<DatosGrafica> }) => {
          let data;

          //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
          //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
          if (datosResultado.length !== objectLabel.labels.length) {
            data = objectLabel['fechaINI'].map((element: any) => {
              const res = datosResultado.find((item: any) => {
                unid = item.unidad;

                return (
                  objectLabel.formatDate(new Date(item.fecha)) === objectLabel.formatDate(element)
                );
              });
              return res ? res.valor : null;
            });
          } else {
            data = datosResultado.map(getValor);
          }
          if (dispositivos.length === 0) {
            nombre = anterior ? 'Periodo Comparativo' : 'Periodo Inicial';
          } else {
            if (anterior) {
              nombre =
                nombre === 'undefined undefined'
                  ? (dispositivos.find((dispositivoExt) => dispositivoExt.id === dispositivo.id)
                      ?.nombre as string) + ` anterior`
                  : nombre + ` anterior`;
            } else {
              nombre =
                nombre === 'undefined undefined'
                  ? (dispositivos.find((dispositivoExt) => dispositivoExt.id === dispositivo.id)
                      ?.nombre as string)
                  : nombre;
            }
          }

          datos.push({ data, name: nombre });
        }
      );
      datos.push({
        data: datos[1].data.map((item, index) => {
          if (item === null && datos[0].data[index] === null) {
            return null;
          } else {
            const valor = parseInt((item - datos[0].data[index]).toFixed(2));

            return valor > 0 ? valor : 0;
          }
        }),

        name: anterior
          ? `Excedencia ${dispositivo.nombre} anterior`
          : `Excedencia ${dispositivo.nombre}`
      });
      hideNames.push(`Excedencia ${dispositivo.nombre}`);
    });
  }
  createGraphData(result);
  createGraphData(resultValorComparativo, true);

  //Propiedades genéricas del gráfico, como el título, título del eje Y y el valor max para que el gráfico tenga una altura correcta.

  const title = `Producción y consumo de energía`;
  const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;

  return {
    datos,
    labels: objectLabel.labels,
    title: title,
    titleYAxis: `Producción y consumo de energía ${unid ?? unidad}`,
    max,
    hideNames: hideNames,
    unid
  };
};
