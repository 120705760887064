import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';

const useStyles = makeStyles({
  descatalogado: {
    fontSize: '14px'
  },
  tituloEstado: {
    fontWeight: 500,
    fontSize: '0.6875rem'
  },
  botonInfo: {
    padding: 0,
    marginLeft: '1vw'
  },
  iconoInfo: {
    height: '20px',
    width: '20px',
    color: '#2196f3'
  },
  estado: {
    paddingRight: '2vw'
  },
  botonesTabla: {
    paddingRight: '8px'
  },
  tituloTabla: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});
export default useStyles;
