/**
 * Distintos estados que puede tener un plugin
 */
export enum statusPlugin {
  INSTALLED = 0,
  ERROR = 1,
  PAUSE = 2,
  RUNNING = 3,
  READY = 4
}
