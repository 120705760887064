import { useEventChange } from 'hooks/useEventChange/useEventChange';
import * as React from 'react';
import { rutasServicios } from 'resources/enums/enumRutasServicios';
import { dictionary_tiposUnidades, dictionary_generic } from 'resources/enums/plainText';
import { createRecord, editRecord, getAll } from 'shared/services/sharedAppServices';
import { useRefreshData, useTriggeredRequest } from 'shared/hooks/useRequest';
import { ArgsCreateRecord } from 'shared/services/types';
import { Unit } from 'shared/types';

export function useUnitTypeCRU({
  unitType: unitTypeSelected = undefined
}: {
  unitType: Unit | undefined;
}) {
  const { trigger: editUnitType } = useTriggeredRequest<Unit, Unit>(
    () => editRecord(rutasServicios.TIPOS_UNIDADES),
    true
  );

  const { trigger: createUnitType } = useTriggeredRequest<Unit, Unit & ArgsCreateRecord>(
    () => createRecord(rutasServicios.TIPOS_UNIDADES),
    true
  );

  const { refresh } = useRefreshData(() => getAll(rutasServicios.TIPOS_UNIDADES));

  const unit = React.useMemo(() => {
    return unitTypeSelected
      ? {
          ...unitTypeSelected,
          title: dictionary_tiposUnidades.MODIFICAR_TIPOS_UNIDADES,
          buttonTitle: dictionary_generic.MODIFICAR
        }
      : {
          codigo: undefined,
          nombre: undefined,
          soloLectura: 0,
          title: dictionary_tiposUnidades.CREAR_TIPOS_UNIDADES,
          buttonTitle: dictionary_generic.CREAR,
          id: undefined
        };
  }, []);

  const [unitType, setUnitType] = React.useState(unit);
  const { handleInputChange, handleInputInvalid } = useEventChange(unitType, setUnitType);
  const { title, buttonTitle, ...rest } = unitType;

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (unitTypeSelected) {
      editUnitType({
        ...(rest as Unit)
      }).then(() => refresh());
    } else {
      createUnitType({ ...(rest as Unit) }).then(() => refresh());
    }
  }

  return { unitType, handleInputChange, handleInputInvalid, handleSubmit };
}
