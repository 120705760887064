import React from 'react';
import BackDropContext from 'context/SmartTeliaBackdropContext';
import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from 'functions/encryption';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';

export function useObtenerDatosPost() {
  const { setOpenSmartTeliaBackdrop } = React.useContext(BackDropContext);
  /**
   * Función para realizar llamadas a servicios de tipo POST, **¡¡PENDIENTE DE REFACTORIZACIÓN, ÚSALO BAJO TU PROPIO RIESGO!!**
   * @param {string} service Ruta del servicio
   * @param {any} object Objeto (si fuera necesario) para la llamada al servicio.
   * @returns
   */
  function obtenerDatosPost(service: string, object: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_URL_LOCAL + service,
          Array.isArray(object)
            ? encriptarJSON(object)
            : encriptarJSON({
                ...object
              }),
          {
            headers: getHeaders(dictionary_entornos.BACK)
          }
        )
        .then((response) => {
          const res = desencriptarRespuesta(response);
          resolve(res.data.result);
        })
        .catch(function (error) {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            // que esta fuera del rango de 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            reject(error);
          } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log(error.request);
            reject('Error request');
          } else {
            // Algo paso al preparar la petición que lanzo un Error
            console.log('Error', error.message);
            reject('Error' + error.message);
          }
          console.log(error.config);
          reject(error.config);
        })
        .finally(() => setOpenSmartTeliaBackdrop(false));
    });
  }

  return { obtenerDatosPost };
}
