import React, { useEffect } from 'react';
import useStyles from '../../reglas/stylesTarjetas';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { enumLabel } from '../../../resources/enums/plainText';

export function DialogoDatosRegla(props: { state: any; events: any }): JSX.Element {
  const { state, events } = props;
  const classes = useStyles();
  const { handleInputChange, handleInputInvalid } = useEventChange(
    state.reglaReady.regla,
    events.setRegla
  );
  useEffect(() => {
    events.setMensajeTelia();
  }, []);
  return (
    <div className={classes.root}>
      <form id="formReglas" onSubmit={events.handleSubmitCrear} autoComplete="off">
        <Grid
          item
          container
          sm={12}
          justifyContent={'flex-start'}
          spacing={2}
          className={classes.cajas}
        >
          <Grid container spacing={2}>
            <Grid
              item
              container
              sm={12}
              justifyContent={'flex-start'}
              spacing={2}
              className={classes.cajas}
            >
              <Grid item xs={12} sm={10}>
                <Typography variant={'subtitle1'} color={'secondary'} className={classes.titulo}>
                  {enumLabel.NOMBRE_REGLA}
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder={enumLabel.NOMBRE_REGLA}
                  type="text"
                  id="nombre"
                  required
                  onChange={handleInputChange}
                  onKeyDown={handleInputInvalid}
                  onPaste={handleInputInvalid}
                  defaultValue={state.reglaReady?.regla?.nombre}
                />
              </Grid>

              <Grid item xs={12} sm={10} style={{ marginBottom: '10px' }}>
                <Typography variant={'subtitle1'} color={'secondary'} className={classes.titulo}>
                  {enumLabel.DESCRIPCION_REGLA}
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder={enumLabel.DESCRIPCION_REGLA}
                  type="text"
                  id="descripcion"
                  required
                  onChange={handleInputChange}
                  onKeyDown={handleInputInvalid}
                  onPaste={handleInputInvalid}
                  defaultValue={state.reglaReady?.regla?.descripcion}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
