import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

const optionsIdioma = {
  textLabels: {
    body: {
      noMatch: 'No se han encontrado registros existentes',
      toolTip: 'Ordenar',
      columnHeaderTooltip: (column: { label: any }) => `Ordenar por ${column.label}`
    },
    pagination: {
      next: 'Página Siguiente',
      previous: 'Página Anterior',
      rowsPerPage: 'Filas por Página:',
      displayRows: 'de'
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Descargar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Columnas',
      filterTable: 'Filtrar Tabla'
    },
    filter: {
      all: 'Todos',
      title: 'FILTROS',
      reset: 'REINICIAR'
    },
    viewColumns: {
      title: 'Mostrar Columnas',
      titleAria: 'Mostrar/Ocultar Columnas'
    },
    selectedRows: {
      text: 'fila(s) seleccionada',
      delete: 'Eliminar',
      deleteAria: 'Eliminar Filas Seleccionadas'
    }
  }
};

/**
 * Componente común de tipo tabla, necesita los siguientes parámetros:
 * @param columns --> columns = ["nombre1", "nombre2"...]
 * @param data --> data = [["nombre1","nombre2"],["nombre1","nombre2"]...]
 * @param title --> title = "Este es el título de la tabla"
 * @param options --> options = {opcion1:"opcion", opcion2:"opcion"...} para ver las opciones disponibles:https://github.com/gregnb/mui-datatables
 *
 */
export default function Table({
  columns,
  data,
  title,
  options,
  isHome,
  components
}: any): JSX.Element {
  const [numeroFilas, setNumeroFilas] = useState<number>(options.rowsPerPage);

  /*
   * Función para obtener el numero que habra de filas en la tabla según el tamaño de la ventana
   * 10 filas si la ventana es mayor de 840 px
   * 8 filas si la ventana es mayor de 730 px y menor o igual a 840 px
   * 6 filas si la ventana es menor o igual a 730 px
   *
   * Luego, si se trata de la tabla que aparece en Home sera de forma:
   * 5 filas si la ventana es mayor de 840 px
   * 3 filas si la ventana es mayor de 730 px y menor o igual a 840 px
   * 2 filas si la ventana es menor o igual a 730 px
   */
  function getNumeroFilas(): number {
    let filas: number;
    const altoVentana = window.innerHeight;
    if (isHome === true) {
      if (altoVentana > 840) {
        filas = 5;
      } else if (altoVentana > 730 && altoVentana <= 840) {
        filas = 3;
      } else {
        filas = 2;
      }
    } else {
      if (altoVentana > 840) {
        filas = 10;
      } else if (altoVentana > 730 && altoVentana <= 840) {
        filas = 8;
      } else {
        filas = 6;
      }
    }
    return filas;
  }

  const updateNumeroFilas = () => {
    setNumeroFilas(getNumeroFilas);
  };

  const optionsNumeroFilas = {
    rowsPerPage: numeroFilas === undefined ? getNumeroFilas() : numeroFilas
  };

  const optionsComun = Object.assign(optionsNumeroFilas, optionsIdioma);
  const optionsMain = Object.assign(options, optionsComun);

  useEffect(() => {
    if (options.rowsPerPage === undefined) {
      window.addEventListener('resize', updateNumeroFilas);
      return () => window.removeEventListener('resize', updateNumeroFilas);
    }
  });
  useEffect(() => {
    if (options.rowsPerPage === undefined) updateNumeroFilas();
  }, []);

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={Object.assign(optionsNumeroFilas, optionsMain)}
      components={components}
    />
  );
}
