import React, { useContext, useState } from 'react';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { dictionary_configurator } from '../../../../resources/enums/plainText';
import { serviceRoutes } from '../../../../resources/enums/enumRutasServicios';
import { availableConfigurableCards, specialCards } from '../../resources/commonResources';
import { AvailableCards } from '../../types/typesCardConfigurator';
import SnackBarContext from '../../../../context/SnackBarContext';

import { enumSeverity } from '../../../common/snackbar/resources/enums/enumSeverity';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';
import { MenuItem } from '@mui/material';
import { ContextMenuOptions } from '../../resources/configuratorEnums';

const excludedEditCards = [cardType.WeatherCard];

const dialogOptions = {
  [ContextMenuOptions.edit]: {
    dialogTitle: dictionary_configurator.EDIT_CARD_DIALOG_TITLE,
    dialogContent: dictionary_configurator.EDIT_CARD_DIALOG_CONTENT,
    option: ContextMenuOptions.edit
  },
  [ContextMenuOptions.delete]: {
    dialogTitle: dictionary_configurator.DELETE_CARD_DIALOG_TITLE,
    dialogContent: dictionary_configurator.DELETE_CARD_DIALOG_CONTENT,
    option: ContextMenuOptions.delete
  },
  [ContextMenuOptions.save]: {
    dialogTitle: dictionary_configurator.SAVE_CARD_DIALOG_TITLE,
    dialogContent: dictionary_configurator.SAVE_CARD_DIALOG_CONTENT,
    option: ContextMenuOptions.save
  },
  [ContextMenuOptions.delete_configuration]: {
    dialogTitle: dictionary_configurator.DELETE_CONFIGURATION_TITLE,
    dialogContent: dictionary_configurator.DELETE_CONFIGURATION_CONTENT,
    option: ContextMenuOptions.delete_configuration
  }
};

const initialDialogState = { openDialog: false, dialogTitle: '', dialogContent: '', option: '' };
const initialState = { name: '', id: 0, component: null };
const snackBarContent = {
  text: '',
  severity: enumSeverity.SUCCESS
};

export function useConfiguratorStructure() {
  const { setSnackBar } = useContext(SnackBarContext);
  const [tipoTarjeta, setTipoTarjeta] = useState<any>(initialState);
  const [dialogState, setDialogState] = useState(initialDialogState);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    cardPosition: number;
    setTransition: React.Dispatch<React.SetStateAction<boolean>>;
    items: Array<JSX.Element>;
  } | null>(null);

  const { controllerRequest } = useControlador();
  const {
    moduleStructureState,
    tabState: [tab],
    selectedStructure,
    selectedUserId,
    deleteCard,
    copySelectedStructure,
    deleteModuleWithChange,
    addModuleWithChange
  } = useConfiguratorContext();

  const [structureModule, setStructureModule] = moduleStructureState;

  function changeAvailableCard(card: any, props = {}) {
    setTipoTarjeta({ ...card, component: React.cloneElement(card.component, { ...props }) });
  }

  function editCard() {
    if (contextMenu) {
      if (dialogState.option === ContextMenuOptions.edit) {
        const { setTransition } = contextMenu;
        const { props } = selectedStructure[contextMenu.cardPosition];
        const allCards = [
          ...availableConfigurableCards,
          ...(specialCards[tab.selectedModule] ? specialCards[tab.selectedModule] : [])
        ];

        const cadFound = allCards.find(
          (card) => card.cardTypeId === props.tarjeta.idTipoTarjeta
        ) as AvailableCards;
        if (props.tarjeta.idTipoTarjeta === cardType.WeatherCard) {
          handleCloseContextMenu();
          handleCloseDialog();
          setTransition(false);
          return;
        }
        setTipoTarjeta({
          ...cadFound,
          component: React.cloneElement(cadFound.component, {
            ...props,
            key: `${tab.selectedModule} - ${contextMenu.cardPosition}`
          })
        });
        handleCloseContextMenu();
        handleCloseDialog();
        setTransition(false);
        setTimeout(() => {
          deleteCard(props.tarjeta.position as number);
        }, 500);
      } else {
        const { props } = selectedStructure[contextMenu.cardPosition];
        deleteCard(props.tarjeta.position as number);
        handleCloseContextMenu();
        handleCloseDialog();
      }

      addModuleWithChange();
    }
  }

  const cards = specialCards[tab.selectedModule]
    ? availableConfigurableCards.concat(specialCards[tab.selectedModule])
    : availableConfigurableCards;

  function saveConfiguration() {
    if (dialogState.option != null || dialogState.option === ContextMenuOptions.save) {
      const objects = structureModule[tab.value]
        .filter((item: any) => typeof item.type === 'function')
        .map((item: any) => {
          const { id, ...rest } = item.props.tarjeta;
          return {
            ...item.props,
            tarjeta: {
              ...rest,
              idUsuario: selectedUserId
            }
          };
        });
      handleCloseContextMenu();
      handleCloseDialog();
      controllerRequest(
        {
          type: action.OBTENER_DATOS_POST,
          payload: {
            service: serviceRoutes.SAVE_CARD_CONFIGURATION,
            object: { tarjetas: objects, idModulo: tab.selectedModule, idUsuario: selectedUserId }
          }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      )
        .then(() => {
          snackBarContent.text = dictionary_configurator.CORRECT_SNACKBAR_SAVED_TEXT;
          snackBarContent.severity = enumSeverity.SUCCESS;
          deleteModuleWithChange();
        })
        .catch(() => {
          snackBarContent.text = dictionary_configurator.INCORRECT_SNACKBAR_TEXT;
          snackBarContent.severity = enumSeverity.ERROR;
        })
        .finally(() => {
          setSnackBar({ open: true, ...snackBarContent });
          copySelectedStructure.current = [...structureModule[tab.value]];
        });
    }
  }

  function deleteConfiguration() {
    handleCloseContextMenu();
    handleCloseDialog();
    controllerRequest(
      {
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: serviceRoutes.SAVE_CARD_CONFIGURATION,
          object: { tarjetas: [], idModulo: tab.selectedModule, idUsuario: selectedUserId }
        }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    )
      .then(() => {
        snackBarContent.text = dictionary_configurator.CORRECT_SNACKBAR_DELETED_TEXT;
        snackBarContent.severity = enumSeverity.SUCCESS;
        structureModule[tab.value] = new Array(12).fill(<div data-visible={true} />);
        setStructureModule([...structureModule]);
        deleteModuleWithChange();
      })
      .catch(() => {
        snackBarContent.text = dictionary_configurator.INCORRECT_SNACKBAR_TEXT;
        snackBarContent.severity = enumSeverity.ERROR;
      })
      .finally(() => {
        setSnackBar({ open: true, ...snackBarContent });
        copySelectedStructure.current = [...structureModule[tab.value]];
      });
  }

  const handleContextMenu = (
    event: React.MouseEvent,
    cardPosition: number,
    setTransition: React.Dispatch<React.SetStateAction<boolean>>,
    tipoTarjeta: number
  ) => {
    event.preventDefault();

    const conditionalElement = excludedEditCards.find((cardType) => cardType === tipoTarjeta) ? (
      <></>
    ) : (
      <ContextMenuItem key="editElement" option={ContextMenuOptions.edit} />
    );

    const items: Array<JSX.Element> = [
      conditionalElement,
      <ContextMenuItem key="deleteElement" option={ContextMenuOptions.delete} />
    ];
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            cardPosition,
            setTransition,
            items
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  function handleOpenDialog(option: ContextMenuOptions): void {
    setDialogState({ openDialog: true, ...dialogOptions[option] });
  }

  function handleCloseDialog(): void {
    setDialogState(initialDialogState);
  }

  React.useEffect(() => {
    setTipoTarjeta(initialState);
  }, [tab.selectedModule]);

  const ContextMenuItem = ({ option }: { option: ContextMenuOptions }): JSX.Element => (
    <MenuItem
      onClick={() => {
        handleOpenDialog(option);
      }}
    >
      {option}
    </MenuItem>
  );

  return {
    changeAvailableCard,
    tipoTarjeta,
    saveConfiguration,
    cards,
    selectedStructure,
    editCard,
    handleOpenDialog,
    handleCloseDialog,
    dialogState,
    handleContextMenu,
    handleCloseContextMenu,
    contextMenu,
    deleteConfiguration
  };
}
