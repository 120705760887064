import { desencriptarRespuesta } from '../../../functions/encryption';
import axios from 'axios';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path: string;
let respuesta: any;

/**
 * Función que obtiene los tipos de actuaciones filtrados por dispositivo
 * @param { string } servicio  servicio al que necesitamos llamar
 * @param { number } idDispositivo id del dispositivo por el que queremos filtrar. El dispositivo tiene que tener un device asociado
 * @returns { Promise<anu> } Promesa que contiene una lista de elementos filtrados por dispositivo
 */
export async function obtenerTiposActuacionesPorDispositivo(
  servicio: string,
  idDispositivo: number
): Promise<any> {
  path = servicio + `/todos/dispositivo/${idDispositivo}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
