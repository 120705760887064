import { useRef } from 'react';
import {
  estadoPlugin,
  getPluginPorDevice,
  getPluginPorNombre,
  tiposDevicesByPlugin
} from '../services/servicioPlugin';

export function usePlugin(): {
  obtenerTiposDevicesByPlugin: (servicio: string, idPlugin: number) => Promise<any>;
  obtenerPluginByNombre: (servicio: string, namePlugin: string) => Promise<any>;
  obtenerPluginByDevice: (servicio: string, idDevice: number) => Promise<any>;
  cambiarEstadoPlugin: (servicio: string, namePlugin: string) => Promise<any>;
} {
  const listaDatos = useRef();

  /**
   * Función que obtiene todos los tipos de devices filtrados por un plugin concreto
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idPlugin id del plugin del que queremos los tiposDevices
   * @returns { Promise<any> } promesa que contiene una lista con los tipos devices de un plugin
   */
  async function obtenerTiposDevicesByPlugin(servicio: string, idPlugin: number): Promise<any> {
    const respuesta: any = await tiposDevicesByPlugin(servicio, idPlugin);
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Función que obtiene todos los datos de un plugin segun el nombre de un plugin
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } namePlugin nombre del plugin
   * @returns { Promise<any> }
   */
  async function obtenerPluginByNombre(servicio: string, namePlugin: string): Promise<any> {
    const respuesta: any = await getPluginPorNombre(servicio, namePlugin);
    return respuesta;
  }

  /**
   * Función que obtiene todos los datos de un plugin segun el id de un device asociado
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idDevice id del device asociado al plugin
   * @returns { Promise<any> }
   */
  async function obtenerPluginByDevice(servicio: string, idDevice: number): Promise<any> {
    const respuesta: any = await getPluginPorDevice(servicio, idDevice);
    return respuesta;
  }

  /**
   * Función que modifica el estado de un Plugin: instalar, desinstalar, pausar o reanudar
   * @param { string } servicio servicio al que queremos llamar
   * @param { any } objeto objeto que contiene el nombre del plugin o la imagenDocker y version del que queremos cambiar su estado
   * @returns { Promise<any> }
   */
  async function cambiarEstadoPlugin(servicio: string, objeto: any): Promise<any> {
    const respuesta: any = await estadoPlugin(servicio, objeto);
    return respuesta;
  }

  return {
    obtenerTiposDevicesByPlugin,
    obtenerPluginByNombre,
    obtenerPluginByDevice,
    cambiarEstadoPlugin
  };
}
