import React from 'react';

import { ReactComponent as iconTemperatura } from '../../../resources/icons/iconsTiposMedida/1.svg';
import { ReactComponent as iconHumedad } from '../../../resources/icons/iconsTiposMedida/2.svg';
import { ReactComponent as iconConsumo } from '../../../resources/icons/iconsTiposMedida/3.svg';
import { ReactComponent as iconCO2 } from '../../../resources/icons/iconsTiposMedida/4.svg';
import { ReactComponent as iconBateria } from '../../../resources/icons/iconsTiposMedida/5.svg';
import { ReactComponent as iconDistancia } from '../../../resources/icons/iconsTiposMedida/6.svg';
import { ReactComponent as iconInclinacion } from '../../../resources/icons/iconsTiposMedida/7.svg';
import { ReactComponent as iconEstadoParking } from '../../../resources/icons/iconsTiposMedida/8.svg';
import { ReactComponent as iconEncendido } from '../../../resources/icons/iconsTiposMedida/9.svg';
import { ReactComponent as iconVelocidadViento } from '../../../resources/icons/iconsTiposMedida/10.svg';
import { ReactComponent as iconVibracion } from '../../../resources/icons/iconsTiposMedida/11.svg';
import { ReactComponent as iconLLuvia } from '../../../resources/icons/iconsTiposMedida/12.svg';
import { ReactComponent as iconGenerado } from '../../../resources/icons/iconsTiposMedida/13.svg';
import { ReactComponent as iconBrillo } from '../../../resources/icons/iconsTiposMedida/14.svg';
import { ReactComponent as iconLocation } from '../../../resources/icons/iconsTiposMedida/15.svg';
import { ReactComponent as iconPotencia } from '../../../resources/icons/iconsTiposMedida/16.svg';
import { ReactComponent as iconPerfil } from '../../../resources/icons/iconsTiposMedida/17.svg';
import { ReactComponent as iconIdentificacion } from '../../../resources/icons/iconsTiposMedida/18.svg';
import { ReactComponent as iconCorriente } from '../../../resources/icons/iconsTiposMedida/19.svg';
import { ReactComponent as iconPH } from '../../../resources/icons/iconsTiposMedida/20.svg';
import { ReactComponent as iconConductividadElectrica } from '../../../resources/icons/iconsTiposMedida/21.svg';
import { ReactComponent as iconOxidacion } from '../../../resources/icons/iconsTiposMedida/22.svg';
import { ReactComponent as iconPresion } from '../../../resources/icons/iconsTiposMedida/23.svg';
import { ReactComponent as iconRadiacionSolar } from '../../../resources/icons/iconsTiposMedida/24.svg';
import { ReactComponent as iconAbiertoCerrado } from '../../../resources/icons/iconsTiposMedida/25.svg';
import { ReactComponent as iconDecibelios } from '../../../resources/icons/iconsTiposMedida/26.svg';
import { ReactComponent as iconLumenes } from '../../../resources/icons/iconsTiposMedida/27.svg';
import { ReactComponent as iconSignalWifi } from '../../../resources/icons/iconsTiposMedida/28.svg';
import { ReactComponent as iconVoltaje } from '../../../resources/icons/iconsTiposMedida/29.svg';
import { ReactComponent as iconAltitud } from '../../../resources/icons/iconsTiposMedida/30.svg';
import { ReactComponent as iconEstadoCielo } from '../../../resources/icons/iconsTiposMedida/31.svg';
import { ReactComponent as iconProbabilidadLluvia } from '../../../resources/icons/iconsTiposMedida/32.svg';
import { ReactComponent as iconRegion } from '../../../resources/icons/iconsTiposMedida/33.svg';
import { ReactComponent as iconCalle } from '../../../resources/icons/iconsTiposMedida/34.svg';
import { ReactComponent as iconGrupo } from '../../../resources/icons/iconsTiposMedida/35.svg';
import { ReactComponent as iconVariacionConsumo } from '../../../resources/icons/iconsTiposMedida/36.svg';
import { ReactComponent as iconCO } from '../../../resources/icons/iconsTiposMedida/81.svg';
import { ReactComponent as iconNO2 } from '../../../resources/icons/iconsTiposMedida/82.svg';
import { ReactComponent as iconO3 } from '../../../resources/icons/iconsTiposMedida/83.svg';
import { ReactComponent as iconSO2 } from '../../../resources/icons/iconsTiposMedida/84.svg';
import { ReactComponent as iconPM1 } from '../../../resources/icons/iconsTiposMedida/85.svg';
import { ReactComponent as iconPM25 } from '../../../resources/icons/iconsTiposMedida/86.svg';
import { ReactComponent as iconPM10 } from '../../../resources/icons/iconsTiposMedida/87.svg';
import { ReactComponent as iconICA } from '../../../resources/icons/iconsTiposMedida/88.svg';
import { ReactComponent as iconIntensidadSonido } from '../../../resources/icons/iconsTiposMedida/91.svg';
import { ReactComponent as iconPeso } from '../../../resources/icons/iconsTiposMedida/92.svg';
import { ReactComponent as iconPHAbastecimiento } from '../../../resources/icons/iconsTiposMedida/93.svg';
import { ReactComponent as iconORPAbastecimiento } from '../../../resources/icons/iconsTiposMedida/94.svg';
import { ReactComponent as iconNivelLlenadoAgua } from '../../../resources/icons/iconsTiposMedida/95.svg';
import { ReactComponent as iconContaminacionEntradaAgua } from '../../../resources/icons/iconsTiposMedida/96.svg';
import { ReactComponent as iconContaminacionSalidaAgua } from '../../../resources/icons/iconsTiposMedida/97.svg';
import { ReactComponent as iconCaudalimetroTroncal } from '../../../resources/icons/iconsTiposMedida/98.svg';
import { ReactComponent as iconCaudalimetroIndividual } from '../../../resources/icons/iconsTiposMedida/99.svg';
import { ReactComponent as iconTemperaturaAbastecimiento } from '../../../resources/icons/iconsTiposMedida/100.svg';
import { SvgIcon, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TiposMedida } from '../../../resources/enums/enumTiposMedida';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

export function IconosTiposMedida(props: {
  icono: string | null;
  nombre?: string;
  style?: {
    fontSize?: any;
    marginRight?: number;
    float?: any;
    width?: any;
    height?: any;
    marginLeft?: number;
  };
  color?: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | undefined;
  htmlColor?: string;
}): JSX.Element {
  const theme = useTheme();
  const iconTipoMedida: any = {
    location: { icon: iconLocation, viewBox: '-50 0 600 476.6' },
    [TiposMedida.TEMPERATURA]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.HUMEDAD]: { icon: iconHumedad, viewBox: '-50 0 600 476.6' },
    [TiposMedida.CONSUMO]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.CO_2]: { icon: iconCO2, viewBox: '-50 0 600 476.6' },
    [TiposMedida.BATERIA]: { icon: iconBateria, viewBox: '-50 0 500 450' },
    [TiposMedida.DISTANCIA]: { icon: iconDistancia, viewBox: '-50 0 -1 476.6' },
    [TiposMedida.INCLINACION]: { icon: iconInclinacion, viewBox: '-50 0 -1 476.6' },
    [TiposMedida.ESTADO_PARKING]: { icon: iconEstadoParking, viewBox: '-50 0 600 476.6' },
    [TiposMedida.ON_OFF]: { icon: iconEncendido, viewBox: '-9 0 45 30' },
    [TiposMedida.VELOCIDAD_VIENTO]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.VIBRACION]: { icon: iconVibracion, viewBox: '-50 0 -1 476.6' },
    [TiposMedida.LLUVIA]: { icon: iconLLuvia, viewBox: '-50 0 600 476.6' },
    [TiposMedida.GENERADO]: { icon: iconGenerado, viewBox: '-50 0 600 476.6' },
    [TiposMedida.BRILLO]: { icon: iconBrillo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.UBICACION]: { icon: iconLocation, viewBox: '-70 0 600 476.6' },
    [TiposMedida.POTENCIA]: { icon: iconPotencia, viewBox: '-20 0 400 425' },
    [TiposMedida.PERFIL]: { icon: iconPerfil, viewBox: '-70 0 600 476.6' },
    [TiposMedida.IDENTIFICACION]: { icon: iconIdentificacion, viewBox: '-70 0 600 476.6' },
    [TiposMedida.CORRIENTE]: { icon: iconCorriente, viewBox: '-50 0 600 476.6' },
    [TiposMedida.PH]: { icon: iconPH, viewBox: '-70 0 550 476.6' },
    [TiposMedida.CONDUCTIVIDAD_ELECTRICA]: {
      icon: iconConductividadElectrica,
      viewBox: '-70 0 600 476.6'
    },
    [TiposMedida.ORP]: { icon: iconOxidacion, viewBox: '-70 0 550 476.6' },
    [TiposMedida.PRESION]: { icon: iconPresion, viewBox: '-30 0 600 476.6' },
    [TiposMedida.RADIACION_SOLAR]: { icon: iconRadiacionSolar, viewBox: '-50 0 600 476.6' },
    [TiposMedida.ABIERTO_CERRADO]: { icon: iconAbiertoCerrado, viewBox: '-70 0 500 476.6' },
    [TiposMedida.DECIBELIOS]: { icon: iconDecibelios, viewBox: '-70 0 500 476.6' },
    [TiposMedida.LUMENES]: { icon: iconLumenes, viewBox: '-50 0 550 476.6' },
    [TiposMedida.SENAL_WIFI]: { icon: iconSignalWifi, viewBox: '-20 20 500 476.6' },
    [TiposMedida.VOLTAJE]: { icon: iconVoltaje, viewBox: '-20 0 550 476.6' },
    [TiposMedida.ALTITUD]: { icon: iconAltitud, viewBox: '-20 0 600 476.6' },
    [TiposMedida.ESTADO_CIELO]: { icon: iconEstadoCielo, viewBox: '-20 0 550 476.6' },
    [TiposMedida.PROBABILIDAD_LLUVIA]: { icon: iconProbabilidadLluvia, viewBox: '-20 0 550 476.6' },
    [TiposMedida.REGION]: { icon: iconRegion, viewBox: '-50 0 550 476.6' },
    [TiposMedida.CALLE]: { icon: iconCalle, viewBox: '-20 0 500 476.6' },
    [TiposMedida.GRUPO]: { icon: iconGrupo, viewBox: '-20 0 650 476.6' },
    [TiposMedida.VARIACION_CONSUMO]: { icon: iconVariacionConsumo, viewBox: '-20 0 650 476.6' },
    // SIN ICONOS OFICIALES, PONEMOS LOS DE OTRAS MEDIDAS ALGO "PARECIDAS" DE FORMA PROVISIONAL
    [TiposMedida.SAC_ABSORCION_ESPECTRAL_UV]: {
      icon: iconRadiacionSolar,
      viewBox: '-50 0 600 476.6'
    },
    [TiposMedida.COD_QUIMICA_OXIGENO]: { icon: iconOxidacion, viewBox: '-70 0 550 476.6' },
    [TiposMedida.BOD_BIOQUIMICA_OXIGENO]: { icon: iconOxidacion, viewBox: '-70 0 550 476.6' },
    [TiposMedida.CONTAMINACION_ENTRADA]: {
      icon: iconContaminacionEntradaAgua,
      viewBox: '10 10 200 200'
    },
    [TiposMedida.TE_TURBIDEZ]: { icon: iconOxidacion, viewBox: '-70 0 550 476.6' },
    [TiposMedida.UV_ABSORBANCIA]: { icon: iconRadiacionSolar, viewBox: '-50 0 600 476.6' },
    [TiposMedida.GR_ABSORBANCIA]: { icon: iconRadiacionSolar, viewBox: '-50 0 600 476.6' },
    [TiposMedida.UV_TRANSMITANCIA]: { icon: iconRadiacionSolar, viewBox: '-50 0 600 476.6' },
    [TiposMedida.GR_TRANSMITANCIA]: { icon: iconRadiacionSolar, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NUM_PERSONAS]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NUM_VEHICULOS]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NUM_VEHICULOS_PEQ]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NUM_ROSTROS]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NUM_INTRUSIONES]: { icon: iconConsumo, viewBox: '-50 0 600 476.6' },
    [TiposMedida.ROSTRO_DETECTADO]: { icon: iconIdentificacion, viewBox: '-70 0 600 476.6' },
    [TiposMedida.MATRICULA_DETECTADA]: { icon: iconIdentificacion, viewBox: '-70 0 600 476.6' },
    [TiposMedida.EVENTO_DETECTADO]: { icon: iconIdentificacion, viewBox: '-70 0 600 476.6' },
    [TiposMedida.TEMPERATURA_MIN]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.TEMPERATURA_MAX]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.DIRECCION_VIENTO]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.DIRECCION_RAFAGA]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.VELOCIDAD_RAFAGA]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.TEMPERATURA_SUELO]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.NIVEL_LLENADO_AGUA]: { icon: iconNivelLlenadoAgua, viewBox: '25 10 200 200' },
    [TiposMedida.TEMPERATURA_AGUA_ABASTECIMIENTO]: {
      icon: iconTemperaturaAbastecimiento,
      viewBox: '20 25 200 200'
    },
    [TiposMedida.CONTAMINACION_SALIDA]: {
      icon: iconContaminacionSalidaAgua,
      viewBox: '10 10 200 200'
    },
    [TiposMedida.CONSUMO_CAUDAL_ENTRADA]: {
      icon: iconCaudalimetroTroncal,
      viewBox: '27 10 215 215'
    },
    [TiposMedida.CONSUMO_CAUDAL]: { icon: iconCaudalimetroIndividual, viewBox: '27 10 215 215' },
    [TiposMedida.PH_AGUA_ABASTECIMIENTO]: { icon: iconPHAbastecimiento, viewBox: '10 0 235 235' },
    [TiposMedida.ORP_AGUA_ABASTECIMIENTO]: {
      icon: iconORPAbastecimiento,
      viewBox: '10 10 200 200'
    },
    [TiposMedida.TEMP_MIN]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.TEMP_MAX]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.TEMP_AVG]: { icon: iconTemperatura, viewBox: '-50 0 600 476.6' },
    [TiposMedida.HUMEDAD_MIN]: { icon: iconHumedad, viewBox: '-50 0 600 476.6' },
    [TiposMedida.HUMEDAD_MAX]: { icon: iconHumedad, viewBox: '-50 0 600 476.6' },
    [TiposMedida.HUMEDAD_AVG]: { icon: iconHumedad, viewBox: '-50 0 600 476.6' },
    [TiposMedida.VIENTO_MIN]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.VIENTO_MAX]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.VIENTO_AVG]: { icon: iconVelocidadViento, viewBox: '-50 0 600 476.6' },
    [TiposMedida.LLUVIA_MIN]: { icon: iconLLuvia, viewBox: '-50 0 600 476.6' },
    [TiposMedida.LLUVIA_MAX]: { icon: iconLLuvia, viewBox: '-50 0 600 476.6' },
    [TiposMedida.LLUVIA_AVG]: { icon: iconLLuvia, viewBox: '-50 0 600 476.6' },
    [TiposMedida.MONOXIDO_CARBONO]: { icon: iconCO, viewBox: '0 0 24 24' },
    [TiposMedida.DIOXIDO_NITROGENO]: { icon: iconNO2, viewBox: '0 0 24 24' },
    [TiposMedida.DIOXIDO_AZUFRE]: { icon: iconSO2, viewBox: '0 0 24 24' },
    [TiposMedida.PARTICULAS_PM1]: { icon: iconPM1, viewBox: '0 0 24 24' },
    [TiposMedida.PARTICULAS_PM25]: { icon: iconPM25, viewBox: '0 0 24 24' },
    [TiposMedida.PARTICULAS_PM10]: { icon: iconPM10, viewBox: '0 0 24 24' },
    [TiposMedida.OZONO]: { icon: iconO3, viewBox: '0 0 24 24' },
    [TiposMedida.INDICE_CALIDAD_AIRE]: { icon: iconICA, viewBox: '0 0 24 24' },
    [TiposMedida.INTENSIDAD_SONIDO]: { icon: iconIntensidadSonido, viewBox: ' 0 0 350 400' },
    [TiposMedida.PESO]: { icon: iconPeso, viewBox: '-50 0 550 476.6' }
  };
  if (!props.icono)
    return (
      <Tooltip title="No se encuentra el icono">
        <SvgIcon
          component={ImageSearchIcon}
          style={{
            width: props.style?.width ?? 25,
            height: props.style?.height ?? 25,
            fontSize: props.style?.fontSize ?? ''
          }}
          {...props}
        />
      </Tooltip>
    );

  return (
    <>
      {iconTipoMedida[props.icono] === undefined ? (
        <Typography variant="subtitle1">{props.nombre}</Typography>
      ) : (
        <SvgIcon
          component={iconTipoMedida[props.icono].icon}
          viewBox={iconTipoMedida[props.icono].viewBox}
          style={{
            color: props.color ?? theme.palette.secondary.main,
            width: props.style?.width ?? 25,
            height: props.style?.height ?? 25,
            fontSize: props.style?.fontSize ?? ''
          }}
          {...props}
        />
      )}
    </>
  );
}
