/**
 * Enumerado de los stream que puede existir
 * @readonly
 * @enum { number } Stream
 * @member DEFECTO = -1,
 * @member PRINCIPAL = 0,
 * @member SECUNDARIO = 1,
 * @member TERCIARIO = 2
 */
export enum Stream {
  DEFECTO = -1,
  PRINCIPAL = 0,
  SECUNDARIO = 1,
  TERCIARIO = 2
}
