import { tipoDispositivoByDispositivo } from '../resources/serviciosTipoDispositivo';

export function useTiposDispositivos(): {
  obtenerTipoDispositivoPorDispositivo: (servicio: string, idDispositivo: number) => Promise<any>;
} {
  /**
   * @description Función que obtiene el tipo de dispositivo de un dispositivo en concreto
   * @param { string } servicio servicio al que queremos llevamar
   * @param { number } idDispositivo identificador del dispositivo
   */
  async function obtenerTipoDispositivoPorDispositivo(servicio: string, idDispositivo: number) {
    const respuesta: any = await tipoDispositivoByDispositivo(servicio, idDispositivo);

    if (!respuesta.isAxiosError) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  return {
    obtenerTipoDispositivoPorDispositivo
  };
}
