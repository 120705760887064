import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pxToVw } from '../../../functions/functions';

export const useStyles = makeStyles((theme: Theme) => ({
  button_tarjetaHome: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 3px 0px ',
      borderRadius: '15px'
    }
  },
  gridContainer: {
    padding: '24px'
  },
  gridMapa3d: {
    height: '450px',
    paddingBottom: '15px'
  },
  boxTypographyMapa: {
    position: 'absolute',
    top: '190px',
    marginLeft: '30px',
    width: '28%',
    [theme.breakpoints.down('md')]: {
      width: '43%'
    }
  },
  tarjetaDialogos: {
    fontVariant: 'all-small-caps',
    textAlign: 'right',
    fontSize: '0.63rem'
  },
  typographyNumeroAlertas: {
    textAlign: 'right',
    lineHeight: '0.5',
    letterSpacing: '-3px',
    fontSize: '1.1rem',
    width: '100%'
  },
  typographyBienvenidoA: {
    fontWeight: 'bold'
  },
  typographyFechaMapa: {
    marginTop: '15px'
  },
  typographyPrevision: {},
  imgTiempoMapa: {
    width: '100%',
    height: '53px'
  },
  typographyMaxMin: {},
  bMaxMin: {
    marginRight: '2px'
  },
  tarjeta: {
    textAlign: 'end',
    width: '100%'
  },
  typographyHoy: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  typographyTarjetas: {
    fontSize: '0.61rem',
    fontVariant: 'all-small-caps'
  },
  typographyNumerosTarjetas: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    position: 'relative'
  },
  typographyClima: {
    fontSize: '2rem',
    textAlign: 'end',
    letterSpacing: '-5px',
    position: 'relative'
  },
  imgSalidaSolLuna: {
    width: pxToVw(40)
  },
  box: {
    height: '100%'
  },
  imgIntereses: {
    width: '60px'
  },
  iconoAdd: {
    marginBottom: 'auto',
    marginLeft: 'auto'
  },
  boxIntereses: {
    marginBottom: '15px',
    alignItems: 'end'
  },
  typographyAlert: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'end',
    lineHeight: '1',
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}));
