import { AxiosRequestConfig } from 'axios';
import { encriptarJSON } from 'functions/encryption';
import { swrServiceRoutes } from './serviceRoutes';
import { Key } from 'swr';
import { rutasServicios } from 'resources/enums/enumRutasServicios';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';

export function getAllByModule(idModule: number, path: rutasServicios) {
  const request: AxiosRequestConfig | null = {
    method: 'POST',
    headers: getHeaders(dictionary_entornos.BACK),
    url: process.env.REACT_APP_URL_LOCAL + path + swrServiceRoutes.getAllByModule,
    data: encriptarJSON({ idModulo: idModule })
  };
  const key: Key = `${path}${swrServiceRoutes.getAllByModule}${idModule}`;

  return { key, request };
}

export function getAll(path: rutasServicios) {
  const request: AxiosRequestConfig | null = {
    method: 'GET',
    headers: getHeaders(dictionary_entornos.BACK),
    url: process.env.REACT_APP_URL_LOCAL + path + swrServiceRoutes.getAll
  };
  const key: Key = `${path}${swrServiceRoutes.getAll}`;

  return { key, request };
}

export function editRecord(path: string) {
  const request: AxiosRequestConfig | null = {
    method: 'PUT',
    headers: getHeaders(dictionary_entornos.BACK),
    url: process.env.REACT_APP_URL_LOCAL + path + swrServiceRoutes.editRecord
  };
  const key: Key = 'editRecord';

  return { key, request };
}

export function createRecord(path: string) {
  const request: AxiosRequestConfig | null = {
    method: 'POST',
    headers: getHeaders(dictionary_entornos.BACK),
    url: process.env.REACT_APP_URL_LOCAL + path + swrServiceRoutes.createRecord
  };
  const key: Key = 'createRecord';

  return { key, request };
}

export function deleteRecords(path: string) {
  const request: AxiosRequestConfig | null = {
    method: 'POST',
    headers: getHeaders(dictionary_entornos.BACK),
    url: process.env.REACT_APP_URL_LOCAL + path + swrServiceRoutes.deleteRecords
  };
  const key: Key = 'deleteRecords';

  return { key, request };
}
