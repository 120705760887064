import axios, { CancelTokenSource } from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';
import { ConsultaGraficas } from '../resources/interfaces/interfaceConsultaGraficas';
import { formatearFechas } from '../functions/funciones';

let respuesta: any;

/**
 * Función que llama a los servicios par obtener los datos de las graficas
 * @param {ConsultaGraficas} consulta consulta para obtener los datos
 * @param {CancelTokenSource | undefined} cancelarPeticion aplicamos cancelToken para poder cancelar la petición
 * cuando cambiemos de dispositivo, ya que se puede demorar muchisimo tiempo y las peticiones
 * se van encolando --> https://axios-http.com/docs/cancellation --> a partir de la v0.22.0 de Axios, se debe de hacer con AbortController()
 * @returns {Promise<any>} promesa con la respuesta del servicio
 */
export async function servicioObtenerDatosGraficas(
  consulta: ConsultaGraficas,
  cancelarPeticion?: CancelTokenSource
): Promise<any> {
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + `graficas/${consulta.servicio}/`,
        encriptarJSON({
          range: consulta.range,
          group: consulta.group,
          startDate: consulta.startDate,
          endDate: consulta.endDate,
          dispositivos: consulta.dispositivos,
          modulos: consulta.modulos,
          mostrarSinAgrupar: consulta.mostrarSinAgrupar,
          measure: consulta.measure,
          operation: consulta.operation,
          obtenerTotal: consulta.obtenerTotal
        }),
        {
          cancelToken: cancelarPeticion ? cancelarPeticion.token : undefined,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );

    return formatearFechas(desencriptarRespuesta(respuesta));
  } catch (exception) {
    return exception;
  }
}
