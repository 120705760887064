import { SvgIcon } from '@mui/material';
import React from 'react';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';
import { tipoAlerta } from '../resources/enums/enumTipoAlerta';
import { ReactComponent as cancel } from '../resources/iconsTabla/cancel.svg';
import { ReactComponent as check } from '../resources/iconsTabla/check.svg';
import { ReactComponent as information } from '../resources/iconsTabla/information.svg';
import { ReactComponent as warning } from '../resources/iconsTabla/warning.svg';
import { ReactComponent as event } from '../resources/iconsTabla/information.svg';

export function seleccionarIcono(
  idTipoAlerta: number,
  timeLine?: boolean
): { icono: JSX.Element; titulo: string } {
  const obj = idTipoAlerta === null ? 0 : idTipoAlerta;
  const icon: any = {
    [tipoAlerta.INFO]: { icono: information, titulo: 'Información', color: 'blue' },
    [tipoAlerta.SUCCESS]: { icono: check, titulo: 'Realizado', color: 'green' },
    [tipoAlerta.WARNING]: { icono: warning, titulo: 'Advertencia', color: 'orange' },
    [tipoAlerta.ERROR]: { icono: cancel, titulo: 'Error', color: 'red' },
    [tipoAlerta.EVENT]: { icono: event, titulo: 'Evento', color: 'purple' }
  };
  const respuesta = {
    icono: (
      <SvgIcon
        component={icon[obj].icono}
        viewBox="0 0 510 510"
        htmlColor={icon[obj].color}
        style={timeLine ? { width: '100%', height: '100%' } : {}}
      />
    ),
    titulo: icon[obj].titulo
  };

  return respuesta;
}

type severity = 'success' | 'info' | 'warning' | 'error';

/**
 * Función que devuelve el severity según el id del tipo de Alerta. Por defecto devolverá info
 * @param {number} idTipoAlerta id del tipo de alerta
 * @returns {severity} severity: 'success', 'info', 'warning' o 'error' del tipo de alerta
 */
export function getSeverity(idTipoAlerta: number): severity {
  switch (idTipoAlerta) {
    case tipoAlerta.SUCCESS:
      return enumSeverity.SUCCESS;
    case tipoAlerta.WARNING:
      return enumSeverity.WARNING;
    case tipoAlerta.ERROR:
      return enumSeverity.ERROR;
    default:
      return enumSeverity.INFO;
  }
}
