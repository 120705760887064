import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Tipo Dispositivo Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  // Tipo Dispositivo CRU
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium',
    marginBottom: '2px'
  },
  cajas: {
    marginBottom: '15px'
  },
  alert: {
    alignItems: 'center'
  },
  // Tipo Dispositivo Carrousel
  rootCarrousel: {
    flexGrow: 1,
    maxWidth: '100%',
    marginBottom: '20px'
  },
  tabWidth: {
    width: '100%'
  },
  icono: {
    '&>.MuiTab-wrapper>.MuiIcon-root': {
      height: '2em'
    }
  }
});
export default useStyles;
