import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  }
});
export default useStyles;
