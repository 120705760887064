import React, { useContext } from 'react';
import './SmartTeliaBackdrop.css';
import Backdrop from '@mui/material/Backdrop';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import preloader from '../../../resources/images/preloader.gif';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 101,
    color: '#fff',
    backgroundColor: '#fbfbfb'
  }
}));

export function SmartTeliaBackdrop(props: { open: boolean }): JSX.Element {
  const { open = false } = props;
  const classes = useStyles();
  const { openSmartTeliaBackdrop = false } = useContext(SmartTeliaBackdropContext);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={!open ? openSmartTeliaBackdrop : open}>
        <img src={preloader} alt="gifPreloader" style={{ width: '75%' }} />;
      </Backdrop>
    </div>
  );
}
