import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export function BackdropTarjetasModulos({
  open,
  marginTop = '0px'
}: {
  open: boolean;
  marginTop?: string;
}): JSX.Element {
  const style = {
    backgroundColor: 'transparent',
    zIndex: (theme: any) => theme.zIndex.drawer + 1,
    position: 'absolute',
    borderRadius: '15px',
    marginTop: marginTop
  };

  return (
    <Backdrop
      sx={{
        backgroundColor: 'transparent',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        borderRadius: '15px',
        marginTop: marginTop
      }}
      open={open}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
