import { AxiosRequestConfig } from 'axios';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get the residuals parameters data.
 * @returns {Key, AxiosRequestConfig} key used by swr and axios request config.
 */
export function getResidualsParametersData() {
  const key: Key = `${swrServiceRoutes.getResidualsParametersData}`;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getResidualsParametersData,
    headers: getHeaders(dictionary_entornos.BACK)
  };
  return { key, request };
}
