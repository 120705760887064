import React, { useEffect, useState } from 'react';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { dictionary_generic, enumLabel } from '../../../resources/enums/plainText';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { Button, Grid, Input, TextField, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useStyles from '../../reglas/styles';
import { useReducerAcciones } from '../hooks/useReducerAcciones';

export function FormularioWhatsapp(props: { state?: any; events?: any }): JSX.Element {
  const { stateAcciones, events: eventosAcciones } = useReducerAcciones(
    props.events,
    enumTiposAcciones.whatsapp
  );
  const { state } = props;

  const { setWhatsapp, handleCreateAccion } = eventosAcciones;
  const { whatsappCompleto } = stateAcciones;
  const { handleInputChange, handleInputInvalid } = useEventChange(whatsappCompleto, setWhatsapp);
  const classes = useStyles();

  return (
    <form id="panelWhatsapp">
      <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
        {'Enviar WhatsApp'}
      </Typography>
      <Grid container direction="column">
        <Grid padding={1}>
          <PhoneInput
            country={'es'}
            onlyCountries={['es']}
            disableDropdown={true}
            value={whatsappCompleto.numeroWhatsapp}
            disableCountryCode={true}
            onChange={(value) => setWhatsapp({ ...whatsappCompleto, numeroWhatsapp: value })}
            placeholder={'Número de teléfono'}
            inputProps={{ id: 'numeroWhatsapp' }}
          />
        </Grid>
        <Grid padding={1}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth={true}
            label={enumLabel.MENSAJE}
            id="mensaje"
            type="text"
            required
            onChange={handleInputChange}
            onKeyDown={handleInputInvalid}
            onPaste={handleInputInvalid}
            multiline
            rows={3}
          />
        </Grid>

        <Grid container direction="row-reverse" padding={1}>
          <Button variant="contained" color="secondary" onClick={handleCreateAccion}>
            {dictionary_generic.CREAR}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
