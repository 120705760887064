import React from 'react';
import { InputAdornment } from '@mui/material';
import { Lock, Person, Email } from '@mui/icons-material';

const COLOR = '#5c5c5c';
const POSITION = 'start';

export const InputAdornmentPerson = () => {
  return (
    <InputAdornment position={POSITION}>
      <Person htmlColor={COLOR} />
    </InputAdornment>
  );
};

export const InputAdornmentLock = (): JSX.Element => {
  return (
    <InputAdornment position={POSITION}>
      <Lock htmlColor={COLOR} />
    </InputAdornment>
  );
};

export const InputAdornmentEmail = (): JSX.Element => {
  return (
    <InputAdornment position={POSITION}>
      <Email htmlColor={COLOR} />
    </InputAdornment>
  );
};
