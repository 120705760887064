import { regla, objectRegla } from './interfaceRegla';
import {
  dispositivo,
  objectDispositivo
} from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import {
  tiposDispositivo,
  objectTiposDispositivo
} from '../../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import {
  tipoActuacion,
  objectTipoActuacion
} from '../../../tiposActuaciones/resources/interfaces/interfaceTipoActuacion';

export interface actuacionCompleta {
  tipoDispositivo: tiposDispositivo;
  dispositivo: dispositivo;
  tipoActuacion: tipoActuacion;
  regla?: regla;
}

export function objectActuacionCompleta(): actuacionCompleta {
  return {
    tipoDispositivo: objectTiposDispositivo(),
    dispositivo: objectDispositivo(),
    tipoActuacion: objectTipoActuacion(),
    regla: objectRegla()
  };
}
