import React, { useEffect, useState } from 'react';
import { Checkbox, TextField, createFilterOptions, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export function SelectAllAutocomplete(props: any): JSX.Element {
  const {
    id,
    items,
    label,
    selectAllLabel,
    limitTags,
    onChange,
    disabled,
    errorAutocomplete,
    getOptionLabel,
    allDefaultValue,
    fullWidth = false,
    //Una variable cualquiera que cambie cuando necesitemos limpiar el autoComplete, por ejemplo, yo uso un booleano que cambia cuando lo necesito y por lo tanto se limpia.
    limpiarAutoComplete,
    className,
    selectedItems,
    additionalRender,
    dataTestId
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<Array<any>>(selectedItems ?? []);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = (selectedOptions: any) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event: any, selectedOptions: any, reason: any) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOptions.find((option: any) => option.value === 'select-all')) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter((el: any) => el.value !== 'select-all');
        return onChange(!allSelected ? result : []);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return onChange(selectedOptions);
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions();
      onChange([]);
    }
  };

  const optionRenderer = (props: any, option: any, { selected }: { selected: any }): any => {
    const selectAllProps =
      items.length > 0 && option.value === 'select-all' ? { checked: allSelected } : {};

    if (selectedItems) {
      selected = selectedItems.some((item: any) => item.id === option.id);
    }

    return (
      <li {...props} style={{ display: additionalRender ? 'block' : 'inherit' }}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />

        {
          <>
            <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>{' '}
            {additionalRender ? (
              <span
                style={{
                  display: 'block',
                  margin: 0,
                  padding: 0,
                  fontSize: '10px',
                  fontWeight: 'lighter'
                }}
              >
                {option[additionalRender]}
              </span>
            ) : (
              <></>
            )}
          </>
        }
      </li>
    );
  };

  const filter = createFilterOptions();

  useEffect(() => {
    if (allDefaultValue && items.length > 0) {
      setSelectedOptions(items);
      onChange(items);
    }
    if (!selectedItems && items.length === 0 && selectedOptions.length > 0) {
      setSelectedOptions([]);
    }
  }, [items]);

  useEffect(() => {
    if (selectedItems === null) setSelectedOptions([]);
  }, [limpiarAutoComplete]);

  return (
    <Autocomplete
      disablePortal
      id={id}
      multiple
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => option === value}
      fullWidth={fullWidth}
      className={className}
      filterOptions={(options: any, params: any): any => {
        if (items.length > 0) {
          const filtered = filter(options, params);
          return [{ nombre: selectAllLabel, value: 'select-all' }, ...filtered];
        } else if (items.length === 0) {
          const filtered = filter(options, params);
          return [...filtered];
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={(params) =>
        errorAutocomplete ? (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={errorAutocomplete.autocomplete === id ? true : false}
            helperText={errorAutocomplete.autocomplete === id ? errorAutocomplete.helperText : ''}
          />
        ) : (
          <TextField {...params} label={label} variant="outlined" />
        )
      }
      disabled={disabled}
    />
  );
}
