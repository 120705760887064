import { obtenerTiposActuacionesPorDispositivo } from '../resources/servicioTiposActuaciones';

export function useObtenerTiposActuaciones(): {
  obtenerTiposActuacionesByDispositivo: (servicio: string, idDispositivo: number) => Promise<any>;
} {
  /**
   * @description Función que obtiene todas las condiciones o acciones asociadas a una regla
   * @param { string } servicio servicio al que necesitamos llamar
   * @param { number } idRegla identificador de la regla asociada
   */
  async function obtenerTiposActuacionesByDispositivo(
    servicio: string,
    idDispositivo: number
  ): Promise<any> {
    const respuesta: any = await obtenerTiposActuacionesPorDispositivo(servicio, idDispositivo);
    if (!respuesta.isAxiosError) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  return {
    obtenerTiposActuacionesByDispositivo
  };
}
