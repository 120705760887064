import React from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import useStyles from './styles';
import { icon, MapIcons } from '../common/gis/components/iconos/Iconos';

export default function IconTabs(props: {
  onChange: any;
  valueTab: any;
  soloLectura?: number;
}): JSX.Element {
  const classes = useStyles();
  const iconos = Object.keys(icon);

  return (
    <div>
      <Paper square className={classes.rootCarrousel}>
        <Tabs
          value={props.valueTab}
          id="icon"
          onChange={props.onChange}
          variant="scrollable"
          scrollButtons={!props.soloLectura}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="Carrousel de iconos"
          className={classes.tabWidth}
        >
          {iconos.map((i: any, index: number) => {
            return (
              <Tab
                icon={<MapIcons icono={i} />}
                key={index}
                className={classes.icono}
                disabled={!!props.soloLectura}
              />
            );
          })}
        </Tabs>
      </Paper>
    </div>
  );
}
