import React, { useEffect, useState, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Fab, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './styles';
import { etiqueta } from './resources/interfaces/interfaceEtiquetas';
import { EtiquetasManager } from './EtiquetasManager';
import Table from '../common/table/Table';
import PaginationRounded from '../common/table/Paginacion';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import { enumTabla } from '../common/table/resources/enumTabla';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import ControlContext from '../../context/control/ControlContext';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { dictionary_etiquetas } from '../../resources/enums/plainText';
import { enumComponentes } from '../../resources/enums/enumComponente';

export function TablaEtiquetas(): JSX.Element {
  const classes = useStyles();
  const { setControl } = React.useContext(ControlContext);
  const theme = useTheme();
  const [etiquetas, setEtiquetas] = useState<etiqueta[]>([]);
  const { controllerRequest } = useControlador();
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  function confirmarEliminarAlert(): void {
    confirmarEliminar(etiquetas, setEtiquetas, rutasServicios.ETIQUETAS);
  }

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'codigo', label: 'Código' },
    { name: 'nombre', label: 'Nombre' },
    { name: 'fechaCreacion', label: 'Fecha Creación' },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_etiquetas.ETIQUETAS}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla.BOTONES_TABLA }, cellMeta: { dataIndex: number }) => {
      if (etiquetas.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <EtiquetasManager
            etiquetaSeleccionada={etiquetas[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_ETIQUETA}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((e) => etiquetas[e.dataIndex]));
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setEtiquetas(
        await controllerRequest(
          {
            type: action.OBTENER_DATOS_SIN_MODULO,
            payload: { servicio: rutasServicios.ETIQUETAS }
          },
          { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
        )
      );
    };
    cargarDatos();
  }, []);

  return (
    <div>
      {!openSmartTeliaBackdrop ? (
        <Table
          title={dictionary_etiquetas.ETIQUETAS}
          columns={columns}
          data={etiquetas}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      <Tooltip title={dictionary_etiquetas.CREAR_ETIQUETAS} arrow>
        <Fab
          tabIndex={0}
          color="secondary"
          aria-label="add"
          onClick={() => setControl(<EtiquetasManager />)}
          className={classes.fabBoton}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </div>
  );
}
