import * as React from 'react';
import { Grid, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownload';
import { enumTabla } from '../../../common/table/resources/enumTabla';
import { ContenedorElementos } from '../../../common/table/contenedorElementos';
import { useTheme } from '@mui/material/styles';
import { BotonTablaFilaGenerico } from '../../../common/table/BotonesTablaFila';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import { Box } from '@mui/system';
import { DataTableReport } from 'components/Reports/shared/DataTableReport/DataTableReport';
import { useReportContext } from 'components/Reports/Context/index.hook';
import { WaterQualityDetail } from './Detail';

const columnsOptions = {
  options: {
    setCellProps: () => {
      return {
        style: { fontSize: '0.365rem', padding: '12px' }
      };
    },
    setCellHeaderProps: () => {
      return {
        className: 'cellHeaderCustomTable'
      };
    }
  }
};

const components = {
  icons: { DownloadIcon }
};

export function WaterQualityReport(): JSX.Element {
  const theme = useTheme();
  const [openDetail, setOpenDetail] = React.useState({ open: false, row: undefined });

  const {
    reportData: [reportData]
  } = useReportContext();

  const {
    report: { data, foot }
  } = reportData as any;

  const handleClickOpen = (row) => {
    setOpenDetail({ open: true, row });
  };

  const columns = [
    {
      name: 'medida',
      label: 'Parámetros',
      ...columnsOptions
    },
    {
      name: 'vp',
      label: 'VP',
      ...columnsOptions
    },
    {
      name: 'valorMostrar',
      label: 'Valor última medición',
      ...columnsOptions
    },
    {
      name: 'fecuenciaMuestreo',
      label: 'Frecuencia muestreo',
      ...columnsOptions
    },
    {
      name: 'fechaMedida',
      label: 'Fecha última medición',
      ...columnsOptions
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          if (tableMeta.rowData[2] === 'Sin información') return <></>;

          return (
            <div key={enumTabla.BOTONES_TABLA}>
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaGenerico
                    datosTabla={tableMeta}
                    handleClick={handleClickOpen}
                    nombreBoton={'Ver detalles'}
                    icono={<ArtTrackIcon />}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </div>
          );
        }
      }
    },
    { name: 'color', options: { display: 'excluded' } }
  ];

  const rowsOptions = {
    filter: false,
    fixedHeader: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    pagination: false,
    tableBodyHeight: '16rem',
    viewColumns: false,
    setRowProps: (row: Array<any>) => {
      return {
        style: { backgroundColor: row[6] + '80', padding: '10px' }
      };
    },
    customTableBodyFooterRender: () => {
      return (
        <TableFooter
          style={{ position: 'sticky', bottom: 0, zIndex: 100, backgroundColor: 'white' }}
        >
          <TableRow>
            <TableCell colSpan={6}>
              <Box display={'flex'} justifyContent={'space-around'}>
                {foot.map((item: { name: string; color: string }) => (
                  <Box
                    display={'flex'}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    key={item.name}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: item.color,
                        marginRight: '5px'
                      }}
                    ></div>
                    <Typography>{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }
  };

  return (
    <React.Fragment>
      {data ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid item xs={12}>
            <DataTableReport
              body={data}
              columns={columns}
              variableOptions={rowsOptions}
              components={components}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <WaterQualityDetail openDetail={openDetail} setOpenDetail={setOpenDetail} />
    </React.Fragment>
  );
}
