import React from 'react';
import Table from '../../../common/table/Table';

export function DataTableReport({
  columns,
  body,
  variableOptions,
  components
}: {
  columns: Array<any>;
  body: any;
  variableOptions?: any;
  components?: any;
}): JSX.Element {
  const defaultOptions = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    search: false,
    rowsPerPage: 10,
    selectableRows: 'none'
  };

  const options = variableOptions || defaultOptions;
  return (
    <Table
      title={''}
      columns={columns}
      data={body}
      options={options}
      components={components}
    ></Table>
  );
}
