import React from 'react';
import { Box, FormControlLabel } from '@mui/material';
import { Button, Grid, Slider, Switch, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from './styles';
import { ParametrosConfiguracion } from './ParametrosConfiguracion';
import { calcularFrecuenciaObtener } from '../../functions/functions';
import { dictionary_devices, dictionary_generic, enumLabel } from '../../resources/enums/plainText';
import { State } from './hooks/useReducerDevice/resources/types/typeState';
import { Events } from './hooks/useReducerDevice/resources/types/typeEvents';
import { DeviceManager } from './DeviceManager';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';

export function DeviceCRU({ state, events }: { state: State; events: Events }): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <form id="formDevice" onSubmit={events.handleSubmit} autoComplete="off">
        <Grid container spacing={2}></Grid>
        <Box display="flex" marginBottom="30px">
          <VolverAtras
            backTo={
              <DeviceManager
                deviceSeleccionado={state.deviceSeleccionado}
                pluginSeleccionado={state.pluginSeleccionado}
                key={
                  (state.key === enumComponentes.INFO_MODIFICAR_DEVICE
                    ? enumComponentes.INFORMACION_DEVICE
                    : state.pluginSeleccionado?.id
                    ? enumComponentes.DEVICES_POR_PLUGIN
                    : enumComponentes.DEVICES) +
                  state.keySecond +
                  state.keyThird
                }
              />
            }
          />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {state.deviceModificar
              ? dictionary_devices.MODIFICAR_DEVICE
              : dictionary_devices.CREAR_DEVICE}
            {state.pluginSeleccionado?.id
              ? ` para ${state.pluginSeleccionado.nombre.toUpperCase()}`
              : state.deviceModificar
              ? ` para ${state.deviceModificar?.device.nombrePlugin?.toUpperCase()}`
              : ''}
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Grid container sm={5} xs={12} spacing={2} direction="column">
            <Grid item className={classes.gridTitulo}>
              <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
                {dictionary_devices.DEVICE}
              </Typography>
            </Grid>
            {state.plugins !== undefined ? (
              <Grid item>
                <Autocomplete
                  size="small"
                  id="idPlugin"
                  options={state.plugins}
                  getOptionLabel={(option) => option.nombre}
                  onChange={events.handleAutocompletePlugins}
                  disabled={state.deviceModificar !== undefined ? true : false}
                  renderInput={(params) => (
                    <TextField {...params} label={enumLabel.PLUGIN} variant="outlined" required />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item>
              <Autocomplete
                key={state.limpiar}
                size="small"
                id="idTipoDevice"
                defaultValue={{
                  nombre:
                    state.deviceModificar !== undefined
                      ? (state.deviceModificar.device.nombreTipoDevice as string)
                      : ''
                }}
                options={state.tiposDevice !== undefined ? state.tiposDevice : []}
                getOptionLabel={(option) => option.nombre}
                onChange={events.handleAutocompleteTipoDevice}
                disabled={
                  state.deviceModificar !== undefined || state.disabledTipoDevice ? true : false
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={enumLabel.TIPO_DEVICE}
                    variant="outlined"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.NOMBRE_DEVICE}
                id="nombre"
                type="text"
                required
                disabled={state.disabled}
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={state.deviceModificar ? state.deviceModificar.device.nombre : null}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                fullWidth={true}
                label={enumLabel.DESCRIPCION_DEVICE}
                id="descripcion"
                type="text"
                required
                disabled={state.disabled}
                onChange={events.handleInputChange}
                onKeyDown={events.handleInputInvalid}
                onPaste={events.handleInputInvalid}
                defaultValue={
                  state.deviceModificar ? state.deviceModificar.device.descripcion : null
                }
              />
            </Grid>
            {state.autoRefresh === 1 ? (
              <>
                <Grid item className={classes.gridTitulo}>
                  <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
                    {dictionary_devices.FRECUENCIA}
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item md={6} className={classes.gridSlider}>
                    <Slider
                      aria-labelledby="frecuencia-slider"
                      defaultValue={
                        state.deviceModificar !== undefined
                          ? calcularFrecuenciaObtener(
                              state.deviceModificar.device.tiempoRefresco as number
                            ).tiempo
                          : 30
                      }
                      valueLabelDisplay="auto"
                      onChangeCommitted={events.handleSliderFrecuencia}
                      step={1}
                      marks={[
                        { value: 1, label: 1 },
                        { value: 60, label: 60 }
                      ]}
                      min={1}
                      max={60}
                      className={classes.slider}
                      disabled={state.disabled}
                      id="tiempoRefresco"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Autocomplete
                      key={state.limpiar}
                      size="small"
                      id="tiempoFrecuencia"
                      options={[
                        { nombre: dictionary_generic.MINUTOS },
                        { nombre: dictionary_generic.HORAS },
                        { nombre: dictionary_generic.DIAS }
                      ]}
                      filterSelectedOptions
                      defaultValue={{
                        nombre:
                          state.deviceModificar !== undefined
                            ? calcularFrecuenciaObtener(state.deviceModificar.device.tiempoRefresco)
                                .frecuencia
                            : dictionary_generic.MINUTOS
                      }}
                      getOptionLabel={(option) => option.nombre}
                      disabled={state.disabled}
                      onChange={events.handleSelectFrecuencia}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={enumLabel.FRECUENCIA} />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <>
              <Grid item className={classes.gridTitulo}>
                <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
                  {dictionary_devices.INACTIVIDAD}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item md={6} className={classes.gridSlider}>
                  <Slider
                    aria-labelledby="inactividad-slider"
                    defaultValue={
                      state.deviceModificar !== undefined
                        ? calcularFrecuenciaObtener(
                            state.deviceModificar.device.tiempoInactividad as number
                          ).tiempo
                        : 24
                    }
                    valueLabelDisplay="auto"
                    onChangeCommitted={events.handleSliderInactividadFrecuencia}
                    step={1}
                    marks={[
                      { value: 1, label: 1 },
                      { value: 60, label: 60 }
                    ]}
                    min={1}
                    max={60}
                    className={classes.slider}
                    disabled={state.disabled}
                    id="tiempoInactividad"
                  />
                </Grid>
                <Grid item md={6}>
                  <Autocomplete
                    key={state.limpiar}
                    size="small"
                    id="tiempoInactividadFrecuencia"
                    options={[
                      { nombre: dictionary_generic.MINUTOS },
                      { nombre: dictionary_generic.HORAS },
                      { nombre: dictionary_generic.DIAS }
                    ]}
                    filterSelectedOptions
                    defaultValue={{
                      nombre:
                        state.deviceModificar !== undefined
                          ? calcularFrecuenciaObtener(
                              state.deviceModificar.device.tiempoInactividad
                            ).frecuencia
                          : dictionary_generic.HORAS
                    }}
                    getOptionLabel={(option) => option.nombre}
                    disabled={state.disabled}
                    onChange={events.handleSelectInactividadFrecuencia}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label={enumLabel.FRECUENCIA} />
                    )}
                  />
                </Grid>
              </Grid>
            </>
            {state.parametrosConfiguracion.length > 0 ? (
              <ParametrosConfiguracion state={state} events={events} />
            ) : (
              <></>
            )}
            <Grid item>
              <Grid container justifyContent="space-between" spacing={4} alignItems="center">
                <Grid item xs={12} sm={3} className={classes.gridSwitch}>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        color="secondary"
                        id="activo"
                        checked={state.checked}
                        disabled={state.disabled}
                        onChange={events.handleChecked}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={enumLabel.ACTIVAR_DESACTIVAR}
                    labelPlacement="top"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    disabled={state.disabled}
                    type="submit"
                  >
                    {state.deviceModificar !== undefined
                      ? dictionary_generic.MODIFICAR
                      : dictionary_generic.CREAR}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
