import React, { useRef, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { DatosUsuario } from '../components/login/interfaceDatosUsuario';
import { usuario } from '../resources/interfaces/interfaceUsuario';

/**
 * Este contexto nos permite tener acceso en toda la aplicación estado del usuario (si está logeado o no).
 *
 */

interface userContext {
  logged: boolean;
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
  datosUsuarioContext: DatosUsuario;
  setDatosUsuario: React.Dispatch<React.SetStateAction<DatosUsuario>>;
  modificandoUsuario: React.MutableRefObject<usuario>;
  openPerfil: boolean;
  setOpenPerfil: React.Dispatch<React.SetStateAction<boolean>>;
}

const Context = React.createContext<userContext>({
  logged: false,
  setLogged: useState,
  datosUsuarioContext: {} as DatosUsuario,
  setDatosUsuario: useState,
  modificandoUsuario: { current: {} as usuario },
  openPerfil: false,
  setOpenPerfil: useState
});

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export function UserContextProvider({
  children
}: InferProps<typeof UserContextProvider.propTypes>): JSX.Element {
  const [logged, setLogged] = useState<boolean>(false);
  const [openPerfil, setOpenPerfil] = useState<boolean>(false);
  const [datosUsuarioContext, setDatosUsuario] = useState<DatosUsuario>({} as DatosUsuario);
  const modificandoUsuario = useRef<usuario>({} as usuario);

  return (
    <Context.Provider
      value={{
        logged,
        setLogged,
        datosUsuarioContext,
        setDatosUsuario,
        modificandoUsuario,
        openPerfil,
        setOpenPerfil
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
