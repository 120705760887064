export enum Unidades {
  'None',
  'ºC',
  '%',
  'm3',
  'kWh',
  'ppm',
  'MWh',
  'cm',
  'º',
  'km/h',
  'mm',
  'W',
  'A',
  'bar',
  'µmol/m²/s',
  'µS/cm',
  'mV',
  'dBA',
  'KW',
  'lm/m²',
  'VAr',
  'V',
  'm',
  'dB',
  '1/m',
  'mg/L',
  'FAU',
  'pH',
  'W/m²',
  'ppb',
  'μg/m³',
  'ICA'
}
