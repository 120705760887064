/**
 * @alias Etiquetas
 * @typedef Etiquetas
 * @description Interfaz de etiquetas tal cual aparece en la base de datos de etiquetas
 * @property {number} id id de la etiqueta
 * @property {string} codigo codigo de la etiqueta
 * @property {string} nombre nombre de la etiqueta
 * @property {date} fechaCreacion fecha en la que se creo la etiqueta
 */

export interface etiqueta {
  id?: number;
  codigo: string;
  nombre: string;
  fechaCreacion?: Date | string;
}

export const objectEtiqueta = (elemento?: etiqueta): etiqueta => {
  let etiqueta: etiqueta;
  elemento === undefined
    ? (etiqueta = {
        codigo: '',
        nombre: ''
      })
    : (etiqueta = {
        id: elemento.id,
        codigo: elemento.codigo,
        nombre: elemento.nombre
      });

  return etiqueta;
};
