import React from 'react';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import ReplayIcon from '@mui/icons-material/ReplayOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { State } from '../hooks/useReducerVideo/resources/types/typeState';
import { Events } from '../hooks/useReducerVideo/resources/types/typeEvents';
import { dictionary_cctv } from '../../../resources/enums/plainText';

/**
 * Componente que muestra los controles del Playback (grabación) como pausar o reproducir
 * @param { State } state State del hook useReducerVideo
 * @param { Events } events Events del hook useReducerVideo
 * @returns { JSX.Element } Devuelve el componente
 */
export function ControlPlayback({ state, events }: { state: State; events: Events }): JSX.Element {
  return (
    <>
      {state.isPlaying && !state.isFullScreen ? (
        <>
          <Tooltip title={dictionary_cctv.REPLAY} arrow>
            <IconButton color="secondary" onClick={events.repetirVideo}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
          {state.isPause ? (
            <Tooltip title={dictionary_cctv.PLAY} arrow>
              <IconButton color="secondary" onClick={events.reproducirVideo}>
                <PlayIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={dictionary_cctv.PAUSE} arrow>
              <IconButton color="secondary" onClick={events.pausarVideo}>
                <PauseIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
