import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

const esMonths = [
  ['Enero', 'j'],
  ['Febrero', 'f'],
  ['Marzo', 'm'],
  ['Abril', 'a'],
  ['Mayo', 'm'],
  ['Junio', 'j'],
  ['Julio', 'j'],
  ['Agosto', 'a'],
  ['Septiembre', 's'],
  ['Octubre', 'o'],
  ['Noviembre', 'n'],
  ['Diciembre', 'd']
];
const esWeekDays = [
  ['Domingo', 'Dom'],
  ['Lunes', 'Lun'],
  ['Martes', 'Mar'],
  ['Miércoles', 'Mie'],
  ['Jueves', 'Jue'],
  ['Viernes', 'Vie'],
  ['Sábado', 'Sab']
];

function CustomInput({
  openCalendar,
  value,
  handleValueChange,
  label,
  error = false
}: any): JSX.Element {
  return (
    <TextField
      id="dateComponent"
      label={label}
      onClick={openCalendar}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <DateRangeIcon color="secondary" />
          </InputAdornment>
        )
      }}
      fullWidth
      variant="outlined"
      value={value}
      onFocus={openCalendar}
      onChange={handleValueChange}
      onKeyDown={(event) => event.preventDefault()}
      required
      error={error}
    />
  );
}

export function MultipleDateComponent({
  onChange,
  values,
  label
}: {
  onChange: any;
  values: Array<any>;
  label: string;
}): JSX.Element {
  return (
    <DatePicker
      months={esMonths}
      weekDays={esWeekDays}
      weekStartDayIndex={1}
      render={<CustomInput label={label} />}
      multiple
      format="DD/MM/YYYY"
      plugins={[<DatePanel key={'date-panel'} header={'Fechas'} />]}
      onChange={onChange}
      containerStyle={{
        width: '100%'
      }}
      value={values.map((fecha: string) => new Date(fecha))}
    />
  );
}

export function DateComponent({
  onChange,
  value,
  label,
  calendarPosition = undefined,
  minDate,
  maxDate,
  style
}: {
  onChange: any;
  value: string;
  label: string;
  calendarPosition?:
    | undefined
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end';
  minDate?: Date;
  maxDate?: Date;
  style?: Record<string, unknown>;
}): JSX.Element {
  return (
    <DatePicker
      months={esMonths}
      weekDays={esWeekDays}
      weekStartDayIndex={1}
      render={<CustomInput label={label} />}
      format="DD/MM/YYYY"
      onChange={onChange}
      containerStyle={{
        width: '100%',
        ...style
      }}
      value={new Date(value)}
      calendarPosition={calendarPosition}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
}

export function TimeComponent({
  onChange,
  value,
  calendarPosition = undefined
}: {
  onChange: any;
  value: string;
  calendarPosition?:
    | undefined
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end';
}): JSX.Element {
  return (
    <DatePicker
      disableDayPicker
      format="HH:mm"
      plugins={[<TimePicker position="top" key={'timePicker'} hideSeconds />]}
      onChange={onChange}
      value={new Date(value)}
      render={<CustomInput label={'Hora'} />}
    />
  );
}

export function RangeDateComponent({
  onChange,
  values,
  calendarPosition = undefined,
  label,
  style,
  error = false
}: {
  onChange: any;
  values: Array<Date>;
  label: string;
  calendarPosition?:
    | undefined
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-start'
    | 'top-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end';
  style?: Record<string, unknown>;
  error?;
}) {
  return (
    <DatePicker
      value={values}
      onChange={onChange}
      months={esMonths}
      range
      rangeHover
      calendarPosition={calendarPosition}
      render={<CustomInput label={label} error={error} />}
      format="DD/MM/YYYY"
      containerStyle={{
        width: '100%',
        ...style
      }}
    />
  );
}
