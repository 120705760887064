import React from 'react';
import { CanalesCreate } from './CanalesCreate';
import { CanalesUpdate } from './CanalesUpdate';
import { canalesRespuesta } from './resources/interfaces/interfaceCanalesRespuesta';
import { useReducerCanales } from './hooks/useReducerCanales';

export function CanalesManager(props: { canalSeleccionado?: canalesRespuesta | any }): JSX.Element {
  const {
    state,
    handleAutocompleteChange,
    handleInputChange,
    handleInputInvalid,
    onchangeCheked,
    handleSubmitCrear,
    handleSubmitModificar,
    confirmarAlertAviso,
    setOpenAviso
  } = useReducerCanales(props.canalSeleccionado);
  const eventosReducer = {
    handleAutocompleteChange,
    handleInputChange,
    handleInputInvalid,
    onchangeCheked,
    handleSubmitCrear,
    handleSubmitModificar,
    confirmarAlertAviso,
    setOpenAviso
  };
  const { mostrarCrear, mostrarModificar } = state;

  return (
    <>
      {mostrarCrear ? <CanalesCreate state={state} events={eventosReducer} /> : <></>}
      {mostrarModificar ? <CanalesUpdate state={state} events={eventosReducer} /> : <></>}
    </>
  );
}
