import { useContext } from 'react';
import ErrorContext from '../context/ErrorContext';
import ControlContext from '../../../context/control/ControlContext';
import UserContext from '../../../context/UserContext';
import { Modulos } from '../../../resources/enums/enumModulo';
import { objectError } from '../resources/interfaces/interfaceFrontalError';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { useCud } from '../../../hooks/useCud/useCud';
import { HandleError } from '../resources/types/typeHandleError';

let timer: NodeJS.Timeout;
/**
 * Hook para manejar y controlar el error producido dentro de un try/catch
 */
export function useHasError(): {
  handleError: HandleError;
} {
  const { crearRegistro } = useCud();
  const { hasError, setHasError } = useContext(ErrorContext);
  const { datosUsuarioContext } = useContext(UserContext);
  const { moduloSeleccionado, control } = useContext(ControlContext);

  /**
   * Función que controla el error producido. Primero averiguando si es por un problema de conexión con el servidor.
   * En caso de que no lo sea, se enviará el error a un servicio de back para registrarlo y avisar por correo
   * @param { string } localizacion archivo y función del error producido
   * @param { objeto } error error producido
   * @param { objeto }info información del error producido
   */
  async function handleError(
    localizacion: string,
    error: {
      message: string;
      stack: string;
      isAxiosError?: any;
      response?: { status: number; data: { message: string } };
    },
    info?: { componentStack: string }
  ): Promise<void> {
    const infoSmartTelia = {
      nombreCliente: datosUsuarioContext?.cliente.nombre,
      nombreUsuario: datosUsuarioContext?.usuario.nombreUsuario,
      modulo: Modulos[moduloSeleccionado],
      componente: control.key as string
    };
    if (
      error.isAxiosError &&
      (error.message.includes('Network') || error.message.includes('timeout'))
    ) {
      setHasError({ isError: true, countErrors: hasError.countErrors, isNetworkError: true });
    } else {
      // Establecemos un contador para saber si el error provoca un bucle
      const contador: number = hasError.countErrors + 1;
      setHasError({ isError: true, countErrors: contador });
      // si el contador ha llegado a 5 en menos de 45 segundos, es que estamos en un bucle
      if (contador === 1) {
        await sendError(localizacion, infoSmartTelia, error, info);
        timer = setTimeout(() => {
          setHasError({ isError: false, countErrors: 0 });
        }, 45000);
      } else if (contador === 5) {
        window.clearTimeout(timer);
      }
    }
  }

  /**
   * Función que se encarga de enviar al servicio de back el error producido
   * @param { string } localizacion archivo y función del error producido
   * @param { objeto }infoSmartTelia información del usuario, cliente, módulo y componente seleccionado
   * @param { objeto }error error producido
   * @param { objeto }info información del error producido
   */
  async function sendError(
    localizacion: string,
    infoSmartTelia: {
      nombreCliente: string | undefined;
      nombreUsuario: string | undefined;
      modulo: string;
      componente: string;
    },
    error: {
      message: string;
      stack: string;
      isAxiosError?: boolean;
      response?: { status: number; data: { message: string } };
    },
    info?: { componentStack: string }
  ): Promise<void> {
    const date: Date = new Date();
    const fecha = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const registro = objectError({
      fecha: fecha,
      localizacion: localizacion,
      usuario: infoSmartTelia.nombreUsuario as string,
      cliente: infoSmartTelia.nombreCliente as string,
      modulo: infoSmartTelia.modulo,
      componente: infoSmartTelia.componente,
      mensaje: error.message,
      stack: '<pre>' + (info ? info.componentStack : error.stack) + '</pre>'
    });

    await crearRegistro(rutasServicios.REGISTRAR_ERROR, registro);
  }

  return {
    handleError
  };
}
