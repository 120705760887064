import React, { useRef } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Typography from '@mui/material/Typography';
import { seleccionarIcono } from './functions/functions';
import { formatToDayStart } from '../../functions/functions';
import { alerta } from './resources/interfaces/interfaceAlerta';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import DialogVideo from '../videovigilancia/DialogVideo';
import { DispositivoVideo } from '../videovigilancia/resources/interfaces/interfaceDispositivoVideo';
import ControlContext from '../../context/control/ControlContext';
import {
  alertToDeviceVideo,
  isCctvEvent
} from '../videovigilancia/hooks/useReducerVideo/resources/functions/functions';
import { Box } from '@mui/system';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import SnackBarContext from '../../context/SnackBarContext';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { tipoAlerta } from './resources/enums/enumTipoAlerta';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  oppositeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  }
}));

export default function TimeLine(props: {
  log: alerta[];
  isNotificaciones?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { moduloSeleccionado, comprobarAcceso, control } = React.useContext(ControlContext);
  const alertRef = useRef();
  const { setSnackBar } = React.useContext(SnackBarContext);
  const [dispositivoVideo, setDispositivoVideo] = React.useState<DispositivoVideo>();
  const [openVideo, setOpenVideo] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [mensajeAlerta, setMensajeAlerta] = React.useState('');

  const handleClickOpen = ({ target }: any) => {
    const { id } = target;
    alertRef.current = id;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { controllerRequest, propiedadesSnackBar, completadoCorrectamente } = useControlador();

  const handleOpenVideo = (element: alerta) => {
    setDispositivoVideo(alertToDeviceVideo(element));
    setOpenVideo(true);
  };

  const resolverAlerta = async () => {
    await controllerRequest(
      {
        type: action.RESOLVER_ALERTA,
        payload: {
          servicio: rutasServicios.ALERTAS,
          idAlerta: parseInt(alertRef.current as unknown as string),
          mensajeResolucion: mensajeAlerta
        }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );
    if (completadoCorrectamente.current) {
      handleClose();
      const alertaResuelta = props.log.find(
        (alerta) => alerta.id === parseInt(alertRef.current as unknown as string)
      );

      if (alertaResuelta) {
        alertaResuelta.resuelta = 1;
        alertaResuelta.mensajeResolucion = mensajeAlerta;
        alertaResuelta.fechaResolucion = new Date().toString();
      }
    }

    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  };

  return (
    <div tabIndex={0}>
      <Timeline position="alternate">
        {props.log.map((element, index) => {
          const nombreDispositivo = props.isNotificaciones ? element.nombre + ': ' : '';
          return (
            <TimelineItem key={element.id}>
              <TimelineSeparator>
                <span style={{ width: '30px', height: '30px', margin: '10px 0px 10px 0px' }}>
                  {seleccionarIcono(element.idTipoAlerta, true).icono}
                </span>

                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography color="textSecondary">
                  {formatToDayStart(
                    element.fechaRegistro > element.fechaUltimoRegistro
                      ? element.fechaRegistro
                      : element.fechaUltimoRegistro
                  )}
                </Typography>
                <Typography style={{ color: 'Gray', fontSize: '12px' }}>
                  {nombreDispositivo + element.informacion}
                </Typography>
                {element.idTipoAlerta === tipoAlerta.EVENT ? (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => handleOpenVideo(element)}
                    disabled={!isCctvEvent(moduloSeleccionado, alertToDeviceVideo(element))}
                  >
                    Ver clip de alerta
                  </Button>
                ) : (
                  <></>
                )}
                <Box>
                  {comprobarAcceso(control.key as enumComponentes) &&
                  element.idTipoAlerta != tipoAlerta.EVENT ? (
                    !element.resuelta ? (
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={handleClickOpen}
                        id={element.id.toString()}
                        data-target={element}
                      >
                        Resolver alerta
                      </Button>
                    ) : (
                      <>
                        <Typography variant="body2" color="green">
                          RESUELTA
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {formatToDayStart(element.fechaResolucion as string)}
                        </Typography>
                        <Typography variant="body2">{element.mensajeResolucion}</Typography>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
      {openVideo ? (
        <DialogVideo datosVideo={dispositivoVideo} open={openVideo} setOpen={setOpenVideo} />
      ) : (
        <></>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Mensaje de resolución</DialogTitle>
        <DialogContent>
          <DialogContentText>Escribe un mensaje de resolución para esta alerta.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Mensaje de resolución"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setMensajeAlerta(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={resolverAlerta}>Resolver</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
