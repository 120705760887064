import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { Modulos } from '../../../resources/enums/enumModulo';
import { dictionary_entornos } from '../../../resources/enums/plainText';
import { Rango } from '../../graficas/resources/enums/enumRango';

let path = '';
let respuesta: any;

/**
 * Función para obtener las alertas que pertenecen a un dispositivo concreto
 * @param { string } servicio nombre del servicio
 * @param { number } idDispositivo id del dispositivo cuyas alertas queremos saber
 * @param { Rango } range indica el rango de fechas en el que queremos el resultado
 * @param { string | undefined } fechaDesde fecha desde la cuál queremos filtrar
 * @param { string | undefined } fechaHasta fecha hasta a cuál queremos filtrar
 * @return { Promise<any> | exception} Si la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerAlertasDispositivo(
  servicio: string,
  idDispositivo: number,
  range: Rango,
  fechaDesde?: string | undefined,
  fechaHasta?: string | undefined,
  componente?: string | undefined
): Promise<any> {
  path = servicio + '/dispositivo/';
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({
        idDispositivo: idDispositivo,
        range: range,
        fechaAlertaDesde: fechaDesde,
        fechaAlertaHasta: fechaHasta,
        componente: componente
      }),
      {
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función para obtener los datos de las alertas que pertenecen a un modulo concreto
 * @param { string } servicio nombre del servicio
 * @param { number } idModulo id del modulo cuyas alertas queremos saber
 *  @param { Rango } range indica el rango de fechas en el que queremos el resultado
 * @param { string | undefined } fechaDesde fecha desde la cuál queremos filtrar
 * @param { string | undefined } fechaHasta fecha hasta a cuál queremos filtrar
 * @return { Promise<any> | exception} Si  la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerAlertasModulo(
  servicio: string,
  idModulo: number,
  range: Rango,
  versionTable: string | null | undefined,
  fechaDesde?: string | undefined,
  fechaHasta?: string | undefined
): Promise<any> {
  path = servicio + '/modulo/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({
          idModulo: idModulo,
          range: range,
          fechaAlertaDesde: fechaDesde,
          fechaAlertaHasta: fechaHasta
        }),
        {
          headers: getHeaders(dictionary_entornos.BACK, { versiontables: versionTable as string })
        }
      );

    return respuesta;
  } catch (exception) {
    return exception;
  }
}

/**
 * Función para obtener las alertas filtradas por tipo de error
 * @param { string } servicio nombre del servicio
 * @param { number } idTipoAlerta id del tipo de alerta por el que queremos filtrar
 * @return { Promise<any> | exception} Si  la petición se hace bien devuelve la respuesta del servicio en formato JSON, si no, una excepción
 */
export async function obtenerAlertasTipoAlerta(
  servicio: string,
  idTipoAlerta: number
): Promise<any> {
  path = servicio + '/modulo/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({ idTipoAlerta: idTipoAlerta }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
/**
 * Función que obtiene las alertas de tipo error en un rango de fechas determinado
 * @param { string } servicio nombre del servicio
 * @param { number } idDispositivo id del dispositivo cuyas alertas queremos saber
 * @param { Rango } range indica el rango de fechas en el que queremos el resultado
 * @param { string | undefined } fechaDesde fecha desde la cuál queremos filtrar
 * @param { string | undefined } fechaHasta fecha hasta a cuál queremos filtrar
 * @return { Promise<any> | exception} Lista de alertas de tipo error
 */
export async function obtenerAlertasTipoError(
  servicio: string,
  range: Rango,
  fechaDesde?: string | undefined,
  fechaHasta?: string | undefined
): Promise<any> {
  path = servicio + '/tipoError/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({
          range: range,
          fechaAlertaDesde: fechaDesde,
          fechaAlertaHasta: fechaHasta
        }),
        {
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );
    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene una lista de alertas sin resolver y sin filtrar por modulos
 * @param { string } servicio nombre del servicio
 * @return { Promise<any> | exception} Lista de alertas de tipo error o warning sin filtrar
 */
export async function obtenerAlertasPendientesResolver(servicio: string): Promise<any> {
  path = servicio + '/sinResolver/';
  //path = servicio + '/todos/pendientes/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
export async function obtenerTodasAlertasPendientesResolver(servicio: string): Promise<any> {
  path = servicio + '/todos/pendientes/';
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene una lista de alertas sin resolver y filtrado por módulos
 * @param { string } servicio nombre del servicio
 * @return { Promise<any> | exception} Lista de alertas de tipo error o warning filtrado por módulos
 */
export async function obtenerAlertasPendientesResolverPorModulo(
  servicio: string,
  modulo: Modulos,
  signal: AbortSignal
): Promise<any> {
  path = servicio + `/sinResolver/${modulo}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({
          idModulo: modulo
        }),
        {
          signal: signal,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que obtiene una lista de alertas sin resolver y filtrado por módulos
 * @param { string } servicio nombre del servicio
 * @param { number } idAlerta idAlerta a resolver
 * @param { string } mensajeResolucion mensaje de resolución
 * @param { AbortSignal } signal signal de axios para poder cancelar la petición
 * @return { Promise<any> | exception} Si se ha resuelto o no
 */
export async function resolverAlertaService(
  servicio: string,
  idAlerta: number,
  mensajeResolucion: string,
  signal: AbortSignal
): Promise<any> {
  path = servicio + `/resolver`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.post(
        process.env.REACT_APP_URL_LOCAL + path,
        encriptarJSON({
          id: idAlerta,
          mensajeResolucion: mensajeResolucion
        }),
        {
          signal: signal,
          headers: getHeaders(dictionary_entornos.BACK)
        }
      );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
