import React from 'react';
import { ConsumptionReport } from '../../Reports/Consumption';
import { ConsumptionReportFilter } from '../../Reports/Consumption/Filter';
import { ResidualsParameters } from '../WaterManagement/ResidualsParameters';
import { ResidualParametersFilter } from '../../Reports/WaterManagement/ResidualsParameters/Filter';
import { WaterQualityReport } from '../../Reports/WaterManagement/WaterQuality';
import { WaterQualityReportFilter } from '../../Reports/WaterManagement/WaterQuality/Filter';
import { UnitsTypes } from 'components/UnitTypes';
import { Unidades } from 'resources/enums/enumUnidades';

/**
 * Código de los informes para identificar correctamente los filtros que necesite
 */
enum InformesDisponibles {
  INFORME_CONSUMO_DE_AGUA = '301',
  INFORME_DE_INDICADORES_CALIDAD_AGUA = '302',
  INFORME_PARAMETROS_RESIDUALES = '303',
  ENERGY_CONSUMPTION_REPORT = '601',
  ILLUMINATION_CONSUMPTION_REPORT = '201'
}

export const informesDisponibles: any = {
  [InformesDisponibles.INFORME_DE_INDICADORES_CALIDAD_AGUA]: {
    filtro: <WaterQualityReportFilter />,
    informe: <WaterQualityReport />
  },
  [InformesDisponibles.INFORME_CONSUMO_DE_AGUA]: {
    filtro: <ConsumptionReportFilter />,
    informe: <ConsumptionReport />,
    headerUnit: Unidades['3']
  },
  [InformesDisponibles.INFORME_PARAMETROS_RESIDUALES]: {
    filtro: <ResidualParametersFilter />,
    informe: <ResidualsParameters />
  },
  [InformesDisponibles.ENERGY_CONSUMPTION_REPORT]: {
    filtro: <ConsumptionReportFilter />,
    informe: <ConsumptionReport />,
    headerUnit: Unidades['4']
  },
  [InformesDisponibles.ILLUMINATION_CONSUMPTION_REPORT]: {
    filtro: <ConsumptionReportFilter />,
    informe: <ConsumptionReport />,
    headerUnit: Unidades['4']
  }
};
