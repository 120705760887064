import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, TextField } from '@mui/material';
import {
  dictionary_dispositivos,
  dictionary_etiquetas,
  dictionary_informes
} from '../../../../../resources/enums/plainText';
import { SelectAllAutocomplete } from '../../../../common/selectAllAutocomplete/SelectAllAutocomplete';
import { etiqueta } from '../../../../etiquetas/resources/interfaces/interfaceEtiquetas';
import { useWaterQualityReportFilter } from './index.hook';

export function ResidualParametersFilter(): JSX.Element {
  const {
    tagsByModule = [],
    selectedTag,
    handleAutoCompleteTag,
    devicesFilteredByTag = [],
    handleAutoCompleteDevices,
    resetReport,
    selectedDevices = [],
    loadingTags,
    getReportData
  } = useWaterQualityReportFilter();

  return (
    <List>
      <ListItem key={dictionary_etiquetas.ETIQUETAS}>
        <Autocomplete
          key={selectedDevices.length}
          loading={loadingTags}
          disablePortal
          id="etiquetas"
          options={tagsByModule}
          value={selectedTag}
          getOptionLabel={(option: etiqueta) => option.nombre}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label={dictionary_etiquetas.ETIQUETAS} variant="outlined" />
          )}
          onChange={handleAutoCompleteTag}
        />
      </ListItem>

      <ListItem key={'dispositivos'}>
        <SelectAllAutocomplete
          id={'dispositivos'}
          items={devicesFilteredByTag}
          label={dictionary_dispositivos.DISPOSITIVOS}
          selectAllLabel={dictionary_informes.SELECCIONAR_TODOS}
          onChange={handleAutoCompleteDevices}
          limitTags={1}
          fullWidth
          disabled={selectedTag ? false : true}
          getOptionLabel={(option: { nombre: string; id: number }) => option.nombre}
          allDefaultValue={true}
        />
      </ListItem>

      <ListItem>
        <Button
          variant="contained"
          onClick={resetReport}
          style={{ width: '100%', marginRight: '3%' }}
          color="secondary"
        >
          {dictionary_informes.RESET_REPORT}
        </Button>
        <Button
          variant="contained"
          onClick={getReportData}
          style={{ width: '100%' }}
          color="secondary"
          disabled={!selectedDevices.length}
        >
          {dictionary_informes.VER_INFORME}
        </Button>
      </ListItem>
    </List>
  );
}
