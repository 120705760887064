import React from 'react';
import { actions } from './resources/enums/enumActions';
import { Action } from './resources/types/typeAction';
import { Events } from './resources/types/typeEvents';
import { State } from './resources/types/typeState';
import { useLoginUsuario } from '../../../../hooks/useLogin/useLoginUsuario';
import { useLocation } from 'wouter';
import SnackBarContext from '../../../../context/SnackBarContext';
import SmartTeliaBackdropContext from '../../../../context/SmartTeliaBackdropContext';
import { dictionary_login, dictionary_password } from '../../../../resources/enums/plainText';
import { enumSeverity } from '../../../common/snackbar/resources/enums/enumSeverity';
import { limpiarCampos } from '../../../../functions/functions';
import { formulario } from './resources/enums/enumFormulario';

/**
 * Función Reducer que modifica el estado actual de los distintos componentes y variables
 * @param {State} state nuevo estado para modificar
 * @param {Action} action contiene el tipo y el payload con el nuevo valor del estado
 * @returns {State} devuelve el nuevo estado
 */
const REDUCER = (state: State, action: Action): State => {
  switch (action.type) {
    /* LOGIN */
    case actions.SET_NAME:
      return { ...state, name: action.payload };
    case actions.SET_PASSWORD:
      return { ...state, pass: action.payload };
    /* RESTART PASSWORD */
    case actions.SET_EMAIL:
      return { ...state, email: action.payload };
    case actions.SET_NOMBRE_USUARIO:
      return { ...state, nombreUsuario: action.payload };
    case actions.SET_ID_USUARIO:
      return { ...state, idUsuario: action.payload };
    case actions.SET_CODE_SECRET:
      return { ...state, codeSecret: action.payload };
    case actions.SET_IS_CODE_CORRECT:
      return { ...state, isCodeCorrect: action.payload };
    case actions.SET_NUEVA_PASSWORD:
      return { ...state, nuevaPassword: action.payload };
    case actions.SET_REPETIR_PASSWORD:
      return { ...state, repetirPassword: action.payload };
    default:
      return state;
  }
};

/**
 * Custom Hook para el control del componente para Restablecer la contraseña del ususario
 *
 * @returns { {state: State, events: Events} } devuelve dos parametros, state para los
 * estados (variables) y events para los eventos (funciones)
 */
const useReducerLogin = (): {
  state: State;
  events: Events;
} => {
  const INITIAL_STATE: State = {
    /* LOGIN */
    name: '',
    pass: '',
    /* RESTART PASSWORD */
    nombreUsuario: undefined,
    email: undefined,
    idUsuario: undefined,
    codeSecret: undefined,
    isCodeCorrect: false,
    nuevaPassword: undefined,
    repetirPassword: undefined
  };
  const [state, dispatch] = React.useReducer(REDUCER, INITIAL_STATE);
  // States
  const {
    name,
    pass,
    email,
    nombreUsuario,
    idUsuario,
    codeSecret,
    isCodeCorrect,
    nuevaPassword,
    repetirPassword
  } = state;
  // customs hooks
  const {
    login,
    pedirCodigoRestablecerPass,
    verificarCodigoRestablecerPass,
    restablecerPassword,
    propiedadesSnackBar
  } = useLoginUsuario();
  const [, location] = useLocation();
  // useContext
  const { setSnackBar } = React.useContext(SnackBarContext);
  const { setOpenSmartTeliaBackdrop } = React.useContext(SmartTeliaBackdropContext);
  /* DISPATCHS (SET STATES) */
  /* LOGIN */
  /**
   * Función que establece el name
   * @param { string } name
   */
  const setName = (name: string): void => dispatch({ type: actions.SET_NAME, payload: name });
  /**
   * Función que establece el pass
   * @param { string } pass
   */
  const setPassword = (pass: string): void =>
    dispatch({ type: actions.SET_PASSWORD, payload: pass });
  /* RESTART PASSWORD */
  /**
   * Función que establece el email
   * @param { string } email
   */
  const setEmail = (email: string): void => dispatch({ type: actions.SET_EMAIL, payload: email });
  /**
   * Función que establece el nombre del usuario
   * @param { string } nombreUsuario
   */
  const setNombreUsuario = (nombreUsuario: string): void =>
    dispatch({ type: actions.SET_NOMBRE_USUARIO, payload: nombreUsuario });
  /**
   * Función que establece el idUsuario
   * @param { number } idUsuario
   */
  const setIdUsuario = (idUsuario: number): void =>
    dispatch({ type: actions.SET_ID_USUARIO, payload: idUsuario });
  /**
   * Función que establece el codeSecret
   * @param { string } codeSecret
   */
  const setCodeSecret = (codeSecret: string): void =>
    dispatch({ type: actions.SET_CODE_SECRET, payload: codeSecret });
  /**
   * Función que establece el isCodeCorrect
   * @param { boolean } isCodeCorrect
   */
  const setIsCodeCorrect = (isCodeCorrect: boolean): void =>
    dispatch({ type: actions.SET_IS_CODE_CORRECT, payload: isCodeCorrect });
  /**
   * Función que establece el nuevaPassword
   * @param { string } nuevaPassword
   */
  const setNuevaPassword = (nuevaPassword: string): void =>
    dispatch({ type: actions.SET_NUEVA_PASSWORD, payload: nuevaPassword });
  /**
   * Función que establece el repetirPassword
   * @param { string } repetirPassword
   */
  const setRepetirPassword = (repetirPassword: string): void =>
    dispatch({ type: actions.SET_REPETIR_PASSWORD, payload: repetirPassword });

  /* VARIABLES, HANDLES Y FUNCIONES */
  /* LOGIN */
  /**
   * Handle que se ejecuta al escribir en el input de usuario
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  /**
   * Handle que se ejecuta al escribir en el input de password
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  /* RESTART PASSWORD */
  /**
   * Handle que se ejecuta al escribir en el input de email
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };
  /**
   * Handle que se ejecuta al escribir en el input del usuario
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputNombreUsuarioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNombreUsuario(event.target.value);
  };
  /**
   * Handle que se ejecuta al escribir en el input de codeSecret
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputCodeSecretChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCodeSecret(event.target.value);
  };
  /**
   * Handle que se ejecuta al escribir en el input de nuevaPassword
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputNuevaPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNuevaPassword(event.target.value);
  };
  /**
   * Handle que se ejecuta al escribir en el input de repetirPassword
   * @param {6React.ChangeEvent<HTMLInputElement>} event evento que se produce que contiene el valor
   */
  const handleInputRepetirPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRepetirPassword(event.target.value);
  };

  /**
   * Función que indica si la password repetida es correcta
   * @returns {boolean} true si es correcta o false si no lo es
   */
  const isCorrectPassword = (): boolean => {
    if (!nuevaPassword || !repetirPassword) return false;
    return nuevaPassword === repetirPassword;
  };

  /**
   * Función que indica si un error en el input, para ello no tendrá que coincidir las contraseñas
   * además de que deben tener algo escrito ambas.
   * @returns {boolean} true si es error o false si no lo es
   */
  const isErrorPassword = (): boolean => {
    return !isCorrectPassword() && !!nuevaPassword && !!repetirPassword;
  };

  /**
   * Función que indica un texto de ayuda en el input en caso de error, para ello no tendrá que coincidir las contraseñas
   * además de que deben tener algo escrito ambas.
   * @returns {string} menseja vacío si es correcto, o un mensaje en caso de que no
   */
  const helperText = (): string => {
    return isCorrectPassword() || !nuevaPassword || !repetirPassword
      ? ''
      : dictionary_password.ERROR_CONTRASENA;
  };

  /**
   * Función que bloquea el botón 'Enviar' (submit) en caso de que el codigo de verificacion
   * y las contraseñas den error
   * @returns {boolean} true si está todo bien o false si no lo es
   */
  const isDisabledButton = (): boolean => {
    return isCodeCorrect && !isCorrectPassword();
  };

  /**
   * Función que cambia la url a la pantalla principal del Login
   */
  const irToMain = (): void => {
    location('/main');
  };

  /**
   * Función que cambia la url a la pantalla principal del Login
   */
  const volverToLogin = (): void => {
    location('/');
  };

  /**
   * Función Submit que se ejecuta al clicar en el botón del formulario
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();
    const idForm = (event.target as HTMLElement).id;
    setOpenSmartTeliaBackdrop(true);
    // Dependiendo del formulario (login o restart pass), actuaremos de una forma u otra
    switch (idForm) {
      // caso en el que el formulario es el de login
      case formulario.LOGIN: {
        const isLogin = await login(name, pass);
        // si va todo bien, vamos a la pagina principal del main
        if (isLogin) irToMain();
        // si no mostramos un snackbar
        else {
          setSnackBar({
            open: true,
            text: propiedadesSnackBar.current.texto,
            severity: propiedadesSnackBar.current.severity
          });
        }
        break;
      }
      // caso en el que el formulario es el de restablecer el password
      case formulario.RESTART_PASS: {
        // 1º Caso: hay un email valido y aun no hay un idUsuario -> servicio pedir codigo al correo
        if (idUsuario === undefined && email && nombreUsuario) {
          const idUsuario: any = await pedirCodigoRestablecerPass(nombreUsuario, email);
          //Si el idUsuario no es 0, ha obtenido correctamente el idUsuario
          if (idUsuario !== 0) {
            setIdUsuario(idUsuario);
            propiedadesSnackBar.current.texto = dictionary_login.TEXTO_ENVIADO_CORREO + ' ' + email;
            propiedadesSnackBar.current.severity = enumSeverity.INFO;
          } else {
            propiedadesSnackBar.current.texto = dictionary_login.ERROR_EMAIL_USUARIO;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          }
        }
        // 2º Caso: hay un idUsuario, un codigo y aun no se ha verificado -> servicio verificar codigo
        else if (idUsuario !== undefined && codeSecret && !isCodeCorrect) {
          // en caso de que el email no es valido, el idUsuario será 0, y por lo tanto no haremos peticion
          // e indicamos que el codigo es incorrecto y así no se podrá saber si el email pertenece a alguien
          if (idUsuario === 0) {
            propiedadesSnackBar.current.texto = dictionary_login.ERROR_CODIGO_VERIFICACION;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          }
          // en caso contrario, se realiza la petición
          else {
            setIsCodeCorrect(await verificarCodigoRestablecerPass(idUsuario, codeSecret));
          }
        }
        // 3º Caso: hay un idUsuario, un codigo correcto y una nueva contraseña correcta -> servicio restablecer una nueva contraseña
        else if (idUsuario && isCodeCorrect && isCorrectPassword() && nuevaPassword) {
          const isRestablecido = await restablecerPassword(idUsuario, nuevaPassword);
          // si va todo bien, volvemos a la pagina principal del login
          if (isRestablecido) volverToLogin();
        }
        // en cualquier caso mostraremos un snackbar
        setSnackBar({
          open: true,
          text: propiedadesSnackBar.current.texto,
          severity: propiedadesSnackBar.current.severity
        });
        limpiarCampos(idForm);
        break;
      }
    }
    setOpenSmartTeliaBackdrop(false);
  }

  // Events
  const events = {
    handleSubmit,
    handleInputUserChange,
    handleInputPasswordChange,
    handleInputEmailChange,
    handleInputNombreUsuarioChange,
    handleInputCodeSecretChange,
    handleInputNuevaPasswordChange,
    handleInputRepetirPasswordChange,
    isErrorPassword,
    helperText,
    isDisabledButton,
    volverToLogin
  };

  return {
    state,
    events
  };
};
export default useReducerLogin;
