/**
 * @alias Device
 * @typedef Device
 * @property { number } id identificador del device
 * @property { string } nombre nombre del device
 * @property { string } descripcion descripcion del device
 * @property { bigint } tiempoRefresco cantidad de tiempo que pasa hasta que se actualiza la información
 * @property { bigint } tiempoInactividad umbral de tiempo necesario para establecer una alerta de inactividad
 * @property { number | boolean } activo indica si el deice stá activo o no
 * @property { number }  idTipoDevice id del tipo device que está asociado al device
 * @property { string | undefined }  nombreTipoDevice nombre del tipo de device del device
 * @property { number | undefined  }  idPlugin id del plugin del device
 * @property { string | undefined  }  nombrePlugin nombre del plugin del device
 * @property { string | undefined  }  logotipoPlugin logotipo del plugin del device
 * @property { string | undefined  }  descripcionPlugin descripcion del plugin del device
 * @property { string | undefined  }  versionPlugin version del plugin del device
 * @property { number | undefined  }  autoRefresh indica si el device puede actualizarse automaticamente
 */

export interface device {
  id?: number;
  nombre: string;
  descripcion: string;
  tiempoRefresco: number;
  tiempoInactividad: number;
  activo: number | boolean;
  idTipoDevice: number;
  nombreTipoDevice?: string;
  idPlugin?: number;
  nombrePlugin?: string;
  logotipoPlugin?: string;
  descripcionPlugin?: string;
  versionPlugin?: string;
  autoRefresh?: number;
}

export const objectDevice = (elemento?: device): device => {
  let device: device;
  elemento === undefined
    ? (device = {
        nombre: '',
        descripcion: '',
        tiempoRefresco: 1800000,
        tiempoInactividad: 86400000,
        activo: 1,
        idTipoDevice: 0
      })
    : (device = {
        id: elemento.id,
        nombre: elemento.nombre,
        descripcion: elemento.descripcion,
        tiempoRefresco: elemento.tiempoRefresco,
        tiempoInactividad: elemento.tiempoInactividad,
        activo: elemento.activo,
        idTipoDevice: elemento.idTipoDevice
      });

  return device;
};
