import { actionsAcciones } from '../../../reglas/resources/enums/enumActionsAcciones';
import { actionsReducerAcciones } from '../interfaces/actionsReducerAcciones';
import { StateAcciones } from '../interfaces/interfaceStateAcciones';

export const reducerAcciones = (state: StateAcciones, action: actionsReducerAcciones) => {
  switch (action.type) {
    case actionsAcciones.SET_DIALOGO_ACCIONES:
      return { ...state, dialogoAcciones: action.payload };
    case actionsAcciones.SET_DISPOSITIVOS:
      return {
        ...state,
        dialogoAcciones: { ...state.dialogoAcciones, dispositivos: action.payload }
      };
    case actionsAcciones.SET_TIPOS_DISPOSITIVO:
      return {
        ...state,
        dialogoAcciones: {
          ...state.dialogoAcciones,
          tiposDispositivo: action.payload,
          tiposActuaciones: []
        }
      };
    case actionsAcciones.SET_MOSTRAR_CREAR:
      return { ...state, mostrarCrear: true };
    case actionsAcciones.SET_MOSTRAR_MODIFICAR:
      return { ...state, mostrarModificar: true };
    case actionsAcciones.SET_LISTA_ACCIONES:
      return { ...state, listaAcciones: action.payload };
    case actionsAcciones.SET_PANEL_ACCION_ACTIVO:
      return { ...state, panelAccionActivo: action.payload };
    case actionsAcciones.SET_ACTUACION_COMPLETA:
      return { ...state, actuacionCompleta: action.payload };
    case actionsAcciones.SET_ACTUACION_GRUPAL_COMPLETA:
      return { ...state, actuacionGrupalCompleta: action.payload };
    case actionsAcciones.SET_CAMBIO_ESTADO_REGLA:
      return { ...state, cambioEstadoReglaCompleta: action.payload };
    case actionsAcciones.SET_EMAIL:
      return { ...state, emailCompleto: action.payload };
    case actionsAcciones.TOGGLE_ESTADO_REGLA:
      return {
        ...state,
        cambioEstadoReglaCompleta: {
          ...state.cambioEstadoReglaCompleta,
          regla: {
            ...state.cambioEstadoReglaCompleta.regla,
            activo: !state.cambioEstadoReglaCompleta.regla.activo
          }
        }
      };
    case actionsAcciones.SET_WHATSAPP:
      return { ...state, whatsappCompleto: action.payload };
  }
};
