import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gridTarjeta: {
    zIndex: 2,
    backgroundColor: '#ffffff',
    height: '81.5%',
    right: '0px',
    width: '460px',
    position: 'absolute',
    marginTop: '86px'
  },

  botonTarjetaAbierta: {
    position: 'absolute',
    marginLeft: '-25px',
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    opacity: '0 linear',
    transition: `${theme.transitions.create(['opacity', 'visibility'], {
      duration: `${theme.transitions.duration.standard}`,
      delay: '10'
    })}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2)
    }
  },

  botonTarjetaCerrada: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
    right: '0px',
    top: '163px',
    position: 'fixed',
    zIndex: 10,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.2)
    }
  }
}));
export default useStyles;
