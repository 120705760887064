import React from 'react';

import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { MapIcons } from '../gis/components/iconos/Iconos';
import { ContenedorActivado, ContenedorDesactivado } from '../table/contenedorElementos';
import Table from '../../common/table/Table';

export function TablaDispositivosComun(props: {
  title: string | React.ReactNode;
  columns: any;
  options: any;
  dispositivos: dispositivo[];
  isHome?: boolean;
}): JSX.Element {
  const columnsComun = [
    { name: 'id', options: { display: 'excluded' } },
    // eslint-disable-next-line react/display-name
    {
      name: 'icono',
      label: 'Icono',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string, index: number) => {
          return <MapIcons icono={value} index={index} />;
        }
      }
    },
    { name: 'nombre', label: 'Identificador' },
    { name: 'direccion', label: 'Dirección' },
    { name: 'longitud', options: { display: 'excluded' } },
    { name: 'latitud', options: { display: 'excluded' } },
    { name: 'fechaCreacion', label: 'Fecha Creación' },
    {
      name: 'activo',
      label: 'Activo',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (value: number): JSX.Element => {
          if (value === 1) return <ContenedorActivado />;
          return <ContenedorDesactivado />;
        }
      }
    },
    { name: 'idModulo', options: { display: 'excluded' } },
    { name: 'idTipoDispositivo', options: { display: 'excluded' } }
  ];

  const optionsComun = {
    rowsPerPageOptions: [],
    rowsPerPage: 10,
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple'
  };

  return (
    <Table
      title={props.title}
      columns={columnsComun.concat(props.columns)}
      data={props.dispositivos}
      options={Object.assign(props.options, optionsComun)}
      isHome={props.isHome}
    ></Table>
  );
}
