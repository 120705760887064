import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import { DocumentCard } from '../documentCard/DocumentCard';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useDocumentsContainer } from './useDocumentsContainer';

export function DocumentsContainer({
  idDispositivo = null,
  documents,
  setDroppedFiles,
  idModulo = null,
  exceededCapacity
}: {
  idDispositivo: number | null;
  documents: Array<any>;
  setDroppedFiles: (documents: File[]) => void;
  idModulo: number | null;
  exceededCapacity: boolean;
}): JSX.Element {
  const {
    handleFileSelected,
    drop,
    isOver,
    deleteFile,
    isLoading,
    tieneAcceso,
    canDrop,
    setIsLoading
  } = useDocumentsContainer(idDispositivo, setDroppedFiles, documents, idModulo, exceededCapacity);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={idDispositivo ? 6 : 12}
      ref={drop}
      sx={{
        border: isOver && canDrop ? `2px dashed grey` : ' 1px dashed grey',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        cursor: exceededCapacity ? 'no-drop' : 'inherit'
      }}
    >
      <Box display="flex" marginBottom="5px" alignItems={'center'}>
        <Typography variant="h6">Documentos</Typography>
      </Box>
      <DocumentCard
        files={documents}
        deleteFile={deleteFile}
        isLoading={isLoading}
        tieneAcceso={tieneAcceso}
        setIsLoading={setIsLoading}
      />

      <Box
        display="flex"
        marginBottom="5px"
        alignItems={'center'}
        justifyContent="end"
        marginRight={'15px'}
      >
        <Button
          variant="contained"
          color="secondary"
          component="label"
          startIcon={<UploadFileIcon />}
          disabled={exceededCapacity || !tieneAcceso || isLoading}
        >
          Subir documentos
          <input
            hidden
            multiple
            type="file"
            onChange={handleFileSelected}
            disabled={exceededCapacity || !tieneAcceso || isLoading}
          />
        </Button>
      </Box>
    </Grid>
  );
}
