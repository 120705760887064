import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path = '';
let respuesta: any;

/**
 *
 * @param { string } servicio servicio al que llamamos
 * @param { [] } dispositivos lista de dispositivos
 * @returns { Promise<any> } retorna una lista de etiquetas pertenecientes a los dispositivos
 */
export async function etiquetasByDispositivos(
  servicio: string,
  dispositivos: number[]
): Promise<any> {
  path = 'tags/devices/';
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({
        devices: dispositivos
      }),
      {
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
