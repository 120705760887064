import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { grupo, objectGrupo } from './resources/interfaces/interfaceGrupo';
import useStyles from './styles';
import { TablaGrupos } from './TablaGrupos';
import { VolverAtras } from '../common/botonVolverAtras/VolverAtras';
import ControlContext from '../../context/control/ControlContext';
import SnackBarContext from '../../context/SnackBarContext';
import { limpiarCampos } from '../../functions/functions';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { useEventChange } from '../../hooks/useEventChange/useEventChange';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { dictionary_grupos, dictionary_generic, enumLabel } from '../../resources/enums/plainText';

export function GruposCRU(props: { grupoSeleccionado?: grupo }): JSX.Element {
  const classes = useStyles();
  const [grupo, setGrupo] = React.useState<grupo>(objectGrupo(props.grupoSeleccionado));
  const [checked, setChecked] = React.useState<boolean>(
    props.grupoSeleccionado === undefined ? true : false
  );
  const { setControl } = React.useContext(ControlContext);
  const { setSnackBar } = React.useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { handleInputChange, handleInputInvalid } = useEventChange(grupo, setGrupo);
  const openCloseSmarTeliaBackdrop = {
    openSmartTeliaBackdrop: true,
    closeSmartTeliaBackdrop: true
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (props.grupoSeleccionado) {
      await controllerRequest(
        {
          type: action.MODIFICAR_REGISTRO,
          payload: { servicio: rutasServicios.GRUPOS, objeto: grupo, id: grupo.id as number }
        },
        openCloseSmarTeliaBackdrop
      );
    } else {
      await controllerRequest(
        {
          type: action.CREAR_UN_REGISTRO,
          payload: { servicio: rutasServicios.GRUPOS, objeto: grupo }
        },
        openCloseSmarTeliaBackdrop
      );
    }
    if (completadoCorrectamente.current) {
      limpiarCampos('formGrupo');
      setControl(<TablaGrupos key={enumComponentes.GRUPOS} />);
    }
    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  }

  return (
    <div className={classes.root}>
      <form id="formGrupo" onSubmit={handleSubmit} autoComplete="off">
        <Box display="flex" marginBottom="30px">
          <VolverAtras backTo={<TablaGrupos />} />
          <Typography variant="h5" className={classes.tituloFormulario}>
            {props.grupoSeleccionado
              ? dictionary_grupos.MODIFICAR_GRUPO
              : dictionary_grupos.CREAR_GRUPO}
          </Typography>
        </Box>
        <Grid container md={5} sm={8} xs={12} spacing={4} direction="column">
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label={enumLabel.CODIGO_GRUPO}
              id="codigo"
              type="text"
              required
              onChange={handleInputChange}
              onKeyDown={handleInputInvalid}
              onPaste={handleInputInvalid}
              defaultValue={props.grupoSeleccionado?.codigo}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={4} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={3}>
                <Button variant="contained" color="secondary" fullWidth={true} type="submit">
                  {props.grupoSeleccionado
                    ? dictionary_generic.MODIFICAR
                    : dictionary_generic.CREAR}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
