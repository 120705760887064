import React from 'react';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import UserContext from '../../../context/UserContext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MicIcon from '@mui/icons-material/Mic';
import MailIcon from '@mui/icons-material/Mail';
import { useStyles } from './styles';
import { useReducerNav } from './hook/useReducerNav';
import PersonIcon from '@mui/icons-material/Person';
import { MiCuenta } from '../../usuarios/MiCuenta';
import { Modulos } from '../../../resources/enums/enumModulo';
import { dictionary_generic, dictionary_usuarios } from '../../../resources/enums/plainText';
import ControlContext from '../../../context/control/ControlContext';
import { UsuariosManager } from '../../usuarios/UsuariosManager';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { ArrowBack } from '@mui/icons-material';
import { keyHome } from 'components/home/homeModulos/resources/enums/enumHome';

export function NavPrincipal({
  open,
  handleDrawerOpen,
  handleClickModulo,
  moduloSeleccionado
}: {
  open: boolean;
  handleDrawerOpen: () => void;
  handleClickModulo: (idModulo: number) => void;
  moduloSeleccionado: number;
}): JSX.Element {
  const classes = useStyles();
  const { datosUsuarioContext } = React.useContext(UserContext);
  const {
    setControl,
    setModuloSeleccionado,
    comprobarAcceso,
    handleClickOpenConfiguratorAlert,
    control
  } = React.useContext(ControlContext);
  const { state, events } = useReducerNav();
  const { abrirMiCuenta } = state;

  const handleOpenSettings = () => {
    if (comprobarAcceso(enumComponentes.USUARIOS)) {
      handleClickOpenConfiguratorAlert(() => {
        setModuloSeleccionado(Modulos['GESTIÓN DE USUARIOS']);
        setControl(
          <UsuariosManager
            usuarioSeleccionado={datosUsuarioContext.usuario}
            key={enumComponentes.MODIFICAR_USUARIOS}
          />
        );
      });
    }
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}
    >
      <Toolbar className={classes.toolbar}>
        {moduloSeleccionado > 0 ? (
          <div
            style={{
              marginLeft: `${open ? '30px' : '50px'}`,
              flexGrow: 1
            }}
          >
            {control.key === keyHome.COMUN ? (
              <></>
            ) : (
              <IconButton color="secondary" onClick={() => handleClickModulo(moduloSeleccionado)}>
                <ArrowBack />
              </IconButton>
            )}

            <Button
              variant="outlined"
              onClick={() => handleClickModulo(moduloSeleccionado)}
              className={classes.botonModulo}
            >
              {Modulos[moduloSeleccionado]}
            </Button>
          </div>
        ) : (
          <></>
        )}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Tooltip title={dictionary_generic.RECONOCIMIENTO_VOZ}>
          <IconButton
            aria-label={dictionary_generic.MICROFONO}
            onClick={events.escuchar}
            size={'medium'}
            className={classes.botonNavDerecha}
          >
            <MicIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary_generic.SUGERENCIAS}>
          <IconButton
            aria-label={dictionary_generic.SUGERENCIAS}
            size={'medium'}
            className={classes.botonNavDerecha}
            onClick={() => {
              window.location.href = 'mailto:producto@426code.com';
            }}
          >
            <MailIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary_generic.HOME}>
          <IconButton onClick={() => handleClickModulo(0)} className={classes.botonNavDerecha}>
            <HomeOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={dictionary_usuarios.MI_CUENTA}>
            <IconButton
              onClick={handleOpenSettings}
              size="small"
              accessKey="u"
              className={classes.botonNavDerecha}
              aria-label="usuario"
              color="primary"
            >
              <Avatar alt={datosUsuarioContext.usuario.nombreUsuario} className={classes.avatar}>
                <PersonIcon />
              </Avatar>
              <Typography color={'textPrimary'} className={classes.nombreUsuario}>
                {datosUsuarioContext.usuario.nombre + ' ' + datosUsuarioContext.usuario.apellidos}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>

        <Tooltip title={dictionary_usuarios.CERRAR_SESION}>
          <IconButton onClick={events.cerrarSesion}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Divider />
      <MiCuenta
        abrirMiCuenta={abrirMiCuenta}
        setAbrirMiCuenta={events.setAbrirMiCuenta}
        usuario={datosUsuarioContext.usuario}
      />
    </AppBar>
  );
}
