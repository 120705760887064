import React, { useEffect, useReducer } from 'react';
import ControlContext from '../../../context/control/ControlContext';
import UserContext from '../../../context/UserContext';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import {
  obtenerAlertasSinResolverByModulo,
  obtenerDispositivosConAlertas
} from '../../../hooks/useControlador/resources/interfaces/interfaceServicios';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { formatToDayStart } from '../../../functions/functions';
import { seleccionarIcono } from '../functions/functions';
import { Rango } from '../../graficas/resources/enums/enumRango';
import { showTimeLine } from '../resources/interfaces/interfaceShowTimeLine';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { comprobadorPermisosDispositivos } from '../../../context/DatosContext';
import { alerta } from '../resources/interfaces/interfaceAlerta';

export const ACTIONS = {
  SET_DISPOSITIVO_SELECCIONADO: 'setDispositivoSeleccionado',
  SET_OPEN_VER_MAS: 'setOpenVerMas',
  SET_LOG: 'setLog',
  SET_ALERTAS: 'setAlertas',
  SET_ALERTAS_SIN_RESOLVER: 'setAlertasSinResolver',
  SET_ALERTAS_SIN_RESOLVER_POR_MODULO: 'setAlertasSinResolverPorModulo'
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_DISPOSITIVO_SELECCIONADO:
      return { ...state, dispositivoSeleccionado: action.payload };
    case ACTIONS.SET_OPEN_VER_MAS:
      return { ...state, openVerMas: action.payload };
    case ACTIONS.SET_ALERTAS:
      return { ...state, alertas: action.payload };
    case ACTIONS.SET_LOG:
      return { ...state, log: action.payload };
    case ACTIONS.SET_ALERTAS_SIN_RESOLVER:
      return { ...state, alertasSinResolver: action.payload };
    case ACTIONS.SET_ALERTAS_SIN_RESOLVER_POR_MODULO:
      return { ...state, alertasSinResolverPorModulo: action.payload };
    default:
      return state;
  }
};

export function useReducerAlertas(componente?: string) {
  const { moduloSeleccionado, control } = React.useContext(ControlContext);
  const [state, dispatch] = useReducer(reducer, {
    dispositivoSeleccionado: undefined,
    openVerMas: false,
    log: [],
    alertas: null,
    alertasSinResolver: null,
    alertasSinResolverPorModulo: null,
    control
  });
  const { controllerRequest } = useControlador();
  const { datosUsuarioContext } = React.useContext(UserContext);
  const { dispositivoSeleccionado } = state;

  /**
   * Función ue modifica el dispositivo seleccionado
   * @param { dispositivo | undefined } dispositivo Objeto dispositivo
   */
  function setDispositivoSeleccionado(dispositivo: dispositivo | undefined) {
    dispatch({ type: ACTIONS.SET_DISPOSITIVO_SELECCIONADO, payload: dispositivo });
  }

  /**
   * Función que modifica el abrir o cerrar del modal ver más alertas
   * @param { boolean } openVerMas booleano par abrir o cerrar el modal
   */
  function setOpenVerMas(openVerMas: boolean) {
    dispatch({ type: ACTIONS.SET_OPEN_VER_MAS, payload: openVerMas });
  }
  /**
   * Función que modifica la lista de alertas
   * @param { [] } alertas alertas
   */
  function setAlertas(alertas: showTimeLine[]) {
    const alertasFiltradas =
      alertas.length > 0
        ? comprobadorPermisosDispositivos(
            alertas,
            'alertas',
            datosUsuarioContext.dispositivos,
            moduloSeleccionado
          )
        : alertas;
    dispatch({ type: ACTIONS.SET_ALERTAS, payload: alertasFiltradas });
  }
  /**
   * Función que modifica la lista de alertas sin resolver
   * @param { [] } alertas alertas
   */
  function setAlertasSinResolverPorModulo(alertas: showTimeLine[]) {
    const alertasFiltradas =
      alertas.length > 0
        ? comprobadorPermisosDispositivos(
            alertas,
            'alertas',
            datosUsuarioContext.dispositivos,
            moduloSeleccionado
          )
        : alertas;
    dispatch({
      type: ACTIONS.SET_ALERTAS_SIN_RESOLVER_POR_MODULO,
      payload: alertasFiltradas
    });
  }

  /**
   * Función que modifica los logs
   * @param { [] } logs
   */
  function setLog(logs: []) {
    dispatch({ type: ACTIONS.SET_LOG, payload: logs });
  }

  const cargarLog = async (
    setTimeLine: any,
    range: number,
    fechaDesde?: string | undefined,
    fechaHasta?: string | undefined
  ) => {
    setTimeLine(
      await controllerRequest(
        {
          type: action.OBTENER_ALERTAS_BY_DISPOSITIVO,
          payload: {
            servicio: rutasServicios.ALERTAS,
            idDispositivo: (dispositivoSeleccionado?.idDispositivo as dispositivo['id']) as number,
            range: range,
            fechaDesde: fechaDesde === undefined ? undefined : fechaDesde,
            fechaHasta: fechaHasta === undefined ? undefined : fechaHasta,
            componente: componente as string
          }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      )
    );
  };

  //*********************************** */
  //Este código no se está usando de momento.

  // const obtenerAlertasPorModulo: obtenerAlertasByModulo = {
  //   type: action.OBTENER_ALERTAS_BY_MODULO,
  //   payload: { servicio: rutasServicios.ALERTAS, range: 0 }
  // };

  // useEffect(() => {
  //   const cargarAlertasPorModulo = async () => {
  //     dispatch({
  //       type: ACTIONS.SET_ALERTAS_SIN_RESOLVER,
  //       payload: await controllerRequest(obtenerAlertasPorModulo, {
  //         openSmartTeliaBackdrop: true,
  //         closeSmartTeliaBackdrop: true
  //       })
  //     });
  //   };

  //   cargarAlertasPorModulo();
  // }, []);

  //*********************************** */

  const cargarAlertasSinResolverPorModulo = async (valores?: showTimeLine[]): Promise<void> => {
    if (valores && valores.length) {
      setAlertasSinResolverPorModulo(valores);
    } else {
      const arrayAlertas: showTimeLine[] = [];
      const obtenerAlertasSinResolverPorModulos: obtenerAlertasSinResolverByModulo = {
        type: action.OBTENER_ALERTAS_SIN_RESOLVER_POR_MODULO,
        payload: { servicio: rutasServicios.ALERTAS }
      };
      await controllerRequest(
        obtenerAlertasSinResolverPorModulos,
        (control.key as string).includes(enumComponentes.ALERTAS)
          ? {
              openSmartTeliaBackdrop: true,
              closeSmartTeliaBackdrop: true
            }
          : undefined
      ).then((response: alerta[]) => {
        response.reverse().forEach((alert) => {
          const fila: showTimeLine = {
            idDispositivo: alert.idDispositivo,
            icono: seleccionarIcono(alert.idTipoAlerta).icono,
            nombre: alert.nombre,
            ultimaAlarma: formatToDayStart(alert.fechaRegistro?.toString() as string)
          };
          if (!arrayAlertas.some((a) => a.idDispositivo === alert.idDispositivo))
            arrayAlertas.push(fila);
        });
      });
      setAlertasSinResolverPorModulo(arrayAlertas);
    }
  };

  const cargarDispositivosConAlertas = async (valores?: showTimeLine[]): Promise<void> => {
    if (valores && valores.length) {
      setAlertas(valores);
    } else {
      const arrayAlertas: showTimeLine[] = [];
      const obtenerLista: obtenerDispositivosConAlertas = {
        type: action.OBTENER_DISPOSITIVOS_CON_ALERTAS,
        payload: { servicio: rutasServicios.DISPOSITIVOS, idModulo: moduloSeleccionado }
      };
      await controllerRequest(
        obtenerLista,
        (control.key as string).includes(enumComponentes.ALERTAS)
          ? {
              openSmartTeliaBackdrop: true,
              closeSmartTeliaBackdrop: true
            }
          : undefined
      ).then((response: dispositivo[]) => {
        response.reverse().forEach((dispositivo) => {
          const fila: showTimeLine = {
            idDispositivo: dispositivo.id,
            icono: seleccionarIcono(dispositivo.idTipoAlerta as number).icono,
            nombre: dispositivo.nombre,
            ultimaAlarma: formatToDayStart(
              (dispositivo.fechaAlerta?.toString() as dispositivo['fechaAlerta']) as string
            )
          };
          if (!arrayAlertas.some((alerta) => dispositivo.id === alerta.idDispositivo))
            arrayAlertas.push(fila);
        });
      });
      setAlertas(arrayAlertas);
    }
  };

  // useEffect(() => {
  //   const cargarDatos = async () => {
  //     if ((control.key as string) === enumComponentes.ALERTAS_PENDIENTES) {
  //       await cargarAlertasSinResolverPorModulo(valoresObtenidos);
  //     } else {
  //       await cargarDispositivosConAlertas(valoresObtenidos);
  //     }
  //   };
  //   cargarDatos();

  //   return () => {
  //     setAlertas([]);
  //     setAlertasSinResolverPorModulo([]);
  //   };
  // }, [moduloSeleccionado]);

  useEffect(() => {
    if (dispositivoSeleccionado !== undefined) cargarLog(setLog, Rango.WITHOUT_INDICATING);

    return () => dispatch({ type: ACTIONS.SET_LOG, payload: [] });
  }, [dispositivoSeleccionado]);

  return {
    state,
    setDispositivoSeleccionado,
    setLog,
    setOpenVerMas,
    cargarLog,
    cargarAlertasSinResolverPorModulo,
    cargarDispositivosConAlertas
  };
}
