import React, { useState } from 'react';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import PropTypes, { InferProps } from 'prop-types';

/**
 * Este contexto nos permite tener acceso al objeto que formará el GIS, además de los objetos que filtremos.
 */

interface GISContext {
  datosGIS: dispositivo[];
  datosGISFiltrados: dispositivo[];
  setDatosGIS: React.Dispatch<React.SetStateAction<dispositivo[]>>;
  setDatosGISFiltrados: React.Dispatch<React.SetStateAction<dispositivo[]>>;
  codTipoDispositivo: Array<string>;
  setCodTipoDispositivo: React.Dispatch<React.SetStateAction<Array<string>>>;
}
const Context = React.createContext<GISContext>({
  datosGIS: [],
  datosGISFiltrados: [],
  setDatosGIS: useState,
  setDatosGISFiltrados: useState,
  codTipoDispositivo: [],
  setCodTipoDispositivo: useState
});

GISContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export function GISContextProvider({
  children
}: InferProps<typeof GISContextProvider.propTypes>): JSX.Element {
  const [datosGIS, setDatosGIS] = useState<dispositivo[]>([]);
  const [datosGISFiltrados, setDatosGISFiltrados] = useState<dispositivo[]>([]);
  const [codTipoDispositivo, setCodTipoDispositivo] = useState<Array<string>>([]);

  return (
    <Context.Provider
      value={{
        datosGIS,
        setDatosGIS,
        datosGISFiltrados,
        setDatosGISFiltrados,
        codTipoDispositivo,
        setCodTipoDispositivo
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
