import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Fab, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import useStyles from './styles';
import { canalesRespuesta } from './resources/interfaces/interfaceCanalesRespuesta';
import { CanalesManager } from './CanalesManager';
import Table from '../common/table/Table';
import PaginationRounded from '../common/table/Paginacion';
import {
  ContenedorActivado,
  ContenedorDesactivado,
  ContenedorElementos
} from '../common/table/contenedorElementos';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import { enumTabla } from '../common/table/resources/enumTabla';
import ControlContext from '../../context/control/ControlContext';
import { useControlador } from '../../hooks/useControlador/useControlador';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { administracion } from '../../resources/enums/enumAdministracion';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import { dictionary_canales } from '../../resources/enums/plainText';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { sortArray } from '../../functions/functions';

export function TablaCanales(props: any): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [canales, setCanales] = useState<canalesRespuesta[]>([]);
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  const { controllerRequest } = useControlador();
  const { setControl, tieneAcceso } = useContext(ControlContext);

  function confirmarEliminarAlert(): void {
    confirmarEliminar(canales, setCanales, rutasServicios.CANALES);
  }

  const columns = [
    { name: 'nombreDispositivo', label: 'Dispositivo' },
    { name: 'nombre', label: 'Nombre' },
    {
      name: 'activo',
      label: 'Activo',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (value: number): JSX.Element => {
          if (value === 1) return <ContenedorActivado />;
          else return <ContenedorDesactivado />;
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return tieneAcceso ? (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar datosTabla={tableMeta} elemento={administracion.CANALES} />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          ) : (
            <></>
          );
        }
      }
    }
  ];

  const optionsConAcceso = {
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (canales.length > 0 && colData.key !== enumTabla.BOTONES_TABLA && tieneAcceso) {
        setControl(
          <CanalesManager
            canalSeleccionado={canales[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_CANAL}
          />
        );
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((c) => canales[c.dataIndex]));
    }
  };

  const optionsSinAcceso = {
    selectableRows: 'none',
    selectableRowsHeader: false
  };

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  Object.assign(options, tieneAcceso ? optionsConAcceso : optionsSinAcceso);

  useEffect(() => {
    const p = async () => {
      const resultado: canalesRespuesta[] = await controllerRequest(
        {
          type: action.OBTENER_DATOS_BY_MODULO,
          payload: { servicio: rutasServicios.CANALES }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      );
      const canalesOrdenados = sortArray(resultado, 'nombreDispositivo');
      setCanales(canalesOrdenados);
    };
    p();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      alignItems="end"
    >
      {canales.length > 0 ? (
        <Table
          title={dictionary_canales.CANALES}
          columns={columns}
          data={canales}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      {tieneAcceso ? (
        <Tooltip accessKey="a" title={dictionary_canales.CREAR_CANAL} arrow>
          <Fab
            className={classes.fabBoton}
            tabIndex={0}
            color="secondary"
            aria-label="add"
            onClick={() => setControl(<CanalesManager key={enumComponentes.CREAR_CANAL} />)}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      ) : (
        <></>
      )}
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </Box>
  );
}
