import React from 'react';
import ControlContext from '../../context/control/ControlContext';
import { Slide, Zoom } from '@mui/material';
import { Transitions } from '../../resources/enums/enumTransitions';

export function SelectComponente(): JSX.Element {
  const { control } = React.useContext(ControlContext);

  function controlManager(): JSX.Element {
    let component = <></>;
    switch (control.props['data-Transition']) {
      case undefined:
        component = control;
        break;
      case Transitions.Slide:
        component = (
          <Slide
            in
            direction={'up'}
            mountOnEnter
            unmountOnExit
            timeout={700}
            style={{ height: '100%' }}
          >
            <div>{control}</div>
          </Slide>
        );
        break;
      case Transitions.Zoom:
        component = (
          <Zoom in mountOnEnter unmountOnExit timeout={700} style={{ height: '100%' }}>
            <div>{control}</div>
          </Zoom>
        );
        break;
      default:
        break;
    }
    return component;
  }

  return controlManager();
}
