import React, { Dispatch, SetStateAction } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Button, Dialog, Grid } from '@mui/material/';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material/';
import { Extension } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { useStyles } from './styles';
import { plugin } from '../resources/interfaces/interfacePlugin';
import { dictionary_generic, dictionary_plugins } from '../../../resources/enums/plainText';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-info-plugin" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DialogoInfoPlugin(props: {
  openDialogoInfo: boolean;
  setOpenDialogoInfo: Dispatch<SetStateAction<boolean>>;
  plugin: plugin;
}): JSX.Element {
  const classes = useStyles();
  const handleCloseDialogoInfo = () => {
    props.setOpenDialogoInfo(false);
  };

  const handleUpdate = (): void => {
    // actualizamos el plugin
  };

  return (
    <div>
      <Dialog
        open={props.openDialogoInfo}
        onClose={handleCloseDialogoInfo}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-info-plugin"
      >
        <DialogTitle id="draggable-dialog-info-plugin">{props.plugin.nombre}</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item md={4} sm={4} xs={4}>
              <div className={classes.imagenDialogo}>
                {props.plugin.logotipo !== '' ? (
                  <img
                    className={classes.logotipo}
                    src={props.plugin.logotipo}
                    alt={props.plugin.nombre}
                  />
                ) : (
                  <Extension className={classes.logotipoPluginDialogo} color="secondary" />
                )}
              </div>
            </Grid>
            <Grid item md sm xs>
              <DialogContentText>{props.plugin.descripcion}</DialogContentText>
              <DialogContentText component="i">{`Versión: ${props.plugin.version}`}</DialogContentText>
              {props.plugin.descatalogado === 1 ? (
                <Alert severity="warning" className={classes.avisoDescatalogado}>
                  {dictionary_plugins.DESCATALOGADO}
                </Alert>
              ) : (
                <></>
              )}
              {props.plugin.actualizado === false ? (
                <Alert severity="warning" className={classes.avisoDescatalogado}>
                  {dictionary_plugins.DESACTUALIZADO}
                </Alert>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {props.plugin.actualizado === false ? (
            <Button
              variant="contained"
              type="button"
              aria-label="install/uninstall"
              color="secondary"
              onClick={handleUpdate}
            >
              {dictionary_generic.ACTUALIZAR}
            </Button>
          ) : (
            <></>
          )}
          <Button
            autoFocus
            variant="contained"
            type="button"
            aria-label="close"
            color="secondary"
            onClick={handleCloseDialogoInfo}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
