import { useCallback, useState } from 'react';
import { enumSeverity } from '../../components/common/snackbar/resources/enums/enumSeverity';
import { Severity } from '../../components/common/snackbar/resources/types/Severity';
import { respuesta } from '../../resources/interfaces/interfaceRespuesta';

const MENSAJE_ERROR_GEOLOCATION = 'No se ha podido obtener la geolocalización del cliente';

export function useRespuesta(
  completadoCorrectamente: React.MutableRefObject<boolean>,
  propiedadesSnackBar: React.MutableRefObject<{
    texto: string;
    severity: Severity;
  }>
): {
  tratarRespuesta: (respuesta: respuesta) => void;
  completadoCorrectamente: React.MutableRefObject<boolean>;
  propiedadesSnackBar: React.MutableRefObject<{
    texto: string;
    severity: Severity;
  }>;
} {
  /**
   * Todas las respuestas de los hooks son exactamente iguales y se tratan de la misma forma. Esta función
   * actúa de zona común donde se trata esa respuesta evitando que cada hook tenga un useEffect con código duplicado
   *
   * @param respuesta la respuesta que mandan los hooks en función de la cuál damos un mensaje u otro
   */
  const tratarRespuesta = useCallback((respuesta: respuesta) => {
    if (respuesta) {
      if (respuesta.status === 200) {
        completadoCorrectamente.current = true;
        propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
        propiedadesSnackBar.current.texto = respuesta.data.message;
      } else if (respuesta.isAxiosError) {
        completadoCorrectamente.current = false;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        propiedadesSnackBar.current.texto =
          respuesta.response === undefined
            ? 'No hay conexión con el servidor'
            : respuesta.response.data.message;
      } else if (respuesta.message?.includes('ZERO_RESULTS')) {
        completadoCorrectamente.current = false;
        propiedadesSnackBar.current.severity = enumSeverity.WARNING;
        propiedadesSnackBar.current.texto = MENSAJE_ERROR_GEOLOCATION;
      } else {
        completadoCorrectamente.current = false;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        propiedadesSnackBar.current.texto = respuesta.data.message;
      }
    }
  }, []);

  return {
    tratarRespuesta,
    completadoCorrectamente,
    propiedadesSnackBar
  };
}
