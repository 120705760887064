export enum actions {
  SET_OPEN_CLOSE_TRANSITION = 'setOpenCloseTransition',
  SET_END_TRANSITION = 'setEndTransition',
  SET_DOING_TRANSITION = 'setDoingTransition',
  SET_SECTIONS = 'setSections',
  SET_IS_LOADING = 'setIsLoading',
  HANDLE_ENTER = 'handleEnter',
  HANDLE_OPEN_CLOSE_TRANSITION = 'handleOpenCloseTransition',
  HANDLE_ENDED_TRANSITION = 'handleEndedTransition',
  HANDLE_EXIT = 'handleExit'
}
