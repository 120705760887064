import { Grid, Box, Typography, Grow } from '@mui/material';
import React from 'react';
import { namesLocalStorage } from '../../../../resources/enums/enumNamesLocalStorage';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { formateoFechaGrafico } from '../../../graficas/functions/funciones';
import { PrevisionClimaDias } from '../../principal/resources/interfaces/interfaceClima';
import { useStyles } from './styles';

export function WeatherForecastCard(props: any): JSX.Element {
  const classes = useStyles();
  const formateoPrevision = formateoFechaGrafico.format('%e %a');
  const dailyForecast = localStorage.getItem(namesLocalStorage.dailyForecast);
  const arrForecast: PrevisionClimaDias[] = JSON.parse(dailyForecast as string);
  const { tarjeta, handleCardClick = null } = props;

  const [transition, setTransition] = React.useState(true);

  return (
    <>
      <Grid
        item
        xs={4}
        style={{ cursor: 'not-allowedone' }}
        sx={{ minHeight: '166px', maxHeight: '182px' }}
      >
        <Grow in={transition} timeout={650} style={{ height: 'auto' }}>
          <div
            style={{ height: '100%', cursor: handleCardClick ? 'context-menu' : 'auto' }}
            onContextMenu={(event) => {
              event.preventDefault();
              if (handleCardClick) {
                handleCardClick(event, tarjeta.position, setTransition, tarjeta.idTipoTarjeta);
              }
            }}
          >
            <Box
              style={{
                boxShadow: `0px 0px 1px 2px #e0e0e0`,
                borderRadius: '15px',
                display: 'grid',
                width: '100%',
                height: '166px'
              }}
              alignItems="center"
              textAlign={'center'}
              gridAutoFlow={'column'}
              justifyContent={'space-evenly'}
            >
              {arrForecast.map((item) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={item.unixUTCDay}
                  flexDirection={'column'}
                  sx={{ minHeight: '102px' }}
                >
                  <Typography>{formateoPrevision(new Date(item.unixUTCDay * 1000))}</Typography>
                  <svg className={classes.imgTiempoMapa}>
                    <image xlinkHref={item.weather.icono} />
                  </svg>
                  <Typography>
                    <b className={classes.bMaxMin}>
                      {item.tempMAX}
                      {dictionary_unidades.grados}
                    </b>{' '}
                    {item.tempMIN}
                    {dictionary_unidades.grados}
                  </Typography>
                </Box>
              ))}
            </Box>
          </div>
        </Grow>
      </Grid>
    </>
  );
}
