/* eslint-disable no-case-declarations */
import React, { useContext, useReducer, FormEvent } from 'react';
import moment from 'moment';
import ControlContext from '../../../context/control/ControlContext';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { regla, objectRegla } from '../resources/interfaces/interfaceRegla';
import {
  condicionCompleta,
  objectCondicionCompleta
} from '../resources/interfaces/interfaceCondicionCompleta';

import { useEffect } from 'react';
import { accion } from '../resources/interfaces/interfaceAccion';
import { enumTiposAcciones } from '../resources/enums/enumTiposAcciones';
import { FormularioActuacionDispositivo } from '../../accionesMotor/formularios/FormularioActuacionDispositivo';
import { tipoAccion } from '../resources/interfaces/interfaceTipoAccion';
import {
  accionCompleta,
  objectAccionCompleta
} from '../resources/interfaces/interfaceAccionCompleta';
import { ReglasManager } from '../ReglasManager';
import { ChangeEvent } from 'react';
import { dictionary_asistente_permisos } from '../../../resources/enums/plainText';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';
import { FormularioCambiarEstadoRegla } from '../../accionesMotor/formularios/FormularioCambiarEstadoRegla';
import { FormularioEmail } from '../../accionesMotor/formularios/FormularioEmail';
import {
  dictionary_error_reglas,
  dictionary_correcto_reglas
} from '../../../resources/enums/plainText';
import { FormularioWhatsapp } from '../../accionesMotor/formularios/FormularioWhatsapp';
import { actionsReglas } from '../resources/enums/enumActionsReglas';
import { reducerReglas } from '../resources/reducers/reducerReglas';
import { TipoCondicionTemporal } from '../resources/enums/enumTipoCondicionTemporal';
import { useReducerCondiciones } from '../../condiciones/hooks/useReducerCondiciones';
import { TipoCondicion } from '../../condiciones/resources/enums/enumTipoCondicion';
import { condicion } from '../resources/interfaces/interfaceCondicion';
import { FormularioActuacionGrupal } from '../../accionesMotor/formularios/FormularioActuacionGrupal';
import { grupo } from '../../grupos/resources/interfaces/interfaceGrupo';
import { section } from '../../sections/resources/interfaces/interfaceSection';
import { tipoDevice } from '../../device/resources/interfaces/interfaceTipoDevice';

const steps = ['Crear regla', 'Añadir condiciones', 'Seleccionar tipo de acción', 'Añadir acción'];
export function useReducerReglas(reglaSeleccionada?: regla | any) {
  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const { control, setControl } = React.useContext(ControlContext);
  const { openSmartTeliaBackdrop, setOpenSmartTeliaBackdrop } =
    useContext(SmartTeliaBackdropContext);
  const eventosRegla = { addCondicionRegla, convertirOperadorAFrase };
  const { stateCondiciones, events: eventosCondicion } = useReducerCondiciones(eventosRegla);
  const {
    setCondicionCompleta,
    setDialogoCondiciones,
    handleCreateCondicion,
    mostrarInfoCondicion,
    cerrarInfoCondicion,
    setTipoCondicion,
    setHora,
    setOpcionTemporalEscogida,
    setDiaSemana,
    setMomentoDia,
    mensajesTemporales
  } = eventosCondicion;

  const [state, dispatch] = useReducer(reducerReglas, {
    listaReglas: [],
    tiposAcciones: [],
    listaCondiciones: [],
    listaAcciones: [],
    accionSeleccionada: undefined,
    //objeto listo para mandar a base de datos
    reglaReady: {
      regla: reglaSeleccionada !== undefined ? objectRegla(reglaSeleccionada) : objectRegla(),
      condiciones: [],
      acciones: []
    },
    reglaSeleccionada: reglaSeleccionada !== undefined ? reglaSeleccionada : undefined,
    panelAccionActivo: <></>,
    tipoAccionActiva: undefined,
    estadoDialogo: false,
    estadoDialogoCondiciones: false,
    estadoDialogoAcciones: false,
    mensajeTelia: '',
    estadoTelia: false,
    asistenteReglas: {
      activeStep: 0,
      steps: steps,
      componenteAsistente: <></>
    },
    isInfoAccionOpen: false,
    accionActiva: objectAccionCompleta(),
    textoMostrarAccion: ''
  });
  const { reglaReady } = state;

  const cargarReglas = async () => {
    setOpenSmartTeliaBackdrop(true);
    const listaReglas: regla[] = await controllerRequest({
      type: action.OBTENER_DATOS_SIN_MODULO,
      payload: { servicio: rutasServicios.REGLAS }
    });
    //Si no tengo ninguna regla, activo a Telia
    listaReglas.length == 0 && !state.estadoTelia && handleButtonTelia();
    setListaReglas(listaReglas);
    setOpenSmartTeliaBackdrop(false);
  };
  const eventosAcciones = { addAccionRegla, cargarReglas };
  /**
   * Función que modifica el panel de acción según el tipo indicado
   *
   * @param { number } tipoAccion Indica el tipo de acción del panel que se debe renderizar
   */
  function handlePanelAccionActivoChange(tipoAccion: number) {
    setEstadoTelia(false);
    dispatch({ type: actionsReglas.SET_TIPO_ACCION_ACTIVA, payload: tipoAccion });
    //TODO: A medida que se vayan implementando los demás tipos de acciones, este switch controla qué componente se va cargando
    switch (tipoAccion) {
      case enumTiposAcciones.actuacion_dispositivo:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <FormularioActuacionDispositivo state={state} events={eventosAcciones} />
        });
        dispatch({
          type: actionsReglas.SET_MENSAJE_TELIA,
          payload:
            'Para crear una actuación, elija el dispositivo y la acción que quieras ejercer sobre el mismo.'
        });
        break;
      case enumTiposAcciones.whatsapp:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <FormularioWhatsapp state={state} events={eventosAcciones} />
        });
        dispatch({
          type: actionsReglas.SET_MENSAJE_TELIA,
          payload:
            'Para enviar un WhatsApp, indique el teléfono y el mensaje. Al introducir el número de teléfono, el usuario consiente a SmartTelia del envío de mensajes a través de WhatsApp.'
        });
        break;
      case enumTiposAcciones.emails:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <FormularioEmail state={state} events={eventosAcciones} />
        });
        dispatch({
          type: actionsReglas.SET_MENSAJE_TELIA,
          payload:
            'Rellene la dirección, el asunto y el mensaje a enviar cuando se cumplan las condiciones.'
        });
        break;
      case enumTiposAcciones.cambio_estado_regla:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <FormularioCambiarEstadoRegla state={state} events={eventosAcciones} />
        });
        dispatch({
          type: actionsReglas.SET_MENSAJE_TELIA,
          payload:
            'Para modificar el estado de una regla, seleccione la que desees y el nuevo estado.'
        });
        break;
      case enumTiposAcciones.actuacion_grupal:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <FormularioActuacionGrupal state={state} events={eventosAcciones} />
        });
        dispatch({
          type: actionsReglas.SET_MENSAJE_TELIA,
          payload:
            'Para crear una actuación grupal, elija el grupo de dispositivos, el tipo de device que quieres cambiar, el canal y el valor a modificar.'
        });
        break;

      case 0:
        dispatch({
          type: actionsReglas.SET_PANEL_ACCION_ACTIVO,
          payload: <></>
        });
        break;
    }
  }

  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }
  /**
   * Función que cambia el valor de los tipos de acciones
   * @param { tipoAccion[] } datos datos de los tipos de acciones
   */
  function setTiposAcciones(datos: tipoAccion[]) {
    dispatch({ type: actionsReglas.SET_TIPOS_ACCIONES, payload: datos });
  }

  function abrirDialogoReglas() {
    dispatch({ type: actionsReglas.SET_ESTADO_DIALOGO, payload: true });
  }
  function cerrarDialogoReglas() {
    dispatch({
      type: actionsReglas.CERRAR_DIALOGO_REGLAS,
      payload: {
        activeStep: 0,
        steps: steps,
        componenteAsistente: <></>
      }
    });
    setCondicionCompleta(objectCondicionCompleta());
    setTipoCondicion('');
  }

  /**
   * Función que crea el nuevo dispositivo con todos los datos recogidos en el formulario
   * @param {FormEvent<HTMLFormElement>} event este evento se lanza cuando pulsamos en el botón crear
   */
  async function handleSubmitEditar(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    //El objeto reglaReady almacena todo lo relacionado con la regla, sin contar condiciones y acciones
    if (estaReglaCorrectamenteCompletada()) {
      const reglaCU = {
        regla: {
          ...reglaReady.regla,
          fechaCreacion: moment(reglaReady.regla.fechaCreacion, 'DD/MM/YYYY HH:mm:ss')
            .subtract(1, 'hours')
            .format('YYYY-MM-DD HH:mm:ss')
        },
        condiciones: state.listaCondiciones.map((c: condicionCompleta) =>
          obtenerCondicionCompleta(c)
        ),
        acciones: state.listaAcciones.map((accion: any) => {
          return obtenerAccionCompleta(accion);
        })
      };
      await controllerRequest(
        {
          type: action.MODIFICAR_REGISTRO,
          payload: { id: reglaCU.regla.id, servicio: rutasServicios.REGLAS, objeto: reglaCU }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      );
      if (completadoCorrectamente.current) {
        //Se cambian las propiedades del Snackbar de que todo ha ido bien
        propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
        propiedadesSnackBar.current.texto = dictionary_correcto_reglas.MODIFICAR_REGLA_CORRECTA;
        setControl(<ReglasManager key={enumComponentes.REGLAS} />);
      }
    }
    mostrarSnackBar(propiedadesSnackBar);
  }

  function onChangeChecked(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: actionsReglas.SET_REGLA_ACTIVA, payload: event.target.checked });
  }

  function setListaCondiciones(listaCondiciones: condicionCompleta[]) {
    dispatch({ type: actionsReglas.SET_LISTA_CONDICIONES, payload: listaCondiciones });
  }

  function setListaAcciones(listaAcciones: accion[]) {
    dispatch({ type: actionsReglas.SET_LISTA_ACCIONES, payload: listaAcciones });
  }
  function setRegla(regla: regla) {
    dispatch({ type: actionsReglas.SET_REGLA, payload: regla });
  }
  function setListaReglas(reglas: regla[]) {
    dispatch({ type: actionsReglas.SET_LISTA_REGLAS, payload: reglas });
  }

  function toggleDialogoCondiciones() {
    dispatch({
      type: actionsReglas.SET_DIALOGO_CONDICIONES,
      payload: !state.estadoDialogoCondiciones
    });
  }

  function toggleDialogoAcciones() {
    dispatch({ type: actionsReglas.SET_DIALOGO_ACCIONES, payload: !state.estadoDialogoAcciones });
  }

  function addCondicionRegla(condicion: condicionCompleta, tipoCondicion: string) {
    //Busco las condiciones que tengan el mismo dispositivo, canal, operador y valor que la nueva.
    //Si existe alguna,
    const numCondicionesRepetidas: number = state.listaCondiciones.filter(
      (condicionLista: condicionCompleta) => {
        //Si la condición a añadir es de tipo dispositivo
        //TODO Aqui se añade la condición a la regla
        if (tipoCondicion == TipoCondicion.CONDICION_DISPOSITIVO) {
          //Si la condición de la lista tiene un dispositivo, hay que compararla con la que vamos a añadir
          if (condicionLista.dispositivo)
            return (
              condicionLista.dispositivo.id == condicion.dispositivo.id &&
              condicionLista.canal.id == condicion.canal.id &&
              condicionLista.operador.id == condicion.operador.id &&
              condicionLista.valor == condicion.valor
            );
        }
        if (tipoCondicion == TipoCondicion.CONDICION_TEMPORAL) {
          return (
            condicionLista.valor == condicion.valor &&
            condicionLista.operador.id == condicion.operador.id
          );
        }

        if (tipoCondicion == TipoCondicion.CONDICION_GRUPAL) {
          return (
            condicionLista.nombreCanal == condicion.nombreCanal &&
            condicionLista.grupo?.codigo == condicion.grupo?.codigo
          );
        }
      }
    ).length;

    if (numCondicionesRepetidas > 0) {
      (propiedadesSnackBar.current.severity = enumSeverity.ERROR),
        (propiedadesSnackBar.current.texto = dictionary_error_reglas.CONDICION_REPETIDA);
    } else {
      propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
      propiedadesSnackBar.current.texto = dictionary_correcto_reglas.CONDICION_CORRECTA;
      //Si es una condición de dispositivo, guardo toda la condición. Si no, tengo que guardar sólo el operador y el valor
      tipoCondicion == TipoCondicion.CONDICION_TEMPORAL
        ? dispatch({
            type: actionsReglas.ADD_CONDICION,
            payload: {
              operador: condicion.operador,
              valor: condicion.valor,
              tipoTemporal: condicion.tipoTemporal
            }
          })
        : dispatch({ type: actionsReglas.ADD_CONDICION, payload: condicion });
    }
    mostrarSnackBar(propiedadesSnackBar);
    setCondicionCompleta(objectCondicionCompleta());
  }

  function addAccionRegla(accion: accionCompleta) {
    //Tengo que guardar la acción, poner el activeStep +1
    dispatch({ type: actionsReglas.ADD_ACCION, payload: accion });
    dispatch({
      type: actionsReglas.HANDLE_BUTTON_ASISTENTE,
      payload: state.asistenteReglas.activeStep
    });
  }

  function quitarCondicionLista(condicion: condicionCompleta) {
    dispatch({
      type: actionsReglas.SET_LISTA_CONDICIONES,
      payload: state.listaCondiciones.filter((e: any) => e !== condicion)
    });
    setCondicionCompleta(objectCondicionCompleta());
    setDialogoCondiciones({
      ...stateCondiciones.dialogoCondiciones,
      dispositivos: [],
      canales: []
    });
  }

  function quitarAccionLista(accion: accionCompleta) {
    dispatch({
      type: actionsReglas.SET_LISTA_ACCIONES,
      payload: state.listaAcciones.filter((e: any) => e !== accion)
    });
  }

  const cargarReglasModificar = async (reglaSeleccionada: regla) => {
    setOpenSmartTeliaBackdrop(true);

    cargarReglas();
    setTiposAcciones(
      await controllerRequest({
        type: action.OBTENER_DATOS_SIN_MODULO,
        payload: { servicio: rutasServicios.TIPOS_ACCIONES }
      })
    );
    const response = await controllerRequest({
      type: action.OBTENER_ELEMENTOS_POR_REGLA,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      payload: { servicio: rutasServicios.CONDICIONES_COMPLETAS, idRegla: reglaSeleccionada.id! }
    });

    setListaCondiciones(response);
    setListaAcciones(
      await controllerRequest({
        type: action.OBTENER_ELEMENTOS_POR_REGLA,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        payload: { servicio: rutasServicios.ACCIONES, idRegla: reglaSeleccionada.id! }
      })
    );
    setOpenSmartTeliaBackdrop(false);
  };
  //Carga inicial de datos
  useEffect(() => {
    switch (control.key) {
      case enumComponentes.REGLAS:
        cargarReglas();
        break;
      case enumComponentes.MODIFICAR_REGLA:
        cargarReglasModificar(reglaSeleccionada);
        break;
    }
  }, []);

  async function handleButtonAsistente(event: ChangeEvent<{ id: string }>) {
    //Si el botón pulsado es el de finalizar, hay que cerrar el diálogo y enviarlo todo
    switch (event.currentTarget.id) {
      case dictionary_asistente_permisos.BOTON_FINALIZAR:
        if (state.listaAcciones.length == 0) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_ACCION;
        } else {
          await handleSubmitCrearRegla();
          //Si la regla se ha creado bien
          if (completadoCorrectamente.current) {
            //Se cambian las propiedades del Snackbar de que todo ha ido bien
            propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
            propiedadesSnackBar.current.texto = dictionary_correcto_reglas.CREAR_REGLA_CORRECTA;
            //Se vuelven a cargar las reglas de back
            await cargarReglas();
          } else {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.CONDICION_REPETIDA;
          }
          cerrarDialogoReglas();
        }
        mostrarSnackBar(propiedadesSnackBar);
        break;
      case dictionary_asistente_permisos.BOTON_SIGUIENTE:
        estaReglaCorrectamenteCompletada(state.asistenteReglas.activeStep)
          ? [
              dispatch({
                type: actionsReglas.HANDLE_BUTTON_ASISTENTE,
                payload: state.asistenteReglas.activeStep + 1
              }),
              setEstadoTelia(false)
            ]
          : mostrarSnackBar(propiedadesSnackBar);

        break;
      case dictionary_asistente_permisos.BOTON_ATRAS:
        dispatch({
          type: actionsReglas.HANDLE_BUTTON_ASISTENTE,
          payload: state.asistenteReglas.activeStep - 1
        });
        setEstadoTelia(false);

        break;
      case dictionary_asistente_permisos.BOTON_FINALIZAR_CONDICION_ACCION:
        dispatch({ type: actionsReglas.SET_DIALOGO_CONDICIONES, payload: false });
        dispatch({ type: actionsReglas.SET_DIALOGO_ACCIONES, payload: false });
        break;
    }
  }

  //Gestión del diálogo que muestra la información de la acción
  async function mostrarInfoAccion(accion: accionCompleta) {
    setOpenSmartTeliaBackdrop(true);
    await obtenerTextoMostrarAccion(accion);
    dispatch({ type: actionsReglas.MOSTRAR_INFO_ACCION, payload: accion });
    setOpenSmartTeliaBackdrop(false);
  }
  function cerrarInfoAccion() {
    dispatch({ type: actionsReglas.CERRAR_MOSTRAR_INFO_ACCION });
  }
  //Función que devuelve un string de texto para mostrar al usuario dependiendo de la acción que se quiera mostrar
  async function obtenerTextoMostrarAccion(accion: any) {
    // eslint-disable-next-line prefer-const
    let texto = '';

    switch (accion.tipoAccion.id) {
      case enumTiposAcciones.actuacion_dispositivo:
        let nombreDispositivo = '';
        let tipoActuacionDispositivo = '';
        // eslint-disable-next-line no-case-declarations
        //Si la acción tiene un ID, es que viene de base de datos y es necesario recoger los datos
        if (accion.id) {
          const tipoActuacion = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: {
              servicio: rutasServicios.TIPOS_ACTUACIONES,
              id: accion.actuacionDispositivo.idTipoActuacion
            }
          });

          // eslint-disable-next-line no-case-declarations
          const dispositivo = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: {
              servicio: rutasServicios.DEVICES,
              id: accion.actuacionDispositivo.idDevice
            }
          });

          nombreDispositivo = dispositivo.nombre;
          tipoActuacionDispositivo = tipoActuacion.nombre;
        } else {
          nombreDispositivo = accion.actuacionDispositivo.dispositivo.nombre;
          tipoActuacionDispositivo = accion.actuacionDispositivo.tipoActuacion.nombre;
        }
        texto = `Al dispositivo ${nombreDispositivo} se le hará lo siguiente: ${tipoActuacionDispositivo}`;
        if (accion.actuacionDispositivo.duration) {
          let ctd = accion.actuacionDispositivo.duration + ' minutos';
          if (accion.actuacionDispositivo.duration >= 60) {
            ctd =
              accion.actuacionDispositivo.duration / 60 === 1
                ? accion.actuacionDispositivo.duration / 60 + ' hora'
                : accion.actuacionDispositivo.duration / 60 + ' horas';
          }
          texto += ` durante ${ctd}`;
        }

        break;
      case enumTiposAcciones.cambio_estado_regla:
        let estadoNuevo = '';
        let nombreRegla = '';
        //Si la acción tiene un ID, es que viene de base de datos y es necesario recoger los datos
        if (accion.id) {
          // eslint-disable-next-line no-case-declarations
          const regla = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: { servicio: rutasServicios.REGLAS, id: accion.cambioEstadoRegla.idRegla }
          });

          // eslint-disable-next-line no-case-declarations
          estadoNuevo = accion.cambioEstadoRegla.estadoNuevo == 1 ? 'activada' : 'desactivada';
          nombreRegla = regla.nombre;
        }
        //En caso contrario, tenemos los datos ya en front y sólo necesitamos recogerlos
        else {
          estadoNuevo = accion.cambioEstadoRegla.regla.activo == 1 ? 'activada' : 'desactivada';
          nombreRegla = accion.cambioEstadoRegla.regla.nombre;
        }
        texto = `A la regla "${nombreRegla}" se le aplicará el estado de ${estadoNuevo}`;
        break;

      case enumTiposAcciones.emails:
        texto = `Se enviará un correo a ${accion.email.direccion} con el asunto: ${accion.email.asunto}`;
        break;

      case enumTiposAcciones.whatsapp:
        texto = `Se enviará un mensaje al número ${accion.whatsapp.numeroWhatsapp}`;
        break;
      case enumTiposAcciones.actuacion_grupal:
        let grupo: grupo;
        let section: section;
        let tipoDevice: tipoDevice;
        if (accion.id) {
          // eslint-disable-next-line no-case-declarations
          grupo = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: { servicio: rutasServicios.GRUPOS, id: accion.actuacionGrupal.idGrupo }
          });

          section = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: { servicio: rutasServicios.SECTIONS, id: accion.actuacionGrupal.idSection }
          });

          tipoDevice = await controllerRequest({
            type: action.OBTENER_DATOS_BY_ID,
            payload: {
              servicio: rutasServicios.TIPOS_DEVICES,
              id: accion.actuacionGrupal.idTipoDevice
            }
          });
        } else {
          grupo = accion.actuacionGrupal.grupo;
          section = accion.actuacionGrupal.section;
          tipoDevice = accion.actuacionGrupal.tipoDevice;
        }
        texto = `A todos los dispositivos de tipo "${tipoDevice.tipo}" del grupo "${grupo.codigo}" se les aplicará "${section.etiqueta}" con el valor: ${accion.actuacionGrupal.valor}`;
        break;
    }

    dispatch({ type: actionsReglas.SET_TEXTO_MOSTRAR_ACCION, payload: texto });
  }

  //Esta función comprueba que toda la información que hay actualmente en el asistente esté
  //correctamente cumplimentada
  function estaReglaCorrectamenteCompletada(pasoAsistente?: number): boolean {
    //Si tenemos el paso del asistente en el que nos encontramos, tenemos que evaluar únicamente ese paso
    if (pasoAsistente != undefined) {
      switch (pasoAsistente) {
        //Nombre y descripción de regla
        case 0:
          if (reglaReady.regla.nombre == '') {
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_NOMBRE;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            return false;
          }
          if (reglaReady.regla.descripcion == '') {
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_DESCRIPCION;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            return false;
          }
          if (reglaReady.regla.descripcion.length > 300) {
            propiedadesSnackBar.current.texto = dictionary_error_reglas.DESCRIPCION_LARGA;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            return false;
          }
          if (reglaReady.regla.nombre.length > 100) {
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NOMBRE_LARGO;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            return false;
          }
          break;
        //Condiciones
        case 1:
          if (state.listaCondiciones.length == 0) {
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_CONDICION;
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            return false;
          }
          break;
        //Seleccionar tipo de actuación
        case 2:
          if (state.listaAcciones.length == 0) {
            propiedadesSnackBar.current.severity = enumSeverity.ERROR;
            propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_ACCION;
            return false;
          }
          break;
      }
    }
    //Si no tenemos el paso del asistente, tenemos que evaluar toda la regla
    else {
      //Regla
      if (reglaReady.regla.nombre == '') {
        propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_NOMBRE;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        return false;
      }
      if (reglaReady.regla.descripcion == '') {
        propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_DESCRIPCION;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        return false;
      }
      if (reglaReady.regla.descripcion.length > 300) {
        propiedadesSnackBar.current.texto = dictionary_error_reglas.DESCRIPCION_LARGA;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        return false;
      }
      if (reglaReady.regla.nombre.length > 100) {
        propiedadesSnackBar.current.texto = dictionary_error_reglas.NOMBRE_LARGO;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        return false;
      }
      if (state.listaCondiciones.length == 0) {
        propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_CONDICION;
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        return false;
      }
      if (state.listaAcciones.length == 0) {
        propiedadesSnackBar.current.severity = enumSeverity.ERROR;
        propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_ACCION;
        return false;
      }
    }
    return true;
  }

  //Convierte el símbolo de operador a una frase para que pueda ser más entendible por el usuario
  function convertirOperadorAFrase(operador: string, temporal?: TipoCondicionTemporal): string {
    if (temporal) {
      switch (temporal) {
        case TipoCondicionTemporal.HORA:
          switch (operador) {
            case '!=':
              return 'cuando no son las';
            case '=':
              return 'cuando son las';
            case '<':
              return 'cuando sean menos de las';
            case '>':
              return 'a partir de las';
            case '>=':
              return 'a partir de las';
            case '<=':
              return 'cuando sean menos de las';
            default:
              return '';
          }
        case TipoCondicionTemporal.SOLAR:
          switch (operador) {
            case '!=':
              return 'cuando no sea el';
            case '=':
              return 'en el';
            case '<':
              return 'cuando sean menos del';
            case '>':
              return 'a partir del';
            case '>=':
              return 'a partir del';
            case '<=':
              return 'cuando sean menos del';
            default:
              return '';
          }
        default:
          switch (operador) {
            case '!=':
              return 'cuando no sea';
            case '=':
              return 'el día';
            case '<':
              return 'cuando sea menos del';
            case '>':
              return 'a partir del';
            case '>=':
              return 'a partir del';
            case '<=':
              return 'cuando sea menos del';
            default:
              return '';
          }
      }
    }
    //Si es un dispositivo, se devuelve de forma simple
    switch (operador) {
      case '!=':
        return 'distinto a';
      case '=':
        return 'igual que';
      case '<':
        return 'menor que';
      case '>':
        return 'mayor que';
      case '>=':
        return 'mayor o igual que';
      case '<=':
        return 'menor o igual que';
      case 'in':
        return 'incluye';
      default:
        return '';
    }
  }

  //Alterna el estado del diálogo de telia
  function handleButtonTelia() {
    dispatch({ type: actionsReglas.SET_ESTADO_TELIA, payload: !state.estadoTelia });
  }

  function setEstadoTelia(estado: boolean) {
    dispatch({ type: actionsReglas.SET_ESTADO_TELIA, payload: estado });
  }

  function setMensajeTelia(mensaje: string) {
    dispatch({ type: actionsReglas.SET_MENSAJE_TELIA, payload: mensaje });
  }

  //Función que convierte la condición completa en una condición lista para enviar a back
  function obtenerCondicionCompleta(c: condicionCompleta): condicion {
    return {
      valor: c.valor,
      idCanal: c.canal ? c.canal.id : undefined,
      idOperador: c.operador.id,
      tipoTemporal: c.tipoTemporal ? c.tipoTemporal : undefined,
      idGrupo: c.grupo ? c.grupo.id : undefined,
      nombreCanal: c.nombreCanal
    };
  }

  //Función que convierte la acción completa en una acción lista para enviar a back
  function obtenerAccionCompleta(accion: any): any {
    //Si la acción viene de base de datos, se devuelve tal cual ya que no es necesario procesamiento extra
    if (accion.id) return accion;
    //SI no, hay que procesarla de forma diferente
    switch (accion.tipoAccion.id) {
      case enumTiposAcciones.actuacion_dispositivo:
        //Si la actuación es nueva, tiene que formatearla y guardarla. Si no, se pasa tal cual como está
        return {
          actuacionDispositivo: {
            idTipoActuacion: accion.actuacionDispositivo.tipoActuacion.id,
            idDevice: accion.actuacionDispositivo.dispositivo.idDevice,
            duration: accion.actuacionDispositivo.duration
          }
        };
      case enumTiposAcciones.cambio_estado_regla:
        return {
          cambioEstadoRegla: {
            idRegla: accion.cambioEstadoRegla.regla.id,
            estadoNuevo: accion.cambioEstadoRegla.regla.activo
          }
        };
      case enumTiposAcciones.emails:
        return {
          email: accion.email
        };
      case enumTiposAcciones.whatsapp:
        return {
          whatsapp: accion.whatsapp
        };
      case enumTiposAcciones.actuacion_grupal:
        return {
          actuacionGrupal: {
            idGrupo: accion.actuacionGrupal.grupo.id,
            idSection: accion.actuacionGrupal.section.id,
            idTipoDevice: accion.actuacionGrupal.tipoDevice.id,
            valor: accion.actuacionGrupal.valor
          }
        };
      default:
        throw new Error(`Tipo de acción ${accion.tipoAccion.id} no implementado`);
    }
  }
  //Función que gestiona el envío de la regla a los servicios
  async function handleSubmitCrearRegla() {
    const reglaCU = {
      regla: reglaReady.regla,
      condiciones: state.listaCondiciones.map((c: condicionCompleta) =>
        obtenerCondicionCompleta(c)
      ),
      acciones: state.listaAcciones.map((accion: any) => {
        return obtenerAccionCompleta(accion);
      })
    };
    await controllerRequest(
      {
        type: action.CREAR_UN_REGISTRO,
        payload: { servicio: rutasServicios.REGLAS, objeto: reglaCU }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );

    setEstadoTelia(false);
  }

  const events = {
    setRegla,
    openSmartTeliaBackdrop,
    setOpenSmartTeliaBackdrop,
    handleSubmitEditar,
    addCondicionRegla,
    setCondicionCompleta,
    handlePanelAccionActivoChange,
    quitarCondicionLista,
    onChangeChecked,
    quitarAccionLista,
    setListaReglas,
    cerrarDialogoReglas,
    abrirDialogoReglas,
    handleButtonAsistente,
    convertirOperadorAFrase,
    handleCreateCondicion,
    mostrarInfoCondicion,
    cerrarInfoCondicion,
    mostrarInfoAccion,
    cerrarInfoAccion,
    handleButtonTelia,
    setMensajeTelia,
    toggleDialogoCondiciones,
    toggleDialogoAcciones,
    cargarReglas,
    obtenerTextoMostrarAccion,
    setTipoCondicion,
    setHora,
    setOpcionTemporalEscogida,
    setDiaSemana,
    mensajesTemporales,
    setMomentoDia
  };
  return {
    state,
    stateCondiciones,
    events
  };
}
