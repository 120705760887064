import CryptoJS from 'crypto-js';
import jwt from 'jwt-decode';

const key = localStorage.getItem('secret_crypto') as string;

/* Función que desencripta el login
 * @param {any} respuesta objeto con toda la respuesta que contendra un texto encriptado
 *
 * @return {JSON} resultado desencriptado en formato JSON
 */
export function desencriptarLogin(respuesta: any): any {
  if (respuesta.data.result !== undefined) {
    const textoBytes = CryptoJS.AES.decrypt(respuesta.data.result, respuesta.data.uuid);
    const textoDesencriptado = textoBytes.toString(CryptoJS.enc.Utf8);
    respuesta.data.result = JSON.parse(textoDesencriptado);
  }
  return respuesta;
}

/**
 * Función que desencripta "result" y "versiontables" de una respuesta
 * @param {any} respuesta objeto con toda la respuesta que contendra un texto encriptado
 * @param {boolean} isLogin Indica si es para desencriptar la respuesta al hacer login, para usar la key correspondiente
 *
 * @return {JSON} resultado desencriptado en formato JSON
 */
export function desencriptarRespuesta(respuesta: any): any {
  if (respuesta.data.result !== undefined)
    respuesta.data.result = desencriptarTexto(respuesta.data.result);

  if (respuesta.data.versiontables !== undefined)
    respuesta.data.versiontables = desencriptarTexto(respuesta.data.versiontables);
  return respuesta;
}

/**
 * Función que desencripta "result" y "versiontables" de una respuesta
 * @param {any} respuesta objeto con toda la respuesta que contendra un texto encriptado
 * @param {boolean} isLogin Indica si es para desencriptar la respuesta al hacer login, para usar la key correspondiente
 *
 * @return {JSON} resultado desencriptado en formato JSON
 */
export function decryptResponse(respuesta: any): any {
  if (respuesta.result !== undefined) respuesta.result = desencriptarTexto(respuesta.result);

  return respuesta;
}

/**
 * Función que desencripta un texto encriptado (cifrado) con el protocolo AES 256
 * @param {string} textoEncriptado texto encriptado
 *
 * @return {JSON} resultado desencriptado en formato JSON
 */
export function desencriptarTexto(textoEncriptado: string): JSON {
  const textoBytes = CryptoJS.AES.decrypt(
    textoEncriptado,
    key ?? localStorage.getItem('secret_crypto')
  );
  const textoDesencriptado = textoBytes.toString(CryptoJS.enc.Utf8);
  const resultadoDesencriptado: JSON = JSON.parse(textoDesencriptado);
  return resultadoDesencriptado;
}

export function desencriptarPassword(password: string): string {
  const textoBytes = CryptoJS.AES.decrypt(password, key ?? localStorage.getItem('secret_crypto'));
  return textoBytes.toString(CryptoJS.enc.Utf8);
}
export function encriptarPassword(password: string): string {
  // const datosTexto: string = JSON.stringify(password);
  const datosEncriptados = CryptoJS.AES.encrypt(
    password,
    key ?? localStorage.getItem('secret_crypto')
  ).toString();
  return datosEncriptados;
}

/**
 * Función que encripta (cifra) un objeto JSON con el protocolo AES 256
 * @param {JSON} datos Objeto u objetos en formato JSON para encriptar
 *
 * @return {string} Datos encriptados en formato string
 */
export function encriptarJSON(datos: any): any {
  const datosTexto: string = JSON.stringify(datos);
  const datosEncriptados = CryptoJS.AES.encrypt(
    datosTexto,
    key ?? localStorage.getItem('secret_crypto')
  ).toString();
  const objetoEncriptado = { data: datosEncriptados };
  return objetoEncriptado;
}

/**
 * Función que decodifica el token
 * @returns { object } token
 */
export function decodeToken(): any {
  const token: any = localStorage.getItem('token');
  return jwt(token);
}
