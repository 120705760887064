import * as React from 'react';
import { Modulos } from '../../../resources/enums/enumModulo';
import { Rango } from '../../graficas/resources/enums/enumRango';
import { ConfigurableComparativeCard } from '../components/comparativeCard/ConfigurableComparativeCard';
import { ConfEvolutiveGraphCard } from '../components/evolutiveGraphCard/ConfEvolutiveGraphCard';
import { ConfigurableStandardCard } from '../components/standardCard/StandardCard';
import { ConfigurableDistributionWaterCard } from '../components/distributionWaterCard/ConfigurableDistributionWaterCard';
import { AvailableCards } from '../types/typesCardConfigurator';

import { ConfigurableWeatherCard } from '../components/weatherCard/ConfigurableWeatherCard';
import { cardType } from '../../home/homeModulos/resources/enums/enumCardType';
import { CardNames } from './configuratorEnums';
import { Operation } from '../../graficas/resources/enums/enumOperation';

export const defaultControlStates = {
  deshabilitar: true,
  deshabilitarGrafica: true,
  deshabilitarSemaforo: true,
  deshabilitarRango: true,
  operationDisabled: true
};

export const optionsAutoCompleteFrecuencia = [
  { frecuencia: 'Hoy', option: Rango.TODAY },
  { frecuencia: 'Esta semana', option: Rango.THIS_WEEK },
  { frecuencia: 'Este mes', option: Rango.THIS_MONTH },
  { frecuencia: 'Este año', option: Rango.THIS_YEAR }
];

export const actualAgregateOption = { name: 'Valor actual', code: '5', data: Operation.ACTUAL };
export const sumAgregateOption = { name: 'Acumulado', code: '2', data: Operation.SUM };
export const initialOptionsAgregateFunctions = [
  { name: 'Media', code: '1', data: Operation.AVG },
  { name: 'Máximo', code: '3', data: Operation.MAX },
  { name: 'Mínimo', code: '4', data: Operation.MIN }
];

export const allAgregateOptions = [
  actualAgregateOption,
  sumAgregateOption,
  ...initialOptionsAgregateFunctions
];

export const availableConfigurableCards: Array<AvailableCards> = [
  { name: CardNames.STANDARD_CARD, cardTypeId: 1, component: <ConfigurableStandardCard /> },
  { name: CardNames.COMPARATIVE_CARD, cardTypeId: 2, component: <ConfigurableComparativeCard /> },
  { name: CardNames.EVOLUTIVE_CARD, cardTypeId: 3, component: <ConfEvolutiveGraphCard /> }
];

export const specialCards: any = {
  [Modulos['MEDIO AMBIENTE']]: [
    { name: CardNames.WEATHER_CARD, cardTypeId: 4, component: <ConfigurableWeatherCard /> }
  ],
  [Modulos['GESTIÓN DE AGUA POTABLE']]: [
    { name: CardNames.WATER_CARD, cardTypeId: 5, component: <ConfigurableDistributionWaterCard /> }
  ]
};

export const sizeCardType: Record<string, number> = {
  [cardType.ComparativeCard]: 1,
  [cardType.EvolutiveCard]: 1,
  [cardType.WeatherCard]: 1,
  [cardType.WaterCard]: 1
};
