import * as React from 'react';
import { Grid, Box } from '@mui/material';

export function EmptyCard(): JSX.Element {
  return (
    <Grid item xs={6} sm={4} lg={2} maxHeight={'182px'}>
      <Box
        style={{
          borderRadius: '10px',
          height: '166px'
        }}
      ></Box>
    </Grid>
  );
}
