import { AxiosRequestConfig } from 'axios';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get the report data.
 * @param {Array<string>} tiposDispositivos Array of device types code.
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Array<number>} dispositivos Array of ids
 * @param {Array<number>} modulos Array of module numbers
 * @param {Array<etiqueta>} etiquetas
 * @returns {{key: Key; request: AxiosRequestConfig}} key used by swr and axios request config.
 */
export function getConsumptionReportData(): { key: Key; request: AxiosRequestConfig } {
  const key: Key = `${swrServiceRoutes.getConsumptionReportData}`;
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getConsumptionReportData,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  return { key, request };
}
