/**
 * Enumerado con los diferentes tipos de acciones para modificar el State del hook
 * @readonly
 * @enum { string } actions
 * TARJETA
 * @member SET_ID_DEVICE para establecer el device
 * @member SET_OPEN_DIALOG_VIDEO para establecer el openDialogVideo
 * DIALOG VIDEO
 * @member SET_INTERVAL_TIME para establecer el intervalTime
 * @member SET_TITLE para establecer el title
 * @member SET_SUBTITLE para establecer el subtitle
 * @member SET_CANAL para establecer el canal
 * @member SET_IS_FULLSCREEN para establecer el isFullScreen
 * VIDEO STREAM
 * @member SET_STREAM para establecer el stream
 * @member SET_IS_PLAYING para establecer el isPlaying
 * @member SET_IS_DISCONNECT para establecer el isDisconnect
 * VIDEO PLAYBACK
 * @member SET_REPLAY para establecer el replay
 * CONTROLS PLAYBACK
 * @member SET_IS_PAUSE para establecer el isPause
 * VIDEO
 * @member SET_VIDEOPLAYER para establecer el videoPlayer
 * HOME VIDEOVIGILANCIA
 * @member SET_CAMARAS_HOME para establecer el camarasHome
 * @member SET_SHOW_CAMARAS_HOME para establecer el showCamarasHome
 * OPEN-CLOSE DIALOG
 * @member HANDLE_OPEN_CLOSE para ejecutar el handleOpenClose
 */
export enum actions {
  SET_ID_DEVICE = 'setIdDevice',
  SET_OPEN_DIALOG_VIDEO = 'setOpenDialogVideo',
  SET_INTERVAL_TIME = 'setIntervalTime',
  SET_TITLE = 'setTitle',
  SET_SUBTITLE = 'setSubtitle',
  SET_CANAL = 'setCanal',
  SET_IS_FULLSCREEN = 'setIsFullScreen',
  SET_STREAM = 'setStream',
  SET_IS_PLAYING = 'setIsPlaying',
  SET_IS_DISCONNECT = 'setIsDisconnect',
  SET_REPLAY = 'setReplay',
  SET_IS_PAUSE = 'setIsPause',
  SET_VIDEOPLAYER = 'setVideoPlayer',
  SET_CAMARAS_HOME = 'setCamaras',
  SET_SHOW_CAMARAS_HOME = 'setShowCamaras',
  HANDLE_OPEN_CLOSE = 'handleOpenClose'
}
