import axios from 'axios';
import { desencriptarRespuesta } from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path: string;
let respuesta: any;

/**
 * Función que obtiene un tipo de dispositivo en base a un dispositivo
 * @param { strintg } servicio  servicio al que vamos a llamar
 * @param { number } idDispositivo  id del dispositivo por el que tenemos que filtrar
 * @returns { Promise<any } Promesa que contiene un tipo de dispositivo filtrado por dispositivo
 */

export async function tipoDispositivoByDispositivo(
  servicio: string,
  idDispositivo: number
): Promise<any> {
  path = servicio + `/todos/dispositivo/${idDispositivo}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
