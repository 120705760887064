import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  // Tipo Actuaciones Tabla
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  },
  // Tipo Actuaciones Create y Update
  root: {
    width: '100%',
    '& > * + *': {},
    paddingLeft: '20px'
  },
  tituloFormulario: {
    marginTop: '9px'
  },
  cajas: {
    marginBottom: '15px'
  },
  displayBlock: {
    display: 'block'
  },
  styleSpanSection: {
    display: 'block',
    margin: 0,
    padding: 0,
    fontSize: '10px',
    fontWeight: 'lighter'
  },
  titulo: {
    fontVariantCaps: 'all-petite-caps',
    fontWeight: 'bold',
    fontSize: 'medium',
    marginBottom: '2px'
  },
  slider: {
    width: '99%',
    float: 'right'
  }
});
export default useStyles;
