import React, { useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { controlContext } from './resources/interface/interfaceControlContext';
import { useReducerControlPermisos } from './hooks/useReducerControlPermisos';
import { enumComponentes } from '../../resources/enums/enumComponente';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { CardProperties } from '../../components/configurador/types/typesCardConfigurator';
import { enumSeverity } from '../../components/common/snackbar/resources/enums/enumSeverity';
import { action } from '../../hooks/useControlador/resources/enums/enumActions';
import { serviceRoutes } from '../../resources/enums/enumRutasServicios';
import { dictionary_configurator } from '../../resources/enums/plainText';
import { useControlador } from '../../hooks/useControlador/useControlador';
import SnackBarContext from '../SnackBarContext';

/**
 * Este contexto nos permite tener acceso en toda la aplicación al modulo seleccionado, el componente seleccionado, el control seleccionado y establecerlos.
 *
 */

const Context = React.createContext<controlContext>({
  moduloSeleccionado: 0,
  setModuloSeleccionado: useState,
  control: <></>,
  setControl: (element: JSX.Element, tmp?: boolean) => {
    useState(element);
  },
  componentesFiltrados: [],
  tieneAcceso: false,
  comprobarAcceso: (key: enumComponentes) => false,
  page: 1,
  setPage: useState,
  previousComponent: { current: { component: <></>, tmp: false } },
  signal: new AbortController().signal,
  abortRequest: () => {
    ('');
  },
  pendingConfiguratorChanges: { hasChanges: false, changes: [], userId: 0, selectedModule: 0 },
  setPendingConfiguratorChanges: useState,
  openConfiguratorAlert: {
    open: false,
    openMainMenuItem: () => {
      ('');
    }
  },
  handleClickOpenConfiguratorAlert: () => ''
});

ControlContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export function ControlContextProvider({
  children
}: InferProps<typeof ControlContextProvider.propTypes>): JSX.Element {
  const [moduloSeleccionado, setModuloSeleccionado] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(1);
  const [openConfiguratorAlert, setOpenConfiguratorAlert] = React.useState({
    open: false,
    openMainMenuItem: () => {
      ('');
    }
  });
  const { setSnackBar } = React.useContext(SnackBarContext);

  const abortController = new AbortController();
  const { signal } = abortController;
  const previousComponent = React.useRef<{ component: JSX.Element; tmp: boolean }>({
    component: <></>,
    tmp: false
  });

  const { control, setControl, componentesFiltrados, tieneAcceso, comprobarAcceso } =
    useReducerControlPermisos(moduloSeleccionado, previousComponent);
  const { controllerRequest } = useControlador();

  const [pendingConfiguratorChanges, setPendingConfiguratorChanges] = React.useState<{
    hasChanges: boolean;
    changes: Array<CardProperties>;
    userId: number;
    selectedModule: number;
  }>({ hasChanges: false, changes: [], userId: 0, selectedModule: 0 });

  /**
   * Opens an alert if the configurator has changes pending.
   * @param callback Function that will execute if the user accepts.
   */
  const handleClickOpenConfiguratorAlert = (callback: () => void) => {
    if (pendingConfiguratorChanges.hasChanges) {
      setOpenConfiguratorAlert({ open: true, openMainMenuItem: callback });
    } else {
      callback();
    }
  };

  const handleCloseWithoutSave = () => {
    setPendingConfiguratorChanges({
      hasChanges: false,
      changes: [],
      userId: 0,
      selectedModule: 0
    });
    setOpenConfiguratorAlert({
      open: false,
      openMainMenuItem: () => {
        ('');
      }
    });
    openConfiguratorAlert.openMainMenuItem();
  };

  const handleClose = () => {
    setOpenConfiguratorAlert({
      open: false,
      openMainMenuItem: () => {
        ('');
      }
    });
  };

  function saveAndExit() {
    const snackBarContent = {
      text: '',
      severity: enumSeverity.SUCCESS
    };
    controllerRequest(
      {
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: serviceRoutes.SAVE_CARD_CONFIGURATION,
          object: {
            tarjetas: pendingConfiguratorChanges.changes,
            idModulo: pendingConfiguratorChanges.selectedModule,
            idUsuario: pendingConfiguratorChanges.userId
          }
        }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    )
      .then(() => {
        snackBarContent.text = dictionary_configurator.CORRECT_SNACKBAR_SAVED_TEXT;
        snackBarContent.severity = enumSeverity.SUCCESS;
        setPendingConfiguratorChanges({
          hasChanges: false,
          changes: [],
          userId: 0,
          selectedModule: 0
        });
        openConfiguratorAlert.openMainMenuItem();
      })
      .catch(() => {
        snackBarContent.text = dictionary_configurator.INCORRECT_SNACKBAR_TEXT;
        snackBarContent.severity = enumSeverity.ERROR;
      })
      .finally(() => {
        setSnackBar({ open: true, ...snackBarContent });
        handleClose();
      });
  }

  function abortRequest() {
    abortController.abort();
  }

  function ConfiguratorChangesPendingAlert() {
    return (
      <div>
        <Dialog
          open={openConfiguratorAlert.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Cambios pendientes en el configurador'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tienes cambios pendientes en este módulo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={saveAndExit} autoFocus>
              Guardar y salir
            </Button>
            <Button onClick={handleCloseWithoutSave}>Salir sin guardar</Button>
            <Button onClick={handleClose}>Volver</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <Context.Provider
      value={{
        moduloSeleccionado,
        setModuloSeleccionado,
        control,
        setControl,
        componentesFiltrados,
        page,
        setPage,
        tieneAcceso,
        comprobarAcceso,
        previousComponent,
        signal,
        abortRequest,
        pendingConfiguratorChanges,
        setPendingConfiguratorChanges,
        openConfiguratorAlert,
        handleClickOpenConfiguratorAlert
      }}
    >
      {children}
      <ConfiguratorChangesPendingAlert />
    </Context.Provider>
  );
}

export default Context;
