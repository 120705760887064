import * as React from 'react';
import { PropsAlertDialog } from './types';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { dictionary_alertDialog } from 'resources/enums/plainText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function RefactorizedAlertDialog(props: PropsAlertDialog): JSX.Element {
  const {
    title,
    openAlert,
    setOpenAlert,
    mainText = null,
    secondaryText = null,
    onConfirm,
    showCancelButton
  } = props;

  const closeAlertDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = () => {
    setOpenAlert(false);
    onConfirm();
  };

  return (
    <Dialog
      open={openAlert}
      onClose={closeAlertDialog}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
    >
      <InfoOutlinedIcon
        color="secondary"
        style={{ fontSize: '70', display: 'block', margin: 'auto' }}
      />
      <DialogTitle id="confirm-dialog" style={{ alignSelf: 'center' }}>
        {title}
      </DialogTitle>
      {mainText ? (
        <DialogContentText style={{ margin: '8px 24px' }}>{mainText}</DialogContentText>
      ) : (
        <></>
      )}
      {secondaryText ? <DialogContent>{secondaryText}</DialogContent> : <></>}
      <DialogActions style={{ padding: '24px 24px 8px 8px' }}>
        <Button variant="contained" onClick={handleClick} color="secondary">
          {showCancelButton ? dictionary_alertDialog.ACEPTAR : dictionary_alertDialog.CERRAR}
        </Button>
        {showCancelButton ? (
          <Button variant="contained" onClick={closeAlertDialog} color="secondary">
            {dictionary_alertDialog.CANCELAR}
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
}
