import React from 'react';

import { ReactComponent as iconLuminaria } from '../../../../../resources/icons/iconsTiposDispositivo/luminaria.svg';
import { ReactComponent as iconCaudalimetro } from '../../../../../resources/icons/iconsTiposDispositivo/caudalimetro.svg';
import { ReactComponent as iconSensor } from '../../../../../resources/icons/iconsTiposDispositivo/sensor.svg';
import { ReactComponent as iconCuadroElectrico } from '../../../../../resources/icons/iconsTiposDispositivo/cuadro_electrico.svg';
import { ReactComponent as iconEstacionMeteorologica } from '../../../../../resources/icons/iconsTiposDispositivo/estacionMeteorologica.svg';
import { ReactComponent as iconSensorHumedad } from '../../../../../resources/icons/iconsTiposDispositivo/sensorHumedad.svg';
import { ReactComponent as iconElectrovalvula } from '../../../../../resources/icons/iconsTiposDispositivo/electrovalvula.svg';
import { ReactComponent as iconSensorResiduos } from '../../../../../resources/icons/iconsTiposDispositivo/sensorResiduos.svg';
import { ReactComponent as iconMetering } from '../../../../../resources/icons/iconsTiposDispositivo/metering.svg';
import { ReactComponent as iconWifi } from '../../../../../resources/icons/iconsTiposDispositivo/wifi.svg';
import { ReactComponent as iconParking } from '../../../../../resources/icons/iconsTiposDispositivo/parking.svg';
import { ReactComponent as iconAnemometro } from '../../../../../resources/icons/iconsTiposDispositivo/anemometro.svg';
import { ReactComponent as iconPluviometro } from '../../../../../resources/icons/iconsTiposDispositivo/pluviometro.svg';
import { ReactComponent as iconEnergiaSolar } from '../../../../../resources/icons/iconsTiposDispositivo/energiaSolar.svg';
import { ReactComponent as iconGateway } from '../../../../../resources/icons/iconsTiposDispositivo/gateway.svg';
import { ReactComponent as iconActuador } from '../../../../../resources/icons/iconsTiposDispositivo/actuador.svg';
import { ReactComponent as iconAemet } from '../../../../../resources/icons/iconsTiposDispositivo/aemet.svg';
import { ReactComponent as iconContadorFiscal } from '../../../../../resources/icons/iconsTiposDispositivo/contador_fiscal.svg';
import { ReactComponent as iconCamara } from '../../../../../resources/icons/iconsTiposDispositivo/camara.svg';
import { ReactComponent as iconCamaraPtz } from '../../../../../resources/icons/iconsTiposDispositivo/camaraPtz.svg';
import { ReactComponent as iconVideograbador } from '../../../../../resources/icons/iconsTiposDispositivo/videograbador.svg';
import { ReactComponent as iconCalidadDelAgua } from '../../../../../resources/icons/iconsTiposDispositivo/calidadDelAguaAbastecimiento.svg';
import { ReactComponent as iconContaminacionEntrada } from '../../../../../resources/icons/iconsTiposDispositivo/contaminacionEntrada.svg';
import { ReactComponent as iconContaminacionSalida } from '../../../../../resources/icons/iconsTiposDispositivo/contaminacionSalida.svg';
import { ReactComponent as iconCaudalimetroEntradaCaudal } from '../../../../../resources/icons/iconsTiposDispositivo/caudalimetroEntradaCaudal.svg';
import { ReactComponent as iconCaudalimetroCaudal } from '../../../../../resources/icons/iconsTiposDispositivo/caudalimetroCaudal.svg';
import { ReactComponent as iconSondaNivelAbastecimiento } from '../../../../../resources/icons/iconsTiposDispositivo/sondaNivelAbastecimiento.svg';
import { ReactComponent as iconEstacionMedioAmb } from '../../../../../resources/icons/iconsTiposDispositivo/estacionMedioAmb.svg';
import { ReactComponent as iconColmena } from '../../../../../resources/icons/iconsTiposDispositivo/colmena.svg';
import { SvgIcon, useTheme } from '@mui/material';

export const icon: any = {
  luminaria: iconLuminaria,
  caudalimetro: iconCaudalimetro,
  sensor: iconSensor,
  cuadroElectrico: iconCuadroElectrico,
  estacionMeteorologica: iconEstacionMeteorologica,
  sensorHumedad: iconSensorHumedad,
  electrovalvula: iconElectrovalvula,
  sensorResiduos: iconSensorResiduos,
  metering: iconMetering,
  wifi: iconWifi,
  parking: iconParking,
  anemometro: iconAnemometro,
  pluviometro: iconPluviometro,
  energiaSolar: iconEnergiaSolar,
  gateway: iconGateway,
  actuador: iconActuador,
  aemet: iconAemet,
  contadorFiscal: iconContadorFiscal,
  camara: iconCamara,
  ptz: iconCamaraPtz,
  videograbador: iconVideograbador,
  calidadDelAguaAbastecimiento: iconCalidadDelAgua,
  sensorContaminacionEntrada: iconContaminacionEntrada,
  sensorContaminacionSalida: iconContaminacionSalida,
  caudalimetroEntradaCaudal: iconCaudalimetroEntradaCaudal,
  caudalimetroCaudal: iconCaudalimetroCaudal,
  sondaNivelDeAbastecimiento: iconSondaNivelAbastecimiento,
  estacionMedioambiental: iconEstacionMedioAmb,
  colmena: iconColmena
};

export function MapIcons(props: {
  icono: string;
  animacion?: string;
  size?: 'medium' | 'large' | 'small';
  index?: any;
  id?: string;
}): JSX.Element {
  const { size = 'large' } = props;
  const theme = useTheme();

  return (
    <>
      <SvgIcon
        component={icon[props.icono]}
        inheritViewBox
        htmlColor={theme.palette.secondary.main}
        fontSize={size}
        id={[props.icono]}
      />
    </>
  );
}
