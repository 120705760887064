import axios from 'axios';
import {
  desencriptarLogin,
  desencriptarRespuesta,
  encriptarJSON
} from '../../../functions/encryption';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

const serviceURL: string = process.env.REACT_APP_URL_SERVICES + 'login/smarttelia';
const serviceRequestCode = serviceURL + '/restablecerPassword';
const serviceVerifyCode = serviceURL + '/verificarCodigoPassword';
const serviceRestorePassword = process.env.REACT_APP_URL_SERVICES + 'usuarios/restablecerpassword/';
let response: any;

/**
 * Servicio para obtener hacer login,
 * @param {String} name Nombre del usuario
 * @param {String} pass Contraseña del usuario
 * @return {Promise<Object>} Respuesta de Axios
 *
 */
export async function hacerLogin(name: string, pass: string): Promise<any> {
  const usuario = {
    nombreUsuario: name,
    password: pass,
    guidCliente: process.env.REACT_APP_GUID_CLIENTE
  };

  try {
    response = await axios.post(serviceURL, usuario, { timeout: 10000 });
    return desencriptarLogin(response);
  } catch (exception) {
    return exception;
  }
}

/**
 * Servicio para pedir el codigo de verificación al correo que se envía para restablecer la contraseña del usuario
 * @param {string} email Email del usuario
 * @returns {Promise<any>} Respuesta de la Petición
 */
export async function servicioPedirCodigo(nombreUsuario: string, email: string): Promise<any> {
  const objeto = encriptarJSON({
    nombreUsuario: nombreUsuario,
    email: email,
    guidCliente: process.env.REACT_APP_GUID_CLIENTE
  });

  try {
    response = await axios.post(serviceRequestCode, objeto, { timeout: 5000 });
    return desencriptarRespuesta(response);
  } catch (exception) {
    return exception;
  }
}

/**
 * Servicio para verificar el codigo que ha recibido el usuario al correo para restablecer la contraseña del usuario
 * @param {String} idUsuario id del usuario
 * @param {String} verifyCode codigo para de verificación del usuario recibido al correo
 * @returns {Promise<any>} Respuesta de la Petición
 */
export async function servicioVerificarCodigo(idUsuario: number, verifyCode: string): Promise<any> {
  const objeto = encriptarJSON({
    idUsuario: idUsuario,
    codeReset: verifyCode
  });

  try {
    response = await axios.post(serviceVerifyCode, objeto, { timeout: 5000 });
    return desencriptarRespuesta(response);
  } catch (exception) {
    return exception;
  }
}

/**
 * Servicio para restablecer la contraseña del usuario
 * @param {String} idUsuario id del usuario
 * @param {String} nuevaPassword nueva contraseña
 * @returns {Promise<any>} Respuesta de la Petición
 */
export async function servicioRestablecerPassword(
  idUsuario: number,
  nuevaPassword: string
): Promise<any> {
  const objeto = encriptarJSON({ password: nuevaPassword });

  try {
    response = await axios.put(serviceRestorePassword + idUsuario, objeto, {
      timeout: 5000,
      headers: getHeaders(dictionary_entornos.GESTION)
    });
    return desencriptarRespuesta(response);
  } catch (exception) {
    return exception;
  }
}
