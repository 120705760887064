import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Card } from '@mui/material';
import { formateoFechaGrafico } from '../../graficas/functions/funciones';
import { Tarjeta } from './common/Tarjeta';
import { useReducerHome } from './hooks/useReducerHome';
import {
  dictionary_generic,
  dictionary_home,
  dictionary_unidades
} from '../../../resources/enums/plainText';
import salidaSol from '../../../resources/icons/iconosTiempo/salidaSol.png';
import moon from '../../../resources/icons/iconosTiempo/moon.png';
import { useStyles } from './styles';
import { WeatherColors } from '../../../resources/enums/enumColores';
import { HomeGis } from './HomeGis';
import { Clima } from './resources/interfaces/interfaceClima';
import { BackdropTarjetasModulos } from '../homeModulos/common/BackdropTarjetasModulos';

import { WeatherAlerts } from './resources/types/objReducerHome';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const DEFAULT_UV = {
  color: WeatherColors.VERY_POOR,
  text: 'Extremo'
};

const uvInfo: any = {
  0: { color: 'inherit', text: 'Mínimo' },
  1: { color: WeatherColors.GOOD, text: 'Bajo' },
  2: { color: WeatherColors.GOOD, text: 'Bajo' },
  3: { color: WeatherColors.FAIR, text: 'Moderado' },
  4: { color: WeatherColors.FAIR, text: 'Moderado' },
  5: { color: WeatherColors.FAIR, text: 'Moderado' },
  6: { color: WeatherColors.MODERATE, text: 'Alto' },
  7: { color: WeatherColors.MODERATE, text: 'Alto' },
  8: { color: WeatherColors.POOR, text: 'Muy Alto' },
  9: { color: WeatherColors.POOR, text: 'Muy Alto' },
  10: { color: WeatherColors.POOR, text: 'Muy Alto' }
};

const initial_weatherAlert: { open: boolean; weatherAlert: WeatherAlerts | null } = {
  open: false,
  weatherAlert: null
};

export function Home(): JSX.Element {
  const formateoFecha = formateoFechaGrafico.format('%B de %Y');
  const formateoPrevision = formateoFechaGrafico.format('%e %a');
  const fechaActual = formateoFecha(new Date(Date.now()));
  const { state } = useReducerHome();
  const { clima, airPollution, openAllAlertsBackdrop, openClimateBackdrop, allGisDevices, alerts } =
    state;
  const fecha = new Date(Date.now());
  const hoy: number = fecha.getDate();
  const classes = useStyles();
  const theme = useTheme();
  const { current, dailyForecast: dailyPrevision } = clima as Clima;

  const [weatherAlertDialog, setWeatherAlertDialog] = React.useState(initial_weatherAlert);

  function handleWeatherAlertDialog(weatherAlert: WeatherAlerts): void {
    setWeatherAlertDialog({ open: true, weatherAlert });
  }

  function handleCloseWeatherAlertDialog() {
    setWeatherAlertDialog({ open: false, weatherAlert: null });
  }

  return (
    <>
      <Box>
        <Grid item xs={12} className={classes.gridMapa3d}>
          <Card elevation={0} className={classes.box} sx={{ position: 'relative' }}>
            {!openAllAlertsBackdrop ? (
              <HomeGis markers={allGisDevices} />
            ) : (
              <BackdropTarjetasModulos open={openAllAlertsBackdrop} />
            )}
          </Card>
        </Grid>
      </Box>

      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} container spacing={2}>
          <Tooltip title={dictionary_home.PREVISION_METEO_SEMANA} enterTouchDelay={0}>
            <Grid item xs={12} sm={12} md={9} lg={6} xl={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  border: '2px solid rgb(224, 224, 224)',
                  borderRadius: '15px',
                  width: '100%',
                  height: '166px',
                  padding: '5px',
                  position: 'relative'
                }}
              >
                {!openClimateBackdrop ? (
                  dailyPrevision.map((item) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      key={item.unixUTCDay}
                      flexDirection={'column'}
                      sx={{ minHeight: '102px' }}
                    >
                      <Typography className={classes.typographyPrevision}>
                        {formateoPrevision(new Date(item.unixUTCDay * 1000))}
                      </Typography>
                      <svg className={classes.imgTiempoMapa}>
                        <image xlinkHref={item.weather.icono} />
                      </svg>
                      <Typography className={classes.typographyMaxMin}>
                        <b className={classes.bMaxMin}>
                          {item.tempMAX}
                          {dictionary_unidades.grados}
                        </b>{' '}
                        {item.tempMIN}
                        {dictionary_unidades.grados}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <BackdropTarjetasModulos open={openClimateBackdrop} />
                )}
              </Box>
            </Grid>
          </Tooltip>

          <Tooltip title={dictionary_home.FECHA} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography className={classes.typographyHoy}>{hoy}</Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {fechaActual}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.SENSACION_TERMICA} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography className={classes.typographyNumerosTarjetas}>
                  {!openClimateBackdrop ? (
                    current.feelLike
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                  {dictionary_unidades.grados}
                </Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.SENSACION_TERMICA.toUpperCase()}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.SALIDA_DEL_SOL} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} justifyContent={'end'}>
                <Typography className={classes.typographyClima} color={'primary'}>
                  {!openClimateBackdrop ? (
                    ('0' + new Date(clima?.current?.sunrise * 1000).getHours()).slice(-2) +
                    ':' +
                    ('0' + new Date(clima?.current?.sunrise * 1000).getMinutes()).slice(-2)
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                </Typography>

                <Box display="flex" alignItems="end" justifyContent={'space-between'}>
                  <img src={salidaSol} className={classes.imgSalidaSolLuna} />
                  <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                    {dictionary_home.SALIDA_DEL_SOL}
                  </Typography>
                </Box>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.PUESTO_DEL_SOL} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} justifyContent={'end'}>
                <Typography className={classes.typographyClima} color={'primary'}>
                  {!openClimateBackdrop ? (
                    ('0' + new Date(clima?.current?.sunset * 1000).getHours()).slice(-2) +
                    ':' +
                    ('0' + new Date(clima?.current?.sunset * 1000).getMinutes()).slice(-2)
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                </Typography>

                <Box display="flex" alignItems="end" justifyContent={'space-between'}>
                  <img src={moon} className={classes.imgSalidaSolLuna} />
                  <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                    {dictionary_home.PUESTO_DEL_SOL.toUpperCase()}
                  </Typography>
                </Box>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.HUMEDAD} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography className={classes.typographyNumerosTarjetas}>
                  {!openClimateBackdrop ? (
                    current?.humidity
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                  {dictionary_unidades.porcentaje}
                </Typography>

                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.HUMEDAD.toUpperCase()}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.VELOCIDAD_VIENTO} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography className={classes.typographyNumerosTarjetas}>
                  {!openClimateBackdrop ? (
                    current?.wind
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                </Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.VELOCIDAD_VIENTO.toUpperCase()}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip title={dictionary_home.PROBABILIDAD_LLUVIA} enterTouchDelay={0}>
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography className={classes.typographyNumerosTarjetas}>
                  {!openClimateBackdrop ? (
                    current.pop
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                  {dictionary_unidades.porcentaje}
                </Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.PROBABILIDAD_LLUVIA}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip
            title={`${dictionary_home.RADIACION_ULTRAVIOLETA}: ${
              uvInfo[current.uv] ? uvInfo[current.uv].text : DEFAULT_UV.text
            }`}
            enterTouchDelay={0}
          >
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography
                  style={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    color: uvInfo[current.uv] ? uvInfo[current.uv].color : DEFAULT_UV.color,
                    position: 'relative'
                  }}
                >
                  {!openClimateBackdrop ? (
                    current.uv
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                </Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.INDICE_UV}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>
          <Tooltip
            title={`${dictionary_home.INDICE_CALIDAD_DEL_AIRE}: ${
              airPollution.value ?? dictionary_generic.NO_DATA
            }`}
            enterTouchDelay={0}
          >
            <Grid item xs={6} sm={6} md={3} xl={2}>
              <Tarjeta flexDirection={'column'} className={classes.tarjeta}>
                <Typography
                  style={{
                    fontSize: airPollution.aqi ? '2rem' : '0.9rem',
                    fontWeight: 'bold',
                    color: airPollution.color ?? theme.palette.primary.main,
                    position: 'relative'
                  }}
                >
                  {!openClimateBackdrop ? (
                    airPollution.aqi ?? dictionary_generic.NO_DATA
                  ) : (
                    <BackdropTarjetasModulos open={openClimateBackdrop} />
                  )}
                </Typography>
                <Typography paragraph variant={'body1'} className={classes.typographyTarjetas}>
                  {dictionary_home.ICA}
                </Typography>
              </Tarjeta>
            </Grid>
          </Tooltip>

          <Grid item xs={6} sm={6} md={3} xl={2}>
            <Tarjeta flexDirection={'column'} className={classes.tarjeta} justifyContent={'start'}>
              {alerts ? (
                <List
                  sx={{ width: '100%', maxHeight: '150px', padding: '0', overflow: 'auto' }}
                  aria-labelledby="weather-alert-list"
                >
                  {alerts?.map((item) => (
                    <Tooltip
                      title={`Alerta ${
                        item.alertColor.text
                      } por ${item.alertIcon.text.toLowerCase()}`}
                      enterTouchDelay={0}
                      key={`${item.start} ${item.event}`}
                    >
                      <ListItemButton
                        sx={{ padding: '0' }}
                        onClick={() => handleWeatherAlertDialog(item)}
                      >
                        <ListItemIcon
                          sx={{
                            height: '35px',
                            width: '35px',
                            backgroundColor: `${item.alertColor.color}`,
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '25px',
                            marginRight: '5px'
                          }}
                        >
                          <SvgIcon
                            component={item.alertIcon.icon as any}
                            fontSize="inherit"
                            htmlColor={'#fff'}
                            viewBox="20 -5 24 76"
                            style={{ width: '100%', height: '100%' }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: '700', fontSize: '0.4rem' }}
                            >
                              {item.alertIcon.text}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="caption" sx={{ fontSize: '0.37rem' }}>{`${format(
                              new Date(item.start * 1000),
                              'dd/MM/yyyy p',
                              { locale: es }
                            )} - ${format(new Date(item.end * 1000), 'dd/MM/yyyy p', {
                              locale: es
                            })}`}</Typography>
                          }
                          disableTypography
                        />
                      </ListItemButton>
                    </Tooltip>
                  ))}
                </List>
              ) : !openClimateBackdrop ? (
                <>🧘‍♀️ Vive en paz, pasa la vida en calma</>
              ) : (
                <></>
              )}
            </Tarjeta>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={weatherAlertDialog.open}
        maxWidth={'xs'}
        sx={{ borderRadius: '15px' }}
        className="weatherAlertDialog"
      >
        {weatherAlertDialog.weatherAlert ? (
          <>
            <DialogTitle
              sx={{
                backgroundColor: `${weatherAlertDialog.weatherAlert.alertColor.color}`,
                color: 'white',
                minHeight: '4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start'
              }}
              component={'div'}
            >
              <Typography variant="h5" fontWeight={'bold'} marginTop={'10px'}>{`Alerta ${
                weatherAlertDialog.weatherAlert.alertColor.text
              } por ${weatherAlertDialog.weatherAlert.alertIcon.text.toLowerCase()}`}</Typography>
            </DialogTitle>
            <Box position={'relative'} sx={{ paddingTop: '2.2rem' }}>
              <Grid
                position={'absolute'}
                bottom={'0'}
                top={'-1.6rem'}
                right={'0'}
                left={'0'}
                display={'flex'}
                container
                justifyContent={'center'}
              >
                <Grid item xs={2} paddingTop={'0.7rem'}>
                  <Paper
                    elevation={3}
                    sx={{
                      height: '55px',
                      width: '55px',
                      backgroundColor: `${weatherAlertDialog.weatherAlert.alertColor.color}`,
                      borderRadius: '30px !important',
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: '25px',
                      marginRight: '5px',
                      border: 'white 3px solid'
                    }}
                  >
                    <SvgIcon
                      component={weatherAlertDialog.weatherAlert.alertIcon.icon}
                      fontSize="inherit"
                      htmlColor={'#fff'}
                      viewBox="20 -5 24 76"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={9} sx={{ backgroundColor: 'white', borderRadius: '15px' }}>
                  <Paper elevation={3} sx={{ borderRadius: '15px', padding: '0.5rem 0.9rem' }}>
                    <Typography fontWeight={'bold'} fontSize={'0.45rem'}>
                      Duración
                    </Typography>
                    <Typography fontWeight={'bold'} fontSize={'0.4rem'}>
                      {format(new Date(weatherAlertDialog.weatherAlert.start * 1000), 'PPPP p', {
                        locale: es
                      })}
                    </Typography>
                    <Typography fontWeight={'bold'} fontSize={'0.4rem'}>
                      {format(new Date(weatherAlertDialog.weatherAlert.end * 1000), 'PPPP p', {
                        locale: es
                      })}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
            <DialogContent>
              <Typography gutterBottom paragraph>
                {weatherAlertDialog.weatherAlert.description}
              </Typography>
              <Typography variant="caption" color={'light'}>
                {weatherAlertDialog.weatherAlert.sender_name}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseWeatherAlertDialog} variant="outlined">
                Cerrar
              </Button>
            </DialogActions>
          </>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
}
