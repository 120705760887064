import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { PropsGraficas } from '../graficas/resources/interfaces/interfacePropsGraficas';
import { useTheme } from '@mui/material/styles';

const DEFAULT_COLORS = ['#f892ff', '#ffaf92', '#b6ff92', '#92ffeb'];

function hexToRGB(hex: any) {
  let r = '0',
    g = '0',
    b = '0';

  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }

  let hr = +r,
    hg = +g,
    hb = +b;

  // Make r, g, and b fractions of 1
  hr /= 255;
  hg /= 255;
  hb /= 255;

  // Find greatest and smallest channel values

  const cmin = Math.min(hr, hg, hb),
    cmax = Math.max(hr, hg, hb),
    delta = cmax - cmin;
  let hue = 0,
    saturation = 0,
    lightness = 0;

  if (delta == 0) hue = 0;
  else if (cmax == hr) hue = ((hg - hb) / delta) % 6;
  else if (cmax == hg) hue = (hb - hr) / delta + 2;
  else hue = (hr - hg) / delta + 4;

  hue = Math.round(hue * 60);

  if (hue < 0) hue += 360;

  lightness = (cmax + cmin) / 2;
  saturation = delta == 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));
  saturation = +(saturation * 100).toFixed(1);
  lightness = +(lightness * 100 + 10).toFixed(1);

  const colors = 'hsl(' + hue + ',' + saturation + '%,' + lightness + '%)';

  // for (let index = 0; index < units; index++) {
  //   lightness = index ? +(lightness + 10).toFixed(1) : lightness;
  //   colors.push('hsl(' + hue + ',' + saturation + '%,' + lightness + '%)');
  // }

  return colors;
}

export function MultipleYaxisChart(props: {
  title: string;
  labels: string[];
  datos: Array<PropsGraficas>;
  max: number;
  showDetails: boolean;
  titleYAxis?: string;
  limitesGrafico?: any;
  idChart?: string;
  unid?: string;
}): JSX.Element {
  const {
    title,
    labels,
    datos,
    titleYAxis,
    max,
    showDetails = true,
    idChart = 'chart',
    unid = ''
  } = props;
  const theme = useTheme();
  const lineColors =
    datos.length === 2
      ? [theme.palette.secondary.main, theme.palette.primary.main]
      : DEFAULT_COLORS.slice(0, datos.length / 2);

  const colors: Array<string> = [...lineColors, ...lineColors.map((item) => hexToRGB(item))];
  const height = props.limitesGrafico ? props.limitesGrafico.alto : 350;

  const lineWidth = datos.map((dato, index) => (index + 1 > datos.length / 2 ? 1 : 8));

  const options: ApexCharts.ApexOptions = {
    title: {
      text: showDetails ? title : '',
      align: 'left',
      offsetX: 110
    },
    chart: {
      id: idChart,
      height: 350,
      type: 'line',
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: { width: lineWidth, lineCap: 'round', curve: 'smooth' },
    xaxis: {
      categories: labels,
      type: 'category'
    },
    colors: colors,
    yaxis: {
      title: {
        text: showDetails ? titleYAxis : ''
      },
      min: 0,
      max: max,
      tickAmount: 4
    },
    tooltip: {
      followCursor: true
      // y: datos.map(() => {
      //   return {
      //     formatter: function (y: number, x: any) {
      //       if (y !== null && y !== undefined) {
      //         return `${y + ' ' + unid}`;
      //       }
      //       return '';
      //     }
      //   };
      // })
    },
    legend: {
      show: showDetails,
      horizontalAlign: 'left',
      offsetX: 40,
      width: 0
    }
  };

  return (
    <div id={idChart} style={{ marginTop: `15px` }}>
      <ReactApexChart options={options} series={[...datos]} type="line" height={height} />
    </div>
  );
}
