import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

/**
 * Este contexto nos permite establecer un error en cualquier parte de la aplicacion
 * Aunque ahora solo se establece en el hook useHasHerror y se sirve en el dialogo de error
 *
 */

interface hasError {
  isError: boolean;
  isNetworkError?: boolean;
  countErrors: number;
}

interface errorContext {
  hasError: hasError;
  setHasError: Dispatch<SetStateAction<hasError>>;
}

const ErrorContext = createContext<errorContext>({
  hasError: { isError: false, countErrors: 0 },
  setHasError: useState
});

ErrorContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export function ErrorContextProvider({
  children
}: InferProps<typeof ErrorContextProvider.propTypes>): JSX.Element {
  const [hasError, setHasError] = useState<hasError>({ isError: false, countErrors: 0 });

  return (
    <ErrorContext.Provider
      value={{
        hasError,
        setHasError
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
}
export default ErrorContext;
