import React from 'react';
import { GruposCRU } from './GruposCRU';
import { grupo } from './resources/interfaces/interfaceGrupo';

export function GruposManager(props: { grupoSeleccionado?: grupo }): JSX.Element {
  return (
    <>
      {props.grupoSeleccionado !== undefined ? (
        <GruposCRU grupoSeleccionado={props.grupoSeleccionado} />
      ) : (
        <GruposCRU />
      )}
    </>
  );
}
