import React from 'react';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@mui/material';

import { TextBoxTelia } from '../common/textboxTelia/TextBoxTelia';
import { TipoCondicionTemporal } from '../reglas/resources/enums/enumTipoCondicionTemporal';
import useStylesTarjetas from '../reglas/stylesTarjetas';
import { enumOperadores } from '../reglas/resources/enums/enumOperadores';

export function TeliaMostrarCondicion(props: {
  state: any;
  events: any;
  stateCondiciones: any;
}): JSX.Element {
  const classes = useStylesTarjetas();
  const { stateCondiciones, events } = props;

  let texto = '';

  if (stateCondiciones.condicionActiva.canal) {
    //Si es una condición de análisis de vídeo, el texto es diferente
    if (stateCondiciones.condicionActiva.operador.id === enumOperadores.INCLUYE) {
      texto = `Esta regla se ejecuta cuando la cámara 
      ${stateCondiciones.condicionActiva.dispositivo.nombre} 
      registra que ${
        stateCondiciones.condicionActiva.canal.nombre
      } ${events.convertirOperadorAFrase(
        stateCondiciones.condicionActiva.operador.operador
      )} los valores ${stateCondiciones.condicionActiva.valor}`;
    } else {
      texto = `Esta regla se ejecuta cuando el dispositivo 
      ${stateCondiciones.condicionActiva.dispositivo.nombre} 
      tiene ${stateCondiciones.condicionActiva.canal.nombre} ${events.convertirOperadorAFrase(
        stateCondiciones.condicionActiva.operador.operador
      )} ${stateCondiciones.condicionActiva.valor}`;
    }
  }
  if (stateCondiciones.condicionActiva.tipoTemporal) {
    texto = events.mensajesTemporales[
      stateCondiciones.condicionActiva.tipoTemporal as TipoCondicionTemporal
    ](stateCondiciones.condicionActiva);
  }
  if (stateCondiciones.condicionActiva.nombreCanal) {
    texto = `Esta regla se ejecuta cuando todos los dispositivos del grupo "${
      stateCondiciones.condicionActiva.grupo.codigo
    }"
    tienen el canal ${
      stateCondiciones.condicionActiva.nombreCanal
    } ${events.convertirOperadorAFrase(stateCondiciones.condicionActiva.operador.operador)} ${
      stateCondiciones.condicionActiva.valor
    }`;
  }

  return (
    <Dialog open={stateCondiciones.isInfoCondicionOpen} onClose={events.cerrarInfoCondicion}>
      <DialogTitle>
        Información de condición
        <IconButton
          aria-label="close"
          onClick={events.cerrarInfoCondicion}
          className={classes.botonCerrarDialogo}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextBoxTelia texto={texto} />
      </DialogContent>
    </Dialog>
  );
}
