import { timeFormatLocale } from 'd3-time-format';
import { Rango } from '../resources/enums/enumRango';
import { DatosGrafica } from '../resources/interfaces/interfaceDatosGraficas';
import { Frecuencia } from '../resources/types/types';
import {
  addDays,
  addMonths,
  getDate,
  getDay,
  getDaysInMonth,
  getHours,
  getMonth,
  startOfWeek,
  startOfYear,
  differenceInDays,
  addHours
} from 'date-fns';
import { DatosComparativos } from '../resources/interfaces/interfaceDatosComparativos';
import { Group } from '../resources/enums/enumGroup';

/**
 * Devuelve un array con todos los id.
 * @param datos
 * @returns Array<number>
 */
export const getId = (datos: any): Array<number> =>
  datos.length ? datos.map(({ id }: { id: number }): number => id) : null;

// Formateo de las fechas para los componentes de los gráficos (tooltip,ejeX,ejeY...)
export const formateoFechaGrafico = timeFormatLocale({
  dateTime: '%A, %e %B %Y г. %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dec']
});

export function ajustarFecha(frecuencia: Frecuencia) {
  const formatos = {
    tooltip: '',
    ejeX: '',
    ticks: 0
  };

  switch (frecuencia) {
    case Rango.LAST_24H:
      formatos.tooltip = '%b %d, %H:%M h';
      formatos.ejeX = '%H:%Mh';
      formatos.ticks = 5;
      break;
    case Rango.WEEKLY:
      formatos.tooltip = '%d %B';
      formatos.ejeX = '%d %B';
      formatos.ticks = 7;
      break;
    case Rango.MONTHLY:
      formatos.tooltip = '%d %B';
      formatos.ejeX = '%d';
      formatos.ticks = 20;
      break;
    case Rango.ANNUALLY:
      formatos.tooltip = '%B';
      formatos.ejeX = '%B';
      formatos.ticks = 3;
      break;
    default:
      break;
  }
  return formatos;
}

/**
 * Formatea los datos dependiendo del tipo de medida, por ejemplo, cuando es de tipo "Distancia" los datos deben mostrarse como : (100 - valor)
 * @param datos
 * @param measure
 */
export function formateaDatosFinales(datos: DatosGrafica[]) {
  datos.forEach((element) => {
    element.valor = 100 - element.valor;
    element.unidad = '%';
  });
  return datos;
}
/**
 * Esta función genera los Labels necesarios para el gráfico dependiendo del rango y además genera las condiciones necesarias para comprobar
 * si existen datos en ese intervalo de tiempo.
 * @param range
 * @returns
 */
export function getLabels(range: Rango): {
  labels: string[];
  condition: string;
  formateoFecha: (date: Date) => string;
  getLastValue: (d: Date) => number;
} {
  function formatDate(range: Rango) {
    switch (range) {
      case Rango.THIS_YEAR:
        return formateoFechaGrafico.format('%b');
      case Rango.THIS_MONTH:
        return formateoFechaGrafico.format('%_d');
      case Rango.TODAY:
        return formateoFechaGrafico.format('%Hh');
      default:
      case Rango.THIS_WEEK:
        return formateoFechaGrafico.format('%A');
    }
  }

  const formateoFecha = formatDate(range);
  const arrLabel: {
    labels: string[];
    condition: string;
    formateoFecha: (date: Date) => string;
    getLastValue: (d: Date) => any;
  } = {
    labels: [],
    condition: '',
    formateoFecha,
    getLastValue: (d: Date) => ''
  };
  let date = new Date();

  const rangeValues: any = {
    [Rango.TODAY]: {
      values: 24,
      foo: (i: number) => {
        arrLabel.labels.push(i < 10 ? `0${i}h` : `${i}h`);
      },
      startLoop: 0,
      condition: 'fecha',
      getLastValue: (d: Date) => getHours(d)
    },
    [Rango.THIS_WEEK]: {
      values: 7,
      foo: (i: number) => {
        if (i) {
          date = addDays(date, 1);
        } else {
          date = startOfWeek(addDays(date, i), { weekStartsOn: 1 });
        }
        arrLabel.labels.push(formateoFecha(date));
      },
      startLoop: 0,
      condition: 'fecha',
      getLastValue: (d: Date) => getDay(startOfWeek(addDays(d, 1), { weekStartsOn: 1 }))
    },
    [Rango.THIS_MONTH]: {
      //Se le suma 1 al empezar los días en 1,2...
      values: getDaysInMonth(new Date()) + 1,
      foo: (i: number) => {
        arrLabel.labels.push(i.toString());
      },
      startLoop: 1,
      condition: 'fecha',
      getLastValue: (d: Date) => getDate(d)
    },
    [Rango.THIS_YEAR]: {
      values: 12,
      foo: (i: number) => {
        date = startOfYear(date);
        arrLabel.labels.push(formateoFecha(addMonths(date, i)));
      },
      startLoop: 0,
      condition: 'fecha',
      getLastValue: (d: Date) => getMonth(d)
    }
  };

  const loop = {
    ...rangeValues[range]
  };
  arrLabel.condition = rangeValues[range].condition;
  arrLabel.getLastValue = rangeValues[range].getLastValue;

  for (loop.startLoop; loop.startLoop < loop.values; loop.startLoop++) {
    loop.foo(loop.startLoop);
  }

  return arrLabel;
}

/**
 * Función que formatea todas las fechas que se usan para las gráficas, ya que estas se encuentran en UTC y se deben mostrar en la zona horaria del usuario
 * @param respuesta
 * @returns respuesta con las fechas formateadas
 */
export const formatearFechas = (respuesta: {
  data: { result: DatosComparativos[] | undefined };
}): { data: { result: DatosComparativos[] | undefined } } => {
  if (respuesta.data.result !== undefined) {
    const newResult: DatosComparativos[] = [];
    respuesta.data.result.forEach((dispositivo) => {
      const newDatos: DatosGrafica[] = [];
      dispositivo.datos.forEach((dato) => {
        const newDate = new Date((dato.fecha as string).replace(' ', 'T') + 'Z');
        newDatos.push({
          ...dato,
          fecha: newDate,
          hora: newDate.getHours(),
          dia: newDate.getDate(),
          mes: newDate.getMonth() + 1,
          anio: newDate.getFullYear()
        });
      });
      newResult.push({ ...dispositivo, datos: newDatos });
    });
    respuesta.data.result = newResult;
  }

  return respuesta;
};
export function getLabelsRange(inicio: Array<string>, fin: Array<string>) {
  const agrupacion: any = {
    [Rango.LAST_24H]: Group.HOUR,
    [Rango.WEEKLY]: Group.DAY,
    [Rango.MONTHLY]: Group.DAY,
    [Rango.ANNUALLY]: Group.MONTH,
    [Rango.TODAY]: Group.HOUR,
    [Rango.LAST_MONTH]: Group.DAY,
    [Rango.THIS_MONTH]: Group.DAY,
    [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: Group.HOUR,
    [Rango.LAST_YEAR]: Group.MONTH,
    [Rango.TODAY_IN_THE_PREVIOUS_YEAR]: Group.HOUR,
    [Rango.LAST_WEEK]: Group.DAY,
    [Rango.THIS_WEEK]: Group.DAY,
    [Rango.THIS_YEAR]: Group.MONTH,
    [Rango.YESTERDAY]: Group.HOUR
  };
  let textoRango = Rango.THIS_WEEK;
  const diferenciaDias = differenceInDays(new Date(inicio[1]), new Date(inicio[0]));
  const objectLabel: any = {
    labels: [],
    fechaINI: [],
    fechaFINI: [],
    formatDate: formateoFechaGrafico.format('%d/%m'),
    agrupacionValue: Group.DAY
  };
  if (diferenciaDias === 0) {
    textoRango = Rango.TODAY;
    objectLabel.agrupacionValue = agrupacion[Rango.TODAY];
    objectLabel.formatDate = formateoFechaGrafico.format('%Hh');

    for (let index = 0; index <= 24; index++) {
      objectLabel.labels.push(index < 10 ? `0${index}h` : `${index}h`);
      objectLabel.fechaINI.push(addHours(new Date(inicio[0]), index));
      objectLabel.fechaFINI.push(addHours(new Date(fin[0]), index));
    }
  } else if (diferenciaDias >= 1 && diferenciaDias <= 62) {
    textoRango = Rango.THIS_WEEK;
    objectLabel.agrupacionValue = agrupacion[Rango.THIS_WEEK];
    objectLabel.formatDate = formateoFechaGrafico.format('%d/%m');

    for (let index = 0; index <= diferenciaDias; index++) {
      objectLabel.labels.push(`${index + 1}`);
      objectLabel.fechaINI.push(addDays(new Date(inicio[0]), index));
      objectLabel.fechaFINI.push(addDays(new Date(fin[0]), index));
    }
  } else if (diferenciaDias > 62) {
    textoRango = Rango.THIS_YEAR;
    objectLabel.agrupacionValue = agrupacion[Rango.THIS_YEAR];
    objectLabel.formatDate = formateoFechaGrafico.format('%b');

    for (let index = 0; index < diferenciaDias / 30; index++) {
      objectLabel.labels.push(`${index + 1}`);
      objectLabel.fechaINI.push(addMonths(new Date(inicio[0]), index));
      objectLabel.fechaFINI.push(addMonths(new Date(fin[0]), index));
    }
  }
  return objectLabel;
}
