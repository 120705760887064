/**
 * Enumerado que permite distinguir entre los nombres de los componentes de Administración
 */
export enum administracion {
  DISPOSITIVOS = 'Dispositivos',
  CREAR_DISPOSITIVO = 'Crear dispositivo',
  MODIFICAR_DISPOSITIVO = 'Modificar dispositivo',
  CANALES = 'Canales',
  CREAR_CANAL = 'Crear canal',
  MODIFICAR_CANAL = 'Modificar canal'
}
