import React from 'react';
import { TiposMedidasCRU } from './TiposMedidasCRU';
import { tipoMedida } from './resources/interfaces/interfaceTipoMedida';

export function TiposMedidasManager(props: { tipoSeleccionado?: tipoMedida }): JSX.Element {
  return (
    <>
      {props.tipoSeleccionado !== undefined ? (
        <TiposMedidasCRU tipoModificar={props.tipoSeleccionado} />
      ) : (
        <TiposMedidasCRU />
      )}
    </>
  );
}
