import * as React from 'react';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import UserContext from '../../../../context/UserContext';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { Operation } from '../../../graficas/resources/enums/enumOperation';
import { CardProperties, reducerState } from '../../types/typesCardConfigurator';
import {
  allAgregateOptions,
  defaultControlStates,
  initialOptionsAgregateFunctions,
  optionsAutoCompleteFrecuencia,
  sumAgregateOption
} from '../../resources/commonResources';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import ConfiguratorContext from '../../context/ConfiguratorContext';

enum typeActions {
  SET_DATA = 'setData'
}

type setData = {
  type: typeActions.SET_DATA;
  payload: Record<string, unknown>;
};

const initialCardProperties: CardProperties = {
  tarjeta: {
    titulo: '',
    codigoTipoDispositivo: null,
    range: null,
    codigoFuncionAgregada: null,
    codigoTipoMedida: null,
    mostrarGrafica: false,
    mostrarSemaforo: false,
    servicio: '',
    position: null,
    idTipoTarjeta: null
  },
  dispositivos: null,
  semaforo: null
};

//Esta variable se usa principalmente para facilitar la gestión de estado de los pasos que va haciendo el usuario,
//ya que tenemos los objetos completos de los controladores.
const initialObj: reducerState = {
  tiposDispositivo: [],
  tiposMedida: [],
  dispositivosDisponibles: [],
  medida: null,
  tipoDispositivo: null,
  range: null,
  operation: null,
  cardReady: false,
  dataObject: initialCardProperties
};

export function useConfEvolutiveGraphCard(modulo: number, cardProps: any) {
  const { datosUsuarioContext } = React.useContext(UserContext);
  const { controllerRequest } = useControlador();
  const { userDevicesConfiguration } = React.useContext(ConfiguratorContext);
  const [userDives] = userDevicesConfiguration;
  const [controlStates, setControlStates] = React.useState(defaultControlStates);
  const [randomValues, setRandomValues] = React.useState<ApexAxisChartSeries>([
    { data: [], type: 'line', name: '' }
  ]);

  const reducer = (obj: reducerState, action: setData) => {
    return { ...obj, ...action.payload };
  };

  const [obj, dispatch] = React.useReducer(reducer, {
    ...initialObj,
    dataObject: cardProps ?? initialCardProperties
  });
  const { dataObject } = obj;

  const [optionsAgregateFunctions, setOptionsAgregateFunctions] = React.useState(
    initialOptionsAgregateFunctions
  );

  function handleCardTitle(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, titulo: event.target.value }
        }
      }
    });
  }

  function handleAutoCompleteTiposDispositivo(event: React.SyntheticEvent | null, value: any) {
    const { codigo } = value ?? { codigo: null };
    //When the device type is changed, fields must be updated.
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        tipoDispositivo: value,
        operation: null,
        range: null,
        medida: null,
        dataObject: {
          ...initialCardProperties,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            codigoTipoDispositivo: codigo,
            titulo: dataObject.tarjeta.titulo
          }
        }
      }
    });
  }

  function handleAutoCompleteTiposMedida(event: React.SyntheticEvent | null, value: any) {
    const { codigo } = value ?? { codigo: null };
    const measure = parseInt(codigo as string);
    if (
      measure === TiposMedida.CONSUMO ||
      measure === TiposMedida.CONSUMO_CAUDAL ||
      measure === TiposMedida.CONSUMO_CAUDAL_ENTRADA ||
      measure === TiposMedida.GENERADO
    ) {
      setOptionsAgregateFunctions([...initialOptionsAgregateFunctions, sumAgregateOption]);
    } else {
      setOptionsAgregateFunctions(initialOptionsAgregateFunctions);
    }
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        operation: null,
        range: null,
        medida: value,
        dataObject: {
          ...initialCardProperties,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            titulo: dataObject.tarjeta.titulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo as string,
            codigoTipoMedida: codigo
          }
        }
      }
    });
    setControlStates(defaultControlStates);
  }

  function handleAutoCompleteDispositivos(
    dispositivos: Array<{ canal: string; id: number; nombre: string; idCanal: number }>
  ) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        ...obj,
        operation: null,
        range: null,
        cardReady: false,
        dataObject: {
          ...initialCardProperties,
          dispositivos: dispositivos,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            titulo: dataObject.tarjeta.titulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo as string,
            codigoTipoMedida: obj.medida?.codigo
          }
        }
      }
    });

    if (dispositivos.length) {
      setControlStates({
        deshabilitarGrafica: false,
        deshabilitarRango: false,
        operationDisabled: false,
        deshabilitarSemaforo: false,
        deshabilitar: true
      });
    } else {
      setControlStates(defaultControlStates);
    }
  }

  function handleRange(event: React.SyntheticEvent, value: any): void {
    const { option } = value ?? { option: null };

    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        range: value,
        dataObject: { ...dataObject, tarjeta: { ...dataObject.tarjeta, range: option } }
      }
    });
  }

  function handleAutoCompleteAgregateFunctions(
    event: React.SyntheticEvent,
    value: { name: string; code: string; data: Operation } | null
  ) {
    const { code } = value ?? { name: null, code: null, data: null };

    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        operation: value,
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, codigoFuncionAgregada: code }
        }
      }
    });
  }

  React.useEffect(() => {
    const dispositivosFiltrados = userDives;
    dispatch({ type: typeActions.SET_DATA, payload: { dispositivos: dispositivosFiltrados } });

    //Obtiene todos los tipos de dispositivo filtrado por dispositivos.
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: 'tiposDispositivo/dispositivos/',
        object: { dispositivos: dispositivosFiltrados.map(({ id }) => id) }
      }
    }).then((deviceTypes) => {
      //If the user click on a previously loaded card
      if (cardProps) {
        const loadedDeviceType = deviceTypes.find(
          (item: any) => item.codigo == cardProps.tarjeta.codigoTipoDispositivo
        );
        controllerRequest({
          type: action.OBTENER_DATOS_POST,
          payload: {
            service: 'tiposMedidas/tipoDispositivo',
            object: {
              idTipoDispositivo: loadedDeviceType.id
            }
          }
        }).then((measureTypes) => {
          const loadedMeasure = measureTypes.find(
            (item: any) => item.codigo == cardProps.tarjeta.codigoTipoMedida
          );
          if (
            loadedMeasure.codigo == TiposMedida.CONSUMO ||
            TiposMedida.CONSUMO_CAUDAL ||
            TiposMedida.CONSUMO_CAUDAL_ENTRADA ||
            TiposMedida.GENERADO
          ) {
            setOptionsAgregateFunctions([sumAgregateOption, ...initialOptionsAgregateFunctions]);
          }
          dispatch({
            type: typeActions.SET_DATA,
            payload: {
              tiposDispositivo: deviceTypes,
              tiposMedida: measureTypes,
              range: optionsAutoCompleteFrecuencia.find(
                (item) => item.option === cardProps.tarjeta.range
              ),
              operation: allAgregateOptions.find(
                (item) => item.code == cardProps.tarjeta.codigoFuncionAgregada
              ),
              dataObject: {
                ...dataObject,
                tarjeta: {
                  ...dataObject.tarjeta,
                  codigoTipoMedida: loadedMeasure.codigo,
                  codigoTipoDispositivo: loadedDeviceType.codigo
                }
              },
              medida: loadedMeasure,
              tipoDispositivo: loadedDeviceType
            }
          });
          setControlStates({
            deshabilitarGrafica: false,
            deshabilitarRango: false,
            operationDisabled: false,
            deshabilitarSemaforo: false,
            deshabilitar: true
          });
        });
      } else {
        dispatch({ type: typeActions.SET_DATA, payload: { tiposDispositivo: deviceTypes } });
      }
    });
  }, []);

  React.useEffect(() => {
    if (obj.tipoDispositivo) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: 'tiposMedidas/tipoDispositivo',
          object: {
            idTipoDispositivo: obj.tipoDispositivo.id
          }
        }
      }).then((res) => {
        dispatch({
          type: typeActions.SET_DATA,
          payload: { tiposMedida: res.filter((medida: any) => medida.tipo === 'number') }
        });
      });
    }
  }, [obj.tipoDispositivo]);

  React.useEffect(() => {
    if (obj.medida) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: 'dispositivos/tipoMedida',
          object: {
            codigoMedida: obj.medida.codigo,
            idModulo: modulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo
          }
        }
      }).then((res) => {
        const dispositivosFiltradosUser = userDives;
        const dispositivosFiltrados: Array<dispositivo> = res.filter((p) => {
          return (
            dispositivosFiltradosUser.filter((y: any) => (y.id | y.idDispositivo) === p.id).length >
            0
          );
        });
        dispatch({
          type: typeActions.SET_DATA,
          payload: { dispositivosDisponibles: dispositivosFiltrados }
        });
      });
    }
  }, [obj.medida]);

  React.useEffect(() => {
    const Operation_Range_Ready = obj.operation && obj.range;

    dispatch({
      type: typeActions.SET_DATA,
      payload: { cardReady: Operation_Range_Ready != null ? Operation_Range_Ready : false }
    });
  }, [obj.operation, obj.range]);

  React.useEffect(() => {
    if (dataObject.dispositivos) {
      const randomVal: ApexAxisChartSeries = dataObject.dispositivos.map((dispositivo) => {
        return {
          data: Array(12)
            .fill(undefined)
            .map(() => Math.round(Math.random() * 10)),
          type: 'line',
          name: dispositivo.nombre
        };
      });
      setRandomValues(randomVal);
    } else {
      setRandomValues([{ data: [], type: 'line', name: '' }]);
    }
  }, [dataObject.dispositivos]);

  return {
    handleAutoCompleteTiposMedida,
    handleAutoCompleteDispositivos,
    handleAutoCompleteTiposDispositivo,
    controlStates,
    obj,
    optionsAgregateFunctions,
    optionsAutoCompleteFrecuencia,
    handleAutoCompleteAgregateFunctions,
    controllerRequest,
    handleCardTitle,
    handleRange,
    randomValues
  };
}
