import React from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent, Typography } from '@mui/material';
import { objReducerUsuarios } from '../resources/types/objReducerUsuarios';
import { Perfil } from '../resources/interfaces/interfacePerfil';
import { enumLabel } from '../../../resources/enums/plainText';

export function EstablecerPerfil({
  state,
  onChange
}: {
  state: objReducerUsuarios;
  onChange: (event: SelectChangeEvent<{ value: any }>) => void;
}): JSX.Element {
  return (
    <div style={{ height: '300px', paddingTop: '15px' }}>
      <FormControl style={{ marginBottom: '30px' }}>
        <InputLabel id="select-perfil">{enumLabel.PERFIL}</InputLabel>
        <Select
          native
          inputProps={{
            name: enumLabel.PERFIL,
            id: 'select-perfil'
          }}
          onChange={onChange}
          value={state.permisosUsuario.usuario.idPerfil}
          label={enumLabel.PERFIL}
        >
          {state.perfiles.map((perfil: Perfil) => (
            <option key={perfil.id} value={perfil.codigo}>
              {perfil.nombre}
            </option>
          ))}
        </Select>
      </FormControl>
      <Typography gutterBottom>
        {state.perfiles[state.usuarioFinal.usuario.idPerfil - 1].descripcion}
      </Typography>
    </div>
  );
}
