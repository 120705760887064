/**
 * Enumeración para los diferentes tipos de acciones del motor de reglas
 *
 * @readonly
 * @enum {string} enumTiposAcciones
 * @param actuacion_dispositivo
 * @param whatsapp
 * @param emails
 * @param cambio_estado_regla
 */
export enum enumTiposAcciones {
  actuacion_dispositivo = 1,
  whatsapp = 2,
  emails = 3,
  cambio_estado_regla = 4,
  actuacion_grupal = 5
}
