import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { formatToDayStart } from '../../../../../functions/functions';
import { seleccionarIcono } from '../../../../alertas/functions/functions';
import { tipoAlerta } from '../../../../alertas/resources/enums/enumTipoAlerta';
import { TarjetaInterface } from '../../resources/interfaces/interfaceTarjeta';
import useStyles from './styles';
import DialogVideo from '../../../../videovigilancia/DialogVideo';
import { DispositivoVideo } from '../../../../videovigilancia/resources/interfaces/interfaceDispositivoVideo';
import ControlContext from '../../../../../context/control/ControlContext';
import {
  getFechaRegistro,
  isCctvEvent
} from '../../../../videovigilancia/hooks/useReducerVideo/resources/functions/functions';

const getColorAlerta = (tipo: number): string => {
  switch (tipo) {
    case tipoAlerta.SUCCESS:
      return '#EDFDEA';
    case tipoAlerta.INFO:
      return '#EAEDFD';
    case tipoAlerta.WARNING:
      return '#FFFDE5';
    case tipoAlerta.ERROR:
      return '#FDECEA';
    case tipoAlerta.EVENT:
      return '#FCEAFD';
  }
  return '';
};

/**
 * Función que obtiene el mensaje de la alerta ya que puede estar en dos parametros
 * @param {TarjetaInterface} datosTarjeta objeto que contiene el mensaje de la alerta
 * @returns {string} mensaje de la alerta
 */
const getMensajeAlerta = (datosTarjeta: TarjetaInterface): string => {
  return (datosTarjeta.informacion || datosTarjeta.mensajeAlerta) as string;
};
/**
 * Función que obtiene la fecha de la alerta ya que puede estar en dos parametros
 * @param {TarjetaInterface} datosTarjeta objeto que contiene la fecha de la alerta
 * @returns {string} fecha formateada
 */
const getFechaAlerta = (datosTarjeta: TarjetaInterface): string => {
  return (datosTarjeta.fecha as string) || formatToDayStart(datosTarjeta.fechaAlerta as string);
};

export function AlertaTarjeta(props: { datosTarjeta: TarjetaInterface }): JSX.Element {
  const classes = useStyles();
  const { moduloSeleccionado } = React.useContext(ControlContext);
  const [openVideo, setOpenVideo] = React.useState<boolean>(false);
  const dispositivoVideo: DispositivoVideo = {
    idDevice: props.datosTarjeta.idDevice as number,
    nombre: props.datosTarjeta.nombre,
    fechaAlerta: getFechaRegistro(
      (props.datosTarjeta.fechaAlerta || props.datosTarjeta.fecha) as string
    ),
    mensajeAlerta: props.datosTarjeta.mensajeAlerta as string,
    idTipoAlerta: props.datosTarjeta.idTipoAlerta as number
  };

  const isEventWarningError = (): boolean => {
    return (
      props.datosTarjeta.idTipoAlerta === tipoAlerta.EVENT ||
      props.datosTarjeta.idTipoAlerta === tipoAlerta.WARNING ||
      props.datosTarjeta.idTipoAlerta === tipoAlerta.ERROR
    );
  };

  return (
    <>
      {isEventWarningError() ? (
        <Button
          onClick={() => setOpenVideo(true)}
          disabled={!isCctvEvent(moduloSeleccionado, dispositivoVideo)}
          fullWidth
        >
          <ListItem
            style={{
              backgroundColor: getColorAlerta(props.datosTarjeta.idTipoAlerta as number)
            }}
          >
            <ListItemIcon>
              <div className={classes.iconAlerta}>
                {seleccionarIcono(props.datosTarjeta.idTipoAlerta as number, true).icono}
              </div>
            </ListItemIcon>
            <ListItemText
              primary={getMensajeAlerta(props.datosTarjeta)}
              primaryTypographyProps={{
                color: 'textPrimary'
              }}
              secondary={getFechaAlerta(props.datosTarjeta)}
            />
          </ListItem>
        </Button>
      ) : (
        <></>
      )}
      {openVideo ? (
        <DialogVideo datosVideo={dispositivoVideo} open={openVideo} setOpen={setOpenVideo} />
      ) : (
        <></>
      )}
    </>
  );
}
