import * as React from 'react';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { serviceRoutes } from '../../../../resources/enums/enumRutasServicios';

export function useDocument(
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): {
  downloadFile: (file: {
    fileId: string;
    name: string;
    mimeType: string;
    previewUrl: string;
  }) => void;
} {
  const { controllerRequest } = useControlador();

  function downloadFile(file: {
    fileId: string;
    name: string;
    mimeType: string;
    previewUrl: string;
  }) {
    const { fileId, name: fileName, mimeType, previewUrl } = file;

    if (fileId) {
      setIsLoading(true);
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: { service: serviceRoutes.DOWNLOAD_DOCUMENT, object: { fileId } }
      })
        .then((res) => {
          const binary = atob(res.replace(/\s/g, ''));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }

          const blob = new Blob([view], { type: mimeType });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch((error) => console.log(error))
        .finally(() => setTimeout(() => setIsLoading(false), 4000));
    } else {
      const link = document.createElement('a');
      link.href = previewUrl;
      link.download = fileName;
      link.click();
    }
    //Este Timeout es para prevenir que el usuario intente borrar inmediatamente el fichero después de descargar,
    //ya que google drive parece que en ese proceso de descarga aunque devuelva un 200, sigue usando ese archivo durante un corto periodo de tiempo
    //y si se intenta borrar en ese corto periodo de tiempo, da un error, lo que llevaría finalmente a un archivo corrupto que pasado un tiempo google drive lo borra
    // pero en la base de datos seguiría existiendo.
  }

  return { downloadFile };
}
