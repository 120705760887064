import { useReducer } from 'react';
import { section } from '../../../../../../../sections/resources/interfaces/interfaceSection';
import { sectionsInteraccion } from '../resources/interfaces/interfaceSectionsInteraccion';
import { actions } from '../resources/enums/enumActions';
import { Action } from '../resources/types/typeAction';
import { State } from '../resources/types/typeState';

const INITIAL_STATE: State = {
  openCloseTransition: false,
  endTransition: false,
  doingTransition: false,
  sections: {
    sectionsVisibles: [] as section[],
    sectionsOcultas: [] as section[]
  },
  isLoading: true
};

const REDUCER = (state: State, action: Action): State => {
  switch (action.type) {
    case actions.SET_OPEN_CLOSE_TRANSITION:
      return { ...state, openCloseTransition: action.payload };
    case actions.SET_END_TRANSITION:
      return { ...state, endTransition: action.payload };
    case actions.SET_DOING_TRANSITION:
      return { ...state, doingTransition: action.payload };
    case actions.SET_SECTIONS:
      return { ...state, sections: action.payload };
    case actions.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case actions.HANDLE_ENTER:
      return { ...state, sections: action.payload, isLoading: false };
    case actions.HANDLE_OPEN_CLOSE_TRANSITION:
      return { ...state, openCloseTransition: !state.openCloseTransition, doingTransition: true };
    case actions.HANDLE_ENDED_TRANSITION:
      return { ...state, endTransition: !state.endTransition, doingTransition: false };
    case actions.HANDLE_EXIT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

/**
 * Custom Hook para el control de la interación con sections, y el control de la transición (expander)
 */
export function useReducerInteraccion(): {
  openCloseTransition: boolean;
  setOpenCloseTransition: (openCloseTransition: boolean) => void;
  endTransition: boolean;
  setEndTransition: (endTransition: boolean) => void;
  doingTransition: boolean;
  setDoingTransition: (doingTransition: boolean) => void;
  sections: sectionsInteraccion;
  setSections: (sections: sectionsInteraccion) => void;
  isLoading: boolean;
  setIsLoading: (doingTransition: boolean) => void;
  handleEnter: (sections: sectionsInteraccion) => void;
  handleOpenCloseTransition: () => void;
  handleEndedTransition: () => void;
  handleExit: () => void;
} {
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE);
  const { openCloseTransition, endTransition, doingTransition, sections, isLoading } = state;

  /**
   * Función que establece cuando hacemos click en el botón para abrir o cerrar la
   * transición collapse donde están las sections.
   * @param { boolean } openCloseTransition si es true, estamos iniciando la transición
   */
  const setOpenCloseTransition = (openCloseTransition: boolean): void =>
    dispatch({ type: actions.SET_OPEN_CLOSE_TRANSITION, payload: openCloseTransition });

  /**
   * Función que establece cuando ha terminado la transición collapse donde están las sections.
   * @param { boolean } endTransition si es true, la transición ha finalizado
   */
  const setEndTransition = (endTransition: boolean): void =>
    dispatch({ type: actions.SET_END_TRANSITION, payload: endTransition });

  /**
   * Función que establece si se está realizando la transición collapse donde están las sections.
   * Y así poder deshabilitar temporalmente el botón de abrir o cerrar la transición.
   * @param { boolean } doingTransition si es true, se está realizando la transición
   */
  const setDoingTransition = (doingTransition: boolean): void =>
    dispatch({ type: actions.SET_DOING_TRANSITION, payload: doingTransition });

  /**
   * Función que guarda las sections que se van a mostrar en el componente
   * @param { sectionsInteraccion } sections sections del componente tarjeta interacción
   */
  const setSections = (sections: sectionsInteraccion): void =>
    dispatch({ type: actions.SET_SECTIONS, payload: sections });

  /**
   * Función que establece la animación carrusel mientras se obtienen las sections
   * @param { boolean } isLoading si es true, se está realizando la petición para obtener las sections
   */
  const setIsLoading = (isLoading: boolean): void =>
    dispatch({ type: actions.SET_IS_LOADING, payload: isLoading });

  /**
   * Función que se ejecuta cuando montamos el componente, se guardan las sections, se establece a false
   * isLoading, para indicar que ya ha terminado de hacer la petición.
   * @param { sectionsInteraccion } sections sections que se van a mostrar en el componente.
   */
  const handleEnter = (sections: sectionsInteraccion): void =>
    dispatch({ type: actions.HANDLE_ENTER, payload: sections });

  /**
   * Función que se ejecuta cuando hacemos click en el botón para abrir o cerrar la transición
   * collapse donde están las sections.
   * Deshabilita el boton de abrir y cerrar la transición a través de doingTransition
   */
  const handleOpenCloseTransition = (): void =>
    dispatch({ type: actions.HANDLE_OPEN_CLOSE_TRANSITION });

  /**
   * Función que se ejecuta cuando termina la transición collapse donde están las sections.
   * Vuelve a habilitar el boton de abrir y cerrar la transición a través de doingTransition
   */
  const handleEndedTransition = (): void => dispatch({ type: actions.HANDLE_ENDED_TRANSITION });

  /**
   * Función que se ejecuta cuando desmontamos el componente. eL estado se restablece al inicial
   */
  const handleExit = (): void => dispatch({ type: actions.HANDLE_EXIT });

  return {
    openCloseTransition,
    setOpenCloseTransition,
    endTransition,
    setEndTransition,
    doingTransition,
    setDoingTransition,
    sections,
    setSections,
    isLoading,
    setIsLoading,
    handleEnter,
    handleOpenCloseTransition,
    handleEndedTransition,
    handleExit
  };
}
