import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../../../../functions/encryption';
import { getHeaders } from '../../../../functions/functions';
import { dictionary_entornos } from '../../../../resources/enums/plainText';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
interface respuestaAxios {
  data: dispositivo[];
  error: any;
  versiontables: { id: number; nombreTabla: string; version: string }[];
}

let res: any;

export async function obtenerDispositivosGIS(idModulo: number): Promise<respuestaAxios> {
  const respuesta: respuestaAxios = { data: [], error: '', versiontables: [] };
  await axios
    .post(
      process.env.REACT_APP_URL_LOCAL + 'dispositivos/todos/activos/GIS',
      encriptarJSON({ idModulo: idModulo }),
      {
        headers: getHeaders(dictionary_entornos.BACK)
      }
    )
    .then(function (response) {
      res = desencriptarRespuesta(response);

      if (res.data.result !== undefined) respuesta.data = res.data.result;
      if (res.data.versiontables !== undefined) respuesta.versiontables = res.data.versiontables;
    })
    .catch(function (error) {
      respuesta.error = error;
    });
  return respuesta;
}
