import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Color } from '../../resources/enums/enumColores';

export function DonutChart(props: {
  series: Array<number>;
  labels: Array<string>;
  diferencia: number;
  title?: string;
  total?: boolean;
}): JSX.Element {
  const theme = useTheme();
  const { title = '', labels = ['', ''], series, total = true, diferencia } = props;

  const options: ApexCharts.ApexOptions = {
    legend: {
      show: false
    },
    title: {
      text: title,
      align: 'left'
    },
    chart: {
      type: 'donut'
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: total,
            value: {
              fontSize: '0.35rem',
              color: `${diferencia > 0 ? '#d32f2f' : '#008000'}`
            },
            total: {
              showAlways: total,
              show: total,
              label: `Diferencia`,
              fontSize: '0.35rem',
              formatter: function () {
                return isFinite(diferencia)
                  ? `${diferencia > 0 ? '+' : '-'} ${Math.abs(diferencia) ?? '-'} %`
                  : '0';
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val: number, { seriesIndex, dataPointIndex, w }) {
        return w.config.series[seriesIndex];
      }
    },
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
    colors: [Color.GRIS_GRAFICAS, theme.palette.secondary.main]
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" height={160} />
    </div>
  );
}
