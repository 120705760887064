import React, { useContext, useEffect } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import ControlContext from '../../../context/control/ControlContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2)
    }
  },
  ul: {
    display: 'flex',
    justifyContent: 'center',
    height: '40px'
  },
  centrarTabla: {
    justifyContentContent: 'center',
    marginTop: '10px'
  },
  '@media screen and (max-height: 700px)': {
    ul: {
      position: 'absolute',
      justifyContentContent: 'center',
      bottom: '5%',
      left: '45%'
    }
  },
  ulShadow: {
    '& ul li button': {
      fontWeight: 'bold',
      fontSize: 'small'
    }
  }
}));

export default function PaginationRounded(props: {
  count: any;
  page: any;
  rowsPerPage: any;
  changePage: any;
  tableFooter?: true;
}): JSX.Element {
  const classes = useStyles();
  const { page, setPage } = useContext(ControlContext);

  const handleChange = (event: any, value: number) => {
    let contador = 1;

    if (value < page) contador = contador * -1;

    if (value - page > 1 || value - page < 1) {
      setPage(value);
      props.changePage(value - 1);
    } else {
      props.changePage(props.page + contador);
      setPage(page + contador);
    }
  };

  useEffect(() => {
    if (page === 1) {
      props.changePage(0);
    } else {
      props.changePage(page - 1);
    }
  }, []);

  return (
    <Pagination
      shape="rounded"
      color="secondary"
      count={Math.ceil(props.count / props.rowsPerPage)}
      page={page}
      onChange={handleChange}
      classes={{ ul: props.tableFooter !== undefined ? classes.centrarTabla : classes.ul }}
      className={classes.ulShadow}
    />
  );
}
