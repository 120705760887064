import * as React from 'react';
import { useReportContext } from 'components/Reports/Context/index.hook';
import {
  ObjectConsumptionReportDetail,
  ArgsGetConsumptionReportDetail,
  ValueGetConsumptionReportDetail
} from './types';
import { useGraficas } from 'components/graficas/hooks/useGraficas/useGraficas';
import { TiposMedida } from 'resources/enums/enumTiposMedida';
import { useTriggeredRequest } from 'shared/hooks/useRequest';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import { Operation } from 'components/graficas/resources/enums/enumOperation';
import { GraphTypes } from 'components/graficas/resources/enums/enumGraphTypes';
import { dispositivo } from 'components/dispositivos/resources/interfaces/interfaceDispositivo';
import { dictionary_unidades } from 'resources/enums/plainText';
import { getGroup } from 'components/Reports/shared/functions';
import { OpenDetail, reportInfo } from 'components/Reports/shared/types';
import { getConsumptionReportDetail } from './services';

const measuresByReport = {
  '301': [TiposMedida.CONSUMO_CAUDAL, TiposMedida.CONSUMO_CAUDAL_ENTRADA],
  '601': [TiposMedida.CONSUMO],
  '201': [TiposMedida.CONSUMO]
};

const initial_state: ObjectConsumptionReportDetail = {
  min: '0',
  max: '0',
  graphData: {},
  details: undefined,
  average: '0'
};

export function useConsumptionReportDetails(openDetail: OpenDetail): {
  details: ObjectConsumptionReportDetail;
} {
  const {
    reportObject: [reportObject],
    selectedReport: [selectedReport]
  } = useReportContext();

  const { obtenerDatosGrafica } = useGraficas();

  const { startDate: startDateFromFilter, endDate: endDateFromFilter } = reportObject;
  const { reportInfo } = selectedReport;
  const { codigo: reportCode } = reportInfo as reportInfo;
  const [details, setDetails] = React.useState<ObjectConsumptionReportDetail>(initial_state);

  const { trigger: getData } = useTriggeredRequest<
    Array<ValueGetConsumptionReportDetail>,
    ArgsGetConsumptionReportDetail
  >(getConsumptionReportDetail);

  React.useEffect(() => {
    if (openDetail.open && openDetail.row) {
      obtenerDatosGrafica(
        measuresByReport[reportCode],
        Rango.RANGE,
        Operation.SUM,
        GraphTypes.area,
        [
          {
            id: openDetail.row.idDispositivo,
            nombre: openDetail.row.dispositivo
          } as unknown as dispositivo
        ],
        openDetail.row.unidad as dictionary_unidades,
        startDateFromFilter,
        endDateFromFilter,
        { openSmarteliaBackdrop: false },
        undefined,
        undefined,
        undefined
      ).then((graphData) => {
        getData({
          deviceId: openDetail.row?.idDispositivo as number,
          startDate: startDateFromFilter,
          endDate: endDateFromFilter,
          measures: measuresByReport[reportCode],
          group: getGroup(startDateFromFilter, endDateFromFilter)
        }).then((response) => {
          const data: Array<number> = [];
          response?.data.result.forEach((i) => {
            data.push(i.valor as number);
            i['valor'] = `${i.valor.toLocaleString(undefined, { useGrouping: true })} ${i.unidad}`;
          });
          const min = `${Math.min(...data).toFixed(2)} ${graphData.unid}`;
          const max = `${Math.max(...data).toFixed(2)} ${graphData.unid}`;
          const average = `${(data.reduce((a, b) => a + b, 0) / data.length).toFixed(2)} ${
            graphData.unid
          }`;
          setDetails({
            min,
            max,
            graphData,
            details: response?.data.result,
            average
          });
        });
      });
    }

    return () => setDetails(initial_state);
  }, [openDetail.open]);

  return { details };
}
