import React from 'react';

import { ReactComponent as iconCanales } from '../../../resources/icons/iconosComponentes/canales.svg';
import { ReactComponent as iconDevices } from '../../../resources/icons/iconosComponentes/device.svg';
import { ReactComponent as iconDispositivos } from '../../../resources/icons/iconosComponentes/dispositivos.svg';
import { ReactComponent as iconEtiquetas } from '../../../resources/icons/iconosComponentes/etiqueta.svg';
import { ReactComponent as iconGrupos } from '../../../resources/icons/iconosComponentes/grupo.svg';
import { ReactComponent as iconTiposMedida } from '../../../resources/icons/iconosComponentes/medida.svg';
import { ReactComponent as iconPlugins } from '../../../resources/icons/iconosComponentes/plugin.svg';
import { ReactComponent as iconPluginsInstalados } from '../../../resources/icons/iconosComponentes/plugin_instalado.svg';
import { ReactComponent as iconGraficas } from '../../../resources/icons/iconosComponentes/graficas.svg';
import { ReactComponent as iconTipoUnidades } from '../../../resources/icons/iconosComponentes/tipounidades.svg';
import { ReactComponent as iconInformes } from '../../../resources/icons/iconosComponentes/informes.svg';
import { ReactComponent as iconControl } from '../../../resources/icons/iconosComponentes/control.svg';
import { ReactComponent as iconTiposAcciones } from '../../../resources/icons/iconosComponentes/tiposacciones.svg';
import { ReactComponent as iconAlertasPendientes } from '../../../resources/icons/iconosComponentes/alertasPendientes.svg';
import { ReactComponent as iconAlertasRegistradas } from '../../../resources/icons/iconosComponentes/alertasRegistradas.svg';
import { ReactComponent as iconTiposDispositivo } from '../../../resources/icons/iconosComponentes/tiposdispositivo.svg';
import { ReactComponent as iconActuacionDispositivo } from '../../../resources/icons/iconosComponentes/actuardispositivo.svg';
import { ReactComponent as iconCambioEstadoRegla } from '../../../resources/icons/iconosComponentes/cambioestadoregla.svg';
import { ReactComponent as iconEmail } from '../../../resources/icons/iconosComponentes/email.svg';
import { ReactComponent as iconWhatsapp } from '../../../resources/icons/iconosComponentes/whatsapp.svg';
import { ReactComponent as iconActuacionGrupal } from '../../../resources/icons/iconosComponentes/actuacionGrupal.svg';
import { ReactComponent as iconMisIntereses } from '../../../resources/icons/intereses.svg';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function IconosComponentes(props: {
  componente: enumComponentes;
  style?: {
    fontSize?: number;
    marginRight?: number;
    width?: number;
    height?: number;
    marginBottom?: string;
  };
}): JSX.Element {
  const theme = useTheme();
  const icono: any = {
    [enumComponentes.CANALES]: { icon: iconCanales, viewBox: '0 0 250 250' },
    [enumComponentes.DEVICES]: { icon: iconDevices, viewBox: '0 0 2000 2000' },
    [enumComponentes.DISPOSITIVOS]: { icon: iconDispositivos, viewBox: '0 0 250 250' },
    [enumComponentes.ETIQUETAS]: { icon: iconEtiquetas, viewBox: '0 0 2000 2000' },
    [enumComponentes.GRUPOS]: { icon: iconGrupos, viewBox: '-50 0 600 476.6' },
    [enumComponentes.TIPOS_MEDIDAS]: { icon: iconTiposMedida, viewBox: '-120 0 820 820' },
    [enumComponentes.PLUGINS]: { icon: iconPlugins, viewBox: '0 0 256 256' },
    [enumComponentes.PLUGINS_INSTALADOS]: {
      icon: iconPluginsInstalados,
      viewBox: '20 0 210 210'
    },
    [enumComponentes.GRAFICAS]: { icon: iconGraficas, viewBox: '0 0 250 250' },
    [enumComponentes.TIPOS_UNIDADES]: { icon: iconTipoUnidades, viewBox: '0 0 60 60' },
    [enumComponentes.INFORMES]: { icon: iconInformes, viewBox: '0 0 250 250' },
    [enumComponentes.TIPOS_ACCIONES]: { icon: iconTiposAcciones, viewBox: '0 0 256 256' },
    [enumComponentes.TIPOS_DISPOSITIVOS]: {
      icon: iconTiposDispositivo,
      viewBox: '-50 0 600 476.6'
    },
    [enumComponentes.ALERTAS_PENDIENTES]: {
      icon: iconAlertasPendientes,
      viewBox: '0 0 250 250'
    },
    [enumComponentes.ALERTAS_REGISTRADAS]: {
      icon: iconAlertasPendientes,
      viewBox: '0 0 250 250'
    },
    [enumComponentes.ENLACES_EXTERNOS]: {
      icon: iconAlertasRegistradas,
      viewBox: '-50 0 600 476.6'
    },
    [enumComponentes.CONFIGURADOR]: {
      icon: iconAlertasRegistradas,
      viewBox: '-50 0 600 476.6'
    },
    [enumComponentes.ACTUACION_DISPOSITIVO]: {
      icon: iconActuacionDispositivo,
      viewBox: '0 0 512 512'
    },
    [enumComponentes.CAMBIO_ESTADO_REGLA]: {
      icon: iconCambioEstadoRegla,
      viewBox: '0 0 16 16'
    },
    [enumComponentes.CONTROL]: {
      icon: iconControl,
      viewBox: '0 0 250 250'
    },
    [enumComponentes.EMAIL]: {
      icon: iconEmail,
      viewBox: '0 0 24 24'
    },
    [enumComponentes.WHATSAPP]: {
      icon: iconWhatsapp,
      viewBox: '0 0 568.969 568.969'
    },
    [enumComponentes.MIS_INTERESES]: {
      icon: iconMisIntereses,
      viewBox: '0 0 250 250'
    },
    [enumComponentes.ACTUACION_GRUPAL]: {
      icon: iconActuacionGrupal,
      viewBox: '0 0 512 512'
    }
  };
  return (
    <>
      <SvgIcon
        component={icono[props.componente].icon}
        style={{ fontSize: '1.7rem', ...props.style }}
        viewBox={icono[props.componente].viewBox}
        htmlColor={theme.palette.primary.main}
      />
    </>
  );
}
