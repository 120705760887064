import React, { useRef } from 'react';
import { AppBar, Tab, Tabs, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { objReducerUsuarios } from '../resources/types/objReducerUsuarios';
import { Modulos } from '../../../resources/enums/enumModulo';
import Table from '../../common/table/Table';
import { MapIcons } from '../../common/gis/components/iconos/Iconos';
import { ContenedorActivado, ContenedorDesactivado } from '../../common/table/contenedorElementos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      height: '300px',
      flexGrow: 1
    },
    nested: {
      paddingLeft: theme.spacing(4),
      float: 'left',
      width: '45%'
    }
  })
);

function a11yProps(index: any) {
  return {
    id: index,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export function EstablecerDispostivos({
  state,
  events
}: {
  state: objReducerUsuarios;
  events: any;
}): JSX.Element {
  const classes = useStyles();
  const tablePage = useRef(state.tablaSeleccionada);

  const page = useRef(0);

  const table = {
    title: 'Dispositivos',
    columns: [
      { name: 'id', options: { display: 'excluded' } },
      // eslint-disable-next-line react/display-name
      {
        name: 'icono',
        label: 'Icono',
        options: {
          filter: false,
          sort: false,
          empty: true,
          viewColumns: false,
          // eslint-disable-next-line react/display-name
          customBodyRender: (value: string, index: number) => {
            return <MapIcons icono={value} index={index} />;
          }
        }
      },
      { name: 'nombre', label: 'Nombre' },
      { name: 'grupoDispositivo', label: 'Grupo' },
      { name: 'etiquetas', label: 'Etiqueta' },
      {
        name: 'activo',
        label: 'Activo',
        options: {
          filter: false,
          sort: false,
          empty: true,
          viewColumns: false,
          customBodyRender: (value: number): JSX.Element => {
            if (value === 1) return <ContenedorActivado />;
            else return <ContenedorDesactivado />;
          }
        }
      }
    ],
    options: {
      rowsPerPageOptions: [],
      page: tablePage.current !== state.tablaSeleccionada ? 0 : page,
      rowsPerPage: 3,
      download: false,
      filter: false,
      viewColumns: false,
      print: false,
      responsive: 'simple',
      searchOpen: true,
      searchAlwaysOpen: true,
      searchPlaceholder: 'Busque el dispositivo.',
      rowsSelected: state.dispositivosSeleccionados.length
        ? state.dispositivosSeleccionados
        : state.dispositivos.map((dispositivo, index) => index),
      selectToolbarPlacement: 'none',
      onRowSelectionChange: (
        currentRowsSelected: Array<any>,
        allRowsSelected: Array<any>,
        rowsSelected: Array<any>
      ) => {
        events.setDispositivosUsuario(
          currentRowsSelected,
          rowsSelected,
          state.moduloSeleccionadoEstDispositivos
        );
      }
    },
    onChangePage: (currentPage: number) => {
      page.current = currentPage;
    },
    data: state.dispositivos
  };

  tablePage.current = state.tablaSeleccionada;

  return (
    <div className={classes.root}>
      <Typography paragraph>
        * Por defecto el usuario tendrá permisos en todos los dispositivos de cada módulo.
      </Typography>
      <AppBar position="static" color="default">
        <Tabs
          value={state.tablaSeleccionada === -1 ? false : state.tablaSeleccionada}
          onChange={events.cargarDispositivos}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="tabs-Dispositivos"
        >
          {state.usuarioFinal.modulos.map((modulo: any) => {
            const mod = state.permisosUsuario.modulos.find((item: any) => modulo.id === item.id);
            return mod.id !== Modulos['GESTIÓN DE USUARIOS'] &&
              mod.id !== Modulos['CONFIGURACIÓN GENERAL'] &&
              mod.id !== Modulos['IA Y BIG DATA'] &&
              mod.id !== Modulos['REGLAS'] ? (
              <Tab label={mod.nombre} {...a11yProps(mod.id)} />
            ) : (
              []
            );
          })}
        </Tabs>
      </AppBar>
      {state.dispositivos.length !== 0 ? <Table {...table}></Table> : <></>}
    </div>
  );
}
