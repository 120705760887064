/**
 * @alias TiposDispositivo
 * @typedef TiposDispositivo
 * @property {number} id id del tipo de dispositivo
 * @property {string} codigo código del tipo de dispositivo.
 * @property {string} nombre nombre del tipo de dispositivo.
 * @property {string} icon icon del tipo de dispositivo.
 * @property {number} soloLectura indica si el tipo de dispositivo
 * es de solo lectura: 0 indica que no, 1 indica que si.
 */

export interface tiposDispositivo {
  id?: number;
  codigo?: string;
  nombre: string;
  icon?: string;
  soloLectura?: number;
}

export const objectTiposDispositivo = (elemento?: tiposDispositivo): tiposDispositivo => {
  let tiposDispositivo: tiposDispositivo;
  elemento === undefined
    ? (tiposDispositivo = {
        codigo: '',
        nombre: '',
        icon: 'luminaria',
        soloLectura: 0
      })
    : (tiposDispositivo = {
        id: elemento.id,
        codigo: elemento.codigo,
        nombre: elemento.nombre,
        icon: elemento.icon,
        soloLectura: 0
      });

  return tiposDispositivo;
};
