import { dispositivo } from 'components/dispositivos/resources/interfaces/interfaceDispositivo';
import { useGraficas } from 'components/graficas/hooks/useGraficas/useGraficas';
import { Group } from 'components/graficas/resources/enums/enumGroup';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as React from 'react';
import { Modulos } from 'resources/enums/enumModulo';
import { getDevicesByDeviceTypes } from 'shared/services/devices';
import { useRequest, useTriggeredRequest } from 'shared/hooks/useRequest';

import { ArgsGetResidualsParametersDetails, ObjectDetailsResidualsParameters } from './types';
import { getResidualsParametersDetail } from './services';
import { ReportData } from 'components/Reports/shared/types';
import { ValueGetDevicesByDeviceTypes } from '../../shared/types';

const initialState: ObjectDetailsResidualsParameters = {
  datosGrafico: {},
  detalles: {},
  parametro: '',
  concentracion: '',
  ready: false
};

const optionsAutoCompleteFrecuencia = [
  { frecuencia: 'Últimas 24h', option: Rango.TODAY, group: Group.HOUR },
  { frecuencia: 'Últimos 7 días', option: Rango.THIS_WEEK, group: Group.DAY },
  { frecuencia: 'Último Mes', option: Rango.THIS_MONTH, group: Group.DAY },
  { frecuencia: 'Último Año', option: Rango.THIS_YEAR, group: Group.MONTH }
];

const FREQUENCY_DEFAULT_VALUE = optionsAutoCompleteFrecuencia[1];

export function useResidualParametersDetails({
  openDetail
}: {
  openDetail: { open: boolean; row: any };
}) {
  const [objetoPopUp, setObjetoPopUp] =
    React.useState<ObjectDetailsResidualsParameters>(initialState);

  const [frecuencia, setFrecuencia] = React.useState(FREQUENCY_DEFAULT_VALUE);
  const { row } = openDetail;

  const { data: devices, isLoading } = useRequest<Array<ValueGetDevicesByDeviceTypes>>(() =>
    getDevicesByDeviceTypes({
      deviceType: ['23', '24'],
      moduleId: Modulos['GESTIÓN DE AGUA POTABLE'],
      withTags: true
    })
  );

  const { trigger: getDetailData, isMutating } = useTriggeredRequest<
    ReportData,
    ArgsGetResidualsParametersDetails
  >(getResidualsParametersDetail);

  const { formatearDatosGraficaRendimientoResiduos } = useGraficas();

  function handleAutoCompleteFrecuencia(event: any, value: any) {
    if (value.option !== frecuencia.option) {
      setFrecuencia(
        optionsAutoCompleteFrecuencia.filter((options) => options.option === value.option)[0]
      );
    }
  }

  React.useEffect(() => {
    if (openDetail.open && !isLoading)
      getDetailData({
        devicesIds: devices.map((item) => item.id),
        measurementCode: parseInt(row.codigoMedida),
        range: frecuencia.option,
        group: frecuencia.group
      }).then((responseDetail) => {
        const formattedGraphData = formatearDatosGraficaRendimientoResiduos(
          responseDetail?.data.result.body as Array<any>,
          devices.find((item) => item.id === row.idDispositivo) as unknown as dispositivo,
          frecuencia.option
        );

        responseDetail?.data.result.body.forEach(
          (item: any) =>
            (item.fechaMedida = format(new Date(item.fechaMedida), 'dd/MM/yyyy', { locale: es }))
        );
        setObjetoPopUp({
          datosGrafico: formattedGraphData,
          detalles: responseDetail?.data.result as ReportData,
          parametro: row.parametro,
          concentracion: row.concentracion,
          ready: true
        });
      });

    return () => setObjetoPopUp(initialState);
  }, [openDetail.open, frecuencia, isLoading]);

  return {
    objetoPopUp,
    handleAutoCompleteFrecuencia,
    frecuencia,
    setFrecuencia,
    optionsAutoCompleteFrecuencia,
    isMutating
  };
}
