import * as React from 'react';
import { ReportProperties } from './types';
import { startOfMonth, endOfMonth } from 'date-fns';

const initialSelectedReport = {
  filter: <></>,
  reportContainer: <></>,
  reportInfo: undefined,
  headerUnit: undefined
};

const ReportContext = React.createContext<ReportProperties>({
  reportData: [
    { report: { head: undefined, body: undefined, foot: undefined }, graphData: undefined },
    () => {
      return {};
    }
  ],
  reportObject: [
    {},
    () => {
      return {};
    }
  ],
  openFilter: [true, (prev) => prev],
  selectedReport: [initialSelectedReport, () => initialSelectedReport]
});

export function ReportProvider(props: any) {
  const [reportData, setReportData] = React.useState({
    report: { head: undefined, body: undefined, foot: undefined },
    graphData: undefined
  });

  const [reportObject, setReportObject] = React.useState<Record<string, unknown>>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date())
  });

  const [openFilter, setOpenFilter] = React.useState(true);

  const [selectedReport, setSelectedReport] = React.useState(initialSelectedReport);

  const value = {
    reportData: [reportData, setReportData],
    reportObject: [reportObject, setReportObject],
    openFilter: [openFilter, setOpenFilter],
    selectedReport: [selectedReport, setSelectedReport]
  };

  React.useEffect(() => {
    setReportData({
      report: { head: undefined, body: undefined, foot: undefined },
      graphData: undefined
    });
    setReportObject({
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date())
    });
  }, [selectedReport]);

  return <ReportContext.Provider value={value} {...props} />;
}

export default ReportContext;
