import React from 'react';
import { Box } from '@mui/material/';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { enumLabel } from '../../../resources/enums/plainText';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { tiposDispositivo } from '../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { operador } from '../../reglas/resources/interfaces/interfaceOperador';
import { opcionTipoMedida } from '../../reglas/resources/interfaces/interfaceOpcionTipoMedida';
import { canal } from '../../reglas/resources/interfaces/interfaceCanal';

export const FormularioCondicionDispositivo = (props: {
  stateCondiciones: any;
  events: any;
  state: any;
}): JSX.Element => {
  const { stateCondiciones, events } = props;

  const { condicionCompleta, dialogoCondiciones } = stateCondiciones;
  const { setCondicionCompleta, convertirOperadorAFrase } = events;
  const { handleInputChange, handleInputInvalid, handleAutocompleteObjetoChange } = useEventChange(
    condicionCompleta,
    setCondicionCompleta
  );
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.tipoDispositivo}
            disableClearable
            id="tipoDispositivo"
            options={dialogoCondiciones.tiposDispositivo as tiposDispositivo[]}
            size="small"
            getOptionLabel={(option) => option.nombre}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.TIPO_DISPOSITIVO} variant="outlined" />
            )}
          />
        </Box>

        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.dispositivo}
            disableClearable
            id="dispositivo"
            options={dialogoCondiciones.dispositivos as dispositivo[]}
            size="small"
            getOptionLabel={(option) => option.nombre}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.DISPOSITIVO} variant="outlined" />
            )}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.canal}
            disableClearable
            id="canal"
            options={dialogoCondiciones.canales as canal[]}
            size="small"
            getOptionLabel={(option) => option.nombre}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.CANAL} variant="outlined" />
            )}
          />
        </Box>

        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.operador}
            disableClearable
            id="operador"
            options={dialogoCondiciones.operadores as operador[]}
            size="small"
            getOptionLabel={(option) => convertirOperadorAFrase(option.operador)}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.OPERADOR} variant="outlined" />
            )}
          />
        </Box>

        <Box p={1} flexGrow={1}>
          {dialogoCondiciones.opcionesTiposMedida.length == 0 &&
          dialogoCondiciones.opcionesTiposMedida != undefined ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label={enumLabel.VALOR}
              id="valor"
              type="text"
              required
              onChange={handleInputChange}
              onKeyDown={handleInputInvalid}
              onPaste={handleInputInvalid}
              value={condicionCompleta.valor}
              autoComplete="off"
            />
          ) : (
            <Autocomplete
              value={condicionCompleta.valor}
              disableClearable
              id="valor"
              options={dialogoCondiciones.opcionesTiposMedida.map(
                (o: opcionTipoMedida) => o.opcion
              )}
              size="small"
              getOptionLabel={(option) => option}
              onChange={handleAutocompleteObjetoChange}
              fullWidth={true}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.VALOR} variant="outlined" />
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
