import * as React from 'react';
import { serviceRoutes } from '../../../../resources/enums/enumRutasServicios';
import { formateoFechaGrafico } from '../../../graficas/functions/funciones';
import UserContext from '../../../../context/UserContext';

import { NativeTypes } from 'react-dnd-html5-backend';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import SnackBarContext from '../../../../context/SnackBarContext';
import { enumSeverity } from '../../../common/snackbar/resources/enums/enumSeverity';
import ControlContext from '../../../../context/control/ControlContext';
import { useUploadFileGoogDrive } from '../../../../hooks/useUploadFileGoogDrive';
import { ConnectDropTarget, DropTargetMonitor, useDrop } from 'react-dnd';

//Formateo fecha para poder insertar en la BD.
const formateoFechaBD = formateoFechaGrafico.format('%Y-%m-%d %H:%M:%S');

function formatBytes(bytes: number) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;

  const decimals = bytes < 10000 ? 4 : 2;

  return parseFloat((bytes / Math.pow(k, 2)).toFixed(decimals));
}

export function useDocumentsContainer(
  idDispositivo: number | null,
  setDocuments: (documents: File[]) => void,
  documents: File[],
  idModulo: number | null,
  exceededCapacity: boolean
): {
  handleFileSelected: (event: { target: { files: any } }) => void;
  handleFileDrop: any;
  drop: ConnectDropTarget;
  isOver: boolean;
  deleteFile: (fileId: string, fileName: string) => void;
  isLoading: boolean;
  tieneAcceso: boolean;
  canDrop: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const {
    datosUsuarioContext: { cliente }
  } = React.useContext(UserContext);

  const { controllerRequest } = useControlador();
  const { setSnackBar } = React.useContext(SnackBarContext);
  const { tieneAcceso } = React.useContext(ControlContext);
  const { uploadDocument } = useUploadFileGoogDrive();

  const [isLoading, setIsLoading] = React.useState(false);

  async function upload(data: any, newDocuments: Array<any>, counter: number) {
    const dataForBD = {
      uploadDate: formateoFechaBD(new Date()),
      icon: data.type.substring(0, data.type.indexOf('/')),
      formatSize: formatBytes(data.size),
      idDispositivo,
      idModulo,
      name: data.name,
      mimeType: data.type
    };
    const newDoc: any = {
      ...dataForBD,
      previewUrl: URL.createObjectURL(data)
    };

    newDocuments[counter] = newDoc;
    setDocuments([...documents, ...newDocuments]);

    await uploadDocument(data, dataForBD)
      .then((response: any) => {
        newDoc.fileId = response.data.result.fileId;
        newDocuments[counter] = newDoc;
        setDocuments([...documents, ...newDocuments]);
      })
      .catch((error) => {
        setDocuments([
          ...documents,
          ...newDocuments.filter((documents) => documents.name !== dataForBD.name)
        ]);
        setSnackBar({
          open: true,
          severity: enumSeverity.ERROR,
          text: `Ha ocurrido un error, no se ha podido subir el fichero ${newDoc.name}`
        });
      });
  }

  const handleFileSelected = async (event: { target: { files: any } }) => {
    if (event) {
      setIsLoading(true);
      let counter = 0;
      const newDocuments: any = [];
      for (const data of event.target.files) {
        await upload(data, newDocuments, counter);
        counter++;
      }
      setIsLoading(false);
    }
  };

  const handleFileDrop: any = React.useCallback(
    async (item: { files: any[] }) => {
      if (item) {
        setIsLoading(true);
        let counter = 0;
        const newDocuments: any = [];
        for (const data of item.files) {
          await upload(data, newDocuments, counter);
          counter++;
        }
        setIsLoading(false);
      }
    },
    [setDocuments, documents]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (handleFileDrop) {
          handleFileDrop(item);
        }
      },
      canDrop(item: any) {
        return !exceededCapacity && tieneAcceso && !isLoading;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        };
      }
    }),
    [handleFileDrop]
  );

  function deleteFile(fileId: string, fileName: string) {
    setIsLoading(true);
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: { service: serviceRoutes.DELETE_DOCUMENT, object: { fileId } }
    })
      .then(() => {
        setSnackBar({
          open: true,
          severity: enumSeverity.SUCCESS,
          text: `El fichero ${fileName} se ha eliminado correctamente`
        });
        const newDocuments = documents.filter((item: any) => item.fileId !== fileId);
        setDocuments([...newDocuments]);
      })
      .catch(() => {
        setSnackBar({
          open: true,
          severity: enumSeverity.ERROR,
          text: `Ha ocurrido un error, no se ha podido eliminar el fichero ${fileName}`
        });
      })
      .finally(() => setIsLoading(false));
  }

  return {
    handleFileSelected,
    handleFileDrop,
    drop,
    isOver,
    deleteFile,
    isLoading,
    tieneAcceso,
    canDrop,
    setIsLoading
  };
}
