export enum actionsCondiciones {
  SET_DIALOGO_CONDICIONES = 'setDialogoCondiciones',
  SET_CONDICION_COMPLETA = 'setCondicion',
  SET_LISTA_CONDICIONES = 'setListaCondiciones',
  ADD_CONDICION = 'addCondicion',
  MOSTRAR_INFO_CONDICION = 'mostrarCondicion',
  CERRAR_MOSTRAR_INFO_CONDICION = 'cerrarMostrarCondicion',
  SET_TIPO_CONDICION = 'setTipoCondicion',
  SET_OPCIONES_TIPOS_MEDIDA = 'setOpcionesTiposMedida',
  SET_HORA = 'SET_HORA',
  SET_OPCION_TEMPORAL_ESCOGIDA = 'SET_OPCION_TEMPORAL_ESCOGIDA',
  SET_DIA_SEMANA = 'SET_DIA_SEMANA',
  SET_STATE = 'SET_STATE',
  LIMPIAR_CONDICION = 'LIMPIAR_CONDICION',
  SET_MOMENTO_DIA = 'SET_MOMENTO_DIA'
}
