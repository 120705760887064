export enum actions {
  SET_DEVICE_SELECCIONADO = 'SetDeviceSeleccionado',
  SET_PLUGIN_SELECCIONADO = 'SetPluginSeleccionado',
  // DEVICE TABLA
  SET_DEVICES_TABLA = 'setDevicesTabla',
  // DEVICE MANAGER
  SET_TIPOS_DEVICE = 'setTiposDevice',
  SET_PLUGINS = 'setPlugins',
  SET_DEVICE_MODIFICAR = 'setDeviceModificar',
  // DEVICE CRU
  SET_PARAMETROS_CONFIGURACION = 'setParametrosConfiguracion',
  SET_DEVICE = 'setDevice',
  SET_CONFIGURACION = 'setConfiguracion',
  SET_CHECKED = 'setChecked',
  SET_DISABLED = 'setDisabled',
  SET_DISABLED_TIPO_DEVICE = 'setDisabledTipoDevice',
  SET_AUTOREFRESH = 'setAutoRefresh',
  SET_LIMPIAR = 'setLimpiar',
  HANDLE_AUTOCOMPLETE_TIPO_DEVICE_NULL = 'handleAutocompleteTipoDeviceNull',
  HANDLE_AUTOCOMPLETE_PLUGIN_NULL = 'handleAutocompletePluginNull',
  // DEVICE INFO
  SET_SECTIONS = 'setSections',
  SET_SECTIONS_MODIFICAR = 'setSectionModificar',
  SET_OPEN_AVISO = 'setOpenAviso'
}
