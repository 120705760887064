import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  gridMain: {
    width: `max(20vw, 300px)`,
    background: '#ccd1de',
    borderRadius: '30px',
    padding: '30px'
  },
  formLogin: {
    width: 'max(14vw, 210px)'
  },
  gridLogin: {
    margin: '20px 0 20px 0'
  },
  inputLogin: {
    fontSize: '14px',
    background: 'white'
  },
  buttonLogin: {
    width: '100%',
    fontSize: '16px',
    padding: '8px'
  },
  textCenterBlack: {
    textAlign: 'center',
    color: 'black'
  }
});

export default useStyles;
