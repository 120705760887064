import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  growStyleMain: {
    width: '100%'
  },
  growStyleSecond: {
    width: '100%',
    marginTop: '12px'
  },
  gridStyle: {
    marginTop: '12px'
  }
});
export default useStyles;
