import { useRef } from 'react';
import { objectSection, section } from '../resources/interfaces/interfaceSection';
import {
  servicioModificarEstadoSection,
  obtenerSectionsPorDevice,
  servicioObtenerSectionsEscrituraActivas
} from '../services/servicioSection';
import { useRespuesta } from '../../../hooks/useRespuesta/useRespuesta';
import { respuesta } from '../../../resources/interfaces/interfaceRespuesta';
import { Severity } from '../../common/snackbar/resources/types/Severity';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';

export function useSection(): {
  obtenerSectionsByDevice: (
    servicio: string,
    idDevice: number,
    escritura?: boolean,
    idCanal?: number,
    onlyFree?: boolean
  ) => Promise<any>;
  cambiarEstadoSection: (servicio: string, id: number, nuevoActivo: number) => Promise<any>;
  registroGenerado: React.MutableRefObject<section>;
  completadoCorrectamente: React.MutableRefObject<boolean>;
  propiedadesSnackBar: React.MutableRefObject<{
    texto: string;
    severity: Severity;
  }>;
  obtenerSectionsEscrituraActivas: (servicio: string) => Promise<any>;
} {
  const registroGenerado = useRef<section>(objectSection());
  const completado = useRef<boolean>(false);
  const propiedades = useRef<{
    texto: string;
    severity: Severity;
  }>({ texto: '', severity: enumSeverity.INFO });
  const { completadoCorrectamente, propiedadesSnackBar } = useRespuesta(completado, propiedades);

  const listaDatos = useRef();

  /**
   * Función que llama al servicio para obtener los sections filtrados por
   * un device
   * @param { string } servicio servicio al que queremos llamar
   * @param { number } idDevice id del device por el que queremos filtrar los sections
   * @returns { Promise<any> } Promesa que contiene los sections filtrados por device
   */
  async function obtenerSectionsByDevice(
    servicio: string,
    idDevice: number,
    escritura?: boolean,
    idCanal?: number,
    onlyFree?: boolean
  ): Promise<any> {
    const respuesta: any = await obtenerSectionsPorDevice(
      servicio,
      idDevice,
      escritura,
      idCanal,
      onlyFree
    );
    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  /**
   * Esta función llama al servicio servicioModificarEstadoSection para cambiar el estado de un section
   *
   * @param {string} servicio ruta del servicio donde hacemos la petición.
   * @param {number} id id del section a modificar estado.
   * @param {number} nuevoActivo nuevo estado activo.
   */
  async function cambiarEstadoSection(
    servicio: string,
    id: number,
    nuevoActivo: number
  ): Promise<any> {
    const respuesta: respuesta = await servicioModificarEstadoSection(servicio, {
      idSection: id,
      activo: nuevoActivo
    });
    return respuesta;
  }

  /**
   * Esta función llama al servicio servicioModificarEstadoSection para obtener las sections activas de escritura
   *
   * @param {string} servicio ruta del servicio donde hacemos la petición.
   * @param {number} id id del section a modificar estado.
   * @param {number} nuevoActivo nuevo estado activo.
   */
  async function obtenerSectionsEscrituraActivas(servicio: string): Promise<any> {
    const respuesta: respuesta = await servicioObtenerSectionsEscrituraActivas(servicio);

    if (!respuesta.isAxiosError) {
      listaDatos.current = respuesta.data.result;
      return listaDatos.current;
    } else {
      return [];
    }
  }

  return {
    obtenerSectionsByDevice,
    cambiarEstadoSection,
    registroGenerado,
    completadoCorrectamente,
    propiedadesSnackBar,
    obtenerSectionsEscrituraActivas
  };
}
