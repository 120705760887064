import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 180
  },
  wrapper: {
    width: 100 + theme.spacing(2)
  },
  paper: {
    position: 'relative'
  },
  svg: {
    width: 100,
    height: 100
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1
  },
  closeButton: {
    borderRadius: '12%',
    border: 'solid',
    borderWidth: 'thin',
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main}33`
    },
    '&:hover span:nth-child(1) svg': {
      fill: theme.palette.secondary.main
    }
  },
  autocompleteSlider: {
    marginTop: '15px'
  },

  autoCompleteFrecuencia: {
    marginBottom: '15px',
    '& div fieldset': {
      borderColor: theme.palette.secondary.main
    },
    '& div fieldset legend span': {
      color: theme.palette.secondary.main
    }
  },
  autoCompleteRangoInicio: {
    marginBottom: '15px',
    '& label': {
      color: theme.palette.secondary.main
    },
    '& div fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  autoCompleteRangoFinal: {
    marginBottom: '15px',
    '& label': {
      color: theme.palette.primary.main
    },
    '& div fieldset': {
      borderColor: theme.palette.primary.main
    }
  }
}));

export default useStyles;
