/**
 * @alias Section
 * @typedef Section
 * @description Esta interaz se corresponde con la tabla sections de la base de datos
 * @property { number } id id del item
 * @property { string } nombre nombre de la section
 * @property { string } tipo  tipo de la section
 * @property { string } etiqueta  etiqueta de la section
 * @property { boolean | number } metodo  metodo de la section
 * @property { boolean | number } activo indica si la section está activa
 * @property { string } path  path de la section
 * @property { string } valor valor de la section
 * @property { string } parametros  parametros de la section
 * @property { number } idTipoDevice  id del tipo device asociada a la section
 * @property { boolean | number } verSiempre indica si el section debe estar visible
 * @property { number } idPlugin id del plugin asociado a la section
 * @property { string } valorReal valor real que tiene la section obtenida a través del canal
 **/

export interface section {
  id?: number;
  nombre: string;
  tipo?: string;
  etiqueta?: string;
  metodo?: boolean | number;
  activo?: boolean | number;
  path?: string;
  valor?: string;
  parametros?: string;
  idTipoDevice: number;
  siempreVisible?: boolean | number;
  idPlugin?: number;
  nombrePlugin?: string;
  valorReal?: string;
}

export const objectSection = (elemento?: section): section => {
  let section: section;

  elemento === undefined
    ? (section = {
        id: 0,
        nombre: '',
        idTipoDevice: 0,
        etiqueta: ''
      })
    : (section = {
        id: elemento.id,
        nombre: elemento.nombre,
        idTipoDevice: elemento.idTipoDevice
      });
  return section;
};
