/**
 * Hook capturador de eventos, para que el objeto que pasamos por parámetros, los elementos HTML que usamos con el Hook, deben tener declarado el id como el nombre de la propiedad del objeto a formar.
 * Por ejemplo: const usuario = {nombre:"Juan" , edad:26}, los input que formarán el objeto deben tener la propiedad id => <Input id={"nombre"} /> <Input id={"edad"} />
 * @param objeto
 * @param setObjeto
 */
export function useEventChange(objeto: any, setObjeto: any) {
  /**
   * Eventos de Autocomplete que sean de tipo múltiple selección.
   * @param event
   * @param value
   */
  const handleMultipleAutocompleteChange = (event: any, value?: any) => {
    const respuestaMultiple: Array<number> = [];
    let id: Array<number> = [];

    if (event.currentTarget.nodeName === 'svg')
      if (event.currentTarget.parentElement.nextSibling.id.length !== 0) {
        id.push(event.currentTarget.parentElement.nextSibling.id);
      } else {
        id.push(event.currentTarget.parentElement.parentElement.children[2].id);
      }

    if (event.currentTarget.nodeName === 'LI') id = event.target.id.split('-');

    if (value.length === 0) {
      setObjeto({
        ...objeto,
        [id[0]]: []
      });
    } else {
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        respuestaMultiple.push(element.id);
      }
      setObjeto({
        ...objeto,
        [id[0]]: respuestaMultiple
      });
    }
  };

  /**
   * Eventos de Autocomplete que sean selección única.
   * @param event
   * @param value
   */
  const handleAutocompleteChange = (event: any, value?: any) => {
    if (value != null) {
      const id = event.target.id.split('-');
      setObjeto({
        ...objeto,
        [id[0]]: value.id
      });
    } else {
      const id = event.currentTarget.parentElement.previousSibling.id;
      setObjeto({ ...objeto, [id]: undefined });
    }
  };

  /**
   * Eventos de Autocomplete que sean selección única, pero que devuelve todo el objeto
   * @param event
   * @param value
   */
  const handleAutocompleteObjetoChange = (event: any, value?: any) => {
    if (value != null) {
      const id = event.target.id.split('-');
      setObjeto({
        ...objeto,
        [id[0]]: value
      });
    } else {
      const id = event.currentTarget.parentElement.previousSibling.id;
      setObjeto({ ...objeto, [id]: undefined });
    }
  };

  /**
   * Evento de Inputs.
   * @param target
   */
  const handleInputChange = ({ target }: any) => {
    setObjeto({
      ...objeto,
      [target.id]: target.value.trim()
    });
  };

  /**
   * Evento de Inputs. No se permite introducir un 'espacio' como primer caracter, ni tampoco pegar.
   * @param event
   */
  const handleInputInvalid = (event: any) => {
    if ((event.target.value[0] === undefined && event.key === ' ') || event.type === 'paste') {
      event.preventDefault();
    }
  };

  const handleTabChange = (event: any, value: any) => {
    setObjeto({
      ...objeto,
      icon:
        event.currentTarget.children[0].id ??
        event.currentTarget.children[0].children[0].id ??
        event.target.alt
    });
  };

  return {
    handleMultipleAutocompleteChange,
    handleAutocompleteChange,
    handleInputChange,
    handleInputInvalid,
    handleTabChange,
    handleAutocompleteObjetoChange
  };
}
