import React from 'react';
import { DispositivoManager } from '../../../../../../dispositivos/DispositivoManager';
import SettingsIcon from '@mui/icons-material/Settings';
import useStyles from '../../styles';
import { IconButton, Tooltip } from '@mui/material';
import { TarjetaInterface } from '../../../../resources/interfaces/interfaceTarjeta';
import ControlContext from '../../../../../../../context/control/ControlContext';
import { dictionary_dispositivos } from '../../../../../../../resources/enums/plainText';
import { enumComponentes } from '../../../../../../../resources/enums/enumComponente';

export function Settings(props: { datosTarjeta: TarjetaInterface }): JSX.Element {
  const classes = useStyles();
  const { setControl } = React.useContext(ControlContext);
  return (
    <Tooltip
      title={dictionary_dispositivos.MODIFICAR_DISPOSITIVO}
      className={classes.tooltip}
      placement="top"
      arrow
    >
      <IconButton
        color="secondary"
        aria-label="configuración dispositivo"
        style={{ padding: '0 6px 0 6px' }}
        onClick={() =>
          setControl(
            <DispositivoManager
              dispositivoSeleccionado={props.datosTarjeta}
              key={enumComponentes.MODIFICAR_DISPOSITIVO}
            />,
            true
          )
        }
      >
        <SettingsIcon className={classes.botones_header} />
      </IconButton>
    </Tooltip>
  );
}
