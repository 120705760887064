import React from 'react';
import { Card, CardContent, List, ListItem } from '@mui/material';
import { Grid, CardMedia, CardActions, Typography } from '@mui/material';
import { dictionary_dispositivos, dictionary_generic } from '../../resources/enums/plainText';
import { infoDevice } from './resources/interfaces/interfaceInfoDevice';
import useStyles from './styles';

export default function InfoDevice(props: { device: infoDevice }): JSX.Element {
  const classes = useStyles();
  const activo: string = props.device.activo == 1 ? dictionary_generic.SI : dictionary_generic.NO;
  const tipoDevice: string = props.device.nombreTipoDevice
    ? props.device.nombreTipoDevice.charAt(0).toUpperCase() + props.device.nombreTipoDevice.slice(1)
    : '';

  return (
    <>
      <Card className={classes.tarjeta} elevation={8}>
        <div className={classes.imagen}>
          <CardMedia
            title={dictionary_dispositivos.LOGO}
            className={classes.logotipo}
            component="img"
            image={props.device.logotipoPlugin}
          />
        </div>
        <div className={classes.detalles}>
          <CardContent className={classes.contenido}>
            <Typography variant="h4" className={classes.tituloPlugin} paragraph>
              {props.device.nombrePlugin}
            </Typography>
            <List>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.NOMBRE_DEVICE} {props.device.nombre}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.DESCRIPCION_DEVICE} {props.device.descripcion}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.TIPO_DEVICE} {tipoDevice}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.TIEMPO_REFRESCO}{' '}
                  {props.device.tiempoRefresco != undefined
                    ? props.device.tiempoRefresco / 1000 / 60
                    : dictionary_dispositivos.NO_DISPONIBLE}{' '}
                  {dictionary_dispositivos.MIN}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.TIEMPO_INACTIVIDAD}{' '}
                  {props.device.tiempoInactividad != undefined
                    ? props.device.tiempoInactividad / 1000 / 60 / 60
                    : dictionary_dispositivos.NO_DISPONIBLE}{' '}
                  {dictionary_dispositivos.HORAS}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.DESCRIPCION_PLUGIN} {props.device.descripcionPlugin}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography className={classes.textoTarjeta}>
                  {dictionary_dispositivos.DEVICE_ACTIVO} {activo}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
          <CardActions className={classes.controles}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography component="em" variant="subtitle1" color="textSecondary">
                  {dictionary_dispositivos.VERSION_PLUGIN} {props.device.versionPlugin}
                </Typography>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
    </>
  );
}
