import { desencriptarRespuesta } from '../../../functions/encryption';
import axios from 'axios';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';

let path: string;
let respuesta: any;

/**
 * Función que obtiene elementos filtrados por regla
 * @param { string } servicio  servicio al que necesitamos llamar
 * @param { number } idRegla id de la regla por la que queremos filtrar
 * @returns { Promise<anu> } Promesa que contiene una lista de elementos filtrados por regla
 */
export async function obtenerElementosByRegla(servicio: string, idRegla: number): Promise<any> {
  path = servicio + `/regla/${idRegla}`;
  try {
    if (localStorage.getItem('token'))
      respuesta = await axios.get(process.env.REACT_APP_URL_LOCAL + path, {
        timeout: 10000,
        headers: getHeaders(dictionary_entornos.BACK)
      });

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
