import * as React from 'react';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { Operation } from '../../../graficas/resources/enums/enumOperation';
import { CardProperties, reducerState } from '../../types/typesCardConfigurator';
import {
  allAgregateOptions,
  defaultControlStates,
  initialOptionsAgregateFunctions,
  optionsAutoCompleteFrecuencia,
  sumAgregateOption
} from '../../resources/commonResources';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import ConfiguratorContext from '../../context/ConfiguratorContext';

enum typeActions {
  SET_DATA = 'setData'
}

type setData = {
  type: typeActions.SET_DATA;
  payload: Record<string, unknown>;
};

const initialCardProperties: CardProperties = {
  tarjeta: {
    titulo: '',
    codigoTipoDispositivo: null,
    range: null,
    codigoFuncionAgregada: null,
    codigoTipoMedida: null,
    mostrarGrafica: false,
    mostrarSemaforo: false,
    servicio: 'configuradorTarjetas/comparativeRanges',
    position: null,
    idTipoTarjeta: null
  },
  dispositivos: null,
  semaforo: null
};

const initialCardObject: reducerState = {
  tiposDispositivo: [],
  tiposMedida: [],
  dispositivosDisponibles: [],
  medida: null,
  tipoDispositivo: null,
  range: null,
  operation: null,
  cardReady: false,
  dataObject: initialCardProperties
};

export function useComparativeCard(modulo: number, cardProps: any) {
  const { userDevicesConfiguration } = React.useContext(ConfiguratorContext);
  const [userDives] = userDevicesConfiguration;
  const { controllerRequest } = useControlador();
  const [controlStates, setControlStates] = React.useState(defaultControlStates);
  const reducer = (obj: reducerState, action: setData) => {
    return { ...obj, ...action.payload };
  };

  const [obj, dispatch] = React.useReducer(reducer, {
    ...initialCardObject,
    dataObject: cardProps
      ? { ...cardProps, tarjeta: { ...cardProps.tarjeta, dato: undefined } }
      : initialCardProperties
  });
  const [optionsAgregateFunctions, setOptionsAgregateFunctions] = React.useState(
    initialOptionsAgregateFunctions
  );
  const { dataObject } = obj;

  function handleCardTitle(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, titulo: event.target.value }
        }
      }
    });
  }

  function handleAutoCompleteTiposDispositivo(event: React.SyntheticEvent, value: any) {
    const { codigo } = value ?? { codigo: null };
    //When the device type is changed, fields must be updated.
    setControlStates(defaultControlStates);
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        tipoDispositivo: value,
        tiposMedida: [],
        operation: null,
        cardReady: false,
        range: null,
        medida: null,
        dataObject: {
          ...initialCardProperties,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            codigoTipoDispositivo: codigo,
            titulo: dataObject.tarjeta.titulo
          }
        }
      }
    });
  }

  function handleAutoCompleteTiposMedida(event: React.SyntheticEvent, value: any) {
    const { codigo } = value ?? { codigo: null };
    const measure = parseInt(codigo as string);
    if (
      measure === TiposMedida.CONSUMO ||
      measure === TiposMedida.CONSUMO_CAUDAL ||
      measure === TiposMedida.CONSUMO_CAUDAL_ENTRADA ||
      measure === TiposMedida.GENERADO
    ) {
      setOptionsAgregateFunctions([...initialOptionsAgregateFunctions, sumAgregateOption]);
    } else {
      setOptionsAgregateFunctions(initialOptionsAgregateFunctions);
    }

    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        operation: null,
        range: null,
        medida: value,
        cardReady: false,
        dataObject: {
          ...initialCardProperties,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            titulo: dataObject.tarjeta.titulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo as string,
            codigoTipoMedida: codigo
          }
        }
      }
    });
    setControlStates(defaultControlStates);
  }

  function handleAutoCompleteDispositivos(
    dispositivos: Array<{ canal: string; id: number; nombre: string; idCanal: number }>
  ) {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        ...obj,
        operation: null,
        range: null,
        cardReady: false,
        dataObject: {
          ...initialCardProperties,
          dispositivos: dispositivos,
          tarjeta: {
            ...initialCardProperties.tarjeta,
            titulo: dataObject.tarjeta.titulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo as string,
            codigoTipoMedida: obj.medida?.codigo
          }
        }
      }
    });

    if (dispositivos.length) {
      setControlStates({
        deshabilitarGrafica: false,
        deshabilitarRango: false,
        operationDisabled: false,
        deshabilitarSemaforo: false,
        deshabilitar: true
      });
    } else {
      setControlStates(defaultControlStates);
    }
  }
  function handleRange(event: React.SyntheticEvent, value: any): void {
    const { option } = value ?? { option: null };

    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        range: value,
        dataObject: { ...dataObject, tarjeta: { ...dataObject.tarjeta, range: option } }
      }
    });
  }

  function handleAutoCompleteAgregateFunctions(
    event: React.SyntheticEvent,
    value: { name: string; code: string; data: Operation } | null
  ) {
    const { code } = value ?? { code: null };

    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        operation: value,
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, codigoFuncionAgregada: code }
        }
      }
    });
  }

  function handleSemaphoreCheckBox(event: React.SyntheticEvent, checked: boolean): void {
    dispatch({
      type: typeActions.SET_DATA,
      payload: {
        dataObject: {
          ...dataObject,
          tarjeta: { ...dataObject.tarjeta, mostrarSemaforo: checked },
          semaforo: null
        }
      }
    });
  }

  function handleSemaphoreValues(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: 'min' | 'max'
  ) {
    const proper = property === 'min' ? 'max' : 'min';
    const regex = new RegExp('^-?[0-9]*$');

    if (regex.test(event.target.value))
      dispatch({
        type: typeActions.SET_DATA,
        payload: {
          dataObject: {
            ...dataObject,
            semaforo: {
              [property]: event.target.value,
              [proper]: dataObject.semaforo ? dataObject.semaforo[proper] : null
            }
          }
        }
      });
  }

  React.useEffect(() => {
    const dispositivosFiltrados = userDives;
    dispatch({ type: typeActions.SET_DATA, payload: { dispositivos: dispositivosFiltrados } });

    //Obtiene todos los tipos de dispositivo filtrado por dispositivos.
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: 'tiposDispositivo/dispositivos/',
        object: { dispositivos: dispositivosFiltrados.map(({ id }) => id) }
      }
    }).then((deviceTypes) => {
      //If the user click on a previously loaded card
      if (cardProps) {
        const loadedDeviceType = deviceTypes.find(
          (item: any) => item.codigo == cardProps.tarjeta.codigoTipoDispositivo
        );
        controllerRequest({
          type: action.OBTENER_DATOS_POST,
          payload: {
            service: 'tiposMedidas/tipoDispositivo',
            object: {
              idTipoDispositivo: loadedDeviceType.id
            }
          }
        }).then((measureTypes) => {
          const loadedMeasure = measureTypes.find(
            (item: any) => item.codigo == cardProps.tarjeta.codigoTipoMedida
          );
          if (
            loadedMeasure.codigo == TiposMedida.CONSUMO ||
            TiposMedida.CONSUMO_CAUDAL ||
            TiposMedida.CONSUMO_CAUDAL_ENTRADA ||
            TiposMedida.GENERADO
          ) {
            setOptionsAgregateFunctions([sumAgregateOption, ...initialOptionsAgregateFunctions]);
          }
          dispatch({
            type: typeActions.SET_DATA,
            payload: {
              tiposDispositivo: deviceTypes,
              tiposMedida: measureTypes,
              range: optionsAutoCompleteFrecuencia.find(
                (item) => item.option === cardProps.tarjeta.range
              ),
              operation: allAgregateOptions.find(
                (item) => item.code == cardProps.tarjeta.codigoFuncionAgregada
              ),
              dataObject: {
                ...dataObject,
                tarjeta: {
                  ...dataObject.tarjeta,
                  codigoTipoMedida: loadedMeasure.codigo,
                  codigoTipoDispositivo: loadedDeviceType.codigo
                }
              },
              medida: loadedMeasure,
              tipoDispositivo: loadedDeviceType
            }
          });

          if (cardProps.dispositivos && loadedMeasure.tipo === 'number') {
            setControlStates({
              deshabilitarGrafica: false,
              deshabilitarRango: false,
              operationDisabled: false,
              deshabilitarSemaforo: false,
              deshabilitar: true
            });
          } else {
            setControlStates(defaultControlStates);
          }
        });
      } else {
        dispatch({ type: typeActions.SET_DATA, payload: { tiposDispositivo: deviceTypes } });
      }
      // });
    });
  }, []);

  React.useEffect(() => {
    if (obj.tipoDispositivo) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: 'tiposMedidas/tipoDispositivo',
          object: {
            idTipoDispositivo: obj.tipoDispositivo.id
          }
        }
      }).then((res) => {
        dispatch({
          type: typeActions.SET_DATA,
          payload: { tiposMedida: res.filter((medida: any) => medida.tipo === 'number') }
        });
      });
    }
  }, [obj.tipoDispositivo]);

  React.useEffect(() => {
    if (obj.medida) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: 'dispositivos/tipoMedida',
          object: {
            codigoMedida: obj.medida.codigo,
            idModulo: modulo,
            codigoTipoDispositivo: obj.tipoDispositivo?.codigo
          }
        }
      }).then((res) => {
        const dispositivosFiltradosUser = userDives;
        const dispositivosFiltrados: Array<dispositivo> = res.filter((p) => {
          return (
            dispositivosFiltradosUser.filter((y: any) => (y.id | y.idDispositivo) === p.id).length >
            0
          );
        });
        dispatch({
          type: typeActions.SET_DATA,
          payload: { dispositivosDisponibles: dispositivosFiltrados }
        });
      });
    }
  }, [obj.medida]);

  React.useEffect(() => {
    const Operation_Range_Ready = obj.operation && obj.range;
    if (dataObject.tarjeta.mostrarSemaforo && Operation_Range_Ready) {
      if (dataObject.semaforo?.min || dataObject.semaforo?.max) {
        dispatch({ type: typeActions.SET_DATA, payload: { cardReady: true } });
      } else {
        dispatch({ type: typeActions.SET_DATA, payload: { cardReady: false } });
      }
    } else {
      dispatch({
        type: typeActions.SET_DATA,
        payload: {
          cardReady: Operation_Range_Ready != null ? Boolean(Operation_Range_Ready) : false
        }
      });
    }
  }, [obj.operation, obj.range, dataObject.semaforo, dataObject.tarjeta.mostrarSemaforo]);

  return {
    handleAutoCompleteTiposMedida,
    handleAutoCompleteDispositivos,
    handleAutoCompleteTiposDispositivo,
    controlStates,
    obj,
    optionsAgregateFunctions,
    optionsAutoCompleteFrecuencia,
    handleAutoCompleteAgregateFunctions,
    controllerRequest,
    handleCardTitle,
    handleSemaphoreValues,
    handleSemaphoreCheckBox,
    handleRange
  };
}
