import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { dictionary_generic } from '../../resources/enums/plainText';
import { LineColumnChart } from './LineColumnChart';
import Grid from '@mui/material/Grid';
import Table from '../common/table/Table';
import { MultipleYaxisChart } from './MultipleYaxisChart';
import { MapIcons } from '../common/gis/components/iconos/Iconos';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#dialog-chart" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * Componente Dialogo que contiene una grafica. De momento contiene una comparación de datos de ayer con hoy
 * @param { open: boolean, handleClose: () => void, tipoMedida: TiposMedida } props
 * @returns {JSX.Element}
 */
export default function DialogCharts(props: {
  open: boolean;
  handleClose: () => void;
  datosGrafica: any;
  dispositivos?: Array<any>;
}): JSX.Element {
  const columns = [
    {
      name: 'nombre',
      label: 'Nombre'
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    search: false,
    rowsPerPage: 10,
    selectableRows: 'none'
  };

  const { open, handleClose, datosGrafica, dispositivos = null } = props;

  const infoDialog = () => {
    const arrComponents: Array<JSX.Element> = [];

    if (datosGrafica)
      arrComponents.push(
        (dispositivos?.length as number) > 1 ? (
          <MultipleYaxisChart {...datosGrafica} />
        ) : (
          <LineColumnChart {...datosGrafica} />
        )
      );

    if (dispositivos)
      arrComponents.push(
        <Table
          title={'Dispositivos de la tarjeta'}
          columns={columns}
          data={dispositivos}
          options={options}
        ></Table>
      );

    return arrComponents.map((item, index) => (
      <Grid item xs={arrComponents.length > 1 ? (item.props.columns ? 4 : 8) : 12} key={index}>
        {item}
      </Grid>
    ));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="dialog-chart"
        maxWidth={'xl'}
      >
        <DialogTitle style={{ cursor: 'move' }} id="dialog-chart">
          {datosGrafica.titulo}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            {infoDialog()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{dictionary_generic.CERRAR}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
