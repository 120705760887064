import React, { useState, useRef, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useProcesoEliminar } from '../../hooks/useConfirmarEliminar/useProcesoEliminar';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import {
  ContenedorActivado,
  ContenedorDesactivado,
  ContenedorElementos
} from '../common/table/contenedorElementos';
import { BotonTablaFilaEditar, BotonTablaFilaEliminar } from '../common/table/BotonesTablaFila';
import { Grid, Fab, Tooltip } from '@mui/material';
import PaginationRounded from '../common/table/Paginacion';
import { rutasServicios } from '../../resources/enums/enumRutasServicios';
import Table from '../common/table/Table';
import AddIcon from '@mui/icons-material/Add';
import { AlertDialog } from '../common/alertDialog/AlertDialog';
import ControlContext from '../../context/control/ControlContext';
import { ReglasManager } from './ReglasManager';
import { dictionary_reglas } from '../../resources/enums/plainText';
import { DialogoCrearRegla } from '../accionesMotor/dialogos/DialogoCrearRegla';
import { enumTabla } from '../common/table/resources/enumTabla';
import { enumComponentes } from '../../resources/enums/enumComponente';

const useStyles = makeStyles({
  gridBotonTabla: {
    paddingRight: '8px'
  },
  fabBoton: {
    position: 'fixed',
    bottom: '5%',
    right: '25px'
  }
});

export function TablaReglas(props: {
  state: any;
  events: any;
  stateCondiciones: any;
}): JSX.Element {
  const classes = useStyles();
  const { state, stateCondiciones, events } = props;
  const { listaReglas } = state;
  const { setListaReglas } = events;
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { setControl } = React.useContext(ControlContext);
  const theme = useTheme();
  const [openAlertEliminar, setOpenAlertEliminar] = useState<boolean>(false);
  const tituloAlertEliminar = useRef<string>('');
  const { confirmarEliminar, eliminarUnoVariosElementos } = useProcesoEliminar(
    tituloAlertEliminar,
    setOpenAlertEliminar
  );
  function confirmarEliminarAlert(): void {
    confirmarEliminar(listaReglas, setListaReglas, rutasServicios.REGLAS);
  }

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'nombre', label: 'Nombre' },
    { name: 'fechaCreacion', label: 'Fecha de creación' },
    { name: 'descripcion', options: { display: 'excluded' } },
    {
      name: 'activo',
      label: 'Activo',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (value: number): JSX.Element => {
          if (value === 1) return <ContenedorActivado />;
          else return <ContenedorDesactivado />;
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              {
                <ContenedorElementos
                  elemento={
                    <BotonTablaFilaEditar
                      datosTabla={tableMeta}
                      elemento={dictionary_reglas.REGLAS}
                    />
                  }
                  colorElemento={theme.palette.secondary.main}
                  colorFondo={`${theme.palette.secondary.main}33`}
                  clase="botones"
                />
              }
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEliminar
                    datosTabla={tableMeta}
                    eliminarElemento={eliminarUnoVariosElementos}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    sortOrder: {
      name: 'nombreDispositivo',
      direction: 'asc'
    },
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (listaReglas.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <ReglasManager
            reglaSeleccionada={listaReglas[cellMeta.dataIndex]}
            key={enumComponentes.MODIFICAR_REGLA}
          />
        );
      }
      if (listaReglas.length == 0) {
        setControl(<ReglasManager key={enumComponentes.REGLAS} />);
        events.abrirDialogoReglas();
      }
    },
    onRowsDelete: (rowsDeleted: { data: Array<{ index: number; dataIndex: number }> }) => {
      eliminarUnoVariosElementos(rowsDeleted.data.map((r) => listaReglas[r.dataIndex]));
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  return (
    <>
      {!openSmartTeliaBackdrop ? (
        <Table
          title={dictionary_reglas.REGLAS}
          columns={columns}
          data={listaReglas}
          options={options}
        ></Table>
      ) : (
        <></>
      )}
      <Tooltip accessKey="a" title={dictionary_reglas.CREAR_REGLA} arrow>
        <Fab
          tabIndex={0}
          color="secondary"
          aria-label="add"
          onClick={() => events.abrirDialogoReglas()}
          className={classes.fabBoton}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <DialogoCrearRegla state={state} events={events} stateCondiciones={stateCondiciones} />
      <AlertDialog
        titulo={tituloAlertEliminar.current}
        open={openAlertEliminar}
        setOpen={setOpenAlertEliminar}
        onConfirm={confirmarEliminarAlert}
      />
    </>
  );
}
