import { LocalizationProvider, TimePicker, DatePicker } from '@mui/lab';
import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box } from '@mui/material/';

import Autocomplete from '@mui/material/Autocomplete';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { enumLabel } from '../../../resources/enums/plainText';
import { operador } from '../../reglas/resources/interfaces/interfaceOperador';
import { TipoCondicionTemporal } from '../../reglas/resources/enums/enumTipoCondicionTemporal';
import { DateComponent, TimeComponent } from '../../common/dateComponent/DateComponent';

export const CondicionTemporal = (props: {
  stateCondiciones: any;
  events: any;
  state: any;
}): JSX.Element => {
  const {
    condicionCompleta,
    dialogoCondiciones,
    hora,
    opcionTemporalEscogida,
    diaSemana,
    momentoDia
  } = props.stateCondiciones;
  const {
    setCondicionCompleta,
    convertirOperadorAFrase,
    setHora,
    setOpcionTemporalEscogida,
    setDiaSemana,
    setMomentoDia
  } = props.events;
  const { handleAutocompleteObjetoChange } = useEventChange(
    condicionCompleta,
    setCondicionCompleta
  );
  const listaOperadoresTemporales: operador[] = dialogoCondiciones.operadores.filter(
    (o: operador) => o.id != 3 && o.id != 4
  );
  const opciones: any[] = [
    { label: 'Hora', value: TipoCondicionTemporal.HORA },
    { label: 'Día de la semana', value: TipoCondicionTemporal.SEMANA },
    { label: 'Fecha', value: TipoCondicionTemporal.FECHA },
    { label: 'Amanecer / Atardecer', value: TipoCondicionTemporal.SOLAR }
  ];

  //  Los días de la semana se comparan mediante la función getDay(), pero como
  //  van guardados en millis, para asignar días de la semana deben ir establecidos en una fecha
  //  millis. Es por eso que los valores corresponden a los días 20 a 26 de junio de 2022, por lo
  //  que al hacer getDay() nos devolverá un día de Lunes a Domingo.

  const diasSemana: any[] = [
    { label: 'Lunes', value: '1655726400000' },
    { label: 'Martes', value: '1655812800000' },
    { label: 'Miércoles', value: '1655899200000' },
    { label: 'Jueves', value: '1655985600000' },
    { label: 'Viernes', value: '1656072000000' },
    { label: 'Sábado', value: '1656158400000' },
    { label: 'Domingo', value: '1656244800000' }
  ];

  const amanecerAtardecer: any[] = [
    { label: 'Amanecer', value: 'amanecer' },
    { label: 'Atardecer', value: 'atardecer' }
  ];
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box p={1} flexGrow={3}>
          <Autocomplete
            value={opcionTemporalEscogida}
            disableClearable
            id="tipoTemporal"
            options={opciones}
            size="small"
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setOpcionTemporalEscogida(newValue);
            }}
            renderInput={(params) => <TextField {...params} label={'Opción'} variant="outlined" />}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          {opcionTemporalEscogida.value != '' && (
            <Box p={1} flexGrow={3}>
              <Autocomplete
                value={condicionCompleta.operador as operador}
                disableClearable
                id="operador"
                size="small"
                options={listaOperadoresTemporales as operador[]}
                getOptionLabel={(option) =>
                  option.operador &&
                  convertirOperadorAFrase(
                    option.operador,
                    opcionTemporalEscogida.value
                  )[0].toLocaleUpperCase() +
                    convertirOperadorAFrase(option.operador, opcionTemporalEscogida.value).substr(1)
                }
                onChange={handleAutocompleteObjetoChange}
                renderInput={(params) => (
                  <TextField {...params} label={enumLabel.OPERADOR} variant="outlined" />
                )}
              />
            </Box>
          )}

          {opcionTemporalEscogida.value == TipoCondicionTemporal.HORA && (
            <Box p={1}>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={hora}
                  onChange={(newValue) => setHora(newValue as Date)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                  ampm={false}
                />
              </LocalizationProvider> */}
              <TimeComponent
                value={hora}
                onChange={(newValue) => setHora(new Date(newValue))}
                calendarPosition={'left'}
              />
            </Box>
          )}

          {opcionTemporalEscogida.value == TipoCondicionTemporal.FECHA && (
            <Box p={1}>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={hora}
                  inputFormat="dd/MM"
                  onChange={(newValue) => setHora(newValue as Date)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider> */}
              <DateComponent
                label={enumLabel.FECHA_INICIO}
                value={hora}
                onChange={(newValue) => {
                  setHora(new Date(newValue));
                }}
                calendarPosition={'left-end'}
              />
            </Box>
          )}

          {opcionTemporalEscogida.value == TipoCondicionTemporal.SEMANA && (
            <Box p={1} flexGrow={3}>
              <Autocomplete
                value={diaSemana}
                disableClearable
                id="valor"
                options={diasSemana}
                size="small"
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setDiaSemana(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={'Día de la semana'} variant="outlined" />
                )}
              />
            </Box>
          )}

          {opcionTemporalEscogida.value == TipoCondicionTemporal.SOLAR && (
            <Box p={1} flexGrow={3}>
              <Autocomplete
                value={momentoDia}
                disableClearable
                id="valor"
                options={amanecerAtardecer}
                size="small"
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setMomentoDia(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={'Momento'} variant="outlined" />
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
