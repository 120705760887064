import * as React from 'react';
import { Box, Grid, Grow, Icon, Typography } from '@mui/material';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { dictionary_generic } from '../../../../resources/enums/plainText';
import { IconosTiposMedida } from '../../../common/iconosTiposMedida/IconosTiposMedida';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { useGraficas } from '../../../graficas/hooks/useGraficas/useGraficas';
import { GraphTypes } from '../../../graficas/resources/enums/enumGraphTypes';
import DialogCharts from '../../../graficasApexCharts/DialogCharts';
import { getId } from '../../../graficas/functions/funciones';
import { cardData } from '../resources/types/CardData';
import { Operation } from '../../../graficas/resources/enums/enumOperation';
import { Color } from '../../../../resources/enums/enumColores';
import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';

const operations: any = {
  1: Operation.MAX,
  2: Operation.SUM,
  3: Operation.MAX,
  4: Operation.MIN
};

export function Card1x1(props: cardData | any): JSX.Element {
  const { controllerRequest } = useControlador();
  const classes = useStyles();
  const { obtenerDatosGrafica } = useGraficas();

  const { tarjeta, dispositivos, semaforo, handleCardClick = null } = props;

  const { servicio, codigoTipoMedida, titulo, ...rest } = tarjeta;

  const [cardData, setCardData] = React.useState(tarjeta);
  const [transition, setTransition] = React.useState(true);
  const [dialogInfo, setDialogInfo] = React.useState<{
    openDialogInfo: boolean;
    datos: any;
  }>({
    openDialogInfo: false,
    datos: null
  });

  // Abre gráfico de la tarjeta.
  const handleClickOpenCharts = async () => {
    const datosGrafica = await obtenerDatosGrafica(
      codigoTipoMedida,
      rest.range,
      operations[rest.codigoFuncionAgregada],
      GraphTypes.comparativo,
      dispositivos,
      undefined,
      undefined,
      undefined,
      { openSmarteliaBackdrop: true, closeSmarteliaBackdrop: true },
      dispositivos.length > 4 &&
        (codigoTipoMedida == TiposMedida.CONSUMO ||
          codigoTipoMedida == TiposMedida.CONSUMO_CAUDAL_ENTRADA ||
          codigoTipoMedida == TiposMedida.CONSUMO_CAUDAL),
      undefined,
      undefined,
      undefined,
      tarjeta.idModulo
    );

    setDialogInfo({ datos: datosGrafica, openDialogInfo: true });
  };

  //Cierra el gráfico.
  const handleCloseCharts = () => {
    setDialogInfo({ openDialogInfo: false, datos: null });
  };

  //Realiza la llamada para obtener datos, una vez los tenga lo actualiza.
  React.useEffect(() => {
    const dataObject = {
      range: rest.range,
      codigoTipoDispositivo: rest.codigoTipoDispositivo,
      codigoTipoMedida: codigoTipoMedida,
      dispositivos: Array.isArray(dispositivos) ? getId(dispositivos) : [dispositivos.id],
      operation: rest.codigoFuncionAgregada,
      idModulo: rest.idModulo
    };

    if (tarjeta.dato === undefined || tarjeta.dato === null) {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: servicio,
          object: { ...dataObject }
        }
      }).then((response) => {
        setCardData({
          ...cardData,
          dato: response.dato ?? dictionary_generic.NO_DATA,
          unidad: response.unidad
        });
        if (tarjeta.mostrarSemaforo) {
          const dangerValue = semaforo.max
            ? parseInt(semaforo.max) * 0.05
            : semaforo.min
            ? parseInt(semaforo.min) * 0.05
            : null;

          semaforo.color =
            response.dato < parseInt(semaforo.min) || response.dato > parseInt(semaforo.max)
              ? Color.ERROR
              : dangerValue
              ? response.dato < parseInt(semaforo.min) + dangerValue ||
                response.dato > parseInt(semaforo.max) - dangerValue
                ? Color.WARNING
                : Color.OK
              : Color.OK;
        }
      });
    }
  }, []);

  return (
    <>
      <Grid item xs={6} sm={4} lg={2} maxHeight={'182px'}>
        <Grow in={transition} timeout={650} style={{ height: 'auto' }}>
          <div
            style={{ height: '100%', cursor: handleCardClick ? 'context-menu' : 'auto' }}
            onContextMenu={(event) => {
              event.preventDefault();
              if (handleCardClick) {
                handleCardClick(event, cardData.position, setTransition, cardData.idTipoTarjeta);
              }
            }}
          >
            <CustomTooltip title={titulo} placement={'bottom'}>
              <Box component="div" className={classes.boxColumn}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Icon color="secondary" className={classes.iconMedida}>
                    <IconosTiposMedida
                      icono={codigoTipoMedida.toString()}
                      style={{ fontSize: '1.35rem', width: '1em', height: '1em' }}
                      color={'primary'}
                    />
                  </Icon>

                  {tarjeta.mostrarGrafica && cardData.dato !== dictionary_generic.NO_DATA ? (
                    <Icon
                      className={classes.extraIcon}
                      component={'div'}
                      onClick={handleClickOpenCharts}
                      sx={{ zIndex: 1 }}
                    >
                      <AddIcon color="primary" fontSize="large" />
                    </Icon>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography
                    className={classes.value}
                    style={{ position: 'relative' }}
                    noWrap={cardData.dato ? cardData.dato.toString().length > 10 : false}
                  >
                    {cardData.dato !== undefined && cardData.dato !== null ? (
                      <>
                        <span
                          style={{
                            fontSize: cardData.dato.toString().length >= 5 ? '0.65rem' : 'inherit',
                            color:
                              cardData.dato !== dictionary_generic.NO_DATA &&
                              tarjeta.mostrarSemaforo
                                ? semaforo.color
                                : 'inherit'
                          }}
                        >
                          {cardData.dato !== dictionary_generic.NO_DATA
                            ? cardData.dato.toLocaleString()
                            : cardData.dato}
                        </span>{' '}
                        <span className={classes.measure}>{cardData.unidad}</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'end'}>
                  <Typography className={classes.valueText} noWrap>
                    {titulo}
                  </Typography>
                </Box>
              </Box>
            </CustomTooltip>
          </div>
        </Grow>
      </Grid>
      {dialogInfo.openDialogInfo && (
        <DialogCharts
          open={dialogInfo.openDialogInfo}
          handleClose={handleCloseCharts}
          datosGrafica={dialogInfo.datos}
          dispositivos={dispositivos}
        />
      )}
    </>
  );
}
