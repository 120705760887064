import { ReactComponent as highTemperature } from 'resources/icons/iconosTiempo/high-temperature-alert.svg';
import { ReactComponent as thunderstorm } from 'resources/icons/iconosTiempo/thunderstorm-alert.svg';
import { ReactComponent as rain } from 'resources/icons/iconosTiempo/rain-alert.svg';
import { ReactComponent as coastalEvent } from 'resources/icons/iconosTiempo/coastal-event-alert.svg';
import { ReactComponent as avalanches } from 'resources/icons/iconosTiempo/avalanches-alert.svg';
import { ReactComponent as fog } from 'resources/icons/iconosTiempo/fog-alert.svg';
import { ReactComponent as flood } from 'resources/icons/iconosTiempo/flooding-alert.svg';
import { ReactComponent as forestFire } from 'resources/icons/iconosTiempo/forest-fire-alert.svg';
import { ReactComponent as lowTemperature } from 'resources/icons/iconosTiempo/low-temperature-alert.svg';
import { ReactComponent as rainFlood } from 'resources/icons/iconosTiempo/rain-flood-alert.svg';
import { ReactComponent as snow } from 'resources/icons/iconosTiempo/snow-alert.svg';
import { ReactComponent as wind } from 'resources/icons/iconosTiempo/wind-alert.svg';
import { ReactComponent as defaultAlert } from 'resources/icons/iconosTiempo/warning-sign.svg';

export const weatherAlertsColors = Object.freeze([
  { level: ['Moderate', 'Yellow'], color: '#ffdb23', text: 'amarilla' },
  { level: ['Severe', 'Orange'], color: '#ff9500', text: 'naranja' },
  { level: ['Extreme', 'Red'], color: '#ff0100', text: 'rojo' }
]);

export const weatherAlertIcons = Object.freeze([
  { id: 'default-alert', icon: defaultAlert, text: 'Aviso meteorológico' },
  { id: 'high-temperature', icon: highTemperature, text: 'Altas temperaturas' },
  { id: 'rain', icon: rain, text: 'LLuvia' },
  { id: 'thunderstorm', icon: thunderstorm, text: 'Tormenta eléctrica' },
  { id: 'wind', icon: wind, text: 'Viento' },
  { id: 'snow', icon: snow, text: 'Nieve' },
  { id: 'coastal-event', icon: coastalEvent, text: 'Evento costero' },
  { id: 'avalanche', icon: avalanches, text: 'Avalancha' },
  { id: 'fog', icon: fog, text: 'Niebla' },
  { id: 'flood', icon: flood, text: 'Inundación' },
  { id: 'forest-fire', icon: forestFire, text: 'Incendio forestal' },
  { id: 'low-temperature', icon: lowTemperature, text: 'Bajas temperaturas' },
  { id: 'rain-flood', icon: rainFlood, text: 'Inundación por lluvia' }
]);
export const DEFAULT_ALERT_LEVEL = Object.freeze(weatherAlertsColors[0]);
export const DEFAULT_ALERT_ICON = Object.freeze(weatherAlertIcons[0]);
