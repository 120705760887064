import React from 'react';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { useReducerAcciones } from '../hooks/useReducerAcciones';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { enumLabel } from '../../../resources/enums/plainText';
import { grupo } from '../../grupos/resources/interfaces/interfaceGrupo';

export function FormularioActuacionGrupal(props: { state?: any; events?: any }): JSX.Element {
  const { stateAcciones, events } = useReducerAcciones(
    props.events,
    enumTiposAcciones.actuacion_grupal
  );
  const { setActuacionGrupalCompleta, handleCreateAccion } = events;
  const { dialogoAcciones, actuacionGrupalCompleta } = stateAcciones;
  const { handleInputChange, handleInputInvalid, handleAutocompleteObjetoChange } = useEventChange(
    actuacionGrupalCompleta,
    setActuacionGrupalCompleta
  );

  return (
    <form id="formularioActuacionGrupal">
      <Grid container spacing={2}>
        <Grid item container sm={12} justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={10} md={6}>
            <Autocomplete
              value={actuacionGrupalCompleta.grupo}
              disableClearable
              id={'grupo'}
              options={dialogoAcciones.grupos as grupo[]}
              size={'small'}
              getOptionLabel={(option) => option.codigo}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.GRUPOS_DISPOSITIVO} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Autocomplete
              value={actuacionGrupalCompleta.tipoDevice}
              disableClearable
              id={'tipoDevice'}
              options={dialogoAcciones.tiposDevice}
              size={'small'}
              getOptionLabel={(option) => option.nombre}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.TIPO_DEVICE} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Autocomplete
              value={actuacionGrupalCompleta.section}
              disableClearable
              id={'section'}
              options={dialogoAcciones.sections}
              size={'small'}
              getOptionLabel={(option) => (option.etiqueta ? option.etiqueta : '')}
              onChange={handleAutocompleteObjetoChange}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.SECTION} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label={enumLabel.VALOR}
              id="valor"
              type="text"
              required
              onChange={handleInputChange}
              onKeyDown={handleInputInvalid}
              onPaste={handleInputInvalid}
              value={actuacionGrupalCompleta.valor}
              autoComplete="off"
            />
          </Grid>
          <Grid item container sm={12} justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleCreateAccion}>
              Crear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
