import axios, { AxiosRequestConfig } from 'axios';
import { encriptarJSON } from 'functions/encryption';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { ServiceValues } from 'shared/services/types';
import { Key } from 'swr';

/**Get device types by report Id.
 * @param {number} reportId Id of the report.
 * @returns {{string | null, AxiosRequestConfig}} key used by swr and axios request config.
 */
export function getDeviceTypesByReport(props: { reportCode: string }): ServiceValues {
  const { reportCode } = props;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getDeviceTypesByReport,
    headers: getHeaders(dictionary_entornos.BACK),
    data: encriptarJSON({ reportCode })
  };
  const key: Key = `${swrServiceRoutes.getDeviceTypesByReport}-${JSON.stringify(props)}`;

  return { key, request };
}

/** Download report in pdf
 * @param {string} color
 * @param {unknown} head
 * @param {unknown} body
 * @param {unknown} foot
 * @returns {ServiceValues} key used by swr and axios request config.
 */
export function downloadReportInPDF(): ServiceValues {
  const key = `${swrServiceRoutes.downloadReportInPDF}`;
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.downloadReportInPDF,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  return { key, request };
}
