import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { dictionary_asistente_permisos } from '../../../resources/enums/plainText';
import useStylesTarjetas from '../../reglas/stylesTarjetas';
import { DialogoSeleccionTipoAccion } from './DialogoSeleccionTipoAccion';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { TextBoxTelia } from '../../common/textboxTelia/TextBoxTelia';

const useStylesDialogo: any = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export function DialogoCrearAccion(props: { state: any; events: any }): JSX.Element {
  enum title {
    'Seleccionar tipo de acción',
    'Añadir acción'
  }
  const classes = useStylesTarjetas();
  const classesDialogo = useStylesDialogo();
  const { state, events } = props;
  const { asistenteReglas } = state;
  const componentesDialogo: any = {
    0: <DialogoSeleccionTipoAccion state={state} events={events} />,
    1: state.panelAccionActivo
  };
  const mensajesTelia: any = {
    0: 'Seleccione el tipo de actuación que se ejecutará cuando se cumplan todas tus condiciones.',
    1: state.mensajeTelia
  };

  return (
    <Dialog open={state.estadoDialogoAcciones} onClose={events.toggleDialogoAcciones}>
      <DialogTitle>
        {title[asistenteReglas.activeStep]}
        <IconButton
          aria-label="close"
          onClick={events.toggleDialogoAcciones}
          className={classes.botonCerrarDialogo}
        >
          <CloseIcon />
        </IconButton>
        <IconButton aria-label="help" color="secondary" onClick={events.handleButtonTelia}>
          <HelpOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {state.estadoTelia && <TextBoxTelia texto={mensajesTelia[asistenteReglas.activeStep]} />}
      </DialogContent>
      <DialogContent>{componentesDialogo[asistenteReglas.activeStep]}</DialogContent>
      <DialogContent>
        <div>
          <div>
            <Button
              variant="contained"
              disabled={asistenteReglas.activeStep === 0}
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={dictionary_asistente_permisos.BOTON_ATRAS}
            >
              {dictionary_asistente_permisos.BOTON_ATRAS}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={dictionary_asistente_permisos.BOTON_FINALIZAR_CONDICION_ACCION}
            >
              Finalizar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
