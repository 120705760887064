import React from 'react';
import {
  dispositivo,
  objectDispositivo
} from '../../../../dispositivos/resources/interfaces/interfaceDispositivo';

/**
 * Proporciona la información necesaria para la tarjeta del mapa al hacer click en un marcador.
 * @param { dispositivo[] } markers Lista de dispositivo.
 * @param { string } id Para saber el dispositivo.
 * @param { React.MutableRefObject<google.maps.Geocoder> } geocoder Obtiene la imagen de google.
 * @returns { Promise<any> } Promesa que contiene la información de la tarjeta
 */

export async function getInfo(
  markers: dispositivo[],
  id: string,
  geocoder: React.MutableRefObject<google.maps.Geocoder>
): Promise<any> {
  const dato = markers.filter((marker) => marker.id?.toString() === id.toString());

  // en caso de no encontrar coincidencia, devolvemos un objeto dispositivo vacio
  if (!(dato && dato.length)) return objectDispositivo();

  dato[0].imagen = `https://maps.googleapis.com/maps/api/streetview?size=450x155&location=${dato[0].latitud},${dato[0].longitud}&heading=0&pitch=-0.76&key=AIzaSyBiQ2p69A_5krInjp2q-0EufD-jr-V0sso`;

  const getDatos = async () => {
    try {
      return new Promise((resolve, reject) => {
        geocoder.current.geocode(
          { location: { lat: dato[0].latitud, lng: dato[0].longitud } },
          (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
            if (status === 'OK') {
              resolve((dato[0].direccion = results![0].formatted_address));
            } else {
              reject(status);
            }
          }
        );
      });
    } catch (exception) {
      return exception;
    }
  };

  await getDatos();
  return dato[0];
}
