import { useContext, useReducer, useEffect } from 'react';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { dialogoAcciones } from '../../reglas/resources/interfaces/interfaceDialogoAcciones';
import {
  objectActuacionCompleta,
  actuacionCompleta
} from '../../reglas/resources/interfaces/interfaceActuacionCompleta';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import {
  objectDispositivo,
  dispositivo
} from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { objectTipoActuacion } from '../../tiposActuaciones/resources/interfaces/interfaceTipoActuacion';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';
import { tiposDispositivo } from '../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { enumTiposAcciones } from '../../reglas/resources/enums/enumTiposAcciones';
import { objectEmail, Email } from '../../reglas/resources/interfaces/interfaceEmail';
import {
  cambioEstadoReglaCompleta,
  objectCambioEstadoReglaCompleta
} from '../../reglas/resources/interfaces/interfaceCambioEstadoReglaCompleta';
import {
  dictionary_error_reglas,
  dictionary_correcto_reglas
} from '../../../resources/enums/plainText';
import { actionsAcciones } from '../../reglas/resources/enums/enumActionsAcciones';
import { reducerAcciones } from '../resources/reducers/reducerAcciones';
import { objectWhatsapp, Whatsapp } from '../../reglas/resources/interfaces/interfaceWhatsapp';
import {
  actuacionGrupalCompleta,
  objectActuacionGrupalCompleta
} from '../../reglas/resources/interfaces/interfaceActuacionGrupalCompleta';
import { StateAcciones } from '../resources/interfaces/interfaceStateAcciones';
import { objectTipoDevice } from '../../device/resources/interfaces/interfaceTipoDevice';
import { objectSection } from '../../sections/resources/interfaces/interfaceSection';
import { UseReducerAcciones } from '../resources/interfaces/interfaceUseReducerAcciones';

export function useReducerAcciones(eventosRegla: any, tipoAccion: number): UseReducerAcciones {
  const { addAccionRegla } = eventosRegla;
  const { setSnackBar } = useContext(SnackBarContext);
  const { controllerRequest, propiedadesSnackBar } = useControlador();
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);

  const initState: StateAcciones = {
    dialogoAcciones: {
      tiposDispositivo: [],
      dispositivos: [],
      tiposActuaciones: [],
      grupos: [],
      tiposDevice: [],
      sections: []
    },
    actuacionCompleta: objectActuacionCompleta(),
    cambioEstadoReglaCompleta: objectCambioEstadoReglaCompleta(),
    emailCompleto: objectEmail(),
    whatsappCompleto: objectWhatsapp(),
    actuacionGrupalCompleta: objectActuacionGrupalCompleta()
  };

  const [stateAcciones, dispatch] = useReducer(reducerAcciones, initState);

  /**
   * Función que modifica las propiedades del snackbar
   * @param { any } props propiedades del snackbar
   */
  function mostrarSnackBar(props: any) {
    setSnackBar({
      open: true,
      severity: props.current.severity,
      text: props.current.texto
    });
  }

  function onChangeCambioEstadoRegla() {
    dispatch({ type: actionsAcciones.TOGGLE_ESTADO_REGLA });
  }
  function setCambioEstadoRegla(cambioEstadoRegla: cambioEstadoReglaCompleta) {
    dispatch({ type: actionsAcciones.SET_CAMBIO_ESTADO_REGLA, payload: cambioEstadoRegla });
  }

  function setDispositivos(dispositivos: dispositivo[]) {
    dispatch({ type: actionsAcciones.SET_DISPOSITIVOS, payload: dispositivos });
  }

  function setActuacionCompleta(actuacion: actuacionCompleta) {
    dispatch({ type: actionsAcciones.SET_ACTUACION_COMPLETA, payload: actuacion });
  }

  function setActuacionGrupalCompleta(actuacionGrupal: actuacionGrupalCompleta) {
    dispatch({ type: actionsAcciones.SET_ACTUACION_GRUPAL_COMPLETA, payload: actuacionGrupal });
  }
  function setEmail(email: Email) {
    dispatch({ type: actionsAcciones.SET_EMAIL, payload: email });
  }

  function setWhatsapp(whatsapp: Whatsapp) {
    dispatch({ type: actionsAcciones.SET_WHATSAPP, payload: whatsapp });
  }

  function setDialogoAcciones(dialogo: dialogoAcciones) {
    dispatch({ type: actionsAcciones.SET_DIALOGO_ACCIONES, payload: dialogo });
  }
  //Carga inicial de datos
  useEffect(() => {
    const cargarDatos = async () => {
      setDialogoAcciones({
        ...stateAcciones.dialogoAcciones,
        tiposDispositivo: await controllerRequest({
          type: action.OBTENER_TODOS_DATOS,
          payload: {
            servicio: rutasServicios.TIPOS_DISPOSITIVO_ACTUACION
          }
        }),
        grupos: await controllerRequest({
          type: action.OBTENER_TODOS_DATOS,
          payload: {
            servicio: rutasServicios.GRUPOS
          }
        }),
        tiposActuaciones: []
      });
    };
    setOpenSmartTeliaBackdrop(true);
    cargarDatos();
    setOpenSmartTeliaBackdrop(false);
  }, []);

  //Carga los dispositivos cuando se actualiza el tipo de dispositivo
  useEffect(() => {
    const cargarSections = async () => {
      setDispositivos(
        await controllerRequest({
          type: action.OBTENER_DISPOSITIVO_ACTUACION_BY_TIPO_DISPOSITIVO,
          payload: {
            servicio: rutasServicios.DISPOSITIVOS,
            idTipoDispositivo: stateAcciones.actuacionCompleta.tipoDispositivo.id
          }
        })
      );
      setActuacionCompleta({
        ...stateAcciones.actuacionCompleta,
        dispositivo: objectDispositivo()
      });
    };
    stateAcciones.actuacionCompleta.tipoDispositivo.nombre !== '' && cargarSections();
  }, [stateAcciones.actuacionCompleta.tipoDispositivo]);

  //Carga los tipos de actuaciones cuando se actualiza el dispositivo
  useEffect(() => {
    const cargarSections = async () => {
      stateAcciones.actuacionCompleta.dispositivo.nombre !== ''
        ? setDialogoAcciones({
            ...stateAcciones.dialogoAcciones,
            tiposActuaciones: await controllerRequest({
              type: action.OBTENER_TIPOS_ACTUACIONES_POR_DISPOSITIVO,
              payload: {
                servicio: rutasServicios.TIPOS_ACTUACIONES,
                idDispositivo: stateAcciones.actuacionCompleta.dispositivo.id
              }
            })
          })
        : setDialogoAcciones({
            ...stateAcciones.dialogoAcciones,
            tiposActuaciones: []
          });
      setActuacionCompleta({
        ...stateAcciones.actuacionCompleta,
        tipoActuacion: objectTipoActuacion()
      });
    };
    cargarSections();
  }, [stateAcciones.actuacionCompleta.dispositivo]);

  //Carga los tipos de device cuando se actualiza el grupo
  useEffect(() => {
    const cargarTiposDevice = async () => {
      setDialogoAcciones({
        ...stateAcciones.dialogoAcciones,
        tiposDevice: await controllerRequest({
          type: action.OBTENER_DATOS_BY_ID,
          payload: {
            servicio: 'tiposDevice/grupo',
            id: stateAcciones.actuacionGrupalCompleta.grupo.id
          }
        }),
        sections: []
      });
    };

    stateAcciones.actuacionGrupalCompleta.grupo.codigo !== '' && cargarTiposDevice();
    setActuacionGrupalCompleta({
      ...stateAcciones.actuacionGrupalCompleta,
      tipoDevice: objectTipoDevice()
    });
  }, [stateAcciones.actuacionGrupalCompleta.grupo]);

  //Carga las section cuando se actualiza el tipo de device
  useEffect(() => {
    const cargarSections = async () => {
      setDialogoAcciones({
        ...stateAcciones.dialogoAcciones,
        sections: await controllerRequest({
          type: action.OBTENER_DATOS_BY_ID,
          payload: {
            servicio: 'sections/activo/escritura/tipoDevice',
            id: stateAcciones.actuacionGrupalCompleta.tipoDevice.id
          }
        })
      });
    };

    stateAcciones.actuacionGrupalCompleta.tipoDevice.tipo !== '' && cargarSections();
    setActuacionGrupalCompleta({
      ...stateAcciones.actuacionGrupalCompleta,
      section: objectSection()
    });
  }, [stateAcciones.actuacionGrupalCompleta.tipoDevice]);

  //Cuando cambio la section he de limpiar el valor
  useEffect(() => {
    setActuacionGrupalCompleta({
      ...stateAcciones.actuacionGrupalCompleta,
      valor: ''
    });
  }, [stateAcciones.actuacionGrupalCompleta.section]);
  function handleCreateAccion() {
    if (isPanelRellenadoCorrectamente()) {
      //Establezco las propiedades del snackbar indicando que se ha hecho correctamente
      propiedadesSnackBar.current.severity = enumSeverity.SUCCESS;
      propiedadesSnackBar.current.texto = dictionary_correcto_reglas.ACTUACION_CORRECTA;

      switch (tipoAccion) {
        case enumTiposAcciones.actuacion_dispositivo:
          addAccionRegla({
            tipoAccion: {
              id: enumTiposAcciones.actuacion_dispositivo,
              tipo: 'Actuación sobre dispositivo'
            },
            actuacionDispositivo: stateAcciones.actuacionCompleta
          });
          setDialogoAcciones({
            ...stateAcciones.dialogoAcciones,
            tiposActuaciones: [],
            dispositivos: []
          });
          break;

        case enumTiposAcciones.cambio_estado_regla:
          addAccionRegla({
            tipoAccion: {
              id: enumTiposAcciones.cambio_estado_regla,
              tipo: 'Cambio de estado de regla'
            },
            cambioEstadoRegla: stateAcciones.cambioEstadoReglaCompleta
          });
          break;

        case enumTiposAcciones.emails:
          addAccionRegla({
            tipoAccion: {
              id: enumTiposAcciones.emails,
              tipo: 'Enviar email'
            },
            email: stateAcciones.emailCompleto
          });
          setEmail(objectEmail());
          break;

        case enumTiposAcciones.whatsapp:
          addAccionRegla({
            tipoAccion: {
              id: enumTiposAcciones.whatsapp,
              tipo: 'Enviar WhatsApp'
            },
            whatsapp: {
              ...stateAcciones.whatsappCompleto,
              numeroWhatsapp: parseInt(stateAcciones.whatsappCompleto.numeroWhatsapp)
            }
          });
          setWhatsapp(objectWhatsapp());
          break;
        case enumTiposAcciones.actuacion_grupal:
          addAccionRegla({
            tipoAccion: {
              id: enumTiposAcciones.actuacion_grupal,
              tipo: 'Actuación grupal'
            },
            actuacionGrupal: stateAcciones.actuacionGrupalCompleta
          });
          setActuacionGrupalCompleta(objectActuacionGrupalCompleta());
          setDialogoAcciones({
            ...stateAcciones.dialogoAcciones,
            tiposDevice: [],
            sections: []
          });
          break;
      }
      setActuacionCompleta(objectActuacionCompleta());
    }
    mostrarSnackBar(propiedadesSnackBar);
  }

  const validateEmail = (email: string) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  };
  function isPanelRellenadoCorrectamente(): boolean {
    //Voy comprobando que cada elemento del panel exista correctamente

    switch (tipoAccion) {
      case enumTiposAcciones.actuacion_dispositivo:
        if (
          stateAcciones.actuacionCompleta.tipoDispositivo === undefined ||
          stateAcciones.actuacionCompleta.tipoDispositivo.nombre == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_TIPO_DISPOSITIVO;
          return false;
        }
        if (
          stateAcciones.actuacionCompleta.dispositivo === undefined ||
          stateAcciones.actuacionCompleta.dispositivo.nombre == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_DISPOSITIVO;
          return false;
        }
        if (
          stateAcciones.actuacionCompleta.tipoActuacion == undefined ||
          stateAcciones.actuacionCompleta.tipoActuacion.nombre == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_TIPO_ACTUACION;
          return false;
        }
        //Si no da error, es que está correctamente realizado
        return true;

      case enumTiposAcciones.cambio_estado_regla:
        if (stateAcciones.cambioEstadoReglaCompleta.regla.nombre == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_REGLA;
          return false;
        }
        //Si no da error, es que está correctamente realizado
        return true;
      case enumTiposAcciones.emails:
        //Si los campos están vacíos
        if (
          stateAcciones.emailCompleto.mensaje == '' ||
          stateAcciones.emailCompleto.asunto == '' ||
          stateAcciones.emailCompleto.direccion == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_EMAIL;
          return false;
        }
        //Si el asunto es demasiado grande
        if (stateAcciones.emailCompleto.asunto.length > 45) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.ASUNTO_GRANDE;
          return false;
        }
        //Si el mensaje es demasiado grande
        if (stateAcciones.emailCompleto.mensaje.length > 500) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.MENSAJE_GRANDE;
          return false;
        }
        //Si el email no tiene el formato adecuado
        if (!validateEmail(stateAcciones.emailCompleto.direccion)) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.DIRECCION_INCORRECTA;
          return false;
        }
        return true;

      case enumTiposAcciones.whatsapp:
        //Si no hay escrito ni mensaje ni teléfono
        if (
          stateAcciones.whatsappCompleto.numeroWhatsapp == '' ||
          stateAcciones.whatsappCompleto.mensaje == ''
        ) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_WHATSAPP;
          return false;
        }
        //Si el teléfono es menor de 9 dígitos
        if (stateAcciones.whatsappCompleto.numeroWhatsapp.length < 9) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NUMERO_ERRONEO;
          return false;
        }
        //Si el mensaje excede los 500 caracteres
        if (stateAcciones.whatsappCompleto.mensaje.length > 500) {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.MENSAJE_GRANDE;
          return false;
        }
        return true;

      case enumTiposAcciones.actuacion_grupal:
        //Si no hay grupo
        if (stateAcciones.actuacionGrupalCompleta.grupo.codigo == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_GRUPO;
          return false;
        }
        //Si no hay tipo de device
        if (stateAcciones.actuacionGrupalCompleta.tipoDevice.tipo == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_TIPO_DEVICE;
          return false;
        }
        //Si no hay section
        if (stateAcciones.actuacionGrupalCompleta.section.nombre == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_SECTION;
          return false;
        }
        //Si no hay valor
        if (stateAcciones.actuacionGrupalCompleta.valor == '') {
          propiedadesSnackBar.current.severity = enumSeverity.ERROR;
          propiedadesSnackBar.current.texto = dictionary_error_reglas.NO_VALOR;
          return false;
        }
        return true;
      default:
        return false;
    }
  }
  const events = {
    setActuacionCompleta,
    handleCreateAccion,
    onChangeCambioEstadoRegla,
    setCambioEstadoRegla,
    setEmail,
    setWhatsapp,
    setActuacionGrupalCompleta
  };

  return {
    stateAcciones,
    events
  };
}
