import React from 'react';
import { Box, Grow, Paper } from '@mui/material';
export function Tarjeta(props: any): JSX.Element {
  return (
    <Grow in timeout={650} style={{ height: props.height ?? 'auto' }}>
      <div style={{ height: props.height ?? 'auto' }}>
        <Paper
          onClick={props.onClick}
          id={props.id}
          style={{
            height: props.height ?? 'auto',
            boxShadow: 'none',
            border: '2px',
            borderStyle: 'solid',
            borderColor: ' #e0e0e0',
            borderRadius: '15px'
          }}
        >
          <Box
            padding="15px"
            alignItems={props.alignItems}
            justifyContent={props.justifyContent ?? 'end'}
            display="flex"
            flexDirection={props.flexDirection}
            height={props.height !== undefined ? props.height : '166px'}
            style={props.style}
            className={props.className}
          >
            {props.children}
          </Box>
        </Paper>
      </div>
    </Grow>
  );
}
