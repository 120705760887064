export enum dictionary_generic {
  CREAR = 'Crear',
  MODIFICAR = 'Modificar',
  EDITAR = 'Editar',
  ELIMINAR = 'Eliminar',
  UBICACION = 'Ubicación',
  ACTIVADO = 'activado',
  DESACTIVADO = 'desactivado',
  INSTALAR = 'instalar',
  DESINSTALAR = 'desinstalar',
  ACTUALIZAR = 'actualizar',
  MAS_OPCIONES = 'Más opciones',
  MENOS_OPCIONES = 'Menos opciones',
  INFO_SOLO_LECTURA = 'Este elemento está preestablecido por defecto en la aplicación y no se puede modificar',
  CERRAR = 'Cerrar',
  VER = 'Ver',
  MAS_INFORMACION = 'Mas información',
  NOTIFICACIONES = 'Notificaciones de alertas',
  HOME = 'Home',
  POWER = 'Encender/Apagar',
  SINCRONIZAR = 'Sincronizar',
  CANCELAR = 'Cancelar',
  NO_SE_HAN_ENCONTRADO_REGISTROS = 'No se han encontrado registros existentes',
  PROBLEMAS = 'Ha ocurrido un error, vuelva a intentarlo',
  CRUZADO_LOS_CABLES = 'Se nos han cruzado los cables ...',
  REINICIAR = 'reiniciar',
  SI_EL_ERROR_PERSISTE = ' Si el error persiste, puede volver a',
  INICIO = 'Inicio',
  SE_HA_PERDIDO_CONEXION = 'Se ha perdido la conexión con el servidor',
  NOTIFICAR_PROBLEMA = 'Pero no te preocupes porque acabamos de notificar el problema al equipo de soporte para que lo solucionen cuanto antes',
  PETICION_GRAFICA_CANCELADA = 'Petición axios cancelada por cambio de dispositivo',
  MICROFONO = 'microfono',
  RECONOCIMIENTO_VOZ = 'Reconocimiento de voz',
  SI = 'Sí',
  NO = 'No',
  MINUTOS = 'Minutos',
  HORAS = 'Horas',
  DIAS = 'Días',
  NO_DATA = 'Sin datos',
  AYER = 'Ayer',
  HOY = 'Hoy',
  ESTA_SEMANA = 'Esta semana',
  SEMANA_ANTERIOR = 'Semana anterior',
  ESTE_MES = 'Este mes',
  MES_ANTERIOR = 'Mes anterior',
  ESTE_ANIO = 'Este año',
  ANIO_ANTERIOR = 'Año anterior',
  SIN_DATOS_GRAFICA = 'Sin datos para mostrar en la gráfica.',
  SIN_GRAFICAS_DISPONIBLES = 'No hay gráficas disponibles para este dispositivo.',
  SUGERENCIAS = 'Sugerencias',
  ACEPTAR = 'Aceptar',
  UNIT = 'Unidad',
  NO_DEVICES = 'No existen devices disponibles'
}

export enum dictionary_login {
  ACCEDER = 'ACCEDER',
  TEXTO_ACCEDER = 'Acceder a la plataforma',
  TEXTO_CONTRASENA = '¿Olvidaste la contraseña?',
  RESTABLECER_PASS = 'Restablecer contraseña',
  ENVIAR = 'ENVIAR',
  TEXTO_ENVIAR = 'Enviar a la plataforma',
  VOLVER = 'VOLVER',
  TEXTO_VOLVER = 'Volver a la pantalla de Login',
  TEXTO_EMAIL = 'Introduce el nombre de usuario y el email asociado para obtener un código de verificación',
  TEXTO_ENVIADO_CORREO = 'Se ha enviado el código de verificación al correo',
  TEXTO_CODIGO_VERIFICACION = 'Ahora introduce el código de verificación que ha recibido en el correo',
  ERROR_CODIGO_VERIFICACION = '¡Upsss! Parece que algo no ha ido bien El código indicado es INCORRECTO o ha EXPIRADO. Vuelva a intentarlo',
  TEXTO_NUEVA_CONTRASENA = 'Ya puede introducir la nueva contraseña',
  ERROR_EMAIL_USUARIO = 'El email o el usuario no son correctos'
}

export enum dictionary_password {
  PATRON_CONTRASENA = '(?=.*[-!#$%&/()?¡_])(?=.*[A-Z])(?=.*[a-z]).{8,}',
  TEXTO_PATRON_CONTRASENA = 'La contraseña debe tener mínimo 8 caracteres, al menos una letra minúscula, una letra mayúscula y un carácter especial !#$%&/()?¡_-  \n',
  ERROR_CONTRASENA = 'La contraseña no coincide'
}

export enum dictionary_home {
  BIENVENIDO_A = 'Hola',
  MAPA_3D = 'Mapa 3D',
  PREVISION_METEO_SEMANA = 'Previsión meteorológica semanal',
  VELOCIDAD_VIENTO = 'Viento km/h',
  HUMEDAD = 'Humedad',
  IR_ALERTAS_PENDIENTES_RESOLVER = 'Ir a alertas pendientes de resolver',
  SALIDA_DEL_SOL = 'Salida del sol',
  PUESTO_DEL_SOL = 'Puesta del sol',
  IR_A = 'Ir a',
  SENSACION_TERMICA = 'Sensación térmica',
  FECHA = 'Fecha',
  MIS_INTERESES = 'Mis intereses',
  PROBABILIDAD_LLUVIA = 'Probabilidad lluvia',
  INDICE_UV = 'Índice UV',
  RADIACION_ULTRAVIOLETA = 'Índice de radiación ultravioleta',
  ICA = 'ICA',
  INDICE_CALIDAD_DEL_AIRE = 'Índice de calidad del aire',
  NUBOSIDAD = 'Nubosidad'
}

export enum dictionary_informes {
  VER_INFORME = 'Ver informe',
  INFORME = 'informe',
  CONSUMO_MEDIO = 'CONSUMO MEDIO',
  CONSUMO_MINIMO = 'CONSUMO MÍNIMO',
  CONSUMO_MAXIMO = 'CONSUMO MÁXIMO',
  SELECCIONA_UN_INFORME = 'Selecciona un informe',
  RESTABLECER_FILTROS = 'Restablecer filtros',
  TOTAL_CONSUMO = 'Total Consumo:',
  SELECCIONAR_TODOS = 'Seleccionar todos',
  INFORMES = 'informes',
  RESET_REPORT = 'Restablecer informe'
}

export enum dictionary_grupos {
  GRUPOS = 'Grupos',
  CREAR_GRUPO = 'Crear grupo',
  MODIFICAR_GRUPO = 'Modificar grupo',
  PROVOCAR_ERROR = 'Provocar error'
}

export enum dictionary_canales {
  CANALES = 'Canales',
  CREAR_CANAL = 'Crear canal',
  MODIFICAR_CANAL = 'Modificar canal',
  AVISO_MODIFICAR = 'La modificación puede afectar al dispositivo asociado a este canal. ¿Desea continuar?'
}

export enum dictionary_tiposDispositivos {
  TITULO = 'Tipos de Dispositivos',
  TIPOS_DISPOSITIVOS = 'TiposDispositivo',
  CREAR_TIPOS_DISPOSITIVOS = 'Crear tipo de dispositivo',
  MODIFICAR_TIPOS_DISPOSITIVOS = 'Modificar tipo de dispositivo',
  ICONO_TIPOS_DISPOSITIVOS = 'ICONO DEL TIPO DE DISPOSITIVO',
  INFORMACION_TIPO_DISPOSITIVO = 'INFORMACIÓN DEL TIPO DE DISPOSITIVO',
  SELECCIONA_TIPO_DISPOSITIVO = 'Selecciona un tipo de dispositivo',
  TIPOS_DISPOSITIVO = 'Tipos Dispositivos'
}

export enum dictionary_tiposMedidas {
  TITULO = 'Tipos de Medida',
  TIPOS_MEDIDAS = 'TiposMedida',
  CREAR_TIPOS_MEDIDAS = 'Crear tipo de medida',
  MODIFICAR_TIPOS_MEDIDAS = 'Modificar tipo de medida'
}

export enum dictionary_tiposUnidades {
  TITULO = 'Tipos de Unidad',
  TIPOS_UNIDADES = 'TiposUnidad',
  CREAR_TIPOS_UNIDADES = 'Crear tipo de unidad',
  MODIFICAR_TIPOS_UNIDADES = 'Modificar tipo de unidad'
}

export enum dictionary_dispositivos {
  DISPOSITIVOS = 'Dispositivos',
  CREAR_DISPOSITIVO = 'Crear dispositivo',
  MODIFICAR_DISPOSITIVO = 'Modificar dispositivo',
  UBICACION = 'Ubicación',
  PATRON_UBICACION = '(^-?[0-9]{1,3}[.][0-9]+$)',
  MENSAJE_UBICACION = 'Formato de coordenadas válidas: 39.154448, -3.02021',
  FRECUENCIA_ACTUALIZACION = 'Frecuencia de Actualización',
  OTRA_INFORMACION = 'Otra Información',
  AVISO_MODIFICAR = 'La modificación puede afectar al canal asociado a este dispositivo. ¿Desea continuar?',
  LOGO = 'Logo',
  NOMBRE_DEVICE = 'Nombre:',
  DESCRIPCION_DEVICE = 'Descripción:',
  TIPO_DEVICE = 'Tipo:',
  TIEMPO_REFRESCO = 'Tiempo de refresco:',
  TIEMPO_INACTIVIDAD = 'Tiempo de inactividad:',
  NO_DISPONIBLE = 'No disponible',
  MIN = 'Min',
  HORAS = 'Horas',
  DESCRIPCION_PLUGIN = 'Descripción del plugin:',
  DEVICE_ACTIVO = 'Activo:',
  VERSION_PLUGIN = 'Versión:',
  IDENTIFICADOR = 'Identificador',
  TOOLTIP_IDENTIFICADOR = 'Puede indicar aquí el número de catastro, la dirección, el nombre del dispositivo o cualquier forma para poder identificarlo',
  SELECCIONA_DISPOSITIVO = 'Selecciona un dispositivo',
  GESTOR_DOCUMENTAL = 'Documentos asociados',
  GESTOR_DOCUMENTAL_GENERAL = 'Documentación general',
  DEVICE_IN = 'Dispositivos entrada',
  DEVICE_OUT = 'Dispositivos salida'
}

export enum dictionary_etiquetas {
  ETIQUETAS = 'Etiquetas',
  CREAR_ETIQUETAS = 'Crear etiqueta',
  MODIFICAR_ETIQUETAS = 'Modificar etiqueta',
  SELECCIONAR_ETIQUETA = 'Selecciona una etiqueta'
}

export enum dictionary_plugins {
  PLUGIN = 'Plugin',
  PLUGINS = 'Plugins',
  INSTALADO = '-instalado',
  DISPONIBLE = '-disponible',
  PLUGINS_DISPONIBLES = 'Plugins disponibles',
  PLUGINS_INSTALADOS = 'Plugins instalados',
  DESCATALOGADO = 'Este plugin se encuentra descatalogado',
  DESACTUALIZADO = 'Este plugin se encuentra desactualizado',
  ESTADO_PLUGIN = 'Estado del Plugin',
  INSTALLED = 'El plugin ha sido instalado, pero está a la espera de configurarse.',
  READY = 'El plugin ha sido configurado, pero está a la espera de ponerse en ejecución.',
  RUNNING = 'El plugin está ejecutándose correctamente y no hay ningún error.',
  ERROR = 'El plugin se ha ejecutado pero ha habido algún tipo de error.',
  PAUSE = 'El plugin no se está ejecutando porque se ha pausado.',
  AVISO_DESINSTALAR = 'Si lo desinstalas, serán eliminados todos los devices y sections pertenecientes al plugin.',
  AVISO_INSTALAR = 'La instalación del plugin puede durar unos pocos minutos dependiendo de la conexión.',
  INFO_ESTADO = 'Información de estados'
}

export enum dictionary_devices {
  DEVICES = 'Devices',
  CREAR_DEVICE = 'Crear device',
  INFORMACION_DEVICE = 'Información device',
  MODIFICAR_DEVICE = 'Modificar device',
  DEVICE = 'Device',
  FRECUENCIA = 'Frecuencia de Actualización',
  INACTIVIDAD = 'Tiempo máximo de inactividad',
  PARAMETROS = 'Parámetros de configuración',
  AVISO_MODIFICAR = 'La modificación puede afectar a las sections asociadas a este device. ¿Desea continuar?',
  SIN_SECTIONS = 'Este device no tiene sections',
  ACTIVAR = 'Activar',
  DESACTIVAR = 'Desactivar',
  DEVICES_DEL_PLUGIN = 'Ver devices del plugin',
  INFO_DEL_DEVICE = ' Información del device'
}

export enum dictionary_sections {
  SECTION = 'Section',
  SECTIONS = 'Sections',
  CREAR_SECTION = 'Crear section',
  MODIFICAR_SECTION = 'Modificar section',
  AVISO_ACTIVAR = 'La section se habilitará o deshabilitará para todos los device de ese tipo. ¿Desea continuar?',
  AVISO_MODIFICAR = 'La modificación puede afectar al device asociado a esta section. ¿Desea continuar?'
}

export enum dictionary_alertas {
  ALERTAS = 'Alertas',
  CREAR_ALERTA = 'Crear alerta',
  MODIFICAR_ALERTA = 'Modificar alerta',
  ALERTAS_REGISTRADAS = 'Alertas registradas',
  ALERTAS_PENDIENTES = 'Alertas pendientes',
  VER_MAS_ALERTAS = 'Ver más alertas',
  ALERTAS_DEL_DISPOSITIVO = 'Alertas de ',
  ALERTAS_PENDIENTES_DE_RESOLVER = 'Alertas sin resolver',
  NO_HAY_ALERTAS = 'No hay alertas pendientes para revisar'
}

export enum dictionary_reglas {
  REGLA = 'Regla',
  REGLAS = 'Reglas',
  CREAR_REGLA = 'Crear una regla',
  MODIFICAR_REGLA = 'Modificar una regla',
  CONDICIONES = 'Condiciones',
  ACCIONES = 'Acciones',
  INFORMACION_REGLA = 'Información de la regla',
  CREAR_CONDICION = 'Añadir condición',
  CREAR_ACCION = 'Añadir acción'
}

export enum dictionary_error_reglas {
  NO_CONDICION_ACCION = '¡Vaya! Se debió olvidar de añadir al menos una condición y una acción.',
  NO_CONDICION = '¡Vaya! Se debió olvidar de añadir al menos una condición.',
  NO_ACCION = '¡Vaya! Se debió olvidar de añadir al menos una acción.',
  NO_TIPO_DISPOSITIVO = '¡Vaya! Se debió olvidar añadir el tipo de dispositivo.',
  NO_OPERADOR = '¡Vaya! Se debió olvidar añadir el operador.',
  NO_VALOR = '¡Vaya! Se debió olvidar añadir el valor a comparar.',
  NO_HORA = 'Vaya! Se te debió olvidar añadir la hora',
  NO_CANAL = '¡Vaya! Se debió olvidar añadir el canal del dispositivo.',
  NO_DISPOSITIVO = '¡Vaya! Se debió olvidar añadir el dispositivo.',
  CONDICION_REPETIDA = 'Ya hay una condición con esos mismos datos.',
  NO_NOMBRE = 'Debes rellenar el nombre de la regla.',
  NO_DESCRIPCION = 'Debes rellenar la descripción de la regla.',
  NO_TIPO_ACTUACION = 'Debes seleccionar una acción.',
  NO_REGLA = 'Debes seleccionar una regla.',
  NO_EMAIL = 'Debes rellenar los campos de dirección, asunto y mensaje',
  ASUNTO_GRANDE = 'El asunto del email debe tener 45 caracteres máximo.',
  MENSAJE_GRANDE = 'El mensaje debe tener 500 caracteres máximo.',
  DIRECCION_INCORRECTA = 'Formato incorrecto de correo. Compruébelo de nuevo.',
  NO_WHATSAPP = 'Debes rellenar el número de teléfono y el mensaje a enviar.',
  NUMERO_ERRONEO = 'Debes introducir un número de teléfono correcto.',
  NO_GRUPO = '¡Vaya! Se debió olvidar añadir el grupo.',
  NO_TIPO_CONDICION = 'Debes escoger un tipo de condición.',
  NO_TIPO_DEVICE = '¡Vaya! Tienes que escoger un tipo de device.',
  NO_SECTION = '¡Ups! Tienes que elegir una section',
  DESCRIPCION_LARGA = 'La descripción debe tener 300 caracteres máximo.',
  NOMBRE_LARGO = 'El nombre debe tener 100 caracteres máximo.'
}
export enum dictionary_correcto_reglas {
  CONDICION_CORRECTA = 'La condición se ha creado correctamente.',
  ACTUACION_CORRECTA = 'Acción añadida correctamente. Puedes añadir otro tipo de acción o terminar la regla.',
  CREAR_REGLA_CORRECTA = 'La regla se ha creado correctamente.',
  MODIFICAR_REGLA_CORRECTA = 'La regla se ha modificado correctamente'
}

export enum dictionary_alertDialog {
  ACEPTAR = '¡Adelante con ello!',
  CANCELAR = 'Por ahora no',
  CERRAR = 'Cerrar'
}

export enum dictionary_eliminar {
  TITULO_ALERT_ELIMINAR_PLURAL = '¿Desea eliminar los elementos seleccionados?',
  TEXTO_ALERT_ELIMINAR_PLURAL = 'Ha seleccionado algún elemento preestablecido por defecto en la aplicación, estos no serán eliminados',
  TITULO_ALERT_ELMINAR = 'El elemento seleccionado está preestablecido por defecto en la aplicación y no se puede eliminar',
  TEXTO_ALERT_ELIMINAR = '¿Desea eliminar el elemento seleccionado?'
}

export enum dictionary_graficas {
  TEXTO_INFORMATIVO = 'Para visualizar las gráficas seleccione uno o varios dispositivos',
  DISPOSITIVO_SELECCIONADOS = 'Dispositivos Seleccionados: '
}

export enum dictionary_usuarios {
  PERFIL = 'Perfil',
  MI_CUENTA = 'Mi cuenta',
  CERRAR_SESION = 'Cerrar sesión',
  TITULO_TABLA = 'Usuarios',
  MODIFICAR_USUARIO = 'Modificar usuario',
  MODIFICAR_PERFIL = 'Perfil',
  MODIFICAR_NOMBRE_USUARIO = 'Está modificando el nombre de usuario ¿Está de acuerdo?',
  PATRON_NOMBRE_USUARIO = '.{3,}',
  MENSAJE_NOMBRE_USUARIO = 'Debe contener al menos 3 caracteres',
  AVISO_NOMBRE_USUARIO = '¡¡IMPORTANTE!! Este campo es el nombre del usuario que se usa para hacer login.',
  PERMISOS = 'Permisos',
  ASISTENTE_PERMISOS = 'Abrir asistente de permisos'
}

export enum dictionary_cctv {
  ANTERIOR = 'Cámara anterior',
  SIGUIENTE = 'Cámara siguiente',
  VER_TODO = 'Ver todo',
  ABRIR = 'Abrir',
  CERRAR = 'Cerrar',
  PANTALLA_COMPLETA = 'Pantalla Completa',
  REPLAY = 'Replay',
  PLAY = 'Play',
  PAUSE = 'Pause',
  STREAM = 'Stream',
  POR_DEFECTO = 'Por defecto',
  PRINCIPAL = 'Principal',
  SECUNDARIO = 'Secundario',
  OTRO = 'Otro',
  NO_HAY_IMAGENES = 'NO HAY IMÁGENES',
  VELOCIDAD = 'Velocidad',
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical'
}

export enum dictionary_asistente_permisos {
  TEXTO_PERFIL_ADMINISTRADOR = ' El perfil de Administrador local de la plataforma SmartTelia otorga los permisos necesarios al usuario para poder realizar la configuración y parametrización de los módulos instalados en las solución, así como controlar la asignación de perfiles y roles a los distintos usuarios que tengan permiso de acceso a dicho módulo. También podrá realizar la parametrización y configuración de los dispositivos asociados al módulo al que tenga acceso y podrá crear y editar reglas de automatización que afecten a los dispositivos asociados a dicho módulo.',
  TEXTO_PERFIL_USUARIO = ' El perfil de Usuario de la plataforma SmartTelia otorga los permisos necesarios al usuario para poder acceder a uno o varios módulos de la plataforma para poder realizar las actividades del día a día de trabajo. Este usuario podrá visualizar las alertas, consultar la información, generar informes, etc. Pero no podrá realizar cambios en la parametrización del módulo o módulos a los que tenga acceso ni podrá modificar la configuración de los dispositivos que en él se incluyan. Tampoco tendrá acceso al motor de reglas de la plataforma.',
  TEXTO_PERFIL_TECNICO = ' El perfil de Técnico de la plataforma SmartTelia otorga los permisos necesarios al usuario para poder realizar la configuración y parametrización de los módulos instalados en las solución, así como controlar la asignación de perfiles y roles a los distintos usuarios que tengan permiso de acceso a dicho módulo. También podrá realizar la parametrización y configuración de los dispositivos asociados al módulo al que tenga acceso y podrá crear y editar reglas de automatización que afecten a los dispositivos asociados a dicho módulo.',
  TEXTO_PERFIL_INVITADO = ' El perfil de Invitado de la plataforma SmartTelia otorga los permisos necesarios al usuario para poder acceder a uno o varios módulos de la plataforma para poder realizar las actividades del día a día de trabajo pero sin tener la opción de poder hacer modificación alguna en la plataforma. Este tipo de usuario únicamente podrá consultar la información que aparecen en los distintos módulos, pero no tendrá acceso ni a la generación de informes ni a las opciones de parametrización y configuración tanto del módulo como de los dispositivos que estén asociados al mismo. ',
  BOTON_ATRAS = 'Atras',
  BOTON_SIGUIENTE = 'Siguiente',
  BOTON_FINALIZAR = 'Finalizar',
  BOTON_FINALIZAR_CONDICION_ACCION = 'FinalizarCondicionAccion'
}

export enum dictionary_iluminacion_viaria {
  CONSUMO_MENSUAL = 'Consumo mensual',
  CONSUMO_SEMANAL = 'Consumo semanal',
  CONSUMO_ANUAL = 'Consumo anual',
  CONSUMO_TOTAL = 'Consumo total',
  CONSUMO_DIARIO = 'Consumo diario',
  COMPARATIVA_CONSUMO_MENSUAL = 'Comparativo de consumo mensual',
  COMPARATIVA_CONSUMO_ANUAL = 'Comparativo de consumo anual',
  DISPOSITIVOS_MAYOR_CONSUMO = 'Dispositivos que más consumen'
}

export enum dictionary_tipos_actuaciones {
  TIPOS_ACTUACIONES = 'Tipos de actuaciones',
  CREAR_TIPO_ACTUACION = 'Crear tipo de actuación',
  MODIFICAR_TIPO_ACTUACION = 'Modificar tipo de actuación',
  NO_SECTION = '¡Vaya! Debes añadir la section relacionada con el tipo de actuación',
  NO_NOMBRE = 'No se ha especificado el nombre del tipo de actuación',
  NO_VALOR = 'No se ha especificado el valor del tipo de actuación',
  TIPO_ACTUACION = 'Tiposactuaciones'
}

/**
 * Enumerado de los Label (etiquetas) de inputs
 */

export enum enumLabel {
  // Común
  ACTIVAR_DESACTIVAR = 'Desactivar/Activar',
  DISPOSITIVO = 'Dispositivo',
  DISPOSITIVOS = 'Dispositivos',
  TIPO_DISPOSITIVO = 'Tipo de dispositivo',
  FRECUENCIA = 'Frecuencia',
  SECTION = 'Section',
  VALOR = 'Valor',
  // Login
  USUARIO = 'Usuario',
  CONTRASENA = 'Contraseña',
  EMAIL = 'Email',
  CODIGO_VERIFICACION = 'Código de verificación',
  CONTRASENA_ACTUAL = 'Contraseña actual',
  CONTRASENA_NUEVA = 'Nueva contraseña',
  CONTRASENA_REPETIR = 'Repetir contraseña',
  // Dispositivos
  DEVICE = 'Device',
  NOMBRE_DISPOSITIVO = 'Nombre de dispositivo',
  GRUPOS_DISPOSITIVO = 'Grupos del dispositivo',
  MARCA_DISPOSITIVO = 'Marca del dispositivo',
  MODELO_DISPOSITIVO = 'Modelo del dispositivo',
  NUM_SERIE_DISPOSITIVO = 'N° de serie del dispositivo',
  LATITUD = 'Latitud',
  LONGITUD = 'Longitud',
  ETIQUETAS = 'Etiquetas',
  // Canales
  NOMBRE_CANAL = 'Nombre de canal',
  TIPO_MEDIDA = 'Tipo de medida',
  TIPO_UNIDAD = 'Tipo de unidad',
  // Device
  PLUGIN = 'Plugin',
  TIPO_DEVICE = 'Tipo de device',
  NOMBRE_DEVICE = 'Nombre de device',
  DESCRIPCION_DEVICE = 'Descripción de device',
  // Etiquetas
  CODIGO_ETIQUETA = 'Código de etiqueta',
  NOMBRE_ETIQUETA = 'Nombre de etiqueta',
  // GRUPOS
  CODIGO_GRUPO = 'Código de grupo',
  // TIPO UNIDAD
  CODIGO_UNIDAD = 'Código de unidad',
  NOMBRE_UNIDAD = 'Nombre de unidad',
  // TIPO MEDIDA
  CODIGO_MEDIDA = 'Código de medida',
  NOMBRE_MEDIDA = 'Nombre de medida',
  // TIPO DISPOSITIVO
  CODIGO_TIPO_DISPOSITIVO = 'Código de tipo de dispositivo',
  NOMBRE_TIPO_DISPOSITIVO = 'Nombre de tipo de dispositivo',
  // PERFIL / USUARIOS
  PERFIL = 'Perfil',
  NOMBRE = 'Nombre',
  APELLIDOS = 'Apellidos',
  DIRECCION = 'Dirección',
  CODIGO_POSTAL = 'Código Postal',
  POBLACION = 'Población',
  PROVINCIA = 'Provincia',
  CORREO_ELECTRONICO = 'Correo electrónico',
  TELEFONO = 'Teléfono',
  NOMBRE_USUARIO = 'Nombre de usuario',
  CAMBIAR_CONTRASENA = 'Cambiar contraseña',
  // REGLAS
  REGLAS = 'Reglas',
  NOMBRE_REGLA = 'Nombre de la regla',
  DESCRIPCION_REGLA = 'Descripción de la regla',
  // PANEL CONDICIONES
  CANAL = 'Canal',
  OPERADOR = 'Operador',
  TIPO_CONDICION = 'Tipo de condición',
  // PANEL EMAIL
  DIRECCION_EMAIL = 'Dirección de email',
  ASUNTO = 'Asunto',
  MENSAJE = 'Mensaje',
  // TIPOS ACTUACIONES
  NOMBRE_TIPO_ACTUACION = 'Nombre de tipo de actuación',
  ACCION = 'Acción',
  DURATION = 'Duración',
  //GESTOR_DOCUMENTAL
  DATOS_FABRICANTE = 'Datos del fabricante',
  INSTALADOR = 'Instalador',
  FECHA_DE_INSTALACION = 'Fecha de instalación',
  FECHA_DE_EXPIRACION_GARANTIA = 'Fecha de expiración garantía',
  FECHAS_DE_MANTENIMIENTO_PREVENTIVO = 'Fecha de mantenimiento preventivo',
  UBICACION_DEL_DISPOSITIVO = 'Ubicación del dispositivo',
  SERVICIO_TECNICO = 'Servicio técnico',
  CUPS = 'CUPs',
  // OTROS
  FECHA_DESDE = 'Fecha desde',
  FECHA_HASTA = 'Fecha hasta',
  FECHA_INICIO = 'Fecha Inicio',
  FECHA_FIN = 'Fecha Fin',
  RANGO_FECHAS = 'Rango de fechas'
}

export enum dictionary_unidades {
  kwh = 'kWh',
  mWh = 'MWh',
  grados = '°',
  porcentaje = '%',
  m3 = 'm³',
  gradosCentigrados = '°C',
  kmh = 'km/h',
  mm = 'mm',
  pH = 'pH',
  orp = 'mV',
  miligramoLitro = 'mg/l',
  cm = 'cm'
}

export enum dictionary_tarjetas_modulos {
  MINIMA = 'MÍNIMA',
  MEDIA = 'MEDIA',
  MAXIMA = 'MÁXIMA'
}

export enum dictionary_gestion_agua_potable {
  ABASTECIMIENTO = 'Abastecimiento',
  DISTRIBUCION = 'Distribución',
  DEPURACION = 'Depuración',
  RENDIMIENTO_DE_LA_DEPURADORA = 'Rendimiento de depuración'
}

export enum dictionary_tipos_acciones {
  CREAR_WHATSAPP = 'Enviar WhatsApp',
  CREAR_ACTUACION_DISPOSITIVO = 'Actuar sobre dispositivo',
  CREAR_EMAIL = 'Enviar correo electrónico',
  CREAR_CAMBIO_REGLA = 'Cambiar estado de regla',
  CREAR_ACTUACION_GRUPAL = 'Crear actuación grupal'
}

export enum dictionary_configurator {
  EDIT_CARD_DIALOG_TITLE = '¿Desea modificar esta tarjeta?',
  EDIT_CARD_DIALOG_CONTENT = 'Tenga en cuenta que puede perder la configuración inicial.',
  DELETE_CARD_DIALOG_TITLE = '¿Desea eliminar esta tarjeta?',
  DELETE_CARD_DIALOG_CONTENT = 'Deberá guardar la configuración después de eliminar la tarjeta si quiere mantener los cambios.',
  CORRECT_SNACKBAR_SAVED_TEXT = 'La configuración se ha guardado correctamente',
  CORRECT_SNACKBAR_DELETED_TEXT = 'La configuración se ha eliminado correctamente',
  INCORRECT_SNACKBAR_TEXT = 'No se ha realizado la acción, póngase en contacto con administración.',
  SAVE_CARD_DIALOG_TITLE = '¿Desea modificar la configuración?',
  SAVE_CARD_DIALOG_CONTENT = 'Tenga en cuenta que perderá la configuración inicial.',
  DELETE_CONFIGURATION_TITLE = '¿Desea eliminar la configuración?',
  DELETE_CONFIGURATION_CONTENT = 'Tenga en cuenta que perderá la configuración.',
  EXISTS_OTHER_CARD = 'La celda que quiere usar está siendo utilizada por otra tarjeta. ¿Quiere sustituirla?',
  MEASURE_DATE = 'Fecha medición:',
  TITLE = 'Título',
  DEVICES_TYPES = 'Tipos dispositivos',
  MEASURE_TYPES = 'Tipos medida',
  SELECT_DEVICES = 'Seleccionar dispositivos',
  RANGE = 'Rango',
  AGGREGATE_FUNCTION = 'Función agregada',
  VALUE = 'Valor',
  DIFFERENCE = 'Diferencia',
  SELECT_INPUT_DEVICES = 'Seleccionar dispositivos entrada',
  SELECT_OUTPUT_DEVICES = 'Seleccionar dispositivos salida',
  MESSAGE_SHOW_GRAPH = 'Se mostrará una gráfica con todos los dispositivos desglosados en el rango seleccionado.',
  SHOW_GRPAH = 'Mostrar gráfica'
}

export enum dictionary_entornos {
  GESTION = 'Gestión',
  BACK = 'Backend'
}
