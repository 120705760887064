import * as React from 'react';
import { OpenDetail, ValuesStateDialog } from '../types';

const initial_state: OpenDetail = {
  open: false,
  row: null
};

export function useStateDialogReports(): ValuesStateDialog {
  const [openDetail, setOpenDetail] = React.useState<OpenDetail>(initial_state);

  const handleOpen = (row: any) => {
    setOpenDetail({ open: true, row });
  };

  const handleClose = () => {
    setOpenDetail(initial_state);
  };

  return { handleOpen, handleClose, openDetail };
}
