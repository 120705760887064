import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dictionary_generic, dictionary_unidades } from '../../../../resources/enums/plainText';
import { RangosString } from '../../resources/enums/enumsRangosString';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { HandleError } from '../../../error/resources/types/typeHandleError';
import { getId, getLabels } from '../../functions/funciones';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { Group } from '../../resources/enums/enumGroup';
import { MeasuresText } from '../../resources/enums/enumMeasuresText';
import { Operation } from '../../resources/enums/enumOperation';
import { Rango } from '../../resources/enums/enumRango';
import { ServiciosGraficas } from '../../resources/enums/enumServiciosGraficas';
import { ComparativeChart, NoValues } from '../../resources/interfaces/interfaceCharts';
import { DatosGrafica } from '../../resources/interfaces/interfaceDatosGraficas';
import { servicioObtenerDatosGraficas } from '../../services/obtenerDatosGraficas';

const SERVICE_DEFAULT = ServiciosGraficas.SENSOR;
const file = 'createComparativeGraph';

const agrupacion: any = {
  [Rango.LAST_24H]: Group.HOUR,
  [Rango.WEEKLY]: Group.DAY,
  [Rango.MONTHLY]: Group.DAY,
  [Rango.ANNUALLY]: Group.MONTH,
  [Rango.TODAY]: Group.HOUR,
  [Rango.LAST_MONTH]: Group.DAY,
  [Rango.THIS_MONTH]: Group.DAY,
  [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: Group.HOUR,
  [Rango.LAST_YEAR]: Group.MONTH,
  [Rango.TODAY_IN_THE_PREVIOUS_YEAR]: Group.HOUR,
  [Rango.LAST_WEEK]: Group.DAY,
  [Rango.THIS_WEEK]: Group.DAY,
  [Rango.THIS_YEAR]: Group.MONTH,
  [Rango.YESTERDAY]: Group.HOUR
};

export const createComparativeGraph = async (
  moduloSeleccionado: number,
  medida: TiposMedida | TiposMedida[],
  rango: Rango,
  operation: Operation,
  tipoGrafica: GraphTypes,
  dispositivos: Array<dispositivo>,
  handleError: HandleError,
  unidad?: dictionary_unidades,
  inicio?: string,
  fin?: string,
  mostrarSinAgrupar?: boolean
): Promise<ComparativeChart | NoValues> => {
  const getValor = (d: DatosGrafica) => d.valor;

  const rangosComparativos: any = {
    [Rango.TODAY]: {
      actual: Rango.TODAY,
      anterior: Rango.YESTERDAY,
      tituloMedia: dictionary_generic.AYER,
      nombreNoDispositivo: dictionary_generic.HOY
    },
    [Rango.THIS_WEEK]: {
      actual: Rango.THIS_WEEK,
      anterior: Rango.LAST_WEEK,
      tituloMedia: dictionary_generic.SEMANA_ANTERIOR,
      nombreNoDispositivo: dictionary_generic.ESTA_SEMANA
    },
    [Rango.THIS_MONTH]: {
      actual: Rango.THIS_MONTH,
      anterior: Rango.LAST_MONTH,
      tituloMedia: dictionary_generic.MES_ANTERIOR,
      nombreNoDispositivo: dictionary_generic.ESTE_MES
    },
    [Rango.THIS_YEAR]: {
      actual: Rango.THIS_YEAR,
      anterior: Rango.LAST_YEAR,
      tituloMedia: dictionary_generic.ANIO_ANTERIOR,
      nombreNoDispositivo: dictionary_generic.ESTE_ANIO
    }
  };

  const consulta: any = {
    range: rango,
    startDate: '',
    endDate: '',
    dispositivos: getId(dispositivos),
    group: agrupacion[rango],
    operation: mostrarSinAgrupar ? null : operation,
    measure: medida,
    servicio: Array.isArray(medida)
      ? medida.find(
          (item) =>
            item === TiposMedida.CONSUMO ||
            TiposMedida.CONSUMO_CAUDAL ||
            TiposMedida.CONSUMO_CAUDAL_ENTRADA
        )
        ? ServiciosGraficas.CONSUMO
        : SERVICE_DEFAULT
      : medida == TiposMedida.CONSUMO
      ? ServiciosGraficas.CONSUMO
      : SERVICE_DEFAULT,
    mostrarSinAgrupar: mostrarSinAgrupar
  };

  if (rango === Rango.RANGE) {
    consulta.startDate = inicio as string;
    consulta.endDate = fin as string;
  }

  //Obtención de los datos del rango actual.
  const respuesta: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  //Obtención de los datos del rango anterior para comparar.
  consulta.range = rangosComparativos[rango].anterior;
  const respuestaValorComparativo: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  if (respuesta.isAxiosError) {
    handleError(`[${file}][${servicioObtenerDatosGraficas.name}]`, respuesta.message);
    return { noValues: true };
  }

  //Desestructuración de los datos actuales y comparativos.
  const { result } = respuesta.data;
  const { result: resultValorComparativo } = respuestaValorComparativo.data;

  //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
  //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
  const { labels, condition = 'fecha', formateoFecha } = getLabels(rango);

  let unid;

  const datos: Array<{ data: Array<any>; name: string; type: 'column' | 'line' }> = [];

  function createGraphData(result: Array<any>, tipo: 'column' | 'line', anterior = false) {
    result.forEach(
      ({
        idDispositivo,
        datos: datosResultado
      }: {
        idDispositivo: number;
        datos: Array<DatosGrafica>;
      }) => {
        let data, name;
        //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
        //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
        if (datosResultado.length !== labels.length) {
          data = labels.map((element) => {
            const res = datosResultado.find((item: any) => {
              unid = item.unidad;
              return formateoFecha(new Date(item[condition])).trim() === element;
            });
            return res ? res.valor : null;
          });
        } else {
          data = datosResultado.map(getValor);
        }

        if (mostrarSinAgrupar || dispositivos.length === 0) {
          name = anterior
            ? rangosComparativos[rango].tituloMedia
            : rangosComparativos[rango].nombreNoDispositivo;
        } else {
          if (anterior) {
            name =
              (dispositivos.find((dispositivo) => dispositivo.id === idDispositivo)
                ?.nombre as string) + ` anterior`;
          } else {
            name = dispositivos.find((dispositivo) => dispositivo.id === idDispositivo)
              ?.nombre as string;
          }
        }

        datos.push({ data, name, type: tipo });
      }
    );
  }
  createGraphData(result, 'line');
  createGraphData(resultValorComparativo, 'column', true);

  //Propiedades genéricas del gráfico, como el título, título del eje Y y el valor max para que el gráfico tenga una altura correcta.
  const titulo = Array.isArray(medida) ? 'Comparativo' : MeasuresText[medida];
  const title = `Comparativa de ${titulo} ${RangosString[rango].toLowerCase()}`;
  const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;

  return { datos, labels, title: title, titleYAxis: `${titulo} ${unid ?? unidad}`, max, unid };
};
