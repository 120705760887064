import React, { ChangeEvent, FormEvent, MouseEvent } from 'react';
import { useContext } from 'react';
import UserContext from '../../../context/UserContext';
import ControlContext from '../../../context/control/ControlContext';
import SnackBarContext from '../../../context/SnackBarContext';
import { TablaUsuarios } from '../TablaUsuarios';
import { UsuariosUpdate } from '../UsuariosUpdate';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { useReducer } from 'react';
import { interfaceUseReducer } from '../resources/interfaces/interfaceUseReducer';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { UsuariosManager } from '../UsuariosManager';
import { PerfilUpdate } from '../PerfilUpdate';
import { useEffect } from 'react';
import { desencriptarPassword, encriptarPassword } from '../../../functions/encryption';
import { dictionary_asistente_permisos } from '../../../resources/enums/plainText';
import { EstablecerPerfil } from '../asistentePermisos/EstablecerPerfil';
import { Modulos } from '../../../resources/enums/enumModulo';
import { objReducerUsuarios } from '../resources/types/objReducerUsuarios';
import { actionsReducerUsuarios } from '../resources/types/actionsReducerUsuarios';
import { ACTIONS_REDUCER_USUARIOS } from '../resources/enums/enumActionsUsuarios';
import { PermisosUsuario } from '../resources/interfaces/interfacePermisosUsuario';
import { AccionesComponentes } from '../resources/enums/enumAccionesComponentes';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { enumSeverity } from '../../common/snackbar/resources/enums/enumSeverity';
import { objectUsuario, usuario } from '../../../resources/interfaces/interfaceUsuario';
import { SelectChangeEvent } from '@mui/material';

import { componente } from '../../../resources/interfaces/interfaceComponente';
import { modulo } from '../../../resources/interfaces/interfaceModulo';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { sortArray } from '../../../functions/functions';
import { Configurator } from '../../configurador/components/configurator/Configurator';
import { ConfiguratorProvider } from '../../configurador/context/ConfiguratorContext';

//Pasos del asistente de permisos
const steps = ['Perfil de usuario', 'Acceso a módulos y componentes', 'Dispositivos'];

const reducer = (state: objReducerUsuarios, action: actionsReducerUsuarios) => {
  switch (action.type) {
    case ACTIONS_REDUCER_USUARIOS.SET_USUARIO:
      return { ...state, usuario: action.payload };
    case ACTIONS_REDUCER_USUARIOS.SET_GROW:
      return { ...state, grow: action.payload };
    case ACTIONS_REDUCER_USUARIOS.SET_PASSWORD:
      return { ...state, newPassword: action.payload };
    case ACTIONS_REDUCER_USUARIOS.SET_REPEAT_PASSWORD:
      return { ...state, repeatPassword: action.payload };
    case ACTIONS_REDUCER_USUARIOS.CHECK_NEW_PASSWORD:
      return { ...state, newPasswordWrong: action.payload };
    case ACTIONS_REDUCER_USUARIOS.CHECK_OLD_PASSWORD:
      return { ...state, oldPasswordWrong: action.payload };
    case ACTIONS_REDUCER_USUARIOS.SET_OPEN_CLOSE_ASISTENTE:
      return {
        ...state,
        openAsistente: action.payload,
        asistentePermisos: {
          ...state.asistentePermisos,
          componenteAsistente: action.componente,
          activeStep: action.payload ? 0 : state.asistentePermisos.activeStep
        }
      };
    case ACTIONS_REDUCER_USUARIOS.CHANGE_TEXT_PERFIL_ASISTENTE:
      return {
        ...state,
        usuarioFinal: {
          ...state.usuarioFinal,
          usuario: {
            ...state.usuarioFinal.usuario,
            idPerfil: action.payload
          }
        },
        permisosUsuario: {
          ...state.permisosUsuario,
          usuario: {
            ...state.permisosUsuario.usuario,
            idPerfil: action.payload
          }
        }
      };
    case ACTIONS_REDUCER_USUARIOS.HANDLE_BUTTON_ASISTENTE:
      return {
        ...state,
        asistentePermisos: {
          ...state.asistentePermisos,
          activeStep: action.payload
        }
      };
    case ACTIONS_REDUCER_USUARIOS.SET_MODULOS_FILTRADOS_POR_PERFIL:
      return {
        ...state,
        asistentePermisos: {
          ...state.asistentePermisos,
          modulosAsistente: action.payload
        }
      };
    case ACTIONS_REDUCER_USUARIOS.SET_PERFILES:
      return { ...state, perfiles: action.payload };
    case ACTIONS_REDUCER_USUARIOS.GET_PERMISOS_USUARIO:
      return {
        ...state,
        permisosUsuario: action.payload,
        componenteVisible: true,
        usuarioFinal: action.usuarioFinal
      };
    case ACTIONS_REDUCER_USUARIOS.SET_USUARIOS:
      return { ...state, usuarios: action.payload, componenteVisible: true };
    case ACTIONS_REDUCER_USUARIOS.SET_ESTADO_MODULO:
      return { ...state, permisosUsuario: { ...state.permisosUsuario, modulos: action.payload } };
    case ACTIONS_REDUCER_USUARIOS.SET_ACCION:
      return { ...state, permisosUsuario: { ...state.permisosUsuario, acciones: action.payload } };
    case ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS:
      return {
        ...state,
        dispositivos: action.payload,
        tablaSeleccionada: action.tablaSeleccionada
      };
    case ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS_USUARIO:
      return {
        ...state,
        usuarioFinal: action.payload
      };
    case ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS_SELECCIONADOS:
      return {
        ...state,
        dispositivosSeleccionados: action.payload
      };

    case ACTIONS_REDUCER_USUARIOS.SET_MODULO_SELECCIONADO_ESTABLECER_DISPOSITIVOS:
      return {
        ...state,
        moduloSeleccionadoEstDispositivos: action.payload
      };
    case ACTIONS_REDUCER_USUARIOS.SET_INITIAL_STATE:
      return { ...action.payload };
    default:
      return state;
  }
};

export function useReducerUsuarios(
  editarPerfil?: boolean,
  usuarioSeleccionado?: usuario,
  abrirDialogoPerfil?: () => void
): interfaceUseReducer {
  //Objeto inicial del reducer.
  const initial_state: objReducerUsuarios = {
    usuario: usuarioSeleccionado as usuario,
    componenteVisible: false,
    usuarios: [],
    grow: false,
    newPassword: '',
    repeatPassword: '',
    newPasswordWrong: true,
    oldPasswordWrong: true,
    oldPassword:
      usuarioSeleccionado !== undefined ? desencriptarPassword(usuarioSeleccionado.password) : '',
    openAsistente: false,
    perfiles: [],
    asistentePermisos: {
      activeStep: 0,
      steps: steps,
      componenteAsistente: <></>
    },
    permisosUsuario: {},
    usuarioFinal: {},
    dispositivos: [],
    tablaSeleccionada: -1,
    dispositivosSeleccionados: [],
    moduloSeleccionadoEstDispositivos: null
  };

  const [state, dispatch] = useReducer(reducer, initial_state);
  const { datosUsuarioContext, modificandoUsuario, setOpenPerfil } = useContext(UserContext);
  const { setControl, control } = useContext(ControlContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const { handleInputChange, handleInputInvalid } = useEventChange(state.usuario, setUsuario);
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar, registroGenerado } =
    useControlador();

  //#region helpers

  /**
   * Obtiene el índice del último valor en un array de objetos.
   * @param arr Array que recorremos.
   * @param property propiedad del objeto que vamos a acceder.
   * @param condition condición que compararemos con la propiedad para obtener el índice del objeto deseado.
   * @returns Devuelve el índice del último valor.
   */
  const getLastIndex = (arr: Array<any>, property: string, condition: any): number => {
    let index;
    for (index = arr.length - 1; index >= 0; index--) {
      if (arr[index][property] === condition) return index;
    }
    return index;
  };

  /**
   * Elimina un objeto de un array
   * @param arr Array sobre el que queremos actuar.
   * @param property Propiedad del objeto por la que buscaremos en el array.
   * @param condition Condición que debe cumplir la propiedad para eliminar.
   * @returns Array<any>
   */
  const eliminarUnObjetoArray = (arr: Array<any>, property: string, condition: any): void => {
    arr.splice(
      arr.findIndex((item) => item[property] == condition),
      1
    );
  };
  function controladorObjectUsuario(key: string, access: boolean, objeto: any, idModulo?: string) {
    const { usuarioFinal, permisosUsuario } = state;

    const addModulo = () => {
      //Añado el módulo.
      usuarioFinal.modulos.push({ id: objeto });

      //Añade todos los componentes de ese módulo y sus acciones.
      datosUsuarioContext.componentes.filter((componente) => {
        if (componente.idModulo === objeto) {
          usuarioFinal.componentes.push({
            id: componente.id,
            idModulo: componente.idModulo
          });
          permisosUsuario.permisos.filter(
            (item: any) => item.idComponente === componente.id
          )[0].id = 1;
          usuarioFinal.permisos.push({ id: 1, idComponente: componente.id });
        }
      });
      dispatch({
        type: ACTIONS_REDUCER_USUARIOS.SET_ACCION,
        payload: state.permisosUsuario.permisos
      });
    };

    const removeModulo = () => {
      const lastIndex = getLastIndex(usuarioFinal.componentes, 'idModulo', objeto);
      const firstIndex = usuarioFinal.componentes.findIndex(
        (componente: any) => componente.idModulo === objeto
      );
      let contador = 0;

      //Se elimina del array el módulo
      eliminarUnObjetoArray(usuarioFinal.modulos, 'id', objeto);

      //Se eliminan las acciones de los componentes asociados al módulo.
      if (firstIndex !== -1)
        for (let i = firstIndex; i <= lastIndex; i++) {
          eliminarUnObjetoArray(
            usuarioFinal.permisos,
            'idComponente',
            usuarioFinal.componentes[i].id
          );
          contador++;
        }
      //Se eliminan los componentes.
      usuarioFinal.componentes.splice(firstIndex, contador);
    };

    const cambiarAccion = () => {
      const index = state.usuarioFinal.permisos.findIndex(
        (accion: any) => accion.idComponente === objeto.idComponente
      );
      index !== -1
        ? (state.usuarioFinal.permisos[index] = objeto)
        : (state.usuarioFinal.permisos.push(objeto),
          state.usuarioFinal.componentes.push({
            id: objeto.idComponente,
            idModulo: parseInt(idModulo as string)
          }));
    };

    switch (key) {
      case 'modulo':
        access ? addModulo() : removeModulo();
        break;
      case 'accion':
        access
          ? cambiarAccion()
          : (eliminarUnObjetoArray(
              state.usuarioFinal.permisos,
              'idComponente',
              objeto.idComponente
            ),
            eliminarUnObjetoArray(state.usuarioFinal.componentes, 'id', objeto.idComponente));

        break;
      case 'perfil':
        access ? addModulo() : removeModulo();
        break;
      default:
        break;
    }
  }

  /**
   * Esta función se encarga de formar el objeto que controlará el asistente de permisos.
   * Solo se pueden editar los permisos en función de los permisos del usuario que está editando, por ejemplo: Usuario1 edita a Usuario2, si Usuario1 sólo tiene acceso al modulo1 sólo puede aparecer ese módulo y sus componentes,
   * independientemente de otros modulos/permisos que tenga el Usuario2.
   * @param {PermisosUsuario} datosUsuario Los datos del usuario que está siendo editado.
   */
  function formarObjetoUsuarioPermisos(datosUsuario: PermisosUsuario) {
    const { usuarioNuevo } = datosUsuario;

    function obtenerIdPermiso(item: componente) {
      const p = datosUsuario.permisos.findIndex((permiso) => permiso.idComponente === item.id);
      const idPermiso = p === -1 ? 0 : datosUsuario.permisos[p].id;

      return idPermiso;
    }
    // Esta es la variable es la que se encarga de mostrar los permisos actuales del usuario que edita.
    //es la principal que formará los componentes que se mostrarán en el asistente así como sus estados en función de los permisos del usuario que se está editando.
    const permisosUsuario: any = {
      usuario: {
        idPerfil: usuarioNuevo ? 1 : datosUsuario.usuario.idPerfil,
        idUsuario: usuarioSeleccionado?.id as number,
        dispositivosBloqueados: 0
      },
      modulos: [],
      componentes: [],
      permisos: [],
      dispositivos: [],
      usuarioNuevo: usuarioNuevo
    };
    //En este For recorremos los módulos, si es un usuario nuevo tenemos que darle por defecto acceso a todos los módulos del usuario que edita.
    for (const modulo of datosUsuarioContext.modulos) {
      if (usuarioNuevo) {
        datosUsuario.modulos.push({ id: modulo.id });
        permisosUsuario.modulos.push({
          ...modulo,
          acceso: true
        });
      } else {
        //Si no es nuevo, se mostrarán los módulos correspondientes al usuario que edita y además tendrá permiso o no dependiendo de si ese módulo existe en el usuario que está siendo editado.
        permisosUsuario.modulos.push({
          ...modulo,
          acceso: datosUsuario.modulos.some((mod) => mod['id'] === modulo.id)
        });
      }
    }

    // En este For recorremos los componentes, si es nuevo, se añadiran por defecto al usuario que está siendo editado todos los componentes y con CONTROL TOTAL del usuario que lo edita.
    for (const componente of datosUsuarioContext.componentes) {
      if (usuarioNuevo) {
        datosUsuario.componentes.push({
          id: componente.id,
          idModulo: componente.idModulo
        });
        datosUsuario.permisos.push({ idComponente: componente.id, id: 1 });
      }
      permisosUsuario.componentes.push(componente);
      permisosUsuario.permisos.push({
        idComponente: componente.id,
        id: obtenerIdPermiso(componente)
      });
    }

    sortArray(datosUsuario.componentes, 'idModulo');

    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.GET_PERMISOS_USUARIO,
      payload: permisosUsuario,
      usuarioFinal: datosUsuario
    });
  }

  //#endregion

  //#region Events

  const onChangeSelectPerfil = (event: SelectChangeEvent<any>) => {
    const key = 'modulo';
    let access = true;
    const modulosDefectoPerfil = [
      Modulos['GESTIÓN DE USUARIOS'],
      Modulos['CONFIGURACIÓN GENERAL'],
      Modulos.REGLAS
    ];

    //Cuando el perfil cambia a "Administrador local" se debe habilitar los módulos de reglas, configuración general y gestión de usuarios.
    if (event.target.value == 1) {
      modulosDefectoPerfil.forEach((element) => {
        controladorObjectUsuario(key, access, element);
      });
    }
    //Si al cambiar de perfil, este fuera anteriormente "Administrador local" se deben deshabilitar los módulos de reglas, configuración general y gestión de usuarios.
    else if (state.permisosUsuario.usuario.idPerfil == 1) {
      access = false;
      modulosDefectoPerfil.forEach((element) => {
        controladorObjectUsuario(key, access, element);
      });
    }
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.CHANGE_TEXT_PERFIL_ASISTENTE,
      payload: parseInt(event.target.value)
    });
  };

  const onChangeElementsPermisos = (evt: ChangeEvent, checked: boolean, value: modulo) => {
    value.acceso = checked;
    controladorObjectUsuario('modulo', checked, value.id);
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_ESTADO_MODULO,
      payload: state.permisosUsuario.modulos
    });
  };

  function onChangeAcciones(event: MouseEvent<any>) {
    const accion =
      event.currentTarget.name == 'total'
        ? AccionesComponentes.Control_Total
        : AccionesComponentes.Solo_lectura;
    const object = state.permisosUsuario.permisos[parseInt(event.currentTarget.id)];

    object.id === AccionesComponentes.Control_Total
      ? (object.id = AccionesComponentes.Solo_lectura)
      : object.id == AccionesComponentes.Solo_lectura && accion == AccionesComponentes.Solo_lectura
      ? (object.id = AccionesComponentes.Desactivado)
      : (object.id = accion);

    const access = object.id !== AccionesComponentes.Desactivado ? true : false;

    controladorObjectUsuario('accion', access, object, event.currentTarget.dataset.idmodulo);

    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_ACCION,
      payload: state.permisosUsuario.permisos
    });
  }

  //Esta función se encarga de actualizar el paso en el Stepper y si es el último, ejecuta la actualización de permisos de usuario.
  async function handleButtonAsistente(event: ChangeEvent<{ id: string }>) {
    if (event.currentTarget.id !== dictionary_asistente_permisos.BOTON_FINALIZAR)
      dispatch({
        type: ACTIONS_REDUCER_USUARIOS.HANDLE_BUTTON_ASISTENTE,
        payload:
          event.currentTarget.id == dictionary_asistente_permisos.BOTON_SIGUIENTE
            ? state.asistentePermisos.activeStep + 1
            : state.asistentePermisos.activeStep - 1
      });
    else {
      await controllerRequest(
        {
          type: action.MODIFICAR_PERMISOS_USUARIO,
          payload: { usuario: state.usuarioFinal, servicio: rutasServicios.USUARIOS }
        },
        { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
      );
      closeAsistente();

      setSnackBar({
        open: true,
        text: propiedadesSnackBar.current.texto,
        severity: propiedadesSnackBar.current.severity
      });
    }
  }

  function handleGrow() {
    dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_GROW, payload: !state.grow });
  }

  function handleChangeOldPassword({ target }: any) {
    target.value == state.oldPassword
      ? dispatch({ type: ACTIONS_REDUCER_USUARIOS.CHECK_OLD_PASSWORD, payload: false })
      : dispatch({ type: ACTIONS_REDUCER_USUARIOS.CHECK_OLD_PASSWORD, payload: true });
  }

  function setPassword({ target }: any) {
    dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_PASSWORD, payload: target.value });
  }

  function setRepeatPassword({ target }: any) {
    dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_REPEAT_PASSWORD, payload: target.value });
  }

  function setModuloSeleccionadoEstDispositivos(idModulo: number | null) {
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_MODULO_SELECCIONADO_ESTABLECER_DISPOSITIVOS,
      payload: idModulo
    });
  }

  async function cargarDispositivos(event: React.ChangeEvent, newValue: number): Promise<any> {
    await controllerRequest(
      {
        type: action.OBTENER_DATOS_BY_MODULO,
        payload: { servicio: rutasServicios.DISPOSITIVOS, modulo: parseInt(event.target.id) }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    ).then((response) => {
      setDispositivos(response, newValue);
      const dispositivosSeleccionados: Array<number> = [];
      const c = state.usuarioFinal.dispositivos.filter(
        (item: any) => item.idModulo === parseInt(event.target.id)
      );
      if (c.length) {
        c.forEach((dispositivo: any, index: number) => {
          const d = response.findIndex((item: any) => item.id === dispositivo.idDispositivo);
          //Debe ser distinto de -1, hay casos en los que el índice puede ser el 0 y no lo contemplaría.
          if (d !== -1) dispositivosSeleccionados.push(d);
        });
      }
      setDispositivosSeleccionados(dispositivosSeleccionados);
      setModuloSeleccionadoEstDispositivos(parseInt(event.target.id));
    });
  }
  function abrirAlerta() {
    setSnackBar({
      open: true,
      text: '¡Ya estás editando tus datos!',
      severity: enumSeverity.INFO
    });
  }
  function confirmarAlerta() {
    modificarUsuario();
  }

  const handleLatitude = ({ target }: any) => {
    const value = target.value.length ? target.value : null;
    setUsuario({ ...state.usuario, latitude: value });
  };

  const handleLongitude = ({ target }: any) => {
    const value = target.value.length ? target.value : null;
    setUsuario({ ...state.usuario, longitude: value });
  };

  async function openAsistente() {
    //Obtenemos los perfiles disponibles para los usuarios.
    await controllerRequest({
      type: action.OBTENER_PERFILES_USUARIO,
      payload: { servicio: rutasServicios.PERFILES }
    }).then((response) =>
      dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_PERFILES, payload: response.data.result })
    );

    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_OPEN_CLOSE_ASISTENTE,
      payload: true,
      componente: <EstablecerPerfil state={state} onChange={onChangeSelectPerfil} />
    });
  }

  function setDispositivosUsuario(
    rows: Array<any>,
    rowsSelected: Array<number>,
    idModuloSeleccionado: number | null
  ) {
    const filasModificadas = state.dispositivos.length - rowsSelected.length;

    const newArrayPermisosModulo = state.usuarioFinal.dispositivos.filter(
      (dispositivoUsuario: any) => dispositivoUsuario.idModulo !== idModuloSeleccionado
    );
    if (rowsSelected.length > 0 && filasModificadas !== 0) {
      rowsSelected.forEach((rowSelected) => {
        newArrayPermisosModulo.push({
          idDispositivo: state.dispositivos[rowSelected].id,
          idModulo: state.dispositivos[rowSelected].idModulo
        });
      });
    }
    state.usuarioFinal.dispositivos = newArrayPermisosModulo;
    state.usuarioFinal.dispositivos.sort();

    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS_USUARIO,
      payload: state.usuarioFinal as PermisosUsuario
    });
    if (rows.length === 0 && rowsSelected.length === 0) rowsSelected = [-1];

    setDispositivosSeleccionados(rowsSelected);
  }

  function closeAsistente() {
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_OPEN_CLOSE_ASISTENTE,
      payload: false,
      componente: <></>
    });
  }

  function openConfigurador() {
    //Se obtienen los modulos con acceso home del usuario seleccionado, para filtrarlos después con los módulos que puede editar el usuario administrador.
    const modulosDelUsuarioSeleccionado = state.permisosUsuario.componentes.filter(
      (item: any) =>
        item.nombre === 'Home' &&
        state.usuarioFinal.permisos.some(
          ({ idComponente }: { idComponente: number }) => idComponente === item.id
        )
    );

    //El usuario administrador podrá editar la configuración de los módulos al que el tenga acceso y además el usuario seleccionado también lo tenga.
    const modulosDelUsuarioAdministrador = datosUsuarioContext.modulos.filter((item) =>
      modulosDelUsuarioSeleccionado.some(
        ({ idModulo }: { idModulo: number }) => item.id === idModulo
      )
    );

    if (modulosDelUsuarioAdministrador.length === 0) {
      setSnackBar({
        open: true,
        text: 'El usuario o el administrador no tienen acceso o permiso a los mismos módulos, por lo tanto no se pueden configurar tarjetas.',
        severity: enumSeverity.ERROR
      });
    } else {
      setControl(
        <ConfiguratorProvider
          modulos={modulosDelUsuarioAdministrador}
          idUsuario={state.usuario.id}
          key={enumComponentes.CONFIGURADOR}
        >
          <Configurator
            modulos={modulosDelUsuarioAdministrador.filter(
              (s) => s.id !== Modulos['IA Y BIG DATA'] && s.id !== Modulos['VIDEOVIGILANCIA']
            )}
            user={state.usuario}
          />
        </ConfiguratorProvider>
      );
    }
  }

  /**
   * Modificar los datos de un usuario.
   * @param event
   */
  async function handleSubmitEditar(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // state.usuario.nombreUsuario !== datosUsuarioContext.usuario.nombreUsuario
    //   ? abrirAlerta()
    //   : modificarUsuario();
    modificarUsuario();
  }

  async function modificarUsuario() {
    state.grow
      ? (state.usuario.password = state.newPassword)
      : (state.usuario.password = state.oldPassword);
    state.usuario.password = encriptarPassword(state.usuario.password);
    await controllerRequest(
      {
        type: action.MODIFICAR_USUARIO,
        payload: {
          objeto: state.usuario
        }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );
    if (completadoCorrectamente.current) {
      editarPerfil
        ? setOpenPerfil(false)
        : setControl(<UsuariosManager key={enumComponentes.USUARIOS} />);

      datosUsuarioContext.usuarios.map((usuario: usuario, index) => {
        if (usuario.id == registroGenerado.current[0].id) {
          // El registro generado nos dará dos parámetros nuevos que no queremos: 'cliente' y 'perfil'
          datosUsuarioContext.usuarios[index] = objectUsuario(registroGenerado.current[0]);
        }
      });
    }

    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  }

  const events = {
    setUsuario,
    handleInputChange,
    handleInputInvalid,
    handleSubmitEditar,
    handleGrow,
    setRepeatPassword,
    setPassword,
    handleChangeOldPassword,
    openAsistente,
    closeAsistente,
    onChangeSelectPerfil,
    handleButtonAsistente,
    onChangeElementsPermisos,
    onChangeAcciones,
    cargarDispositivos,
    setDispositivosUsuario,
    abrirDialogoPerfil,
    openConfigurador,
    handleLatitude,
    handleLongitude
  };

  //#endregion

  // Actualiza el objeto usuario.
  function setUsuario(usuario: usuario) {
    dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_USUARIO, payload: usuario });
  }

  // Obtiene los usuarios del contexto.
  function getUsers() {
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_USUARIOS,
      payload: datosUsuarioContext.usuarios
    });
  }

  function setDispositivos(dispositivos: dispositivo[], tablaSeleccionada: number) {
    dispatch({
      type: ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS,
      payload: dispositivos,
      tablaSeleccionada: tablaSeleccionada
    });
  }
  function setDispositivosSeleccionados(arr: Array<number>) {
    dispatch({ type: ACTIONS_REDUCER_USUARIOS.SET_DISPOSITIVOS_SELECCIONADOS, payload: arr });
  }

  function comprobarPassword() {
    return state.newPassword !== state.repeatPassword;
  }

  // Obtiene el componente dependiendo del key con el que se ha llamado a UsuariosManager.
  function obtenerComponente(): JSX.Element {
    const componente: any = {
      [enumComponentes.USUARIOS]: <TablaUsuarios usuarios={state.usuarios} />,
      [enumComponentes.MODIFICAR_USUARIOS]: <UsuariosUpdate state={state} events={events} />,
      [enumComponentes.MODIFICAR_PERFIL]: <PerfilUpdate state={state} events={events} />
    };
    if (!editarPerfil && control.key == enumComponentes.USUARIOS)
      modificandoUsuario.current = usuarioSeleccionado as usuario;

    return editarPerfil
      ? componente[enumComponentes.MODIFICAR_PERFIL as string]
      : componente[control.key as string];
  }

  useEffect(() => {
    if (usuarioSeleccionado !== undefined)
      dispatch({
        type: ACTIONS_REDUCER_USUARIOS.SET_INITIAL_STATE,
        payload: initial_state
      });
  }, [usuarioSeleccionado]);

  useEffect(() => {
    if (usuarioSeleccionado !== undefined)
      dispatch({
        type: ACTIONS_REDUCER_USUARIOS.CHECK_NEW_PASSWORD,
        payload: comprobarPassword()
      });
  }, [state.newPassword, state.repeatPassword]);

  //Se encarga de cargar los datos del usuario seleccionado o los datos para la tabla de usuario.
  useEffect(() => {
    const obtenerPermisosdelUsuario = async () => {
      //Obtenemos primero los permisos.
      let responsePermisos: any;
      await controllerRequest(
        {
          type: action.OBTENER_PERMISOS_USUARIO,
          payload: {
            servicio: rutasServicios.USUARIOS,
            id: usuarioSeleccionado?.id as number
          }
        },
        { openSmartTeliaBackdrop: true }
      ).then(({ data }) => (responsePermisos = data.result));
      //Obtenemos el usuario, este paso hay que hacerlo para tener actualizado el usuario en todo momento, si no,
      //lo cogeríamos del contexto y este sólo se actualiza cuando hace login, por lo que puede generar errores.
      await controllerRequest(
        {
          type: action.OBTENER_DATOS_USUARIO,
          payload: { servicio: rutasServicios.USUARIOS, id: state.usuario.id as number }
        },
        { closeSmartTeliaBackdrop: true }
      ).then(({ data }) => {
        const usuario = data.result;

        const usuarioFinal: PermisosUsuario = {
          usuario: {
            idPerfil: usuario.idPerfil === null ? 1 : usuario.idPerfil,
            id: usuario.id,
            dispositivosBloqueados: 0
          },
          modulos: responsePermisos.modulos,
          componentes: responsePermisos.componentes,
          permisos: responsePermisos.permisos,
          dispositivos: responsePermisos.dispositivos,
          usuarioNuevo: usuario.idPerfil === null ? true : false
        };

        formarObjetoUsuarioPermisos(usuarioFinal);
      });
    };

    if (usuarioSeleccionado !== undefined && !state.openAsistente && !editarPerfil) {
      obtenerPermisosdelUsuario();
    } else {
      getUsers();
    }

    return function clean() {
      setDispositivos([], -1);
    };
  }, [state.openAsistente, usuarioSeleccionado]);

  return { getUsers, obtenerComponente, events, state, datosUsuarioContext };
}
