import React from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, Button, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, createStyles } from '@mui/styles';
import { dictionary_asistente_permisos } from '../../../resources/enums/plainText';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { TextBoxTelia } from '../../common/textboxTelia/TextBoxTelia';
import { ContenedorCrearCondicion } from './ContenedorCrearCondicion';
import useStylesTarjetas from '../../reglas/stylesTarjetas';

const useStylesDialogo = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

export function DialogoCrearCondicion(props: {
  state: any;
  events: any;
  stateCondiciones: any;
}): JSX.Element {
  enum title {
    'Añadir condiciones'
  }
  const classes = useStylesTarjetas();
  const classesDialogo = useStylesDialogo();
  const { state, stateCondiciones, events } = props;
  const { asistenteReglas } = state;
  const componentesDialogo: any = {
    0: (
      <ContenedorCrearCondicion state={state} events={events} stateCondiciones={stateCondiciones} />
    )
  };
  const mensajesTelia: any = {
    0: 'Seleccione el tipo de condición: Temporal si quiere activar la regla a cierta hora, de dispositivo si quiere utilizar algún sensor o grupal si quieres evaluar muchos dispositivos a la vez.'
  };

  return (
    <Dialog open={state.estadoDialogoCondiciones} onClose={events.toggleDialogoCondiciones}>
      <DialogTitle>
        {title[asistenteReglas.activeStep]}
        <IconButton
          aria-label="close"
          onClick={events.toggleDialogoCondiciones}
          className={classes.botonCerrarDialogo}
        >
          <CloseIcon />
        </IconButton>
        <IconButton aria-label="help" color="secondary" onClick={events.handleButtonTelia}>
          <HelpOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {state.estadoTelia && <TextBoxTelia texto={mensajesTelia[asistenteReglas.activeStep]} />}
      </DialogContent>
      <DialogContent>{componentesDialogo[asistenteReglas.activeStep]}</DialogContent>
      <DialogContent>
        <div>
          <div>
            <Button
              variant="contained"
              disabled={asistenteReglas.activeStep === 0}
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={dictionary_asistente_permisos.BOTON_ATRAS}
            >
              {dictionary_asistente_permisos.BOTON_ATRAS}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={events.handleButtonAsistente}
              className={classesDialogo.button}
              id={dictionary_asistente_permisos.BOTON_FINALIZAR_CONDICION_ACCION}
            >
              Finalizar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
