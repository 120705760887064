/**
 * @readonly
 * @enum {number} Group
 * @param WHITOUT_GROUP = 0
 * @param HOUR = 1
 * @param DAY = 2
 * @param WEEK = 3
 * @param MONTH = 4
 * @param YEAR = 5
 */
export enum Group {
  WITHOUT_GROUP,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR
}
