import * as React from 'react';
import { Checkbox, Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';

export function HourCalendar(props: {
  index: number;
  hourcalendar: any;
  handleRowCheckboxChange: (
    row: { date: string; range: Array<any> },
    evt: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleCheckboxChange: (evt: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}): JSX.Element {
  const indexSelected = props.index * 8;

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell />
          <TableCell align="center">00 - 15</TableCell>
          <TableCell align="center">15 - 30</TableCell>
          <TableCell align="center">30 - 45</TableCell>
          <TableCell align="center">45 - 00</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.hourcalendar.slice(indexSelected, 8 + indexSelected).map((row, indexRow) => (
          <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Checkbox
                id={`row-checkbox-${indexRow.toString()}`}
                checked={!row.range.some((item) => item.state === false)}
                onChange={(evt) => props.handleRowCheckboxChange(row, evt)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </TableCell>
            <TableCell component="th" scope="row">
              {row.date}
            </TableCell>
            {row.range.map((detailRow) => (
              <TableCell align="center" key={detailRow.index}>
                <Checkbox
                  id={`checkbox-${detailRow.index.toString()}`}
                  checked={detailRow.state}
                  onChange={(evt) => props.handleCheckboxChange(evt, detailRow.index)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
