/**
 * Enumerado con los diferentes tipos de acciones para modificar el State del hook
 * @readonly
 * @enum { string } actions
 * LOGIN
 * @member SET_NAME Establece el usuario
 * @member SET_PASSWORD Establece el password del usuario
 * RESTART PASSWORD
 * @member SET_EMAIL Establece el email del usuario
 * @member SET_ID_USUARIO Establece el id del usuario
 * @member SET_CODE_SECRET Establece el código de verificación del usuario
 * @member SET_IS_CODE_CORRECT Establece si es válido o no el código de verificación
 * @member SET_NUEVA_PASSWORD Establece la nueva contraseña
 * @member SET_REPETIR_PASSWORD Establece la verificación de la nueva contraseña
 */
export enum actions {
  /* LOGIN */
  SET_NAME = 'setName',
  SET_PASSWORD = 'setPassword',
  /* RESTART PASSWORD */
  SET_NOMBRE_USUARIO = 'setNombreUsuario',
  SET_EMAIL = 'setEmail',
  SET_ID_USUARIO = 'setIdUsuario',
  SET_CODE_SECRET = 'setCodeSecret',
  SET_IS_CODE_CORRECT = 'setIsCodeCorrect',
  SET_NUEVA_PASSWORD = 'setNuevaPassword',
  SET_REPETIR_PASSWORD = 'setRepetirPassword'
}
