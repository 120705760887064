/**
 *
 * @alias Email
 * @typedef Email
 * @property { number } id identificador del emilio
 * @property { string } direccion dirección a enviar el correo
 * @property { string } asunto asunto del correo
 * @property { string } mensaje cuerpo del mensaje
 * @property { boolean } enviado indica si el mensaje ha sido ya enviado o no
 */
export interface Email {
  id?: number;
  direccion: string;
  asunto: string;
  mensaje: string;
  enviado?: boolean;
}

export function objectEmail(): Email {
  return {
    direccion: '',
    asunto: '',
    mensaje: ''
  };
}
