import React, { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "../gis/components/marcador/Marker";
import { MarcadorInterface } from "../gis/resources/interfaces/interfaceMarcador";

let latitud = 39.154448;
let longitud = -3.02021;

/**
 * Mapa de google con funcionalidades extra, como dibujar polígono para seleccionar un Área o mostrar una tarjeta haciendo click en un marcador.
 * @param {dispositivosGIS} props Los dispositivos que usaremos en el GIS
 */
export function MapaSimple(props: { marker: MarcadorInterface }): JSX.Element {
  if (props.marker) {
    latitud = props.marker.lat;
    longitud = props.marker.lng;
  }
  const [center] = useState({ lat: latitud, lng: longitud });
  const [zoom] = useState(16);
  const geocoder = useRef<google.maps.Geocoder | any>();

  const handleGoogleMapApi = (map: any, google: any) => {
    geocoder.current = new google.Geocoder();
  };

  return (
    <div style={{ height: "300px", width: "525px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB3HwdNNg_YK-yc_EA7EcEQ3WoH7ZQfjlw",
          language: "es",
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleGoogleMapApi(map, maps)}
      >
        {props.marker ? (
          <Marker
            key={props.marker.id}
            id={props.marker.id}
            icono={props.marker.icono}
            lat={props.marker.lat}
            lng={props.marker.lng}
            nombre={props.marker.nombre}
            tipo={props.marker.tipo}
            animacion={"botar"}
            size={25}
          />
        ) : (
          <></>
        )}
      </GoogleMapReact>
    </div>
  );
}
