/**
+ * @readonly
+ * @enum {string} AgregateCode
+ * @param AVG = '1'
+ * @param SUM = '2'
+ * @param MAX = '3'
+ * @param MIN = '4'
+ */
export enum AgregateCode {
  AVG = '1',
  SUM = '2',
  MAX = '3',
  MIN = '4'
}
