import React, { useEffect, useContext, useState } from 'react';
import { Grid, IconButton, ListItem, Tooltip, Typography, Avatar } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BotonPlugin, EstadoPlugin } from './ContenedorFilaPlugin';
import DialogoInfoEstado from './DialogInfoEstado';
import useStyles from './styles';
import { plugin } from '../resources/interfaces/interfacePlugin';
import { statusPlugin } from '../resources/enums/enumEstado';
import { changeStatusPlugin } from '../resources/enums/enumCambiarEstado';
import { buscarRegistro } from '../../common/table/buscarRegistro';
import PaginationRounded from '../../common/table/Paginacion';
import Table from '../../common/table/Table';
import SmartTeliaBackdropContext from '../../../context/SmartTeliaBackdropContext';
import SnackBarContext from '../../../context/SnackBarContext';
import ControlContext from '../../../context/control/ControlContext';
import { rutasServicios } from '../../../resources/enums/enumRutasServicios';
import { dictionary_plugins } from '../../../resources/enums/plainText';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { DeviceManager } from '../../device/DeviceManager';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { enumTabla } from '../../common/table/resources/enumTabla';
import { VolverAtras } from '../../common/botonVolverAtras/VolverAtras';
import { PluginsDisponibles } from '../pluginsDisponibles/PluginsDisponibles';
import { ContenedorElementos } from '../../common/table/contenedorElementos';
import { BotonTablaFilaGenerico } from '../../common/table/BotonesTablaFila';
import { getDirectionServices } from '../../../functions/functions';

/**
 * Componente que representa en una tabla todos los plugins instalados
 */
export function PluginsInstalados(): JSX.Element {
  const classes = useStyles();
  const { controllerRequest, completadoCorrectamente, propiedadesSnackBar } = useControlador();
  const [plugins, setPlugins] = useState<plugin[]>([]);
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const [openDialogoInfo, setOpenDialogoInfo] = useState(false);
  const { setSnackBar } = useContext(SnackBarContext);
  const { control, setControl } = useContext(ControlContext);

  const handleOpenInformacion = (plugin: plugin): void => {
    if (plugin.puerto) {
      const direccionPlugin = getDirectionServices();
      const url = `http://${direccionPlugin}:${plugin.puerto}`;
      window.open(url);
    }
  };

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    {
      name: 'logotipo',
      label: 'Logotipo',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (
          value: string,
          tableMeta: {
            tableData: any[];
            currentTableData: { index: number }[];
            rowIndex: number;
          }
        ): JSX.Element => {
          const { objeto } = buscarRegistro(
            tableMeta.tableData,
            tableMeta.currentTableData[tableMeta.rowIndex].index
          );
          if (value === '') {
            return <Avatar variant="rounded">{objeto.nombre.charAt(0).toUpperCase()}</Avatar>;
          } else {
            return <Avatar variant="rounded" alt={objeto.nombre} src={value} />;
          }
        }
      }
    },
    { name: 'nombre', label: 'Nombre' },
    {
      name: '',
      label: 'Documentación',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          return (
            <div key={enumTabla.BOTONES_TABLA} style={{ marginLeft: '2vw' }}>
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaGenerico
                    datosTabla={tableMeta}
                    handleClick={handleOpenInformacion}
                    nombreBoton="Documentación"
                    icono={<InfoIcon />}
                  />
                }
                colorElemento="#2196f3"
                colorFondo="#2196f333"
                clase={'botones'}
              />
            </div>
          );
        }
      }
    },
    { name: 'version', label: 'Versión' },
    {
      name: 'descatalogado',
      label: 'Descatalogado',
      options: {
        filter: false,
        empty: true,
        viewColumns: false,
        customBodyRender: (value: number) => {
          if (value === 1) return <Typography className={classes.descatalogado}>SI</Typography>;
          else return <Typography className={classes.descatalogado}>NO</Typography>;
        }
      }
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: statusPlugin): JSX.Element => {
          return <EstadoPlugin estado={value} />;
        },
        // eslint-disable-next-line react/display-name
        customHeadLabelRender: (columnMeta: any): JSX.Element => {
          return (
            <ListItem key={columnMeta.label}>
              <Typography className={classes.tituloEstado}>{columnMeta.label}</Typography>
              <Tooltip title={dictionary_plugins.INFO_ESTADO} placement="right">
                <IconButton
                  aria-label="info-estado"
                  className={classes.botonInfo}
                  onClick={handleClickOpenDialogoInfo}
                >
                  <InfoIcon className={classes.iconoInfo} />
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          const { objeto } = buscarRegistro(
            tableMeta.tableData,
            tableMeta.currentTableData[tableMeta.rowIndex].index
          );
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.botonesTabla}
            >
              {objeto.estado !== statusPlugin.INSTALLED ? (
                objeto.estado !== statusPlugin.RUNNING ? (
                  <BotonPlugin
                    tableMeta={tableMeta}
                    nombreBoton="Ejecutar"
                    handleClick={handleClickEjecutar}
                  />
                ) : (
                  <BotonPlugin
                    tableMeta={tableMeta}
                    nombreBoton="Pausar"
                    handleClick={handleClickPausar}
                  />
                )
              ) : (
                <></>
              )}
              <BotonPlugin
                tableMeta={tableMeta}
                nombreBoton="Configurar"
                handleClick={handleClickConfiguracion}
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    responsive: 'simple',
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (plugins.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        handleClickConfiguracion(plugins[cellMeta.dataIndex]);
      }
    },
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ): JSX.Element => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    }
  };

  /**
   * Título con opción de volver a atrás a plugins disponibles si venimos de ahí
   * @returns {JSX.Element}
   */
  const Titulo = (): JSX.Element => {
    return (
      <div className={classes.tituloTabla}>
        {isComeFromDisponibles() ? (
          <VolverAtras backTo={<PluginsDisponibles key={enumComponentes.PLUGINS} />} />
        ) : (
          <></>
        )}
        <Typography component="h6" variant="h6" key={dictionary_plugins.PLUGINS_INSTALADOS}>
          {dictionary_plugins.PLUGINS_INSTALADOS}
        </Typography>
      </div>
    );
  };

  /**
   * Función que nos indica si venimos de Plugins Disponibles
   */
  const isComeFromDisponibles = (): boolean => {
    return (control.key as string)?.includes(dictionary_plugins.DISPONIBLE);
  };

  /**
   * Handle al hacer click en el icono de informacion 'i'
   */
  const handleClickOpenDialogoInfo = () => {
    setOpenDialogoInfo(true);
  };

  /**
   * Handle al hacer click en el icono 'Play' o ejecutar de un plugin
   * @param {plugin} plugin
   */
  const handleClickEjecutar = async (plugin: plugin): Promise<void> => {
    await handleRequest(changeStatusPlugin.REANUDAR, { nombre: plugin.nombre });
    handleCompletado(plugin, statusPlugin.RUNNING);
  };

  /**
   * Handle al hacer click en el icono 'Pause' o pausar de un plugin
   * @param {plugin} plugin
   */
  const handleClickPausar = async (plugin: plugin): Promise<void> => {
    await handleRequest(changeStatusPlugin.PAUSAR, { nombre: plugin.nombre });
    handleCompletado(plugin, statusPlugin.PAUSE);
  };

  /**
   * Handle para realizar la petición
   * @param accion accion que queremos realizar (ejecutar o pausar)
   * @param objeto objeto que contiene el nombre del plugin
   */
  const handleRequest = async (accion: string, objeto: any) => {
    await controllerRequest(
      {
        type: action.CAMBIAR_ESTADO_PLUGIN,
        payload: { servicio: accion, objeto: objeto }
      },
      { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
    );
  };

  /**
   * Handle cuando se termina de realizar la peticion, se actualiza los cambios y muestra un snackbar
   * @param {plugin} plugin
   * @param {number} newEstado
   */
  const handleCompletado = (plugin: plugin, newEstado: number) => {
    if (completadoCorrectamente.current) {
      //actualizamos los cambios del plugin
      const newPlugins = plugins;
      const indice = newPlugins.findIndex((p) => p.nombre === plugin.nombre);
      const newPlugin = { ...plugin, estado: newEstado };
      if (indice !== -1) {
        newPlugins.splice(indice, 1, newPlugin);
        setPlugins(newPlugins);
      }
    }
    setSnackBar({
      open: true,
      text: propiedadesSnackBar.current.texto,
      severity: propiedadesSnackBar.current.severity
    });
  };

  /**
   * Handle al hacer click en el icono configuracion de un plugin. Abrira la tabla con los devices
   * @param plugin
   */
  const handleClickConfiguracion = (plugin: plugin): void => {
    setControl(
      <DeviceManager
        pluginSeleccionado={plugin}
        key={
          enumComponentes.DEVICES_POR_PLUGIN +
          (isComeFromDisponibles() ? dictionary_plugins.DISPONIBLE : '') +
          dictionary_plugins.INSTALADO
        }
      />
    );
  };

  // Obtenemos todos los plugins existentes del CLIENTE para mostrarlos en una tabla
  useEffect(() => {
    const obtenerPlugins = async () => {
      setPlugins(
        await controllerRequest(
          {
            type: action.OBTENER_DATOS_SIN_MODULO,
            payload: { servicio: rutasServicios.PLUGINS }
          },
          { openSmartTeliaBackdrop: true, closeSmartTeliaBackdrop: true }
        )
      );
    };
    obtenerPlugins();
  }, []);

  return (
    <>
      {!openSmartTeliaBackdrop ? (
        <Table title={<Titulo />} columns={columns} data={plugins} options={options}></Table>
      ) : (
        <></>
      )}

      <DialogoInfoEstado
        openDialogoInfo={openDialogoInfo}
        setOpenDialogoInfo={setOpenDialogoInfo}
      />
    </>
  );
}
