import React from 'react';
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDrop } from 'react-dnd';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { CardProperties } from '../../types/typesCardConfigurator';
import { dictionary_configurator } from '../../../../resources/enums/plainText';

export function DropTargetCard({ posicion }: { posicion: number }): JSX.Element {
  const theme = useTheme();

  const {
    selectedStructure,
    moduleStructureState: [structureModule],
    addCard,
    sizeCardType,
    addModuleWithChange
  } = useConfiguratorContext();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const droppedCard = React.useRef<any>();

  function freeSizeConfigurator(position: number, idTypeCard: number): boolean {
    const size: any = sizeCardType[idTypeCard];
    if (size) {
      if (selectedStructure[position + 1].props.tarjeta !== undefined) {
        setOpen(true);
        return false;
      }
    }
    return true;
  }

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'tarjeta',
      drop: (item: any) => {
        droppedCard.current = { ...item, tarjeta: { ...item.tarjeta, position: posicion } };
        if (freeSizeConfigurator(posicion, item.tarjeta.idTipoTarjeta)) {
          addCard(droppedCard.current as CardProperties, posicion);
          addModuleWithChange();
        }
        return selectedStructure[posicion];
      },
      canDrop: (item: any, monitor: any) => {
        const { x } = monitor.getClientOffset();
        if (Number(sizeCardType[item.tarjeta.idTipoTarjeta]) && x + 370 > window.innerWidth) {
          return false;
        }
        return true;
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [addCard, structureModule, selectedStructure]
  );
  const addCardOnClick = () => {
    const size: any = sizeCardType[selectedStructure[posicion + 1].props.tarjeta.idTipoTarjeta];
    if (size) {
      selectedStructure[posicion + 1] = <div data-visible={true} />;
      selectedStructure[posicion + 2] = <div data-visible={true} />;
    }
    addCard(droppedCard.current as CardProperties, posicion);
    addModuleWithChange();
  };

  return (
    <Grid
      item
      xs={6}
      sm={4}
      lg={2}
      ref={drop}
      style={{ cursor: canDrop ? 'default' : 'not-allowed' }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Configurador de tarjetas'}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dictionary_configurator.EXISTS_OTHER_CARD}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => addCardOnClick()}>Aceptar</Button>
          <Button onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        style={{
          border: `2px dashed ${theme.palette.secondary.main}`,
          borderRadius: '10px',
          backgroundColor: isOver && canDrop ? 'lightgray' : 'inherit',
          height: '166px'
        }}
      ></Box>
    </Grid>
  );
}
