import { TiposMedida } from '../../../../resources/enums/enumTiposMedida';
import { dictionary_unidades } from '../../../../resources/enums/plainText';
import { dispositivo } from '../../../dispositivos/resources/interfaces/interfaceDispositivo';
import { HandleError } from '../../../error/resources/types/typeHandleError';
import { getId, getLabelsRange } from '../../functions/funciones';
import { GraphTypes } from '../../resources/enums/enumGraphTypes';
import { MeasuresText } from '../../resources/enums/enumMeasuresText';
import { Operation } from '../../resources/enums/enumOperation';
import { Rango } from '../../resources/enums/enumRango';
import { ServiciosGraficas } from '../../resources/enums/enumServiciosGraficas';
import { ComparativeChart, NoValues } from '../../resources/interfaces/interfaceCharts';
import { DatosGrafica } from '../../resources/interfaces/interfaceDatosGraficas';
import { servicioObtenerDatosGraficas } from '../../services/obtenerDatosGraficas';

const SERVICE_DEFAULT = ServiciosGraficas.SENSOR;
const file = 'createAreaChart';

export const createComparativeRangedChart = async (
  moduloSeleccionado: number,
  medida: TiposMedida | TiposMedida[],
  rango: Rango,
  operation: Operation,
  tipoGrafica: GraphTypes,
  dispositivos: Array<dispositivo>,
  handleError: HandleError,
  inicio: Array<string>,
  fin: Array<string>,
  unidad?: dictionary_unidades,
  agruparDispositivos?: boolean
): Promise<ComparativeChart | NoValues> => {
  const getValor = (d: DatosGrafica) => d.valor;

  const objectLabel = getLabelsRange(inicio, fin);
  const consulta: any = {
    range: rango,
    startDate: inicio[0],
    endDate: inicio[1],
    dispositivos: getId(dispositivos),
    group: objectLabel.agrupacionValue,
    operation: operation,
    measure: medida,
    servicio: medida === TiposMedida.CONSUMO ? ServiciosGraficas.CONSUMO : SERVICE_DEFAULT,
    mostrarSinAgrupar: agruparDispositivos
  };

  //Obtención de los datos del rango actual.
  const respuestaPeriodoInicial: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  if (respuestaPeriodoInicial.isAxiosError) {
    handleError(`[${file}][${servicioObtenerDatosGraficas.name}]`, respuestaPeriodoInicial.message);
    return { noValues: true };
  }

  //Obtención de los datos del rango anterior para comparar.
  consulta.startDate = fin[0];
  consulta.endDate = fin[1];
  const respuestaValorComparativo: any = await servicioObtenerDatosGraficas({
    ...consulta,
    modulos: [moduloSeleccionado]
  });

  //Desestructuración de los datos actuales y comparativos.
  const { result } = respuestaPeriodoInicial.data;
  const { result: resultValorComparativo } = respuestaValorComparativo.data;

  //Labels son las etiquetas que aparecerán en el ejeX del gráfico, condition es la condición(key) por la que buscaremos, todos los servicios de cualquier
  //intervalo tienen en común el campo 'fecha' por lo tanto filtraremos por el.
  // const { condition = 'fecha', formateoFecha, getLastValue } = getLabels(rango);

  let unid = '';

  const datos: Array<{ data: Array<any>; name: string; type: 'column' | 'line' }> = [];

  function createGraphData(
    result: Array<any>,
    tipo: 'column' | 'line',
    condition: string,
    anterior = false
  ) {
    result.forEach(
      ({
        idDispositivo,
        datos: datosResultado
      }: {
        idDispositivo: number;
        datos: Array<DatosGrafica>;
      }) => {
        let data, name;
        //Si no vienen todos los datos en un rango, hay que rellenar esos huecos con null.
        //Por ejemplo, los datos de la semana actual con toda seguridad si hoy fuera miércoles no estarán los datos del jueves, viernes...
        if (datosResultado.length !== objectLabel.labels.length) {
          data = objectLabel[condition].map((element: any) => {
            const res = datosResultado.find((item: any) => {
              unid = item.unidad;

              return (
                objectLabel.formatDate(new Date(item.fecha)) === objectLabel.formatDate(element)
              );
            });
            return res ? res.valor : null;
          });
        } else {
          data = datosResultado.map(getValor);
        }

        if (agruparDispositivos || dispositivos.length === 0) {
          name = anterior ? 'Periodo Comparativo' : 'Periodo Inicial';
        } else {
          if (anterior) {
            name =
              (dispositivos.find((dispositivo) => dispositivo.id === idDispositivo)
                ?.nombre as string) + ` anterior`;
          } else {
            name = dispositivos.find((dispositivo) => dispositivo.id === idDispositivo)
              ?.nombre as string;
          }
        }

        datos.push({ data, name, type: tipo });
      }
    );
  }
  createGraphData(result, 'line', 'fechaINI');
  createGraphData(resultValorComparativo, 'column', 'fechaFINI', true);

  //Propiedades genéricas del gráfico, como el título, título del eje Y y el valor max para que el gráfico tenga una altura correcta.
  const titulo = Array.isArray(medida) ? 'Comparativo' : MeasuresText[medida];
  const title = `Comparativa de ${titulo}`;
  const max = Math.max(...datos.map(({ data }) => Math.max(...(data as number[])))) * 1.15;

  return {
    datos,
    labels: objectLabel.labels,
    title: title,
    titleYAxis: `${titulo} ${unid ?? unidad}`,
    max,
    unid
  };
};
