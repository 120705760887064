import React, { useState } from 'react';
import { Button, ButtonGroup, IconButton } from '@mui/material';
import { Slider, Switch, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SyncIcon from '@mui/icons-material/Sync';
import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import useStyles from './styles';
import { TipoSection } from './resources/enums/enumTipoSection';
import { section } from '../../../../../../sections/resources/interfaces/interfaceSection';
import { dictionary_generic } from '../../../../../../../resources/enums/plainText';
import { ControlPtzSection } from './ControlPtzSection';

/**
 * Componente TextNumberSection: Texto o Numero de una section de tipo text o number.
 * Este componente solo refleja información
 * @param props
 */
const TextNumberSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  return (
    <Typography id={`${props.section.id}`} variant="subtitle1">
      {props.section.valorReal === undefined || props.section.valorReal === ''
        ? '-'
        : props.section.valorReal}
    </Typography>
  );
};

/**
 * Componente ButtonSection: Botón de una section de tipo button
 * @param props
 */
const ButtonSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="icono"
      className={classes.buttonSection}
      onClick={() => props.handleChange(props.section)}
    >
      {props.section.nombre.toLowerCase().includes('apagar') ||
      props.section.nombre.toLowerCase().includes('off') ? (
        <PowerIcon aria-label={dictionary_generic.POWER} className={classes.iconSection} />
      ) : (
        <SyncIcon aria-label={dictionary_generic.SINCRONIZAR} className={classes.iconSection} />
      )}
    </IconButton>
  );
};

/**
 * Componente SwitchSection: Switch de una section de tipo switch
 * @param props
 */
const SwitchSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  return (
    <Switch
      defaultChecked={
        props.section.valorReal == '1' || props.section.valorReal === 'ON' ? true : false
      }
      onChange={(event) => props.handleChange(props.section, event.target.checked ? '1' : '0')}
      color="secondary"
      name={props.section.nombre}
      inputProps={{ 'aria-label': props.section.nombre }}
      size={'small'}
    />
  );
};

/**
 * Componente SwitchExtended: es un grupo de botones para el tipo switchExtended.
 * La etiqueta y el valor de cada boton está establecido en el tipo de sections despues de un '#'
 * @param props
 */
const SwitchExtended = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const switchExtended = props.section.tipo?.split('#')[1] as string;
  const botones = switchExtended.split(',');

  return (
    <ButtonGroup
      key={props.section.id}
      disableElevation
      variant="outlined"
      color="secondary"
      size="small"
      aria-label={props.section.nombre}
    >
      {botones.map((boton) => {
        const etiqueta = boton.split(':')[0];
        const valor = boton.split(':')[1];

        return (
          <Button
            key={`${props.section.id}-${etiqueta}`}
            onClick={() => props.handleChange(props.section, valor)}
          >
            {etiqueta}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

/**
 * Componente DimmerSection: Dimmer de una section de tipo dimmer
 * @param props
 */
const DimmerSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Slider
      className={classes.widthSection}
      defaultValue={
        props.section.valorReal === undefined || props.section.valorReal === ''
          ? 0
          : parseInt(props.section.valorReal as string)
      }
      getAriaValueText={(value: number) => `${value}%`}
      aria-labelledby={props.section.nombre}
      valueLabelDisplay="auto"
      onChangeCommitted={(_event, newValue) => props.handleChange(props.section, `${newValue}`)}
      step={5}
      marks
      min={0}
      max={100}
    />
  );
};

/**
 * Componente Input: es un input con un boton para el tipo input.
 * El tipo de input está establecido en el tipo de section despues de un '#'
 * @param props
 */
const InputSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();
  const [newValue, setNewValue] = useState<string>('');
  const tipoInput = props.section.tipo?.split('#')[1] as string;

  return (
    <>
      <TextField
        id={`${props.section.id}`}
        type={tipoInput}
        onChange={(event) => setNewValue(event.target.value)}
        InputProps={{ className: classes.widthInputSection }}
      />
      <IconButton
        aria-label={dictionary_generic.SINCRONIZAR}
        className={classes.buttonSection}
        onClick={() => props.handleChange(props.section, newValue)}
        disabled={!newValue.length}
      >
        <SyncIcon aria-label={dictionary_generic.SINCRONIZAR} className={classes.iconSection} />
      </IconButton>
    </>
  );
};

/**
 * Componente AutocompleteSection: es un select o combo box de opciones para el tipo autocomplete.
 * La etiqueta y el valor de cada opción está establecido en el tipo de sections despues de un '#'
 * @param props
 */
const AutocompleteSection = (props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();
  const autocompleteSection = props.section.tipo?.split('#')[1] as string;
  const botones = autocompleteSection.split(',');
  const opciones: { etiqueta: string; valor: string }[] = [];
  botones.forEach((boton) => {
    opciones.push({ etiqueta: boton.split(':')[0], valor: boton.split(':')[1] });
  });
  let valorPorDefecto = { etiqueta: '', valor: '' };
  if (props.section.valorReal !== undefined && props.section.valorReal !== '0') {
    valorPorDefecto = opciones.find((opcion) => opcion.valor === props.section.valorReal) as {
      etiqueta: string;
      valor: string;
    };
  }

  return (
    <Autocomplete
      className={classes.widthSection}
      size={'small'}
      id={`${props.section.id}`}
      options={opciones}
      defaultValue={valorPorDefecto}
      getOptionLabel={(option: any) => option.etiqueta}
      onChange={(_event: any, newValue: any) => {
        if (newValue !== null) props.handleChange(props.section, `${newValue.valor}`);
      }}
      renderInput={(params: any) => <TextField {...params} variant="outlined" />}
    />
  );
};

/**
 * Componente que establece el tipo de interacción según el tipo de section
 * @param section
 * @param handleChange
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tipoSection: any = (
  section: section,
  handleChange: (section: section, valor?: string) => Promise<void>
) => ({
  [TipoSection.Texto]: <TextNumberSection section={section} handleChange={handleChange} />,
  [TipoSection.Number]: <TextNumberSection section={section} handleChange={handleChange} />,
  [TipoSection.Button]: <ButtonSection section={section} handleChange={handleChange} />,
  [TipoSection.Switch]: <SwitchSection section={section} handleChange={handleChange} />,
  [TipoSection.SwitchExtended]: <SwitchExtended section={section} handleChange={handleChange} />,
  [TipoSection.Dimmer]: <DimmerSection section={section} handleChange={handleChange} />,
  [TipoSection.Input]: <InputSection section={section} handleChange={handleChange} />,
  [TipoSection.Autocomplete]: <AutocompleteSection section={section} handleChange={handleChange} />,
  [TipoSection.ControlPtz]: <ControlPtzSection section={section} handleChange={handleChange} />
});

/**
 * Función para extraer el tipo section en caso de que tenga más información despues de un '#'.
 * @param {string} tipoSection tipo de section (switch, buttom, etc.)
 * @return {string} tipo de section extraida de la cadena.
 */
const getTipoSection = (tipoSection: string): string => {
  return tipoSection.split('#')[0];
};

/**
 * Función que recibe una section, y según sea creará el componente según el tipo de section.
 * Si no existe, devuelve un componente vacío.
 * @param props
 */
export function InteraccionSections(props: {
  section: section;
  handleChange: (section: section, valor?: string) => Promise<void>;
}): JSX.Element {
  const controladorSection = tipoSection(props.section, props.handleChange)[
    getTipoSection(props.section.tipo as string)
  ];

  return controladorSection ? controladorSection : <></>;
}
