import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '0 20px 10px 20px',
    height: '90%'
  },
  cajas: {
    marginBottom: '15px'
  },
  circularProgress: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px'
  }
}));
export default useStyles;
