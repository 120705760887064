/**
 * @alias Canal
 * @typedef Canal
 * @description Esta interfaz es la respuesta que nos da el servicio al obtener canales o un canal concreto
 * @property { number } id? id del canal.
 * @property { string } nombre nombre del canal.
 * @property { string } nombreDispositivo nombre del dispositivo.
 * @property { string } nombreMedida nombre de la medida.
 * @property { string } nombreUnidad nombre de la unidad.
 * @property { number } idTipoMedida id del tipo de medida.
 * @property { number } idTipoUnidad id del tipo de unidad.
 * @property { number } idSection id del section asociado
 * @property { string } nombreSection nombre del section asociado
 * @property { string } etiquetaSection nombre del section asociado
 * @property {boolean | number} activo indica si el canal está activado o desactivado.
 */

export interface canalesRespuesta {
  id?: number;
  idDispositivo?: number;
  nombre: string;
  nombreDispositivo: string;
  nombreMedida: string;
  nombreUnidad: string;
  idTipoMedida?: number;
  idTipoUnidad?: number;
  idSection?: number;
  nombreSection?: string;
  etiquetaSection?: string;
  activo: boolean | number;
}

export const objectCanalRespuesta = (elemento?: canalesRespuesta): canalesRespuesta => {
  let canal: canalesRespuesta;

  elemento === undefined
    ? (canal = {
        nombre: '',
        nombreDispositivo: '',
        nombreMedida: '',
        nombreUnidad: '',
        idTipoMedida: 0,
        idTipoUnidad: 0,
        activo: 1,
        idDispositivo: undefined,
        nombreSection: '',
        etiquetaSection: '',
        idSection: undefined
      })
    : (canal = {
        id: elemento.id,
        idDispositivo: elemento.idDispositivo,
        nombre: elemento.nombre,
        nombreDispositivo: elemento.nombreDispositivo,
        nombreMedida: elemento.nombreMedida,
        nombreUnidad: elemento.nombreUnidad,
        idTipoMedida: elemento.idTipoMedida,
        idTipoUnidad: elemento.idTipoUnidad,
        activo: elemento.activo,
        idSection: elemento.idSection !== null ? elemento.idSection : undefined,
        nombreSection: elemento.nombreSection !== null ? elemento.nombreSection : '',
        etiquetaSection: elemento.etiquetaSection !== null ? elemento.etiquetaSection : ''
      });
  return canal;
};
