import React from 'react';
import './Marker.css';
import IconButton from '@mui/material/IconButton';
import { MapIcons } from '../iconos/Iconos';
import { MarcadorInterface } from '../../resources/interfaces/interfaceMarcador';
import { tipoAlerta } from '../../../../alertas/resources/enums/enumTipoAlerta';

/**
 * Componente marcador del mapa.
 * @param {MarcadorInterface} props Icono y evento onClick
 */
export function Marker(props: MarcadorInterface): JSX.Element {
  return (
    <>
      <IconButton
        aria-label="Marcador mapa"
        onClick={props.onClick}
        id={props.id?.toString()}
        style={{ margin: '-38px 0px 0px -12px' }}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}
        onFocusVisible={props.onFocus}
        onBlur={props.onBlur}
      >
        <MapIcons icono={props.icono} animacion={props.animacion} id={props.id?.toString()} />
      </IconButton>
      {props.idTipoAlerta === tipoAlerta.WARNING ? (
        <div className={'pulseWarning'}></div>
      ) : props.idTipoAlerta === tipoAlerta.ERROR ? (
        <div className={'pulseError'}></div>
      ) : (
        <></>
      )}
    </>
  );
}
