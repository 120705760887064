import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Rango } from '../graficas/resources/enums/enumRango';
import { Theme } from '@mui/material/styles';
import { dictionary_alertas, dictionary_generic, enumLabel } from '../../resources/enums/plainText';
import TimeLine from './TimeLine';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

export function DialogoVerMas(props: {
  open: boolean;
  setOpen: (openVerMas: boolean) => void;
  nombreDispositivo: string | undefined;
  cargarLog: (
    setTimeLine: React.Dispatch<React.SetStateAction<[]>>,
    range: number,
    fechaDesde: string,
    fechaHasta: string
  ) => void;
}): JSX.Element {
  const classes = useStyles();
  const hoy = new Date(Date.now()).toISOString().substring(0, 16);
  const [fecha, setFecha] = useState({
    desde: hoy,
    hasta: hoy
  });
  const [logModal, setLogModal] = useState<any>([]);
  useEffect(() => {
    return function clean() {
      setLogModal([]);
    };
  }, [props.open]);

  return (
    <div>
      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {dictionary_alertas.ALERTAS_DEL_DISPOSITIVO}
          {props.nombreDispositivo}
        </DialogTitle>
        <DialogContent>
          <form className={classes.container} noValidate style={{ marginTop: '8px' }}>
            <TextField
              id="fechaDesde"
              label={enumLabel.FECHA_DESDE}
              type="datetime-local"
              defaultValue={fecha.desde}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              onChange={({ target }) => setFecha({ ...fecha, desde: target.value })}
            />
            <TextField
              id="fechaHasta"
              label={enumLabel.FECHA_HASTA}
              type="datetime-local"
              defaultValue={fecha.hasta}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              onChange={({ target }) => setFecha({ ...fecha, hasta: target.value })}
            />
            <Tooltip title={dictionary_generic.VER} arrow>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => props.cargarLog(setLogModal, Rango.RANGE, fecha.desde, fecha.hasta)}
              >
                {dictionary_generic.VER}
              </Button>
            </Tooltip>
          </form>
          {logModal.length > 0 ? (
            <TimeLine log={logModal} />
          ) : (
            <div style={{ marginTop: '15px' }}>
              <p>{'No hay datos que mostrar en estas fechas'}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Tooltip title={dictionary_generic.CERRAR} arrow>
            <Button variant="contained" color="secondary" onClick={() => props.setOpen(false)}>
              {dictionary_generic.CERRAR}
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
}
