import React, { Dispatch, SetStateAction } from 'react';
import { Button, Dialog, List, ListItem, ListItemIcon, ListItemText } from '@mui/material/';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material/';
import { EstadoPlugin } from './ContenedorFilaPlugin';
import useStyles from './styles';
import { statusPlugin } from '../resources/enums/enumEstado';
import { dictionary_generic, dictionary_plugins } from '../../../resources/enums/plainText';

export default function DialogoInfoEstado(props: {
  openDialogoInfo: boolean;
  setOpenDialogoInfo: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const classes = useStyles();
  const handleCloseDialogoInfo = () => {
    props.setOpenDialogoInfo(false);
  };

  return (
    <div>
      <Dialog open={props.openDialogoInfo} onClose={handleCloseDialogoInfo}>
        <DialogTitle>{dictionary_plugins.ESTADO_PLUGIN}</DialogTitle>
        <DialogContent>
          <List>
            <ListItem key={statusPlugin.INSTALLED}>
              <ListItemIcon className={classes.estado}>
                <EstadoPlugin estado={statusPlugin.INSTALLED} />
              </ListItemIcon>
              <ListItemText primary={dictionary_plugins.INSTALLED} />
            </ListItem>
            <ListItem key={statusPlugin.READY}>
              <ListItemIcon className={classes.estado}>
                <EstadoPlugin estado={statusPlugin.READY} />
              </ListItemIcon>
              <ListItemText primary={dictionary_plugins.READY} />
            </ListItem>
            <ListItem key={statusPlugin.RUNNING}>
              <ListItemIcon className={classes.estado}>
                <EstadoPlugin estado={statusPlugin.RUNNING} />
              </ListItemIcon>
              <ListItemText primary={dictionary_plugins.RUNNING} />
            </ListItem>
            <ListItem key={statusPlugin.ERROR}>
              <ListItemIcon className={classes.estado}>
                <EstadoPlugin estado={statusPlugin.ERROR} />
              </ListItemIcon>
              <ListItemText primary={dictionary_plugins.ERROR} />
            </ListItem>
            <ListItem key={statusPlugin.PAUSE}>
              <ListItemIcon className={classes.estado}>
                <EstadoPlugin estado={statusPlugin.PAUSE} />
              </ListItemIcon>
              <ListItemText primary={dictionary_plugins.PAUSE} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleCloseDialogoInfo} color="secondary">
            {dictionary_generic.CERRAR}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
