/**
 * FrontalError
 * @alias FrontalError
 * @typedef FrontalError
 * @property { Date | string } fecha fecha en la que se produce el error
 * @property { string } localizacion nombre del componente y método donde se produce
 * @property { string } usuario usuario de acceso a la plataforma
 * @property { string } cliente nombre del cliente
 * @property  {string } modulo módulo donde se produce el error
 * @property { string } componente Componente donde se produce el error
 * @property { string } mensaje Mensaje de error
 * @property { string } stack pila de llamadas del error
 */
export interface frontalError {
  fecha: Date | string;
  localizacion: string;
  usuario: string;
  cliente: string;
  modulo: string;
  componente: string;
  mensaje: string;
  stack: string;
}

export const objectError = (elemento?: frontalError): frontalError => {
  let error: frontalError;
  elemento === undefined
    ? (error = {
        fecha: '',
        localizacion: '',
        usuario: '',
        cliente: '',
        modulo: '',
        componente: '',
        mensaje: '',
        stack: ''
      })
    : (error = {
        fecha: elemento.fecha,
        localizacion: elemento.localizacion,
        usuario: elemento.usuario,
        cliente: elemento.cliente,
        modulo: elemento.modulo,
        componente: elemento.componente,
        mensaje: elemento.mensaje,
        stack: elemento.stack
      });

  return error;
};
