import * as React from 'react';
import { Card1x1 } from '../common/Card1x1';
import { ComparativeCard } from '../common/ComparativeGraphCard';
import { EvolutiveGraphCard } from '../common/EvolutiveGraphCard';
import { WaterDistributionCard } from '../common/WaterDistributionCard';
import { WeatherForecastCard } from '../common/WeatherForecastCard';
import { cardType } from './enums/enumCardType';

export const availableCards: any = {
  [cardType.STANDARD_CARD]: <Card1x1 />,
  [cardType.ComparativeCard]: <ComparativeCard />,
  [cardType.EvolutiveCard]: <EvolutiveGraphCard />,
  [cardType.WeatherCard]: <WeatherForecastCard />,
  [cardType.WaterCard]: <WaterDistributionCard />
};
