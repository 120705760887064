import React, { useState, useContext } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { AlertasPendientesORegistradas } from '../../components/alertas/AlertasPendientesORegistradas';
import { TablaCanales } from '../../components/canales/TablaCanales';
import { DeviceManager } from '../../components/device/DeviceManager';
import { DispositivoManager } from '../../components/dispositivos/DispositivoManager';
import { TablaEtiquetas } from '../../components/etiquetas/TablaEtiquetas';
import { TablaGrupos } from '../../components/grupos/TablaGrupos';
import { PluginsDisponibles } from '../../components/plugins/pluginsDisponibles/PluginsDisponibles';
import { PluginsInstalados } from '../../components/plugins/pluginsInstalados/PluginsInstalados';
import { TablaTiposDispositivo } from '../../components/tiposDispositivo/TablaTiposDispositivo';
import { TablaTiposMedidas } from '../../components/tiposMedidas/TablaTiposMedidas';
import { UsuariosManager } from '../../components/usuarios/UsuariosManager';
import ControlContext from '../../context/control/ControlContext';
import UserContext from '../../context/UserContext';
import { enumComponentes } from '../../resources/enums/enumComponente';
import { Modulos } from '../../resources/enums/enumModulo';
import { ReglasManager } from '../../components/reglas/ReglasManager';
import { keyHome } from '../../components/home/homeModulos/resources/enums/enumHome';
import { HomeComun } from '../../components/home/homeModulos/HomeComun';
import { ConfiguracionGeneral } from '../../components/home/homeModulos/configuracionGeneral/ConfiguracionGeneral';
import { UnitsTypes } from 'components/UnitTypes';

export function useReconocimientoVoz(setTab?: any) {
  const [mensajeRecibido, setMensajeRecibido] = useState('');
  const { setControl, setModuloSeleccionado, setPage } = useContext(ControlContext);
  const { datosUsuarioContext } = useContext(UserContext);

  function comprobarModulo(valor: any): { isCorrect: boolean; idModulo: number } {
    const direccionModulo = Number.isInteger(valor) ? valor : modulosVoz[valor.toLowerCase()];
    const permisoModulo = datosUsuarioContext.modulos.find(
      (item) => item.id == parseInt(direccionModulo)
    );
    if (direccionModulo !== undefined && permisoModulo) {
      setPage(1);
      setControl(<></>);
      setModuloSeleccionado(direccionModulo as unknown as number);

      return { isCorrect: true, idModulo: direccionModulo as unknown as number };
    }
    return { isCorrect: false, idModulo: 0 };
  }

  enum modulosVoz {
    'gestión de usuarios' = 1,
    'usuarios' = 1,
    'iluminación' = 2,
    'iluminación viaria' = 2,
    'agua potable' = 3,
    'parques y jardines' = 4,
    'gestión de residuos' = 5,
    'energía' = 6,
    'medio ambiente' = 7,
    'administración ciudadana' = 8,
    'movilidad urbana' = 9,
    'videovigilancia' = 10,
    'control de accesos' = 11,
    'telecomunicaciones' = 12,
    'ia' = 13,
    'big data' = 13,
    'ia y big data' = 13,
    'reglas' = 14,
    'configuración' = 99,
    'configuración general' = 99
  }

  const commands = [
    // {
    //   command: 'I would like to order *',
    //   callback: (food: any) => setMensajeRecibido(`Your order is for: ${food}`)
    // },
    {
      command: [
        'muéstrame los dispositivos de *',
        'ver los dispositivos de *',
        'ver dispositivos de *',
        've a los dispositivos de *',
        'abre los dispositivos de *',
        'dispositivos de *'
      ],
      callback: (modulo: any) => {
        if (comprobarModulo(modulo).isCorrect)
          setControl(<DispositivoManager key={enumComponentes.ADMINISTRACION} />);
      }
    },
    {
      command: [
        'muéstrame los canales de *',
        'ver los canales de *',
        've a los canales de *',
        'abre los canales de *',
        'canales de *'
      ],
      callback: (modulo: any) => {
        if (comprobarModulo(modulo).isCorrect)
          setControl(<TablaCanales key={enumComponentes.ADMINISTRACION} />);
      }
    },
    {
      command: [
        'muéstrame las alertas pendientes de *',
        'ver las alertas pendientes de *',
        've a las alertas pendientes de *',
        'abre las alertas pendientes de *',
        'alertas pendientes de *',
        'muéstrame las alertas sin resolver *',
        'ver las alertas sin resolver de *',
        've a las alertas sin resolver de *',
        'abre las alertas sin resolver de *',
        'alertas sin resolvers de *'
      ],
      callback: (modulo: any) => {
        if (comprobarModulo(modulo).isCorrect)
          setControl(<AlertasPendientesORegistradas key={enumComponentes.ALERTAS_PENDIENTES} />);
      }
    },
    {
      command: [
        'muéstrame las alertas de *',
        'ver las alertas de *',
        've a las alertas de *',
        'abre las alertas de *',
        'alertas de *',
        'muéstrame las alertas registradas de  *',
        'ver las alertas registradas de *',
        've a las alertas registradas de *',
        'abre las alertas registradas de *',
        'alertas registradas de *'
      ],
      callback: (modulo: any) => {
        if (comprobarModulo(modulo).isCorrect)
          setControl(<AlertasPendientesORegistradas key={enumComponentes.ALERTAS_REGISTRADAS} />);
      }
    },
    {
      command: [
        'gestión de usuarios',
        'muéstrame gestión de usuarios',
        'muéstrame los usuarios',
        'ver usuarios',
        'ver gestión de usuarios',
        've a los usuarios ',
        've a gestión de usuarios',
        'abre usuarios',
        'abre gestión de usuarios',
        'gestión de usuarios',
        'usuarios'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['GESTIÓN DE USUARIOS']).isCorrect)
          setControl(<UsuariosManager key={enumComponentes.USUARIOS} />);
      }
    },
    {
      command: [
        'grupos',
        'muéstrame grupos',
        'ver grupos',
        've a los grupos ',
        've a grupos',
        'abre grupos'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<TablaGrupos key={enumComponentes.GRUPOS} />);
      }
    },
    {
      command: [
        'etiquetas',
        'muéstrame etiquetas',
        'ver etiquetas',
        've a las etiquetas',
        've a etiquetas',
        'abre etiquetas'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<TablaEtiquetas key={enumComponentes.ETIQUETAS} />);
      }
    },
    {
      command: [
        'tipos de dispositivos',
        'muéstrame tipos de dispositivos',
        'ver tipos de dispositivos',
        've a los tipos de dispositivos ',
        've a tipos de dispositivos',
        'abre tipos de dispositivos'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<TablaTiposDispositivo key={enumComponentes.TIPOS_DISPOSITIVOS} />);
      }
    },
    {
      command: [
        'tipos de unidades',
        'muéstrame tipos de unidades',
        'ver tipos de unidades',
        've a los tipos de unidades',
        've a tipos de unidades',
        'abre tipos de unidades'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<UnitsTypes key={enumComponentes.TIPOS_UNIDADES} />);
      }
    },
    {
      command: [
        'tipos de medidas',
        'muéstrame tipos de medidas',
        'ver tipos de medidas',
        've a los tipos de medidas',
        've a tipos de medidas',
        'abre tipos de medidas'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<TablaTiposMedidas key={enumComponentes.TIPOS_MEDIDAS} />);
      }
    },
    {
      command: [
        'plugins',
        'muéstrame los plugins',
        'ver plugins',
        've a los plugins',
        've a plugins',
        'abre plugins'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<PluginsDisponibles key={enumComponentes.PLUGINS} />);
      }
    },
    {
      command: [
        'plugins instalados',
        'muéstrame los plugins instalados',
        'ver plugins instalados',
        've a los plugins instalados',
        've a plugins instalados',
        'abre plugins instalados'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<PluginsInstalados key={enumComponentes.PLUGINS_INSTALADOS} />);
      }
    },
    {
      command: [
        'devices',
        'muéstrame devices',
        'ver devices',
        've a los devices',
        've a devices',
        'abre devices'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['CONFIGURACIÓN GENERAL']).isCorrect)
          setControl(<DeviceManager key={enumComponentes.DEVICES} />);
      }
    },
    {
      command: [
        'reglas',
        'muéstrame las reglas',
        'ver reglas',
        've a las reglas',
        've a reglas',
        'abre reglas'
      ],
      callback: () => {
        if (comprobarModulo(Modulos['REGLAS']).isCorrect)
          setControl(<ReglasManager key={enumComponentes.REGLAS} />);
      }
    },
    {
      command: [
        'muéstrame módulo de *',
        'muéstrame módulo *',
        'abrir módulo *',
        'abrir módulo de *',
        'abrir *',
        'ver módulo *',
        'ver *',
        've al módulo *'
      ],
      callback: (modulo: any) => {
        const moduloComprobado = comprobarModulo(modulo);
        if (moduloComprobado.isCorrect) {
          switch (moduloComprobado.idModulo) {
            case Modulos['GESTIÓN DE USUARIOS']:
              setControl(<UsuariosManager key={enumComponentes.USUARIOS} />);
              break;
            case Modulos['CONFIGURACIÓN GENERAL']:
              setControl(
                <ConfiguracionGeneral
                  setControl={setControl}
                  key={Modulos['CONFIGURACIÓN GENERAL']}
                />
              );
              break;
            case Modulos['IA Y BIG DATA']:
              setControl(<></>);
              break;
            case Modulos['REGLAS']:
              setControl(<ReglasManager key={enumComponentes.REGLAS} />);
              break;
            default:
              setControl(<HomeComun key={keyHome.COMUN} />);
              break;
          }
        }
      }
    }

    // {
    //   command: 'The weather is :condition today',
    //   callback: (condition: any) => setMensajeRecibido(`Today, the weather is ${condition}`)
    // },
    // {
    //   command: 'My top sports are * and *',
    //   callback: (sport1: any, sport2: any) => setMensajeRecibido(`#1: ${sport1}, #2: ${sport2}`)
    // },
    // {
    //   command: 'Pass the salt (please)',
    //   callback: () => setMensajeRecibido('My pleasure')
    // },
    // {
    //   command: ['Hello', 'Hi'],
    //   callback: ({ command }: any) => setMensajeRecibido(`Hi there! You said: "${command}"`),
    //   matchInterim: true
    // },
    // {
    //   command: 'Beijing',
    //   callback: (command: any, spokenPhrase: any, similarityRatio: any) =>
    //     setMensajeRecibido(`${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`),
    //   // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
    //   isFuzzyMatch: true,
    //   fuzzyMatchingThreshold: 0.2
    // },
    // {
    //   command: ['eat', 'sleep', 'leave'],
    //   callback: (command: any) => setMensajeRecibido(`Best matching command: ${command}`),
    //   isFuzzyMatch: true,
    //   fuzzyMatchingThreshold: 0.2,
    //   bestMatchOnly: true
    // },
    // {
    //   command: 'clear',
    //   callback: ({ resetTranscript }: any) => resetTranscript()
    // }
  ];
  const { transcript, listening } = useSpeechRecognition({ commands });
  function comenzarEscuchar() {
    SpeechRecognition.startListening({ language: 'es-ES' });
  }

  return { comenzarEscuchar, mensajeRecibido, transcript };
}
