import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import imagenInProgress from '../../../resources/images/in_progress.png';

const useStyles = makeStyles(() => ({
  gridImage: {
    padding: '50px',
    width: '100%',
    height: '100%'
  },
  imgInProgress: {
    width: 'auto',
    maxWidth: '-webkit-fill-available',
    height: 'auto',
    maxHeight: '-webkit-fill-available'
  }
}));
export function InProgress(): JSX.Element {
  const classes = useStyles();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Grid container justifyContent="center" alignItems="center" className={classes.gridImage}>
        <img src={imagenInProgress} alt="imagen-In-Progress" className={classes.imgInProgress} />
      </Grid>
    </div>
  );
}
