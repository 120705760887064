export interface canal {
  idDispositivo?: number;
  id?: number;
  nombre: string;
  activo: boolean | number;
}

export const objectCanal = (elemento?: canal): canal => {
  let canal: canal;
  elemento === undefined
    ? (canal = {
        nombre: '',
        activo: false
      })
    : (canal = {
        id: elemento.id,
        nombre: elemento.nombre,
        activo: elemento.activo
      });

  return canal;
};
