import React from 'react';
import { Grid, Grow, TextField } from '@mui/material';
import { dictionary_password, enumLabel } from '../../../resources/enums/plainText';
import useStyles from './styles';

export function CheckPassword({ state, events }: { state: any; events: any }): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Grow in={state.grow} className={classes.growStyleMain}>
        <div>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              error={state.oldPasswordWrong}
              label={enumLabel.CONTRASENA_ACTUAL}
              id="oldPassword"
              type="password"
              autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
              required={state.grow}
              onChange={events.handleChangeOldPassword}
              onKeyDown={events.handleInputInvalid}
              onPaste={events.handleInputInvalid}
              helperText={state.oldPasswordWrong ? dictionary_password.ERROR_CONTRASENA : ''}
            />
          </Grid>
        </div>
      </Grow>
      <Grow
        in={state.grow}
        className={classes.growStyleSecond}
        {...(state.grow ? { timeout: 800 } : {})}
      >
        <div>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label={enumLabel.CONTRASENA_NUEVA}
              id="newPassword"
              type="password"
              autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
              required={state.grow}
              onChange={events.setPassword}
              inputProps={{
                pattern: dictionary_password.PATRON_CONTRASENA,
                title: dictionary_password.TEXTO_PATRON_CONTRASENA
              }}
            />
          </Grid>
        </div>
      </Grow>
      <Grow
        in={state.grow}
        className={classes.growStyleMain}
        {...(state.grow ? { timeout: 1200 } : {})}
      >
        <div>
          <Grid item xs={12} className={classes.gridStyle}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              error={state.newPasswordWrong}
              label={enumLabel.CONTRASENA_REPETIR}
              id="repeatPassword"
              type="password"
              autoComplete="new-password" // para que el autocompletado del navegador no ponga la guardada
              required={state.grow}
              onChange={events.setRepeatPassword}
              helperText={state.newPasswordWrong ? dictionary_password.ERROR_CONTRASENA : ''}
            />
          </Grid>
        </div>
      </Grow>
    </>
  );
}
