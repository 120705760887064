import * as React from 'react';
import { useConfiguratorContextProperties } from '../types/interfacesCardConfigurator';
import ConfiguratorContext from './ConfiguratorContext';
import { availableCards } from '../../home/homeModulos/resources/commonResources';
import { CardProperties } from '../types/typesCardConfigurator';
import ControlContext from '../../../context/control/ControlContext';

export function useConfiguratorContext(): useConfiguratorContextProperties {
  const { setPendingConfiguratorChanges } = React.useContext(ControlContext);
  const context = React.useContext(ConfiguratorContext);
  if (!context) {
    throw new Error('useConfiguratorContext must be used whitin the ConfiguratorProvider');
  }
  const {
    selectedStructure,
    moduleStructureState: [structureModule, setStructureModule],
    tabState: [tab],
    sizeCardType,
    selectedUserId
  } = context;

  const addCard = React.useCallback(
    (properties: CardProperties, position: number) => {
      const props = {
        ...properties,
        tarjeta: { ...properties.tarjeta, idModulo: tab.selectedModule }
      };

      const size = sizeCardType[properties.tarjeta.idTipoTarjeta as number];

      selectedStructure[position] = React.cloneElement(
        availableCards[properties.tarjeta.idTipoTarjeta as number],
        { ...props }
      );
      if (size) {
        selectedStructure[position + size] = <div data-visible={false} />;
      }
      setStructureModule([...structureModule]);
    },
    [structureModule, selectedStructure]
  );

  function deleteCard(position: number) {
    const { props } = selectedStructure[position];
    const { tarjeta } = props;
    const size = sizeCardType[tarjeta.idTipoTarjeta];
    selectedStructure[position] = <div data-visible={true} />;
    if (size) {
      selectedStructure[position + size] = <div data-visible={true} />;
    }
    setStructureModule([...structureModule]);
  }

  function addModuleWithChange() {
    const objects = structureModule[tab.value]
      .filter((item: any) => typeof item.type === 'function')
      .map((item: any) => {
        return {
          ...item.props,
          tarjeta: {
            ...item.props.tarjeta,
            idUsuario: selectedUserId
          }
        };
      });
    setPendingConfiguratorChanges({
      hasChanges: true,
      changes: objects,
      userId: selectedUserId,
      selectedModule: tab.selectedModule
    });
  }
  function deleteModuleWithChange() {
    setPendingConfiguratorChanges({ hasChanges: false, changes: [], userId: 0, selectedModule: 0 });
  }

  return { ...context, addCard, deleteCard, addModuleWithChange, deleteModuleWithChange };
}
