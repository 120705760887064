import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Grid, Link } from '@mui/material';
import useStyles from './styles';
import ErrorContext from '../context/ErrorContext';
import { useHasError } from '../hooks/useHasError';
import imagenRoto from '../../../resources/images/404_resource.png';
import ControlContext from '../../../context/control/ControlContext';
import { dictionary_generic } from '../../../resources/enums/plainText';

const file = 'ErrorBoundary';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Componente que se mostrará cuando la variable de estado hasError.isError sea true. Este componente mostrará
 * un mensaje de error y dos botones, uno para cerrarlo y otro para ir a inicio.
 * Si el error se ha producido en alguna función independiente, con Cerrar bastaría para seguir de forma normal, en caso
 * de que el error producido sea persistente y pueda provocar otros errores con Inicio volvemos a la ventana principal
 * reestableciendo el modulo seleccionado a ninguno (0)
 */
export default function DialogError(props: {
  datosError?: {
    error: {
      message: string;
      stack: string;
    };
    info: { componentStack: string };
  };
}): JSX.Element {
  const classes = useStyles();
  const { hasError, setHasError } = useContext(ErrorContext);
  const { setModuloSeleccionado } = useContext(ControlContext);
  const { handleError } = useHasError();

  const handleClose = () => {
    setHasError({ isError: false, countErrors: hasError.countErrors });
  };

  const handleInicio = () => {
    setModuloSeleccionado(0);
    handleClose();
  };

  const handleReiniciar = () => {
    window.onbeforeunload = null;
    window.location.href = '/';
  };

  useEffect(() => {
    if (props.datosError) {
      handleError(`[${file}]`, props.datosError.error, props.datosError.info);
    }
  }, [props.datosError]);

  return (
    <div>
      <Dialog
        fullScreen
        open={hasError.isError}
        onClose={handleClose}
        TransitionComponent={hasError.countErrors > 1 ? undefined : Transition}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item sm={5} md={5} className={classes.gridImagen}>
            <img src={imagenRoto} alt="imagen-Roto" className={classes.imagen} />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-end"
            className={classes.gridContenido}
          >
            <Grid item className={classes.gridTextoPrincipal}>
              <Typography variant="h1" className={classes.textoPrincipal}>
                {dictionary_generic.CRUZADO_LOS_CABLES}
              </Typography>
            </Grid>
            <Grid item className={classes.gridTextoSecundario}>
              <Typography variant="h3" className={classes.textoSecundario}>
                {hasError.isNetworkError
                  ? dictionary_generic.SE_HA_PERDIDO_CONEXION
                  : dictionary_generic.NOTIFICAR_PROBLEMA}
              </Typography>
            </Grid>
            <Grid item className={classes.gridBotones}>
              {hasError.countErrors < 5 ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classes.boton}
                >
                  {dictionary_generic.CERRAR}
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleReiniciar} className={classes.boton}>
                  {dictionary_generic.REINICIAR}
                </Button>
              )}
            </Grid>
            {hasError.countErrors < 5 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                className={classes.gridMensajeInicio}
              >
                <Typography variant="h5" color="textSecondary" className={classes.mensajeInicio}>
                  {dictionary_generic.SI_EL_ERROR_PERSISTE}
                </Typography>
                <Link href="#" variant="h5" onClick={handleInicio} color="secondary">
                  {dictionary_generic.INICIO}
                </Link>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
