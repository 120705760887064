/**
 * Enumerado con los diferentes tipos de Formularios en el login
 * @readonly
 * @enum { string } formulario
 * @member LOGIN Formulario de login
 * @member RESTART_PASS Formulario de restablecer la contraseña
 */
export enum formulario {
  LOGIN = 'formLogin',
  RESTART_PASS = 'formRestartPassword'
}
