import React from 'react';
import { actionsReglas } from '../enums/enumActionsReglas';
import { objectAccionCompleta } from '../interfaces/interfaceAccionCompleta';
import { objectRegla } from '../interfaces/interfaceRegla';
const steps = ['Crear regla', 'Añadir condiciones', 'Seleccionar tipo de acción', 'Añadir acción'];
export const reducerReglas = (state: any, action: any) => {
  switch (action.type) {
    case actionsReglas.SET_REGLA:
      return {
        ...state,
        reglaReady: { ...state.reglaReady, regla: action.payload }
      };

    case actionsReglas.SET_LISTA_CONDICIONES:
      return { ...state, listaCondiciones: action.payload };
    case actionsReglas.SET_LISTA_ACCIONES:
      return { ...state, listaAcciones: action.payload };
    case actionsReglas.SET_PANEL_ACCION_ACTIVO:
      return {
        ...state,
        panelAccionActivo: action.payload,
        asistenteReglas: {
          ...state.asistenteReglas,
          activeStep: state.asistenteReglas.activeStep + 1
        }
      };
    case actionsReglas.ADD_CONDICION:
      return { ...state, listaCondiciones: [...state.listaCondiciones, action.payload] };
    case actionsReglas.ADD_ACCION:
      return { ...state, listaAcciones: [...state.listaAcciones, action.payload] };
    case actionsReglas.SET_REGLA_ACTIVA:
      return {
        ...state,
        reglaReady: {
          ...state.reglaReady,
          regla: { ...state.reglaReady.regla, activo: action.payload }
        }
      };
    case actionsReglas.SET_TIPOS_ACCIONES:
      return { ...state, tiposAcciones: action.payload };
    case actionsReglas.SET_TIPO_ACCION_ACTIVA:
      return { ...state, tipoAccionActiva: action.payload };
    case actionsReglas.SET_ESTADO_DIALOGO:
      return { ...state, estadoDialogo: action.payload };
    case actionsReglas.SET_LISTA_REGLAS:
      return { ...state, listaReglas: action.payload };
    case actionsReglas.HANDLE_BUTTON_ASISTENTE:
      return {
        ...state,
        asistenteReglas: { ...state.asistenteReglas, activeStep: action.payload }
      };
    case actionsReglas.SET_ASISTENTE_REGLAS:
      return { ...state, asistenteReglas: action.payload };
    case actionsReglas.CERRAR_DIALOGO_REGLAS:
      return {
        ...state,
        asistenteReglas: { activeStep: 0, steps: steps, componenteAsistente: <></> },
        reglaReady: { regla: objectRegla(), condiciones: [], acciones: [] },
        estadoDialogo: false,
        listaCondiciones: [],
        listaAcciones: []
      };

    case actionsReglas.SET_ESTADO_TELIA:
      return { ...state, estadoTelia: action.payload };
    case actionsReglas.SET_MENSAJE_TELIA:
      return { ...state, mensajeTelia: action.payload };
    case actionsReglas.SET_DIALOGO_CONDICIONES:
      return { ...state, estadoDialogoCondiciones: action.payload };
    case actionsReglas.SET_DIALOGO_ACCIONES:
      return { ...state, estadoDialogoAcciones: action.payload };
    case actionsReglas.MOSTRAR_INFO_ACCION:
      return { ...state, accionActiva: action.payload, isInfoAccionOpen: true };
    case actionsReglas.CERRAR_MOSTRAR_INFO_ACCION:
      return {
        ...state,
        accionActiva: objectAccionCompleta(),
        isInfoAccionOpen: false,
        textoMostrarAccion: ''
      };
    case actionsReglas.SET_TEXTO_MOSTRAR_ACCION:
      return { ...state, textoMostrarAccion: action.payload };
  }
};
