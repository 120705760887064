import * as React from 'react';
import { Grid, Box, TextField, Autocomplete, Grow, useTheme, Button } from '@mui/material';
import { useStyles } from './styles';
import { IconosTiposMedida } from '../../../common/iconosTiposMedida/IconosTiposMedida';
import { useConfEvolutiveGraphCard } from './useConfEvolutiveGraphCard';
import { MapIcons } from '../../../common/gis/components/iconos/Iconos';
import { useDrag } from 'react-dnd';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import ReactApexChart from 'react-apexcharts';
import { CardProperties } from '../../types/typesCardConfigurator';
import { DevicesTableDialog } from '../DevicesTableDialog';
import { dictionary_configurator } from '../../../../resources/enums/plainText';

export function ConfEvolutiveGraphCard(props: CardProperties | unknown): JSX.Element {
  const {
    tabState: [{ selectedModule }]
  } = useConfiguratorContext();

  const cardProps =
    Object.keys(props as Record<string, unknown>).length > 0
      ? { ...(props as CardProperties) }
      : null;

  const {
    handleAutoCompleteTiposMedida,
    handleAutoCompleteDispositivos,
    controlStates,
    handleAutoCompleteTiposDispositivo,
    obj,
    optionsAgregateFunctions,
    optionsAutoCompleteFrecuencia,
    handleAutoCompleteAgregateFunctions,
    handleCardTitle,
    handleRange,
    randomValues
  } = useConfEvolutiveGraphCard(selectedModule, cardProps);

  const classes = useStyles({ color: obj.cardReady ? '#94c390' : '#e0e0e0' });
  const { tiposDispositivo, tiposMedida, dispositivosDisponibles, dataObject } = obj;
  const { tarjeta, dispositivos } = dataObject;
  const theme = useTheme();
  const [openDeviceDialog, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const cardGraphOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      id: 'chart',
      toolbar: {
        show: false
      }
    },
    title: {
      text: tarjeta.titulo,
      style: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontFamily: 'Open Sans'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400
        }
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      show: false
    }
  };

  const [, drag] = useDrag(
    () => ({
      type: 'tarjeta',
      item: {
        ...dataObject,
        tarjeta: { ...dataObject.tarjeta, idTipoTarjeta: cardType.EvolutiveCard }
      },
      canDrag: obj.cardReady,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        canDrag: monitor.canDrag()
      })
    }),
    [obj]
  );

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      justifyContent={'space-evenly'}
      marginTop={'15px'}
      flexDirection={'row'}
    >
      <Grid item xs={7} container spacing={1}>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            onChange={handleCardTitle}
            id="titulo"
            label={dictionary_configurator.TITLE}
            placeholder="Máx, 20 caracteres."
            className={classes.elements}
            fullWidth
            inputProps={{ maxLength: '20' }}
            value={dataObject.tarjeta.titulo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="codigoTipoDispositivo"
            options={tiposDispositivo}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <MapIcons icono={option.icon as string} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => {
              return (
                <div>
                  <TextField
                    {...params}
                    label={dictionary_configurator.DEVICES_TYPES}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: obj.tipoDispositivo ? (
                        <MapIcons icono={obj.tipoDispositivo.icon} />
                      ) : undefined
                    }}
                  ></TextField>
                </div>
              );
            }}
            className={classes.elements}
            onChange={handleAutoCompleteTiposDispositivo}
            value={obj.tipoDispositivo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="tiposMedida"
            options={tiposMedida}
            key={tiposMedida[0]}
            disabled={!tiposMedida.length}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <IconosTiposMedida icono={option.codigo.toString()} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.MEASURE_TYPES}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: obj.medida ? (
                    <IconosTiposMedida icono={obj.medida.codigo} />
                  ) : undefined
                }}
              />
            )}
            className={classes.elements}
            onChange={handleAutoCompleteTiposMedida}
            value={obj.medida}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            sx={{ height: '65%', backgroundColor: 'lightgray', fontSize: '0.4rem' }}
            onClick={() => setOpen(true)}
            disabled={!obj.medida}
          >
            {`${dictionary_configurator.SELECT_DEVICES} (${dispositivos?.length ?? 0})`}
          </Button>
          <DevicesTableDialog
            devices={dispositivosDisponibles}
            openDialog={openDeviceDialog}
            handleCloseDialog={handleClose}
            onRowSelected={handleAutoCompleteDispositivos}
            selectedItems={dispositivos ?? []}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="range"
            options={optionsAutoCompleteFrecuencia}
            getOptionLabel={(option) => option.frecuencia}
            renderInput={(params) => (
              <TextField {...params} label={dictionary_configurator.RANGE} variant="outlined" />
            )}
            className={classes.elements}
            onChange={handleRange}
            value={obj.range}
            disabled={controlStates.deshabilitarRango}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="operation"
            options={optionsAgregateFunctions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.AGGREGATE_FUNCTION}
                variant="outlined"
              />
            )}
            className={classes.elements}
            getOptionDisabled={(option) =>
              dataObject.dispositivos && dataObject.dispositivos.length > 1
                ? option.name !== 'Media' && option.name !== 'Acumulado'
                : false
            }
            onChange={handleAutoCompleteAgregateFunctions}
            disabled={controlStates.operationDisabled || dispositivosDisponibles.length === 0}
            value={obj.operation}
          />
        </Grid>
      </Grid>

      <Grid item container xs={5} justifyContent="flex-end" component={'div'} ref={drag}>
        <Grid
          item
          xs={12}
          style={{
            cursor: obj.cardReady ? 'move' : 'not-allowed',
            maxWidth: '95%'
          }}
        >
          <Grow in timeout={650} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>
              <CustomTooltip title={tarjeta.titulo} placement={'bottom'}>
                <Box className={classes.boxColumn}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <ReactApexChart
                      options={cardGraphOptions}
                      series={[...randomValues]}
                      height={150}
                    />
                  </div>
                </Box>
              </CustomTooltip>
            </div>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
}
