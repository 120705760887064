import * as React from 'react';
import { Box, Grid, LinearProgress, Tooltip, Typography } from '@mui/material';
import useStyles from '../styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DocumentsContainer } from '../documentsContainer/DocumentsContainer';
import ControlContext from '../../../../context/control/ControlContext';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { dictionary_dispositivos, dictionary_entornos } from '../../../../resources/enums/plainText';
import { VolverAtras } from '../../../common/botonVolverAtras/VolverAtras';
import { DispositivoManager } from '../../../dispositivos/DispositivoManager';
import { useControlador } from '../../../../hooks/useControlador/useControlador';
import { action } from '../../../../hooks/useControlador/resources/enums/enumActions';
import { serviceRoutes } from '../../../../resources/enums/enumRutasServicios';
import { formateoFechaGrafico } from '../../../graficas/functions/funciones';
import axios from 'axios';
import { desencriptarRespuesta } from '../../../../functions/encryption';
import UserContext from '../../../../context/UserContext';
import { getHeaders } from 'functions/functions';

const formateoFecha = formateoFechaGrafico.format('%Y-%m-%d %H:%M:%S');

export function DocumentModuleManager() {
  const classes = useStyles();

  const { moduloSeleccionado } = React.useContext(ControlContext);
  const {
    datosUsuarioContext: { cliente }
  } = React.useContext(UserContext);
  const [documents, setDocuments] = React.useState<File[]>([]);
  const [usedCapacity, setUsedCapacity] = React.useState(0);
  const { controllerRequest } = useControlador();
  const capacidadUsadaPorcentaje =
    parseFloat(((usedCapacity * 100) / cliente.capacidad).toFixed(2)) || 0;

  React.useEffect(() => {
    controllerRequest({
      type: action.OBTENER_DATOS_POST,
      payload: {
        service: serviceRoutes.GET_DOCUMENTS_BY_MODULE,
        object: { idModulo: moduloSeleccionado }
      }
    }).then((res = []) => {
      res = res.map((item: any) => {
        return { ...item, uploadDate: formateoFecha(new Date(item.uploadDate)) };
      });

      setDocuments(res);
    });
  }, []);
  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL_LOCAL + serviceRoutes.CONSULT_TOTAL_SIZE, {
        headers: getHeaders(dictionary_entornos.BACK)
      })
      .then((res) => {
        const response = desencriptarRespuesta(res);
        setUsedCapacity(parseFloat(response.data.result.toFixed(2)));
      });
  }, [documents]);

  return (
    <div className={classes.root}>
      <Box display="flex" marginBottom="30px" alignItems={'center'}>
        <VolverAtras backTo={<DispositivoManager key={enumComponentes.ADMINISTRACION} />} />
        <Typography variant="h6">{dictionary_dispositivos.GESTOR_DOCUMENTAL_GENERAL}</Typography>
      </Box>
      <Grid container spacing={2} height={'95%'}>
        <DndProvider backend={HTML5Backend}>
          <DocumentsContainer
            idDispositivo={null}
            documents={documents}
            setDroppedFiles={setDocuments}
            idModulo={moduloSeleccionado}
            exceededCapacity={usedCapacity > cliente.capacidad}
          />
        </DndProvider>
        <Tooltip
          title={`${cliente.capacidad} mb de capacidad total, ${usedCapacity ?? 0} mb usados.`}
        >
          <Grid item xs={6}>
            <Typography>{`Capacidad total de documentos : ${cliente.capacidad} mb`}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={capacidadUsadaPorcentaje}
                  color={'secondary'}
                  sx={{ height: '8px' }}
                />
              </Box>
              <Box sx={{ minWidth: 85 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${capacidadUsadaPorcentaje}% Usado`}</Typography>
              </Box>
            </Box>
          </Grid>
        </Tooltip>
      </Grid>
    </div>
  );
}
