import axios from 'axios';
import { desencriptarRespuesta, encriptarJSON } from '../functions/encryption';
import { getHeaders } from '../functions/functions';
import { dictionary_entornos } from '../resources/enums/plainText';

let path = '';
let respuesta: any;

/**
 * Función que crea un nuevo usuario en la basae de datos
 *
 * @param { string } servicio ruta del servicio donde hacemos la petición
 * @param { any } objeto objeto que vamos a crear
 * @param { number } idUsuario id del usuario que realiza la acción.
 *
 * @return { Promise<any> } retorna una promesa con la respuesta que indica si se ha creado correctamente
 */
export async function crearUnRegistro(
  servicio: string,
  objeto: any,
  idUsuario?: number | undefined
): Promise<any> {
  path = servicio + '/';
  const body = idUsuario != undefined ? { ...objeto, idUsuario } : { ...objeto };
  try {
    respuesta = await axios.post(process.env.REACT_APP_URL_LOCAL + path, encriptarJSON(body), {
      timeout: 35000,
      headers: getHeaders(dictionary_entornos.BACK)
    });
    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que eliminar uno o varios registros
 *
 * @param {string} servicio ruta del servicio donde hacemos la petición
 * @param {number[]} id id o ids de los registros que vamos a eliminar
 *
 * @return {Promise<any>} retorna una promesa con la respuesta de axios que indica si se han eliminado correctamente
 */
export async function eliminarUnoVariosRegistros(servicio: string, id: number[]): Promise<any> {
  path = servicio + `/eliminado/fisico`;
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({ ids: id }),
      {
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}

/**
 * Función que crea un nuevo usuario en la basae de datos
 *
 * @param {string} servicio ruta del servicio donde hacemos la petición
 * @param {any} objeto usuario que vamos a crear
 *
 * @return {Promise<any>} retorna una promesa con la respuesta que indica si se ha creado correctamente
 */
export async function modificarUnRegistro(servicio: string, objeto: any, id: number): Promise<any> {
  path = servicio + '/' + id;
  try {
    respuesta = await axios.put(
      process.env.REACT_APP_URL_LOCAL + path,
      encriptarJSON({ ...objeto }),
      {
        timeout: 35000,
        headers: getHeaders(dictionary_entornos.BACK)
      }
    );

    return desencriptarRespuesta(respuesta);
  } catch (exception) {
    return exception;
  }
}
