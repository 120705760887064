/** Este enum hace referencia a los códigos de los tipos de dispositivo
 * @readonly
 * @enum {number} tipoDispositivo
 * @param LUMINARIA = 1,
 * @param CAUDALIMETRO = 2,
 * @param SENSOR = 3,
 * @param CUADRO_ELECTRICO = 4,
 * @param ESTACION_METEREOLOGICA = 5,
 * @param SENSOR_HUMEDAD = 6,
 * @param ELECTROVALVULA = 7,
 * @param SENSOR_RESIDUOS = 8,
 * @param METERING = 9,
 * @param CAMARA = 10,
 * @param WIFI = 11,
 * @param PARKING = 12,
 * @param ANENOMETRO = 13,
 * @param PLUVIOMETRO = 14,
 * @param ENERGIA_SOLAR = 15,
 * @param GATEWAY = 16,
 * @param ACTUADOR = 17,
 * @param AEMET = 18,
 * @param CONTADOR_FISCAL = 19,
 * @param PTZ = 20,
 * @param VIDEOGRABADOR = 21,
 * @param   CALIDAD_AGUA_ABASTECIMIENTO = 22,
 * @param SENSOR_CONTAMINACION_ENTRADA = 23,
 * @param SENSOR_CONTAMINACION_SALIDA = 24,
 * @param CAUDALIMETRO_ENTRADA_CAUDAL = 25,
 * @param CAUDALIMETRO_CAUDAL = 26,
 * @param SONDA_NIVEL_ABASTECIMIENTO = 27,
 * @param ESTACION_MEDIOAMBIENTAL = 28,
 * @param COLMENA = 29
 */
export enum TIPOS_DISPOSITIVO {
  LUMINARIA = 1,
  CAUDALIMETRO = 2,
  SENSOR = 3,
  CUADRO_ELECTRICO = 4,
  ESTACION_METEREOLOGICA = 5,
  SENSOR_HUMEDAD = 6,
  ELECTROVALVULA = 7,
  SENSOR_RESIDUOS = 8,
  METERING = 9,
  CAMARA = 10,
  WIFI = 11,
  PARKING = 12,
  ANENOMETRO = 13,
  PLUVIOMETRO = 14,
  ENERGIA_SOLAR = 15,
  GATEWAY = 16,
  ACTUADOR = 17,
  AEMET = 18,
  CONTADOR_FISCAL = 19,
  PTZ = 20,
  VIDEOGRABADOR = 21,
  CALIDAD_AGUA_ABASTECIMIENTO = 22,
  SENSOR_CONTAMINACION_ENTRADA = 23,
  SENSOR_CONTAMINACION_SALIDA = 24,
  CAUDALIMETRO_ENTRADA_CAUDAL = 25,
  CAUDALIMETRO_CAUDAL = 26,
  SONDA_NIVEL_ABASTECIMIENTO = 27,
  ESTACION_MEDIOAMBIENTAL = 28,
  COLMENA = 29
}
