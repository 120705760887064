import useSWR from 'swr';
import { AirPollution } from './types';

const appid = '513ac38e5c52621f75668173c5688921';

/**
 * https://openweathermap.org/api/air-pollution
 * @param {number} lat
 * @param {number} lon
 * @returns {AirPollution}
 */
export function useGetAirPollution(lat: number | null, lon: number | null) {
  let key: string | null = null;

  const url = `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${appid}`;

  if (lat && lon) {
    key = `airPollution-${lat}-${lon}`;
  }
  const { data: airPollution } = useSWR<AirPollution>(
    key,
    () => fetch(url).then((res) => res.json()),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  return { airPollution };
}
