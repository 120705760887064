export enum ContextMenuOptions {
  'edit' = 'Editar',
  'delete' = 'Eliminar',
  'save' = 'Guardar',
  'delete_configuration' = 'Eliminar configuración'
}

export enum CardNames {
  STANDARD_CARD = 'Tarjeta estándar',
  COMPARATIVE_CARD = 'Comparativa de fechas',
  EVOLUTIVE_CARD = 'Evolutiva',
  WEATHER_CARD = 'Previsón semanal del clima',
  WATER_CARD = 'Gestión del agua'
}
