import * as React from 'react';
import { Grid, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/FileDownload';
import { enumTabla } from '../../../common/table/resources/enumTabla';
import { ContenedorElementos } from '../../../common/table/contenedorElementos';
import { useTheme } from '@mui/material/styles';
import { BotonTablaFilaGenerico } from '../../../common/table/BotonesTablaFila';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import { Box } from '@mui/system';
import { DataTableReport } from 'components/Reports/shared/DataTableReport/DataTableReport';
import { useReportContext } from 'components/Reports/Context/index.hook';
import { dictionary_generic } from 'resources/enums/plainText';
import { ResidualParametersDetail } from './Detail';
import { useStateDialogReports } from 'components/Reports/shared/hooks/useStateDialogDetails';

const components = {
  icons: { DownloadIcon }
};

const columnsOptions = {
  options: {
    setCellProps: () => {
      return {
        style: { fontSize: '0.365rem', padding: '12px' }
      };
    },
    setCellHeaderProps: () => {
      return {
        className: 'cellHeaderCustomTable'
      };
    }
  }
};

export function ResidualsParameters(): JSX.Element {
  const theme = useTheme();

  const { openDetail, handleOpen, handleClose } = useStateDialogReports();

  const {
    reportData: [reportData]
  } = useReportContext();

  const {
    report: { data, foot }
  } = reportData as { report: { data; foot; head; body } };

  const columns = [
    { name: 'parametro', label: 'PARÁMETRO', ...columnsOptions },
    { name: 'concentracion', label: 'CONCENTRACIÓN', ...columnsOptions },
    { name: 'minimoReduccion', label: '%MÍNIMO DE REDUCCIÓN', ...columnsOptions },
    { name: 'rendimiento', label: 'RENDIMIENTO', ...columnsOptions },
    { name: 'notas', label: 'NOTAS', ...columnsOptions },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
        customBodyRender: (_value: string, tableMeta: any): JSX.Element => {
          if (tableMeta.rowData[2] === 'Sin información') return <></>;

          return (
            <div key={enumTabla.BOTONES_TABLA}>
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaGenerico
                    datosTabla={tableMeta}
                    handleClick={handleOpen}
                    nombreBoton={'Ver detalles'}
                    icono={<ArtTrackIcon />}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase="botones"
              />
            </div>
          );
        }
      }
    },
    { name: 'color', options: { display: 'excluded' } }
  ];

  const rowsOptions = {
    filter: false,
    fixedHeader: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    pagination: false,
    tableBodyHeight: '16rem',
    viewColumns: false,
    setRowProps: (row: Array<any>) => {
      return {
        style: { backgroundColor: row[6] + '80', padding: '10px' }
      };
    },
    customTableBodyFooterRender: () => {
      return (
        <TableFooter
          style={{ position: 'sticky', bottom: 0, zIndex: 100, backgroundColor: 'white' }}
        >
          <TableRow>
            <TableCell colSpan={6}>
              <Box display={'flex'} justifyContent={'space-around'}>
                {foot.map((item: { name: string; color: string }) => (
                  <Box
                    display={'flex'}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    key={item.name}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: item.color,
                        marginRight: '5px'
                      }}
                    ></div>
                    <Typography>{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container style={{ paddingLeft: '40px' }}>
        <Grid item xs={12}>
          {data ? (
            <DataTableReport
              body={data}
              columns={columns}
              variableOptions={rowsOptions}
              components={components}
            />
          ) : (
            <Typography variant={'h4'}>{dictionary_generic.NO_DATA}</Typography>
          )}
        </Grid>
      </Grid>
      <ResidualParametersDetail openDetail={openDetail} handleClose={handleClose} />
    </React.Fragment>
  );
}
