import axios, { AxiosRequestConfig } from 'axios';
import { Group } from 'components/graficas/resources/enums/enumGroup';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import { encriptarJSON } from 'functions/encryption';
import { getHeaders } from 'functions/functions';
import { TiposMedida } from 'resources/enums/enumTiposMedida';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * @returns {Key,AxiosRequestConfig} key used by swr and axios request config.
 */
export function getDetailWaterQuality() {
  const key: Key = swrServiceRoutes.getWaterQualityDetailData;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getWaterQualityDetailData,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  // //The arg is passed when 'trigger' from useSWRMutation is used.
  // const request = (
  //   headers,
  //   { arg }: { arg: { deviceId: number; measurementCode: TiposMedida; range: Rango; group: Group } }
  // ) =>
  //   axios.post(url, encriptarJSON({ ...arg }), { headers: headers }).then((res) => {
  //     return res;
  //   });

  return { key, request };
}
