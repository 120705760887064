import { useContext, useRef } from 'react';
import { useObtenerCanales } from '../../components/canales/hooks/useObtenerCanales';
import { useSection } from '../../components/sections/hooks/useSection';
import { tokenCaducado } from '../../functions/functions';
import { useLoginUsuario } from '../../hooks/useLogin/useLoginUsuario';
import { action } from './resources/enums/enumActions';
import { reducerActions } from './resources/types/typeReducerActions';
import { useRespuesta } from '../../hooks/useRespuesta/useRespuesta';
import { Severity } from '../../components/common/snackbar/resources/types/Severity';
import { enumSeverity } from '../../components/common/snackbar/resources/enums/enumSeverity';
import { useDevice } from '../../components/device/hooks/useDevice';
import { useCud } from '../useCud/useCud';
import { respuestaControlador } from './resources/interfaces/interfaceRespuestaControlador';
import { respuesta } from '../../resources/interfaces/interfaceRespuesta';
import { useHasError } from '../../components/error/hooks/useHasError';
import { usePlugin } from '../../components/plugins/hooks/usePlugin';
import { useObtenerReglas } from '../../components/reglas/hooks/useObtenerReglas';
import { useObtener } from '../useObtener/useObtener';
import { useDispositivos } from '../../components/dispositivos/hook/useDispositivo';
import DatosContext from '../../context/DatosContext';
import ControlContext from '../../context/control/ControlContext';
import { Modulos } from '../../resources/enums/enumModulo';
import SmartTeliaBackdropContext from '../../context/SmartTeliaBackdropContext';
import { typeResponse } from './resources/enums/typeResponse';
import { useAlertas } from '../../components/alertas/hooks/useAlertas';
import { useTiposDispositivos } from '../../components/tiposDispositivo/hook/useTipoDispositivo';
import {
  modificarPermisosUsuario,
  obtenerDatosUsuario,
  obtenerPerfilesUsuario,
  obtenerPermisosUsuario,
  serviceModificarUsuario
} from '../../components/usuarios/services/usuario';
import { useObtenerTiposActuaciones } from '../../components/tiposActuaciones/hooks/useObtenerTiposActuaciones';
import { useEtiquetas } from '../../components/etiquetas/hooks/useEtiquetas';
import { useObtenerDatosPost } from 'shared/hooks/useObtenerDatosPost';

export const CONTROLADOR_ACTIONS = {
  GUARDAR_RESPUESTA: action.GUARDAR_RESPUESTA,
  OBTENER_CANALES_BY_DISPOSITIVOS: action.OBTENER_CANALES_BY_DISPOSITIVOS,
  OBTENER_CANALES_DATOS_BY_DISPOSITIVOS: action.OBTENER_CANALES_DATOS_BY_DISPOSITIVOS,
  CAMBIAR_ESTADO_SECTION: action.CAMBIAR_ESTADO_SECTION,
  OBTENER_TIPOS_DEVICE_BY_PLUGIN: action.OBTENER_TIPOS_DEVICE_BY_PLUGIN,
  OBTENER_PLUGIN_BY_NOMBRE: action.OBTENER_PLUGIN_BY_NOMBRE,
  OBTENER_PLUGIN_BY_DEVICE: action.OBTENER_PLUGIN_BY_DEVICE,
  CAMBIAR_ESTADO_PLUGIN: action.CAMBIAR_ESTADO_PLUGIN,
  OBTENER_CONFIGURACION_BY_TIPO_DEVICE: action.OBTENER_CONFIGURACION_BY_TIPO_DEVICE,
  OBTENER_CONFIGURACION_VALOR_BY_DEVICE: action.OBTENER_CONFIGURACION_VALOR_BY_DEVICE,
  OBTENER_DEVICES_BY_PLUGIN: action.DEVICES_BY_PLUGIN,
  OBTENER_SECTIONS_BY_DEVICE: action.SECTION_BY_DEVICE,
  CREAR_REGISTRO: action.CREAR_UN_REGISTRO,
  MODIFICAR_REGISTRO: action.MODIFICAR_REGISTRO,
  ELIMINAR_REGISTROS: action.ELIMINAR_REGISTROS,
  OBTENER_TODOS_DATOS: action.OBTENER_TODOS_DATOS,
  OBTENER_DATOS_ELIMINADOS: action.OBTENER_DATOS_ELIMINADOS,
  OBTENER_DATOS_BY_MODULO: action.OBTENER_DATOS_BY_MODULO,
  OBTENER_DATOS_BY_ID: action.OBTENER_DATOS_BY_ID,
  OBTENER_DATOS_SIN_MODULO: action.OBTENER_DATOS_SIN_MODULO,
  OBTENER_ALERTAS_BY_DISPOSITIVO: action.OBTENER_ALERTAS_BY_DISPOSITIVO,
  OBTENER_ALERTAS_BY_MODULO: action.OBTENER_ALERTAS_BY_MODULO,
  OBTENER_ALERTAS_SIN_RESOLVER_POR_MODULO: action.OBTENER_ALERTAS_SIN_RESOLVER_POR_MODULO,
  OBTENER_ALERTAS_BY_TIPO_ALERTA: action.OBTENER_ALERTAS_BY_TIPO_ALERTA,
  OBTENER_DISPOSITIVOS_CON_ALERTAS: action.OBTENER_DISPOSITIVOS_CON_ALERTAS,
  OBTENER_ALERTAS_TIPO_ERROR: action.OBTENER_ALERTAS_TIPO_ERROR,
  OBTENER_ALERTAS_SIN_RESOLVER: action.OBTENER_ALERTAS_SIN_RESOLVER,
  OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO: action.OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO,
  OBTENER_ELEMENTOS_POR_REGLA: action.OBTENER_ELEMENTOS_POR_REGLA,
  OBTENER_TIPO_DISPOSITIVO_BY_DISPOSITIVO: action.OBTENER_TIPO_DISPOSITIVO_BY_DISPOSITIVO,
  OBTENER_DEVICES_BY_TIPO_DEVICE: action.OBTENER_DEVICES_BY_TIPO_DEVICE,
  OBTENER_SECTIONS_ESCRITURA_ACTIVAS: action.OBTENER_SECTIONS_ESCRITURA_ACTIVAS,
  MODIFICAR_USUARIO: action.MODIFICAR_USUARIO,
  OBTENER_DISPOSITIVOS_MAYOR_CONSUMO: action.OBTENER_DISPOSITIVOS_MAYOR_CONSUMO,
  OBTENER_PERFILES_USUARIO: action.OBTENER_PERFILES_USUARIO,
  OBTENER_PERMISOS_USUARIO: action.OBTENER_PERMISOS_USUARIO,
  MODIFICAR_PERMISOS_USUARIO: action.MODIFICAR_PERMISOS_USUARIO,
  OBTENER_DATOS_USUARIO: action.OBTENER_DATOS_USUARIO,
  OBTENER_CONSUMO_HOME: action.OBTENER_CONSUMO_HOME,
  OBTENER_MINAVGMAX_HOME: action.OBTENER_MINAVGMAX_HOME,
  OBTENER_CALIDAD_AGUA: action.OBTENER_CALIDAD_AGUA,
  OBTENER_ULTIMOS_VALORES_CALIDAD_AGUA: action.OBTENER_ULTIMOS_VALORES_CALIDAD_AGUA,
  OBTENER_AHORRO_ENERGETICO_BY_RANGE: action.OBTENER_AHORRO_ENERGETICO_BY_RANGE,
  OBTENER_DISPOSITIVO_ACTUACION_BY_TIPO_DISPOSITIVO:
    action.OBTENER_DISPOSITIVO_ACTUACION_BY_TIPO_DISPOSITIVO,
  OBTENER_TIPOS_ACTUACIONES_POR_DISPOSITIVO: action.OBTENER_TIPOS_ACTUACIONES_POR_DISPOSITIVO,
  OBTENER_INFO_DEVICE_PLUGIN: action.OBTENER_INFO_DEVICE_PLUGIN,
  OBTENER_ENERGIA_PRODUCIDA: action.OBTENER_ENERGIA_PRODUCIDA,
  OBTENER_DATOS_SENSOR: action.OBTENER_DATO_SENSOR,
  RESOLVER_ALERTA: action.RESOLVER_ALERTA,
  OBTENER_DATOS_CICLO_DEL_AGUA: action.OBTENER_DATOS_CICLO_DEL_AGUA,
  OBTENER_ETIQUETAS_BY_DISPOSITIVOS: action.OBTENER_ETIQUETAS_BY_DISPOSITIVOS,
  OBTENER_LISTADISPOSITIVOS_BY_TIPOSDISPOSITIVOS:
    action.OBTENER_LISTADISPOSITIVOS_BY_TIPOSDISPOSITIVOS,
  OBTENER_CANALES_POR_GRUPO: action.OBTENER_CANALES_POR_GRUPO,
  OBTENER_DATOS_POST: action.OBTENER_DATOS_POST,
  OBTENER_ALERTAS_TODAS_SIN_RESOLVER: action.OBTENER_ALERTAS_TODAS_SIN_RESOLVER,
  OBTENER_TODOS_LOS_DEVICES_DISPONIBLES: action.OBTENER_TODOS_LOS_DEVICES_DISPONIBLES
};

export function useControlador(): {
  controllerRequest: (
    action: reducerActions,
    backdrop?: { openSmartTeliaBackdrop?: boolean; closeSmartTeliaBackdrop?: boolean }
  ) => Promise<any>;
  completadoCorrectamente: React.MutableRefObject<boolean>;
  propiedadesSnackBar: React.MutableRefObject<{
    texto: string;
    severity: Severity;
  }>;
  registroGenerado: React.MutableRefObject<any>;
} {
  const {
    obtenerCanalesByDispositivo,
    obtenerCanalesDatosByDispositivo,
    obtenerNombresCanalesByGrupo
  } = useObtenerCanales();
  const { cambiarEstadoSection, obtenerSectionsByDevice, obtenerSectionsEscrituraActivas } =
    useSection();
  const { obtenerEtiquetasByDispositivo } = useEtiquetas();
  const {
    obtenerConfiguraciónTipoDevice,
    obtenerConfiguracionValorByDevice,
    obtenerDevicesByPlugin,
    obtenerDevicesByTipoDevice,
    obtenerInfoDevicePlugin
  } = useDevice();
  const {
    obtenerTiposDevicesByPlugin,
    obtenerPluginByNombre,
    obtenerPluginByDevice,
    cambiarEstadoPlugin
  } = usePlugin();
  const {
    obtenerDispositivoPorTipoDispositivo,
    obtenerDispositivosConAlertas,
    obtenerDispositivoActuacionPorTipoDispositivo,
    obtenerListadispositivosByipoDispositivos,
    getAllDeviceFree
  } = useDispositivos();
  const { obtenerTipoDispositivoPorDispositivo } = useTiposDispositivos();
  const { crearRegistro, modificarRegistro, eliminarRegistros } = useCud();
  const { obtenerElementosPorRegla } = useObtenerReglas();
  const {
    obtenerDatosServicioGenerico,
    obtenerDatosEliminados,
    obtenerDatosContextoPorModulo,
    obtenerDatosContextoPorId,
    obtenerDatosContextoSinModulo
  } = useObtener();
  const {
    obtenerAlertasByDispositivo,
    obtenerAlertasByModulo,
    obtenerAlertasByTipoAlerta,
    obtenerAlertasByTipoError,
    obtenerAlertasSinResolver,
    obtenerAlertasSinResolverPorModulo,
    resolverAlerta,
    obtenerTodasAlertasSinResolver
  } = useAlertas();
  const { obtenerDatosPost } = useObtenerDatosPost();
  const { controladorDatosContexto, controladorDatosContextoPorId } = useContext(DatosContext);
  const { obtenerTiposActuacionesByDispositivo } = useObtenerTiposActuaciones();
  const { moduloSeleccionado, signal } = useContext(ControlContext);
  const { setOpenSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { logout } = useLoginUsuario();
  const { handleError } = useHasError();
  const completado = useRef<boolean>(false);
  const propiedades = useRef<{
    texto: string;
    severity: Severity;
  }>({ texto: '', severity: enumSeverity.INFO });
  const registroGenerado = useRef<any>();
  const { tratarRespuesta, completadoCorrectamente, propiedadesSnackBar } = useRespuesta(
    completado,
    propiedades
  );
  // const { modificarUsuario } = useReducerUsuarios();

  const executeRequest: any = async (action: any, signal: AbortSignal) => {
    const response: respuestaControlador = {
      responseService: undefined,
      typeResponse: undefined,
      contexto: undefined
    };
    switch (action.type) {
      case CONTROLADOR_ACTIONS.OBTENER_CANALES_BY_DISPOSITIVOS:
        response.responseService = await obtenerCanalesByDispositivo(
          action.payload.servicio,
          action.payload.idDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_CANALES_DATOS_BY_DISPOSITIVOS:
        response.responseService = await obtenerCanalesDatosByDispositivo(
          action.payload.servicio,
          action.payload.idDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.CAMBIAR_ESTADO_SECTION:
        response.responseService = await cambiarEstadoSection(
          action.payload.servicio,
          action.payload.id,
          action.payload.nuevoActivo
        );
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_TIPOS_DEVICE_BY_PLUGIN:
        response.responseService = await obtenerTiposDevicesByPlugin(
          action.payload.servicio,
          action.payload.idPlugin
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_PLUGIN_BY_NOMBRE:
        response.responseService = await obtenerPluginByNombre(
          action.payload.servicio,
          action.payload.nombre
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_PLUGIN_BY_DEVICE:
        response.responseService = await obtenerPluginByDevice(
          action.payload.servicio,
          action.payload.idDevice
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.CAMBIAR_ESTADO_PLUGIN:
        response.responseService = await cambiarEstadoPlugin(
          action.payload.servicio,
          action.payload.objeto
        );
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_CONFIGURACION_BY_TIPO_DEVICE:
        response.responseService = await obtenerConfiguraciónTipoDevice(
          action.payload.servicio,
          action.payload.idTipoDevice
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_CONFIGURACION_VALOR_BY_DEVICE:
        response.responseService = await obtenerConfiguracionValorByDevice(
          action.payload.servicio,
          action.payload.idDevice
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DEVICES_BY_PLUGIN:
        response.responseService = await obtenerDevicesByPlugin(
          action.payload.servicio,
          action.payload.idPlugin
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_SECTIONS_BY_DEVICE:
        response.responseService = await obtenerSectionsByDevice(
          action.payload.servicio,
          action.payload.idDevice,
          action.payload.escritura,
          action.payload.idCanal,
          action.payload.onlyFree
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.CREAR_REGISTRO:
        response.responseService = await crearRegistro(
          action.payload.servicio,
          action.payload.objeto,
          action.payload.idUsuario
        );
        response.typeResponse = typeResponse.information;
        break;

      case CONTROLADOR_ACTIONS.MODIFICAR_REGISTRO:
        response.responseService = await modificarRegistro(
          action.payload.servicio,
          action.payload.objeto,
          action.payload.id
        );
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.ELIMINAR_REGISTROS:
        response.responseService = await eliminarRegistros(
          action.payload.servicio,
          action.payload.id
        );
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_TODOS_DATOS:
        response.responseService = await obtenerDatosServicioGenerico(action.payload.servicio);
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_ELIMINADOS:
        response.responseService = await obtenerDatosEliminados(action.payload.servicio);
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_BY_MODULO:
        response.responseService = await obtenerDatosContextoPorModulo(
          action.payload.servicio,
          action.payload.modulo
        );
        response.typeResponse = typeResponse.contexto;

        response.contexto =
          Modulos[
            action.payload.modulo !== undefined ? action.payload.modulo : moduloSeleccionado
          ] + `:${action.payload.servicio}`;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_BY_ID:
        response.responseService = await obtenerDatosContextoPorId(
          action.payload.servicio,
          action.payload.id
        );
        response.contexto = action.payload.servicio;
        response.typeResponse = typeResponse.contexto_by_id;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_SIN_MODULO:
        response.responseService = await obtenerDatosContextoSinModulo(action.payload.servicio);
        response.typeResponse = typeResponse.contexto;
        response.contexto = action.payload.servicio;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DISPOSITIVO_BY_TIPO_DISPOSITIVO:
        response.responseService = await obtenerDispositivoPorTipoDispositivo(
          action.payload.servicio,
          action.payload.idTipoDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_TIPO_DISPOSITIVO_BY_DISPOSITIVO:
        response.responseService = await obtenerTipoDispositivoPorDispositivo(
          action.payload.servicio,
          action.payload.idDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ELEMENTOS_POR_REGLA:
        response.responseService = await obtenerElementosPorRegla(
          action.payload.servicio,
          action.payload.idRegla
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_BY_DISPOSITIVO:
        response.responseService = await obtenerAlertasByDispositivo(
          action.payload.servicio,
          action.payload.idDispositivo,
          action.payload.range,
          action.payload.fechaDesde,
          action.payload.fechaHasta,
          action.payload.componente
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_BY_MODULO:
        response.responseService = await obtenerAlertasByModulo(
          action.payload.servicio,
          action.payload.range,
          action.payload.fechaDesde,
          action.payload.fechaHasta
        );
        response.typeResponse = typeResponse.contexto;
        response.contexto =
          Modulos[moduloSeleccionado] +
          `:${action.payload.servicio}:${action.payload.range}:${action.payload.fechaDesde}:${action.payload.fechaHasta}`;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_BY_TIPO_ALERTA:
        response.responseService = await obtenerAlertasByTipoAlerta(
          action.payload.servicio,
          action.payload.idTipoAlerta
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_SIN_RESOLVER:
        response.responseService = await obtenerAlertasSinResolver(action.payload.servicio);
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_TODAS_SIN_RESOLVER:
        response.responseService = await obtenerTodasAlertasSinResolver(action.payload.servicio);
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DISPOSITIVOS_CON_ALERTAS:
        response.responseService = await obtenerDispositivosConAlertas(
          action.payload.servicio,
          action.payload.idModulo,
          signal
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;

      case CONTROLADOR_ACTIONS.OBTENER_INFO_DEVICE_PLUGIN:
        response.responseService = await obtenerInfoDevicePlugin(
          action.payload.servicio,
          action.payload.idDevice
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;

      case CONTROLADOR_ACTIONS.OBTENER_DEVICES_BY_TIPO_DEVICE:
        response.responseService = await obtenerDevicesByTipoDevice(
          action.payload.servicio,
          action.payload.idTipoDevice
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;

      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_TIPO_ERROR:
        response.responseService = await obtenerAlertasByTipoError(
          action.payload.servicio,
          action.payload.range,
          action.payload.fechaDesde,
          action.payload.fechaHasta
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_SECTIONS_ESCRITURA_ACTIVAS:
        response.responseService = await obtenerSectionsEscrituraActivas(action.payload.servicio);
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.MODIFICAR_USUARIO:
        response.responseService = await serviceModificarUsuario(action.payload.objeto);
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ALERTAS_SIN_RESOLVER_POR_MODULO:
        response.responseService = await obtenerAlertasSinResolverPorModulo(
          action.payload.servicio,
          signal
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_PERFILES_USUARIO:
        {
          response.responseService = await obtenerPerfilesUsuario(action.payload.servicio);
          response.typeResponse = typeResponse.naturalResponse;
        }
        break;
      case CONTROLADOR_ACTIONS.OBTENER_PERMISOS_USUARIO:
        {
          response.responseService = await obtenerPermisosUsuario(
            action.payload.servicio,
            action.payload.id
          );
          response.typeResponse = typeResponse.naturalResponse;
        }
        break;
      case CONTROLADOR_ACTIONS.MODIFICAR_PERMISOS_USUARIO:
        {
          response.responseService = await modificarPermisosUsuario(
            action.payload.servicio,
            action.payload.usuario
          );
          response.typeResponse = typeResponse.information;
        }
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_USUARIO:
        {
          response.responseService = await obtenerDatosUsuario(
            action.payload.servicio,
            action.payload.id
          );
          response.typeResponse = typeResponse.naturalResponse;
        }
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DISPOSITIVO_ACTUACION_BY_TIPO_DISPOSITIVO:
        response.responseService = await obtenerDispositivoActuacionPorTipoDispositivo(
          action.payload.servicio,
          action.payload.idTipoDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_TIPOS_ACTUACIONES_POR_DISPOSITIVO:
        response.responseService = await obtenerTiposActuacionesByDispositivo(
          action.payload.servicio,
          action.payload.idDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.RESOLVER_ALERTA:
        response.responseService = await resolverAlerta(
          action.payload.servicio,
          action.payload.idAlerta,
          action.payload.mensajeResolucion,
          signal
        );
        response.typeResponse = typeResponse.information;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_ETIQUETAS_BY_DISPOSITIVOS:
        response.responseService = await obtenerEtiquetasByDispositivo(
          action.payload.servicio,
          action.payload.dispositivos
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_LISTADISPOSITIVOS_BY_TIPOSDISPOSITIVOS:
        response.responseService = await obtenerListadispositivosByipoDispositivos(
          action.payload.servicio,
          action.payload.tiposDispositivos,
          action.payload.idModulo,
          action.payload.conEtiquetas
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_CANALES_POR_GRUPO:
        response.responseService = await obtenerNombresCanalesByGrupo(
          action.payload.servicio,
          action.payload.idGrupo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_DATOS_POST:
        response.responseService = await obtenerDatosPost(
          action.payload.service,
          action.payload.object
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      case CONTROLADOR_ACTIONS.OBTENER_TODOS_LOS_DEVICES_DISPONIBLES:
        response.responseService = await getAllDeviceFree(
          action.payload.servicio,
          action.payload.idDispositivo
        );
        response.typeResponse = typeResponse.naturalResponse;
        break;
      default:
        break;
    }
    return response;
  };

  /**
   * @description Función que comprueba si el token está caducado para continuar ejecutando la petición al servidor
   * @param { reducerActions } action action al que va a llamar para ejecutar una petición
   * @param @param { boolean } backdrop boolean que indica si hay que mostrar el preloader
   * @returns { Promise<any> } Promesa con el resultado de la petición
   */
  async function controllerRequest(
    action: reducerActions,
    backdrop?: { openSmartTeliaBackdrop?: boolean; closeSmartTeliaBackdrop?: boolean },
    cancelSignal: AbortSignal = signal
  ) {
    if (!tokenCaducado()) {
      if (backdrop?.openSmartTeliaBackdrop) setOpenSmartTeliaBackdrop(true);

      return await procesarPeticion(
        action,
        backdrop?.closeSmartTeliaBackdrop as boolean,
        cancelSignal
      );
    } else {
      logout();
    }
  }

  /**
   * @description Función que procesa la petición al servidor
   * @param { reducerActions } action action al que va a llamar para ejecutar una petición
   * @param  { boolean } closeSmartTeliaBackdrop  boolean que indica si hay que cerrar el preloader
   * @returns  { Promise<any> } Promesa con el resultado de la petición
   */
  async function procesarPeticion(
    action: reducerActions,
    closeSmartTeliaBackdrop: boolean,
    signal: AbortSignal
  ) {
    const data: respuestaControlador = await executeRequest(action, signal);

    const objRespuesta: any = {
      [typeResponse.naturalResponse]: () => data.responseService,
      [typeResponse.contexto]: () =>
        controladorDatosContexto(data.contexto as string, data.responseService),
      [typeResponse.contexto_by_id]: () =>
        controladorDatosContextoPorId(
          data.contexto as string,
          data.responseService,
          action.payload
        ),
      [typeResponse.error]: () => []
    };

    if (closeSmartTeliaBackdrop) setOpenSmartTeliaBackdrop(false);

    if (data.typeResponse === typeResponse.information) {
      tratarRespuesta(data.responseService as unknown as respuesta);
      if (!data.responseService.isAxiosError || data.responseService.isAxiosError == undefined)
        registroGenerado.current = data.responseService.data.result;
    } else {
      if (data.responseService === null || data.responseService === undefined)
        return objRespuesta[data.typeResponse as number]();
      if (data.responseService.isAxiosError) {
        handleError(`[${'useControlador'}][${action.type}]`, data.responseService);
        data.typeResponse = typeResponse.error;
      }

      return objRespuesta[data.typeResponse as number]();
    }
  }

  return {
    controllerRequest,
    completadoCorrectamente,
    propiedadesSnackBar,
    registroGenerado
  };
}
