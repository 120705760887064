import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RoomIcon from '@mui/icons-material/Room';
import { buscarRegistro } from './buscarRegistro';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { controladorBotonEditar } from './controladorBotonEditar';
import ControlContext from '../../../context/control/ControlContext';
import { dictionary_generic } from '../../../resources/enums/plainText';
import { Visibility } from '@mui/icons-material';

export function BotonTablaFilaEditar(props: {
  datosTabla: any;
  elemento: string;
  moduloSeleccionado?: number;
}): JSX.Element {
  const { control, setControl } = React.useContext(ControlContext);
  const { tableData, currentTableData, rowIndex } = props.datosTabla;
  return (
    <div>
      <Tooltip title={dictionary_generic.EDITAR}>
        <IconButton
          aria-label={`editar-${props.elemento}`}
          onClick={() => {
            const { objeto } = buscarRegistro(tableData, currentTableData[rowIndex].index);

            controladorBotonEditar(objeto, setControl, props.elemento as string, control);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export function BotonTablaFilaEliminar(props: {
  datosTabla: any;
  eliminarElemento: (param: any) => void;
  disabled?: boolean;
  callBack?: () => void;
}): JSX.Element {
  const { tableData, currentTableData, rowIndex } = props.datosTabla;
  const { disabled = false, callBack } = props;

  return (
    <div>
      <Tooltip
        title={
          disabled
            ? 'Elemento preestablecido por la aplicación, no se puede eliminar.'
            : dictionary_generic.ELIMINAR
        }
      >
        <IconButton
          aria-label="eliminar"
          onClick={() => {
            if (disabled) return;
            const { objeto } = buscarRegistro(tableData, currentTableData[rowIndex].index);
            if (objeto !== undefined) {
              props.eliminarElemento([objeto]);
              if (callBack) callBack();
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export function BotonTablaFilaVer(): JSX.Element {
  return (
    <div>
      <Tooltip title={dictionary_generic.VER}>
        <IconButton aria-label="ver">
          <Visibility />
        </IconButton>
      </Tooltip>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function BotonTablaFilaUbicacion(props: {
  datosTabla: any;
  handleOpenUbicacion: (objeto: dispositivo) => void;
}) {
  const { tableData, currentTableData, rowIndex } = props.datosTabla;
  return (
    <div>
      <Tooltip title={dictionary_generic.UBICACION}>
        <IconButton
          aria-label="ubicacion"
          onClick={() => {
            const { objeto } = buscarRegistro(tableData, currentTableData[rowIndex].index);
            if (objeto) props.handleOpenUbicacion(objeto);
          }}
        >
          <RoomIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function BotonTablaFilaGenerico(props: {
  datosTabla: any;
  handleClick: (objeto: any) => void;
  nombreBoton: string;
  icono: JSX.Element;
}) {
  const { tableData, currentTableData, rowIndex } = props.datosTabla;
  return (
    <div>
      <Tooltip title={props.nombreBoton}>
        <IconButton
          aria-label={props.nombreBoton}
          onClick={() => {
            const { objeto } = buscarRegistro(tableData, currentTableData[rowIndex].index);
            if (objeto) props.handleClick(objeto);
          }}
        >
          {props.icono}
        </IconButton>
      </Tooltip>
    </div>
  );
}
