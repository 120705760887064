/**
 *
 * @alias Whatsapp
 * @typedef Whatsapp
 * @property { number } id identificador del Whatsapp
 * @property { string } numeroWhatsapp númerp a enviar el mensaje
 * @property { string } mensaje cuerpo del mensaje
 * @property { boolean } enviado indica si el mensaje ha sido ya enviado o no
 */
export interface Whatsapp {
  id?: number;
  numeroWhatsapp: string;
  mensaje: string;
  enviado?: boolean;
}

export function objectWhatsapp(): Whatsapp {
  return {
    numeroWhatsapp: '',
    mensaje: ''
  };
}
