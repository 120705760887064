export interface operador {
  id?: number;
  operador: string;
}

export function objectOperador(elemento?: operador): operador {
  let operador: operador;
  elemento === undefined
    ? (operador = { operador: '' })
    : (operador = { id: elemento.id, operador: elemento.operador });

  return operador;
}
