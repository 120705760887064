/**
 * @alias TipoDevice
 * @typedef TipoDevice
 * @property { number } id identificador del tipo de device
 * @property { string } tipo nombre del tipo de device
 * @property { number } autoRefresg indica si el device puede
 * actualizar automaticamente los valores de los canales
 * @property { number } plugin id del plugin asociado
 */
export interface tipoDevice {
  id?: number;
  nombre: string;
  tipo?: string;
  descripcion?: string;
  autoRefresh?: number;
  idPlugin?: number;
}

export const objectTipoDevice = (elemento?: tipoDevice): tipoDevice => {
  let tipoDevice: tipoDevice;
  elemento === undefined
    ? (tipoDevice = {
        nombre: '',
        tipo: '',
        descripcion: '',
        autoRefresh: 1,
        idPlugin: 0
      })
    : (tipoDevice = {
        id: elemento.id,
        nombre: elemento.nombre,
        tipo: elemento.tipo,
        descripcion: elemento.descripcion,
        autoRefresh: elemento.autoRefresh,
        idPlugin: elemento.idPlugin
      });

  return tipoDevice;
};
