import * as React from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Grow,
  Divider,
  Button
} from '@mui/material';
import { useStyles } from './styles';
import { IconosTiposMedida } from '../../../common/iconosTiposMedida/IconosTiposMedida';
import { useComparativeCard } from './useConfigurableComparativeCard';
import { MapIcons } from '../../../common/gis/components/iconos/Iconos';
import { dictionary_generic, dictionary_configurator } from '../../../../resources/enums/plainText';
import { useDrag } from 'react-dnd';
import { DonutChart } from '../../../graficasApexCharts/DonutChart';
import { CustomTooltip } from '../../../common/customTooltip/CustomTooltip';
import { useTheme } from '@mui/material/styles';
import { cardType } from '../../../home/homeModulos/resources/enums/enumCardType';
import { useConfiguratorContext } from '../../context/useConfiguratorContext';
import { Rango } from '../../../graficas/resources/enums/enumRango';
import { CardProperties } from '../../types/typesCardConfigurator';
import { DevicesTableDialog } from '../DevicesTableDialog';

export function ConfigurableComparativeCard(props: CardProperties | unknown): JSX.Element {
  const {
    tabState: [{ selectedModule }]
  } = useConfiguratorContext();

  const cardProps =
    Object.keys(props as Record<string, unknown>).length > 0
      ? { ...(props as CardProperties) }
      : null;

  const {
    handleAutoCompleteTiposMedida,
    handleAutoCompleteDispositivos,
    controlStates,
    handleAutoCompleteTiposDispositivo,
    obj,
    optionsAgregateFunctions,
    optionsAutoCompleteFrecuencia,
    handleAutoCompleteAgregateFunctions,
    handleCardTitle,
    handleSemaphoreValues,
    handleRange,
    handleSemaphoreCheckBox
  } = useComparativeCard(selectedModule, cardProps);

  const { tiposDispositivo, tiposMedida, dispositivosDisponibles: dispositivos, dataObject } = obj;
  const { tarjeta } = dataObject;
  const classes = useStyles({ color: obj.cardReady ? '#94c390' : '#e0e0e0' });
  const [openDeviceDialog, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const rangosLegibles: any = {
    [Rango.TODAY]: 'Hoy',
    [Rango.YESTERDAY]: 'Ayer',
    [Rango.THIS_WEEK]: 'Esta semana',
    [Rango.LAST_WEEK]: 'Anterior',
    [Rango.THIS_MONTH]: 'Este mes',
    [Rango.TODAY_IN_THE_PREVIOUS_MONTH]: 'Anterior',
    [Rango.THIS_YEAR]: 'Este año',
    [Rango.LAST_YEAR]: 'Anterior'
  };
  const rangosComparativos: any = {
    [Rango.TODAY]: { actual: Rango.TODAY, anterior: Rango.YESTERDAY },
    [Rango.THIS_WEEK]: { actual: Rango.THIS_WEEK, anterior: Rango.LAST_WEEK },
    [Rango.THIS_MONTH]: { actual: Rango.THIS_MONTH, anterior: Rango.TODAY_IN_THE_PREVIOUS_MONTH },
    [Rango.THIS_YEAR]: { actual: Rango.THIS_YEAR, anterior: Rango.LAST_YEAR }
  };

  const [{ isDragging, canDrag }, drag] = useDrag(
    () => ({
      type: 'tarjeta',
      item: {
        ...dataObject,
        tarjeta: { ...dataObject.tarjeta, idTipoTarjeta: cardType.ComparativeCard }
      },
      canDrag: obj.cardReady,

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        canDrag: monitor.canDrag()
      })
    }),
    [obj]
  );
  const graphData: {
    series: Array<number>;
    labels: Array<string>;
    unidad: Array<string>;
    diferencia: number;
  } = {
    series: [100, 100],
    labels: [dictionary_generic.HOY, dictionary_generic.AYER],
    unidad: [dictionary_generic.UNIT],
    diferencia: 0
  };
  const theme = useTheme();
  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      justifyContent={'space-evenly'}
      marginTop={'15px'}
      flexDirection={'row'}
    >
      <Grid item xs={7} container spacing={1}>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            onChange={handleCardTitle}
            id="titulo"
            label={dictionary_configurator.TITLE}
            placeholder="Máx, 20 caracteres."
            className={classes.elements}
            fullWidth
            inputProps={{ maxLength: '20' }}
            value={dataObject.tarjeta.titulo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="codigoTipoDispositivo"
            options={tiposDispositivo}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <MapIcons icono={option.icon as string} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => {
              return (
                <div>
                  <TextField
                    {...params}
                    label={dictionary_configurator.DEVICES_TYPES}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: obj.tipoDispositivo ? (
                        <MapIcons icono={obj.tipoDispositivo.icon} />
                      ) : undefined
                    }}
                  ></TextField>
                </div>
              );
            }}
            className={classes.elements}
            onChange={handleAutoCompleteTiposDispositivo}
            value={obj.tipoDispositivo}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="tiposMedida"
            options={tiposMedida}
            key={tiposMedida[0]}
            disabled={!tiposMedida.length}
            getOptionLabel={(option) => option.nombre}
            isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
            renderOption={(props, option) => (
              <li {...props}>
                <IconosTiposMedida icono={option.codigo.toString()} />
                <span style={{ paddingLeft: '10px' }}>{option.nombre}</span>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.MEASURE_TYPES}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: obj.medida ? (
                    <IconosTiposMedida icono={obj.medida.codigo} />
                  ) : undefined
                }}
              />
            )}
            className={classes.elements}
            onChange={handleAutoCompleteTiposMedida}
            value={obj.medida}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            sx={{ height: '65%', backgroundColor: 'lightgray', fontSize: '0.4rem' }}
            onClick={() => setOpen(true)}
            disabled={!obj.medida}
            disableElevation
          >
            {`${dictionary_configurator.SELECT_DEVICES} (${dataObject.dispositivos?.length ?? 0})`}
          </Button>
          <DevicesTableDialog
            devices={dispositivos}
            openDialog={openDeviceDialog}
            handleCloseDialog={handleClose}
            onRowSelected={handleAutoCompleteDispositivos}
            selectedItems={dataObject.dispositivos ?? []}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            id="range"
            options={optionsAutoCompleteFrecuencia}
            getOptionLabel={(option) => option.frecuencia}
            renderInput={(params) => (
              <TextField {...params} label={dictionary_configurator.RANGE} variant="outlined" />
            )}
            className={classes.elements}
            onChange={handleRange}
            value={obj.range}
            disabled={controlStates.deshabilitarRango}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="operation"
            options={optionsAgregateFunctions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dictionary_configurator.AGGREGATE_FUNCTION}
                variant="outlined"
              />
            )}
            className={classes.elements}
            getOptionDisabled={(option) =>
              dataObject.dispositivos && dataObject.dispositivos.length > 1
                ? option.name !== 'Media' && option.name !== 'Acumulado'
                : false
            }
            onChange={handleAutoCompleteAgregateFunctions}
            disabled={controlStates.operationDisabled || dispositivos.length === 0}
            value={obj.operation}
          />
        </Grid>
      </Grid>

      <Grid item container xs={5} justifyContent="flex-end" component={'div'} ref={drag}>
        <Grid
          item
          xs={12}
          style={{
            cursor: obj.cardReady ? 'move' : 'not-allowed',
            maxWidth: '95%'
          }}
        >
          <Grow in timeout={650} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>
              <CustomTooltip title={tarjeta.titulo} placement={'bottom'}>
                <Box
                  style={{
                    boxShadow: obj.cardReady
                      ? '0px 0px 1px 2px #94c390'
                      : `0px 0px 1px 2px #e0e0e0`,
                    borderRadius: '15px',
                    width: '100%',
                    height: '166px',
                    display: 'flex',
                    position: 'relative'
                  }}
                  alignItems={'center'}
                  textAlign={'center'}
                >
                  <div style={{ width: '100%' }}>
                    <DonutChart {...graphData} />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Typography className={classes.comparativeText}>
                      <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {obj.range?.frecuencia}
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {dictionary_configurator.VALUE}
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span className={classes.measure}>{graphData.unidad}</span>
                    </Typography>
                  </div>

                  <Divider
                    orientation="vertical"
                    style={{
                      borderRightWidth: 'medium',
                      height: '50%'
                    }}
                  />

                  <div style={{ width: '100%' }}>
                    <Typography className={classes.comparativeText}>
                      <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {obj.range != null
                          ? rangosLegibles[rangosComparativos[obj.range?.option].anterior]
                          : ''}
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {dictionary_configurator.VALUE}
                      </span>
                    </Typography>
                    <Typography className={classes.comparativeValue}>
                      <span className={classes.measure}>{graphData.unidad}</span>
                    </Typography>
                  </div>
                </Box>
              </CustomTooltip>
            </div>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
}
