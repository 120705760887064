import * as React from 'react';
import { rutasServicios } from 'resources/enums/enumRutasServicios';
import { getAllByModule } from 'shared/services/sharedAppServices';
import ControlContext from 'context/control/ControlContext';
import { informesDisponibles } from './utils/availableReports';
import { useReportContext } from './Context/index.hook';
import { selectedReport } from './Context/types';
import { useRequest } from 'shared/hooks/useRequest';
import { reportInfo } from './shared/types';

export function useReports(): {
  reportList: Array<reportInfo>;
  handleAutocompleteReportList: (event, value: reportInfo | null) => void;
  selectedReport: selectedReport;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  openDrawer: boolean;
} {
  const { moduloSeleccionado } = React.useContext(ControlContext);
  const {
    handleDrawerClose,
    handleDrawerOpen,
    openFilter: [openDrawer],
    selectedReport: [selectedReport, setSelectedReport]
  } = useReportContext();

  const availableReports = React.useMemo(() => {
    return informesDisponibles;
  }, []);

  const { data: reportList } = useRequest<Array<reportInfo>>(() =>
    getAllByModule(moduloSeleccionado, rutasServicios.INFORMES)
  );

  function handleAutocompleteReportList(event: any, value: reportInfo | null) {
    if (value) {
      setSelectedReport({
        filter: availableReports[parseInt(value.codigo)].filtro,
        reportContainer: availableReports[parseInt(value.codigo)].informe,
        reportInfo: value,
        ...availableReports[parseInt(value.codigo)]
      });
    } else {
      setSelectedReport({
        filter: <></>,
        reportContainer: <></>,
        reportInfo: undefined,
        headerUnit: undefined
      });
    }
  }

  return {
    reportList,
    handleAutocompleteReportList,
    selectedReport,
    handleDrawerClose,
    handleDrawerOpen,
    openDrawer
  };
}
