import axios from 'axios';
import { getHeaders } from '../../../functions/functions';
import { dictionary_entornos } from '../../../resources/enums/plainText';
import { usuario } from '../../../resources/interfaces/interfaceUsuario';

export async function serviceModificarUsuario(usuario: usuario): Promise<any> {
  let respuesta: any;
  const path = 'usuarios' + '/' + usuario.id;
  try {
    respuesta = await axios.put(
      process.env.REACT_APP_URL_SERVICES + path,
      { ...usuario },
      {
        timeout: 5000,
        headers: { 'x-access-token': localStorage.getItem('tokenGestion') as string }
      }
    );

    return respuesta;
  } catch (exception) {
    return exception;
  }
}
export async function obtenerDatosUsuario(servicio: string, id: number): Promise<any> {
  let respuesta: any;
  const path = `${servicio}/${id}`;
  try {
    respuesta = await axios.get(process.env.REACT_APP_URL_SERVICES + path, {
      timeout: 5000,
      headers: getHeaders(dictionary_entornos.GESTION)
    });

    return respuesta;
  } catch (exception) {
    return exception;
  }
}

export async function obtenerPerfilesUsuario(servicio: string): Promise<any> {
  let respuesta: any;
  try {
    respuesta = await axios.get(process.env.REACT_APP_URL_SERVICES + servicio, {
      timeout: 5000,
      headers: getHeaders(dictionary_entornos.GESTION)
    });

    return respuesta;
  } catch (exception) {
    return exception;
  }
}

export async function obtenerPermisosUsuario(servicio: string, id: number): Promise<any> {
  let respuesta: any;
  const path = servicio + '/permisos';
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_SERVICES + path,
      { idUsuario: id },
      {
        timeout: 5000,
        headers: getHeaders(dictionary_entornos.GESTION)
      }
    );

    return respuesta;
  } catch (exception) {
    return exception;
  }
}

export async function modificarPermisosUsuario(servicio: string, permisos: any): Promise<any> {
  let respuesta: any;
  const path = servicio + '/establecerPermisos';
  try {
    respuesta = await axios.post(
      process.env.REACT_APP_URL_SERVICES + path,
      { ...permisos },
      {
        timeout: 5000,
        headers: getHeaders(dictionary_entornos.GESTION)
      }
    );

    return respuesta;
  } catch (exception) {
    return exception;
  }
}
