export enum ACTIONS_REDUCER_USUARIOS {
  SET_USUARIO = 'setUsuario',
  SET_GROW = 'setGrow',
  SET_PASSWORD = 'setPassword',
  SET_REPEAT_PASSWORD = 'setRepeatPassword',
  CHECK_NEW_PASSWORD = 'comprobarPassword',
  CHECK_OLD_PASSWORD = 'checkOldPassword',
  SET_OPEN_CLOSE_ASISTENTE = 'setOpenCloseAsistente',
  CHANGE_TEXT_PERFIL_ASISTENTE = 'changeTextPerfil',
  HANDLE_BUTTON_ASISTENTE = 'handleButtonAsistente',
  SET_MODULOS_FILTRADOS_POR_PERFIL = 'setModulosFiltradosPorPerfil',
  SET_PERFILES = 'setPerfiles',
  GET_PERMISOS_USUARIO = 'getPermisosUsuario',
  SET_USUARIOS = 'setUsuarios',
  SET_ESTADO_MODULO = 'setEstadoModulo',
  SET_ACCION = 'setAccion',
  SET_DISPOSITIVOS = 'setDispositivos',
  SET_DISPOSITIVOS_USUARIO = 'setDispositivosUsuario',
  SET_DISPOSITIVOS_SELECCIONADOS = 'setDispositivosSeleccionados',
  SET_MODULO_SELECCIONADO_ESTABLECER_DISPOSITIVOS = 'setModuloSeleccionadoEstablecerDispositivos',
  SET_INITIAL_STATE = 'setInitialState'
}
