import axios, { AxiosRequestConfig } from 'axios';
import { Group } from 'components/graficas/resources/enums/enumGroup';
import { Rango } from 'components/graficas/resources/enums/enumRango';
import { encriptarJSON } from 'functions/encryption';
import { getHeaders } from 'functions/functions';
import { TiposMedida } from 'resources/enums/enumTiposMedida';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get the water quality report data.
 * @param {Array<number>} devicesIds
 * @param {number} tagId
 * @returns {Key,AxiosRequestConfig} key used by swr and axios request config.
 */
export function getWaterQualityReportData() {
  const key: Key = `${swrServiceRoutes.getWaterQualityReportData}`;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getWaterQualityReportData,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  return { key, request };
}
