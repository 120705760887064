import {
  dispositivoActuacionByTipoDispositivo,
  dispositivoByTipoDispositivo,
  dispositivosConAlertas,
  dispositivosPorTipoDispositivos
} from '../services/serviciosDispositivo';
import { getAllDeviceFree as getAllDeviceFreeByIdDispositivo } from '../../device/services/servicioDevice';

export function useDispositivos(): {
  obtenerDispositivoPorTipoDispositivo: (
    servicio: string,
    idTipoDispositivo: number
  ) => Promise<any>;
  obtenerDispositivoActuacionPorTipoDispositivo: (
    servicio: string,
    idTipoDispositivo: number
  ) => Promise<any>;
  obtenerDispositivosConAlertas: (
    servicio: string,
    idModulo: number,
    signal: AbortSignal
  ) => Promise<any>;
  obtenerListadispositivosByipoDispositivos: (
    servicio: string,
    tiposDispositivos: number[],
    idModulo: number,
    conEtiquetas: boolean
  ) => Promise<any>;
  getAllDeviceFree: (servicio: string, idDispositivo: number) => Promise<any>;
} {
  /**
   * @description Función que obtiene los dispositivos por su tipo
   * @param { string } servicio servicio al que queremos llevamar
   * @param { number } idTipoDispositivo identificador del tipo de servicio
   * @return { React.MutableRefObject  | [] } Promesa que contiene un listado de dispostivos filtrado por tipo de dspositivo
   */
  async function obtenerDispositivoPorTipoDispositivo(servicio: string, idTipoDispositivo: number) {
    const respuesta: any = await dispositivoByTipoDispositivo(servicio, idTipoDispositivo);

    if (!respuesta.isAxiosError) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  /**
   * @description Función que obtiene los dispositivos que sean de actuación por su tipo
   * @param { string } servicio servicio al que queremos llevamar
   * @param { number } idTipoDispositivo identificador del tipo de servicio
   * @return { React.MutableRefObject  | [] } Promesa que contiene un listado de dispostivos filtrado por tipo de dspositivo
   */
  async function obtenerDispositivoActuacionPorTipoDispositivo(
    servicio: string,
    idTipoDispositivo: number
  ) {
    const respuesta: any = await dispositivoActuacionByTipoDispositivo(servicio, idTipoDispositivo);

    if (!respuesta.isAxiosError) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  /**
   * @description Función que obtiene dispositivos que tienen alertas filtradas por modulo
   * @param { string } servicio servicio al que vamos a llamar
   * @param { number } idModulo idModulo por el que queremos filtrar
   * @return { React.MutableRefObject  | [] } Promesa que contiene un listado de alertas de dispositivos o un array vacío
   */
  async function obtenerDispositivosConAlertas(
    servicio: string,
    idModulo: number,
    signal: AbortSignal
  ) {
    const respuesta: any = await dispositivosConAlertas(servicio, idModulo, signal);
    if (!respuesta.isAxiosError && respuesta.data !== undefined) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  async function obtenerListadispositivosByipoDispositivos(
    servicio: string,
    tiposDispositivos: number[],
    idModulo: number,
    conEtiquetas = false
  ): Promise<any> {
    const respuesta: any = await dispositivosPorTipoDispositivos(
      servicio,
      tiposDispositivos,
      idModulo,
      conEtiquetas
    );
    if (!respuesta.isAxiosError && respuesta.data !== undefined) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }
  async function getAllDeviceFree(servicio: string, idDispositivo: number): Promise<any> {
    const respuesta: any = await getAllDeviceFreeByIdDispositivo(servicio, idDispositivo);
    if (!respuesta.isAxiosError && respuesta.data !== undefined) {
      return respuesta.data.result;
    } else {
      return [];
    }
  }

  return {
    obtenerDispositivoPorTipoDispositivo,
    obtenerDispositivosConAlertas,
    obtenerDispositivoActuacionPorTipoDispositivo,
    obtenerListadispositivosByipoDispositivos,
    getAllDeviceFree
  };
}
