import React from 'react';
import { Box } from '@mui/material/';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { enumLabel } from '../../../resources/enums/plainText';
import { useEventChange } from '../../../hooks/useEventChange/useEventChange';
import { operador } from '../../reglas/resources/interfaces/interfaceOperador';
import { grupo } from '../../grupos/resources/interfaces/interfaceGrupo';

export const CondicionGrupal = (props: {
  stateCondiciones: any;
  events: any;
  state: any;
}): JSX.Element => {
  const { stateCondiciones, events } = props;

  const { condicionCompleta, dialogoCondiciones } = stateCondiciones;
  const { setCondicionCompleta, convertirOperadorAFrase } = events;
  const { handleInputChange, handleInputInvalid, handleAutocompleteObjetoChange } = useEventChange(
    condicionCompleta,
    setCondicionCompleta
  );

  return (
    <>
      <Box p={1} flexGrow={1}>
        <Autocomplete
          value={condicionCompleta.grupo}
          disableClearable
          id="grupo"
          options={dialogoCondiciones.grupos as grupo[]}
          size="small"
          getOptionLabel={(option) => option.codigo}
          onChange={handleAutocompleteObjetoChange}
          renderInput={(params) => (
            <TextField {...params} label={enumLabel.GRUPOS_DISPOSITIVO} variant="outlined" />
          )}
        />
      </Box>

      <Box display="flex" flexDirection="row">
        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.nombreCanal}
            disableClearable
            id="nombreCanal"
            options={dialogoCondiciones.canalesGrupo as string[]}
            size="small"
            getOptionLabel={(option) => option}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.CANAL} variant="outlined" />
            )}
          />
        </Box>

        <Box p={1} flexGrow={1}>
          <Autocomplete
            value={condicionCompleta.operador}
            disableClearable
            id="operador"
            options={dialogoCondiciones.operadores as operador[]}
            size="small"
            getOptionLabel={(option) => convertirOperadorAFrase(option.operador)}
            onChange={handleAutocompleteObjetoChange}
            renderInput={(params) => (
              <TextField {...params} label={enumLabel.OPERADOR} variant="outlined" />
            )}
          />
        </Box>

        <Box p={1} flexGrow={1}>
          {
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              label={enumLabel.VALOR}
              id="valor"
              type="text"
              required
              onChange={handleInputChange}
              onKeyDown={handleInputInvalid}
              onPaste={handleInputInvalid}
              value={condicionCompleta.valor}
              autoComplete="off"
            />
          }
        </Box>
      </Box>
    </>
  );
};
