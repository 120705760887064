import React from 'react';
import { Fade, Grid } from '@mui/material';
import useStyles from './style';
import { Login } from '../../components/login/Login';
import { RestartPassword } from '../../components/login/RestartPassword';
import logotipo from '../../resources/images/smartTownLogo.jpg';

export function PageLogin(props: { isRestartPassword?: boolean }): JSX.Element {
  const classes = useStyles();
  const { isRestartPassword } = props;

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        flexWrap="nowrap"
        className={classes.gridLogin}
      >
        <Grid item xs={4}></Grid>
        <Fade in timeout={1700}>
          <div>{isRestartPassword ? <RestartPassword /> : <Login />}</div>
        </Fade>
        <Grid item xs={4} className={classes.gridLogo}>
          {logotipo ? (
            <center>
              <div className={classes.divLogo}>
                <img src={logotipo} className={classes.imgLogo} />
              </div>
            </center>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
