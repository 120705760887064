import * as React from 'react';
import { action } from '../../../hooks/useControlador/resources/enums/enumActions';
import { rutasServicios, serviceRoutes } from '../../../resources/enums/enumRutasServicios';
import { sizeCardType } from '../resources/commonResources';
import { ConfiguratorProperties } from '../types/typesCardConfigurator';
import { useControlador } from '../../../hooks/useControlador/useControlador';
import UserContext from '../../../context/UserContext';

const ConfiguratorContext = React.createContext<ConfiguratorProperties>({
  tabState: [{ selectedModule: 0, value: 0 }, () => ''],
  moduleStructureState: [[], () => []],
  selectedStructure: [],
  selectedUserId: 0,
  sizeCardType: {},
  copySelectedStructure: { current: [] },
  userDevicesConfiguration: [[], () => []],
  openBackdrop: true
});

export function ConfiguratorProvider(props: any): JSX.Element {
  const { modulos, idUsuario } = props;
  const [tab, setTab] = React.useState({ selectedModule: modulos[0].id, value: 0 });
  const [moduleStructure, setModuleStructure] = React.useState(() =>
    modulos.map(() => {
      return new Array(12).fill(<div data-visible={true} />);
    })
  );
  const [userDevices, setUserDevice] = React.useState([]);

  const selectedStructure = moduleStructure[tab.value];
  const copySelectedStructure = React.useRef<Array<JSX.Element>>([...moduleStructure[tab.value]]);
  const { controllerRequest } = useControlador();
  const { datosUsuarioContext } = React.useContext(UserContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);
  React.useEffect(() => {
    setOpenBackdrop(true);
    let dispositivosFiltradosUser: any;
    let allDevices: any;
    controllerRequest({
      type: action.OBTENER_PERMISOS_USUARIO,
      payload: {
        servicio: rutasServicios.USUARIOS,
        id: idUsuario as number
      }
    }).then(({ data }) => {
      controllerRequest({
        type: action.OBTENER_DATOS_POST,
        payload: {
          service: serviceRoutes.OBTENER_TODOS_DISPOSITIVOS_POR_MODULO,
          object: { idModulo: tab.selectedModule }
        }
      }).then((result) => {
        const userDevices = data.result.dispositivos.filter(
          (p) => p.idModulo === tab.selectedModule
        );
        const userLoginDevices = datosUsuarioContext.dispositivos.filter(
          (s) => s.idModulo === tab.selectedModule
        );
        allDevices = result;
        if (userLoginDevices.length > 0) {
          if (userDevices.length > 0) {
            //usuario logeado tiene dispositivos y el usuario a ediatar tambíen
            dispositivosFiltradosUser = userDevices.filter((p) =>
              userLoginDevices.some((v) => v.idDispositivo === p.idDispositivo)
            );
          } else {
            //usuario logeado tiene dispositivos y el usuario a ediatar no
            dispositivosFiltradosUser = userLoginDevices;
          }
        } else {
          if (userDevices.length > 0) {
            //Usuario logeado no tiene dispositivos y el usuario a editar si
            dispositivosFiltradosUser = userDevices;
          } else {
            //ninguno tiene dispositivos
            dispositivosFiltradosUser = result;
          }
        }
        const devices = allDevices.filter((p) =>
          dispositivosFiltradosUser.some((v) => (v.id | v.idDispositivo) === p.id)
        );

        setUserDevice(devices);
        setOpenBackdrop(false);
      });
    });
  }, [tab.selectedModule]);

  const value = {
    tabState: [tab, setTab],
    moduleStructureState: [moduleStructure, setModuleStructure],
    selectedStructure: selectedStructure,
    selectedUserId: idUsuario,
    sizeCardType,
    copySelectedStructure: copySelectedStructure,
    userDevicesConfiguration: [userDevices, setUserDevice],
    openBackdrop: openBackdrop
  };

  return <ConfiguratorContext.Provider value={value} {...props} />;
}

export default ConfiguratorContext;
