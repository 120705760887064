import React from 'react';

import { ReactComponent as iconConfiguracion } from '../../../resources/icons/iconosModulosNuevos/configuracion.svg';
import { ReactComponent as iconGestionUsuarios } from '../../../resources/icons/iconosModulosNuevos/gestion_usuario.svg';
import { ReactComponent as iconEnergia } from '../../../resources/icons/iconosModulosNuevos/energia.svg';
import { ReactComponent as iconVideovigilancia } from '../../../resources/icons/iconosModulosNuevos/videovigilancia.svg';
import { ReactComponent as iconControlAccesos } from '../../../resources/icons/iconosModulosNuevos/control_acceso.svg';
import { ReactComponent as iconBigData } from '../../../resources/icons/iconosModulosNuevos/bigdata.svg';
import { ReactComponent as iconReglas } from '../../../resources/icons/iconosModulosNuevos/reglas.svg';
import { ReactComponent as iconAguaPotable } from '../../../resources/icons/iconosModulosNuevos/agua_potable.svg';
import { ReactComponent as iconIluminacion } from '../../../resources/icons/iconosModulosNuevos/iluminacion_viaria.svg';
import { ReactComponent as iconParquesJardines } from '../../../resources/icons/iconosModulosNuevos/parque.svg';
import { ReactComponent as iconGestionResiduos } from '../../../resources/icons/iconosModulosNuevos/gestion_de_residuos.svg';
import { ReactComponent as iconMedioAmbiente } from '../../../resources/icons/iconosModulosNuevos/medio_ambiente.svg';
import { ReactComponent as iconAdminCiudadana } from '../../../resources/icons/iconosModulosNuevos/administracion_ciudadana.svg';
import { ReactComponent as iconMovilidadUrbana } from '../../../resources/icons/iconosModulosNuevos/movilidad.svg';
import { ReactComponent as iconTelecomunicaciones } from '../../../resources/icons/iconosModulosNuevos/telecomunicaciones.svg';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function IconosModulos(props: {
  idmodulo: number;
  moduloseleccionado: number;
  style?: {
    fontSize?: number;
    marginRight?: number;
    width?: number;
    height?: number;
  };
}): JSX.Element {
  const theme = useTheme();
  const iconoModulo: any = {
    1: { icon: iconGestionUsuarios, viewBox: '0 0 600 476.6' },
    2: { icon: iconIluminacion, viewBox: '0 0 250 250' },
    3: { icon: iconAguaPotable, viewBox: '50 0 420 420' },
    4: { icon: iconParquesJardines, viewBox: '0 0 600 600' },
    5: { icon: iconGestionResiduos, viewBox: '0 0 350 350' },
    6: { icon: iconEnergia, viewBox: '0 0 430 430' },
    7: { icon: iconMedioAmbiente, viewBox: '0 0 600 476.6' },
    8: { icon: iconAdminCiudadana, viewBox: '0 0 460 460' },
    9: { icon: iconMovilidadUrbana, viewBox: '0 0 500 476.6' },
    10: { icon: iconVideovigilancia, viewBox: '0 0 550 550' },
    11: { icon: iconControlAccesos, viewBox: '0 0 550 550' },
    12: { icon: iconTelecomunicaciones, viewBox: '0 0 500 476.6' },
    13: { icon: iconBigData, viewBox: '0 0 25 25' },
    14: { icon: iconReglas, viewBox: '0 0 120 120' },
    99: { icon: iconConfiguracion, viewBox: '0 0 120 120' }
  };

  return (
    <>
      <SvgIcon
        component={iconoModulo[props.idmodulo].icon}
        style={{ fontSize: '26px' }}
        viewBox={iconoModulo[props.idmodulo].viewBox}
        {...props}
        inheritViewBox
        htmlColor={
          props.idmodulo === props.moduloseleccionado
            ? theme.palette.secondary.main
            : theme.palette.primary.main
        }
      />
    </>
  );
}
