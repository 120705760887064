import React from 'react';
import { Box, Grid, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconosModulos } from '../../../common/iconosModulos/IconosModulos';
import { modulo } from '../../../../resources/interfaces/interfaceModulo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useConfigurator } from './useConfigurator';
import { ConfiguratorStructure } from '../configuratorStructure/ConfiguratorStructure';
import { enumComponentes } from '../../../../resources/enums/enumComponente';
import { UsuariosManager } from '../../../usuarios/UsuariosManager';
import { ArrowBack } from '@mui/icons-material';
import ControlContext from '../../../../context/control/ControlContext';
import { IconButton } from '@mui/material';
import { BackdropTarjetasModulos } from '../../../home/homeModulos/common/BackdropTarjetasModulos';

export const useStyles = makeStyles(() => ({
  textModulos: {
    '& span': {
      fontSize: '17px',
      fontFamily: 'Open Sans',
      fontVariant: 'all-small-caps',
      marginRight: '10px'
    }
  },
  root: {
    width: '100%',
    padding: '0 20px 10px 20px'
  }
}));

export const Configurator = (props: any): JSX.Element => {
  const classes = useStyles();
  const { modulos, user } = props;
  const { tab, handleTabChange, openSpinner } = useConfigurator();
  const { setControl, handleClickOpenConfiguratorAlert } = React.useContext(ControlContext);
  const handleClickVolver = () => {
    handleClickOpenConfiguratorAlert(() =>
      setControl(
        <UsuariosManager
          key={enumComponentes.MODIFICAR_USUARIOS}
          usuarioSeleccionado={user}
          editarPerfil={false}
        />
      )
    );
  };

  return (
    <div className={classes.root}>
      <Grid item alignItems={'flex-start'}>
        <Box display="flex" marginBottom="30px">
          <IconButton color="secondary" onClick={handleClickVolver}>
            <ArrowBack />
          </IconButton>
          <Typography variant={'h5'} sx={{ p: '10px' }}>
            Configurador de tarjetas {`-> Usuario: ${user.nombre} ${user.apellidos}`}
          </Typography>
        </Box>
        <Grid item container xs={11}>
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: {
                xs: 320,
                sm: 480,
                md: 820,
                lg: 1020,
                xl: useMediaQuery('(min-width:1600px)') ? 1560 : 1180
              },
              bgcolor: 'background.paper'
            }}
          >
            <Tabs
              value={tab.value}
              onChange={handleTabChange}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 }
                }
              }}
            >
              {modulos.map((modulo: modulo) => (
                <Tab
                  key={modulo.id}
                  id={modulo.id.toString()}
                  iconPosition="start"
                  label={modulo.nombre}
                  icon={
                    <IconosModulos idmodulo={modulo.id} moduloseleccionado={tab.selectedModule} />
                  }
                ></Tab>
              ))}
            </Tabs>
          </Box>
          <ConfiguratorStructure openSpinner={openSpinner} />
        </Grid>
      </Grid>
    </div>
  );
};
