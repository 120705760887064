import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Collapse,
  Box,
  Tab,
  Tabs
} from '@mui/material';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import useStyles from './styles';
import { State } from './hooks/useReducerDevice/resources/types/typeState';
import { Events } from './hooks/useReducerDevice/resources/types/typeEvents';
import { dictionary_devices } from '../../resources/enums/plainText';
import { tipoDeviceConfiguracion } from './resources/interfaces/interfaceTipoDeviceConfiguracion';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TabPanel } from './WeeklyCalendar/TabPanel';
import { CollapseTable } from './WeeklyCalendar/CollapseTable';
import { WeeklyCalendarParam } from './WeeklyCalendar';

/**
 * Componente TextFieldParam
 */
function TextFieldParam({
  param,
  state,
  events
}: {
  param: tipoDeviceConfiguracion;
  state: State;
  events: Events;
}): JSX.Element {
  return (
    <Grid item md={6}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth={true}
        label={param.etiqueta}
        id={param.nombre}
        type={param.tipoPassword === 1 ? 'password' : param.tipo}
        required={param.requerido === 1 ? true : false}
        disabled={state.disabled}
        onChange={(event) =>
          events.handleParametroChange(param.id as number, event.target.value.trim())
        }
        onKeyDown={events.handleInputInvalid}
        defaultValue={
          state.deviceModificar !== undefined
            ? events.getValor(param.id as number)
            : param.valorDefecto
        }
        autoComplete={param.tipoPassword === 1 ? 'new-password' : ''}
        helperText={param.descripcion}
      />
    </Grid>
  );
}

/**
 * Componente CheckboxParam
 */
function CheckboxParam({
  param,
  state,
  events
}: {
  param: tipoDeviceConfiguracion;
  state: State;
  events: Events;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Grid item md={6}>
      <FormControl variant="outlined" size="small">
        <FormControlLabel
          label={param.etiqueta}
          labelPlacement="start"
          className={classes.justifyFlexEnd}
          control={
            <Checkbox
              color="secondary"
              id={param.nombre}
              required={param.requerido === 1 ? true : false}
              disabled={state.disabled}
              inputProps={{ 'aria-label': 'checkbox-' + param.nombre }}
              defaultChecked={
                state.deviceModificar !== undefined
                  ? events.getValor(param.id as number) === '1'
                    ? true
                    : false
                  : param.valorDefecto === '1'
                  ? true
                  : false
              }
              onChange={(event) => {
                events.handleParametroChange(
                  param.id as number,
                  event.target.checked === true ? '1' : '0'
                );
              }}
            />
          }
        />
        <FormHelperText id={'descripcion-' + param.nombre}>{param.descripcion}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

/**
 * Componente AutocompleteParam
 */
function AutocompleteParam({
  param,
  state,
  events
}: {
  param: tipoDeviceConfiguracion;
  state: State;
  events: Events;
}): JSX.Element {
  const autocompleteParam = param.tipo?.split('#')[1] as string;
  const botones = autocompleteParam.split(',');
  const opciones: { etiqueta: string; valor: string }[] = [];
  botones.forEach((boton) => {
    opciones.push({ etiqueta: boton.split(':')[0], valor: boton.split(':')[1] });
  });
  let valorPorDefecto;
  if (state.deviceModificar !== undefined) {
    valorPorDefecto = opciones.find(
      (opcion) => opcion.valor === events.getValor(param.id as number)
    );
  } else if (param.valorDefecto !== '') {
    valorPorDefecto = opciones.find((opcion) => opcion.valor === param.valorDefecto);
  }

  return (
    <Grid item md={6}>
      <Autocomplete
        size={'small'}
        id={param.nombre}
        disabled={state.disabled}
        options={opciones}
        defaultValue={valorPorDefecto}
        getOptionLabel={(option) => option.etiqueta}
        onChange={(_event, newValue) => {
          if (newValue !== null)
            events.handleParametroChange(param.id as number, `${newValue.valor}`);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={param.etiqueta}
            required={param.requerido === 1 ? true : false}
            helperText={param.descripcion}
          />
        )}
      />
    </Grid>
  );
}
/*
 * Función que convierte una cadena de valores hexadecimales en una cadena
 * de su equivalente en binario
 */
function hex2bin(hex: string): string {
  return ('00000000' + parseInt(hex, 16).toString(2)).substr(-8);
}

/**
 * Componente DailyCalendar
 */
function DailyCalendarParam({
  param,
  state,
  events
}: {
  param: tipoDeviceConfiguracion;
  state: State;
  events: Events;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>('');

  interface calendarRow {
    index: number;
    hour: string;
    state: boolean;
  }

  const date = moment('2023-05-01 00:00');

  const initialCalendar = React.useMemo(() => {
    let rows: calendarRow[] = [];

    //Genero el dataset
    for (let i = 0; i < 96; i++) {
      rows.push({
        index: i,
        hour: `${date.format('HH:mm')} - ${date.add(15, 'm').format('HH:mm')}`,
        state: false
      });
    }

    if (state.deviceModificar?.configuracion) {
      let defaultCalendar: string = state.deviceModificar?.configuracion.find(
        (c) => c.configuracion === 'dailyCalendar'
      )!.valor;
      //El calendario viene en formato hexadecimal separado por bytes, por lo que hay que separar cada
      //byte y pasarlo a binario, indicando '1' -> Abierto, '0' -> Cerrado
      // Ejemplo: "05,2f,43,50,00,00,a0..." -> "00000101..."
      defaultCalendar = defaultCalendar
        .split(',')
        .map((h) => hex2bin(h))
        .toString()
        .replaceAll(',', '');
      rows = rows.map((row) => {
        return { ...row, state: Boolean(Number(defaultCalendar[row.index])) };
      });
    }

    return rows;
  }, []);

  const [calendar, setCalendar] = useState<any[]>(initialCalendar);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const index: number = Number.parseInt(event.target.id.split('-')[1]);
    const newCalendar = [...calendar];
    newCalendar[index].state = checked;
    const arranging = newCalendar.map((e: any) => Number(e.state));
    const hexArray: string[] = [];
    for (let i = 0; i < 96; i += 8) {
      const byte = arranging
        .slice(i, i + 8)
        .toString()
        .replaceAll(',', '');
      hexArray.push(('00' + parseInt(byte, 2).toString(16)).slice(-2));
    }
    events.handleParametroChange(param.id as number, `${hexArray.toString()}`);
    setCalendar(newCalendar);
  };
  return (
    <Grid item md={6}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>00:00 - 08:00</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {calendar.map(
                  (row) =>
                    row.index >= 0 &&
                    row.index <= 31 && (
                      <TableRow
                        key={row.index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.hour}
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            id={`checkbox-${row.index.toString()}`}
                            checked={row.state}
                            onChange={handleCheckboxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>08:00 - 16:00</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {calendar.map(
                  (row) =>
                    row.index >= 32 &&
                    row.index <= 63 && (
                      <TableRow
                        key={row.index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.hour}
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            id={`checkbox-${row.index.toString()}`}
                            checked={row.state}
                            onChange={handleCheckboxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>16:00 - 00:00</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {calendar.map(
                  (row) =>
                    row.index >= 64 &&
                    row.index <= 96 && (
                      <TableRow
                        key={row.index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.hour}
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            id={`checkbox-${row.index.toString()}`}
                            checked={row.state}
                            onChange={handleCheckboxChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

/**
 * Componente ParametrosConfiguracion
 */
export function ParametrosConfiguracion({
  state,
  events
}: {
  state: State;
  events: Events;
}): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.gridTitulo}>
        <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
          {dictionary_devices.PARAMETROS}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <>
          {state.parametrosConfiguracion.map((parametro) =>
            parametro.tipo === 'checkbox' ? (
              <CheckboxParam
                param={parametro}
                state={state}
                events={events}
                key={parametro.nombre}
              />
            ) : parametro.tipo.includes('autocomplete') ? (
              <AutocompleteParam
                param={parametro}
                state={state}
                events={events}
                key={parametro.nombre}
              />
            ) : parametro.tipo === 'dailyCalendar' ? (
              <DailyCalendarParam
                param={parametro}
                state={state}
                events={events}
                key={parametro.nombre}
              />
            ) : parametro.tipo === 'weeklyCalendar' ? (
              <WeeklyCalendarParam param={parametro} events={events} state={state} />
            ) : (
              <TextFieldParam param={parametro} state={state} events={events} />
            )
          )}
        </>
      </Grid>
    </>
  );
}
