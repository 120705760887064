/**
 * @alias Grupo
 * @typedef Grupo
 * @property {number} id id del grupo
 * @property {string} codigo código del grupo.
 * @property {Date} fechaCreacion fecha de creación del grupo.
 */

export interface grupo {
  id?: number;
  codigo: string;
  fechaCreacion?: Date | string;
}

export const objectGrupo = (elemento?: grupo): grupo => {
  let grupo: grupo;
  elemento === undefined
    ? (grupo = {
        codigo: ''
      })
    : (grupo = {
        id: elemento.id,
        codigo: elemento.codigo
      });

  return grupo;
};
