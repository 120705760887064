import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  gridImagen: {
    height: '100vh'
  },
  imagen: {
    height: '100vh'
  },
  gridContenido: {
    height: '100vh'
  },
  gridTextoPrincipal: {
    alignSelf: 'center'
  },
  textoPrincipal: {
    color: '#969696',
    fontWeight: 500,
    textShadow: '1px 1px #000000'
  },
  gridTextoSecundario: {
    width: '80%',
    alignSelf: 'center'
  },
  textoSecundario: {
    marginLeft: '10vh',
    color: '#AAAAAA'
  },
  gridBotones: {
    alignSelf: 'center'
  },
  boton: {
    width: '300px',
    height: '50px',
    border: `2px solid ${theme.palette.secondary.main}`,
    '&>.MuiButton-label': { fontSize: '1rem' }
  },
  gridMensajeInicio: {
    paddingRight: '4vh'
  },
  mensajeInicio: {
    marginRight: '0.2vw'
  }
}));
export default useStyles;
