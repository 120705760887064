import React from 'react';
import { usuario } from '../../resources/interfaces/interfaceUsuario';
import { useReducerUsuarios } from './hooks/useReducerUsuarios';

export function UsuariosManager({
  usuarioSeleccionado,
  editarPerfil = false,
  abrirDialogoPerfil
}: {
  usuarioSeleccionado?: usuario;
  editarPerfil?: boolean;
  abrirDialogoPerfil?: () => void;
}): JSX.Element {
  const { obtenerComponente, state } = useReducerUsuarios(
    editarPerfil,
    usuarioSeleccionado,
    abrirDialogoPerfil
  );
  return state.componenteVisible ? obtenerComponente() : <></>;
}
