import { tipoAccion, objectTipoAccion } from './interfaceTipoAccion';
import { actuacionCompleta, objectActuacionCompleta } from './interfaceActuacionCompleta';
import { cambioEstadoReglaCompleta } from './interfaceCambioEstadoReglaCompleta';

export interface accionCompleta {
  id?: number;
  actuacionDispositivo?: actuacionCompleta;
  cambioEstadoRegla?: cambioEstadoReglaCompleta;
  tipoAccion: tipoAccion;
}

export const objectAccionCompleta = (): accionCompleta => {
  const accion: accionCompleta = {
    tipoAccion: objectTipoAccion()
  };
  return accion;
};
