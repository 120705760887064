import React from 'react';
import { useTheme } from '@mui/material/styles';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import SettingsIcon from '@mui/icons-material/Settings';
import { statusPlugin } from '../resources/enums/enumEstado';
import { BotonTablaFilaGenerico } from '../../common/table/BotonesTablaFila';
import { ContenedorElementos } from '../../common/table/contenedorElementos';
import { Color } from '../../../resources/enums/enumColores';

// 0 (instalado), 1 (error), 2 (pausado), 3 (ejecutando) y 4 (listo)
const COLOR_ESTADO = () => ({
  0: Color.GRIS,
  1: Color.ROJO,
  2: Color.ROSA,
  3: Color.VERDE,
  4: Color.AZUL
});

/**
 * Función para obtener el nombre de estado según el estado
 * @param {statusPlugin} estado estado del plugin
 * @return {string} nombre del estado
 */
const getNombreEstado = (estado: statusPlugin): string => {
  switch (estado) {
    case statusPlugin.INSTALLED:
      return 'instalado';
    case statusPlugin.ERROR:
      return 'error';
    case statusPlugin.PAUSE:
      return 'pausado';
    case statusPlugin.RUNNING:
      return 'ejecutando';
    case statusPlugin.READY:
      return 'listo';
  }
};
const ANCHO = '100px';
const CLASE = 'contenedor';

/**
 * Función para construir el contenedor la palabra y color según el estado del plugin
 */
export function EstadoPlugin(props: { estado: statusPlugin }): JSX.Element {
  return (
    <ContenedorElementos
      elemento={getNombreEstado(props.estado)}
      colorElemento={COLOR_ESTADO()[props.estado]}
      colorFondo={COLOR_ESTADO()[props.estado] + '15'}
      ancho={ANCHO}
      clase={CLASE}
    />
  );
}

const ICONO: any = () => ({
  Ejecutar: <PlayIcon />,
  Pausar: <PauseIcon />,
  Configurar: <SettingsIcon />
});

/**
 * Función para construir el botón de la fila para el plugin (pausar, ejecutar y configuración)
 */
export function BotonPlugin(props: {
  tableMeta: any;
  nombreBoton: string;
  handleClick: (objeto: any) => void;
}): JSX.Element {
  const theme = useTheme();
  return (
    <ContenedorElementos
      elemento={
        <BotonTablaFilaGenerico
          datosTabla={props.tableMeta}
          handleClick={props.handleClick}
          nombreBoton={props.nombreBoton}
          icono={ICONO()[props.nombreBoton]}
        />
      }
      colorElemento={theme.palette.secondary.main}
      colorFondo={`${theme.palette.secondary.main}33`}
      clase={'botones'}
    />
  );
}
