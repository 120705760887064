import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, TextField } from '@mui/material';
import { dictionary_informes } from '../../resources/enums/plainText';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useReports } from './index.hook';

const drawerWidth = 370;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: '55px',
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

export function Informes(): JSX.Element {
  const theme = useTheme();
  const {
    reportList = [],
    handleAutocompleteReportList,
    selectedReport,
    handleDrawerClose,
    handleDrawerOpen,
    openDrawer
  } = useReports();

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" open={openDrawer} sx={{ top: 'auto', left: 'auto', right: 'auto' }}>
        <Toolbar>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 2,
              ...(openDrawer && { display: 'none' }),
              backgroundColor: theme.palette.primary.main
            }}
          >
            <FilterAltOutlinedIcon />
          </IconButton>
          {selectedReport.reportInfo ? (
            <Typography variant="h5" color={'black'}>
              {selectedReport.reportInfo.nombre}
            </Typography>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          position: 'relative', //imp
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            position: 'absolute', //imp
            transition: 'none !important'
          }
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'space-between', height: '100px' }}>
          <Autocomplete
            disablePortal
            id="combo-box-informes"
            options={reportList}
            value={selectedReport.reportInfo}
            getOptionLabel={(option) => option.nombre}
            sx={{ width: '82%' }}
            renderInput={(params) => <TextField {...params} label={dictionary_informes.INFORME} />}
            onChange={handleAutocompleteReportList}
          />
          <IconButton
            onClick={handleDrawerClose}
            color="secondary"
            edge="start"
            sx={{
              backgroundColor: theme.palette.primary.main
            }}
          >
            <FilterAltOutlinedIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {selectedReport.filter}
      </Drawer>
      <Main open={openDrawer}>{selectedReport.reportContainer}</Main>
    </Box>
  );
}
