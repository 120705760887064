export enum namesLocalStorage {
  expTemperature = 'expDateTemp',
  tempMin = 'tempMin',
  tempMax = 'tempMax',
  dailyForecast = 'dailyForecast',
  uvIndex = 'uvIndex',
  pop = 'pop',
  user = 'user',
  clouds = 'clouds',
  expDateCurrentWeather = 'expDateCurrentWeather',
  weatherAlerts = 'weatherAlerts'
}
