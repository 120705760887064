import { AxiosRequestConfig } from 'axios';
import { getHeaders } from 'functions/functions';
import { dictionary_entornos } from 'resources/enums/plainText';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { Key } from 'swr';

/**
 * Get the residuals parameters details .
 * @returns {Key, AxiosRequestConfig} key used by swr and axios request config.
 */
export function getResidualsParametersDetail() {
  const key: Key = `${swrServiceRoutes.getResidualsParametersDetail}`;

  const request: AxiosRequestConfig = {
    method: 'POST',
    url: process.env.REACT_APP_URL_LOCAL + swrServiceRoutes.getResidualsParametersDetail,
    headers: getHeaders(dictionary_entornos.BACK)
  };

  return { key, request };
}
