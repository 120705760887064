import * as React from 'react';
import ControlContext from 'context/control/ControlContext';
import { deleteRecords, getAll } from 'shared/services/sharedAppServices';
import { rutasServicios } from 'resources/enums/enumRutasServicios';
import { useDeleteRecords, useRequest } from 'shared/hooks/useRequest';
import { swrServiceRoutes } from 'shared/services/serviceRoutes';
import { ArgsDeleteRecords } from 'shared/services/types';
import { Unit } from 'shared/types';

export function useUnitType() {
  const { setControl } = React.useContext(ControlContext);

  const [openAlertEliminar, setOpenAlertEliminar] = React.useState<boolean>(false);

  const {
    data: unitType = [],
    isLoading,
    mutate: setUnitType
  } = useRequest<Array<Unit>>(() => getAll(rutasServicios.TIPOS_UNIDADES));

  const {
    trigger: deleteUnitsTypes,
    selectRecordsToDelete,
    alertObject
  } = useDeleteRecords<unknown, ArgsDeleteRecords>(
    () => deleteRecords(swrServiceRoutes.unitTypes),
    true
  );

  function onConfirm(): void {
    deleteUnitsTypes().then(() =>
      //Refresh data.
      setUnitType()
    );
  }

  return {
    unitType,
    setUnitType,
    setControl,
    openAlertEliminar,
    setOpenAlertEliminar,
    isLoading,
    deleteUnitsTypes,
    selectRecordsToDelete,
    alertObject,
    onConfirm
  };
}
