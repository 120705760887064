import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Chip } from '@mui/material/';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  dictionary_generic,
  dictionary_reglas,
  enumLabel
} from '../../../resources/enums/plainText';
import useStyles from '../../reglas/stylesTarjetas';

import { FormularioCondicionDispositivo } from './FormularioCondicionDispositivo';
import TextField from '@mui/material/TextField';

import { TipoCondicionTemporal } from '../../reglas/resources/enums/enumTipoCondicionTemporal';
import { TeliaMostrarCondicion } from '../TeliaMostrarCondicion';
import { CondicionTemporal } from './FormularioCondicionTemporal';
import { CondicionGrupal } from './FormularioCondicionGrupal';
import { TipoCondicion } from '../resources/enums/enumTipoCondicion';

export function ContenedorCrearCondicion(props: {
  stateCondiciones: any;
  events: any;
  state: any;
}): JSX.Element {
  const classes = useStyles();
  const { state, stateCondiciones, events } = props;
  const { listaCondiciones } = state;
  const { convertirOperadorAFrase } = events;

  const tiposCondiciones = [
    TipoCondicion.CONDICION_TEMPORAL,
    TipoCondicion.CONDICION_DISPOSITIVO,
    TipoCondicion.CONDICION_GRUPAL
  ];

  const formularioCondicion = {
    [TipoCondicion.CONDICION_DISPOSITIVO]: (
      <FormularioCondicionDispositivo
        stateCondiciones={stateCondiciones}
        state={state}
        events={events}
      />
    ),
    [TipoCondicion.CONDICION_TEMPORAL]: (
      <CondicionTemporal stateCondiciones={stateCondiciones} state={state} events={events} />
    ),
    [TipoCondicion.CONDICION_GRUPAL]: (
      <CondicionGrupal stateCondiciones={stateCondiciones} state={state} events={events} />
    )
  };
  return (
    <form id="panelCrear">
      <Box
        display="flex"
        flexDirection="column"
        border="1px solid #C4C4C4"
        borderRadius="5px"
        style={{ width: '100%' }}
      >
        <Box display="flex" flexDirection="row">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1" color="secondary" className={classes.titulo}>
              {dictionary_reglas.CONDICIONES}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box p={1} flexGrow={1}>
            {/* Lista de las condiciones */}
            {listaCondiciones.map((condicion: any, i: React.Key | null | undefined) => {
              let label = '';

              if (condicion.canal)
                label = `${condicion.canal.nombre} ${convertirOperadorAFrase(
                  condicion.operador.operador
                )} ${condicion.valor}`;
              if (condicion.tipoTemporal) {
                label = events.mensajesTemporales[condicion.tipoTemporal as TipoCondicionTemporal](
                  condicion
                );
              }
              if (condicion.nombreCanal) {
                label = `${condicion.nombreCanal} de ${
                  condicion.grupo.codigo
                } ${convertirOperadorAFrase(condicion.operador.operador)} ${condicion.valor}`;
              }

              return (
                <Chip
                  key={i}
                  label={label}
                  color="secondary"
                  onClick={() => {
                    events.mostrarInfoCondicion(condicion);
                  }}
                  onDelete={() => {
                    events.quitarCondicionLista(condicion);
                  }}
                  style={{ margin: '3px' }}
                />
              );
            })}
          </Box>
        </Box>
        {/* Aquí va el componente que indica el tipo de condición*/}
        <Box display="flex" flexDirection="column">
          <Box p={1}>
            <Autocomplete
              value={stateCondiciones.tipoCondicion}
              disableClearable
              id="tiposCondicion"
              options={tiposCondiciones as string[]}
              size="small"
              getOptionLabel={(option) => option}
              onChange={(event, value) => {
                events.setTipoCondicion(value);
              }}
              renderInput={(params) => (
                <TextField {...params} label={enumLabel.TIPO_CONDICION} variant="outlined" />
              )}
            />
          </Box>
        </Box>

        {stateCondiciones.tipoCondicion &&
          formularioCondicion[stateCondiciones.tipoCondicion as TipoCondicion]}

        {/* Botón de crear condición */}
        <Box display="flex" flexDirection="row-reverse">
          <Box p={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => events.handleCreateCondicion()}
            >
              {dictionary_generic.CREAR}
            </Button>
          </Box>
        </Box>
        <TeliaMostrarCondicion state={state} events={events} stateCondiciones={stateCondiciones} />
      </Box>
    </form>
  );
}
