import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'wouter';
import { PageLogin } from './pages/Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContextProvider } from './context/UserContext';
import { ControlContextProvider } from './context/control/ControlContext';
import { DatosContextProvider } from './context/DatosContext';
import SmartTeliaBackdropContext, {
  SmartTeliaBackdropContextProvider
} from './context/SmartTeliaBackdropContext';
import { SmartTeliaBackdrop } from './components/common/smartTeliaBackdrop/SmartTeliaBackdrop';
import './style.css';
import ErrorBoundary from './components/error/components/ErrorBoundary';
import { ErrorContextProvider } from './components/error/context/ErrorContext';
import SnackBarContext, { SnackBarContextProvider } from './context/SnackBarContext';
import { CommonSnackBar } from './components/common/snackbar/SnackBar';
import { getColorCliente } from './functions/functions';
import PageMain from './pages/Main';
import { StyledEngineProvider } from '@mui/material/styles';
import { dictionary_generic } from './resources/enums/plainText';
import { Color } from './resources/enums/enumColores';
import { esES } from '@mui/material/locale';
import { SWRConfig } from 'swr';
import { enumSeverity } from 'components/common/snackbar/resources/enums/enumSeverity';

function App(): JSX.Element {
  const [colorCliente, setColorCliente] = React.useState<string>(getColorCliente());
  const { openSmartTeliaBackdrop } = useContext(SmartTeliaBackdropContext);
  const { setSnackBar } = React.useContext(SnackBarContext);

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: Color.PRIMARIO_POR_DEFECTO
        },
        secondary: {
          main: colorCliente
        }
      },
      typography: {
        fontFamily: 'Open Sans',
        fontSize: 5.65,
        button: {
          textTransform: 'none'
        }
      },
      components: {
        MuiCheckbox: {
          styleOverrides: {
            colorPrimary: {
              '&.Mui-checked': {
                color: colorCliente
              }
            }
          }
        },
        MuiMenu: {
          styleOverrides: {
            root: {
              '& .MuiPaper-root': {
                background: 'white!important' as 'white'
              }
            }
          }
        },

        MuiTooltip: {
          styleOverrides: {
            arrow: {
              color: '#ffffff'
            },
            tooltip: {
              fontSize: '0.40rem',
              backgroundColor: '#ffffff',
              color: '#000000',
              boxShadow: '0 0 5px 1px rgba(0,0,0,0.5)'
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            overline: {
              fontSize: '1rem'
            }
          }
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              fontSize: '0.75rem'
            }
          }
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: 'white'
            },
            colorDefault: {
              backgroundColor: '#eaeaea'
            }
          }
        },
        MuiTabs: {
          styleOverrides: {
            scroller: {
              display: 'flex'
            },
            flexContainer: {
              width: '100%'
            }
          }
        },

        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: 14,
              fontWeight: 500
            }
          }
        },
        // MuiDrawer: {
        //   paperAnchorDockedLeft: {
        //     background: `linear-gradient(1deg, #000000 -30%, #ffffff 20%)`
        //   }
        // },
        MuiTablePagination: {
          styleOverrides: {
            root: { marginRight: '70px' }
          }
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&.MuiTableRow-hover:hover td div:nth-child(2) button': {
                visibility: 'visible'
              }
            }
          }
        },

        MuiToolbar: {
          styleOverrides: {
            root: {
              '& div:nth-child(2) button': {
                borderRadius: '12%',
                border: 'solid',
                borderColor: colorCliente,
                borderWidth: 'thin'
              },
              '& div:nth-child(2) button span:nth-child(1) svg': {
                fill: colorCliente
              },
              '& div:nth-child(2) button:hover': {
                backgroundColor: colorCliente,
                color: 'white'
              },
              '& div:nth-child(2) button:hover span:nth-child(1) svg': {
                fill: '#ffffff'
              }
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: { padding: '8px' },
            head: {
              '&.cellHeaderCustomTable span button': {
                fontSize: '0.41rem',
                fontWeight: 'bold'
              },
              '&.cellHeaderConfiguratorDevices span button': {
                fontSize: '0.51rem',
                fontWeight: 'bold'
              }
            }
          }
        },
        MuiAvatar: {
          styleOverrides: {
            colorDefault: {
              backgroundColor: colorCliente
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              '*::-webkit-scrollbar': {
                width: '10px',
                height: '10px'
              }
            },
            '*::-webkit-scrollbar-track': {
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
              borderRadius: '10px'
            },
            '*::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
              backgroundColor: '#99999955'
            }
          }
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              '&.info-grafica': {
                '@media print': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                  width: '100%'
                },
                '&.info-grafica div div': {
                  '@media print': {
                    flexBasis: '15%',
                    marginLeft: '12px',
                    marginTop: '15px'
                  }
                }
              }
            }
          }
        },
        MuiStepIcon: {
          styleOverrides: {
            root: {
              '&.Mui-active': {
                color: '#eaeaea'
              },
              '&.MuiStepIcon-completed': {
                color: colorCliente
              }
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#c3c4c5'
              }
            }
          }
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              '&.Mui-focused': {
                color: '#6d6d6d'
              }
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            elevation4: {
              boxShadow: 'none'
            },
            root: {
              '&.MuiPopover-paper': {
                background: `linear-gradient(360deg,  ${colorCliente}, ${colorCliente}83)`
              }
            },
            rounded: {
              width: '100%'
            }
          }
        },
        MuiBadge: {
          styleOverrides: {
            root: {
              '&.badge-home span': {
                bottom: '18px',
                color: 'white',
                width: '30px',
                height: '30px',
                borderRadius: '30px',
                fontSize: '20px',
                fontFamily: 'serif'
              }
            }
          }
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              '&>.MuiPaper-root>.MuiToolbar-root': {
                minHeight: '42px',
                paddingRight: '40px',
                '& div:first-child': {
                  alignSelf: 'flex-end'
                },
                '& div:nth-child(2) button': {
                  border: 'none',
                  padding: '6px',
                  color: '#000000',
                  '& span:nth-child(1) svg': {
                    fill: 'rgb(0,0,0,0.7)'
                  },
                  '&:hover span:nth-child(1) svg': {
                    fill: '#ffffff'
                  }
                }
              }
            }
          }
        },
        MuiDialog: {
          styleOverrides: {
            root: {
              '&.weatherAlertDialog': {
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    borderRadius: '15px'
                  }
                }
              }
            }
          }
        }
      }
    },
    esES
  );

  return (
    <div style={{ height: '100%' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UserContextProvider>
            <SmartTeliaBackdropContextProvider>
              <SnackBarContextProvider>
                <ControlContextProvider>
                  <DatosContextProvider>
                    <SmartTeliaBackdrop open={openSmartTeliaBackdrop}></SmartTeliaBackdrop>
                    <Switch>
                      <Route path="/">
                        <PageLogin />
                      </Route>
                      <Route path="/restart-password">
                        <PageLogin isRestartPassword={true} />
                      </Route>
                      <Route path="/main">
                        <ErrorContextProvider>
                          <ErrorBoundary>
                            <PageMain setColorCliente={setColorCliente} />
                          </ErrorBoundary>
                        </ErrorContextProvider>
                      </Route>
                      <Route path="/:rest*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </DatosContextProvider>
                </ControlContextProvider>
                <CommonSnackBar
                  defaultText={dictionary_generic.PROBLEMAS}
                  defaultSeverity={'error'}
                  autoHideDuration={4500}
                ></CommonSnackBar>
              </SnackBarContextProvider>
            </SmartTeliaBackdropContextProvider>
          </UserContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
