import React from 'react';
import { DispositivoManager } from '../../dispositivos/DispositivoManager';
import { CanalesManager } from '../../canales/CanalesManager';
import { GruposManager } from '../../grupos/GruposManager';
import { TiposDispositivoManager } from '../../tiposDispositivo/TiposDispositivoManager';
import { TiposMedidasManager } from '../../tiposMedidas/TiposMedidasManager';
import { EtiquetasManager } from '../../etiquetas/EtiquetasManager';
import { DeviceManager } from '../../device/DeviceManager';
import { ReglasManager } from '../../reglas/ReglasManager';
import { UsuariosManager } from '../../usuarios/UsuariosManager';
import { enumComponentes } from '../../../resources/enums/enumComponente';
import { objectDevicetoPlugin } from '../../plugins/resources/interfaces/interfacePlugin';
import { TiposActuacionesManager } from '../../tiposActuaciones/TiposActuacionesManager';
import { UnitsTypesCRU } from 'components/UnitTypes/UnitTypeCRU';

export function controladorBotonEditar(
  objetoSeleccionado: any,
  setControl: (element: JSX.Element) => void,
  jsxElement: string,
  control: JSX.Element
): void {
  /**
   * Función para obtener resto de la key que nos sirve como rastro en los componentes
   * de Device: En el caso de modificar un device, es posible que tengamos que dividir la key
   * @returns {string}
   */
  const getKeySecond = (): string => {
    const keyDivide = (control.key as string)?.split('-');
    return (keyDivide[1] ? '-' + keyDivide[1] : '') + (keyDivide[2] ? '-' + keyDivide[2] : '');
  };

  const element: any = {
    Dispositivos: (
      <DispositivoManager
        dispositivoSeleccionado={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_DISPOSITIVO}
      />
    ),
    Canales: (
      <CanalesManager
        canalSeleccionado={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_CANAL}
      />
    ),
    Grupos: (
      <GruposManager grupoSeleccionado={objetoSeleccionado} key={enumComponentes.MODIFICAR_GRUPO} />
    ),
    Etiquetas: (
      <EtiquetasManager
        etiquetaSeleccionada={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_ETIQUETA}
      />
    ),
    TiposDispositivo: (
      <TiposDispositivoManager
        tipoSeleccionado={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_TIPO_DISPOSITIVO}
      />
    ),
    TiposMedida: (
      <TiposMedidasManager
        tipoSeleccionado={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_TIPO_MEDIDA}
      />
    ),
    TiposUnidad: (
      <UnitsTypesCRU
        unitTypeSelected={objetoSeleccionado}
        key={enumComponentes.MODIFICAR_TIPO_UNIDAD}
      />
    ),
    Devices: (
      <DeviceManager
        deviceSeleccionado={objetoSeleccionado}
        pluginSeleccionado={getKeySecond() ? objectDevicetoPlugin(objetoSeleccionado) : undefined}
        key={enumComponentes.MODIFICAR_DEVICE + getKeySecond()}
      />
    ),
    Reglas: (
      <ReglasManager reglaSeleccionada={objetoSeleccionado} key={enumComponentes.MODIFICAR_REGLA} />
    ),
    Usuarios: (
      <UsuariosManager
        key={enumComponentes.MODIFICAR_USUARIOS}
        usuarioSeleccionado={objetoSeleccionado}
      />
    ),
    Tiposactuaciones: (
      <TiposActuacionesManager
        key={enumComponentes.MODIFICAR_TIPO_ACTUACION}
        tipoSeleccionado={objetoSeleccionado}
      />
    )
  };

  setControl(element[jsxElement]);
}
