import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useStyles from './styles';
import { UsuariosManager } from './UsuariosManager';
import { BotonTablaFilaEditar } from '../common/table/BotonesTablaFila';
import { ContenedorElementos } from '../common/table/contenedorElementos';
import PaginationRounded from '../common/table/Paginacion';
import Table from '../common/table/Table';
import { enumTabla } from '../common/table/resources/enumTabla';
import { dictionary_usuarios } from '../../resources/enums/plainText';
import { usuario } from '../../resources/interfaces/interfaceUsuario';
import ControlContext from '../../context/control/ControlContext';
import { enumComponentes } from '../../resources/enums/enumComponente';

export function TablaUsuarios({ usuarios }: { usuarios: usuario[] }): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { setControl } = React.useContext(ControlContext);

  const columns = [
    { name: 'id', options: { display: 'excluded' } },
    { name: 'nombre', label: 'Nombre' },
    { name: 'apellidos', label: 'Apellidos' },
    {
      name: 'email',
      label: 'E-mail'
    },
    {
      name: 'nombreUsuario',
      label: 'Nombre de usuario'
    },
    {
      name: 'poblacion',
      label: 'Población'
    },
    {
      name: 'telefono',
      label: 'Teléfono'
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: any): JSX.Element => {
          return (
            <Grid
              key={enumTabla.BOTONES_TABLA}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              className={classes.gridBotonTabla}
            >
              <ContenedorElementos
                elemento={
                  <BotonTablaFilaEditar
                    datosTabla={tableMeta}
                    elemento={dictionary_usuarios.TITULO_TABLA}
                  />
                }
                colorElemento={theme.palette.secondary.main}
                colorFondo={`${theme.palette.secondary.main}33`}
                clase={'botones'}
              />
            </Grid>
          );
        }
      }
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    rowsPerPage: 10,
    download: false,
    filter: false,
    viewColumns: false,
    print: false,
    responsive: 'simple',
    // eslint-disable-next-line react/display-name
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      _changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ) => {
      return (
        <PaginationRounded
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changePage={changePage}
        />
      );
    },
    onCellClick: (colData: { key: enumTabla }, cellMeta: { dataIndex: number }) => {
      if (usuarios.length > 0 && colData.key !== enumTabla.BOTONES_TABLA) {
        setControl(
          <UsuariosManager
            key={enumComponentes.MODIFICAR_USUARIOS}
            usuarioSeleccionado={usuarios[cellMeta.dataIndex]}
          />
        );
      }
    }
  };

  return (
    <Table
      title={dictionary_usuarios.TITULO_TABLA}
      data={usuarios}
      columns={columns}
      options={options}
    />
  );
}
