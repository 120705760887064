/**
 * @readonly
 * @enum {number} DatesRange
 * @param WITHOUT_INDICATING = 0,
 * @param RANGE = 1
 * @param LAST_24H = 2 - CONTANDO DESDE AHORA MISMO 24 HORAS HACIA ATRÁS
 * @param WEEKLY = 3 - UNA SEMANA ATRÁS DESDE HOY
 * @param MONTHLY = 4 - UN MES ATRÁS DESDE HOY
 * @param ANNUALLY = 5 - AUN AÑO ATRÁS DESDE HOY
 * @param TODAY = 6 - HOY
 * @param LAST_MONTH = 7 - EL MES PASADO
 * @param THIS_MONTH = 8 - ESTE MES
 * @param TODAY_IN_THE_PREVIOUS_MONTH = 9
 * @param LAST_YEAR = 10 - EL AÑO ANTERIOR
 * @param TODAY_IN_THE_PREVIOUS_YEAR = 11 - EL AÑO PASADO A DÍA DE HOY
 * @param LAST_WEEK = 12 - LA SEMANA ANTERIOR
 * @param THIS_WEEK = 13 - ESTA SEMANA
 * @param THIS_YEAR = 14 - LO QUE LLEVAMOS DE AÑO (DESDE QUE EMPEZÓ EL AÑO HASTA HOY)
 * @param YESTERDAY = 15 - AYER
 */
export enum Rango {
  WITHOUT_INDICATING = 0,
  RANGE = 1,
  LAST_24H = 2,
  WEEKLY = 3,
  MONTHLY = 4,
  ANNUALLY = 5,
  TODAY = 6,
  LAST_MONTH = 7,
  THIS_MONTH = 8,
  TODAY_IN_THE_PREVIOUS_MONTH = 9,
  LAST_YEAR = 10,
  TODAY_IN_THE_PREVIOUS_YEAR = 11,
  LAST_WEEK = 12,
  THIS_WEEK = 13,
  THIS_YEAR = 14,
  YESTERDAY = 15
}
