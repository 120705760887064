import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button } from '@mui/material';
import {
  dictionary_dispositivos,
  dictionary_etiquetas,
  dictionary_informes,
  dictionary_tiposDispositivos,
  enumLabel
} from 'resources/enums/plainText';
import { SelectAllAutocomplete } from 'components/common/selectAllAutocomplete/SelectAllAutocomplete';
import { tiposDispositivo } from 'components/tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { RangeDateComponent } from 'components/common/dateComponent/DateComponent';
import { useConsumptionReportFilter } from './index.hook';

export function ConsumptionReportFilter() {
  const {
    devices = [],
    deviceTypesByReport = [],
    tags = [],
    selectedDevices = [],
    handleAutoCompleteDeviceTypes,
    handleAutoCompleteDevices,
    handleAutoCompleteTags,
    handleDate,
    handleGetReport,
    resetReport,
    startDate,
    endDate,
    dateError
  } = useConsumptionReportFilter();

  return (
    <>
      <List>
        <ListItem button key={'tiposDispositivos'}>
          <SelectAllAutocomplete
            id={'tiposDispositivos'}
            items={deviceTypesByReport}
            label={dictionary_tiposDispositivos.TIPOS_DISPOSITIVO}
            selectAllLabel={dictionary_informes.SELECCIONAR_TODOS}
            onChange={handleAutoCompleteDeviceTypes}
            limitTags={1}
            fullWidth
            disabled={deviceTypesByReport.length > 0 ? false : true}
            getOptionLabel={(option: tiposDispositivo) => option.nombre}
          />
        </ListItem>
        <ListItem button key={'dispositivos'}>
          <SelectAllAutocomplete
            id={'dispositivos'}
            dataTestId={'autocomplete-devices'}
            items={devices}
            label={dictionary_dispositivos.DISPOSITIVOS}
            selectAllLabel={dictionary_informes.SELECCIONAR_TODOS}
            onChange={handleAutoCompleteDevices}
            limitTags={1}
            fullWidth
            disabled={devices.length > 0 ? false : true}
            getOptionLabel={(option: { nombre: string; id: number }) => option.nombre}
            allDefaultValue={true}
          />
        </ListItem>
        <ListItem button key={dictionary_etiquetas.ETIQUETAS}>
          <SelectAllAutocomplete
            id={'etiquetas'}
            items={tags}
            label={dictionary_etiquetas.ETIQUETAS}
            selectAllLabel={dictionary_informes.SELECCIONAR_TODOS}
            onChange={handleAutoCompleteTags}
            limitTags={1}
            fullWidth
            disabled={tags.length > 0 ? false : true}
            getOptionLabel={(option: { nombre: string; id: number }) => option.nombre}
          />
        </ListItem>

        <ListItem>
          <RangeDateComponent
            calendarPosition={'top-start'}
            onChange={handleDate}
            values={[startDate, endDate]}
            label={enumLabel.RANGO_FECHAS}
            error={dateError}
          />
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            onClick={resetReport}
            style={{ width: '100%', marginRight: '3%' }}
            color="secondary"
          >
            {dictionary_informes.RESTABLECER_FILTROS}
          </Button>
          <Button
            variant="contained"
            onClick={handleGetReport}
            style={{ width: '100%' }}
            color="secondary"
            disabled={!selectedDevices.length || dateError}
          >
            {dictionary_informes.VER_INFORME}
          </Button>
        </ListItem>
      </List>
    </>
  );
}
