import { makeStyles } from '@mui/styles';

const useStyleDocumentCard = makeStyles(() => ({
  cardContainer: {
    maxHeight: '100vh',
    overflow: 'auto'
  },
  card: {
    position: 'relative'
  },
  fileIcon: {
    marginRight: '5px'
  },
  cardAction: {
    justifyContent: 'space-between'
  }
}));

export default useStyleDocumentCard;
