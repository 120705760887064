import { Modulos } from './enumModulo';

/**
 * Enumerado que permite distinguir entre los id de los modulos
 */
export enum componentePorDefecto {
  CONFIGURACION_GENERAL = 'Grupos',
  GESTION_USUARIOS = 'Usuarios',
  REGLAS = 'Reglas',
  MODULO_GENERICO = 'Home'
}

/**
 * Enumerado que permite distinguir entre los nombres de los componentes en el tab (cambio de color en pestañas)
 */
export enum componenteTab {
  POSICION_0 = 0,
  POSICION_1 = 1,
  POSICION_2 = 2,
  POSICION_3 = 3,
  POSICION_4 = 4,
  POSICION_5 = 5,
  POSICION_6 = 6
}

/**
 * Enumerado que permite distinguir entre los nombres de los componentes
 */

export enum enumComponentes {
  HOME = 'Home',
  MAPA = 'Mapa',
  GRAFICAS = 'Gráficas',
  INFORMES = 'Informes',
  ALERTAS = 'Alertas',
  ALERTAS_REGISTRADAS = 'Alertas registradas',
  ALERTAS_PENDIENTES = 'Alertas pendientes',
  CONTROL = 'Control',
  ADMINISTRACION = 'Administración',
  CREAR_DISPOSITIVO = 'Crear dispositivo',
  MODIFICAR_DISPOSITIVO = 'Modificar dispositivo',
  CREAR_CANAL = 'Crear canal',
  MODIFICAR_CANAL = 'Modificar canal',
  USUARIOS = 'Usuarios',
  MODIFICAR_USUARIOS = 'Modificar Usuarios',
  MODIFICAR_PERFIL = 'Modificar Perfil',
  GRUPOS = 'Grupos',
  MODIFICAR_GRUPO = 'Modificar grupo',
  ETIQUETAS = 'Etiquetas',
  MODIFICAR_ETIQUETA = 'Modificar etiqueta',
  TIPOS_DISPOSITIVOS = 'Tipos de dispositivos',
  MODIFICAR_TIPO_DISPOSITIVO = 'Modifcar tipo dispositivo',
  TIPOS_MEDIDAS = 'Tipos de medidas',
  MODIFICAR_TIPO_MEDIDA = 'Modificar tipo medida',
  TIPOS_UNIDADES = 'Tipos de unidades',
  MODIFICAR_TIPO_UNIDAD = 'Modificar tipo unidad',
  PLUGINS = 'Plugins',
  PLUGINS_INSTALADOS = 'Plugins instalados',
  DEVICES = 'Devices',
  DEVICES_POR_PLUGIN = 'Devices por plugin',
  CREAR_DEVICE = 'Crear device',
  MODIFICAR_DEVICE = 'Modificar device',
  INFORMACION_DEVICE = 'Informacion device',
  INFO_MODIFICAR_DEVICE = 'Informacion modificar device',
  REGLAS = 'Reglas',
  CREAR_REGLA = 'Crear regla',
  MODIFICAR_REGLA = 'Modificar regla',
  TIPOS_CONDICIONES = 'Tipos de condiciones',
  CREAR_TIPO_CONDICION = 'Crear tipo condicion',
  MODIFICAR_TIPO_CONDICION = 'Modificar tipo condicion',
  TIPOS_ACCIONES = 'Tipos de acciones',
  CREAR_TIPO_ACCION = 'Crear tipo accion',
  MODIFICAR_TIPO_ACCION = 'Modificar tipo accion',
  ENLACES_EXTERNOS = 'Enlaces externos',
  CANALES = 'Canales',
  DISPOSITIVOS = 'Dispositivos/Gestor documental',
  TIPOS_ACTUACIONES = 'Tipos de actuaciones',
  ACTUACION_DISPOSITIVO = 'Actuación sobre dispositivo',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'email',
  CAMBIO_ESTADO_REGLA = 'Cambio de estado de relga',
  MODIFICAR_TIPO_ACTUACION = 'MODIFICAR_TIPO_ACTUACION',
  ENVIAR_EMAIL = 'ENVIAR_EMAIL',
  ENVIAR_WHATSAPP = 'Enviar WhatsApp',
  CREAR_TIPO_ACTUACION = 'Crear tipo de actuación',
  CONFIGURADOR = 'Configurador',
  MIS_INTERESES = 'MisIntereses',
  ACTUACION_GRUPAL = 'Actuación grupal'
}
