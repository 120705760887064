import React, { useEffect, useReducer } from 'react';
import { dispositivo } from '../../dispositivos/resources/interfaces/interfaceDispositivo';
import { tiposDispositivo } from '../../tiposDispositivo/resources/interfaces/interfaceTiposDispositivo';
import { useGraficas } from './useGraficas/useGraficas';
import { Operation } from '../resources/enums/enumOperation';
import { Frecuencia, Medida } from '../resources/types/types';
import { Rango } from '../resources/enums/enumRango';
import { GraphTypes } from '../resources/enums/enumGraphTypes';
import { MultipleYaxisChart } from '../../graficasApexCharts/MultipleYaxisChart';
import { LineColumnChart } from '../../graficasApexCharts/LineColumnChart';
import { AreaChart } from '../../graficasApexCharts/AreaChart';
import { CodigoDispositivo } from '../../../resources/enums/enumCodigoDispositivo';
import { TiposMedida } from '../../../resources/enums/enumTiposMedida';
import { codigoDispositivo } from '../components/tarjetasGraficas/TarjetaManager';
import { startOfMonth, subDays, differenceInDays, add, isBefore, addDays, isAfter } from 'date-fns';

export const ACTIONS = {
  SET_DISPOSITIVOS: 'setDispositivos', //Añade los dispositivos del módulo.
  SET_TIPOS_DISPOSITIVOS: 'setTiposDispositivos', //Añade los tipos de dispositivos.
  CHANGE_AUTOCOMPLETE_TIPOS: 'changeAutoCompleteTipos', // Al seleccionar un tipo de dispositivo esta función filtra los dispositivos por ese tipo para el AutoComplete de dispositivos.
  CHANGE_AUTOCOMPLETE_DISPOSITIVOS: 'changeAutoCompleteDispositivos', // Al seleccionar dispositivos, esta función va creando el objeto con los dispositivos seleccionados.
  MOSTRAR_TARJETAS: 'mostrarTarjetas', // Al seleccionar un dispositivo, se muestran las tarjetas correspondientes a esos dispositivos.
  ABRIR_SLIDER: 'abrirSlider', // Al hacer click en una tarjeta se abre el slider con la gráfica correspondiente y sus datos.
  CERRAR_SLIDER: 'cerrarSlider', // Cierra el slider para poder seleccionar otro tipo de gráfica.
  DESHABILITAR_TARJETAS: 'deshabilitarTarjetas', // Si no hay ningún dispositivo seleccionado, se deshabilitan las tarjetas.
  HABILITAR_TARJETAS: 'habilitarTarjetas', // Cuando se selecciona uno o más dispositivos se habilitan las tarjetas para poder consultar gráficas.
  CAMBIAR_FRECUENCIA_CONSULTA: 'cambiarFrecuenciaConsulta', // Al cambiar de frecuencia, se debe ejecutar de nuevo la obtención de datos para mostrar la nueva gráfica.
  LIMPIAR_AUTOCOMPLETE_DISPOSITIVOS: 'limpiarAutoCompleteDispositivos',
  SET_DATOS_GRAFICA_NO_COMPONENTE: 'setDatosGraficaNoComponente', //Genera los datos cuando se llama al hook desde otro sitio que no sea el componente Gráficas.
  LIMPIAR_DATOS_GRAFICA_NO_COMPONENTE: 'limpiarDatosGrafica', //Limpia los datos cuando se obtienes desde un componente distinto al de Gráficas.
  SET_AGRUPAR_DISPOSITIVOS: 'setAgruparDispositivos',
  SET_FECHA_INICIO: 'setFechaInicio',
  SET_FECHA_FIN: 'setFechaFin',
  SET_DATE_ERROR: 'setDateError'
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_DISPOSITIVOS:
      return { ...state, dispositivos: action.payload };
    case ACTIONS.SET_TIPOS_DISPOSITIVOS:
      return { ...state, tiposDispositivos: action.payload };
    case ACTIONS.CHANGE_AUTOCOMPLETE_TIPOS:
      return {
        ...state,
        tipoFiltrado: {
          tipoSeleccionado: action.payload.tipoSeleccionado,
          dispositivosFiltrados: action.payload.dispositivosFiltrados,
          deshabilitarDispositivos: false,
          mostrarAgrupar: action.payload.agruparDispositivos
        }
      };
    case ACTIONS.CHANGE_AUTOCOMPLETE_DISPOSITIVOS:
      return {
        ...state,
        dispositivosSeleccionados: action.payload
      };
    case ACTIONS.MOSTRAR_TARJETAS:
      return {
        ...state,
        mostrarTarjetas: action.payload,
        deshabilitarTiposDispositivos: false
      };
    case ACTIONS.ABRIR_SLIDER:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          ...action.payload,
          abrirSlider: true
        },
        mostrarTarjetas: false,
        deshabilitarTiposDispositivos: true,
        tipoConsulta: action.payload.tipoConsulta
      };
    case ACTIONS.CERRAR_SLIDER:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          abrirSlider: false,
          datos: undefined,
          frecuencia: Rango.THIS_WEEK
        }
      };
    case ACTIONS.LIMPIAR_DATOS_GRAFICA_NO_COMPONENTE:
      return {
        ...state,
        componenteSlider: { ...state.componenteSlider, grafica: undefined }
      };
    case ACTIONS.DESHABILITAR_TARJETAS:
      return {
        ...state,
        deshabilitarTarjetas: true
      };
    case ACTIONS.HABILITAR_TARJETAS:
      return {
        ...state,
        deshabilitarTarjetas: false
      };
    case ACTIONS.CAMBIAR_FRECUENCIA_CONSULTA:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          frecuencia: action.payload,
          datos: undefined
        }
      };
    case ACTIONS.LIMPIAR_AUTOCOMPLETE_DISPOSITIVOS:
      return {
        ...state,
        limpiarAutoCompleteDispositivos: action.payload,
        dispositivosSeleccionados: []
      };

    case ACTIONS.SET_AGRUPAR_DISPOSITIVOS:
      return {
        ...state,
        agruparDispositivos: action.payload
      };
    case ACTIONS.SET_FECHA_INICIO:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          fechaInicio: action.payload
        }
      };
    case ACTIONS.SET_FECHA_FIN:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          fechaFin: action.payload
        }
      };
    case ACTIONS.SET_DATE_ERROR:
      return {
        ...state,
        componenteSlider: {
          ...state.componenteSlider,
          dateError: action.payload
        }
      };
    default:
      return state;
  }
};

export function useReducerGraficasParent() {
  const { obtenerDatosGrafica } = useGraficas();

  const [state, dispatch] = useReducer(reducer, {
    dispositivos: [] as dispositivo[],
    tiposDispositivos: [] as tiposDispositivo[],
    tipoFiltrado: {
      tipoSeleccionado: {} as tiposDispositivo,
      dispositivosFiltrados: [] as dispositivo[],
      deshabilitarDispositivos: true,
      mostrarAgrupar: false
    },
    dispositivosSeleccionados: [] as dispositivo[],
    componenteSlider: {
      frecuencia: Rango.THIS_WEEK,
      operacion: Operation.AVG,
      abrirSlider: false,
      fechaInicio: [
        {
          startDate: startOfMonth(new Date()),
          endDate: new Date(),
          key: 'selection'
        }
      ],
      fechaFin: [
        {
          startDate: subDays(
            startOfMonth(new Date()),
            differenceInDays(new Date(), startOfMonth(new Date()))
          ),
          endDate: add(subDays(startOfMonth(new Date()), 1), {
            hours: 23,
            minutes: 59,
            seconds: 59
          }),
          key: 'selection'
        }
      ],
      dateError: false
    },
    mostrarTarjetas: true,
    tipoConsulta: undefined,
    deshabilitarTiposDispositivos: false,
    deshabilitarTarjetas: true,
    limpiarAutoCompleteDispositivos: true,
    agruparDispositivos: false
  });

  const {
    dispositivos,
    tiposDispositivos,
    tipoFiltrado,
    dispositivosSeleccionados,
    componenteSlider,
    mostrarTarjetas,
    tipoConsulta,
    deshabilitarTiposDispositivos,
    deshabilitarTarjetas,
    limpiarAutoCompleteDispositivos,
    agruparDispositivos
  } = state;

  const refFocusEndRange = React.useRef<any>({
    startDate: subDays(
      componenteSlider.fechaInicio[0].startDate,
      differenceInDays(
        componenteSlider.fechaInicio[0].startDate,
        componenteSlider.fechaInicio[0].endDate
      )
    ),
    endDate: add(subDays(componenteSlider.fechaInicio[0].startDate, 1), {
      hours: 23,
      minutes: 59,
      seconds: 59
    }),
    key: 'selection'
  });

  /**
   * Añade los dispositivos del módulo.
   * @param {dispositivo[]}dispositivos[] Array de dispositivos, lo proporciona la petición al Contexto.
   */
  function setDispositivos(dispositivos: dispositivo[]) {
    dispatch({ type: ACTIONS.SET_DISPOSITIVOS, payload: dispositivos });
  }

  /**
   * Añade los tipos de dispositivos.
   * @param {tiposDispositivo[]}tiposDispositivo[] Array de tipos de dispositivo, lo proporciona la petición al Contexto.
   */
  function setTiposDispositivos(tiposDispositivo: tiposDispositivo[]) {
    dispatch({ type: ACTIONS.SET_TIPOS_DISPOSITIVOS, payload: tiposDispositivo });
  }

  /**
   * Al seleccionar un tipo de dispositivo esta función filtra los dispositivos por ese tipo para el AutoComplete de dispositivos.
   * @param { tiposDispositivo }tipoSeleccionado
   */
  function actualizarAutocompleteTiposDispositivos(tipoSeleccionado: tiposDispositivo) {
    const c = dispositivos.filter(
      (dispositivo: dispositivo) => dispositivo.idTipoDispositivo === tipoSeleccionado.id
    );

    let sePuedeAgrupar = false;

    if (codigoDispositivo[tipoSeleccionado.codigo as CodigoDispositivo])
      sePuedeAgrupar =
        codigoDispositivo[tipoSeleccionado.codigo as CodigoDispositivo][0].consulta ===
        TiposMedida.CONSUMO;

    if (dispositivosSeleccionados.length > 0)
      dispatch({ type: ACTIONS.CHANGE_AUTOCOMPLETE_DISPOSITIVOS, payload: [] });

    dispatch({
      type: ACTIONS.CHANGE_AUTOCOMPLETE_TIPOS,
      payload: {
        tipoSeleccionado: tipoSeleccionado.codigo,
        dispositivosFiltrados: c,
        agruparDispositivos: sePuedeAgrupar
      }
    });
  }
  /**
   * Controlador del AutoComplete de Fecha Inicio
   * @param {Date | null} date Fecha de inicio seleccionada por el usuario.
   */
  function handleFechaInicio(item: any) {
    const { differenceDaysInStart, differenceDaysInEnd } = rangeControl();

    item.selection.endDate = add(item.selection.endDate, {
      hours: 23,
      minutes: 59,
      seconds: 59
    });
    // setValue([item.selection]);
    dispatch({ type: ACTIONS.SET_FECHA_INICIO, payload: [item.selection] });

    const before = isBefore(item.selection.startDate, componenteSlider.fechaFin[0].startDate);

    const moreDays = differenceDaysInStart !== differenceDaysInEnd;

    //Si la fecha de inicio del periodo inicial es anterior a la fecha de inicio del periodo comparativo o hay más días en el periodo inicial que en el comparativo debe lanzar un error.
    if (before || moreDays)
      //setRangeError(true);
      dispatch({ type: ACTIONS.SET_DATE_ERROR, payload: true });
  }

  /**
   * Controlador del AutoComplete de Fecha Fin
   * @param {Date | null} date Fecha de fin seleccionada por el usuario.
   */
  function handleFechaFin(item: any) {
    //dispatch({ type: ACTIONS.SET_FECHA_FIN, payload: date });
    refFocusEndRange.current = item.selection;
  }

  //Esta función controla si el usuario ha seleccionado en el calendario del PERIODO COMPARATIVO tanto el inicio como el fin, la estructura del array es [1 | 0 ,1 | 0] 1 cuando no ha seleccionado, 0 cuando ha seleccionado.
  function handleRangeFocus(item: Array<number>) {
    // Si la posición 2 del array, es decir, la selección del rango final, es 0 (el usuario a elegido una fecha), debemos calcular si es correcta.
    if (item[1] === 0) {
      const { startDate, differenceDaysInStart, differenceDaysInEnd } = rangeControl();

      if (differenceDaysInStart !== differenceDaysInEnd && refFocusEndRange.current !== undefined)
        refFocusEndRange.current.endDate = addDays(
          refFocusEndRange.current.startDate,
          differenceDaysInStart
        );

      if (isAfter(refFocusEndRange.current.endDate, startDate)) {
        refFocusEndRange.current.startDate = subDays(startDate, differenceDaysInStart + 1);
        refFocusEndRange.current.endDate = addDays(
          refFocusEndRange.current.startDate,
          differenceDaysInStart
        );
      }

      //Si hay error, como se calcula automáticamente la fecha del periodo comparativo, debemos eliminar el error.
      if (componenteSlider.dateError) dispatch({ type: ACTIONS.SET_DATE_ERROR, payload: false });
    } else {
      dispatch({ type: ACTIONS.SET_DATE_ERROR, payload: true });
    }

    refFocusEndRange.current.endDate = add(refFocusEndRange.current.endDate - 1, {
      hours: 23,
      minutes: 59,
      seconds: 59
    });
    dispatch({ type: ACTIONS.SET_FECHA_FIN, payload: [refFocusEndRange.current] });
  }

  /**
   * Al seleccionar dispositivos, esta función va creando el objeto con los dispositivos seleccionados.
   * @param { dispositivo[] } dispositivosSeleccionados
   */
  function actualizarAutocompleteDispositivos(dispositivosSeleccionados: dispositivo[]) {
    //Se limpian los datos cada vez que se ejecuta el evento de selección de dispositivos para que siempre se renderice correctamente el componente.
    limpiarDatosGrafica();
    dispatch({
      type: ACTIONS.CHANGE_AUTOCOMPLETE_DISPOSITIVOS,
      payload: dispositivosSeleccionados
    });
    if (dispositivosSeleccionados.length === 0) {
      cerrarSlider();
      setAgruparDispositivos(undefined, false);
    }

    if (dispositivosSeleccionados.length > 4) setAgruparDispositivos(undefined, true);

    if (dispositivosSeleccionados.length === 1 && agruparDispositivos)
      setAgruparDispositivos(undefined);
  }
  /**
   * Actualiza el estado frecuencia se controla ese cambio con un useEffect, además se inicializan los datos para que el renderizado del siguiente gráfico sea correcto.
   * @param { Frecuencia } frecuencia
   */
  function actualizarAutoCompleteFrecuencia(frecuencia: Frecuencia) {
    // const rangeUpdate: any = {
    //   [Rango.TODAY]: { actual: startOfToday(), prev: endOfToday() },
    //   [Rango.THIS_WEEK]: {
    //     actual: startOfWeek(new Date(), { weekStartsOn: 1 }),
    //     prev: endOfWeek(new Date(), { weekStartsOn: 1 })
    //   },
    //   [Rango.THIS_MONTH]: { actual: startOfMonth(new Date()), prev: endOfMonth(new Date()) },
    //   [Rango.THIS_YEAR]: { actual: startOfYear(new Date()), prev: endOfYear(new Date()) }
    // };

    dispatch({ type: ACTIONS.CAMBIAR_FRECUENCIA_CONSULTA, payload: frecuencia });
  }

  /**
   *
   * @param { any } datos
   * @param { string } unidad
   * @param { any } grafica gráfica
   * @param { Medida | Array<Medida> } tipoConsulta el tipo de consulta
   */
  function setDatosGrafica(datos: any, tipoConsulta: Medida | Array<Medida>) {
    dispatch({
      type: ACTIONS.ABRIR_SLIDER,
      payload: {
        ...datos,
        tipoConsulta: tipoConsulta
      }
    });
  }

  //Función que limpia los datos cuando la obtención de los mismos no corresponde al componente gráfica.
  function limpiarDatosGrafica() {
    dispatch({
      type: ACTIONS.LIMPIAR_DATOS_GRAFICA_NO_COMPONENTE
    });
  }

  function rangeControl() {
    const [{ startDate, endDate }] = componenteSlider.fechaInicio;
    const { startDate: endRangeStartDate, endDate: endRangeEndDate } =
      refFocusEndRange.current as any;

    const differenceDaysInStart = differenceInDays(endDate, startDate);

    const differenceDaysInEnd = differenceInDays(endRangeEndDate, endRangeStartDate);

    return { startDate, differenceDaysInStart, differenceDaysInEnd };
  }

  /**
   * Se encarga de mostrar u ocultar el slider con la gráfica necesaria y sus datos correspondientes.
   * @param { boolean } abrir
   * @param { Medida | Array<Medida> } tipoConsulta
   */
  function controladorSliderGrafica(abrir: boolean, tipoConsulta?: Medida | Array<Medida>) {
    if (abrir) {
      cargarDatosGraficas(tipoConsulta as Medida | Array<Medida>);
    } else {
      cerrarSlider();
    }
  }

  /**
   * Cierra el slider y muestra las tarjetas.
   */
  function cerrarSlider() {
    dispatch({
      type: ACTIONS.CERRAR_SLIDER
    });
    setTimeout(() => dispatch({ type: ACTIONS.MOSTRAR_TARJETAS, payload: true }), 300);
  }

  function limpiarDispositivos() {
    dispatch({
      type: ACTIONS.LIMPIAR_AUTOCOMPLETE_DISPOSITIVOS,
      payload: !limpiarAutoCompleteDispositivos
    });
  }

  function setAgruparDispositivos(event: any, value = !agruparDispositivos) {
    dispatch({ type: ACTIONS.SET_AGRUPAR_DISPOSITIVOS, payload: value });
  }

  /**
   * Realiza la carga de datos para mostrar la gráfica, además trata si los datos vinieran vacíos,
   * hay que tener en cuenta que los datos cuando vienen seleccionando 1 dispositivo o varios vienen de distinta forma.
   * @param { Medida | Array<Medida> }tipoConsulta el tipo de consulta.
   * @param {Rango} frecuencia El rango para obtener los datos, por defecto es Rango.THIS_WEEK.
   * @param {boolean} isExternal Si la carga de datos de las gráficas se hace desde un lugar distinto al componente Gráficas, por defecto es false.
   */
  async function cargarDatosGraficas(
    tipoConsulta: Medida | Array<Medida>,
    frecuencia: Rango = componenteSlider.frecuencia,
    dispositivos = dispositivosSeleccionados
  ) {
    let graphType = GraphTypes.comparativo;

    const { startDate, endDate } = componenteSlider.fechaInicio[0];
    const { startDate: startDateEndRange, endDate: endDateEndRange } = componenteSlider.fechaFin[0];
    const inicio = [startDate, endDate];
    const fin = [startDateEndRange, endDateEndRange];

    if (frecuencia === Rango.RANGE) graphType = GraphTypes.comparativo_rango_fechas;

    if (
      Array.isArray(tipoConsulta) &&
      tipoFiltrado.tipoSeleccionado === CodigoDispositivo.ENERGIA_SOLAR
    )
      graphType = GraphTypes.energia_solar;
    if (
      Array.isArray(tipoConsulta) &&
      tipoFiltrado.tipoSeleccionado === CodigoDispositivo.ENERGIA_SOLAR &&
      frecuencia === Rango.RANGE
    )
      graphType = GraphTypes.comparative_solar_energy;

    const datosGrafica = await obtenerDatosGrafica(
      tipoConsulta,
      frecuencia,
      componenteSlider.operacion,
      graphType,
      dispositivos,
      undefined,
      inicio,
      fin,
      { openSmarteliaBackdrop: true, closeSmarteliaBackdrop: true },
      agruparDispositivos
    );
    let grafica;

    const LIMITES_GRAFICOS: any = {
      alto: 600,
      izquierda: 65,
      margenVertical: 70
    };

    if (
      graphType === GraphTypes.energia_solar ||
      graphType === GraphTypes.comparative_solar_energy
    ) {
      grafica = {
        grafica: <AreaChart {...datosGrafica} limitesGrafico={LIMITES_GRAFICOS} />
      };
    } else if (dispositivosSeleccionados.length > 1 || frecuencia === Rango.RANGE) {
      grafica = {
        grafica: <MultipleYaxisChart {...datosGrafica} limitesGrafico={LIMITES_GRAFICOS} />
      };
    } else {
      grafica = {
        grafica: <LineColumnChart {...datosGrafica} limitesGrafico={LIMITES_GRAFICOS} />
      };
    }

    setDatosGrafica(grafica, tipoConsulta);
  }

  /**
   * Este useEffect se encarga de habilitar o deshabilitar tarjetas, así como si hay algun dispositivo seleccionado y el slider está abierto realiza la carga de datos para la gráfica.
   */
  useEffect(() => {
    // if (componenteSlider.frecuencia === Rango.RANGE) return;

    if (dispositivosSeleccionados.length === 0) {
      dispatch({ type: ACTIONS.DESHABILITAR_TARJETAS });
    } else {
      dispatch({ type: ACTIONS.HABILITAR_TARJETAS });
    }
    if (componenteSlider.abrirSlider && dispositivosSeleccionados.length >= 1) {
      cargarDatosGraficas(tipoConsulta);
    }
  }, [dispositivosSeleccionados, componenteSlider.frecuencia, agruparDispositivos]);

  useEffect(() => {
    limpiarDispositivos();
  }, [tipoFiltrado.tipoSeleccionado]);

  // useEffect(() => {
  //   const { fechaFin, fechaInicio, dateError } = componenteSlider;
  //   if (fechaInicio >= fechaFin) {
  //     dispatch({ type: ACTIONS.SET_DATE_ERROR, payload: true });
  //   } else if (dateError) dispatch({ type: ACTIONS.SET_DATE_ERROR, payload: false });
  // }, [componenteSlider.fechaFin, componenteSlider.fechaInicio]);

  return {
    dispositivos,
    tiposDispositivos,
    tipoFiltrado,
    setDispositivos,
    setTiposDispositivos,
    actualizarAutocompleteTiposDispositivos,
    actualizarAutocompleteDispositivos,
    controladorSliderGrafica,
    componenteSlider,
    dispositivosSeleccionados,
    mostrarTarjetas,
    deshabilitarTiposDispositivos,
    deshabilitarTarjetas,
    actualizarAutoCompleteFrecuencia,
    cerrarSlider,
    tipoConsulta,
    limpiarAutoCompleteDispositivos,
    cargarDatosGraficas,
    limpiarDatosGrafica,
    setAgruparDispositivos,
    handleFechaFin,
    handleFechaInicio,
    agruparDispositivos,
    setDatosGrafica,
    refFocusEndRange,
    handleRangeFocus
  };
}
