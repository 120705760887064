import { formatToDayStart } from '../../../../../../functions/functions';
import { Modulos } from '../../../../../../resources/enums/enumModulo';
import { tipoAlerta } from '../../../../../alertas/resources/enums/enumTipoAlerta';
import { alerta } from '../../../../../alertas/resources/interfaces/interfaceAlerta';
import { DispositivoVideo } from '../../../../resources/interfaces/interfaceDispositivoVideo';

/**
 * Función CCTV que obtiene el indice final de la subcadena 'canal: ' de una cadena principal
 * que es el mensaje de alerta
 * @param {string} mensaje mensaje de alerta
 * @returns {number} indice donde termina la subcadena
 */
export const getIndiceFinCanal = (mensaje: string): number => {
  const mensajeLower = mensaje.toLowerCase();
  const buscar = 'canal: ';
  const indice = mensajeLower.indexOf(buscar);

  return indice > -1 ? indice + buscar.length : indice;
};
/**
 * Función CCTV que obtiene el canal de la cámara contenida en el mensaje de alerta
 * @param {string} mensaje mensaje de alerta
 * @returns {string | undefined} devuelve el canal si existe
 */
export const getCanal = (mensaje: string): string | undefined => {
  let canal: string | undefined;
  const indiceFinCanal = getIndiceFinCanal(mensaje);
  if (indiceFinCanal > -1) {
    canal = mensaje.slice(indiceFinCanal, indiceFinCanal + 2);
  }
  return canal;
};
/**
 * Función CCTV que obtiene el titulo para el dialogo de la visualización CCTV
 * El titulo se compondrá de el nombre del dispositivo, el canal que tiene en el videgrabador
 * y la fecha del evento producido
 * @param {string} nombre nombre del dispositivo
 * @param {string} mensaje mensaje de la alerta
 * @param {string} fecha fecha de la alerta
 * @returns {string} titulo construido
 */
export const getTitle = (nombre: string, mensaje: string, fecha: string): string => {
  const fechaFormateada = fecha.includes('T') ? formatToDayStart(fecha) : fecha;
  const canal = getCanal(mensaje);
  const canalInfo = canal ? ': Canal ' + canal : '';
  return nombre + canalInfo + ' - ' + fechaFormateada;
};
/**
 * Función CCTV que obtiene el mensaje de la alerta sin la referencia al canal
 * @param {string} mensaje mensaje de la alerta
 * @returns {string} mensaje construido
 */
export const getInfoSinCanal = (mensaje: string): string => {
  let mensajeSinCanal = mensaje;
  const indiceFinCanal = getIndiceFinCanal(mensaje);
  if (indiceFinCanal > -1) {
    mensajeSinCanal = mensaje.slice(indiceFinCanal + 4);
  }
  return mensajeSinCanal;
};

/**
 * Función que apartir de un objeto alerta obtiene un objeto de dispositivo de video
 * @param {alerta} alerta
 * @returns {DispositivoVideo}
 */
export const alertToDeviceVideo = (alerta: alerta): DispositivoVideo => {
  return {
    idDevice: alerta.idDevice as number,
    nombre: alerta.nombre,
    fechaAlerta: getFechaRegistro(alerta.fechaRegistro),
    mensajeAlerta: alerta.informacion,
    idTipoAlerta: alerta.idTipoAlerta
  };
};

/**
 * Función que comprueba si una alerta es de tipo evento y están en el módulo de CCTV
 * para poder ver grabaciones de los eventos registrados
 * @param {number} moduloSeleccionado modulo que está seleccionado
 * @param {DispositivoVideo} elemento dispositivo/alerta
 * @returns {boolean} verdadero si es cierto, falso si no
 */
export const isCctvEvent = (moduloSeleccionado: number, elemento: DispositivoVideo): boolean => {
  return moduloSeleccionado === Modulos.VIDEOVIGILANCIA &&
    elemento.idTipoAlerta === tipoAlerta.EVENT
    ? true
    : false;
};

/**
 * Función que obtiene la fecha de registro de la alerta
 * @param {string} fecha fecha de registro de la alerta
 * @returns {string} fecha de registro
 */
export const getFechaRegistro = (fecha = ''): string => {
  return fecha.includes('T') ? new Date(fecha).toString() : fecha;
};
